import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// DESIGN SYSTEM && COMPONENTS
import EntityCustomFieldForm from '../../../components/customField/EntityCustomFieldForm';
import { Dropdown, Input, Toggle, Button, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import JiraIssueModalContent from '../../../components/jira/modal/JiraIssueModalContent';
import { Modal } from '../../../components/modal';
// API & HOOKS
import { useApi } from '../../../hooks/UseApi';
import UseCustomField from '../../../hooks/UseCustomField';
import UseProjectUsers from '../../../hooks/UseProjectUsers';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseSystemField from '../../../hooks/UseSystemField';
import UseJiraConfig from '../../../hooks/UseJiraConfig';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useForm } from 'react-hook-form';
import DefectApi from '../../../api/DefectApi';
import JiraEntityApi from '../../../api/JiraEntityApi';
import TestCycleApi from '../../../api/TestCycleApi';
import TestRunApi from '../../../api/TestRunApi';
import TestRunExecutionApi from '../../../api/TestRunExecutionApi';
// HELPERS & CONSTANTS
import { getSystemFieldOptions } from '../../../helpers/EnumHelper';
import { ENTITY_TYPES, PRIVILEGES } from '../../../constants';
import { getArtifactName, getRunName, getCycleName } from '../../../helpers/ArtifactNameHelper';
import { createEntityFields, getCustomFields } from '../../../helpers/CustomFieldHelper';
import { checkRequired, editModalMapping, isValidList, isValidValue } from '../../../helpers/Helper';
import {
  isActiveUpdateJiraDefectButton,
  isActiveNewJiraDefectButton
} from '../../../helpers/jira/JiraConfigurationHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { checkDisabledStatus, hasPrivilege } from '../../../helpers/PriviligeHelper';
import { defaultValue, getStatusOptionsByData } from '../../../helpers/SystemFieldHelper';
import UseSystemFieldParams from '../../../hooks/UseSystemFieldParams';
import { useTranslation } from 'react-i18next';
import { titleTranslation, translateArrItemName, translateArrItemText } from '../../../helpers/TranslationHelper';
import Markdown from '../../../components/markdown/Markdown';

const defaultDataForm = {
  assigneeId: null,
  customFields: [],
  description: null,
  id: null,
  priorityId: null,
  projectId: null,
  statusId: null,
  summary: null,
  testCycleId: null,
  testRunId: null,
  typeId: null,
  testRunExecutionIds: []
};

const DefectModal = ({
  isOpen,
  closeModal,
  data,
  submitCallBack,
  testCycleId,
  runId,
  selectedExecution,
  projectConfig,
  project,
  vftsid,
  token,
  decodedIntegrationData,
  url
}) => {
  const projectId = project || UseQueryProject('projectId')?.projectId;
  const systemFieldParams = UseSystemFieldParams(projectId, ENTITY_TYPES.DEFECT);
  const userList = UseProjectUsers(projectId);
  const jiraConfig = UseJiraConfig(projectId);

  const { t } = useTranslation();
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const [dataForm, setDataForm] = useState(JSON.parse(JSON.stringify(defaultDataForm)));
  const [cFields, setCFields] = useState([]);
  const [customFieldForm, setCustomFieldForm] = useState({});
  const [testCycles, setTestCycles] = useState([]);
  const [testRuns, setTestRuns] = useState([]);
  const [testRunExecutions, setTestRunExecutions] = useState([]);

  const [showJiraSwitchButton, setShowJiraSwitchButton] = useState(false);
  const [openJiraDetails, setOpenJiraDetails] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const localStateOfJiraIntegration = localStorage.getItem('jiraIntegrationIsOpen');

  // <-- API SETUP -->
  const [statusEnums, setStatusEnums] = useStateWithCallbackLazy([]);
  const { pathname } = useLocation();
  let type = getSystemFieldOptions(UseSystemField(projectId, ENTITY_TYPES.DEFECT, 'type')) || [];
  type = translateArrItemName(type, t);
  let status = UseSystemField(projectId, ENTITY_TYPES.DEFECT, 'status') || [];
  status = translateArrItemName(status, t);
  let priority = getSystemFieldOptions(UseSystemField(projectId, ENTITY_TYPES.DEFECT, 'priority')) || [];
  priority = translateArrItemName(priority, t);
  const entityCustomFields = UseCustomField(projectId, ENTITY_TYPES.DEFECT) || [];

  const saveDefect = useApi({
    name: 'saving defect',
    api: isValidValue(data) ? DefectApi.update : DefectApi.create,
    onSuccess: () => {
      if (submitCallBack) submitCallBack();
      closeModal(saveDefect?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const editDefectStatus = useApi({
    name: 'edit defect status',
    api: DefectApi.editStatus,
    onSuccess: () => {
      if (submitCallBack) submitCallBack();
      closeModal(editDefectStatus?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const editDefectPriority = useApi({
    name: 'edit defect priority',
    api: DefectApi.editPriority,
    onSuccess: () => {
      if (submitCallBack) submitCallBack();
      closeModal(editDefectPriority?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const editDefectType = useApi({
    name: 'edit defect type',
    api: DefectApi.editType,
    onSuccess: () => {
      if (submitCallBack) submitCallBack();
      closeModal(editDefectType?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const saveDefectWithJira = useApi({
    name: 'saving defect with Jira',
    api: isValidValue(data) ? JiraEntityApi.updateDefectJira : JiraEntityApi.createDefectJira,
    onSuccess: () => {
      closeModal(saveDefectWithJira?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const createIntegrationDefect = useApi({
    name: 'create 3rd party defect',
    api: DefectApi.create3rdpartyDefect,
    onSuccess: () => {
      closeModal(saveDefect?.data);
      showSuccessMes(INFO_MESSAGES.save);
      setLoading(false);
      setTimeout(() => {
        showSuccessMes('You are beeing redirected to Visium Farm . . . ');
        window.close();
      }, 3000);
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const createJiraIntegrationDefect = useApi({
    name: 'create 3rd party defect withJira',
    api: JiraEntityApi.create3rdpartyDefect,
    onSuccess: () => {
      closeModal(saveDefectWithJira?.data);
      showSuccessMes(INFO_MESSAGES.save);
      setLoading(false);
      setTimeout(() => {
        showSuccessMes('You are beeing redirected to Visium Farm . . . ');
        window.close();
      }, 3000);
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const cyclesLoader = useApi({
    name: 'get test cycles',
    api: TestCycleApi.getTree,
    onSuccess: () => setTestCycles(cyclesLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const runListLoader = useApi({
    name: 'get test runs',
    api: TestRunApi.getAll,
    onSuccess: () => {
      let runList = [];
      runList = runListLoader?.data.map((item) => ({ id: item.id, name: item.code, value: item.id }));
      setTestRuns(runList);
    },
    onError: (err) => showErrorMes(err)
  });

  const executionListLoader = useApi({
    name: 'get executions',
    api: TestRunExecutionApi.getAll,
    onSuccess: () => {
      let executionList = [];
      executionList = executionListLoader?.data.map((item) => ({ id: item.id, name: item.code, value: item.id }));
      setTestRunExecutions(executionList);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    setLoading(true);
    let cFieldForm = getCustomFields(cFields, form);
    let body = {
      ...dataForm,
      summary: form?.summary,
      description: form?.description,
      customFields: createEntityFields(form, cFieldForm),
      testCycleId: form?.testCycleId,
      testRunId: form?.testRunId,
      testRunExecutionIds: dataForm?.testRunExecutionIds?.map((x) => x.id) || [],
      projectId,
      priorityId: isValidValue(form?.priority?.value) ? form?.priority?.value : form?.priority?.id,
      typeId: isValidValue(form?.type?.value) ? form?.type?.value : form?.type?.id,
      statusId: isValidValue(form?.status?.value) ? form?.status?.value : form?.status?.id
    };

    let link = isValidList(form?.jiraNewLink) ? form?.jiraNewLink[0]?.id : dataForm?.jiraNewLink; // link dataformdan alınmali
    let linkType = form?.jiraLinkTypeId?.[0]?.id ? form?.jiraLinkTypeId[0].id : form?.jiraLinkTypeId;

    // TODO: Acil olmayan refactor. Burası biraz karışık. Daha düzenli bir şekilde yazılabilir.
    // if burda başlıyor.
    if (jiraConfig && jiraConfig?.activeDefect === true && showJiraSwitchButton === true && openJiraDetails === true) {
      const jiraInput = {
        ...body,
        jiraProjectId: form?.jiraProjectId?.[0]?.id ? form?.jiraProjectId?.[0]?.id : form?.jiraProjectId,
        jiraIssueTypeId: form?.jiraIssueTypeId?.[0]?.id ? form?.jiraIssueTypeId?.[0]?.id : form?.jiraIssueTypeId,
        jiraPriorityId: form?.jiraPriorityId?.[0]?.id ? form?.jiraPriorityId?.[0]?.id : form?.jiraPriorityId,
        jiraAssigneeId: form?.jiraAssigneeId?.[0]?.id ? form?.jiraAssigneeId?.[0]?.id : form?.jiraAssigneeId,
        jiraDescription: form?.jiraDescription,
        newComment: form?.newComment,
        jiraLinkTypeId: linkType,
        jiraNewLink: link
      };
      if (pathname == '/3rd/defect') {
        createJiraIntegrationDefect.execute({
          data: body,
          params: {
            url: url,
            token: token,
            tsid: vftsid
          }
        });
      } else if (!hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT)) {
        if (hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_STATUS)) {
          const statusBody = {
            statusId: isValidValue(dataForm?.statusId) ? dataForm?.statusId : null,
            id: data?.id
          };
          editDefectStatus.execute(statusBody);
        } else if (hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_PRIORITY)) {
          const priorityBody = {
            priorityId: isValidValue(dataForm?.priorityId) ? dataForm?.priorityId : null,
            id: data?.id
          };
          editDefectPriority.execute(priorityBody);
        } else if (hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_TYPE)) {
          const typeBody = {
            typeId: isValidValue(dataForm?.typeId) ? dataForm?.typeId : null,
            id: data?.id
          };
          editDefectType.execute(typeBody);
        }
      } else {
        saveDefectWithJira.execute(jiraInput);
      }
    } else {
      if (pathname == '/3rd/defect') {
        createIntegrationDefect.execute({
          data: body,
          params: {
            url: url,
            token: token,
            tsid: vftsid
          }
        });
      } else if (!hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT)) {
        if (hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_STATUS)) {
          const statusBody = {
            statusId: isValidValue(dataForm?.statusId) ? dataForm?.statusId : null,
            id: data?.id
          };
          editDefectStatus.execute(statusBody);
        } else if (hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_PRIORITY)) {
          const priorityBody = {
            priorityId: isValidValue(dataForm?.priorityId) ? dataForm?.priorityId : null,
            id: data?.id
          };
          editDefectPriority.execute(priorityBody);
        } else if (hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_TYPE)) {
          const typeBody = {
            typeId: isValidValue(dataForm?.typeId) ? dataForm?.typeId : null,
            id: data?.id
          };
          editDefectType.execute(typeBody);
        }
      } else {
        saveDefect.execute(body);
      }
    }
  };

  const handleOpenJiraDetails = () => {
    if (!isValidValue(localStateOfJiraIntegration)) {
      return true;
    } else {
      return localStateOfJiraIntegration === 'true' ? true : false;
    }
  };

  useEffect(() => {
    setValue('priority', defaultValue(priority));
    setValue('type', defaultValue(type));
    setValue('status', defaultValue(status));
    setDataForm((prev) => ({
      ...prev,
      priorityId: defaultValue(priority)?.value,
      typeId: defaultValue(type)?.value,
      statusId: defaultValue(status)?.value
    }));
    if (isValidValue(data)) {
      setisUpdate(true);
      let testRunExecutionIds = [];
      if (isValidList(data?.testRunExecutionIds)) {
        testRunExecutionIds = data.testRunExecutionIds.map((x) => x.id);
      }

      const form = {
        ...data,
        summary: data?.summary || '',
        typeId: data?.type?.id || '',
        statusId: data?.status?.id || '',
        priorityId: data?.priority?.id || '',
        assigneeId: data?.assignee?.id || '',
        testCycleId: data?.testCycle?.id || '',
        testRunId: data?.testRun?.id || '',
        testRunExecutionIds,
        jiraProjectId: data?.jiraContent?.fields?.project?.id,
        jiraIssueTypeId: data?.jiraContent?.fields?.issuetype?.id,
        jiraPriorityId: data?.jiraContent?.fields?.priority?.id,
        jiraDescription: data?.jiraContent?.fields?.description
      };
      let mappedArray = editModalMapping(form);
      mappedArray.forEach(({ name, value }) => setValue(name, value));

      setDataForm(form);
      if (hasPrivilege(projectId, PRIVILEGES.UPDATE_JIRA_DEFECT) && isActiveUpdateJiraDefectButton(projectConfig)) {
        setShowJiraSwitchButton(true);
        setOpenJiraDetails(handleOpenJiraDetails());
        setValue('openJiraDetails', openJiraDetails);
      }
    } else {
      if (hasPrivilege(projectId, PRIVILEGES.CREATE_JIRA_DEFECT) && isActiveNewJiraDefectButton(projectConfig)) {
        setShowJiraSwitchButton(true);
        setOpenJiraDetails(handleOpenJiraDetails());
        setValue('openJiraDetails', openJiraDetails);
      }
    }
  }, [data, projectId]);

  useEffect(() => {
    let nodes = getStatusOptionsByData(data, status);
    setStatusEnums(translateArrItemText(nodes, t));
  }, [status]);

  useEffect(() => {
    if (isOpen) {
      cyclesLoader.execute({ projectId });

      if (decodedIntegrationData) {
        setValue('summary', decodedIntegrationData.summary);
        setValue('description', decodedIntegrationData.description);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (dataForm?.testCycleId || isValidValue(testCycleId)) {
      let id = isValidValue(dataForm?.testCycleId) ? dataForm?.testCycleId : testCycleId;
      runListLoader.execute({ projectId, testCycleId: id });
      setValue('testCycleId', id);
    }
  }, [dataForm?.testCycleId, testCycleId]);

  useEffect(() => {
    if (dataForm?.testRunId || isValidValue(runId)) {
      let id = isValidValue(dataForm?.testRunId) ? dataForm?.testRunId : runId;
      executionListLoader.execute({ testRunId: id });
      setValue('testRunId', id);
    }
  }, [dataForm?.testRunId, runId]);

  useEffect(() => {
    if (selectedExecution) {
      setDataForm({ ...dataForm, testRunExecutionIds: [selectedExecution] });
    }
  }, [selectedExecution]);
  return (
    <Modal
      open={isOpen}
      close={() => closeModal}
      w={'md'}
    >
      <Modal.Title>{titleTranslation(getArtifactName(projectConfig, ENTITY_TYPES.DEFECT), data, '', t)}</Modal.Title>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Content h={'lg'}>
          <div className="flex flex-grow flex-col gap-4 overflow-y-auto">
            <Input
              disabled={
                isValidValue(data)
                  ? checkDisabledStatus(
                      data?.lockStatus?.update,
                      hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT),
                      projectConfig?.configuration?.defect?.defectSummaryUpdate
                    )
                  : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
              }
              required
              name="summary"
              label={t('Summary')}
              placeholder={t('name_entity', { entity: getArtifactName(projectConfig, ENTITY_TYPES.DEFECT) })}
              control={control}
            />
            <div className="flex gap-4">
              <Dropdown
                control={control}
                label={t('Type')}
                name="type"
                required={checkRequired('type', systemFieldParams)}
                clearable
                placeholder={t('Select Type')}
                options={type}
                selectedItem={isValidValue(getValues('type')) ? getValues('type') : defaultValue(type)}
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(
                        data?.lockStatus?.update,
                        hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_TYPE)
                      )
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
                }
                passValue={(value) => {
                  setValue('type', isValidList(value) ? value[0] : null);
                  if (!isValidList(value)) setValue('type', null);
                  setDataForm({
                    ...dataForm,
                    typeId: isValidList(value) ? value[0]?.id : null
                  });
                }}
              />
              <Dropdown
                control={control}
                placeholder={t('Select Status')}
                label={t('Status')}
                name="status"
                clearable
                required={checkRequired('status', systemFieldParams)}
                options={statusEnums}
                selectedItem={isValidValue(getValues('status')) ? getValues('status') : defaultValue(status)}
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(
                        data?.lockStatus?.update,
                        hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_STATUS)
                      )
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
                }
                passValue={(value) => {
                  setValue('status', isValidList(value) ? value[0] : null);
                  if (!isValidList(value)) setValue('status', null);
                  setDataForm({ ...dataForm, statusId: isValidList(value) ? value[0]?.id : null });
                }}
              />
            </div>
            <div className="flex gap-4">
              <Dropdown
                control={control}
                label={t('Priority')}
                name="priority"
                options={priority}
                required={checkRequired('priority', systemFieldParams)}
                clearable
                placeholder={t('Select Priority')}
                selectedItem={isValidValue(getValues('priority')) ? getValues('priority') : defaultValue(priority)}
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(
                        data?.lockStatus?.update,
                        hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_PRIORITY)
                      )
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
                }
                passValue={(value) => {
                  setValue('priority', isValidList(value) ? value[0] : null);
                  if (!isValidList(value)) setValue('priority', null);
                  setDataForm({ ...dataForm, priorityId: isValidList(value) ? value[0]?.id : null });
                }}
              />
              <Dropdown
                control={control}
                name="assigneeId"
                label={t('Assignee')}
                clearable
                placeholder={t('Select Assignee')}
                options={userList}
                selectedItem={data?.assignee}
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(data?.lockStatus?.update, hasPrivilege(projectId, PRIVILEGES.ASSIGN_DEFECT))
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
                }
                passValue={(value) => {
                  setDataForm({ ...dataForm, assigneeId: isValidList(value) ? value[0]?.id : null });
                  if (!isValidList(value)) setValue('assigneeId', null);
                }}
              />
            </div>
            <div className="flex gap-4">
              <Dropdown
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(data?.lockStatus?.update, hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT))
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
                }
                required
                clearable
                control={control}
                label={getCycleName(projectConfig)}
                name="testCycleId"
                placeholder={t('select_entity', { entity: getCycleName(projectConfig) })}
                options={testCycles}
                selectedItem={
                  data
                    ? data?.testCycle
                    : isValidValue(testCycleId) && testCycles.filter((item) => item.id === testCycleId)[0]
                }
                passValue={(value) => {
                  setDataForm({ ...dataForm, testCycleId: isValidList(value) ? value[0]?.id : null });
                  if (!isValidList(value)) setValue('testCycleId', null);
                }}
              />
            </div>
            {(dataForm?.testCycleId || isValidValue(testCycleId)) && (
              <div className="flex gap-4">
                <Dropdown
                  clearable
                  disabled={
                    isValidValue(data)
                      ? checkDisabledStatus(data?.lockStatus?.update, hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT))
                      : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
                  }
                  control={control}
                  label={getRunName(projectConfig)}
                  name="testRunId"
                  placeholder={t('select_entity', { entity: getRunName(projectConfig) })}
                  options={testRuns}
                  selectedItem={
                    isValidValue(data?.testRun?.code)
                      ? { name: data?.testRun?.code }
                      : isValidValue(runId) && testRuns.filter((item) => item.id === runId)[0]
                  }
                  passValue={(value) => {
                    setDataForm({ ...dataForm, testRunId: isValidList(value) ? value[0]?.id : null });
                    if (!isValidList(value)) setValue('testRunId', null);
                  }}
                />
              </div>
            )}
            {(dataForm?.testRunId || isValidValue(runId)) && (
              <div className="flex cursor-pointer gap-4">
                <MultiSelectDropdownPill
                  control={control}
                  disabled={
                    isValidValue(data)
                      ? checkDisabledStatus(data?.lockStatus?.update, hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT))
                      : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
                  }
                  name="testRunExecutionIds"
                  label={t('Test Run Executions')}
                  options={testRunExecutions}
                  value={
                    data?.testRunExecutionIds
                      ? data?.testRunExecutionIds
                      : isValidValue(selectedExecution) &&
                        testRunExecutions.filter((item) => item.id === selectedExecution.id)
                  }
                  passValue={(value) => setDataForm({ ...dataForm, testRunExecutionIds: value })}
                />
              </div>
            )}
            {jiraConfig && jiraConfig?.activeDefect === true && showJiraSwitchButton === true && (
              <div className="ml-2 flex gap-2">
                <Toggle
                  labelRight
                  labelText={t('Enable Jira Integration')}
                  value={openJiraDetails}
                  control={control}
                  name={'openJiraDetails'}
                  passValue={(value) => {
                    setValue('openJiraDetails', value);
                    setOpenJiraDetails(value);
                    localStorage.setItem('jiraIntegrationIsOpen', value);
                  }}
                />
              </div>
            )}
            {jiraConfig && jiraConfig?.activeDefect === true && openJiraDetails === true && (
              <JiraIssueModalContent
                isUpdate={isUpdate}
                entityType={ENTITY_TYPES.DEFECT}
                jiraConfig={jiraConfig}
                control={control}
                setValue={setValue}
                dataForm={dataForm}
                setDataForm={setDataForm}
                projectId={projectId}
              />
            )}
            <EntityCustomFieldForm
              customFieldForm={customFieldForm}
              setCustomFieldForm={setCustomFieldForm}
              control={control}
              setValue={setValue}
              entityFields={data?.customFields}
              entityCustomFields={entityCustomFields}
              dataForm={dataForm}
              customControlForJiraModal={false}
              setCFields={setCFields}
              disabled={
                isValidValue(data)
                  ? checkDisabledStatus(data?.lockStatus?.update, hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT))
                  : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
              }
            />
            <Markdown
              control={control}
              name="description"
              label={t('Description')}
              disabled={
                isValidValue(data)
                  ? checkDisabledStatus(data?.lockStatus?.update, hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT))
                  : !hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT)
              }
            />
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="defectModal-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={() => closeModal()}
          />
          <Button
            id="defectModal-submit-button"
            primary
            text={t('Submit')}
            type="submit"
            loading={loading}
          />
        </Modal.Buttons>
      </form>
    </Modal>
  );
};

export default DefectModal;
