import React from 'react';
import { NavLink } from 'react-router-dom';
import { Heading } from '../../designSystem/DesignSystem';
import { t } from 'i18next';
import { setSelectedReport } from '../../context/ProjectActions';
import { useAppDispatch } from '../../context/AppContext';

const ReportsSubMenuItem = ({
  subItem,
  index,
  item,
  projectId,
  isSubNav2Open,
  setIsSubNav2Open,
  isSubMenuNameActive
}) => {
  const dispatch = useAppDispatch();

  return (
    <NavLink
      key={index}
      onClick={() => {
        dispatch(setSelectedReport(subItem));
      }}
      to={`/reports/${item.path}/${subItem.id}?projectId=${projectId}`}
      className={
        `flex cursor-pointer flex-col py-0.5 text-sm font-normal text-gray-500 ` +
        (isSubMenuNameActive(subItem.id) && 'font-semibold text-primary-500')
      }
    >
      <div
        className="flex items-center gap-2 "
        onClick={() => {
          setIsSubNav2Open(subItem.id === isSubNav2Open ? ' ' : subItem.id);
        }}
      >
        <div className="ml-3">
          <Heading
            type="base"
            color={isSubMenuNameActive(subItem.id) ? 'text-blue-700' : 'text-gray-500'}
            text={t(subItem.name)}
          />
        </div>
      </div>
    </NavLink>
  );
};

export default ReportsSubMenuItem;
