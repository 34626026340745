import React from 'react';
import { Datepicker } from '../../../designSystem/DesignSystem';

const DateCustomField = ({ control, field, selected, disabled }) => {
  return (
    <>
      <Datepicker
        required={field?.required}
        control={control}
        startDateName={field?.customFieldId}
        disabled={disabled}
        startDateLabel={field?.fieldName}
        startDateForm={selected}
      />
    </>
  );
};
export default DateCustomField;
