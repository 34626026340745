import React, { useEffect } from 'react';
import { Heading } from '../../../src/designSystem/Typography/Typography';

const FarmRedirection = () => {
  const manageTokens = localStorage.getItem('token');
  const { token, refreshToken } = JSON.parse(manageTokens || '{}');

  const url = decodeURIComponent(window.location.href);
  const match = url.match(/callback\/(.+?)$/);
  const farmURI = match ? match[1] : null;

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      window.location.href = `${farmURI}/manual-test-sessions?refreshToken=${refreshToken}&accessToken=${token}`;
    }, 2);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <div className="m-auto text-center">
      <Heading
        color="text-gray-600"
        uppercase
        type="large"
        text={'You are being redirected to Visium Farm'}
        className="py-4"
      />
    </div>
  );
};

export default FarmRedirection;
