import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TestCaseApi from '../../../api/TestCaseApi';
import TestCaseStepApi from '../../../api/TestCaseStepApi';
import { Dropdown, TextArea, Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import { TestCaseApproveType } from '../../../constants';
import { getVersionText, isValidValue } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';

// !TODO: importlar düzenlenecek

const TestCaseStepUpdateModal = ({ step, isOpen, closeModal = () => {} }, defaultValues) => {
  const { control, handleSubmit, reset, setValue } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all',
    defaultValues
  });

  const [versions, setVersions] = useState([]);
  const { t } = useTranslation();

  const editTestCaseStep = useApi({
    name: 'edit testcase step',
    api: TestCaseStepApi.edit,
    autoLoad: false,
    onSuccess: () => {
      reset();
      closeModal(true);
    },
    onError: (err) => showErrorMes(err)
  });

  const getVersion = useApi({
    name: 'get testcase versions',
    api: TestCaseApi.getTestCaseVersion,
    onSuccess: (form) => {
      let optionList = form.map((item) => {
        return {
          key: item.id,
          value: item.id,
          majorVersion: item.majorVersion,
          approveType: item.approveType,
          text: getVersionText(item),
          name: getVersionText(item)
        };
      });
      var sortedObjs = _.sortBy(optionList, 'majorVersion');

      setVersions(sortedObjs);
    }
  });

  useEffect(() => {
    if (isValidValue(step?.relatedTestCase?.rootId)) {
      getVersion.execute({ id: step?.relatedTestCase?.rootId, approveType: TestCaseApproveType.APPROVED });
    }
  }, [step?.relatedTestCase]);

  useEffect(() => {
    setValue('desc', step.description);
    setValue('expResult', step.expectedResult);
  }, [step]);

  const onEditSubmit = (form) => {
    if (step.id) {
      let body = {
        description: form.desc,
        expectedResult: form.expResult
      };

      if (step?.relatedTestCase?.id) {
        body.relatedTestCaseId = form.relatedTestCaseId;
      } else if (!isValidValue(form?.desc) || !isValidValue(form?.desc.trim())) {
        return;
      }

      editTestCaseStep.execute({ ...body, id: step.id });
    }
  };

  return (
    <Modal
      open={isOpen}
      close={() => {}}
      w={'md'}
    >
      <Modal.Title>{t('Edit Step')}</Modal.Title>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onEditSubmit)}
      >
        <Modal.Content h={'md'}>
          <div
            style={{ minHeight: '140px' }}
            className="flex flex-grow flex-col"
          >
            {!step?.relatedTestCase?.id ? (
              <>
                <TextArea
                  placeholder={t('Enter Description')}
                  control={control}
                  name="desc"
                  label={t('Description')}
                />
                <TextArea
                  placeholder={t('Enter Expected Result')}
                  control={control}
                  name="expResult"
                  label={t('Expected Result')}
                />
              </>
            ) : (
              <Dropdown
                label={t('Change Version')}
                control={control}
                options={versions}
                passValue={(value) => setValue('relatedTestCaseId', value[0]?.value)}
                selectedItem={(versions || []).find((item) => item.value === step?.relatedTestCase?.id)}
                name="relatedTestCaseId"
              />
            )}
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="testCaseStepUpdateModal-cancel-button"
            secondary
            text={t('Cancel')}
            size="xsmall"
            onClick={() => {
              reset();
              closeModal();
            }}
          />
          <Button
            id="testCaseStepUpdateModal-submit-button"
            primary
            text={t('Submit')}
            type="submit"
            loading={editTestCaseStep?.loading}
            size="xsmall"
          />
        </Modal.Buttons>
      </form>
    </Modal>
  );
};

export default TestCaseStepUpdateModal;
