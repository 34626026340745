import React from 'react';
import { Modal } from '../modal';
import { useTranslation } from 'react-i18next';
import { Body } from '../../designSystem/DesignSystem';

const UploadLoadingModal = ({ open, totalFiles, uploadedFiles }) => {
  const { t } = useTranslation();

  const progress = totalFiles > 0 ? Math.floor((uploadedFiles / totalFiles) * 100) : 0;

  return (
    <Modal
      open={open}
      close={() => {}}
      w="sm"
    >
      <Modal.Title>{t('Uploading files...')}</Modal.Title>
      <Modal.Content h={'md'}>
        <Body
          type="large"
          text={t('Please wait while your files are being uploaded.')}
        />
        <div className="mt-4">
          <div className="h-2 rounded-full bg-gray-200">
            <div
              className="h-full rounded-full bg-blue-600"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p className="mt-2 text-center">{`${uploadedFiles} / ${totalFiles} ${t('files uploaded')}`}</p>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default UploadLoadingModal;
