import { CopySimple, MagnifyingGlassPlus } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import CloneModal from '../../components/clone/CloneModal';
// DESIGN SYSTEM & COMPONENT
import Table from '../../components/table/Table';
import { TableColumns } from '../../components/table/TableColumns';
// HELPER & CONSTANTS
import { ENTITY_TYPES } from '../../constants';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../helpers/ButtonConstants';
import { getPageFilterInput, isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { isClonePrivileged } from '../../helpers/PriviligeHelper';
import {
  DEFAULT_PAGE_PARAMS,
  disableResizingForColumns,
  getNotResizedColumnList,
  getTableActions,
  handleSelectedRow,
  translateColumns
} from '../../helpers/TableHelper';
// Api and Hook Imports
import UseQueryProject from '../../hooks/UseQueryProject';
import UseTableColumns from '../../hooks/UseTableColumns';
import UseTableHelper from '../../hooks/UseTableHelper';
import UseUserTablePreferences from '../../hooks/UseUserTablePreferences';
import { useApi } from '../../hooks/UseApi';
import DefectApi from '../../api/DefectApi';
import { useNavigate } from 'react-router-dom';
import {
  getCycleName,
  getRunName,
  getSuiteName,
  getTestCaseName,
  getTestFolderName
} from '../../helpers/ArtifactNameHelper';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseSystemField from '../../hooks/UseSystemField';
import { getI18n, useTranslation } from 'react-i18next';

const DefectReactTable = ({ filter, setRefreshStatus, refreshStatus, users }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);

  UseSystemField(projectId, ENTITY_TYPES.DEFECT, 'status');

  const { selectedColumns } = UseTableColumns(projectId, ENTITY_TYPES.DEFECT);
  const { createTableData } = UseTableHelper();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('code', 'DESC'));
  const [selectedItem, setSelectedItem] = useState(null);
  const [customFilters, setCustomFilters] = useState({});
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(0);

  const setUrl = (id) => {
    if (id) navigate(`/defects?projectId=${projectId}&defectId=${id}`);
  };

  const optionsFn = (row) => {
    return [
      {
        id: 'defectReactTable-details-button',
        name: 'Details',
        icon: (weight) => (
          <MagnifyingGlassPlus
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          setUrl(row?.original?.id);
        }
      },
      ...(isClonePrivileged(ENTITY_TYPES.DEFECT, projectId)
        ? [
            {
              id: 'defectReactTable-clone-button',
              name: 'Clone',
              icon: (weight) => (
                <CopySimple
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: false,
              onClick: () => {
                handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
                setOpenCloneModal(true);
              }
            }
          ]
        : [])
    ];
  };

  const dataLoader = useApi({
    name: 'fetching defects',
    api: DefectApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
      setRefreshStatus(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const tData = useMemo(() => {
    let data = [];
    if (dataLoader?.data?.content) {
      const columnsConfig = TableColumns[ENTITY_TYPES.DEFECT];

      // Custom Artifact Names should also be displayed in columns
      const testCaseNameColumn = columnsConfig.find((column) => column?.key === 'testCaseName');
      if (testCaseNameColumn?.name)
        testCaseNameColumn.name = `${t('entityName', { entity: getTestCaseName(projectConfig) })} `;

      const testCaseCodeColumn = columnsConfig.find((column) => column?.key === 'testCaseCode');
      if (testCaseCodeColumn?.name)
        testCaseCodeColumn.name = `${t('entityCode', { entity: getTestCaseName(projectConfig) })} `;

      const testCycleNameColumn = columnsConfig.find((column) => column?.key === 'testCycleName');
      if (testCycleNameColumn?.name)
        testCycleNameColumn.name = `${t('entityName', { entity: getCycleName(projectConfig) })} `;

      const testCycleCodeColumn = columnsConfig.find((column) => column?.key === 'testCycleCode');
      if (testCycleCodeColumn?.name)
        testCycleCodeColumn.name = `${t('entityCode', { entity: getCycleName(projectConfig) })} `;

      const testFolderNameColumn = columnsConfig.find((column) => column?.key === 'testFolderName');
      if (testFolderNameColumn?.name)
        testFolderNameColumn.name = `${t('entityName', { entity: getTestFolderName(projectConfig) })} `;

      const testFolderCodeColumn = columnsConfig.find((column) => column?.key === 'testFolderCode');
      if (testFolderCodeColumn?.name)
        testFolderCodeColumn.name = `${t('entityCode', { entity: getTestFolderName(projectConfig) })} `;

      const testSuiteNameColumn = columnsConfig.find((column) => column?.key === 'testSuiteName');
      if (testSuiteNameColumn?.name)
        testSuiteNameColumn.name = `${t('entityName', { entity: getSuiteName(projectConfig) })} `;

      const testSuiteCodeColumn = columnsConfig.find((column) => column?.key === 'testSuiteCode');
      if (testSuiteCodeColumn?.name)
        testSuiteCodeColumn.name = `${t('entityCode', { entity: getSuiteName(projectConfig) })} `;

      const testRunCodeColumn = columnsConfig.find((column) => column?.key === 'testRunCode');
      if (testRunCodeColumn?.name)
        testRunCodeColumn.name = `${t('entityCode', { entity: getRunName(projectConfig) })} `;

      data = dataLoader?.data?.content.map((item) =>
        createTableData(item, columnsConfig, projectId, false, ENTITY_TYPES.DEFECT, users)
      );
    }
    return data;
  }, [dataLoader?.data?.content, getI18n().language]);

  const loading = useMemo(() => dataLoader?.loading, [dataLoader?.loading]);

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    getItems(pageInput, customFilters);
  };

  const refreshTable = () => getByPage({});

  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.DEFECT);

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) fil = { ...fil, ...userSavedFilters?.filterInput };

    let body = { ...fil, ...getPageFilterInput(pageInput), projectId };
    dataLoader.execute(body);
  };

  useEffect(() => {
    if (refreshStatus) {
      refreshTable();
      setRerenderKey((prevKey) => prevKey + 1);
    }
  }, [refreshStatus]);

  let columns = [getTableActions(optionsFn), ...selectedColumns];
  columns = disableResizingForColumns(columns, getNotResizedColumnList(ENTITY_TYPES.DEFECT));
  columns = translateColumns(columns, t);

  return (
    <>
      <Table
        key={rerenderKey}
        columns={columns}
        data={tData}
        pagination
        loading={loading}
        pageParams={pageParams}
        getByPage={getByPage}
        onFilter={onFilter}
        showCheckbox={true}
        entityType={ENTITY_TYPES.DEFECT}
      />
      {openCloneModal && (
        <CloneModal
          isOpen={openCloneModal}
          closeModal={(dd) => {
            setOpenCloneModal(false);
            isValidValue(dd) && refreshTable();
          }}
          data={selectedItem}
          entityType={ENTITY_TYPES.DEFECT}
          isBulk={false}
        />
      )}
    </>
  );
};

export default DefectReactTable;
