import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
//Api and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import UserApi from '../../../api/UserApi';
// Design System Imports
import { Input, Toggle, Password, Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
// Helper Import
import { getSessionUser, isSystemAdmin } from '../../../helpers/PriviligeHelper';
import {
  isValidValue,
  editModalMapping,
  generateRandomNumberCharacters,
  generateRandomAlpahabetCharacters,
  shuffleStringCharacters,
  isValidList
} from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';

const UserModal = (props) => {
  const { isOpen, data, closeModal, submitCallBack, passwordRule } = props;
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const sessionUser = getSessionUser();

  const [isLdapUser, setLdapUser] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [systemAdmin, setSystemAdmin] = useState(false);
  const [isgeneratePassword, setIsGeneratePassword] = useState(false);

  let entityRoleMessage = passwordRule?.message;

  const createEditUser = useApi({
    name: 'saving user',
    api: isValidValue(data) ? UserApi.edit : UserApi.create,
    onSuccess: () => {
      closeModal();
      submitCallBack(createEditUser?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => {
      const validationErrors = err?.response?.data?.validationErrors;
      // if there are validation errors
      if (isValidList(Object.keys(validationErrors))) {
        let messages = '';
        Object.keys(validationErrors).forEach((item) => {
          if (isValidValue(validationErrors[item])) messages += ' ' + validationErrors[item];
        });
        showErrorMes(messages);
      } else showErrorMes(err?.response?.data?.message); // if not, show message
    }
  });

  const hideSystemAdminCheckbox = () => {
    if (dataForm?.id === sessionUser?.id) return true;
    return sessionUser?.systemAdmin === false ? true : false;
  };
  /*
  const hideCanAddUserCheckbox = () => {
    if (dataForm?.id === sessionUser?.id) return true;
    if (sessionUser?.systemAdmin) return false;
    return sessionUser?.canAddUser === false ? true : false;
  };
  */

  const generatePassword = (status) => {
    setIsGeneratePassword(status);
    if (status === true) {
      let minChar = passwordRule?.minChar ? passwordRule?.minChar : 2;
      let minLen = passwordRule?.minLength ? passwordRule?.minLength : 8;

      let newPass = generateRandomAlpahabetCharacters(minChar) + generateRandomNumberCharacters(minLen - minChar);
      newPass = shuffleStringCharacters(newPass);
      setValue('password', newPass);
      setValue('matchingPassword', newPass);
    }
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setDataForm(data);
      setLdapUser(data?.ldapUser);
      setSystemAdmin(data?.systemAdmin + '' === 'true');
    }
  }, [data]);

  const onSubmit = (form) => {
    const body = {
      ...(isValidValue(data) && { id: data.id }),
      userName: form.userName,
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      password: form.password,
      matchingPassword: form.matchingPassword,
      systemAdmin: isValidValue(systemAdmin) ? systemAdmin : false
    };
    createEditUser.execute(body);
  };
  return (
    <>
      {isSystemAdmin() && (
        <Modal
          open={isOpen}
          close={() => closeModal}
          w={'md'}
        >
          <Modal.Title>{t('User')}</Modal.Title>
          <form
            className="flex flex-grow flex-col gap-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Modal.Content h={'md'}>
              <div className="flex flex-grow flex-col gap-5">
                <Input
                  required
                  disabled={isLdapUser}
                  name="userName"
                  label={t('User Name')}
                  placeholder={t(`Enter a User Name`)}
                  control={control}
                />
                <Input
                  required
                  disabled={isLdapUser}
                  name="firstName"
                  label={t('First Name')}
                  placeholder={t(`Enter a First Name`)}
                  control={control}
                />
                <Input
                  disabled={isLdapUser}
                  name="lastName"
                  label={t('Last Name')}
                  placeholder={t(`Enter a Last Name`)}
                  control={control}
                />
                <Input
                  required
                  disabled={isLdapUser}
                  name="email"
                  label={t('Email')}
                  placeholder={t(`Enter a Email`)}
                  control={control}
                />
                <Toggle
                  labelLeft
                  labelText={t('Generate new password and fill box')}
                  control={control}
                  name="generatepw"
                  passValue={(value) => generatePassword(value)}
                  value={isgeneratePassword}
                />
                {isValidValue(passwordRule?.message) && !isLdapUser && (
                  <span
                    className="ml-2 text-gray-600"
                    style={{ fontSize: 11 }}
                  >
                    {t(`entity_Role_Message`, { entity: entityRoleMessage })}
                  </span>
                )}
                {!isLdapUser && (
                  <>
                    <Password
                      required={!isValidValue(data)}
                      name="password"
                      label={t('Password')}
                      control={control}
                    />
                    <Password
                      required={!isValidValue(data)}
                      name="matchingPassword"
                      label={t('Confirm Password')}
                      control={control}
                    />
                  </>
                )}
                {!hideSystemAdminCheckbox() && (
                  <Toggle
                    labelLeft
                    labelText={t('Is System Admin')}
                    control={control}
                    name="systemAdmin"
                    value={systemAdmin}
                    passValue={(value) => setSystemAdmin(value)}
                  />
                )}
              </div>
            </Modal.Content>
            <Modal.Buttons>
              <Button
                id="userModal-cancel-button"
                secondary
                text={t('Cancel')}
                onClick={() => {
                  closeModal();
                  reset();
                }}
              />
              <Button
                id="userModal-submit-button"
                primary
                text={t('Submit')}
                type="submit"
                loading={createEditUser?.loading}
              />
            </Modal.Buttons>
          </form>
        </Modal>
      )}
    </>
  );
};

export default UserModal;
