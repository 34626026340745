import React, { useEffect, useState } from 'react';
// HOOKS
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation, useNavigate } from 'react-router-dom';
// COMPONENT IMPORTS
import { Modal } from '../modal';
import TablePagination from '../table/TablePagination';
// HELPERS
import { getEntityNameFromIndex, routeObj } from './SearchPortal.utils';
import { debounce } from 'lodash-es';
import { isValidValue } from '../../helpers/Helper';

import SearchResults from './components/SearchPortal.searchResults';
import { useForm } from 'react-hook-form';
import { Input } from '../../designSystem/DesignSystem';
import SearchApi from '../../api/SearchApi';
import { useApi } from '../../hooks/UseApi';
import { useTranslation } from 'react-i18next';

const SearchPortal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const projectId = query.get('projectId');

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);

  const [from, setFrom] = useState(0);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(10);

  useHotkeys('shift+f', () => {
    setTimeout(() => setOpen(true), 200);
  });

  const handleOnClick = (item) => {
    setOpen(false);
    const { _id, _index, _source } = item;

    const entity = getEntityNameFromIndex(_index);
    if (!entity) return;

    const routeVars = routeObj[entity];

    if (routeVars) {
      let pth = `${routeVars.route}?projectId=${projectId}&id=${_id}`;

      if (routeVars.params)
        Object.keys(routeVars.params).forEach((key) => {
          pth += `&${key}=${routeVars.params[key]}`;
        });
      const { majorVersion, rootId } = _source;

      switch (entity) {
        case 'testcase':
          navigate(
            `/test-cases?projectId=${projectId}&type=testCase&id=${rootId}&version=${majorVersion ? majorVersion : 1}`
          );
          break;
        case 'testrun':
          navigate(`/tasks?projectId=${projectId}&runId=${_id}`);
          break;
        case 'defect':
          navigate(`/tasks?projectId=${projectId}&defectIdF=${_id}`);
          break;
        default:
          navigate(`${pth}`);
          return;
      }
    }
  };

  const searchApiCall = useApi({
    name: 'search',
    api: SearchApi.search,
    onSuccess: (data) => {
      setResults(data?.hits?.hits);
      setTotal(data?.hits?.total?.value || 0);
      setIsLoading(false);
    },
    onError: (err) => {
      console.error(err);
      setIsLoading(false);
      setFrom(0);
    }
  });

  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const handleOnChange = (e) => {
    const db = debounce(() => setValue(e.target.value), 500);
    db();
    return db.cancel;
  };

  useEffect(() => {
    if (isValidValue(value) && value.length > 0) searchApiCall.execute({ value, size, from: 0 });
    else {
      setResults([]);
      setTotal(0);
    }
  }, [value]);

  return (
    <Modal
      open={open}
      close={() => setOpen(false)}
      w={'md'}
    >
      <Modal.Title>{t('Search')}</Modal.Title>
      <Modal.Content h={'md'}>
        <div className="flex flex-grow flex-col gap-4">
          <form className="flex flex-grow gap-4">
            <Input
              control={control}
              name="search"
              onKeyDown={true}
              onChangeFn={handleOnChange}
              // icon="search" // TODO: search icon is not working
              placeholder="Search..."
            />
          </form>
          <SearchResults
            results={results}
            isLoading={isLoading}
            handleOnClick={handleOnClick}
          />
          {results?.length ? (
            <TablePagination
              pageCount={total / size + 1}
              pageIndex={from / size + 1}
              pageParams={{ totalElements: total }}
              pageSize={size}
              gotoPage={(page) => searchApiCall.execute({ value, size, from: (page - 1) * size })}
              setPageSize={setSize}
            />
          ) : null}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default SearchPortal;
