import React, { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// API & HOOKS
import { useApi } from '../../../hooks/UseApi';
import DashboardApi from '../../../api/DashboardApi';
// DESIGN SYSTEM & COMPONENTS
import Markdown from '../../../components/markdown/Markdown';
import ChartAddFieldOptionContent from './ChartAddFieldOptionContent';
import { Modal } from '../../../components/modal';
import { Dropdown, Input, Toggle, Button } from '../../../designSystem/DesignSystem';
// HELPERS & CONSTANTS
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { CHART_TYPE, getDashboardEntityFields, showDateHistogramCheckbox } from '../helper/DashboardHelper';
import { isValidList, isValidValue, editModalMapping, formatItem } from '../../../helpers/Helper';
import { titleTranslation } from '../../../helpers/TranslationHelper';

const ChartAddModal = ({
  isOpen,
  data,
  closeModal,
  projectConfig,
  callAfterChartChanges,
  projects,
  projectId,
  commonConfigs
}) => {
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { t } = useTranslation();

  const [dataForm, setDataForm] = useState({});
  const [entityFields, setEntityFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [kibanaFields, setKibanaFields] = useState({});
  const [isDateHistogram, setDateHistogram] = useState(false);

  const isJustOne =
    isDateHistogram === true || dataForm?.type?.id === CHART_TYPE.fieldMetric || dataForm?.type?.id === CHART_TYPE.pie;
  const fieldLimit = isJustOne === true ? 1 : commonConfigs?.chartFieldLimits[dataForm?.type?.id];
  const chartFieldLimits = commonConfigs?.chartFieldLimits;

  const fetchKibanaFields = useApi({
    name: 'fetchKibanaFields',
    api: DashboardApi.fetchKibanaFields,
    autoLoad: false,
    onSuccess: () => {
      setKibanaFields(fetchKibanaFields.data);
      fillEntityFields(fetchKibanaFields.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const createEdit = useApi({
    name: 'saving chart',
    api: isValidValue(data) ? DashboardApi.updateChart : DashboardApi.addChart,
    onSuccess: () => {
      closeModal(createEdit?.data);
      if (callAfterChartChanges) callAfterChartChanges();
    },
    onError: (err) => showErrorMes(err)
  });

  const fillEntityFields = (allFields) => {
    let f = allFields?.entityFields ? allFields?.entityFields : kibanaFields?.entityFields;
    if (isValidList(f) && isValidValue(dataForm?.entityType?.id)) {
      setEntityFields(getDashboardEntityFields(f, dataForm?.entityType?.id));
    } else setEntityFields([]);
  };

  const fillKibanaFields = (proId) => {
    fetchKibanaFields.execute({ projectId: proId });
  };

  const onSubmit = (form) => {
    if (dataForm?.type?.id === CHART_TYPE.metric) {
      let conf = { projectId: dataForm?.project?.id };
      let input = {
        type: CHART_TYPE.metric,
        entityType: dataForm?.entityType?.id,
        description: form?.description,
        name: form?.name,
        configuration: conf
      };
      createEdit.execute({ ...input, id: dataForm?.id });
    } else {
      let arr3 = [];
      selectedFields.forEach((item) => {
        arr3.push({
          fieldKey: item?.fieldKeyValue,
          custom: item?.custom,
          topCount: item?.topCount,
          order: item?.order
        });
      });

      let conf = { projectId: dataForm?.project?.id, fields: arr3 };

      if (showDateHistogramCheckbox(dataForm)) conf['dateHistogram'] = isDateHistogram;

      let input = {
        name: form?.name,
        description: form?.description,
        type: dataForm?.type?.id,
        entityType: dataForm?.entityType?.id,
        configuration: conf
      };
      createEdit.execute({ ...input, id: dataForm?.id });
    }
  };

  const types = useMemo(() => {
    let arr = [];
    commonConfigs.chartTypes.map((x) => {
      arr.push({
        id: x?.key,
        key: x?.key,
        text: x?.text,
        value: x?.key,
        name: t(x?.text)
      });
    });
    return arr;
  }, [commonConfigs?.chartTypes]);

  const entityTypes = useMemo(() => {
    let arr = [];
    commonConfigs.entityTypes.map((x) => {
      arr.push({
        id: x,
        key: x,

        value: x,
        text: getArtifactName(projectConfig, x),
        name: getArtifactName(projectConfig, x)
      });
    });
    return arr;
  }, [commonConfigs?.entityTypes]);

  useEffect(() => {
    if (dataForm?.entityType?.id) {
      fillEntityFields();
    }
  }, [dataForm.entityType]);

  useEffect(() => {
    if (dataForm?.project?.id) fillKibanaFields(dataForm.project.id);
  }, [dataForm.project]);

  useEffect(() => {
    if (isValidValue(data)) {
      let project = projects.find((x) => x.id === data?.configuration?.projectId);
      fillKibanaFields(project?.id);
      fillEntityFields();
      let entityType = entityTypes.find((x) => x.id === data?.entityType);
      let proId = data?.configuration?.projectId;
      let type = types.find((x) => x.id === data?.type);

      data.project = project;
      data.entityType = entityType;
      data.projectId = proId;
      data.type = type;

      let arr = data?.configuration?.fields;

      if (isValidList(arr)) {
        arr.forEach((x, index) => {
          data['fieldKey' + index] = { id: x?.fieldKey, name: formatItem(x?.fieldKey) };
          data['topCount' + index] = parseInt(x?.topCount + '', 10);
          data['order' + index] = parseInt(x?.order + '', 10);
          data['custom' + index] = x?.custom;
        });
      }

      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setDataForm({ ...data, project, entityType, type });
      setDateHistogram(data?.configuration?.dateHistogram + '' === 'true' ? true : false);
    } else {
      setDataForm({ ...dataForm, projectId });
    }
  }, []);

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title> {titleTranslation(t('Chart'), data, '', t)}</Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'lg'}>
            <div className="mr-2.5 flex flex-grow flex-col gap-4">
              <Input
                required
                label={t('Name')}
                name={'name'}
                placeholder={t(`Enter`)}
                control={control}
              />
              <div className="flex gap-4">
                <Dropdown
                  clearable
                  required
                  control={control}
                  label={t('Chart Type')}
                  name={'type'}
                  placeholder={t('Select Chart Type')}
                  options={types}
                  passValue={(value) => {
                    setDataForm({ ...dataForm, type: value[0] });
                    if (!isValidList(value)) setValue('type', null);
                  }}
                  setValue={setValue}
                  selectedItem={data?.type}
                />
                <Dropdown
                  clearable
                  required
                  control={control}
                  label={t('Project (for fields)')}
                  name={'project'}
                  placeholder={t('Select Project')}
                  options={projects}
                  passValue={(value) => {
                    setDataForm({ ...dataForm, project: value[0] });
                    if (!isValidList(value)) {
                      setValue('project', null);
                    }
                  }}
                  selectedItem={data?.project}
                />
                <Dropdown
                  clearable
                  required
                  control={control}
                  label={t('Entity Type')}
                  placeholder={t('Select Entity Type')}
                  name={'entityType'}
                  options={entityTypes}
                  passValue={(value) => {
                    setDataForm({ ...dataForm, entityType: value[0] });
                    if (!isValidList(value)) {
                      setValue('entityType', null);
                    }
                  }}
                  selectedItem={data?.entityType}
                />
              </div>

              <div className="flex gap-2 pl-2">
                {showDateHistogramCheckbox(dataForm) && (
                  <>
                    <Toggle
                      disabled={false}
                      name={'dateHistogram'}
                      labelText={t('Date Histogram by created time (weekly)')}
                      labelRight
                      control={control}
                      value={isDateHistogram}
                      passValue={(value) => setDateHistogram(value)}
                    />
                  </>
                )}
              </div>
              {dataForm?.type?.id !== CHART_TYPE.metric && fieldLimit > 0 && entityFields.length > 0 && (
                <>
                  <ChartAddFieldOptionContent
                    chartFieldLimits={chartFieldLimits}
                    fieldLimit={fieldLimit}
                    allFields={entityFields}
                    selectedFields={selectedFields}
                    setSelectedFields={setSelectedFields}
                    dataForm={dataForm}
                    isJustOne={isJustOne}
                    control={control}
                    setValue={setValue}
                  />
                </>
              )}

              <Markdown
                control={control}
                label={t('Description')}
                name={'description'}
              />
            </div>
          </Modal.Content>

          {/* </div> */}
          <Modal.Buttons>
            <Button
              id="chartAddModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="chartAddModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEdit?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default ChartAddModal;
