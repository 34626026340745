export const AuditColumns = [
  {
    name: 'Created By',
    path: '$.createdBy',
    type: 'user',
    default: false,
    key: 'createdBy'
  },
  {
    name: 'Created Time',
    path: '$.createdTime',
    type: 'date',
    default: false,
    key: 'createdTime'
  },
  {
    name: 'Modified By',
    path: '$.modifiedBy',
    type: 'user',
    default: false,
    key: 'modifiedBy'
  },
  {
    name: 'Modified Time',
    path: '$.modifiedTime',
    type: 'date',
    default: false,
    key: 'modifiedTime'
  }
];

export const TableColumns = {
  defect: [
    {
      name: 'Code',
      path: '$.code',
      type: 'open-modal',
      params: {
        name: 'defectId',
        value: '$.id'
      },
      default: true,
      key: 'code'
    },
    {
      name: 'Summary',
      path: '$.summary',
      type: 'open-modal',
      params: {
        name: 'defectId',
        value: '$.id'
      },
      default: true,
      key: 'summary'
    },
    {
      name: 'Assignee',
      path: '$.assignee.name',
      type: 'text',
      default: true,
      key: 'assigneeFirstName'
    },
    {
      name: 'Status',
      path: '$.status.name',
      type: 'status',
      default: true,
      key: 'status'
    },
    {
      name: 'Status (jira)',
      path: '$.jiraStatus',
      type: 'jiraStatus',
      default: false,
      key: 'jiraStatus'
    },
    {
      name: 'Priority (jira)',
      path: '$.jiraPriority',
      type: 'jiraPriority',
      default: false,
      key: 'jiraPriority'
    },
    {
      name: 'Jira Key',
      path: '$.jiraKey',
      type: 'jiraKey',
      default: false,
      key: 'jiraKey'
    },
    {
      name: 'Priority',
      path: '$.priority.name',
      type: 'text',
      default: true,
      key: 'priority'
    },
    {
      name: 'Type',
      path: '$.type.name',
      type: 'text',
      default: true,
      key: 'type'
    },
    {
      name: 'Test Cycle Code',
      path: '$.testCycle.code',
      type: 'link',
      page: 'planning',
      default: true,
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.testCycle.id' }
        }
      ],
      key: 'testCycleCode'
    },
    {
      name: 'Test Cycle Name',
      path: '$.testCycle.name',
      type: 'link',
      page: 'planning',
      default: true,
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.testCycle.id' }
        }
      ],
      key: 'testCycleName'
    },
    {
      name: 'Test Run',
      path: '$.testRun.code',
      type: 'link',
      page: 'tasks',
      default: true,
      pageParams: [
        {
          name: 'runId',
          value: { path: '$.testRun.id' }
        }
      ],
      key: 'testRunCode'
    },
    {
      name: 'Test Case Code',
      path: '$.testCase.code',
      type: 'link',
      page: 'test-cases',
      pageParams: [
        {
          name: 'type',
          value: 'testCase'
        },
        {
          name: 'id',
          value: { path: '$.testCase.rootId' }
        },
        {
          name: 'version',
          value: { path: '$.testCase.majorVersion' }
        }
      ],
      key: 'testCaseCode'
    },
    {
      name: 'Test Case Name',
      path: '$.testCase.name',
      type: 'link',
      page: 'test-cases',
      pageParams: [
        {
          name: 'type',
          value: 'testCase'
        },
        {
          name: 'id',
          value: { path: '$.testCase.rootId' }
        },
        {
          name: 'version',
          value: { path: '$.testCase.majorVersion' }
        }
      ],
      key: 'testCaseName'
    },
    {
      name: 'Test Folder Code',
      path: '$.testFolder.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testFolder'
        },
        {
          name: 'id',
          value: { path: '$.testFolder.id' }
        }
      ],
      key: 'testFolderCode'
    },
    {
      name: 'Test Folder Name',
      path: '$.testFolder.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testFolder'
        },
        {
          name: 'id',
          value: { path: '$.testFolder.id' }
        }
      ],
      key: 'testFolderName'
    },
    {
      name: 'Test Suite Code',
      path: '$.testSuite.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testSuite'
        },
        {
          name: 'id',
          value: { path: '$.testSuite.id' }
        }
      ],
      key: 'testSuiteCode'
    },
    {
      name: 'Test Suite Name',
      path: '$.testSuite.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testSuite'
        },
        {
          name: 'id',
          value: { path: '$.testSuite.id' }
        }
      ],
      key: 'testSuiteName'
    },
    ...AuditColumns
  ],
  testCycle: [
    {
      name: 'Code',
      path: '$.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.id' }
        }
      ],
      default: true,
      key: 'code'
    },
    {
      name: 'Name',
      path: '$.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.id' }
        }
      ],
      default: true,
      key: 'name'
    },
    {
      name: 'Parent Code',
      path: '$.parent.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.parent.id' }
        }
      ],
      default: true,
      key: 'parentCode'
    },
    {
      name: 'Parent Name',
      path: '$.parent.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.parent.id' }
        }
      ],
      default: true,
      key: 'parentName'
    },
    {
      name: 'Status',
      path: '$.status.name',
      type: 'status',
      key: 'status',
      default: true
    },
    {
      name: 'Test Folder Code',
      path: '$.testFolder.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testFolder'
        },
        {
          name: 'id',
          value: { path: '$.testFolder.id' }
        }
      ],
      key: 'testFolderCode'
    },
    {
      name: 'Test Folder Name',
      path: '$.testFolder.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testFolder'
        },
        {
          name: 'id',
          value: { path: '$.testFolder.id' }
        }
      ],
      key: 'testFolderName'
    },
    ...AuditColumns
  ],
  testSuite: [
    {
      name: 'Code',
      path: '$.code',
      type: 'link',
      page: 'planning',
      default: true,
      pageParams: [
        {
          name: 'type',
          value: 'testSuite'
        },
        {
          name: 'id',
          value: { path: '$.id' }
        }
      ],
      key: 'code'
    },
    {
      name: 'Name',
      path: '$.name',
      type: 'link',
      page: 'planning',
      default: true,
      pageParams: [
        {
          name: 'type',
          value: 'testSuite'
        },
        {
          name: 'id',
          value: { path: '$.id' }
        }
      ],
      key: 'name'
    },
    {
      name: 'Parent Code',
      path: '$.parent.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.parent.id' }
        }
      ],
      default: true,
      key: 'parentCode'
    },
    {
      name: 'Parent Name',
      path: '$.parent.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.parent.id' }
        }
      ],
      default: true,
      key: 'parentName'
    },

    {
      name: 'Test Cycle Code',
      path: '$.testCycle.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.testCycle.id' }
        }
      ],
      key: 'testCycleCode',
      default: true
    },
    {
      name: 'Test Cycle Name',
      path: '$.testCycle.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.testCycle.id' }
        }
      ],
      key: 'testCycleName',
      default: true
    },
    {
      name: 'Test Folder Code',
      path: '$.testFolder.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testFolder'
        },
        {
          name: 'id',
          value: { path: '$.testFolder.id' }
        }
      ],
      key: 'testFolderCode'
    },
    {
      name: 'Test Folder Name',
      path: '$.testFolder.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testFolder'
        },
        {
          name: 'id',
          value: { path: '$.testFolder.id' }
        }
      ],
      key: 'testFolderName'
    },
    ...AuditColumns
  ],
  requirement: [
    {
      name: 'Code',
      path: '$.code',
      type: 'link',
      page: 'requirements',
      pageParams: [
        {
          name: 'type',
          value: 'requirement'
        },
        {
          name: 'id',
          value: { path: '$.id' }
        }
      ],
      default: true,
      key: 'code'
    },
    {
      name: 'Name',
      path: '$.name',
      type: 'link',
      page: 'requirements',
      pageParams: [
        {
          name: 'type',
          value: 'requirement'
        },
        {
          name: 'id',
          value: { path: '$.id' }
        }
      ],
      default: true,
      key: 'name'
    },
    {
      name: 'Folder Name',
      path: '$.parent.name',
      type: 'link',
      page: 'requirements',
      pageParams: [
        {
          name: 'type',
          value: 'folder'
        },
        {
          name: 'id',
          value: { path: '$.parent.id' }
        }
      ],
      default: true,
      key: 'parentName'
    },
    {
      name: 'Releases',
      path: '$.releases[*].name',
      type: 'link',
      page: 'releases',
      pageParams: [
        {
          name: 'type',
          value: 'release'
        },
        {
          name: 'id',
          value: { path: '$.releases[*].id' }
        }
      ],
      default: false,
      key: 'releases'
    },
    {
      name: 'Status',
      path: '$.status.name',
      type: 'status',
      default: true,
      key: 'status'
    },
    {
      name: 'Status (jira)',
      path: '$.jiraStatus',
      type: 'jiraStatus',
      default: false,
      key: 'jiraStatus'
    },
    {
      name: 'Priority (jira)',
      path: '$.jiraPriority',
      type: 'jiraPriority',
      default: false,
      key: 'jiraPriority'
    },
    {
      name: 'Jira Key',
      path: '$.jiraKey',
      type: 'jiraKey',
      default: false,
      key: 'jiraKey'
    },
    {
      name: 'Folder Code',
      path: '$.parent.code',
      type: 'link',
      page: 'requirements',
      pageParams: [
        {
          name: 'type',
          value: 'folder'
        },
        {
          name: 'id',
          value: { path: '$.parent.id' }
        }
      ],
      key: 'parentCode'
    },
    {
      name: 'Priority',
      path: '$.priority.name',
      type: 'text',
      key: 'priority'
    },
    {
      name: 'Type',
      path: '$.type.name',
      type: 'text',
      key: 'type'
    },
    {
      name: 'Contribution',
      path: '$.contribution',
      type: 'contribution',
      default: true,
      key: 'contribution'
    },
    {
      name: 'Coverage',
      path: '$.executionStats',
      type: 'coverage',
      default: true,
      key: 'coverage',
      textAlign: 'center'
    },
    {
      name: 'Description',
      path: '$.description',
      type: 'text',
      key: 'description'
    },
    ...AuditColumns
  ],
  testRun: [
    {
      name: 'Code',
      path: '$.code',
      type: 'open-modal',
      params: {
        name: 'runId',
        value: '$.id'
      },
      default: true,
      key: 'code'
    },
    {
      name: 'Summary',
      path: '$.summary',
      type: 'open-modal',
      params: {
        name: 'runId',
        value: '$.id'
      },
      default: true,
      key: 'summary'
    },
    {
      name: 'Tester',
      path: '$.tester.name',
      type: 'text',
      default: true,
      key: 'testerFirstName'
    },
    {
      name: 'Executions',
      path: '$.executionCount',
      type: 'number',
      default: true,
      key: 'executionCount'
    },
    {
      name: 'Defects',
      path: '$.defectCount',
      type: 'number',
      default: true,
      key: 'defectCount'
    },
    {
      name: 'Start Date',
      path: '$.startDate',
      type: 'date',
      key: 'startDate'
    },
    {
      name: 'End Date',
      path: '$.endDate',
      type: 'date',
      key: 'endDate'
    },
    {
      name: 'Test Case Code',
      path: '$.testCase.code',
      type: 'link',
      page: 'test-cases',
      pageParams: [
        {
          name: 'type',
          value: 'testCase'
        },
        {
          name: 'id',
          value: { path: '$.testCase.rootId' }
        },
        {
          name: 'version',
          value: { path: '$.testCase.majorVersion' }
        }
      ],
      key: 'testCaseCode'
    },
    {
      name: 'Test Case Name',
      path: '$.testCase.name',
      type: 'link',
      page: 'test-cases',
      pageParams: [
        {
          name: 'type',
          value: 'testCase'
        },
        {
          name: 'id',
          value: { path: '$.testCase.rootId' }
        },
        {
          name: 'version',
          value: { path: '$.testCase.majorVersion' }
        }
      ],
      key: 'testCaseName'
    },
    {
      name: 'Test Cycle Code',
      path: '$.testCycle.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.testCycle.id' }
        }
      ],
      key: 'testCycleCode'
    },
    {
      name: 'Test Cycle Name',
      path: '$.testCycle.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testCycle'
        },
        {
          name: 'id',
          value: { path: '$.testCycle.id' }
        }
      ],
      key: 'testCycleName'
    },
    {
      name: 'Test Folder Code',
      path: '$.testFolder.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testFolder'
        },
        {
          name: 'id',
          value: { path: '$.testFolder.id' }
        }
      ],
      key: 'testFolderCode'
    },
    {
      name: 'Test Folder Name',
      path: '$.testFolder.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testFolder'
        },
        {
          name: 'id',
          value: { path: '$.testFolder.id' }
        }
      ],
      key: 'testFolderName'
    },
    {
      name: 'Test Suite Code',
      path: '$.testSuite.code',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testSuite'
        },
        {
          name: 'id',
          value: { path: '$.testSuite.id' }
        }
      ],
      key: 'testSuiteCode'
    },
    {
      name: 'Test Suite Name',
      path: '$.testSuite.name',
      type: 'link',
      page: 'planning',
      pageParams: [
        {
          name: 'type',
          value: 'testSuite'
        },
        {
          name: 'id',
          value: { path: '$.testSuite.id' }
        }
      ],
      key: 'testSuiteName'
    },
    ...AuditColumns
  ],
  testCase: [
    {
      name: 'Code',
      path: '$.code',
      default: true,
      type: 'link',
      page: 'test-cases',
      pageParams: [
        {
          name: 'type',
          value: 'testCase'
        },
        {
          name: 'id',
          value: { path: '$.rootId' }
        },
        {
          name: 'version',
          value: { path: '$.majorVersion' }
        }
      ],
      key: 'code'
    },
    {
      name: 'Name',
      path: '$.name',
      default: true,
      type: 'link',
      page: 'test-cases',
      pageParams: [
        {
          name: 'type',
          value: 'testCase'
        },
        {
          name: 'id',
          value: { path: '$.rootId' }
        },
        {
          name: 'version',
          value: { path: '$.majorVersion' }
        }
      ],
      key: 'name'
    },
    {
      name: 'Container',
      path: '$.parent.name',
      default: true,
      type: 'link',
      page: 'test-cases',
      pageParams: [
        {
          name: 'type',
          value: 'testContainer'
        },
        {
          name: 'id',
          value: { path: '$.parent.id' }
        }
      ],
      key: 'parentName'
    },
    {
      name: 'Version',
      path: '$.majorVersion',
      default: true,
      type: 'text',
      key: 'majorVersion'
    },
    {
      name: 'Approval',
      path: '$.approveType',
      default: true,
      type: 'text',
      key: 'approveType'
    },
    {
      name: 'Status',
      path: '$.lastTestRunExecution.status',
      default: true,
      type: 'status',
      key: 'lastTestRunExecutionStatus'
    },
    {
      name: 'Type',
      path: '$.type.name',
      default: true,
      type: 'text',
      key: 'type'
    },
    {
      name: 'Container Code',
      path: '$.parent.code',
      type: 'link',
      page: 'test-cases',
      pageParams: [
        {
          name: 'type',
          value: 'testContainer'
        },
        {
          name: 'id',
          value: { path: '$.parent.id' }
        }
      ],
      key: 'parentCode'
    },
    {
      name: 'Description',
      path: '$.description',
      type: 'text',
      key: 'description'
    },
    ...AuditColumns
  ],
  release: [
    {
      name: 'Code',
      path: '$.code',
      type: 'link',
      page: 'releases',
      pageParams: [
        {
          name: 'type',
          value: 'release'
        },
        {
          name: 'id',
          value: { path: '$.id' }
        }
      ],
      default: true,
      key: 'code'
    },
    {
      name: 'Name',
      path: '$.name',
      type: 'link',
      page: 'releases',
      pageParams: [
        {
          name: 'type',
          value: 'release'
        },
        {
          name: 'id',
          value: { path: '$.id' }
        }
      ],
      default: true,
      key: 'name'
    },
    {
      name: 'Status',
      path: '$.status.name',
      type: 'status',
      default: true,
      key: 'status'
    },
    {
      name: 'Start Date',
      path: '$.startDate',
      type: 'date',
      key: 'startDate'
    },
    {
      name: 'End Date',
      path: '$.endDate',
      type: 'date',
      key: 'endDate'
    },
    {
      name: 'Description',
      path: '$.description',
      type: 'text',
      key: 'description'
    },
    ...AuditColumns
  ],
  project: [
    {
      name: 'Prefix',
      path: '$.prefix',
      type: 'text',
      default: true,
      key: 'prefix'
    },
    {
      name: 'Name',
      path: '$.name',
      type: 'text',
      default: true,
      key: 'name'
    },
    {
      name: 'Status',
      path: '$.status.name',
      type: 'status',
      default: true,
      key: 'status'
    },
    {
      name: 'Start Date',
      path: '$.startDate',
      type: 'date',
      key: 'startDate'
    },
    {
      name: 'End Date',
      path: '$.endDate',
      type: 'date',
      key: 'endDate'
    },
    {
      name: 'Description',
      path: '$.description',
      type: 'text',
      key: 'description'
    },
    ...AuditColumns
  ]
};
