import { useState, useEffect } from 'react';
import { useApi } from './UseApi';
import UserApi from '../api/UserApi';
import { showErrorMes } from '../helpers/NotificationHelper';

const UseAllUsers = (projectId) => {
  let [values, setValues] = useState([]);

  // <-- API SETUP -->
  const projectUserLoader = useApi({
    name: 'Get all project users',
    api: UserApi.getProjectUsersWithAdmins,
    onSuccess: () => setValues(projectUserLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const allUserLoader = useApi({
    name: 'fetching all users',
    api: UserApi.getAllUsers,
    autoLoad: false,
    onSuccess: () => setValues(allUserLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (projectId) {
      const body = { projectId, deleted: false, isAdminInclude: true, isUserGroupInclude: true };
      projectUserLoader.execute(body);
    } else {
      const body = { projectId, deleted: false, isAdminInclude: true };
      allUserLoader.execute(body);
    }
  }, [projectId]);
  return values;
};

export default UseAllUsers;
