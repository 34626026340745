import { useEffect, useMemo } from 'react';
import { isEmpty, uniqueId } from 'lodash-es';
// Context Imports
import { useAppDispatch, useApp } from '../context/AppContext';
import { saveJiraConfig, addRequest, removeRequest } from '../context/ProjectActions';
// Api Imports
import JiraApi from '../api/JiraApi';
import { showErrorMes } from '../helpers/NotificationHelper';
import { useApi } from './UseApi';

const UseJiraConfig = (projectId) => {
  const app = useApp();
  const activeRequests = app?.activeRequests || [];
  const jiraConfig = app?.jiraConfig || {};
  const dispatch = useAppDispatch();

  const id = useMemo(() => uniqueId(), []);

  const filterPredicate = (item) => item.projectId !== projectId && item?.type !== 'jiraConfig';

  const getJiraConfigLoader = useApi({
    name: 'Get Jira Config',
    api: JiraApi.getActiveJiraConfig,
    autoLoad: false,
    onSuccess: () => dispatch(saveJiraConfig(projectId, getJiraConfigLoader?.data)),
    onError: (err) => showErrorMes(err),
    onFinally: () => dispatch(removeRequest(filterPredicate))
  });

  useEffect(() => {
    if (projectId && jiraConfig?.projectId !== projectId)
      dispatch(addRequest({ order: id, type: 'jiraConfig', projectId }));
  }, [projectId]);

  useEffect(() => {
    if (activeRequests?.length > 0) {
      const allRequestsForEntity = activeRequests
        .filter((item) => item.projectId === projectId && item.type === 'jiraConfig')
        .map((item) => item.order);
      if (
        (allRequestsForEntity || []).indexOf(id) === 0 &&
        isEmpty(jiraConfig) &&
        jiraConfig?.projectId !== projectId
      ) {
        getJiraConfigLoader.execute({ projectId });
      }
    }
  }, [activeRequests]);

  return jiraConfig;
};

export default UseJiraConfig;
