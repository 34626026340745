import React, { useEffect, useMemo, useState } from 'react';
import { CopySimple, MagnifyingGlassPlus } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getI18n, useTranslation } from 'react-i18next';
// API & HOOKS
import UseAllUsers from '../../hooks/UseAllUsers';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseTableColumns from '../../hooks/UseTableColumns';
import UseTableHelper from '../../hooks/UseTableHelper';
import UseUserTablePreferences from '../../hooks/UseUserTablePreferences';
import { useApi } from '../../hooks/UseApi';
import TestRunApi from '../../api/TestRunApi';
import TestRunExecutionApi from '../../api/TestRunExecutionApi';
// DESIGN SYSTEM
import { Dropdown } from '../../designSystem/DesignSystem';
// COMPONENTS
import CloneModal from '../../components/clone/CloneModal';
import Table from '../../components/table/Table';
// HELPER & CONSTANTS
import { TableColumns } from '../../components/table/TableColumns';
import { ENTITY_TYPES, RunExecutionStatus, PRIVILEGES } from '../../constants';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../helpers/ButtonConstants';
import EnumHelper from '../../helpers/EnumHelper';
import { getPageFilterInput, isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { isClonePrivileged, hasPrivilege } from '../../helpers/PriviligeHelper';
import { getDefectName } from '../../helpers/ArtifactNameHelper';
import {
  DEFAULT_PAGE_PARAMS,
  disableResizingForColumns,
  getNotResizedColumnList,
  getTableActions,
  handleSelectedRow,
  translateColumns
} from '../../helpers/TableHelper';
import { translateArrItemName } from '../../helpers/TranslationHelper';

const AllTestRunReactTable = ({ filter, projectConfig, setRefreshTable, refreshTable, setRefreshStatus }) => {
  const { t } = useTranslation();
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const navigate = useNavigate();

  const { projectId } = UseQueryProject('projectId');
  const { selectedColumns } = UseTableColumns(projectId, ENTITY_TYPES.TEST_RUN);
  const { createTableData } = UseTableHelper();
  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.TEST_RUN);
  const users = UseAllUsers();

  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('code', 'DESC'));
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [customFilters, setCustomFilters] = useState({});
  const [statusObj, setStatusObj] = useState({});
  const [rerenderKey, setRerenderKey] = useState(0);

  const getTestRuns = useApi({
    name: 'fetching test runs',
    api: TestRunApi.getAllByTester,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: getTestRuns?.data.size,
        pageSize: getTestRuns?.data.pageSize,
        totalPages: getTestRuns?.data?.totalPages,
        totalElements: getTestRuns?.data?.totalElements
      }));
      setRefreshTable(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const quickExecution = useApi({
    name: 'saving test run',
    api: TestRunExecutionApi.quickExecute,
    onSuccess: () => {
      setRefreshStatus(true), setRefreshTable(true);
    },
    onError: (err) => showErrorMes(err)
  });

  const tData = useMemo(() => {
    let data = [];

    if (getTestRuns?.data?.content) {
      const columnsConfig = TableColumns[ENTITY_TYPES.TEST_RUN];
      // Defect name is  a configurable field, so it should be updated
      const defectColumn = columnsConfig.find((column) => column?.name === 'Defects');
      if (defectColumn?.name) defectColumn.name = getDefectName(projectConfig);

      data = getTestRuns?.data?.content.map((item) =>
        createTableData(item, columnsConfig, projectId, false, ENTITY_TYPES.TEST_RUN, users)
      );
    }
    return data;
  }, [getTestRuns?.data?.content, getI18n().language]);

  const loading = useMemo(() => getTestRuns?.loading, [getTestRuns?.loading]);

  const optionsFn = (row) => {
    return [
      {
        name: t('Details'),
        icon: (weight) => (
          <MagnifyingGlassPlus
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          navigate(`/tasks?projectId=${projectId}&runId=${row.original.id}`);
        }
      },
      ...(isClonePrivileged(ENTITY_TYPES.TEST_RUN, projectId)
        ? [
            {
              name: t('Clone'),
              icon: (weight) => (
                <CopySimple
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: false,
              onClick: () => {
                handleSelectedRow(getTestRuns?.data?.content, row, setSelectedItem);
                setOpenCloneModal(true);
              }
            }
          ]
        : [])
    ];
  };

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    getItems(pageInput, customFilters);
  };

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) {
      fil = { ...fil, ...userSavedFilters?.filterInput };
    }
    let body = { ...fil, ...getPageFilterInput(pageInput), projectId };
    getTestRuns.execute(body);
  };

  const refreshTableData = () => {
    getByPage({});
  };
  let quickTestRunOptions = translateArrItemName(Object.values(RunExecutionStatus), t);

  const getTableDropdownProps = (code) => ({
    control,
    options: quickTestRunOptions || [],
    selectedItem: Object.values(RunExecutionStatus).find((item) => item.text === statusObj[code]),
    name: `status-${code}`,
    key: `status-${code}`,
    passValue: (value) => {
      handleChangeStatus(value[0]?.value, code);
    }
  });

  const setInitialFormValues = () => {
    let executionStatus = {};
    getTestRuns?.data?.content?.forEach((item) => {
      const id = item.id;
      executionStatus[id] = EnumHelper('RunExecutionStatus', item?.lastTestRunExecution?.status);
    });

    setStatusObj(executionStatus);
  };

  const handleChangeStatus = (status, item) => {
    if (status !== 'NOT_EXECUTED' && status !== 'Not Executed') {
      let execution = {
        testRunId: item,
        quickRunExecution: true,
        status
      };
      quickExecution.execute(execution);
    }
  };

  let columns = [getTableActions(optionsFn), ...selectedColumns];
  columns = disableResizingForColumns(columns, getNotResizedColumnList(ENTITY_TYPES.TEST_RUN));
  columns = translateColumns(columns, t);

  useEffect(() => {
    if (refreshTable) {
      refreshTableData();
      setRerenderKey((prevKey) => prevKey + 1);
    }
  }, [refreshTable]);

  useEffect(() => {
    const executionStatus = Object.values(RunExecutionStatus);
    setInitialFormValues({ executionStatus });
  }, [getTestRuns?.data?.content]);

  return (
    <>
      <Table
        key={rerenderKey}
        columns={[
          ...columns,
          {
            Header: t('Status'),
            accessor: 'status',
            minWidth: 150,
            width: 250,
            Cell: (cell) => {
              const code = cell.row.original.id;
              return (
                <Dropdown
                  {...getTableDropdownProps(code)}
                  disabled={!hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_EXECUTION)}
                />
              );
            }
          }
        ]}
        data={tData}
        pagination
        loading={loading}
        pageParams={pageParams}
        getByPage={getByPage}
        onFilter={onFilter}
        showCheckbox={true}
        entityType={ENTITY_TYPES.TEST_RUN}
        rowHeight={'h-16'}
      />
      {openCloneModal && (
        <CloneModal
          isOpen={openCloneModal}
          closeModal={(data) => {
            setOpenCloneModal(false);
            if (isValidValue(data)) {
              setRefreshTable(true);
            }
          }}
          data={selectedItem}
          entityType={ENTITY_TYPES.TEST_RUN}
          isBulk={false}
        />
      )}
    </>
  );
};

export default AllTestRunReactTable;
