import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/UseApi';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useLocation } from 'react-router-dom';
import DefaultReportsApi from '../../api/DefaultReportsApi';
import BasicTable from '../../components/table/BasicTable';
import { getTableDataFromSuiteTestCaseExecutionCountAggregation, findBuckets } from './ReportHelper';
import { SidePaneRightContent } from '../../components/Layout';
import { Heading } from '../../designSystem/DesignSystem';
import { useTranslation } from 'react-i18next';

const DefaultReportsPage = ({ projectId, defaultReports }) => {
  const [selectedDefaultReport, setSelectedDefaultReport] = useState();
  const [tableData, setTableData] = useState([]);

  const location = useLocation();
  const selectedReportId = location.pathname.split('/')[3];
  const { t } = useTranslation();

  const cycleUserDefectCountApi = useApi({
    name: 'cycleUserDefectCount report',
    api: DefaultReportsApi.cycleUserDefectCount,
    onSuccess: () => {
      setTableData(findBuckets(cycleUserDefectCountApi?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const suiteTestCaseExecutionCountApi = useApi({
    name: 'suiteTestCaseExecutionCount report',
    api: DefaultReportsApi.suiteTestCaseExecutionCount,
    onSuccess: () => {
      setTableData(getTableDataFromSuiteTestCaseExecutionCountAggregation(suiteTestCaseExecutionCountApi?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    setSelectedDefaultReport(defaultReports?.filter((report) => report?.id === selectedReportId)[0]);
  }, [location]);

  useEffect(() => {
    switch (selectedDefaultReport?.api) {
      case 'cycleUserDefectCount':
        cycleUserDefectCountApi?.execute({ projectId });
        break;
      case 'suiteTestCaseExecutionCount':
        suiteTestCaseExecutionCountApi?.execute({ projectId });
        break;
      default:
        setTableData([]);
        break;
    }
  }, [selectedDefaultReport]);

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading.Group
          type="medium"
          text={t(selectedDefaultReport?.name) || ''}
        ></Heading.Group>
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Main>
        <SidePaneRightContent.Center>
          <BasicTable
            columns={selectedDefaultReport?.columns || []}
            data={tableData}
          />
        </SidePaneRightContent.Center>
      </SidePaneRightContent.Main>
    </>
  );
};

export default DefaultReportsPage;
