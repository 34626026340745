import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
//MODALS
import DefectDetailsModal from '../../pages/defect/Modal/DefectDetailsModal';
import TestRunDetailsModal from '../../pages/tasks/modal/TestRunDetailsModal';
//Design && Api Import and Hook
import UseQueryProject from '../../hooks/UseQueryProject';
import { FullPageContainer } from '../Layout';

const DetailsModals = () => {
  const { projectId } = UseQueryProject('projectId');
  const [searchParams, setSearchParams] = useSearchParams();
  const defectId = useMemo(() => searchParams?.get('defectId'), [searchParams]);
  const runId = useMemo(() => searchParams?.get('runId'), [searchParams]);

  const closeDetailsModal = (name) => {
    searchParams.delete(name);
    setSearchParams(searchParams);
  };

  const isTestRunModalVisible = runId && !defectId;

  return (
    <>
      {defectId && (
        <FullPageContainer p="p-1">
          <div className="flex-grow flex-col">
            <DefectDetailsModal
              onClose={() => closeDetailsModal('defectId')}
              defectId={defectId}
              projectId={projectId}
            />
          </div>
        </FullPageContainer>
      )}
      {isTestRunModalVisible && (
        <FullPageContainer p="p-1">
          <div className="flex-grow flex-col">
            <TestRunDetailsModal
              close={() => closeDetailsModal('runId')}
              runId={runId}
              projectId={projectId}
            />
          </div>
        </FullPageContainer>
      )}
    </>
  );
};

export default DetailsModals;
