import React, { useState } from 'react';
import ContainerApi from '../../api/ContainerApi';

import DefectApi from '../../api/DefectApi';
import FolderApi from '../../api/FolderApi';
import ReleaseApi from '../../api/ReleaseApi';
import RequirementApi from '../../api/RequirementApi';
import TestCaseApi from '../../api/TestCaseApi';
import TestCycleApi from '../../api/TestCycleApi';
import TestFolderApi from '../../api/TestFolderApi';
import TestSuiteApi from '../../api/TestSuiteApi';
import { useTranslation } from 'react-i18next';
// Helper Import
import { ENTITY_TYPES, PRIVILEGES, TestCaseApproveType } from '../../constants';
import {
  getArtifactName,
  getFolderName,
  getReleaseName,
  getRequirementName,
  getTestCaseName
} from '../../helpers/ArtifactNameHelper';
import { isValidValue } from '../../helpers/Helper';
import { INFO_MESSAGES, showError, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import {
  hasPrivilege,
  isClonePrivileged,
  isDeletePrivileged,
  isViewReport,
  statusUpdatePrivileged
} from '../../helpers/PriviligeHelper';
import { isApproved } from '../../helpers/TestCaseHelper';
// Api Import and Hook
import { useApi } from '../../hooks/UseApi';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import DefectModal from '../../pages/defect/Modal/DefectModal';
import ReleaseModal from '../../pages/release/modal/ReleaseModal';
import FolderModal from '../../pages/requirement/modal/FolderModal';
import RequirementModal from '../../pages/requirement/modal/RequirementModal';
import SubscribeButton from '../../pages/subscription/SubscribeButton';
import TestRunModal from '../../pages/tasks/modal/TestRunModal';
// Component Imports
import TestCaseModal from '../../pages/testCase/modal/TestCaseModal';
import TestContainerModal from '../../pages/testCase/modal/TestContainerModal';
import TestCycleModal from '../../pages/testPlanning/testCycle/TestCycleModal';
import TestFolderModal from '../../pages/testPlanning/testFolder/TestFolderModal';
import TestSuiteModal from '../../pages/testPlanning/testSuite/TestSuiteModal';
import CloneModal from '../clone/CloneModal';
import ConfirmationModal from '../confirm/ConfirmationModal';
// Design System Import
import { Button, DropDownNew } from '../../designSystem/DesignSystem';
import TestPlanningReportModal from '../report/planning/TestPlanningReportModal';
import RequirementReportModal from '../report/requirement/RequirementReportModal';
import VersionDropdown from '../version/VersionDropdown';
import { setRefreshTree } from '../../context/ProjectActions';
import { useAppDispatch } from '../../context/AppContext';

const EntityActionButtonGroup = ({
  data,
  entityType,
  submitCallBack,
  submitCallBackAfterDeletion,
  extraButtons,
  submitCallBackAfterClone,
  submitCallBackVersion,
  jiraConfig,
  closeModal,
  users
}) => {
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openSendApproveModal, setOpenSendApproveModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [isExistDraft, setExistDraft] = useState(false);
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const dispatch = useAppDispatch();

  const deleteApi = () => {
    switch (entityType) {
      case ENTITY_TYPES.RELEASE:
        return ReleaseApi.delete;
      case ENTITY_TYPES.FOLDER:
        return FolderApi.delete;
      case ENTITY_TYPES.REQUIREMENT:
        return RequirementApi.delete;
      case ENTITY_TYPES.TEST_CONTAINER:
        return ContainerApi.delete;
      case ENTITY_TYPES.TEST_CASE:
        return TestCaseApi.delete;
      case ENTITY_TYPES.TEST_FOLDER:
        return TestFolderApi.delete;
      case ENTITY_TYPES.TEST_CYCLE:
        return TestCycleApi.delete;
      case ENTITY_TYPES.TEST_SUITE:
        return TestSuiteApi.delete;
      case ENTITY_TYPES.DEFECT:
        return DefectApi.delete;
      default:
        return null;
    }
  };

  const deleteEntity = useApi({
    name: 'delete item',
    api: deleteApi(),
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      afterSuccessDelete();
      showSuccessMes(INFO_MESSAGES.delete);
    },
    onError: (err) => {
      if (err.response.data.message === 'Release is related to requirements. Release could not be deleted.') {
        const artifactChangeErrorMessage = t('releaseDeleteError', {
          release: getReleaseName(projectConfig),
          requirement: getRequirementName(projectConfig)
        });
        showErrorMes(artifactChangeErrorMessage);
      } else if (err.response.data.message === 'Folder is related to sub folders. Folder could not be deleted.') {
        const artifactChangeErrorMessage = t('folderDeleteError', { folder: getFolderName(projectConfig) });
        showErrorMes(artifactChangeErrorMessage);
      } else if (err.response.data.message.startsWith('Test case could not be deleted. Related Test case(s):')) {
        let notificationText = t('testCaseRelatedErrorMessage', {
          entity: getTestCaseName(projectConfig)
        });
        var fragmentedMessage = err.response.data.message.split(':');
        var relatedRequirements = fragmentedMessage[1].trim();
        let totalErrorMessage = notificationText + relatedRequirements;
        showError(totalErrorMessage);
      } else {
        showErrorMes(err);
      }
    }
  });

  const approve = useApi({
    name: 'approve test case',
    api: TestCaseApi.approve,
    autoLoad: false,
    onSuccess: (form) => {
      afterApprove(form);
      showSuccessMes(
        approve?.data?.approveType == 'REJECTED'
          ? t('rejectedTestCase', { entity: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE) })
          : t('approvedTestCase', { entity: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE) })
      );
    }
  });

  const sendApprove = useApi({
    name: 'send approve test case',
    api: TestCaseApi.sendApprove,
    autoLoad: false,
    onSuccess: (form) => {
      setOpenSendApproveModal(false);
      submitCallBack(form);
      showSuccessMes(t('sendedApprove', { entity: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE) }));
    }
  });

  const newVersion = useApi({
    name: 'create new version',
    api: TestCaseApi.createNewVersion,
    autoLoad: false,
    onSuccess: (form) => submitCallBackVersion(form),
    onError: (err) => showErrorMes(err)
  });

  const handleDelete = () => {
    if (data?.id) {
      if (entityType === ENTITY_TYPES.TEST_CASE) {
        setDeleteItem({
          id: data?.id,
          type: entityType,
          title: `${data?.name} (v${data?.majorVersion - 1})`,
          ...(data?.majorVersion !== 1 && { version: data.majorVersion - 1 })
        });
      } else {
        setDeleteItem({ id: data?.id, type: entityType });
      }

      deleteEntity.execute({ id: data?.id });
    }
  };

  const handleApprove = (typeInput) => {
    approve.execute({ id: data?.id, approveType: typeInput });
  };

  const afterSuccessDelete = () => {
    setOpenDeleteModal(false);

    if (isValidValue(deleteItem)) {
      submitCallBackAfterDeletion(deleteItem);
    }
  };

  const afterEdit = (form) => {
    setOpenEditModal(false);
    if (form) {
      submitCallBack(form);
      dispatch(setRefreshTree(true));
    }
  };

  const afterApprove = (form) => {
    setOpenApproveModal(false);
    setOpenRejectModal(false);
    submitCallBackVersion(form);
  };

  const isSentForApproval = () => {
    return data?.approveType ? data.approveType === TestCaseApproveType.SENT_FOR_APPROVAL : false;
  };

  const renderEditModalSwitch = () => {
    switch (entityType) {
      case ENTITY_TYPES.RELEASE:
        return (
          <ReleaseModal
            isOpen={openEditModal}
            closeModal={(form) => afterEdit(form)}
            data={data}
          />
        );
      case ENTITY_TYPES.FOLDER:
        return (
          <FolderModal
            isOpen={openEditModal}
            closeModal={(form) => afterEdit(form)}
            data={data}
          />
        );
      case ENTITY_TYPES.REQUIREMENT:
        return (
          <RequirementModal
            isOpen={openEditModal}
            closeModal={(form) => afterEdit(form)}
            data={data}
            projectConfig={projectConfig}
            jiraConfig={jiraConfig}
          />
        );
      case ENTITY_TYPES.TEST_CASE:
        return (
          <TestCaseModal
            isOpen={openEditModal}
            closeModal={(form) => afterEdit(form)}
            data={data}
          />
        );
      case ENTITY_TYPES.TEST_CONTAINER:
        return (
          <TestContainerModal
            isOpen={openEditModal}
            closeModal={(form) => afterEdit(form)}
            data={data}
            parentId={data?.parent?.id}
          />
        );
      case ENTITY_TYPES.TEST_FOLDER:
        return (
          <TestFolderModal
            isOpen={openEditModal}
            closeModal={(form) => afterEdit(form)}
            data={data}
            parentId={data?.parent?.id}
          />
        );
      case ENTITY_TYPES.TEST_CYCLE:
        return (
          <TestCycleModal
            isOpen={openEditModal}
            closeModal={(form) => afterEdit(form)}
            data={data}
            testFolderId={data?.testFolder?.id}
            parentId={data?.parentId?.id}
          />
        );
      case ENTITY_TYPES.TEST_SUITE:
        return (
          <TestSuiteModal
            isOpen={openEditModal}
            closeModal={(form) => afterEdit(form)}
            data={data}
            testFolderId={data?.testFolder?.id}
            parentId={data?.parent?.id}
          />
        );
      case ENTITY_TYPES.TEST_RUN:
        return (
          <TestRunModal
            isOpen={openEditModal}
            data={data}
            closeModal={(form) => afterEdit(form)}
          />
        );
      case ENTITY_TYPES.DEFECT:
        return (
          <DefectModal
            isOpen={openEditModal}
            data={data}
            closeModal={(form) => afterEdit(form)}
            jiraConfig={jiraConfig}
            projectConfig={projectConfig}
          />
        );
      default:
        return <></>;
    }
  };

  const getOpts = () => {
    const ddOptionArr = [];

    ddOptionArr.push({
      id: 'common-refresh-button',
      label: t('Refresh'),
      icon: 'ArrowsClockwise',
      onClick: () => submitCallBack()
    });

    (entityType === ENTITY_TYPES.REQUIREMENT ||
      entityType === ENTITY_TYPES.TEST_FOLDER ||
      entityType === ENTITY_TYPES.TEST_CYCLE ||
      entityType === ENTITY_TYPES.TEST_SUITE) &&
      isViewReport(projectId) &&
      ddOptionArr.push({
        id: 'common-report-button',
        label: t('Report'),
        icon: 'ChartBar',
        onClick: () => setReportModal(true)
      });

    if (statusUpdatePrivileged(entityType, projectId) && isApproved(data?.approveType) === false) {
      ddOptionArr.push({
        id: 'common-edit-button',
        label: t('Edit'),
        icon: 'PencilLine',
        disabled: data?.lockStatus?.update,
        onClick: () => setOpenEditModal(true)
      });
    }

    if (entityType !== ENTITY_TYPES.RELEASE && isClonePrivileged(entityType, projectId)) {
      ddOptionArr.push({
        id: 'common-clone-button',
        label: t('Clone'),
        icon: 'CopySimple',
        disabled: data?.lockStatus?.clone,
        onClick: () => setOpenCloneModal(true)
      });
    }
    if (
      entityType !== ENTITY_TYPES.TEST_RUN &&
      isApproved(data?.approveType) === false &&
      isDeletePrivileged(entityType, projectId)
    ) {
      ddOptionArr.push({
        id: 'common-moveToTrash-button',
        label: t('Move To Trash'),
        icon: 'TrashSimple',
        disabled: data?.lockStatus?.delete,
        onClick: () => setOpenDeleteModal(true)
      });
    }
    return ddOptionArr;
  };

  return (
    <>
      <div className="flex flex-row gap-4">
        {(entityType === ENTITY_TYPES.RELEASE ||
          entityType === ENTITY_TYPES.REQUIREMENT ||
          entityType === ENTITY_TYPES.TEST_CASE ||
          entityType === ENTITY_TYPES.TEST_CYCLE ||
          entityType === ENTITY_TYPES.TEST_SUITE ||
          entityType === ENTITY_TYPES.DEFECT ||
          entityType === ENTITY_TYPES.TEST_RUN) && (
          <SubscribeButton
            entityId={data?.id}
            entityType={entityType}
          />
        )}

        {entityType === ENTITY_TYPES.TEST_CASE && (
          <>
            <VersionDropdown
              entityType={entityType}
              data={data}
              setExistDraft={setExistDraft}
              submitCallBack={submitCallBack}
              name="VersionDropdown"
            ></VersionDropdown>
            {isApproved(data?.approveType) === false ? (
              <>
                {hasPrivilege(projectId, PRIVILEGES.APPROVE_TEST_CASE) &&
                  hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CASE) && (
                    <Button
                      id="testCase-approve-button"
                      secondary
                      text={t('Approve')}
                      onClick={() => setOpenApproveModal(true)}
                      icon="FloppyDisk"
                      size="small"
                    />
                  )}
                {hasPrivilege(projectId, PRIVILEGES.APPROVE_TEST_CASE) &&
                  hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CASE) &&
                  isSentForApproval() && (
                    <Button
                      id="testCase-reject-button"
                      secondary
                      text={t('rejectHeaderMessage', { entity: getTestCaseName(projectConfig) })}
                      onClick={() => setOpenRejectModal(true)}
                      icon="XCircle"
                      size="small"
                    />
                  )}
                {!hasPrivilege(projectId, PRIVILEGES.APPROVE_TEST_CASE) &&
                  hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CASE) &&
                  !isSentForApproval() && (
                    <Button
                      id="testCase-sendToApprove-button"
                      secondary
                      text={t('Send to Approve')}
                      onClick={() => setOpenSendApproveModal(true)}
                      icon="CheckSquare"
                      size="small"
                    />
                  )}
              </>
            ) : (
              isExistDraft === false &&
              data?.approveType === 'APPROVED' &&
              hasPrivilege(projectId, PRIVILEGES.NEW_VERSION_TEST_CASE) && (
                <Button
                  id="testCase-newVersion-button"
                  secondary
                  text={t('New Version')}
                  onClick={() => newVersion.execute({ rootId: data.rootId })}
                  loading={newVersion?.loading}
                  icon="CopySimple"
                  size="small"
                />
              )
            )}
          </>
        )}

        {isValidValue(extraButtons) && extraButtons()}
        <>
          {entityType && entityType !== ENTITY_TYPES.TEST_EXECUTION && (
            <DropDownNew
              options={getOpts()}
              buttonText={t('Actions')}
            />
          )}
        </>

        {closeModal && (
          <Button
            id="entityActionButtonGroup-close-button"
            secondary
            size="xsmall"
            icon="X"
            onClick={closeModal}
          />
        )}
      </div>

      <ConfirmationModal
        isOpen={openDeleteModal}
        loading={deleteEntity?.loading}
        closeModal={() => setOpenDeleteModal(false)}
        handleConfirm={handleDelete}
        header={t('Confirm Delete')}
        content={t('Do you really want to delete this?')}
      />
      <ConfirmationModal
        isOpen={openApproveModal}
        closeModal={() => setOpenApproveModal(false)}
        loading={approve?.loading}
        handleConfirm={() => handleApprove(TestCaseApproveType.APPROVED)}
        header={t('Confirm Approve')}
        content={t('approveConfirmMessage', { entity: getTestCaseName(projectConfig) })}
      />
      <ConfirmationModal
        isOpen={openSendApproveModal}
        closeModal={() => setOpenSendApproveModal(false)}
        loading={sendApprove?.loading}
        handleConfirm={() => sendApprove.execute(data?.id)}
        header={t('SendApproveMessage', { entity: getTestCaseName(projectConfig) })}
        content={t('SendApproveContentMessage', { entity: getTestCaseName(projectConfig) })}
      />
      <ConfirmationModal
        isOpen={openRejectModal}
        closeModal={() => setOpenRejectModal(false)}
        loading={approve?.loading}
        handleConfirm={() => handleApprove(TestCaseApproveType.REJECTED)}
        header={t('rejectHeaderMessage', { entity: getTestCaseName(projectConfig) })}
        content={t('rejectContentMessage', { entity: getTestCaseName(projectConfig) })}
      />
      {openEditModal && renderEditModalSwitch()}
      {openCloneModal && (
        <CloneModal
          isOpen={openCloneModal}
          closeModal={(data) => {
            setOpenCloneModal(false);
            if (isValidValue(data)) submitCallBackAfterClone(data);
          }}
          data={data}
          entityType={entityType}
          isBulk={false}
        />
      )}
      {reportModal === true && entityType === ENTITY_TYPES.REQUIREMENT && (
        <RequirementReportModal
          entityData={data}
          isOpen={reportModal}
          closeModal={() => {
            setReportModal(false);
          }}
          entityId={data?.id}
          users={users}
        />
      )}

      {reportModal === true &&
        (entityType === ENTITY_TYPES.TEST_CYCLE ||
          entityType === ENTITY_TYPES.TEST_FOLDER ||
          entityType === ENTITY_TYPES.TEST_SUITE) && (
          <TestPlanningReportModal
            entityType={entityType}
            entityData={data}
            isOpen={reportModal}
            closeModal={() => setReportModal(false)}
            entityId={data?.id}
            users={users}
          />
        )}
    </>
  );
};

export default EntityActionButtonGroup;
