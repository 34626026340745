import React, { useState } from 'react';
import { Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import Upload from '../../../components/upload/Upload';
import { useTranslation } from 'react-i18next';

const ExecutionStepAttachmentModal = (props) => {
  const { step, submitCallBack } = props;

  const { t } = useTranslation();

  const [attachmentModal, setAttachmentModal] = useState(false);
  const [attachmentCount, setAttachmentCount] = useState(0);

  return (
    <>
      <Button
        id="executionStepAttachmentModal-upload-button"
        tertiary
        type="button"
        size="xsmall"
        icon="UploadSimple"
        onClick={() => setAttachmentModal(true)}
      />
      <Modal
        open={attachmentModal}
        close={() => {
          setAttachmentModal(false);
          submitCallBack();
        }}
        w={'md'}
      >
        <Modal.Content h={'md'}>
          <div className="flex-grow">
            <Upload
              submitCallBack={(value) => setAttachmentCount(value)}
              entityType="testExecutionStep"
              entityId={step?.id}
              users={[]}
            />
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="executionStepAttachmentModal-close-button"
            secondary
            text={t('Close')}
            onClick={() => {
              setAttachmentModal(false);
              submitCallBack(attachmentCount);
            }}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};
export default ExecutionStepAttachmentModal;
