import { axios, refreshInstance, setProjecHeader, setupInterceptors } from '../api/ApiClient';

export const setProjectToken = async (projectId) => {
  const session = JSON.parse(localStorage.getItem("token"));
  if (!session) return;
  //
  const jwt = JSON.parse(atob(session.token.split('.')[1]));
  if (jwt?.isSystemAdmin) return;
  //
  if (!projectId) {
    session.token = session?.firstToken;
    session.projectId = null;
  } else if (!session.projectId || session.projectId !== projectId) {
    try {
      const response = await axios.post(`/api/auth/project-token?projectId=${projectId}`, {});
      session.projectId = projectId;
      session.token = response.data.token;
    } catch (err) {
      return;
    }
  }

  refreshInstance();
  setupInterceptors(session.token)
  projectId && setProjecHeader(projectId);
  localStorage.setItem("token", JSON.stringify(session));
};