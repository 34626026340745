import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const TESTCASE = BASE_URL + 'test-cases';

class TestCaseApi {
  async getTestCase(data) {
    const resp = await axios.get(`${TESTCASE}/${data.id}`);
    return resp.data;
  }

  async getRootTestCase(data) {
    const resp = await axios.get(`${TESTCASE}/root`, { params: data });
    return resp.data;
  }

  async assignRequirement(data) {
    const resp = await axios.put(`${TESTCASE}/${data.id}/assign-requirements`, data);
    return resp.data;
  }
  async unassignRequirement(data) {
    const resp = await axios.put(`${TESTCASE}/${data.id}/unassign-requirements`, data?.ids);
    return resp.data;
  }

  async getAll(data) {
    const resp = await axios.get(`${TESTCASE}`, { params: data });
    return resp.data;
  }

  async getByRequirement(data) {
    const resp = await axios.get(`${TESTCASE}`, { params: data });
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${TESTCASE}`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${TESTCASE}/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${TESTCASE}/${data.id}`);
    return resp.data;
  }

  async clone(data) {
    const resp = await axios.post(`${TESTCASE}/clone`, data);
    return resp.data;
  }

  async approve(data) {
    const resp = await axios.put(`${TESTCASE}/${data.id}/approve`, data);
    return resp.data;
  }

  async sendApprove(id) {
    const resp = await axios.put(`${TESTCASE}/${id}/send-approve`);
    return resp.data;
  }

  async createNewVersion(data) {
    const resp = await axios.put(`${TESTCASE}/new-version`, data);
    return resp.data;
  }

  async getTestCaseVersion(data) {
    const resp = await axios.get(`${TESTCASE}/${data.id}/versions`, { params: { approveType: data?.approveType } });
    return resp.data;
  }

  async changeOrder(data) {
    const resp = await axios.put(`${TESTCASE}/${data.id}/steps/change-order`, data);
    return resp.data;
  }

  async getParens(id) {
    const resp = await axios.get(`${TESTCASE}/${id}/parents`);
    return resp.data;
  }

  async updateTestCaseParent(id, body) {
    const resp = await axios.put(`${TESTCASE}/${id}/parent`, body);
    return resp.data;
  }

  async bulkApprove(data) {
    const resp = await axios.put(`${TESTCASE}/bulk/approve`, data);
    return resp.data;
  }

  async bulkClone(data) {
    const resp = await axios.put(`${TESTCASE}/bulk/clone`, data);
    return resp.data;
  }

  async bulkDelete(data) {
    const resp = await axios.put(`${TESTCASE}/bulk/delete`, data);
    return resp.data;
  }

  async bulkSendToApprove(data) {
    const resp = await axios.put(`${TESTCASE}/bulk/send-approve`, data);
    return resp.data;
  }

  async bulkTestCasesAssignFromRequirement(data) {
    const resp = await axios.put(`${TESTCASE}/bulk/assign-requirements`, data);
    return resp.data;
  }

  async assignParameter(data) {
    const res = await axios.post(
      `${TESTCASE}/assign-parameter?parameterId=${data.parameterId}&testCaseId=${data.testCaseId}`
    );
    return res.data;
  }
  async unassignParameter(parameterId, testcaseId) {
    const res = await axios.post(
      `${TESTCASE}/test-cases/unassign-parameter?parameterId=${parameterId}?testcaseId=${testcaseId}`
    );
    return res.data;
  }

  async getTreeData(params) {
    const resp = await axios.get(`/api/test-cases/tree`, { params });
    return resp.data;
  }
}

export default new TestCaseApi();
