import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// Design System Imports
import { Button, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
//Component Imports
import { Modal } from '../../../components/modal';
// API and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import SystemFieldApi from '../../../api/SystemFieldApi';
import DefectApi from '../../../api/DefectApi';
import ReleaseApi from '../../../api/ReleaseApi';
import RequirementApi from '../../../api/RequirementApi';
import TestCycleApi from '../../../api/TestCycleApi';
//Helper Imports
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { capitalize, isValidList, isValidValue } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { ENTITY_TYPES } from '../../../constants';
import { useTranslation } from 'react-i18next';

const SystemFieldLockActionsModal = (props) => {
  const { isOpen, closeModal, projectConfig, relatedField, relatedEntity, systemFieldOptions, setSystemFieldOptions } =
    props;
  const { control, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const [lockActions, setLockActions] = useState([]);
  const [lockActionForm, setLockActionForm] = useState({});
  const { t } = useTranslation();

  const getLockActionsApi = () => {
    switch (relatedEntity) {
      case ENTITY_TYPES.RELEASE:
        return ReleaseApi.getLockActions;
      case ENTITY_TYPES.REQUIREMENT:
        return RequirementApi.getLockActions;
      case ENTITY_TYPES.TEST_CYCLE:
        return TestCycleApi.getLockActions;
      case ENTITY_TYPES.DEFECT:
        return DefectApi.getLockActions;
      default:
        return null;
    }
  };

  const lockActionsLoader = useApi({
    name: 'lock actions loader',
    api: getLockActionsApi(),
    autoLoad: true,
    onSuccess: () => {
      if (isValidList(lockActionsLoader?.data)) {
        let types = [];
        lockActionsLoader?.data.forEach((item) => {
          types.push({
            id: item.key,
            key: item.key,
            text: item.description,
            name: t(item.description.trim()),
            value: item.key
          });
        });
        setLockActions(types);
        fillLockForm(types);
      }
    },
    onError: (err) => showErrorMes(err)
  });
  const updateLockAction = useApi({
    name: 'update lock action',
    api: SystemFieldApi.updateLockAction,
    onSuccess: () => {
      let newList = [...systemFieldOptions];
      let index = systemFieldOptions.findIndex((value) => value.id === updateLockAction?.data.id);
      newList[index] = updateLockAction.data;
      setSystemFieldOptions(newList);
    },
    onError: (err) => showErrorMes(err)
  });

  const getLockOptions = (lockId) => {
    if (isValidList(lockActions)) {
      let newList = [];
      lockActions.forEach((x) => {
        if (x?.key !== lockId) newList.push(x);
      });
      return newList;
    }
    return [];
  };

  const fillLockForm = (types) => {
    let tempForm = {};
    systemFieldOptions.forEach((fieldOptionItem) => {
      let arr = [];
      let val = fieldOptionItem?.configuration?.lockAction;
      if (isValidValue(val)) {
        arr = val.split(',');
      }
      let lockedArr = [];
      arr.map((item) => {
        let locked = types.filter((action) => action.key === item);
        lockedArr.push(locked[0]);
      });
      tempForm[fieldOptionItem?.id] = lockedArr;
    });
    setLockActionForm(tempForm);
  };

  const onUpdateLockAction = (value, fieldOption) => {
    let lockActions = [];
    if (isValidList(value)) {
      value.map((item) => {
        lockActions.push(item.id);
      });
    }
    updateLockAction.execute({ id: fieldOption.id, lockAction: lockActions.toString() });
  };

  return (
    <Modal
      w={'md'}
      open={isOpen}
      close={() => closeModal}
    >
      <Modal.Title className="capitalize">
        {t('SystemFieldLockActionModal_entity', {
          entity: t(getArtifactName(projectConfig, relatedEntity)),
          relatedField: t(capitalize(relatedField))
        })}
      </Modal.Title>
      <Modal.Content h={'md'}>
        <div className="flex flex-grow rounded-md border border-gray-200 shadow ">
          <table className="flex flex-grow flex-col ">
            <thead className="flex-grow bg-gray-50">
              <tr className="flex flex-grow">
                <th
                  className={`group w-2/6 px-6 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                >
                  {t('Name')}
                </th>
                <th className={`group px-6 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}>
                  {t('Lock Actions')}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 overflow-x-hidden bg-white ">
              {systemFieldOptions?.map((fieldOption, index) => {
                return (
                  <tr
                    key={index}
                    className="flex flex-grow items-center"
                  >
                    <td className="w-2/6 place-self-center break-words px-3 py-3 text-sm">{t(fieldOption.name)}</td>
                    <td className="w-5/12 flex-grow whitespace-nowrap  px-3 py-3">
                      <MultiSelectDropdownPill
                        control={control}
                        disabled={isValidValue(fieldOption?.templateBy)}
                        name={fieldOption?.id}
                        options={getLockOptions(fieldOption?.id)}
                        value={lockActionForm[fieldOption?.id]}
                        passValue={(value) => onUpdateLockAction(value, fieldOption)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          id="systemFieldLockActionsModal-close-button"
          primary
          text={t('Close')}
          type="button"
          onClick={() => {
            closeModal();
            reset();
          }}
        />
      </Modal.Buttons>
    </Modal>
  );
};
export default SystemFieldLockActionsModal;
