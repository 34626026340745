import { axios } from "./ApiClient";

class ApplicationApi {

  async getApplicationVersion() {
      const resp = await axios.get(`/api/actuator/info`);
      return resp.data;
  }
}

export default new ApplicationApi();