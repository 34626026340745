import { CaretDown, CaretUp } from 'phosphor-react';
import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, useFlexLayout, useResizeColumns } from 'react-table';
import { TableWrapper } from '../../helpers/TableHelper';

const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData, disabled }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="p-0.5">
      <input
        className="flex flex-grow rounded-md border-2 border-dashed p-2.5 focus:border-solid sm:text-sm"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

function TableInlineEdit({ columns, data, updateMyData, disabled, tableHeight }) {
  columns[1]['Cell'] = EditableCell;
  if (columns[2] !== undefined) {
    columns[2]['Cell'] = EditableCell;
  }

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      updateMyData,
      disabled
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns
  );

  const handleHeight = () => {
    if (tableHeight) return tableHeight - 50;
  };

  useEffect(() => {
    handleHeight();
  }, [data]);

  return (
    <>
      <div
        className="overflow-auto "
        style={{ height: handleHeight() }}
      >
        <TableWrapper>
          <div className="overflow-x-auto border border-gray-200 ">
            <table
              {...getTableProps()}
              border="1"
              className="w-full"
            >
              <thead className="overflow-x-auto overflow-y-auto bg-gray-50">
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    key={index}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        key={index}
                        className={` px-4 py-3 text-left text-sm font-medium uppercase 
                                         tracking-wide text-gray-500 ${
                                           column.isResizing ? 'border-r-2 border-l-2' : ''
                                         }`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <div
                          className={`flex  gap-2`}
                          {...column.getResizerProps()}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <CaretDown
                                  weight="fill"
                                  color="#6B7280"
                                  size={16}
                                />
                              ) : (
                                <CaretUp
                                  weight="fill"
                                  color="#6B7280"
                                  size={16}
                                />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="divide-y divide-gray-200 overflow-x-hidden overflow-y-scroll bg-white"
                {...getTableBodyProps()}
              >
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={index}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            key={index}
                            className="flex whitespace-nowrap px-4 py-2"
                            {...cell.getCellProps()}
                          >
                            <div
                              className="overflow-hidden overflow-ellipsis whitespace-nowrap 
                                                            text-sm text-gray-500"
                            >
                              {cell.render('Cell')}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </TableWrapper>
      </div>
    </>
  );
}

export default TableInlineEdit;
