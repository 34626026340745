import React from 'react';
// Design System Import
import { Button, Body } from '../../designSystem/DesignSystem';
import { Modal } from '../modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AlertModal = ({ header, content }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate('/projects');
  };

  return (
    <Modal
      blur
      open={true}
      close={() => {}}
      w="sm"
    >
      <Modal.Title>{header}</Modal.Title>
      <Modal.Content h={'md'}>
        <Body
          type="large"
          text={content}
        />
      </Modal.Content>
      <Modal.Buttons>
        <Button
          id="alert-cancel-button"
          secondary
          text={t('Go to Home Page')}
          onClick={goBackHandler}
        />
      </Modal.Buttons>
    </Modal>
  );
};

export default AlertModal;
