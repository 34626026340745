import React, { useEffect, useMemo, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import TestSuiteApi from '../../../api/TestSuiteApi';
import TestPlanningReportModal from '../../../components/report/planning/TestPlanningReportModal';
import { getReportActionForTable } from '../../../components/report/ReportHelper';
// Component Imports
import Table from '../../../components/table/Table';
import { TableColumns } from '../../../components/table/TableColumns';
import { ENTITY_TYPES } from '../../../constants';
import { getPageFilterInput } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import {
  DEFAULT_PAGE_PARAMS,
  disableResizingForColumns,
  getNotResizedColumnList,
  getTableActions,
  handleSelectedRow,
  translateColumns
} from '../../../helpers/TableHelper';
import { useApi } from '../../../hooks/UseApi';
// Api and Hook Imports
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseTableColumns from '../../../hooks/UseTableColumns';
import UseTableHelper from '../../../hooks/UseTableHelper';
import UseUserTablePreferences from '../../../hooks/UseUserTablePreferences';
import { getCycleName } from '../../../helpers/ArtifactNameHelper';
import { getTestFolderName } from '../../../helpers/ArtifactNameHelper';

const TestSuiteReactTable = (props) => {
  const { filter, setRefreshStatus, refreshStatus, users, projectConfig } = props;
  const { t } = useTranslation();
  const { projectId } = UseQueryProject('projectId');
  const { selectedColumns } = UseTableColumns(projectId, ENTITY_TYPES.TEST_SUITE);
  const { createTableData } = UseTableHelper();
  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.TEST_SUITE);

  const [customFilters, setCustomFilters] = useState({});
  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('code', 'ASC'));
  const [selectedItem, setSelectedItem] = useState({});
  const [reportModal, setReportModal] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(0);

  const dataLoader = useApi({
    name: 'fetching test suites',
    api: TestSuiteApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
      setRefreshStatus(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const tData = useMemo(() => {
    let data = [];
    if (dataLoader?.data?.content) {
      const columnsConfig = TableColumns[ENTITY_TYPES.TEST_SUITE];

      // Test Cycle and Test Folder are a configurable fields, so they should be updated
      const testCycleNameColumn = columnsConfig.find((column) => column?.key === 'testCycleName');
      if (testCycleNameColumn?.name)
        testCycleNameColumn.name = `${t('entityName', { entity: getCycleName(projectConfig) })} `;

      const testCycleCodeColumn = columnsConfig.find((column) => column?.key === 'testCycleCode');
      if (testCycleCodeColumn?.name)
        testCycleCodeColumn.name = `${t('entityCode', { entity: getCycleName(projectConfig) })} `;

      const testFolderNameColumn = columnsConfig.find((column) => column?.key === 'testFolderName');
      if (testFolderNameColumn?.name)
        testFolderNameColumn.name = `${t('entityName', { entity: getTestFolderName(projectConfig) })} `;

      const testFolderCodeColumn = columnsConfig.find((column) => column?.key === 'testFolderCode');
      if (testFolderCodeColumn?.name)
        testFolderCodeColumn.name = `${t('entityCode', { entity: getTestFolderName(projectConfig) })} `;

      data = dataLoader?.data?.content.map((item) =>
        createTableData(item, columnsConfig, projectId, false, ENTITY_TYPES.TEST_SUITE, users)
      );
    }
    return data;
  }, [dataLoader?.data?.content, getI18n().language]);

  const loading = useMemo(() => dataLoader?.loading, [dataLoader?.loading]);

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    setPageParams(pageInput);
    getItems(pageInput, customFilters);
  };

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) {
      fil = { ...fil, ...userSavedFilters?.filterInput };
    }

    let body = { ...fil, ...getPageFilterInput(pageInput), projectId };
    dataLoader.execute(body);
  };

  const refreshTable = () => getByPage({});

  const optionsFn = (row) => {
    let ops = [];

    let opt = getReportActionForTable(projectId, handleSelectedRow, dataLoader, setReportModal, row, setSelectedItem);
    if (opt !== null) {
      ops.push(opt);
      return ops;
    }

    return null;
  };

  let columns = [getTableActions(optionsFn), ...selectedColumns];
  columns = disableResizingForColumns(columns, getNotResizedColumnList(ENTITY_TYPES.TEST_SUITE));
  columns = translateColumns(columns, t);

  useEffect(() => {
    if (refreshStatus) {
      refreshTable();
      setRerenderKey((prevKey) => prevKey + 1);
    }
  }, [refreshStatus]);

  return (
    <>
      <Table
        key={rerenderKey}
        columns={columns}
        data={tData}
        pagination
        loading={loading}
        pageParams={pageParams}
        getByPage={getByPage}
        onFilter={onFilter}
        entityType={ENTITY_TYPES.TEST_SUITE}
      />

      {reportModal === true && (
        <TestPlanningReportModal
          entityData={selectedItem}
          isOpen={reportModal}
          closeModal={() => {
            setReportModal(false);
          }}
          entityId={selectedItem?.id}
          users={users}
          entityType={ENTITY_TYPES.TEST_SUITE}
        />
      )}
    </>
  );
};
export default TestSuiteReactTable;
