import React, { useEffect } from 'react';
import { Dropdown, Input, Button } from '../../../designSystem/DesignSystem';
import { isValidList, isValidValue } from '../../../helpers/Helper';
import { useTranslation } from 'react-i18next';
import { translateArrItemText } from '../../../helpers/TranslationHelper';

const ChartAddFieldOptionContent = (props) => {
  const {
    allFields,
    dataForm,
    control,
    setValue,
    fieldLimit,
    chartFieldLimits,
    isJustOne,
    selectedFields,
    setSelectedFields
  } = props;

  const { t } = useTranslation();
  const boxLimitedStatus = selectedFields.length >= chartFieldLimits[dataForm?.type?.id];

  const addBox = (index) => {
    let dbs = [...selectedFields];
    let inp = {
      key: null,
      order: 0,
      value: null,
      fieldKey: null,
      topCount: 5,
      custom: false
    };

    setOrderAndTopCount(index, inp);

    if (isValidList(dbs)) {
      dbs.push(inp);
      setSelectedFields(dbs);
    } else {
      let arr = [inp];
      setSelectedFields(arr);
    }
  };

  const onDeleteBox = (index) => {
    if (isValidList(dataForm?.configuration?.fields)) {
      let arr = selectedFields;
      arr = arr.filter((_, x) => x !== index);

      setSelectedFields(arr);

      arr.map((item, index) => {
        setOrderAndTopCount(index, item);
      });
    }
  };

  const setOrderAndTopCount = (index, item) => {
    setValue('topCount' + index, item?.topCount);
    setValue('order' + index, item?.order);
  };

  useEffect(() => {
    let arr = dataForm?.configuration?.fields;

    if (isValidList(arr)) {
      let arr2 = [];

      arr.forEach((x) => {
        let fld = allFields.find((el) => el?.key === x?.fieldKey);
        if (fld) {
          arr2.push({
            order: parseInt(x?.order + '', 10),
            fieldKey: { id: fld?.key, name: fld?.text },
            fieldKeyValue: x?.fieldKey,
            topCount: parseInt(x?.topCount + '', 10),
            custom: x?.custom,
            id: x?.fieldKey,
            name: x?.fieldKey
          });
        }
      });
      setSelectedFields(arr2);
    }
  }, [allFields]);

  return (
    <>
      <span style={{ fontWeight: 'bold', fontSize: 11 }}>{t('chartAddModal_entity', { entity: t(fieldLimit) })}</span>
      <div className="flex">
        <Button
          id="chartAddModal-add-button"
          secondary
          text={t('Field')}
          size="xsmall"
          icon="Plus"
          onClick={(e) => {
            e.preventDefault();
            addBox(selectedFields?.length);
          }}
          disabled={
            !isValidValue(dataForm?.entityType?.id) ||
            !isValidValue(dataForm?.project?.id) ||
            boxLimitedStatus ||
            (isJustOne === true && selectedFields.length >= 1)
          }
        />
      </div>
      <div>
        <table>
          {selectedFields.length > 0 && (
            <thead>
              <tr className="text-center">
                <th className=" border-grey-light  border-b text-xs">{t('Field')}</th>
                <th className="  border-grey-light border-b text-xs">{t('Chart Top Count (for level)')}</th>
                <th className=" border-b  text-xs ">{t('Order (inside to outside)')}</th>
                <th></th>
              </tr>
            </thead>
          )}
          <tbody>
            {selectedFields.map((item, index) => {
              return (
                <tr
                  key={index + 989}
                  className="hover:bg-grey-lighter"
                >
                  <td style={{ minWidth: 300 }}>
                    <Dropdown
                      clearable
                      control={control}
                      name={'fieldKey' + index}
                      placeholder={t('Select Field')}
                      options={translateArrItemText(allFields, t)}
                      selectedItem={selectedFields[index]?.fieldKey}
                      passValue={(value) => {
                        let arr = [...selectedFields];
                        arr[index].fieldKey = value[0];
                        arr[index].fieldKeyValue = value[0]?.id;
                        arr[index].id = value[0]?.id;
                        arr[index].name = value[0]?.name;
                        setSelectedFields(arr);
                      }}
                    />
                  </td>
                  <td className="px-6">
                    <Input
                      name={'topCount' + index}
                      type={'number'}
                      placeholder={t(`Enter top count`)}
                      control={control}
                      passValue={(value) => {
                        let arr = [...selectedFields];
                        arr[index].topCount = value;
                        setSelectedFields(arr);
                      }}
                    />
                  </td>
                  <td className="px-6">
                    <Input
                      name={'order' + index}
                      type={'number'}
                      placeholder={t(`Enter order`)}
                      control={control}
                      passValue={(value) => {
                        let arr = [...selectedFields];
                        arr[index].order = value;
                        setSelectedFields(arr);
                      }}
                    />
                  </td>
                  <td>
                    <div className="flex flex-row gap-0">
                      <Button
                        id="chartAddFieldOptionContext-delete-button"
                        secondary
                        icon="TrashSimple"
                        size="small"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          onDeleteBox(index, item);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ChartAddFieldOptionContent;
