import { createContext, useContext, useReducer } from 'react';
import { loadProject } from './ProjectActions';
import { reducer } from './Reducers';

const AppContext = createContext(null);
const AppContextDispatch = createContext(null);

const initialState = {
  project: loadProject(),
  config: null,
  jiraConfig: null,
  systemParams: [],
  customFields: {},
  systemFields: {},
  systemFieldParams: {},
  users: null,
  projectUserGroups: null,
  runStatuses: [],
  selectedRows: null,
  tablePrefences: {
    requirement: {},
    testCase: {},
    testSuite: {},
    testRun: {},
    testCycle: {},
    defect: {}
  },
  activeRequests: [],
  refreshData: false,
  refreshTree: false,
  webSocket: null,
  wsStatus: null
};

const AppProvider = ({ children }) => {
  let [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <AppContext.Provider value={state}>
        <AppContextDispatch.Provider value={dispatch}>{children}</AppContextDispatch.Provider>
      </AppContext.Provider>
    </>
  );
};

export default AppProvider;

export function useApp() {
  return useContext(AppContext);
}

export function useAppDispatch() {
  return useContext(AppContextDispatch);
}
