import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
// Api and Hook Imports
import LdapApi from '../../../api/LdapApi';
import { useApi } from '../../../hooks/UseApi';
// Design System Import
import { H6, Button, Input, Toggle, Password } from '../../../designSystem/DesignSystem';
import Accordion from '../../../components/accordion/Accordion';
// Component Imports
import { Modal } from '../../../components/modal';
import { editModalMapping, isValidValue } from '../../../helpers/Helper';
import { isSystemAdmin } from '../../../helpers/PriviligeHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { encryptData } from '../../../helpers/CryptoHelper';
import { useTranslation } from 'react-i18next';

const LdapSettingsModal = ({ isOpen, closeModal, data }) => {
  const { control, handleSubmit, setValue, reset, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const [dataForm, setDataForm] = useState(null);
  const [attributesForm, setAttributesForm] = useState();
  const [activation, setActivation] = useState(false);
  const [ssl, setSsl] = useState(false);

  const { t } = useTranslation();

  //#region API
  const addLdapServer = useApi({
    name: ' Adding ldap server',
    api: isValidValue(data) ? LdapApi.edit : LdapApi.create,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal();
      reset();
    },
    onError: (err) => showErrorMes(err)
  });

  const checkLdapConnection = useApi({
    name: 'Testing ldap connection',
    api: LdapApi.check,
    onError: () => showErrorMes('Ldap connection could not be established'),
    onSuccess: () => showSuccessMes('Ldap connection is checked')
  });

  const getLdapsAtt = useApi({
    name: 'get ldap attributes',
    api: LdapApi.getLdapAttributes,
    onError: (err) => showErrorMes(err),
    onSuccess: () => {
      // showSuccessMes("Ldap attributes are getting Successfully.")
    }
  });
  //#endregion

  const onSubmit = async (form) => {
    const { managerPassword } = form;
    const encrManagerPassword = await encryptData(managerPassword);

    const body = {
      ...(isValidValue(data) && { id: data.id }),
      name: form.name,
      url: form.url,
      base: form.base,
      userSearchFilter: form.userSearchFilter,
      groupSearchFilter: form.groupSearchFilter,
      managerDn: form.managerDn,
      managerPassword: encrManagerPassword,
      active: activation,
      allowUnauthorizedCertificates: ssl,
      ldapProp: attributesForm
    };
    addLdapServer.execute(body);
  };

  const attributes = useMemo(() => {
    if (data) return data?.ldapProp;
    else {
      if (data?.attributeMap) return data?.attributeMap;
      else return getLdapsAtt?.data;
    }
  }, [data, getLdapsAtt?.data]);

  const getAttributes = () => {
    if (!data?.attributeMap) getLdapsAtt.execute();
  };
  const ldapConnectionTest = async () => {
    const values = getValues();
    const { managerPassword } = values;
    const encrManagerPassword = await encryptData(managerPassword);
    const body = {
      ...values,
      managerPassword: encrManagerPassword
    };
    checkLdapConnection.execute(body);
  };

  useEffect(() => {
    if (!data) getAttributes();
  }, []);

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setDataForm(data);
      setActivation(data?.active + '' === 'true');
      setSsl(data?.allowUnauthorizedCertificates + '' === 'true');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isValidValue(attributes)) {
      let mappedArray = editModalMapping(attributes);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setAttributesForm(attributes);
    }
  }, [attributes]);

  return (
    <>
      {' '}
      {isSystemAdmin() && (
        <Modal
          open={isOpen}
          close={() => closeModal}
          w={'md'}
        >
          <Modal.Title> {data ? t('Edit Ldap Fields') : t('Ldap Fields')}</Modal.Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Content h={'md'}>
              <div className="flex flex-grow flex-col gap-5  pr-4">
                <Input
                  name="name"
                  label={t('Name')}
                  required
                  placeholder={t('Enter a server name')}
                  control={control}
                  passValue={(value) => {
                    setDataForm((prevState) => ({ ...prevState, name: value }));
                  }}
                />
                <Input
                  name="url"
                  label={t('Url')}
                  required
                  placeholder={t('Enter a server url')}
                  control={control}
                  passValue={(value) => {
                    setDataForm((prevState) => ({ ...prevState, url: value }));
                  }}
                />
                <Input
                  name="base"
                  label={t('Base')}
                  required
                  placeholder={t('Enter a root DN')}
                  control={control}
                  passValue={(value) => {
                    setDataForm((prevState) => ({ ...prevState, base: value }));
                  }}
                />
                <Input
                  name="userSearchFilter"
                  label={t('User Search Filter')}
                  control={control}
                />
                <Input
                  name="groupSearchFilter"
                  label={t('Group Search Filter')}
                  control={control}
                />
                <Input
                  name="managerDn"
                  label={t('ManagerDn')}
                  control={control}
                />
                <Password
                  name="managerPassword"
                  label={t('Manager Password')}
                  control={control}
                />
                <Toggle
                  labelLeft
                  labelText={t('Active')}
                  control={control}
                  name="active"
                  value={activation}
                  passValue={(value) => setActivation(value)}
                />
                <Toggle
                  labelLeft
                  labelText={t('Use SSL')}
                  control={control}
                  name="useSsl"
                  value={ssl}
                  passValue={(value) => setSsl(value)}
                />
                <Accordion
                  border
                  button={<H6 text={t('Extra Fields')} />}
                >
                  <Input
                    name="mail"
                    label={t('Mail')}
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, mail: value }));
                    }}
                  />
                  <Input
                    name="displayName"
                    label={t('Display Name')}
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, displayName: value }));
                    }}
                  />
                  <Input
                    name="firstName"
                    label={t('First Name')}
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, firstName: value }));
                    }}
                  />
                  <Input
                    name="groupIdentifier"
                    label={t('Group Identifier')}
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, groupIdentifier: value }));
                    }}
                  />
                  <Input
                    name="lastName"
                    label={t('Last Name')}
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, lastName: value }));
                    }}
                  />
                  <Input
                    name="groupMember"
                    label={t('Group Member')}
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, groupMember: value }));
                    }}
                  />
                  <Input
                    name="memberOfGroup"
                    label={t('Member of Group')}
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, memberOfGroup: value }));
                    }}
                  />
                  <Input
                    name="objectClassOfGroup"
                    label={t('Object Class of Group')}
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, objectClassOfGroup: value }));
                    }}
                  />
                  <Input
                    label={t('Object Class of User')}
                    name="objectClassOfUser"
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, objectClassOfUser: value }));
                    }}
                  />
                  <Input
                    label={t('User Identifier')}
                    name="userIdentifier"
                    control={control}
                    passValue={(value) => {
                      setAttributesForm((prevState) => ({ ...prevState, userIdentifier: value }));
                    }}
                  />
                </Accordion>
              </div>
            </Modal.Content>
            <Modal.Buttons>
              <Button
                id="ldapSettingsModal-cancel-button"
                secondary
                text={t('Cancel')}
                onClick={() => {
                  closeModal();
                  reset();
                }}
              />
              <Button
                id="ldapSettingsModal-ldapConnectionTest-button"
                primary
                text={t('Ldap Connection Test')}
                type="button"
                loading={checkLdapConnection?.loading}
                onClick={ldapConnectionTest}
                disabled={!isValidValue(dataForm?.url)}
              />
              <Button
                id="ldapSettingsModal-submit-button"
                primary
                text={t('Submit')}
                type="submit"
                loading={addLdapServer?.loading}
              />
            </Modal.Buttons>
          </form>
        </Modal>
      )}
    </>
  );
};

export default LdapSettingsModal;
