import { axios } from "./ApiClient";
import { BASE_URL } from "./CommonApiHelper";

export const MASS_INDEXING = BASE_URL + "mass-indexing";

class ElasticsearchApi {

  async startMassIndexing() {
    const resp = await axios.get(`${MASS_INDEXING}/start`);
    return resp.data
  }

  async statusMassIndexing() {
    const resp = await axios.get(`${MASS_INDEXING}/status`);
    return resp.data
  }

}

export default new ElasticsearchApi();