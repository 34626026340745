import React, { useState, useEffect } from 'react';
import { Checkbox } from '../../../designSystem/DesignSystem';
import { isValidCustomFieldValue } from '../../../helpers/CustomFieldHelper';

const CheckboxCustomField = ({ control, field, setCustomFieldForm, customFieldForm, disabled }) => {
  const [checkboxList, setCheckboxList] = useState([]);

  useEffect(() => {
    if (field && field?.config && field?.config?.objects) {
      setCheckboxList(field?.config?.objects);

      if (isValidCustomFieldValue(customFieldForm[field?.customFieldId])) {
        const selectedIds = customFieldForm[field?.customFieldId];
        const arrObject = [];

        field?.config?.objects.map((item) => {
          const elem = selectedIds.find((id) => id === item?.id);
          // !todo: gözden geçirilecek
          if (item?.visible) {
            arrObject.push({ id: item?.id, value: item?.value, selected: elem ? true : false, visible: item?.visible });
          }
        });

        setCheckboxList(arrObject);
      }
    }
  }, [field]);

  const setCustomField = (value) => {
    if (value.length > 0) {
      setCustomFieldForm((prev) => ({ ...prev, [field?.customFieldId]: value }));
    } else {
      setCustomFieldForm((prev) => ({ ...prev, [field?.customFieldId]: [] }));
    }
  };

  return (
    <>
      <Checkbox
        disabled={disabled}
        required={field?.required}
        control={control}
        name={field?.customFieldId}
        label={field?.fieldName}
        checkboxList={checkboxList}
        setCheckboxList={setCheckboxList}
        passValue={(value) => setCustomField(value)}
      />
    </>
  );
};
export default CheckboxCustomField;
