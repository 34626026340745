import React from 'react';
import { Datepicker } from '../../../designSystem/DesignSystem';
import { isValidValue } from '../../../helpers/Helper';

const DashboardFilterComponent = (props) => {
  const { data, control, setDates } = props;

  const changeDate = (value) => {
    if (isValidValue(value)) {
      if (value?.startDate) {
        setDates((prev) => ({ ...prev, startDate: value?.startDate }));
      } else if (value?.endDate) {
        setDates((prev) => ({ ...prev, endDate: value?.endDate }));
      }
    }
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <Datepicker
          startDateForm={data?.startDate}
          endDateForm={data?.endDate}
          startDateName="startDate"
          control={control}
          endDateName="endDate"
          passValue={(value) => changeDate(value)}
        />
      </div>
    </>
  );
};

export default DashboardFilterComponent;
