import { axios } from './ApiClient';

class TestFolderApi {
  async getTestFolder(data) {
    const resp = await axios.get(`/api/test-folders/${data.id}`);
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`/api/test-folders/`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`/api/test-folders/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`/api/test-folders/${data.id}`);
    return resp.data;
  }

  async clone(data) {
    const resp = await axios.post(`/api/test-folders/clone`, data);
    return resp.data;
  }

  async getParens(id) {
    const resp = await axios.get(`/api/test-folders/${id}/parents`);
    return resp.data;
  }

  async updateTestFolderParent(id, body) {
    const resp = await axios.put(`/api/test-folders/${id}/parent`, body);
    return resp.data;
  }

  async getTreeData(params) {
    const resp = await axios.get(`/api/test-folders/tree`, { params });
    return resp.data;
  }
}

export default new TestFolderApi();
