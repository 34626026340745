import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//Api Import
import { useApi } from '../../../hooks/UseApi';
import ProjectConfigApi from '../../../api/ProjectConfigApi';
//Hook - Context Imports
import { useAppDispatch } from '../../../context/AppContext';
import { saveProjectConfig } from '../../../context/ProjectActions';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';

//Component Imports
import ToggleCard from '../../../components/card/ToggleCard';
import { SidePaneRightContent } from '../../../components/Layout';
import { Heading, Loader } from '../../../designSystem/DesignSystem';
//Helper Import
import { getDefectName } from '../../../helpers/ArtifactNameHelper';
import { showErrorMes, showSuccessMes, INFO_MESSAGES } from '../../../helpers/NotificationHelper';
import { isProjectManager } from '../../../helpers/PriviligeHelper';

const DefectProjectSetting = () => {
  const { t } = useTranslation();
  const { projectId, project } = UseQueryProject('projectId');
  const dispatch = useAppDispatch();
  const projectConfig = UseProjectConfig(projectId);
  const [checked, setChecked] = useState(projectConfig?.configuration?.defect?.defectSummaryUpdate + '' === 'true');

  const title = useMemo(() => getDefectName(projectConfig), [projectConfig]);

  const updateProjectConfig = useApi({
    name: 'Updating project config',
    api: ProjectConfigApi.updateProjectConfiguration,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      dispatch(saveProjectConfig(projectId, updateProjectConfig?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const projectConfigLoader = useApi({
    name: 'Get project configs',
    api: ProjectConfigApi.get,
    onSuccess: () => {
      dispatch(saveProjectConfig(projectId, projectConfigLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const handleSubmit = (value) => {
    const body = { id: projectId, key: 'defectSummaryUpdate', value };
    updateProjectConfig.execute(body);
  };

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading
          type="large"
          text={title}
        />
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Main>
        <SidePaneRightContent.Center colFull={true}>
          {projectConfigLoader?.loading ? (
            <Loader></Loader>
          ) : (
            isProjectManager(projectId) && (
              <div className="mt-4">
                <ToggleCard
                  name="defectSummaryUpdate"
                  disabled={project?.templateBy}
                  title={t('defectSummaryUpdate_entity', { entity: t(title) })}
                  labelText={t('defectProjectSetting_entity', { entity: t(title) })}
                  checked={checked}
                  submitCallBack={(value) => {
                    handleSubmit(value);
                    setChecked(value);
                  }}
                />
              </div>
            )
          )}
        </SidePaneRightContent.Center>
      </SidePaneRightContent.Main>
    </>
  );
};

export default DefectProjectSetting;
