import React, { useMemo, useState } from 'react';
import { Modal } from '../../../components/modal';
import TabBar from '../../../components/TabBar';

import RoleAddTable from './RoleAddTable';
import UserGroupApi from '../../../api/UserGroupApi';
import { useApi } from '../../../hooks/UseApi';
import UsersTabInModal from '../common/UsersTabInModal';
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';

const UserGroupAddEditUsersAndRolesModal = (props) => {
  const { isOpen, selectedUserGroup, closeModal, projectId } = props;

  const [selectedResults, setSelectedResults] = useState(selectedUserGroup?.users ? selectedUserGroup?.users : []);
  const [selectedResultsRole, setSelectedResultsRole] = useState(
    selectedUserGroup?.roles ? selectedUserGroup?.roles : []
  );
  const { t } = useTranslation();

  const [selectedUser, setSelectedUser] = useState(null);

  const [selectedUserRole, setSelectedUserRole] = useState(null);

  /* API requests */
  const addUserApi = useApi({
    name: 'addToUserGroup',
    api: UserGroupApi.addToUserGroup,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.add);
      setSelectedResults((prev) => {
        return prev ? [...selectedResults, selectedUser] : [selectedUser];
      });
    },
    onError: (err) => showErrorMes(err)
  });

  const removeUserApi = useApi({
    name: 'removeFromUserGroup',
    api: UserGroupApi.removeFromUserGroup,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      setSelectedResults(selectedResults ? selectedResults.filter((item) => item.id !== selectedUser.id) : []);
    },
    onError: (err) => showErrorMes(err)
  });

  const addToUserGroup = useApi({
    name: 'addToUserGroup',
    api: UserGroupApi.addToUserGroup,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      setSelectedResultsRole((prev) => (prev ? [...selectedResultsRole, selectedUserRole] : [selectedUserRole]));
    },
    onError: (err) => showErrorMes(err)
  });

  const removeFromUserGroup = useApi({
    name: 'removeFromUserGroup',
    api: UserGroupApi.removeFromUserGroup,
    onSuccess: () =>
      setSelectedResultsRole(
        selectedResults ? selectedResultsRole.filter((item) => item.id !== selectedUserRole.id) : []
      ),
    //onError: () => setAllResults([])
    onError: (err) => showErrorMes(err)
  });

  const selectedUserGroupItemDetails = useMemo(() => {
    const userGroup = JSON.parse(JSON.stringify(selectedUserGroup));
    userGroup?.templateUsers?.forEach((element) => (element.templateBy = userGroup?.templateBy));
    return userGroup;
  }, [selectedUserGroup]);

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title>
          <Heading.Group text={t('User Group Setting')}>
            <div className="flex gap-4">
              <Button
                tertiary
                size="small"
                bgColor="bg-transparent"
                icon="X"
                onClick={closeModal}
              />
            </div>
          </Heading.Group>
        </Modal.Title>
        <Modal.Content h={'lg'}>
          <div className="flex-grow">
            <TabBar
              defaultIndex={0}
              options={[
                {
                  name: t('Users'),
                  disabled: false,
                  render: () => (
                    <UsersTabInModal
                      selectedUserGroup={selectedUserGroupItemDetails}
                      setSelectedRow={setSelectedUser}
                      selectedResults={selectedResults}
                      addApi={addUserApi}
                      removeApi={removeUserApi}
                    />
                  )
                },
                {
                  name: t('Roles'),
                  disabled: false,
                  render: () => (
                    <RoleAddTable
                      selectedUserGroup={selectedUserGroup}
                      projectId={projectId}
                      selectedResults={selectedResultsRole}
                      setSelectedRow={setSelectedUserRole}
                      removeApi={removeFromUserGroup}
                      addApi={addToUserGroup}
                    >
                      {' '}
                    </RoleAddTable>
                  )
                }
              ]}
            />
          </div>
        </Modal.Content>
        {/* <Modal.Buttons>
                  
                </Modal.Buttons> */}
      </Modal>
    </>
  );
};
export default UserGroupAddEditUsersAndRolesModal;
