import { useEffect } from 'react';
// Context Imports
import { useAppDispatch, useApp } from '../context/AppContext';
import { addRequest, removeRequest, saveProjectConfig } from '../context/ProjectActions';
import { uniqueId } from 'lodash-es';
// Api Imports
import ProjectConfigApi from '../api/ProjectConfigApi';
// HElPER & CONSTANTS
import { showErrorMes } from '../helpers/NotificationHelper';
import { useMemo } from 'react';
import { useApi } from './UseApi';

const UseProjectConfig = (projectId) => {
  const app = useApp();
  const projectConfig = app?.config;
  const activeRequests = app?.activeRequests || [];

  const dispatch = useAppDispatch();

  const id = useMemo(() => uniqueId(), []);

  const filterPredicate = (item) => item.projectId !== projectId && item?.type !== 'projectConfig';

  const getProjectConfigLoader = useApi({
    name: 'Get Project Config',
    api: ProjectConfigApi.get,
    autoLoad: false,
    onSuccess: () => dispatch(saveProjectConfig(projectId, getProjectConfigLoader?.data)),
    onError: (err) => showErrorMes(err),
    onFinally: () => dispatch(removeRequest(filterPredicate))
  });

  useEffect(() => {
    if (projectId && projectConfig?.projectId !== projectId)
      dispatch(addRequest({ order: id, type: 'projectConfig', projectId }));
  }, [projectId]);

  useEffect(() => {
    if (activeRequests?.length) {
      const allRequestsForEntity = activeRequests
        .filter((item) => item.projectId === projectId && item.type === 'projectConfig')
        .map((item) => item.order);
      if ((allRequestsForEntity || []).indexOf(id) === 0 && projectConfig?.projectId !== projectId) {
        getProjectConfigLoader.execute({ id: projectId });
      }
    }
  }, [activeRequests]);

  return projectConfig;
};

export default UseProjectConfig;
