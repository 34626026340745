import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CaretDown } from 'phosphor-react';
import * as Icons from 'phosphor-react';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../helpers/ButtonConstants';

const returnIcon = (icon) => {
  const IconComponent = Icons[icon] || Icons.Plus;
  return (
    <IconComponent
      weight="duotone"
      size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
      color={'#7c7cc3'}
    />
  );
};

const DropDownNew = ({ options, buttonText }) => {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <Menu.Button
          id="common-actions-button"
          className="inline-flex h-8 w-full items-center justify-center rounded-md  bg-gray-100 px-3 py-1.5 text-sm font-medium  text-primary-800 transition  duration-500 ease-in-out hover:bg-gray-300 focus:outline-none"
        >
          {buttonText}
          <CaretDown
            className="-mr-1 ml-2 h-4 w-4 text-gray-400 hover:text-gray-600"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-40 mt-2 w-32 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {options.map((item, index) => {
            return (
              <>
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div id={item?.id}>
                      <button
                        id={item?.id}
                        onClick={() => item.onClick()}
                        disabled={item?.disabled}
                        className={`${
                          (active ? 'bg-primary-700 text-white' : 'text-gray-900',
                          item?.disabled ? 'text-gray-400' : 'text-gray-900')
                        } group flex w-full items-center gap-2 px-2 py-2 text-sm`}
                      >
                        {returnIcon(item?.icon)}
                        {item?.label}
                      </button>
                    </div>
                  )}
                </Menu.Item>
              </>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export { DropDownNew };
