import {
  findObjectByTarget,
  isValidValue,
  isValidList,
  uniqueItems,
  dateFormatterWithUniversalDate,
  translatedDateFormat
} from './Helper';
import { CUSTOM_FIELD_TYPES, ENTITY_TYPES } from '../constants';
import { getCheckIconColumn, getVisibleIconColumn } from './TableHelper';
import i18next from 'i18next';

export const isValidCustomFieldValue = (value) => {
  if (value && value !== null && value !== '' && value !== '-') return true;
  else return false;
};

export const createEntityFields = (selectedData, customFieldForm) => {
  let entityFields = [];

  if (selectedData && isValidList(selectedData.customFields)) {
    Object.keys(customFieldForm).forEach(function (key) {
      var field = selectedData.customFields.find((x) => x.customFieldId === key);
      let value = customFieldForm[key] && customFieldForm[key] !== null ? customFieldForm[key].toString() : '';

      if (field) {
        entityFields.push({
          entityCustomFieldId: field.entityCustomFieldId,
          customFieldId: key,
          value: value
        });
      } else {
        entityFields.push({
          entityCustomFieldId: null,
          customFieldId: key,
          value: value
        });
      }
    });
  } else {
    Object.keys(customFieldForm).forEach(function (key) {
      let value = customFieldForm[key] && customFieldForm[key] !== null ? customFieldForm[key].toString() : '';

      entityFields.push({
        entityCustomFieldId: null,
        customFieldId: key,
        value: value
      });
    });
  }
  return entityFields;
};

export const getCFUserFirstLastNameById = (userList, idValue) => {
  if (userList && userList.length > 0) {
    let user = userList.find((x) => x?.key === idValue);
    if (user && isValidValue(user?.fullname)) return user.fullname;
    if (user && isValidValue(user?.name)) return user.name;
    if (user && isValidValue(user?.text)) return user.text;

    user = userList.find((x) => x?.id === idValue);
    if (user && isValidValue(user?.fullname)) return user.fullname;
    if (user && isValidValue(user?.name)) return user.name;
    if (user && isValidValue(user?.text)) return user.text;
  }
  return '-';
};

export const getEntityCustomFieldValue = (userList, entityCustomFields, customField) => {
  var entityField = findObjectByTarget(entityCustomFields, 'customFieldId', customField?.customFieldId);
  if (entityField) return getEntityFieldValue(entityField, customField, userList);
};

function getEntityFieldValue(entityField, customField, userList) {
  let customFieldValue = isValidCustomFieldValue(entityField.value) ? entityField.value : '-';

  if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.USER) {
    if (isValidCustomFieldValue(customFieldValue)) return getCFUserFirstLastNameById(userList, customFieldValue);
    return '-';
  } else if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.DATE) {
    if (isValidCustomFieldValue(customFieldValue))
      return translatedDateFormat(customFieldValue, 'DD MMM YYYY', i18next.t);
    return '-';
  } else if (
    customField.customFieldEnumId === CUSTOM_FIELD_TYPES.TEXT ||
    customField.customFieldEnumId === CUSTOM_FIELD_TYPES.NUMBER ||
    customField.customFieldEnumId === CUSTOM_FIELD_TYPES.MEMO
  ) {
    return customFieldValue;
  } else if (
    customField.customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN ||
    customField.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST
  ) {
    let customFieldAllObjects = customField?.config?.objects;

    if (isValidList(customFieldAllObjects)) {
      let selectedItemsValue = '-';
      let objects = entityField?.value ? entityField.value.split(',') : [];

      if (isValidList(objects)) {
        let isFirst = true;
        objects.map((itemId) => {
          let field = customFieldAllObjects.find((x) => x.id === itemId);
          if (field) {
            if (isFirst === false) selectedItemsValue = selectedItemsValue + ', ';
            else if (isFirst === true) selectedItemsValue = '';

            selectedItemsValue = selectedItemsValue + field?.value;
            isFirst = false;
          }
        });
      }
      return selectedItemsValue;
    }
  } else if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.MULTI_LIST_USERS) {
    let selectedItemsValue = '-';
    let objects = entityField?.value ? entityField.value.split(',') : [];

    if (isValidList(objects)) {
      let isFirst = true;
      objects.map((itemId) => {
        let user = userList.find((x) => x?.id === itemId);
        if (user) {
          if (isFirst === false) selectedItemsValue = selectedItemsValue + ', ';
          else if (isFirst === true) selectedItemsValue = '';

          if (user?.fullname) {
            selectedItemsValue = selectedItemsValue + user?.fullname;
          } else if (user?.name) {
            selectedItemsValue = selectedItemsValue + user?.name;
          } else if (user?.text) {
            selectedItemsValue = selectedItemsValue + user?.text;
          } else {
            selectedItemsValue = selectedItemsValue + user?.firstName + user?.lastName;
          }
          isFirst = false;
        }
      });
    }
    return selectedItemsValue;
  } else if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX) {
    let customFieldAllObjects = customField?.config?.objects;

    if (isValidList(customFieldAllObjects)) {
      let selectedItemsValue = '-';
      let objects = entityField?.value ? entityField.value.split(',') : [];

      if (isValidList(objects)) {
        let isFirst = true;
        objects.map((itemId) => {
          let field = customFieldAllObjects.find((x) => x.id === itemId);
          if (field) {
            if (isFirst === false) selectedItemsValue = selectedItemsValue + ', ';
            else if (isFirst === true) selectedItemsValue = '';
            selectedItemsValue = selectedItemsValue + field?.value;
            isFirst = false;
          }
        });
      }
      return selectedItemsValue;
    }
  }
  return '-';
}

export const getCascadedCustomFields = (cFields = [], dataForm, customFieldForm) => {
  let cfieldlist = [];

  cFields.forEach((item) => {
    if (item?.visible) {
      let rules = item?.ruleConfig?.rules || [];

      if (rules && rules.length) {
        rules.forEach((rule) => {
          if (rule.type === 'systemField') {
            let dataFormId = rule.id + 'Id';

            if (dataForm[dataFormId] && dataForm[dataFormId] === rule.value) {
              cfieldlist.push(item);
              return;
            }
          } else if (rule.type === 'customField') {
            let dataFormId = rule.id;
            if (Array.isArray(customFieldForm[dataFormId])) {
              customFieldForm[dataFormId].forEach((temp) => {
                if (temp && temp === rule.value) {
                  cfieldlist.push(item);
                  return;
                }
              });
            } else if (customFieldForm[dataFormId] && customFieldForm[dataFormId] === rule.value) {
              cfieldlist.push(item);
              return;
            }
          }
        });
      } else cfieldlist.push(item);
    }
  });
  return uniqueItems(cfieldlist);
};

export const changeJiraStatusPlace = (sortedObjs) => {
  let sortedfieldlist = [];

  if (sortedObjs && sortedObjs.length > 0) {
    let statusField = null;
    sortedObjs.map((item) => {
      if (item?.jiraStatusTransition === true) statusField = item;
    });

    if (statusField !== null) {
      sortedObjs = sortedObjs.filter((f) => f?.jiraStatusTransition !== true);
      let array = [];
      array.push(statusField);
      sortedfieldlist = array.concat(sortedObjs);
    } else {
      sortedfieldlist = sortedObjs;
    }
  }
  return sortedfieldlist;
};

export const getCustomFields = (cFields, customFieldForm) => {
  let cFieldForm = {};
  let values = [];

  Object.keys(customFieldForm).forEach(function (key) {
    let value = customFieldForm[key] && customFieldForm[key] !== null ? convertCFieldValues(customFieldForm[key]) : '';

    if (values.length > 0) value = values;

    const cField = cFields.find((field) => field.customFieldId === key);

    if (isValidValue(cField)) {
      if (cField?.customFieldEnumId === CUSTOM_FIELD_TYPES.DATE) {
        value = customFieldForm[key] ? dateFormatterWithUniversalDate(customFieldForm[key]) : '';
      }
      cFieldForm[key] = value;
    }
  });
  return cFieldForm;
};

/*export const findCFields = (form, customFieldForm) => {
  let customFields = {};
  Object.keys(customFieldForm).forEach(function (item) {
    if (form.hasOwnProperty(item)) customFields[item] = form[item]
  });
  return customFields;
}*/

const convertCFieldValues = (form) => {
  let values = [];
  if (Array.isArray(form)) {
    form.forEach((item) => {
      if (isValidValue(item.id)) {
        values.push(item.id);
      } else {
        values.push(item);
      }
    });
    return values.toString();
  } else return form.toString();
};

export const getOptionList = (field) => {
  let optionList = [];
  if (field?.config?.objects && field?.config?.objects.length > 0) {
    optionList = field.config.objects
      .filter((item) => item?.visible === true)
      .map((item) => {
        return {
          id: item.id,
          name: item.value,
          value: item.id,
          key: item.id,
          jiraId: item.jiraId,
          disabled: false
        };
      });
  }
  return optionList;
};

export const getCommonEntityFieldValue = (customField, userList) => {
  let customFieldValue = isValidCustomFieldValue(customField?.config?.value) ? customField.config.value : '-';

  if (isValidCustomFieldValue(customFieldValue)) {
    if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.USER) {
      return getCFUserFirstLastNameById(userList, customFieldValue);
    } else if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.DATE) {
      return translatedDateFormat(customFieldValue, 'DD MMM YYYY', i18next.t);
    } else if (
      customField.customFieldEnumId === CUSTOM_FIELD_TYPES.TEXT ||
      customField.customFieldEnumId === CUSTOM_FIELD_TYPES.NUMBER ||
      customField.customFieldEnumId === CUSTOM_FIELD_TYPES.MEMO
    ) {
      return customFieldValue.length > 100 ? customFieldValue.substring(0, 100) + '...' : customFieldValue;
    }
  } else if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.MULTI_LIST_USERS) {
    let customFieldAllObjects = customField?.config?.objects?.value;
    if (isValidList(customFieldAllObjects)) {
      let selectedItemsValue = '';
      if (isValidList(customFieldAllObjects)) {
        let isFirst = true;
        customFieldAllObjects.map((itemId) => {
          let user = userList.find((x) => x.id === itemId);
          if (user) {
            if (isFirst === false) selectedItemsValue = selectedItemsValue + ', ';
            else if (isFirst === true) selectedItemsValue = '';
            if (user.name) {
              selectedItemsValue = selectedItemsValue + user.name;
            } else if (user.text) {
              selectedItemsValue = selectedItemsValue + user.text;
            } else {
              selectedItemsValue = selectedItemsValue + user.firstName + user.lastName;
            }
            isFirst = false;
          }
        });
      }
      return selectedItemsValue;
    }
  } else if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN) {
    let selectedItemsValue = '';
    let objects = customField?.config?.objects;
    if (isValidList(objects)) {
      let isFirst = true;
      objects.map((item) => {
        if (item.selected === true) {
          if (isFirst === false) selectedItemsValue = selectedItemsValue + ', ';
          else if (isFirst === true) selectedItemsValue = '';

          selectedItemsValue = selectedItemsValue + item.value;
          isFirst = false;
        }
      });
    }
    return selectedItemsValue;
  } else if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX) {
    let selectedItemsValue = '';
    let objects = customField.config?.objects;
    if (isValidList(objects)) {
      let isFirst = true;
      objects.map((item) => {
        if (item.selected === true) {
          if (isFirst === false) selectedItemsValue = selectedItemsValue + ', ';
          else if (isFirst === true) selectedItemsValue = '';

          selectedItemsValue = selectedItemsValue + item.value;
          isFirst = false;
        }
      });
    }
    return selectedItemsValue;
  } else if (customField.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST) {
    let selectedItemsValue = '';
    let objects = customField.config?.objects;
    if (isValidList(objects)) {
      objects.map((item) => {
        if (item?.selected === true) {
          selectedItemsValue = item.value;
        }
      });
      return selectedItemsValue;
    }
  }
  return '';
};

export const getFieldType = (item, typeList) => {
  let target = typeList.find((field) => field.key === item?.customFieldEnumId);
  if (target) return i18next.t(target?.text);
  return '';
};

export const getRulesAsText = (item, allCustomFields, allSystemFields) => {
  const rules =
    item?.ruleConfig && item?.ruleConfig?.rules && item?.ruleConfig?.rules.length > 0 ? item?.ruleConfig?.rules : [];

  if (rules && rules.length > 0) {
    let text = [];
    rules.map((rule) => {
      if (rule && isValidValue(rule?.value) && isValidValue(rule?.type)) {
        if (allCustomFields && allCustomFields.length > 0 && rule?.type === 'customField') {
          allCustomFields.map((field) => {
            if (isValidValue(field?.fieldName) && field?.config?.objects && field?.config?.objects.length > 0) {
              field.config.objects.map((x) => {
                if (x?.id === rule?.value) {
                  text.push(' ' + i18next.t(field?.fieldName) + '(' + i18next.t(x?.value) + ')');
                }
              });
            }
          });
        } else if (allSystemFields && allSystemFields.length > 0 && rule && rule?.type === 'systemField') {
          allSystemFields.map((field) => {
            if (field.values && field?.values.length > 0) {
              field.values.map((x) => {
                if (x?.id === rule?.value) {
                  text.push(' ' + i18next.t(field?.text) + '(' + i18next.t(x?.text) + ')');
                }
              });
            }
          });
        }
      }
    });
    return text && text.length > 0 ? text.toString() : '-';
  }
  return '-';
};
//
export const customFieldConvertToTableData = (
  dataArr,
  users,
  customFieldTypes,
  entityCustomFields,
  entitySystemFields,
  jiraConfig,
  entityType
) => {
  let jiraActive = false;
  if (
    (entityType === ENTITY_TYPES.REQUIREMENT || entityType === ENTITY_TYPES.TEST_CYCLE) &&
    jiraConfig?.activeRequirement === true
  ) {
    jiraActive = true;
  } else if (entityType === ENTITY_TYPES.DEFECT && jiraConfig?.activeDefect === true) {
    jiraActive = true;
  }
  //
  let jiraInput = {};
  //
  let arr = [];
  if (dataArr) {
    arr = dataArr?.map((item) => {
      if (jiraActive) {
        jiraInput = { jiraId: item?.jiraId };
      }
      return {
        id: item.customFieldId,
        name: item?.fieldName,
        order: item?.place ? item?.place : '',
        type: getFieldType(item, customFieldTypes),
        value: getCommonEntityFieldValue(item, users),
        rule: getRulesAsText(item, entityCustomFields, entitySystemFields),
        required: getCheckIconColumn(item?.required),
        visible: getVisibleIconColumn(item?.visible),
        templateBy: item?.templateBy,
        ...jiraInput
      };
    });
  }
  return arr;
};
//
export const customJiraActive = (relatedEntity, jiraConfig) => {
  return (
    (jiraConfig && jiraConfig?.activeDefect === true && relatedEntity === ENTITY_TYPES.DEFECT) ||
    (jiraConfig &&
      jiraConfig?.activeRequirement === true &&
      (relatedEntity === ENTITY_TYPES.REQUIREMENT || relatedEntity === ENTITY_TYPES.TEST_CYCLE))
  );
};
//
export const getDefaultSelectedOptionList = (field) => {
  let defaultValues = [];
  //
  if (field && field?.config && field?.config?.objects) {
    field.config.objects.map((item) => {
      if (item?.selected === true) defaultValues.push(item.id);
    });
  }
  return defaultValues;
};
//
export const getDefaultSelectedMultipleUserList = (field) => {
  let defaultValues = [];
  if (field && field?.config && field?.config?.objects && field.config.objects.value) {
    //
    field.config.objects.value.map((item) => {
      defaultValues.push(item);
    });
  } else if (field && field?.config && field?.config?.objects && field.config.objects.id) {
    field.config.objects.id.map((item) => {
      defaultValues.push(item);
    });
  }
  //
  return defaultValues;
};
//
export const getDefaultSelectedList = (field) => {
  let defaultValue = null;
  if (field && field?.config && field?.config?.objects) {
    field.config.objects.map((item) => {
      if (item?.selected === true) defaultValue = item.id;
    });
  }
  return defaultValue;
};
