import React from 'react';
import { isValidValue } from '../../helpers/Helper';
import i18next from 'i18next';

const Loader = ({ customText }) => {
  return (
    <div className="flex justify-center  space-x-1 text-sm text-gray-700">
      <svg
        fill="none"
        className="h-6 w-6 animate-spin"
        viewBox="0 0 32 32"
      >
        <path
          clipRule="evenodd"
          d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
      <div>{isValidValue(customText) ? customText : i18next.t('Loading ...')}</div>
    </div>
  );
};

export { Loader };
