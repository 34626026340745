import React, { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// HOOKS & API IMPORTS
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseJiraConfig from '../../hooks/UseJiraConfig';
import UseAllUsers from '../../hooks/UseAllUsers';
import UseCustomField from '../../hooks/UseCustomField';
// DESIGN SYSTEM & COMPONENTS
import { SidePaneLeftContent, SidePaneRightContent } from '../../components/Layout';
import { Loader } from '../../designSystem/DesignSystem';
import PageLeftTreeButton from '../../components/treeView/PageLeftTreeButton';
import TestCycleDetails from './testCycle/TestCycleDetails';
import TestFolderDetails from './testFolder/TestFolderDetails';
import TestPlanningDashboard from './TestPlanningDashboard';
import TestSuiteDetails from './testSuite/TestSuiteDetails';
import AlertModal from '../../components/alert/AlertModal';
import DetailsModals from '../../components/general/DetailsModals';
// HELPER & CONSTANTS IMPORTS
import { ENTITY_TYPES } from '../../constants';
import { getNewUrlPath, isValidValue } from '../../helpers/Helper';
import { isViewTestCycle, isViewTestFolder, isViewTestSuite } from '../../helpers/PriviligeHelper';
import TreeArborist from '../../components/tree/TreeArborist';
import * as TreeEvents from '../../components/tree/TreeEvents';
import { EVENT_TYPES } from '../../components/tree/TreeEvents';

const TestPlanningPage = () => {
  const { t } = useTranslation();

  const { projectId } = UseQueryProject('projectId');
  const jiraConfig = UseJiraConfig(projectId);
  const projectConfig = UseProjectConfig(projectId);
  const users = UseAllUsers();

  const customFieldsFolder = UseCustomField(projectId, ENTITY_TYPES.TEST_FOLDER);
  const customFieldsSuite = UseCustomField(projectId, ENTITY_TYPES.TEST_SUITE);
  const customFieldsCycle = UseCustomField(projectId, ENTITY_TYPES.TEST_CYCLE);

  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const { id, type, runId, defectId } = useMemo(() => {
    return {
      id: searchParams.get('id'),
      type: searchParams.get('type'),
      runId: searchParams.get('runId'),
      defectId: searchParams.get('defectId')
    };
  }, [searchParams]);

  const selectedItem = useMemo(
    () => ({
      id,
      type,
      runId,
      defectId
    }),
    [id, type, runId, defectId]
  );

  const renderSwitch = (param) => {
    let type = isValidValue(param) ? param.type : null;
    // eslint-disable-next-line default-case
    switch (type) {
      case ENTITY_TYPES.TEST_FOLDER:
        return (
          <TestFolderDetails
            users={users}
            customFields={customFieldsFolder}
            selectedItem={selectedItem}
            submitCallBack={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_SUITE, EVENT_TYPES.UPDATED, {
                ...item,
                type: ENTITY_TYPES.TEST_FOLDER
              })
            }
            submitCallBackAfterDeletion={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_SUITE, EVENT_TYPES.DELETED, item)
            }
            submitCallBackAfterClone={(item) => afterCreate(ENTITY_TYPES.TEST_FOLDER, item)}
          />
        );
      case ENTITY_TYPES.TEST_CYCLE:
        return (
          <TestCycleDetails
            users={users}
            customFields={customFieldsCycle}
            selectedItem={selectedItem}
            submitCallBack={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_SUITE, EVENT_TYPES.UPDATED, {
                ...item,
                type: ENTITY_TYPES.TEST_CYCLE
              })
            }
            submitCallBackAfterDeletion={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_SUITE, EVENT_TYPES.DELETED, item)
            }
            submitCallBackAfterClone={(item) => afterCreate(ENTITY_TYPES.TEST_CYCLE, item)}
          />
        );
      case ENTITY_TYPES.TEST_SUITE:
        return (
          <TestSuiteDetails
            users={users}
            customFields={customFieldsSuite}
            selectedItem={selectedItem}
            submitCallBack={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_SUITE, EVENT_TYPES.UPDATED, {
                ...item,
                type: ENTITY_TYPES.TEST_SUITE
              })
            }
            submitCallBackAfterDeletion={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_SUITE, EVENT_TYPES.DELETED, item)
            }
            submitCallBackAfterClone={(item) => afterCreate(ENTITY_TYPES.TEST_SUITE, item)}
          />
        );
      default:
        return <TestPlanningDashboard projectId={projectId} />;
    }
  };

  const selectItem = (item) => {
    navigate(getNewUrlPath(projectId, path, item));
  };

  const afterCreate = (type, form) => {
    isValidValue(form) &&
      TreeEvents.dispatch(ENTITY_TYPES.TEST_SUITE, EVENT_TYPES.CREATED, {
        ...form,
        type
      });
  };

  return (
    <>
      {!(isViewTestSuite(projectId) || isViewTestFolder(projectId) || isViewTestCycle(projectId)) && (
        <AlertModal
          header={t('Access Denied')}
          content={t(`You have no permission to access this page.`)}
        />
      )}
      {runId || defectId ? (
        <DetailsModals />
      ) : (
        <>
          <SidePaneLeftContent>
            <PageLeftTreeButton
              pageKey={ENTITY_TYPES.TEST_FOLDER}
              selectedItem={selectedItem}
              afterCreate={afterCreate}
              jiraConfig={jiraConfig}
              projectConfig={projectConfig}
            />
            <SidePaneLeftContent.Treeview>
              {!isValidValue(projectConfig?.projectId) ? (
                <Loader />
              ) : (
                <TreeArborist
                  module={ENTITY_TYPES.TEST_SUITE}
                  draggable
                  projectId={projectId}
                  projectConfig={projectConfig}
                  onSelect={selectItem}
                  params={selectedItem}
                />
              )}
            </SidePaneLeftContent.Treeview>
          </SidePaneLeftContent>
          <SidePaneRightContent>{renderSwitch(selectedItem)}</SidePaneRightContent>
        </>
      )}
    </>
  );
};

export default TestPlanningPage;
