import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const PROJECT_PATH = BASE_URL + 'projects';
class ProjectApi {
  async get(data) {
    const resp = await axios.get(`${PROJECT_PATH}/${data.id}`);
    return resp.data;
  }

  async getAll(data) {
    const resp = await axios.get(`${PROJECT_PATH}`, { params: { ...data } });
    return resp.data;
  }

  async getAllByUser(data) {
    const resp = await axios.get(`${PROJECT_PATH}/byUser`, { params: { ...data } });
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${PROJECT_PATH}`, data);
    return resp.data;
  }
  async edit(data) {
    const resp = await axios.put(`${PROJECT_PATH}/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${PROJECT_PATH}/${data.id}`);
    return resp.data;
  }
  async fetchProjectOptionList() {
    const resp = await axios.get(`${PROJECT_PATH}/user-projects`);
    return resp.data;
  }

  async fetchManagerProjectOptionList() {
    const resp = await axios.get(`${PROJECT_PATH}/manager-projects`);
    return resp.data;
  }

  async getProjectDetails(id) {
    const resp = await axios.get(`${PROJECT_PATH}/detail`, { headers: { 'X-Project-Id': id } });
    return resp.data;
  }

  async syncRelatedProject(data) {
    const resp = await axios.put(`${PROJECT_PATH}/${data.id}/update`, data?.ids);
    return resp.data;
  }

  async createFromTemplateProject(data) {
    const resp = await axios.post(`${PROJECT_PATH}/by-template/${data.id}`, data);
    return resp.data;
  }
}

export default new ProjectApi();
