import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { setProjecHeader } from '../api/ApiClient';
import { useApi } from './UseApi';
import ProjectApi from '../api/ProjectApi';
import { useApp, useAppDispatch } from '../context/AppContext';
import { saveProject } from '../context/ProjectActions';
// HELPERS
import { isValidValue } from '../helpers/Helper';
import { showErrorMes } from '../helpers/NotificationHelper';
import { getSessionUser, hasPrivilege, isSystemAdmin } from '../helpers/PriviligeHelper';
import { setProjectToken } from '../helpers/ProjectTokenHelper';

const UseQueryProject = (name) => {
  const dispatch = useAppDispatch();
  const app = useApp();
  const location = useLocation();

  const storedProject = app?.project;
  const query = new URLSearchParams(location.search);
  const projectId = query.get(name); //ProjectId

  const projectLoader = useApi({
    name: 'Get project ',
    api: ProjectApi.get,
    onSuccess: () => {
      dispatch(saveProject(projectLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const userHasPrivilege = useCallback(
    (privType) => {
      if (isSystemAdmin()) return true;

      let sessionUser = getSessionUser();
      if (sessionUser) return hasPrivilege(projectId, privType);
      return false;
    },
    [projectId]
  );

  useEffect(() => {
    if (isValidValue(projectId)) {
      if (!storedProject || storedProject?.id !== projectId) {
        projectLoader.execute({ id: projectId });
      }
    }
  }, [storedProject, projectId]);

  useEffect(() => {
    if (projectId) {
      setProjecHeader(projectId);
      setProjectToken(projectId);
    }
  }, [projectId]);

  return useMemo(
    () => ({ projectId: projectId, project: storedProject, userHasPrivilege }),
    [projectId, storedProject, userHasPrivilege]
  );
};

export default UseQueryProject;
