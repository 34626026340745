import { ToggleLabel } from './Toggle.label';
import { ToggleSwitch } from './Toggle.switch';

export const ToggleGroup = (props) => {
  const { onChange, labelLeft, textSize, disabled, labelText, enabled, passValue, setEnabled } = props;

  const getToggleLabelProps = () => ({ textSize, disabled, labelText });
  const getToggleSwitchProps = () => ({ onChange, enabled, disabled, setEnabled, passValue });

  if (labelLeft)
    return (
      <>
        <div className="flex flex-row gap-2">
          <ToggleLabel {...getToggleLabelProps()} />
          <ToggleSwitch {...getToggleSwitchProps()} />
        </div>
      </>
    );

  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <ToggleSwitch {...getToggleSwitchProps()} />
        <ToggleLabel {...getToggleLabelProps()} />
      </div>
    </>
  );
};
