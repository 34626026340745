import React, { useEffect, useState } from 'react';
import DocumentApi from '../../api/DocumentApi';
import { Interweave } from 'interweave';

import { useApi } from '../../hooks/UseApi';
import { Heading } from '../../designSystem/DesignSystem';
import { NoDataMessage } from '../../helpers/TableHelper';
import { useTranslation } from 'react-i18next';

const HeaderBlock = (props) => {
  return (
    <div className="ce-block">
      <div className="ce-block__content">
        {props.level === 2 ? (
          <div className="my-4 text-lg font-semibold text-gray-700">
            <Interweave
              content={props.text}
              tagName="span"
            />
          </div>
        ) : (
          <div className="my-2 text-md font-medium text-gray-700 ">
            <Interweave
              content={props.text}
              tagName="span"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ParagraphBlock = (props) => {
  return (
    <div className="ce-block ">
      <div className="ce-block_content">
        <div className="article-paragraph text-base text-gray-600 ">
          <Interweave
            content={props.text}
            tagName="span"
          />
        </div>
      </div>
    </div>
  );
};

const ImageBlock = (props) => {
  return (
    <div className="ce-block">
      <div className="ce-block__content">
        <div className="cdx-block image-tool image-tool--filled">
          <div className="image-tool__image">
            <a
              href={props.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="image-tool__image-picture"
                src={props.url}
                alt={props.caption}
              />
            </a>
          </div>
          <div className="my-2 flex  justify-center text-base italic text-gray-700">
            <Interweave
              content={props.caption}
              tagName="span"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const UnorderedListBlock = (props) => {
  return (
    <div className="ce-block">
      <div className="ce-block__content">
        <ul className="cdx-block cdx-list cdx-list--unordered">
          {props.items.forEach((item) => (
            <li className="cdx-list__item">
              <Interweave
                content={item}
                tagName="span"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const OrderedListBlock = (props) => {
  return (
    <div className="ce-block">
      <div className="ce-block__content">
        <ol className="cdx-block cdx-list cdx-list--ordered">
          {props.items.map((item, index) => {
            return (
              <li
                key={index}
                className="cdx-list__item"
              >
                <Interweave
                  content={item}
                  tagName="span"
                />
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};
const CodeBlock = (props) => {
  return (
    <div className="ce-block">
      <div className="ce-block__content">
        <div className="cdx-block ce-code">
          <pre className="code-block">
            <code>{props.code}</code>
          </pre>
        </div>
      </div>
    </div>
  );
};

const ArticleViewer = (props) => {
  const { articleId, getHeaders } = props;
  const [error, setError] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [article, setArticle] = useState({});
  const { t } = useTranslation();

  const fetchArticle = useApi({
    name: 'fetching article item',
    api: DocumentApi.getArticle,
    autoLoad: false,
    onSuccess: (res) => {
      setArticle(res);
      setBlocks(JSON.parse(res?.content)?.blocks);
      setError(false);
      getHeaders();
    },
    onError: () => setError(true)
  });

  const getArticle = async (id) => {
    try {
      await fetchArticle.execute(id);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if (articleId) {
      getArticle(articleId);
    }
  }, [articleId]);

  return (
    <div>
      <div id="article-viewer">
        <div
          className="my-8"
          id="article-title-header"
        >
          <Heading
            type="xlarge"
            color={'text-gray-700'}
            text={article?.title}
          ></Heading>
        </div>
        {!error ? (
          blocks?.map((block, index) => {
            if (block?.type === 'header') {
              if (block?.data?.level === 2) {
                return (
                  <HeaderBlock
                    level={2}
                    key={index}
                    text={block?.data?.text}
                  />
                );
              } else if (block?.data?.level === 3) {
                return (
                  <HeaderBlock
                    level={3}
                    key={index}
                    text={block?.data?.text}
                  />
                );
              } else {
                return null;
              }
            } else if (block?.type === 'paragraph') {
              return (
                <ParagraphBlock
                  key={index}
                  text={block?.data?.text}
                />
              );
            } else if (block?.type === 'image') {
              return (
                <ImageBlock
                  url={block?.data?.file?.url}
                  key={index}
                  caption={block?.data?.caption}
                />
              );
            } else if (block?.type === 'list') {
              if (block?.data?.style === 'unordered') {
                return (
                  <UnorderedListBlock
                    key={index}
                    items={block?.data?.items}
                  />
                );
              } else if (block?.data?.style === 'ordered') {
                return (
                  <OrderedListBlock
                    key={index}
                    items={block?.data?.items}
                  />
                );
              } else {
                return null;
              }
            } else if (block?.type === 'code') {
              return (
                <CodeBlock
                  key={index}
                  code={block?.data?.code}
                />
              );
            } else {
              return null;
            }
          })
        ) : (
          <NoDataMessage message={t('Failed to load data. Please try again.')} />
        )}
      </div>
    </div>
  );
};
export default ArticleViewer;
