import React from 'react';
import { isValidList } from '../../../helpers/Helper';
import Table from '../../table/Table';
import { useTranslation } from 'react-i18next';

const CreatedByDefectInsight = ({ response }) => {
  const { t } = useTranslation();

  const getArrList = () => {
    if (isValidList(response)) {
      const arr2 = [];
      const statusColumnKey = [];
      response.forEach((item) => {
        const statusList2 = [];
        const bucketList = item?.['1']?.buckets;
        if (isValidList(bucketList)) {
          bucketList.map((i) => {
            if (!statusColumnKey.includes(i?.key)) {
              statusColumnKey.push(i?.key);
            }
            statusList2.push({ status: i?.key, total: i?.doc_count });
          });
        }

        arr2.push({
          createdby: item?.key,
          total: item?.doc_count,
          statuses: statusList2
        });
      });
      return { dataList: arr2, statusList: statusColumnKey.sort() };
    } // if end
    return { dataList: [], statusList: [] };
  };

  const { dataList, statusList } = getArrList();

  const findStatusTotalVal = (statusKey, item) => {
    const d = item?.statuses.find((x) => x?.status === statusKey);
    if (d && d?.total > 0) {
      return d?.total;
    }
    return 0;
  };

  // Define static table columns

  let tableData = [];
  const tableColumns = [
    { Header: t('Tester / Execution Status'), accessor: 'tester' },
    { Header: t('Total'), accessor: 'total' }
  ];
  // Push additional columns to arr
  statusList.forEach((statusKey) => {
    tableColumns.push({ Header: t(statusKey), accessor: statusKey });
  });

  tableData = dataList.map((item) => {
    let obj;
    item.statuses.forEach((i) => {
      obj = {
        ...obj,
        [i.status]: findStatusTotalVal(i.status, item)
      };
    });
    return {
      tester: item?.tester,
      total: item?.total,
      ...obj
    };
  });

  return (
    <>
      <Table data={tableData} columns={tableColumns} compact />
    </>
  );
};

export default CreatedByDefectInsight;
