import React, { useState } from 'react';
// DESIGN SYSTEM IMPORTS
import { Button, Dropdown, H5 } from '../../designSystem/DesignSystem';
import { Modal } from '../modal';
// HELPER & CONSTANT IMPORTS
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
// import { PRIVILEGES, RunExecutionStatus } from '../../constants';
import { isValidList } from '../../helpers/Helper';
// HOOK && CONTEXT IMPORTS+
import { useApp } from '../../context/AppContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UseQueryProject from '../../hooks/UseQueryProject';
import { useApi } from '../../hooks/UseApi';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import DefectApi from '../../api/DefectApi';
import { getDefectName } from '../../helpers/ArtifactNameHelper';
import UseProjectConfig from '../../hooks/UseProjectConfig';

const BulkAssignModal = ({ runIds, isOpen, closeModal, submitCallBack }) => {
  const { projectId } = UseQueryProject('projectId');
  const userList = UseProjectUsers(projectId);
  const projectConfig = UseProjectConfig(projectId);
  const { control, handleSubmit, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [dataForm, setDataForm] = useState({});
  const { t } = useTranslation();

  const app = useApp();

  const selectedRows = app?.selectedRows.map((item) => item.original.id);

  //#region API
  const bulkAssignee = useApi({
    name: 'Bulk Assignee Defect',
    api: DefectApi.bulkAssignee,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.assign);
      closeModal();
      submitCallBack();
    },
    onError: (err) => showErrorMes(err)
  });
  //#endregion

  const onSubmit = () => {
    let assigneeId = '';
    if (dataForm?.assigneeId) {
      assigneeId = dataForm?.assigneeId;
    }
    const body = {
      ids: runIds,
      assigneeId
    };
    bulkAssignee.execute(body);
  };

  return (
    <Modal
      open={isOpen}
      close={closeModal}
      w={'sm'}
    >
      <Modal.Title>{t('Assign_Defects', { defect: getDefectName(projectConfig) })}</Modal.Title>
      <form
        className="flex flex-grow flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Content h={'xl'}>
          <div className="flex min-h-[240px] flex-grow flex-col gap-5">
            <H5
              text={t('Assign_length', { selectedRows: selectedRows?.length, defect: getDefectName(projectConfig) })}
              className={'font-semibold'}
            />
            <div className="flex gap-4">
              <Dropdown
                control={control}
                name="assigneeId"
                label={t('Assignee')}
                clearable
                placeholder={t('Select Assignee')}
                options={userList}
                passValue={(value) => {
                  setDataForm({ ...dataForm, assigneeId: isValidList(value) ? value[0]?.id : null });
                  if (!isValidList(value)) setValue('assigneeId', null);
                }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="assign-submit-button"
            primary
            text={t('Assign')}
            type="submit"
            loading={bulkAssignee?.loading}
          />
          <Button
            id="assign-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={closeModal}
          />
        </Modal.Buttons>
      </form>
    </Modal>
  );
};

export default BulkAssignModal;
