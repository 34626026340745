import { axios } from './ApiClient';

class AttachmentsApi {
  async getAll(data) {
    const resp = await axios.get(`/api/attachments/${data.id}`);
    return resp.data;
  }

  async upload({ data, config }) {
    const resp = await axios.post(`/api/attachments/upload`, data, config);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`/api/attachments/${data}`);
    return resp.data;
  }
}

export default new AttachmentsApi();
