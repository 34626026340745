import React, { useEffect, useMemo, useState } from 'react';
import VisiumGoIntegrationApi from '../../../../api/VisiumGoIntegrationApi';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../../helpers/ButtonConstants';
import { capitalize, isValidList, isValidValue } from '../../../../helpers/Helper';
import { generateTableColumns, handleSelectedRow, translateColumns } from '../../../../helpers/TableHelper';
import { useApi } from '../../../../hooks/UseApi';
import { Trash } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import BasicTable from '../../../../components/table/BasicTable';
import ConfirmationModal from '../../../../components/confirm/ConfirmationModal';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { Button, Heading } from '../../../../designSystem/DesignSystem';
import AddVisiumGoProjectModal from './modal/AddVisiumGoProjectModal';

const VisiumGoProjectIntegration = ({ selectedVgo, projectId }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState('');
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);

  const getVisiumGoProjects = useApi({
    name: 'visiumGoProjects',
    api: VisiumGoIntegrationApi.getProjects,
    initialValue: false,
    autoLoad: false
  });

  const getConnectedProjects = useApi({
    name: 'get Connected Projects',
    api: VisiumGoIntegrationApi.getConnectedProjects,
    initialValue: false,
    autoLoad: false
  });

  const deleteConnectedProject = useApi({
    name: 'deleteConnectedProject',
    api: VisiumGoIntegrationApi.deleteProject,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      getConnectedProjects.execute({ goIntegrationConfigId: selectedVgo?.id });
      showSuccessMes(INFO_MESSAGES.delete);
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setConfirmVisibility(false)
  });

  const onDelete = () => {
    if (isValidValue(selectedItem?.id)) {
      deleteConnectedProject.execute({ id: selectedItem?.id });
    }
  };
  const convertProjectsTableData = (data) => {
    let arr = [];
    if (isValidList(data)) {
      data.map((item) => {
        arr.push({
          'Project Name': item?.goProjectName,
          'Project Id': item?.goProjectId,
          id: item?.id
        });
      });
    }
    return arr;
  };

  const optionsFn = (row) => {
    return [
      {
        name: t('Delete'),
        icon: (weight) => (
          <Trash
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        onClick: () => {
          handleSelectedRow(getConnectedProjects?.data, row, setSelectedItem);
          setConfirmVisibility(true);
        }
      }
    ];
  };

  const { tableData, tableColumns } = useMemo(() => {
    let dd = [];
    let columns = [];
    if (getConnectedProjects?.data) {
      dd = convertProjectsTableData(getConnectedProjects?.data);
      columns = generateTableColumns(dd, optionsFn, ['id']);
    }
    return {
      tableData: dd,
      tableColumns: columns
    };
  }, [getConnectedProjects?.data]);

  let columns = translateColumns(tableColumns, t);

  useEffect(() => {
    getVisiumGoProjects.execute({ projectId: projectId });
    getConnectedProjects.execute({ goIntegrationConfigId: selectedVgo?.id });
  }, [selectedVgo]);

  return (
    <>
      <div className="mt-5 grid">
        <div className="my-3">
          <Heading.Group
            type="base"
            text={capitalize(`${selectedVgo?.name} `) + t('Projects')}
          >
            <Button
              id="vgoProjects-add-button"
              secondary
              text={t('Add Project')}
              size="small"
              icon="Plus"
              onClick={() => {
                setSelectedItem(undefined);
                setModalVisibility(true);
              }}
            />
          </Heading.Group>
        </div>
        <BasicTable
          columns={columns}
          data={tableData}
          loading={getConnectedProjects?.loading}
        />

        {confirmVisibility === true && (
          <ConfirmationModal
            isOpen={confirmVisibility}
            loading={deleteConnectedProject?.loading}
            closeModal={() => setConfirmVisibility(false)}
            handleConfirm={onDelete}
            header={t('Confirm Delete')}
            content={t('Do you really want to delete this?')}
          />
        )}

        {modalVisibility === true && (
          <AddVisiumGoProjectModal
            isOpen={modalVisibility}
            closeModal={() => {
              setModalVisibility(false);
              getConnectedProjects.execute({ goIntegrationConfigId: selectedVgo?.id });
            }}
            projects={getVisiumGoProjects?.data}
            selectedId={selectedVgo?.id}
          />
        )}
      </div>
    </>
  );
};
export default VisiumGoProjectIntegration;
