import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const GO_INTEGRATION = BASE_URL + 'integration/go';
export const GO_PROJECT_INTEGRATION = BASE_URL + 'integration/go/config';

class VisiumGoIntegrationApi {
  async getAll(data) {
    const resp = await axios.get(`${GO_INTEGRATION}`, { params: data });
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${GO_INTEGRATION}`, data);
    return resp.data;
  }

  async update(data) {
    const resp = await axios.put(`${GO_INTEGRATION}/${data?.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${GO_INTEGRATION}/${data?.id}`);
    return resp.data;
  }

  async getProjects(data) {
    const resp = await axios.get(`${GO_PROJECT_INTEGRATION}/projects/`, { params: data });
    return resp.data;
  }

  async addProjectConfig(data) {
    const resp = await axios.post(`${GO_PROJECT_INTEGRATION}`, data);
    return resp.data;
  }

  async getConnectedProjects(data) {
    const resp = await axios.get(GO_PROJECT_INTEGRATION, { params: data });
    return resp.data;
  }

  async deleteProject(data) {
    const resp = await axios.delete(`${GO_PROJECT_INTEGRATION}/${data?.id}`);
    return resp.data;
  }
}

export default new VisiumGoIntegrationApi();
