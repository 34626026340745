import {axios} from "./ApiClient";

class TokenApi {

    async getTokens(data) {
        const resp = await axios.get(`/api/tokens`,{params:data});
        return resp.data;
    }

    async create(data) {
        const resp = await axios.post(`/api/tokens/`, data);
        return resp.data;
    }
    
    async edit(data) { 
        const resp = await axios.put(`/api/tokens/${data.id}`, data);
        return resp.data;
    }

    async delete(data) {
        const resp = await axios.delete(`/api/tokens/${data.id}`);
        return resp.data;
    }

    async generateToken(data) {
        const resp = await axios.get(`/api/tokens/generate`, {params:data});
        return resp.data;
    }

    async getEndpoint() {
        const resp = await axios.get(`/api/tokens/endpoints`);
        return resp.data;
    }


}

export default new TokenApi();
