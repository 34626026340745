import React, { useEffect, useState } from 'react';
// DESING SYSTEM IMPORTS
import { Dropdown, Input, TextArea, Button, Datepicker } from '../../../designSystem/DesignSystem';
// COMPONENT IMPORTS
import { Modal } from '../../../components/modal';
// HELPER & CONSTAT IMPORTS
import { editModalMapping, isValidList, isValidValue } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { isSystemAdmin } from '../../../helpers/PriviligeHelper';
import { getSystemFieldOptions } from '../../../helpers/EnumHelper';
// API & HOOKS IMPORTS
import { useApi } from '../../../hooks/UseApi';
import { useForm } from 'react-hook-form';
import ProjectApi from '../../../api/ProjectApi';
import SystemFieldApi from '../../../api/SystemFieldApi';
import { useAppDispatch } from '../../../context/AppContext';
import { resetProject } from '../../../context/ProjectActions';
import { titleTranslation, translateArrItemName } from '../../../helpers/TranslationHelper';
import { useTranslation } from 'react-i18next';
import { defaultValue } from '../../../helpers/SystemFieldHelper';

const CreateEditProjectModal = ({ isOpen, dataForm, closeModal, setDataForm, isUpdate, isTemplate }) => {
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const dispatch = useAppDispatch();

  let [statusOptions, setStatus] = useState([]);
  const [tempProjects, setTempProjects] = useState([]);
  const [statusRequired, setStatusRequired] = useState(false);
  const [disableWhenLoading, setDisableWhenLoading] = useState(false);

  const { t } = useTranslation();

  statusOptions = translateArrItemName(statusOptions, t);

  // <-- FN -->
  const onSuccessFn = () => {
    setDisableWhenLoading(false);
    showSuccessMes(INFO_MESSAGES.save);
    closeModal();
  };

  const onErrorFn = (err) => {
    setStatus([]);
    showErrorMes(err);
  };

  // <-- API SET UP START -->;
  const createEditProject = useApi({
    name: 'saving project',
    api: isUpdate ? ProjectApi.edit : ProjectApi.create,
    onSuccess: () => onSuccessFn(),
    onError: (err) => {
      setDisableWhenLoading(false);
      showErrorMes(err);
    }
  });

  const createFromTemplateProject = useApi({
    name: 'saving from template project',
    api: ProjectApi.createFromTemplateProject,
    onSuccess: () => onSuccessFn(),
    onError: (err) => {
      setDisableWhenLoading(false);
      showErrorMes(err);
    }
  });

  const projectStatus = useApi({
    name: 'fetching project status',
    api: SystemFieldApi.fetchDefaultSystemFields,
    onSuccess: () => setStatus(projectStatus.data),
    onError: (err) => onErrorFn(err)
  });

  const entitySystemFieldsLoader = useApi({
    name: 'Get entity system fields',
    api: SystemFieldApi.fetchEntitySystemFieldsWithPermissions,
    onSuccess: () => {
      let statusEnums = getSystemFieldOptions(entitySystemFieldsLoader?.data);
      setStatus(statusEnums);
    },
    onError: (err) => onErrorFn(err)
  });

  const templateProjectLoader = useApi({
    name: 'fetching template projects',
    api: ProjectApi.getAll,
    onSuccess: () => setTempProjects(templateProjectLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const systemFieldLoader = useApi({
    name: 'Get system fields',
    api: SystemFieldApi.fetchSystemFields,
    onSuccess: () => {
      const reqData = systemFieldLoader?.data.find((item) => item?.name === 'status');
      setStatusRequired(reqData?.required);
    },
    onError: (err) => showErrorMes(err)
  });

  // <-- SUBMIT FN -->
  const onSubmit = (data) => {
    setDisableWhenLoading(true);
    const body = {
      ...(isValidValue(dataForm) && { id: dataForm?.id }),
      name: data?.name,
      prefix: data?.prefix,
      startDate: data?.startDate,
      endDate: data?.endDate,
      statusId: isValidValue(data?.status?.value) ? data?.status?.value : data?.status?.id,
      description: data?.description,
      isTemplate: isTemplate,
      ...(isValidValue(dataForm?.templateBy) && { templateBy: dataForm?.templateBy })
    };
    if (isValidValue(dataForm?.templateBy) && !isUpdate) {
      let templateBody = {
        ...body,
        id: dataForm?.templateBy
      };
      createFromTemplateProject.execute(templateBody);
    } else {
      createEditProject.execute(body);
    }
  };

  // <-- ## -->
  useEffect(() => {
    setValue('status', defaultValue(statusOptions));
    if (isValidValue(dataForm) && isUpdate) {
      let mappedArray = editModalMapping(dataForm);
      mappedArray.forEach(({ name, value }) => setValue(name, value));

      setDataForm({ ...dataForm, statusId: dataForm?.status?.id });

      const body = { projectId: dataForm?.id, relatedEntity: 'project', relatedField: 'status' };
      entitySystemFieldsLoader.execute(body);

      systemFieldLoader.execute({ ...body, relatedField: null });
    } else {
      setDataForm(null);
      if (isSystemAdmin()) projectStatus.execute({ relatedEntity: 'project', relatedField: 'status' });
    }

    dispatch(resetProject());

    if (!isTemplate) templateProjectLoader.execute({ isTemplate: true });
  }, []);

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title>{titleTranslation(t('Project'), dataForm, isTemplate, t)}</Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h="md">
            <div className="flex flex-grow flex-col gap-4">
              <div className="flex gap-4">
                <Input
                  required
                  name="prefix"
                  label={t('Prefix')}
                  placeholder={t('Enter a project prefix')}
                  control={control}
                  minLength="1"
                  maxLength="5"
                />
                <Input
                  required
                  name="name"
                  label={t('Name')}
                  placeholder={t('Enter a project name')}
                  control={control}
                  maxLength="255"
                />
              </div>
              <div className="flex flex-grow gap-3">
                {isUpdate ? (
                  <Datepicker
                    clearable
                    control={control}
                    endDateName="endDate"
                    startDateName="startDate"
                    startDateLabel={t('Start Date')}
                    endDateLabel={t('End Date')}
                    startDateForm={dataForm?.startDate}
                    endDateForm={dataForm?.endDate}
                  />
                ) : (
                  <Datepicker
                    clearable
                    control={control}
                    endDateName="endDate"
                    startDateName="startDate"
                    startDateLabel={t('Start Date')}
                    endDateLabel={t('End Date')}
                  />
                )}
              </div>
              <div className="flex gap-3">
                <Dropdown
                  clearable
                  buttonH="h-9"
                  control={control}
                  required={statusRequired}
                  label={t('Status')}
                  name="status"
                  placeholder={t('Select Status')}
                  options={statusOptions}
                  passValue={(value) => {
                    setValue('status', isValidList(value) ? value[0] : null);
                    if (!isValidList(value)) setValue('status', null);
                  }}
                  selectedItem={isValidValue(getValues('status')) ? getValues('status') : defaultValue(statusOptions)}
                />
                {!isTemplate && ((dataForm?.createdBy && dataForm?.templateBy) || !dataForm?.createdBy) && (
                  <Dropdown
                    clearable
                    buttonH="h-9"
                    control={control}
                    label={t('Template Projects')}
                    name="templateBy"
                    disabled={isValidValue(dataForm?.templateBy) && dataForm?.createdBy}
                    placeholder={t('Select Template Projects')}
                    options={tempProjects}
                    passValue={(value) =>
                      setDataForm((prev) => ({
                        ...prev,
                        templateBy: isValidList(value) ? value[0]?.id : null
                      }))
                    }
                    selectedItem={
                      isValidValue(dataForm?.templateBy)
                        ? tempProjects.filter((item) => item?.id === dataForm?.templateBy)[0]
                        : null
                    }
                  />
                )}
              </div>
              <TextArea
                placeholder={t('Enter your text here')}
                control={control}
                name="description"
                label={t('Description')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <>
              <Button
                id="project-cancel-button"
                secondary
                text={t('Cancel')}
                onClick={() => closeModal()}
                disabled={disableWhenLoading}
              />
              <Button
                id="project-submit-button"
                primary
                type="submit"
                text={t('Submit')}
                loading={disableWhenLoading}
              />
            </>
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default CreateEditProjectModal;
