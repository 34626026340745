import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import TestRunApi from '../../api/TestRunApi';
import { Body, Heading } from '../../designSystem/DesignSystem';
import { getTestCaseName } from '../../helpers/ArtifactNameHelper';
import { isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useApi } from '../../hooks/UseApi';
import { useTranslation } from 'react-i18next';

const TestRunSteps = ({ runId, projectConfig, preconditions }) => {
  const [isIncluded, setisIncludedStep] = useState(false);

  const { t } = useTranslation();

  const testRunStepsLoader = useApi({
    name: 'fetching test run steps',
    api: TestRunApi.getSteps,
    autoLoad: false,
    onSuccess: () => isIncludedStep(),
    onError: (err) => showErrorMes(err)
  });

  const isIncludedStep = () => {
    testRunStepsLoader?.data?.forEach((step) => {
      if (step?.includedStep === true) setisIncludedStep(true);
    });
  };

  useEffect(() => {
    if (runId) testRunStepsLoader.execute(runId);
  }, [runId]);

  return (
    <>
      <div className="h-96 divide-y overflow-y-auto">
        {isValidValue(preconditions) && (
          <div className=" m-4 flex flex-row ">
            <div className={`flex-row bg-white`}>
              <Heading
                className="w-36"
                type="small"
                text={t('Preconditions')}
              />
              <div className={'max-h-32 overflow-y-auto'}>
                <Body
                  type="medium"
                  text={preconditions}
                />
              </div>
            </div>
          </div>
        )}

        <table className="flex flex-col py-4">
          <thead className="flex flex-grow bg-gray-50">
            <tr className="flex flex-grow">
              <th className="flex w-12 px-2 py-4">
                <Heading
                  type="xsmall"
                  text="#"
                  uppercase
                />
              </th>

              {isIncluded && (
                <th className="flex w-1/6 py-4">
                  <Heading
                    type="xsmall"
                    text={i18next.t('TestRunStepsRelated', { entity: getTestCaseName(projectConfig) })}
                    uppercase
                  />
                </th>
              )}

              <th className="flex w-1/3 py-4">
                <Heading
                  type="xsmall"
                  text={i18next.t('Step Description')}
                  uppercase
                />
              </th>

              <th className="flex w-1/2	py-4">
                <Heading
                  type="xsmall"
                  text={i18next.t('Expected Result')}
                  uppercase
                />
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 overflow-x-hidden  bg-white">
            {(testRunStepsLoader.data || []).map((item, index) => (
              <tr
                key={item.id}
                className="flex flex-grow"
              >
                <td className="flex w-12 px-2 py-4 ">
                  <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-gray-500">
                    {index + 1}
                  </div>
                </td>

                {isIncluded && (
                  <td className="flex w-1/6 py-4">
                    {item?.includedStep && (
                      <div className="overflow-hidden overflow-ellipsis whitespace-normal text-sm text-gray-500">
                        <Body
                          type="medium"
                          text={item?.testCase.code + ' v' + item.testCase.majorVersion}
                        />
                      </div>
                    )}
                  </td>
                )}

                <td className="flex w-1/3 whitespace-nowrap py-4">
                  <div className="overflow-hidden overflow-ellipsis whitespace-normal text-sm text-gray-500">
                    <Body
                      type="medium"
                      text={i18next.t(item?.description)}
                    />
                  </div>
                </td>

                <td className="flex w-1/2 whitespace-nowrap py-4">
                  <div className="overflow-hidden overflow-ellipsis whitespace-normal text-sm text-gray-500">
                    <Body
                      type="medium"
                      text={i18next.t(item?.expectedResult)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TestRunSteps;
