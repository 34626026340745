import React, { useEffect } from 'react';
import { useApp, useAppDispatch } from '../../../../context/AppContext';
import { PROJECT_ACTION_KEYS } from '../../../../context/Actions';
import { useForm } from 'react-hook-form';
// Api Imports
import ProjectConfigApi from '../../../../api/ProjectConfigApi';
import { useApi } from '../../../../hooks/UseApi';
//Design System Imports
import { Input, Toggle, Button, Heading, P } from '../../../../designSystem/DesignSystem';
import Accordion from '../../../../components/accordion/Accordion';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { getDefectName } from '../../../../helpers/ArtifactNameHelper';
import { useTranslation } from 'react-i18next';

import { saveProjectConfig } from '../../../../context/ProjectActions';
import UseQueryProject from '../../../../hooks/UseQueryProject';
import { isTrue, isValidValue } from '../../../../helpers/Helper';

const HEADING_SIZE = 'small';

const ProjectJiraDefectSettings = ({ projectConfig, jiraConfig }) => {
  const app = useApp();
  const dispatch = useAppDispatch();
  const { projectId } = UseQueryProject('projectId');

  const { control, setValue, getValues } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const { t } = useTranslation();

  const updateProjectConfig = useApi({
    name: 'Updating project config',
    api: ProjectConfigApi.updateProjectConfiguration,
    onSuccess: (response) => {
      dispatch(saveProjectConfig(projectId, response));
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const handleFieldSubmit = (value, key) => {
    let submitValue = value;

    let obj = projectConfig;
    switch (key) {
      case 'createNewJiraDefect':
        obj.configuration.jira.createNewJiraDefect = submitValue;
        break;
      case 'updateJiraDefect':
        obj.configuration.jira.updateJiraDefect = submitValue;
        break;
      case 'showDefectJiraAssigneeToCreate':
        obj.configuration.jira.showDefectJiraAssigneeToCreate = submitValue;
        break;
      case 'jiraAndVManageCommentIntegrationForDefect':
        obj.configuration.jira.jiraAndVManageCommentIntegrationForDefect = submitValue;
        break;
      case 'showDefectJiraLinkToCreate':
        obj.configuration.jira.showDefectJiraLinkToCreate = submitValue;
        break;
      case 'showDefectJiraAssigneeToUpdate':
        obj.configuration.jira.showDefectJiraAssigneeToUpdate = submitValue;
        break;
      case 'showDefectJiraLinkToUpdate':
        obj.configuration.jira.showDefectJiraLinkToUpdate = submitValue;
        break;
      case 'addDefectJiraReporterToCreate':
        obj.configuration.jira.addDefectJiraReporterToCreate = submitValue;
        break;
      case 'showDefectJiraDescriptionToUpdate':
        obj.configuration.jira.showDefectJiraDescriptionToUpdate = submitValue;
        break;
      case 'showDefectJiraDescriptionToCreate':
        obj.configuration.jira.showDefectJiraDescriptionToCreate = submitValue;
        break;
      case 'showDefectJiraPriorityToUpdate':
        obj.configuration.jira.showDefectJiraPriorityToUpdate = submitValue;
        break;
      case 'showDefectJiraPriorityToCreate':
        obj.configuration.jira.showDefectJiraPriorityToCreate = submitValue;
        break;
      default:
        break;
    }

    if (!isValidValue(value)) return;

    if (!isValidValue(value)) return;

    dispatch({
      type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_CONFIG,
      config: { ...obj }
    });

    const body = { id: app.project.id, key, value: submitValue };
    updateProjectConfig.execute(body);
  };

  const saveStringInput = (value, key) => {
    if (projectConfig?.configuration?.jira?.[key] == value) return;

    var body = { id: projectId, key: key, value };
    var obj = {
      ...projectConfig,
      configuration: { ...projectConfig.configuration, jira: { ...projectConfig.configuration.jira, [key]: value } }
    };

    dispatch({
      type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_CONFIG,
      config: { ...obj }
    });

    updateProjectConfig.execute(body);
  };

  useEffect(() => {
    setValue('defectJiraAssigneeCustomField', projectConfig?.configuration?.jira?.defectJiraAssigneeCustomField);
    setValue('defectJiraLinkType', projectConfig?.configuration?.jira?.defectJiraLinkType);
    setValue('defectJiraLinkQuery', projectConfig?.configuration?.jira?.defectJiraLinkQuery);
    setValue('defectSummaryFieldToCreate', projectConfig?.configuration?.jira?.defectSummaryFieldToCreate);
    setValue('defectDescriptionFieldToCreate', projectConfig?.configuration?.jira?.defectDescriptionFieldToCreate);
    setValue('defectManageLinkField', projectConfig?.configuration?.jira?.defectManageLinkField);
  }, [projectConfig]);

  return (
    <div className="flex flex-grow flex-col gap-4">
      <Accordion
        border
        button={
          <Heading
            type={HEADING_SIZE}
            bold
            text={t('Other Settings')}
          />
        }
      >
        <div>
          <div className="mt-2.5">
            <div className="flex w-2/4 items-center gap-3 ">
              <Input
                disabled={jiraConfig?.templateBy}
                name="defectJiraAssigneeCustomField"
                label={t('jiraCustomEntity', { param: getDefectName(projectConfig) })}
                control={control}
                passValue={(value) => setValue('defectJiraAssigneeCustomField', value)}
              />
              <div className="mb-1 flex self-end">
                <Button
                  id="jiraDefectSetting-assignCFSave-button"
                  primary
                  text={t('Save')}
                  size="small"
                  disabled={jiraConfig?.templateBy}
                  onClick={() =>
                    saveStringInput(getValues('defectJiraAssigneeCustomField'), 'defectJiraAssigneeCustomField')
                  }
                />
              </div>
            </div>
            <P
              text={t('customFieldSettingsEntity', { param: getDefectName(projectConfig) })}
              small
              className="mb-4 ml-2"
            />
          </div>
          <div className="flex w-2/4 items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="defectJiraLinkType"
              label={t('jiraLinkType_entity', { param: getDefectName(projectConfig) })}
              control={control}
              passValue={(value) => setValue('defectJiraLinkType', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraDefectSetting-linkTypeSave-button"
                primary
                text={t('Save')}
                size="small"
                disabled={jiraConfig?.templateBy}
                onClick={() => saveStringInput(getValues('defectJiraLinkType'), 'defectJiraLinkType')}
              />
            </div>
          </div>
          <P
            text={t(
              'This field setting will be used for define link type. Link type will be sent according to determinated type. (Example link type : clones )'
            )}
            small
            className="mb-4 ml-2"
          />
          <div className="flex w-2/4  items-center gap-3">
            <Input
              name="defectJiraLinkQuery"
              label={t('Link Query Used to Get Linked Items from Jira For ')}
              control={control}
              passValue={(value) => setValue('defectJiraLinkQuery', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraDefectSetting-linkQuerySave-button"
                primary
                text={t('Save')}
                onClick={() => saveStringInput(getValues('defectJiraLinkQuery'), 'defectJiraLinkQuery')}
                size="small"
              />
            </div>
          </div>
          <P
            text={t('jiraBuild_entity', { param: getDefectName(projectConfig) })}
            small
            className="mb-4 ml-2"
          />
          <div className="flex w-2/4 items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="defectManageLinkField"
              label={t('Jira Custom Field Id to Send Visium Manage Link')}
              control={control}
              passValue={(value) => setValue('defectManageLinkField', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraDefectSetting-linkCFSave-button"
                disabled={jiraConfig?.templateBy}
                onClick={() => saveStringInput(getValues('defectManageLinkField'), 'defectManageLinkField')}
                text={t('Save')}
                primary
                size="small"
              />
            </div>
          </div>
          <P
            text={t('visiumNotSend_Jira', { param: getDefectName(projectConfig) })}
            small
            className="mb-4 ml-2"
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraComment_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.jiraAndVManageCommentIntegrationForDefect)}
            name="jiraAndVManageCommentIntegrationForDefect"
            passValue={(value) => handleFieldSubmit(value, 'jiraAndVManageCommentIntegrationForDefect')}
          />
        </div>
      </Accordion>
      <Accordion
        border
        button={
          <Heading
            type={HEADING_SIZE}
            bold
            text={t('Jira Issue Creation')}
          />
        }
      >
        <div className="flex flex-col gap-2">
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('createJira_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.createNewJiraDefect)}
            name="createNewJiraDefect"
            passValue={(value) => handleFieldSubmit(value, 'createNewJiraDefect')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t(`Add reporter user information into put request payload`)}
            value={isTrue(projectConfig?.configuration?.jira?.addDefectJiraReporterToCreate)}
            name="addDefectJiraReporterToCreate"
            passValue={(value) => handleFieldSubmit(value, 'addDefectJiraReporterToCreate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraAssignee_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showDefectJiraAssigneeToCreate)}
            name="showDefectJiraAssigneeToCreate"
            passValue={(value) => handleFieldSubmit(value, 'showDefectJiraAssigneeToCreate')}
          />

          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('JiraLink_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showDefectJiraLinkToCreate)}
            name="showDefectJiraLinkToCreate"
            passValue={(value) => handleFieldSubmit(value, 'showDefectJiraLinkToCreate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jira_description_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showDefectJiraDescriptionToCreate)}
            name="showDefectJiraDescriptionToCreate"
            passValue={(value) => handleFieldSubmit(value, 'showDefectJiraDescriptionToCreate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jira_priority_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showDefectJiraPriorityToCreate)}
            name="showDefectJiraPriorityToCreate"
            passValue={(value) => handleFieldSubmit(value, 'showDefectJiraPriorityToCreate')}
          />
          <div className="flex w-2/4  items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="defectSummaryFieldToCreate"
              label={t('customField_entity_defect_title', { param: getDefectName(projectConfig) })}
              control={control}
              passValue={(value) => setValue('defectSummaryFieldToCreate', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraDefectSetting-summaryCFSave-button"
                text={t('Save')}
                primary
                size="small"
                disabled={jiraConfig?.templateBy}
                onClick={() => saveStringInput(getValues('defectSummaryFieldToCreate'), 'defectSummaryFieldToCreate')}
              />
            </div>
          </div>
          <P
            text={t('summaryJira_entity_defect', { param: getDefectName(projectConfig) })}
            small
            className="mb-4 ml-2"
          />
          <div className="flex w-2/4  items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="defectDescriptionFieldToCreate"
              label={t('customField_jira_entity', { param: getDefectName(projectConfig) })}
              control={control}
              passValue={(value) => setValue('defectDescriptionFieldToCreate', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraDefectSetting-descriptionCFSave-button"
                primary
                text={t('Save')}
                size="small"
                disabled={jiraConfig?.templateBy}
                onClick={() =>
                  saveStringInput(getValues('defectDescriptionFieldToCreate'), 'defectDescriptionFieldToCreate')
                }
              />
            </div>
          </div>
        </div>
        <P
          text={t('descriptionJira_entity', { param: getDefectName(projectConfig) })}
          small
          className="mb-4 ml-2"
        />
      </Accordion>
      <Accordion
        border
        button={
          <Heading
            type={HEADING_SIZE}
            bold
            text={t('Jira Issue Modification')}
          />
        }
      >
        <div className="flex flex-col gap-2">
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraUpdate_entity', { projectConfig: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.updateJiraDefect)}
            name="updateJiraDefect"
            passValue={(value) => handleFieldSubmit(value, 'updateJiraDefect')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraAssignee_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showDefectJiraAssigneeToUpdate)}
            name="showDefectJiraAssigneeToUpdate"
            passValue={(value) => handleFieldSubmit(value, 'showDefectJiraAssigneeToUpdate')}
          />

          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('JiraLink_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showDefectJiraLinkToUpdate)}
            name="showDefectJiraLinkToUpdate"
            passValue={(value) => handleFieldSubmit(value, 'showDefectJiraLinkToUpdate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jira_description_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showDefectJiraDescriptionToUpdate)}
            name="showDefectJiraDescriptionToUpdate"
            passValue={(value) => handleFieldSubmit(value, 'showDefectJiraDescriptionToUpdate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jira_priority_entity', { param: getDefectName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showDefectJiraPriorityToUpdate)}
            name="showDefectJiraPriorityToUpdate"
            passValue={(value) => handleFieldSubmit(value, 'showDefectJiraPriorityToUpdate')}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default ProjectJiraDefectSettings;
