import React, { useState, useEffect } from 'react';
// API & HOOKS
import TestFolderApi from '../../../api/TestFolderApi';
import { useApi } from '../../../hooks/UseApi';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseCustomField from '../../../hooks/UseCustomField';
import UseProjectUsers from '../../../hooks/UseProjectUsers';
// COMPONENTS & DESIGN SYSTEM
import InsightGrid from '../../../components/insight/InsightGrid';
import CustomEntityDetail from '../../../components/detail/CustomEntityDetail';
// HELPERS & CONSTANTS
import { isValidValue } from '../../../helpers/Helper';
import { ENTITY_TYPES } from '../../../constants';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const TestFolderDetails = ({ selectedItem, submitCallBack, submitCallBackAfterDeletion, submitCallBackAfterClone }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const users = UseProjectUsers(projectId);
  const customFields = UseCustomField(projectId, ENTITY_TYPES.TEST_FOLDER);

  const [dataForm, setDataForm] = useState(null);
  const [filter, setFilter] = useState({});
  const [refreshStatus, setRefreshStatus] = useState(false);

  // <-- API SETUP -->
  const getTestFolders = useApi({
    name: 'fetching test folder item',
    api: TestFolderApi.getTestFolder,
    autoLoad: false,
    onSuccess: () => setDataForm(getTestFolders.data),
    onError: (err) => showErrorMes(err)
  });

  // <-- FN -->
  const refreshData = (form) => {
    if (isValidValue(form)) {
      setDataForm(form);
      submitCallBack(form);
      setRefreshStatus(true);
    } else {
      getTestFolders.execute({ id: selectedItem?.id });
    }
  };

  const afterDelete = (data) => isValidValue(data) && submitCallBackAfterDeletion(data);

  const getTopContent = () => {
    return (
      <div className="mb-4 mt-4 flex flex-grow">
        <InsightGrid
          projectConfig={projectConfig}
          entityId={selectedItem?.id}
          entityType={ENTITY_TYPES.TEST_FOLDER}
        />
      </div>
    );
  };

  useEffect(() => {
    refreshData();
    setFilter({ testFolderId: selectedItem?.id });
  }, [selectedItem]);

  return (
    <>
      <CustomEntityDetail
        config={{
          entityId: selectedItem?.id,
          entityType: ENTITY_TYPES.TEST_FOLDER,
          users,
          refreshStatus,
          setRefreshStatus,
          loading: getTestFolders?.loading,
          dataForm: dataForm,
          selectedItem,
          submitCallBack: refreshData,
          submitCallBackAfterDeletion: afterDelete,
          submitCallBackAfterClone,
          projectId,
          customFields,
          getTopContent,
          filter
        }}
      />
    </>
  );
};
export default TestFolderDetails;
