import React, { useState, useEffect, useCallback } from 'react';
// API AND HOOK IMPORTS
import { useApi } from '../../../hooks/UseApi';
import UserApi from '../../../api/UserApi';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
// COMPONENT IMPORTS
import { Modal } from '../../../components/modal';
// DESIGN SYSTEM IMPORTS
import { Dropdown, Input, Button, H5, H6 } from '../../../designSystem/DesignSystem';
// HELPER IMPORTS
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { userSearchTypesDropdown } from '../../../constants';
import { isValidValue } from '../../../helpers/Helper';
import _, { debounce } from 'lodash';
import { translateArrItemName } from '../../../helpers/TranslationHelper';

const AddProjectUserModal = ({ isOpen, savedUsers, closeModal, projectId, submitCallBack }) => {
  const { control, handleSubmit, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();
  const [allResults, setAllResults] = useState([]);
  const [selectedResults, setSelectedResults] = useState([]);
  const [selectedSearchType, setSelectedSearchType] = useState(userSearchTypesDropdown[0]);
  // TODO: searchkeyword ve loading gereklimi kontrol edilecek.
  // eslint-disable-next-line no-unused-vars
  const [searchKeyword, setSearchKeyword] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  let options = translateArrItemName(userSearchTypesDropdown, t);

  const userLoader = useApi({
    name: 'Get search users',
    api: UserApi.searchUser,
    onSuccess: () => setAllResults(userLoader?.data),
    onError: (err) => {
      showErrorMes(err);
      setAllResults([]);
    }
  });

  const addUsersIntoProject = useApi({
    name: 'Add user into project',
    api: UserApi.addUsersIntoProject,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.add);
      closeModal();
      showSuccessMes('Added user into project successfully.');
      reset();
      submitCallBack();
    },
    onError: (err) => showErrorMes(err)
  });

  const getResults = (value) => {
    let email = null,
      userName = null,
      fullName = null;
    if (selectedSearchType.value === 'email') email = value;
    if (selectedSearchType.value === 'userName') userName = value;
    if (selectedSearchType.value === 'fullName') fullName = value;

    userLoader.execute({ email, userName, fullName, deleted: false });
  };

  const onSubmit = () => {
    let userIds = selectedResults
      ? selectedResults.map((item) => {
          return item.id;
        })
      : [];
    addUsersIntoProject.execute({ userIds: userIds, projectId });
  };

  const onClickAddUser = (user) => {
    setSelectedResults((prev) => (prev ? [...selectedResults, user] : [user]));
  };

  const onClickDeleteUser = (index) => {
    setSelectedResults(selectedResults ? selectedResults.filter((_, i) => i !== index) : []);
  };

  const debounceSearch = useCallback(
    debounce(() => getResults(searchKeyword), 500),
    [searchKeyword]
  );

  const onChangeSearchKeyword = (value) => {
    if (value === '') {
      setLoading(true);
      setSearchKeyword('');
      getResults('');
    } else {
      if (selectedSearchType.value != 'all' && value.length > 3) {
        // TODO: jsx'e loading indicator eklenecek.
        setLoading(true);
        setSearchKeyword(value);
      } else if (selectedSearchType.value === 'all') setLoading(false);
    }
  };

  useEffect(() => {
    if (isValidValue(searchKeyword) && searchKeyword.length > 0) debounceSearch();
    else setAllResults([]);
    return debounceSearch.cancel;
  }, [searchKeyword, debounceSearch]);

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title>{t('Add User into Project')}</Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-5">
              <div className="grid grid-cols-2 divide-x overflow-y-auto">
                <div className="">
                  <div className="flex items-center pb-2">
                    <div className="relative">
                      <Dropdown
                        control={control}
                        name={'search'}
                        options={options}
                        selectedItem={selectedSearchType}
                        passValue={(value) => {
                          setSelectedSearchType(translateArrItemName(value, t)[0]);
                          setSearchKeyword('');
                        }}
                      />
                    </div>
                    <Input
                      classCss={'w-[2px]'}
                      name="name"
                      placeholder={t(`Search`)}
                      control={control}
                      passValue={(value) => onChangeSearchKeyword(value)}
                    />
                  </div>
                  <div className="flex">
                    <table className="flex-grow">
                      <thead className="flex-grow border-b-2 border-gray-300 ">
                        <tr className="flex flex-grow">
                          <div className="flex-grow">
                            <th>{t('User')}</th>
                          </div>
                          <th className="w-1/4">{t('Add')}</th>
                        </tr>
                      </thead>
                      <tbody className="h-96 flex-grow ">
                        {!_.isEmpty(allResults) ? (
                          allResults.map((user) => {
                            return (
                              <tr
                                key={user?.id}
                                className="flex flex-grow"
                              >
                                <td className="w-3/4 place-content-start self-start p-1">
                                  <H5
                                    color={'text-black'}
                                    text={user.name}
                                  />
                                  <H6 text={user.email} />
                                </td>
                                <td className="flex w-1/4 place-content-center self-center">
                                  <Button
                                    id="addProjectUserModal-add-button"
                                    primary
                                    size="small"
                                    icon="ArrowRight"
                                    disabled={
                                      (selectedResults
                                        ? selectedResults.some((value) => {
                                            return value.userName === user.userName;
                                          })
                                        : false) ||
                                      savedUsers.some((value) => {
                                        return value.userName === user.userName;
                                      })
                                    }
                                    onClick={() => onClickAddUser(user)}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <span className="font-semibold text-gray-500 ">{t('No user found with this query')}</span>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="px-3">
                  <div className="flex py-4">
                    <H5
                      text={t('Selected Users')}
                      className={'mb-1 font-semibold'}
                    />
                  </div>
                  <div className="flex">
                    <table className="flex-grow">
                      <thead className="flex-grow border-b-2 border-gray-300 ">
                        <tr className="flex flex-grow">
                          <th className="w-1/4">{t('Delete')}</th>
                          <th>{t('User')}</th>
                        </tr>
                      </thead>
                      <tbody className="flex-grow ">
                        {!_.isEmpty(selectedResults) ? (
                          selectedResults.map((user, index) => {
                            return (
                              <tr
                                key={user?.id}
                                className="flex flex-grow"
                              >
                                <td className="flex w-1/4 place-content-center self-center ">
                                  <Button
                                    id="addProjectUserModal-delete-button"
                                    primary
                                    size="small"
                                    icon="ArrowLeft"
                                    onClick={() => onClickDeleteUser(index)}
                                  />
                                </td>
                                <td className="p-1">
                                  <H5
                                    color={'text-black'}
                                    text={user.name}
                                  />
                                  <H6 text={user.email} />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <span className="font-semibold text-gray-500 ">{t('There is no user selected.')}</span>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="addProjectUserModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="addProjectUserModal-submit-button"
              primary
              text={t('Add Selected Users')}
              type="submit"
              icon="Plus"
              disabled={!selectedResults.length > 0}
              loading={addUsersIntoProject?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default AddProjectUserModal;
