import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ToggleGroup } from './components';

const Toggle = (props) => {
  const { name, control, value, ...toggleGroupProps } = props;
  const [enabled, setEnabled] = useState(value);

  const getToggleGroupProps = (onChange) => ({ onChange, enabled, setEnabled, ...toggleGroupProps });

  useEffect(() => {
    setEnabled(value);
  }, [value]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }) => <ToggleGroup {...getToggleGroupProps(onChange)} />}
      />
    </>
  );
};

export { Toggle };
