/* eslint-disable */
import { Tab } from '@headlessui/react';
import React, { useState, Fragment } from 'react';
import { classNames } from '../../helpers/Helper';
import { useTranslation } from 'react-i18next';

const returnTab = (item, index) => {
  return (
    <>
      <Tab
        as={Fragment}
        key={index}
      >
        {({ selected }) => (
          <button
            style={{ marginBottom: -2 }}
            className={classNames(
              'px-4',
              selected
                ? 'border-b-2 border-primary-700 pb-3 text-sm font-normal text-primary-700'
                : 'border-none text-sm font-normal'
            )}
          >
            {item.label}
          </button>
        )}
      </Tab>
    </>
  );
};

const AdvancedTabBar = ({ tabs }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!tabs) {
    return null;
  }

  return (
    <>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(index) => setSelectedIndex(index)}
      >
        <div className="flex flex-col">
          <div className="flex flex-row justify-between border-b-2 border-gray-200">
            <Tab.List>{tabs?.map((item, index) => returnTab(item, index))}</Tab.List>
            {tabs[selectedIndex]?.topRight ? tabs[selectedIndex].topRight() : null}
          </div>
          <div>{tabs?.[selectedIndex]?.bottomLeft ? tabs[selectedIndex].bottomLeft() : null}</div>
          <div>
            <Tab.Panels>
              {tabs?.map((item, index) => (
                <Tab.Panel key={index}>{item.render()}</Tab.Panel>
              ))}
            </Tab.Panels>
          </div>
        </div>
      </Tab.Group>
    </>
  );
};

export default AdvancedTabBar;
