export const CycleLockEnums = {
    updateJiraRequirementOnTestCyclePage: "updateJiraRequirementOnTestCyclePage",
    updateLinkedRequirementOnRequirementPage: "updateLinkedRequirementOnRequirementPage",
    
    addLink: "addLink",
    updateLink: "updateLink",
    deleteLink: "deleteLink",
    
    addDefect: "addDefect",
    updateDefect: "updateDefect",
    deleteDefect: "deleteDefect",
    cloneDefect: "cloneDefect",
    
    addComment: "addComment",
    updateComment: "updateComment",
    deleteComment: "deleteComment",
    
    update: "update",
    clone: "clone",
    delete: "delete",
    move: "move",
    
    addAttach: "addAttach",
    deleteAttach: "deleteAttach",
    
    addExecutionAttach: "addExecutionAttach",
    deleteExecutionAttach: "deleteExecutionAttach"
};