import React, { useEffect, useState, forwardRef } from 'react';
// LIBRARY IMPORTS
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
// CONSTANTS & HELPER IMPORTS
import {
  isValidValue,
  dateFormatter,
  classNames,
  errorMessage,
  capitalize,
  translatedDateFormat
} from '../../helpers/Helper';
// DESIGN SYSTEM IMPORTS
import { CaretLeft, CaretRight } from 'phosphor-react';
// HOOK IMPORTS
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

const buttonInput = ({ value, onClick }, ref) => {
  return (
    <button
      style={{ minWidth: 150 }}
      onClick={onClick}
      ref={ref}
      type="button"
      className={classNames(
        'inline-flex h-9 w-full justify-start px-3 py-2',
        'text-sm font-medium text-gray-700',
        'rounded-md border border-gray-300 bg-white shadow-sm hover:bg-gray-50',
        'focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0'
      )}
    >
      {value}
    </button>
  );
};
const ButtonInput = forwardRef(buttonInput);

const buttonClassNames = (disabled) => {
  return classNames(
    'inline-flex p-1  bg-white  rounded shadow-sm hover:bg-gray-50',
    disabled && 'cursor-not-allowed opacity-50',
    'text-sm font-medium text-gray-700',
    'border border-gray-300',
    'focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-600'
  );
};

const Datepicker = (props) => {
  const {
    startDateForm,
    endDateForm,
    control,
    startDateName,
    endDateName,
    startDateLabel,
    endDateLabel,
    passValue,
    disabled,
    dateFormat,
    showTimeInput,
    required,
    clearable
  } = props;

  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(isValidValue(startDateForm) ? new Date(startDateForm) : null);
  const [endDate, setEndDate] = useState(isValidValue(endDateForm) ? new Date(endDateForm) : null);

  let days = [t('Su'), t('Mo'), t('Tu'), t('We'), t('Th'), t('Fr'), t('Sa')];
  let months = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December')
  ];

  let locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy',
      dateTime: () => 'mm/dd/yyyy HH:mm',
      time: () => 'HH:mm'
    }
  };
  useEffect(() => {
    if (endDate && startDate > endDate) setStartDate(endDate);
  }, [endDate]);

  return (
    <div className="flex flex-grow gap-2  ">
      <Controller
        control={control}
        name={startDateName}
        rules={{ required: required }}
        render={({ field, formState }) => (
          <>
            <div className="flex flex-1 p-1">
              {/* Start Date */}
              <div className=" relative flex flex-1 flex-col gap-2 ">
                <label className="block text-sm font-medium capitalize">
                  {startDateLabel}
                  {required && startDateLabel && <span className={!disabled && 'pl-2 text-red-500'}>*</span>}
                </label>
                <DatePicker
                  locale={locale}
                  key="start"
                  isClearable={clearable && startDate}
                  disabled={disabled}
                  dateFormat={dateFormat || 'd MMM yyyy'}
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  timeFormat="HH:mm"
                  showTimeInput={showTimeInput || false}
                  maxDate={endDate}
                  placeholderText="Select date"
                  onChange={(e) => {
                    field.onChange(e);
                    setStartDate(e ? dateFormatter(e) : null);
                    if (passValue) passValue({ startDate: dateFormatter(e) });
                  }}
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  popperClassName="react-datepicker-left"
                  customInput={<ButtonInput />}
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                  }) => (
                    <div className="flex items-center justify-between px-2 py-2">
                      <span className="text-sm font-semibold text-gray-700">
                        {showTimeInput ? moment(date).format('MMM yyyy') : translatedDateFormat(date, 'MMM yyyy', t)}
                      </span>
                      <div className="space-x-2">
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                          type="button"
                          className={buttonClassNames(prevMonthButtonDisabled)}
                        >
                          <CaretLeft className="h-3 w-3 text-gray-600" />
                        </button>
                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                          type="button"
                          className={buttonClassNames(nextMonthButtonDisabled)}
                        >
                          <CaretRight className="h-3 w-3 text-gray-600" />
                        </button>
                      </div>
                    </div>
                  )}
                />
                <div className="-mt-3">
                  {formState?.errors[startDateName] && formState?.errors[startDateName]?.type === 'required' && (
                    <Trans t={t}>{errorMessage('required', null, capitalize(startDateLabel), t)}</Trans>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      />
      {endDateName && (
        <Controller
          control={control}
          name={endDateName}
          render={({ field, formState }) => (
            <>
              <div className="flex flex-1 p-1">
                {/* End Date  */}
                <div className="relative flex flex-1 flex-col gap-2">
                  <label className="block text-sm font-medium capitalize">
                    {endDateLabel}
                    {required && endDateLabel && <span className={!disabled && 'pl-2 text-red-500'}>*</span>}
                  </label>
                  <DatePicker
                    locale={locale}
                    key="end"
                    isClearable={clearable && endDate}
                    disabled={disabled}
                    dateFormat={dateFormat || 'd MMM yyyy'}
                    selected={endDate}
                    selectsEnd
                    timeFormat="HH:mm"
                    startDate={startDate}
                    minDate={startDate}
                    showTimeInput={showTimeInput || false}
                    endDate={endDate}
                    placeholderText="Select date"
                    onChange={(e) => {
                      field.onChange(e);
                      setEndDate(e ? dateFormatter(e) : null);
                      if (passValue) passValue({ endDate: dateFormatter(e) });
                    }}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    popperClassName="react-datepicker-left"
                    customInput={<ButtonInput />}
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled
                    }) => (
                      <div className="flex items-center justify-between px-2 py-2">
                        <span className="text-sm font-semibold text-gray-700">
                          {translatedDateFormat(date, 'MMM yyyy', t)}
                        </span>
                        <div className="space-x-2">
                          <button
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                            type="button"
                            className={buttonClassNames(prevMonthButtonDisabled)}
                          >
                            <CaretLeft className="h-3 w-3 text-gray-600" />
                          </button>
                          <button
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                            type="button"
                            className={buttonClassNames(nextMonthButtonDisabled)}
                          >
                            <CaretRight className="h-3 w-3 text-gray-600" />
                          </button>
                        </div>
                      </div>
                    )}
                  />
                  {formState?.errors[endDateName] && formState?.errors[endDateName]?.type === 'required' && (
                    <Trans t={t}>{errorMessage('required', null, endDateName, t)}</Trans>
                  )}
                </div>
              </div>
            </>
          )}
        />
      )}
    </div>
  );
};

export { Datepicker };
