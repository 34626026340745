import React from 'react';
// HELPER & CONSTANT IMPORTS
import { DASHBOARD_PAGE_ENUM } from '../dashboard/helper/DashboardHelper';
import PageAllDashboards from '../dashboard/dpage/PageAllDashboards';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseProjectConfig from '../../hooks/UseProjectConfig';

export const ProjectDashboardPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  return (
    <>
      <PageAllDashboards
        pageName={DASHBOARD_PAGE_ENUM.DASHBOARD_PAGE}
        projectId={projectId}
        projectConfig={projectConfig}
      />
    </>
  );
};

export default ProjectDashboardPage;
