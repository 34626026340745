import { axios } from './ApiClient';

class ProjectConfigApi {
  async get(data) {
    const resp = await axios.get(`/api/project-configs/${data.id}/configs`);
    return resp.data;
  }

  async update(data) {
    const resp = await axios.put(`/api/project-configs/${data.id}/configs`, data);
    return resp.data;
  }

  async updateProjectConfiguration(data) {
    const resp = await axios.put(`/api/project-configs/${data?.id}/configuration`, data);
    return resp.data;
  }

  async updateProjectConfigs(data) {
    const resp = await axios.put(`/api/project-configs/${data?.id}/configs`, data);
    return resp.data;
  }
}

export default new ProjectConfigApi();
