import { useEffect, useMemo } from 'react';
import { uniqueId } from 'lodash-es';
import UserGroupApi from '../api/UserGroupApi';
import { showErrorMes } from '../helpers/NotificationHelper';
import { useApp, useAppDispatch } from '../context/AppContext';
import { removeRequest, addRequest, saveProjectUserGroups } from '../context/ProjectActions';
import { useApi } from './UseApi';

const UseProjectUserGroups = (projectId) => {
  const app = useApp();
  const activeRequests = app?.activeRequests || [];
  const projectUserGroups = app?.projectUserGroups || [];
  const dispatch = useAppDispatch();

  const id = useMemo(() => uniqueId(), []);

  const filterPredicate = (item) => item.projectId !== projectId && item?.type !== 'projectUserGroups';

  const loadProjectUserGroups = useApi({
    name: 'Get all project user groups',
    api: UserGroupApi.getUserGroupOptions,
    autoLoad: false,
    onSuccess: () => dispatch(saveProjectUserGroups(projectId, loadProjectUserGroups?.data)),
    onError: (err) => showErrorMes(err),
    onFinally: () => dispatch(removeRequest(filterPredicate))
  });

  useEffect(() => {
    if (projectId && projectUserGroups?.projectId !== projectId)
      dispatch(addRequest({ order: id, type: 'projectUserGroups', projectId }));
  }, [projectId]);

  useEffect(() => {
    if (activeRequests?.length > 0) {
      const allRequestsForEntity = activeRequests
        .filter((item) => item.projectId === projectId && item.type === 'projectUserGroups')
        .map((item) => item.order);
      if ((allRequestsForEntity || []).indexOf(id) === 0 && projectUserGroups?.projectId !== projectId) {
        loadProjectUserGroups.execute({ projectId });
      }
    }
  }, [activeRequests]);

  return projectUserGroups;
};

export default UseProjectUserGroups;
