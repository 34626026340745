import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as Icons from 'phosphor-react';
import { useElementSize } from 'usehooks-ts';
import { classNames, findId, isValidValue } from '../../helpers/Helper';
import { dropdownOptions, dropdownIcon, renderError, LABEL_SPAN_CLASS, IconComponent } from './Dropdown.Utils';
import {
  useFloating,
  offset,
  flip,
  shift
  // autoPlacement
} from '@floating-ui/react-dom';
import { Trans, useTranslation } from 'react-i18next';

const Dropdown = ({
  label,
  options,
  // value,
  name,
  control,
  selectedItem,
  passValue,
  disabled,
  rules,
  required,
  clearable,
  placeholder,
  textSize,
  buttonH,
  icon,
  isSort = true,
  // style,
  onClear
}) => {
  const { t } = useTranslation();

  const mapOptions = dropdownOptions(options, isSort);
  const [selectedOption, setSelectedOption] = useState(null);

  const [buttonRef, { width }] = useElementSize();

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom',
    strategy: 'absolute',
    middleware: [
      offset(10),
      flip(),
      shift()
      //  autoPlacement()
    ]
  });

  useEffect(() => {
    setSelectedOption(selectedItem ? t(selectedItem?.name) : null);
  }, [selectedItem]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ ...rules, required }}
        render={({ field, formState }) => (
          <div className="flex flex-1 flex-col px-0.5">
            <div
              ref={buttonRef}
              className=" flex-1"
            >
              <Listbox
                as="div"
                disabled={disabled}
                onChange={(e) => {
                  field.onChange(findId(mapOptions, e));
                  setSelectedOption(e);
                  if (passValue) passValue(findId(mapOptions, e));
                }}
                className="flex flex-grow flex-col p-1"
                value={selectedOption}
              >
                {({ open }) => (
                  <>
                    {/* LABEL */}
                    {label && (
                      <Listbox.Label
                        className={classNames(
                          'mb-2 block font-medium leading-5 ',
                          isValidValue(textSize) ? textSize : ' text-sm ',
                          disabled ? ' text-gray-300 ' : 'text-gray-700'
                        )}
                      >
                        {label}
                        {required && <span className={!disabled && LABEL_SPAN_CLASS}>*</span>}
                      </Listbox.Label>
                    )}
                    <div>
                      <Listbox.Button
                        ref={reference}
                        className={classNames(
                          'rounded-md border border-gray-300 bg-white',
                          'flex w-full flex-grow items-center justify-between py-2 pl-3 pr-3',
                          'transition duration-150 ease-in-out',
                          'focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0',
                          'text-left focus:outline-none sm:text-sm sm:leading-5',
                          disabled && 'border-gray-100 bg-gray-50 text-gray-400 placeholder-gray-300',
                          buttonH ? buttonH : ' h-9 ',
                          textSize ? textSize : ' text-sm '
                        )}
                      >
                        {IconComponent(icon) !== null && (
                          <span className="mr-[10px]">
                            {' '}
                            <IconComponent size={12} />{' '}
                          </span>
                        )}
                        <span
                          style={{ marginRight: 10 }}
                          className={' mr-[10px] truncate' + isValidValue(textSize) ? textSize : ' text-xs '}
                        >
                          {isValidValue(selectedOption) ? selectedOption : placeholder}
                        </span>
                        {dropdownIcon(disabled, clearable, selectedOption, setSelectedOption, passValue, onClear)}
                      </Listbox.Button>
                      {/* */}
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          ref={floating}
                          static
                          style={{
                            width,
                            position: strategy,
                            top: y,
                            left: x
                          }}
                          className={classNames(
                            'absolute',
                            'z-9999 mt-2',
                            'max-h-44 overflow-auto rounded-md border border-gray-300 bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                          )}
                        >
                          {mapOptions.map((option) => (
                            <Listbox.Option
                              key={option?.id}
                              value={option?.name}
                              disabled={option?.disabled}
                            >
                              {({ selected, active, disabled }) => (
                                <div
                                  className={classNames(
                                    'flex h-9 flex-grow cursor-pointer select-none justify-between py-2 pl-3 pr-4',
                                    active ? 'bg-primary-600 text-white' : 'text-gray-900',
                                    disabled && 'bg-gray-50 text-gray-400'
                                  )}
                                >
                                  <span
                                    className={classNames('block truncate', selected ? 'font-semibold' : 'font-normal')}
                                  >
                                    {option?.name}
                                  </span>
                                  {selected && (
                                    <span
                                      className={classNames(
                                        'flex items-center pl-1.5',
                                        active ? 'text-white' : 'text-primary-500'
                                      )}
                                    >
                                      <Icons.Check
                                        className="h-5 w-5"
                                        weight="duotone"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <Trans t={t}>{renderError(formState, selectedOption, label, name)}</Trans>
          </div>
        )}
      />
    </>
  );
};

export { Dropdown };
