import React, { useEffect, useState } from 'react';
import { isValidList } from '../../helpers/Helper';
import UseQueryProject from '../../hooks/UseQueryProject';
import { Button } from '../../designSystem/DesignSystem';
import AddFilterModal from './AddFilterModal';
import { useTranslation } from 'react-i18next';
import { BUTTON_SIZE } from './TablePersonalPrefences';
import Popup from '../popup/Popup';
import { CUSTOM_FIELD_PREFIX, ENTITY_TYPES } from '../../constants';

const AddFilterButton = (props) => {
  const { entityType, labelFilters, setLabelFilters, users, allEntityFields, userGroups, setRefreshTable } = props;
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [fieldType, setFieldType] = useState('');
  const { projectId } = UseQueryProject('projectId');

  const [filterOptions, setfilterOptions] = useState([]);
  const [addFilterModalOpen, setAddFilterModalOpen] = useState(false);

  const handleAddFilter = (fieldConfig = {}, selectedOperator = {}, filterValue = []) => {
    const { key } = fieldConfig;

    const filterConf = filterOptions.find((x) => x?.key === key);

    let filterVal = '';
    if (key === 'status' && entityType == ENTITY_TYPES.PROJECT) {
      filterVal = filterValue.map((f) => f?.defaultName).join(',');
    } else if (key === 'jiraPriority' || key === 'jiraStatus') {
      //jira proiroty ve status için name gönderilmeli
      filterVal = filterValue.map((f) => f?.name).join(',');
    } else {
      filterVal = filterValue.map((f) => f?.value).join(',');
    }

    let newFilters = { ...labelFilters };

    if (filterConf?.custom) {
      filterVal = `${selectedOperator?.value}:${filterVal}`;
      newFilters[CUSTOM_FIELD_PREFIX + ':' + key] = filterVal;
    } else {
      newFilters[key] = filterVal;
    }

    if (key === 'lastTestRunExecutionStatus' && entityType == ENTITY_TYPES.TEST_RUN) {
      const newFilterOperator = `${key}:${selectedOperator?.value}`;
      newFilters = { ...newFilters, filter: `${newFilterOperator}` };
    }

    if (setLabelFilters) {
      setLabelFilters(newFilters);
      setRefreshTable(true);
    }
    setAddFilterModalOpen(false);
  };

  useEffect(() => {
    if (isValidList(allEntityFields)) {
      setfilterOptions(allEntityFields);
    }
  }, [allEntityFields]);

  return (
    <>
      <Popup
        open={addFilterModalOpen}
        setOpen={setAddFilterModalOpen}
        button={() => (
          <>
            <Button
              id="selectedTable-addFilter-button"
              icon="Plus"
              weight="bold"
              text={t('Add Filter')}
              iconPosition="right"
              inlineAdd
              type="submit"
              onClick={() => {}}
              size={BUTTON_SIZE}
            />
          </>
        )}
        content={() => (
          <>
            <AddFilterModal
              setFieldType={setFieldType}
              projectId={projectId}
              entityType={entityType}
              isOpen={addFilterModalOpen}
              onClose={() => setAddFilterModalOpen(false)}
              onSubmit={handleAddFilter}
              allEntityFields={allEntityFields}
              users={users}
              userGroups={userGroups}
            />
          </>
        )}
      />
    </>
  );
};

export default AddFilterButton;
