import { axios } from './ApiClient';

const BASE_URL = '/api/';
const TABLE_FILTER_PATH = BASE_URL + 'filtering';

class TableFilterApi {
  async getAllTableFields(data) {
    const resp = await axios.get(`${TABLE_FILTER_PATH}/fields`, { params: data });
    return resp.data;
  }

  async getProjectAndTemplateTableFields(data) {
    const resp = await axios.get(`${TABLE_FILTER_PATH}/base-field/project`, { params: data });
    return resp.data;
  }
}

export default new TableFilterApi();
