import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const DEFECT_PATH = BASE_URL + 'defects';

class DefectApi {
  async getAll(data) {
    const resp = await axios.get(`${DEFECT_PATH}`, { params: data });
    return resp.data;
  }

  async get(id) {
    const resp = await axios.get(`${DEFECT_PATH}/${id}`);
    return resp.data;
  }

  async create(body) {
    const resp = await axios.post(`${DEFECT_PATH}`, body);
    return resp.data;
  }

  async update(body) {
    const resp = await axios.put(`${DEFECT_PATH}/${body?.id}`, body);
    return resp.data;
  }

  async delete({ id }) {
    const resp = await axios.delete(`${DEFECT_PATH}/${id}`);
    return resp.data;
  }

  async bulkDelete(body) {
    const resp = await axios.put(`${DEFECT_PATH}/bulk-delete`, body);
    return resp.data;
  }

  async bulkAssignee(body) {
    const resp = await axios.put(`${DEFECT_PATH}/bulk-assignee`, body);
    return resp.data;
  }

  async clone(body) {
    const resp = await axios.post(`${DEFECT_PATH}/clone`, body);
    return resp.data;
  }

  async getRelatedEntity(id) {
    const resp = await axios.get(`${DEFECT_PATH}/${id}/related-entity`);
    return resp.data;
  }

  async getLockActions() {
    const resp = await axios.get(`${DEFECT_PATH}/lock-actions`);
    return resp.data;
  }

  async editStatus(data) {
    const resp = await axios.put(`${DEFECT_PATH}/${data?.id}/status`, data);
    return resp.data;
  }
  async editPriority(data) {
    const resp = await axios.put(`${DEFECT_PATH}/${data?.id}/priority`, data);
    return resp.data;
  }
  async editType(data) {
    const resp = await axios.put(`${DEFECT_PATH}/${data?.id}/type`, data);
    return resp.data;
  }
  async create3rdpartyDefect({ data, params }) {
    const resp = await axios.post(`${DEFECT_PATH}/3rd`, data, { params });
    return resp.data;
  }
}

export default new DefectApi();
