import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const RELEASE_PATH = BASE_URL + 'product-releases';

class ReleaseApi {
  async getReleaseTreeData(data) {
    const resp = await axios.get(`${RELEASE_PATH}/tree`, { params: data });
    return resp.data;
  }

  async getRelease(data) {
    const resp = await axios.get(`${RELEASE_PATH}/${data.id}`);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${RELEASE_PATH}/${data.id}`);
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${RELEASE_PATH}/`, data);
    return resp.data;
  }
  async getAll(data) {
    const resp = await axios.get(`${RELEASE_PATH}`, { params: data });
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${RELEASE_PATH}/${data.id}`, data);
    return resp.data;
  }

  async assignRequirement(data) {
    const resp = await axios.put(`${RELEASE_PATH}/${data.id}/assign-requirements`, data);
    return resp.data;
  }

  async unassignRequirement(data) {
    const resp = await axios.put(`${RELEASE_PATH}/${data.id}/unassign-requirements`, data?.ids);
    return resp.data;
  }

  async getLockActions() {
    const resp = await axios.get(`${RELEASE_PATH}/lock-actions`);
    return resp.data;
  }
}

export default new ReleaseApi();
