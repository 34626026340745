import { FullPageContainer, FullPageContent } from "../components/Layout";
import React from 'react'

function FullPageTheme() {
    return (
        <FullPageContainer>
            <FullPageContent>
            </FullPageContent>
        </FullPageContainer>
    )
}

export default FullPageTheme;