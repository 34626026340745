import React from 'react';
import { SidePaneRightContent } from '../../../components/Layout';
import { Heading } from '../../../designSystem/DesignSystem';
import SystemParametersForm from './SystemParametersForm';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import SystemParamApi from '../../../api/SystemParamApi';
import { t } from 'i18next';

const PageLayoutWrapper = ({ children }) => {
  return (
    <SidePaneRightContent>
      <SidePaneRightContent.Top>
        <Heading
          type="large"
          text={t('System Parameters')}
        />
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Main isfullPage={true}>
        <SidePaneRightContent.Center>{children}</SidePaneRightContent.Center>
      </SidePaneRightContent.Main>
    </SidePaneRightContent>
  );
};

const SystemParameters = () => {
  const getSystemParameters = useApi({
    name: 'Get System Parameters',
    api: SystemParamApi.getAllSystemParams,
    autoLoad: true,
    onError: (err) => showErrorMes(err)
  });

  const updateSystemParameters = useApi({
    name: 'Update system parameters',
    api: SystemParamApi.updateSystemParam,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.update);
      getSystemParameters.execute();
    },
    onError: (err) => {
      if (err?.response?.data?.message.startsWith('Attachment count must be equal or less than')) {
        showErrorMes(t('max_attachment_count_limit_error', { maxAttachmentCountLimit: 100 }));
      } else if (err?.response?.data?.message.startsWith('Bulk execution limit must be equal or less than')) {
        showErrorMes(t('max_bulk_execution_limit_error', { maxBulkExecutionLimit: 50 }));
      } else {
        showErrorMes(err);
      }
    }
  });

  const handleSubmit = (data) => {
    updateSystemParameters.execute(data);
  };

  const defaultValues = {};
  getSystemParameters.data?.forEach((item) => {
    defaultValues[item.paramKey] = item.paramVal;
  });

  return (
    <>
      <PageLayoutWrapper>
        <SystemParametersForm
          data={getSystemParameters.data}
          loading={getSystemParameters.loading}
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
        />
      </PageLayoutWrapper>
    </>
  );
};

export default SystemParameters;
