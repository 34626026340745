import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
// HOOK IMPORTS
import { useForm } from 'react-hook-form';
// DESIGN SYSTEM IMPORTS
import { Input, Body } from '../../../designSystem/DesignSystem';
import { CircleWavyWarning } from 'phosphor-react';
// HELPER IMPORTS
import { classNames, isValidValue } from '../../../helpers/Helper';
import { useTranslation } from 'react-i18next';

const UserSearch = ({ selectedItem, list, passOnClick }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  // NOT: Hook form sadece input component çalışması için kullanılıyor. Data handle ederken kullanılmıyor.
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();

  const dataNotFound = (message) => {
    return (
      <>
        <div className="my-8 flex flex-col items-center justify-center text-xs text-gray-300">
          <CircleWavyWarning
            size={24}
            weight="duotone"
            color={'blue'}
          />
          <span className="text-sm  text-gray-500 ">{t(message)}</span>
        </div>
      </>
    );
  };

  const resultItem = (item) => {
    return (
      <>
        <div
          className={classNames(
            'cursor-pointer px-8 py-2',
            isValidValue(selectedItem) && selectedItem?.id === item.id ? 'bg-primary-200' : '',
            item?.deleted ? 'bg-gray-100' : ''
          )}
          onClick={() => passOnClick(item)}
        >
          <Body
            bold={selectedItem?.id === item.id}
            color={selectedItem?.id === item.id ? 'text-primary-800' : item?.deleted ? 'text-gray-400' : ''}
            type="base"
            text={item.name}
          />
        </div>
      </>
    );
  };

  const resultsArea = () => {
    let results;
    if (searchTerm !== null) {
      // NO RESULTS
      if (searchResults === undefined || searchResults?.length === 0)
        return dataNotFound('No data found with the search term.');
      // RESULTS
      if (searchResults?.length > 0) results = searchResults.map((item) => resultItem(item));
    } else {
      // NO FILTER RESULTS
      if (list.length > 0) results = list.map((item) => resultItem(item));
      if (list.length === 0 || list === undefined) return dataNotFound('No data found with this project.');
    }

    return (
      <>
        <div className="systemUser-treeview-container">{results}</div>
      </>
    );
  };

  const debouncedSearch = useCallback(debounce(setSearchTerm, 200), []);

  useEffect(() => {
    let lowCaseSearchTerm = searchTerm?.toLowerCase();
    let filteredResults = list.filter((item) => {
      let name = item.name.toLowerCase();
      return name.includes(lowCaseSearchTerm);
    });
    setSearchResults(filteredResults);
  }, [searchTerm]);

  return (
    <>
      <div className="userSearch bg-white ">
        <div className="sticky top-0 bg-white p-2 ">
          <Input
            search
            name="search"
            placeholder={t('Search')}
            control={control}
            passValue={(value) => debouncedSearch(value)}
          />
        </div>
        {resultsArea()}
      </div>
    </>
  );
};

export default UserSearch;
