import { useEffect, useMemo } from 'react';
// DESIGN SYSTEM & COMPONENTS
import { TableColumns } from '../components/table/TableColumns';
// API & HOOKS
import { useApp, useAppDispatch } from '../context/AppContext';
import { saveSelectedColumns } from '../context/ProjectActions';
import UseCustomField from './UseCustomField';
// HELPER & CONSTANTS
import { isValidValue } from '../helpers/Helper';
import { createTableColumns } from '../helpers/TableHelper';

const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    //
    if (order.indexOf(A) > order.indexOf(B)) return 1;
    else return -1;
  });
  return array;
};

const UseTableColumns = (projectId, entityType) => {
  const app = useApp();
  const dispatch = useAppDispatch();

  const customFields = UseCustomField(projectId, entityType);

  const entityFieldConfigs = app?.tablePrefences?.[entityType];

  useEffect(() => {
    const tableColumns = JSON.parse(localStorage.getItem('tableColumns') || '{}');

    let selectedColumns2 = (TableColumns[entityType] || []).filter((c) => c.default).map((c) => c.key);
    if (isValidValue(tableColumns)) {
      if (projectId && entityType) {
        if (tableColumns[entityType] && tableColumns[entityType][projectId]) {
          selectedColumns2 = tableColumns[entityType][projectId];
        }
      }
    }
    dispatch(saveSelectedColumns(projectId, entityType, selectedColumns2));
  }, [projectId, entityType]);

  const onChangeSelectedColumns = (selectedColumns2) => {
    // !TODO: commente alınmış kod incelenecek gereksizse silinecek
    // let tableColumns = localStorage.getItem("tableColumns");
    // let columnConfigs = {};
    // if (tableColumns) columnConfigs = JSON.parse(tableColumns);
    // if (projectId && entityType) {
    //     columnConfigs[entityType] = columnConfigs[entityType] || {};
    //     columnConfigs[entityType][projectId] = selectedColumns;
    // }
    // localStorage.setItem("tableColumns", JSON.stringify(columnConfigs));
    dispatch(saveSelectedColumns(projectId, entityType, selectedColumns2));
  };

  const onResetSelectedColumns = () => {
    if (entityType) {
      let defaultColumns = (TableColumns[entityType] || []).filter((c) => c.default).map((c) => c.key);
      onChangeSelectedColumns(defaultColumns);
    }
  };

  const selectedFields = useMemo(() => {
    if (entityFieldConfigs && entityFieldConfigs[projectId]) {
      return entityFieldConfigs[projectId];
    }
    return [];
  }, [entityFieldConfigs, projectId]);

  const allColumns = useMemo(() => {
    if (entityType) {
      const columnsConfig = TableColumns[entityType];

      let columns = createTableColumns(columnsConfig, customFields);

      if (selectedFields && selectedFields.length) {
        let selectedColumns = columns.filter((c) => selectedFields.includes(c.accessor));
        selectedColumns = mapOrder(selectedColumns, selectedFields, 'accessor');
        let notSelectedColumns = columns.filter((c) => !selectedFields.includes(c.accessor));
        columns = [...selectedColumns, ...notSelectedColumns];
      }
      return columns;
    }
    return [];
  }, [entityType, customFields, selectedFields]);

  const selectedColumns = useMemo(() => {
    if (entityFieldConfigs) {
      const fields = entityFieldConfigs[projectId];
      if (fields && fields.length) {
        const columns = allColumns.filter((c) => fields.includes(c.accessor));
        return columns;
      }
    }
    return [];
  }, [entityFieldConfigs, projectId, allColumns]);
  return { allColumns, selectedColumns, selectedFields, onChangeSelectedColumns, onResetSelectedColumns };
};

export default UseTableColumns;
