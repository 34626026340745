import { isValidList } from '../../helpers/Helper';

export const colorStyle = (statusEnum, status) => {
  if (!statusEnum) return;
  let tempStatus = statusEnum.filter((item) => item?.name?.toLowerCase() === status);
  if (isValidList(tempStatus)) {
    return {
      backgroundColor: tempStatus[0]?.colorCode,
      color: '#494e57'
    };
  }
};
