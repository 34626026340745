import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ENTITY_TYPES } from '../../constants';
import { getRequirementName } from '../../helpers/ArtifactNameHelper';
import { isValidValue } from '../../helpers/Helper';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import { Dropdown, Input } from '../../designSystem/DesignSystem';
import { useTranslation } from 'react-i18next';
import TreeArborist from '../tree/TreeArborist';

const RequirementSearchTree = ({ setSelectedRequirements, setSelectedFolders, showJiraKey }) => {
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const { t } = useTranslation();

  const searchTypesDropdown = [
    {
      key: 'code',
      value: 'code',
      text: `${getRequirementName(projectConfig)} Code`,
      name: `${t('entityCode', { entity: getRequirementName(projectConfig) })}`
    },
    {
      key: 'name',
      value: 'name',
      text: `${getRequirementName(projectConfig)} Name`,
      name: `${t('entityName', { entity: getRequirementName(projectConfig) })} `
    }
  ];

  if (showJiraKey) {
    searchTypesDropdown.push({ key: 'jiraKey', value: 'jiraKey', text: t(`Jira Key`), name: t(`Jira Key`) });
  }

  const [filters, setFilters] = useState({});
  const [searchOption, setSearchOption] = useState(searchTypesDropdown[1]);
  // TODO: search keyword unused kullanıllmıyorsa silinmeli
  // eslint-disable-next-line no-unused-vars
  const [searchKeyword, setSearchKeyword] = useState('');

  const onCheckNode = (nodes = []) => {
    let checkedRequirements = nodes
      .filter((item) => item?.data?.type === ENTITY_TYPES.REQUIREMENT)
      .map((item) => item?.id);
    let checkedFolders = nodes.filter((item) => item?.data?.type === ENTITY_TYPES.FOLDER).map((item) => item?.id);

    setSelectedRequirements(checkedRequirements);
    setSelectedFolders(checkedFolders);
  };

  const onChangeSearchKeyword = (value) => {
    if (isValidValue(value) && isValidValue(searchOption)) {
      let code = null;
      let name = null;
      let jiraKey = null;

      if (searchOption?.key === 'code') {
        code = value;
        setFilters({ type: 'code', searchParameter: code });
      } else if (searchOption?.key === 'name') {
        name = value;
        setFilters({ type: 'name', searchParameter: name });
      } else if (searchOption?.key === 'jiraKey') {
        jiraKey = value;
        setFilters({ type: 'jiraKey', searchParameter: jiraKey });
      }
    } else {
      setFilters({});
    }
  };

  return (
    <form className="flex flex-grow flex-col gap-4">
      <div className="flex items-center py-2 pr-2">
        <div className="">
          <Dropdown
            control={control}
            name={'search'}
            options={searchTypesDropdown}
            selectedItem={searchOption}
            passValue={(value) => {
              setSearchOption(value[0]);
              setSearchKeyword('');
            }}
          />
        </div>
        <Input
          type={searchOption.key === 'code' ? 'number' : 'text'}
          name="name"
          placeholder={t(`Search`)}
          control={control}
          passValue={(value) => onChangeSearchKeyword(value)}
        />
      </div>
      <div className="h-[22vh] max-h-min">
        <TreeArborist
          module={ENTITY_TYPES.REQUIREMENT}
          draggable={false}
          projectId={projectId}
          projectConfig={projectConfig}
          checkable
          filters={filters}
          onCheck={onCheckNode}
          eventsEnabled={false}
        />
      </div>
    </form>
  );
};
export default RequirementSearchTree;
