import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// Api and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import UserGroupApi from '../../../api/UserGroupApi';
// Design System Imports
import { Input, Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
//Helper Imports
import { isValidValue, editModalMapping } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';

import { useTranslation } from 'react-i18next';
import Markdown from '../../../components/markdown/Markdown';

const UserGroupModal = (props) => {
  const { isOpen, data, closeModal, projectId } = props;
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const [activation, setActivation] = useState(false);

  const { t } = useTranslation();

  const createEditUserGroup = useApi({
    name: 'saving user group',
    api: isValidValue(data) ? UserGroupApi.edit : UserGroupApi.create,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal(createEditUserGroup?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    const body = {
      ...(isValidValue(data) && { id: data.id }),
      name: form.name,
      description: form.description,
      projectId: projectId,
      active: activation
    };
    createEditUserGroup.execute(body);
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, []);

  useEffect(() => {
    if (data) {
      setActivation(data.active);
    }
  }, [data]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>
          {' '}
          {isValidValue(data) ? t('Edit ') : t('Create New')} {t('User Group')}
        </Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-5">
              <Input
                required
                name="name"
                label={t('Name')}
                placeholder={t(`Enter a user group name`)}
                control={control}
              />
              <Markdown
                control={control}
                name="description"
                label={t('Description')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="userGroupModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="userGroupModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEditUserGroup?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};
export default UserGroupModal;
