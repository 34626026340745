import React from 'react';
import { TextArea } from '../../../designSystem/DesignSystem';

const MemoCustomField = ({ control, field, disabled }) => {
  return (
    <>
      <TextArea
        disabled={disabled}
        required={field?.required}
        name={field?.customFieldId}
        label={field?.fieldName}
        control={control}
      />
    </>
  );
};
export default MemoCustomField;
