import React, { useState, useEffect, useId } from 'react';
// API and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import UserApi from '../../../api/UserApi';
//Desing System Imports
import { Heading, Button } from '../../../designSystem/DesignSystem';
//Component Imports
import ToggleCard from '../../../components/card/ToggleCard';
import ProjectUserViewModal from './ProjectUserViewModal';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';

import { isValidValue } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { hasPrivilege } from '../../../helpers/PriviligeHelper';
import { PRIVILEGES } from '../../../constants';
import { useTranslation } from 'react-i18next';

const ProjectUserDetails = (props) => {
  const { selectedItem, allRoles, projectId, submitCallBack } = props;
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [templateState, setTemplateState] = useState(false);

  const id = useId();

  const { t } = useTranslation();

  const userProjectRoles = useApi({
    name: 'fetching user project roles',
    api: UserApi.getUserProjectRoles,
    autoLoad: false,
    onSuccess: () => {
      userProjectRoles?.data.forEach((role) => {
        if (role?.name == 'Project Users') {
          if (role.template === true) {
            setTemplateState(true);
          } else {
            setTemplateState(false);
          }
        }
      });
      setCheckedRoles(userProjectRoles?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const userAssignRole = useApi({
    name: 'assign role',
    api: UserApi.assignRole,
    autoLoad: false,
    onSuccess: () => userProjectRoles.execute({ projectId, userId: selectedItem.id }),
    onError: (err) => {
      showErrorMes(err);
      submitCallBack('');
    }
  });

  const userUnassignRole = useApi({
    name: 'unassign role',
    api: UserApi.unassignRole,
    autoLoad: false,
    onSuccess: () => userProjectRoles.execute({ projectId, userId: selectedItem.id }),
    onError: (err) => {
      showErrorMes(err);
      submitCallBack('');
    }
  });

  const userUnassignProject = useApi({
    name: 'unassign project',
    api: UserApi.unassignProject,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.unassign);
      submitCallBack('');
      setOpenDeleteModal(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const isCheckedRole = (id) => {
    let target = checkedRoles.find((x) => x.id === id);
    return isValidValue(target);
  };

  const onHandleCheckbox = (e, data) => {
    if (e) userAssignRole.execute({ userId: selectedItem.id, id: data.id });
    else userUnassignRole.execute({ userId: selectedItem.id, id: data.id });
  };

  const handleDelete = () => userUnassignProject.execute({ userId: selectedItem.id, projectId });

  useEffect(() => {
    if (isValidValue(selectedItem)) userProjectRoles.execute({ projectId, userId: selectedItem.id });
  }, [selectedItem]);

  return (
    <>
      <div className="m-8 flex flex-grow flex-col">
        {/* Users Header */}
        <div className="flex items-center justify-between">
          <Heading
            type="medium"
            text={selectedItem.name}
          />
          <div className="flex gap-4 self-end">
            {hasPrivilege(projectId, PRIVILEGES.VIEW_USER) && (
              <Button
                id="projectUserDetail-view-button"
                tertiary
                text={t('View')}
                size="small"
                icon="UserGear"
                onClick={() => setOpenViewModal(true)}
              />
            )}
            {hasPrivilege(projectId, PRIVILEGES.DELETE_USER) && (
              <Button
                id="projectUserDetails-remove-button"
                tertiary
                text={t('Remove')}
                size="small"
                icon="TrashSimple"
                disabled={templateState}
                onClick={() => setOpenDeleteModal(true)}
              />
            )}
          </div>
        </div>
        {/* Settings Grid */}
        <div className="mt-4">
          <Heading
            type="base"
            text={t('Roles')}
          />
          <div className="mt-4 grid grid-cols-1 gap-6 overflow-y-auto pb-4  xl:grid-cols-2">
            {allRoles.map((role, index) => {
              if (role.defaultRole !== true) {
                return (
                  <ToggleCard
                    key={`${id}-${index}`}
                    name={role.name}
                    labelText={role.description ? role.description : ' - '}
                    title={role.name}
                    checked={isCheckedRole(role.id)}
                    submitCallBack={(e) => onHandleCheckbox(e, role)}
                    disabled={templateState || !hasPrivilege(projectId, PRIVILEGES.ASSIGN_ROLE)}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={openDeleteModal}
        loading={userUnassignRole?.loading}
        closeModal={() => setOpenDeleteModal(false)}
        handleConfirm={handleDelete}
        header={t('Remove User from Project')}
        content={t('Are you sure to remove user from project?')}
      />
      <ProjectUserViewModal
        isOpen={openViewModal}
        closeModal={() => setOpenViewModal(false)}
        selectedItem={selectedItem}
      />
    </>
  );
};

export default ProjectUserDetails;
