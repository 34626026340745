import i18next from 'i18next';
// HELPER & CONSTANT
import { ENTITY_TYPES } from '../constants';
import { getArtifactName, getContainerName, getRequirementName, getTestCaseName } from './ArtifactNameHelper';
import { isValidValue } from './Helper';

export const getEntityName = (data, entityType) => {
  let text = data?.name;
  const code = data?.code;

  if (entityType === ENTITY_TYPES.DEFECT || entityType === ENTITY_TYPES.TEST_RUN) {
    text = data?.summary;
  }
  if (isValidValue(text) && isValidValue(code)) {
    return code + ' ' + getTitleByLength(text);
  } else if (isValidValue(code)) {
    return code;
  } else if (isValidValue(text)) {
    return getTitleByLength(text);
  } else {
    return '';
  }
};

const getTitleByLength = (title) => {
  if (isValidValue(title)) {
    return title;
  }
  return '';
};

const nameSelect = (name, entityType, projectConfig) => {
  let artifactName = getArtifactName(projectConfig, entityType).toLowerCase();
  // name = name.toLowerCase();
  return name.replace(artifactName, '');
};

/**
 * PrivilegeEnum is a method developed for translating parametric values.
 * @param {String} name
 * @param {ENTITY_TYPES} entityType
 * @param {projectConfig} projectConfig
 * @returns String
 */
export const rolePrivilegeTranslate = (name, entityType, projectConfig) => {
  let type = nameSelect(name, entityType, projectConfig).trim();
  let artifactName = getArtifactName(projectConfig, entityType);
  switch (type) {
    case 'Assign Requirement':
      return i18next.t('assingReq', {
        entity: getRequirementName(projectConfig)
      });
    case 'Assign Requirement To Release':
      return i18next.t('assignRequriementtoRelease', {
        entity: getRequirementName(projectConfig),
        entity1: artifactName
      });
    case 'Create Release':
      return i18next.t('createReleaseData', {
        entity: artifactName
      });
    case 'Create Defect':
      return i18next.t('createReleaseData', {
        entity: artifactName
      });
    case 'Delete Release':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Delete Defect':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Unassign Requirement':
      return i18next.t('unassignRequirement1', {
        entity: getRequirementName(projectConfig)
      });
    case 'Unassign Requirement From Release':
      return i18next.t('unassignRequirementFromRelease', {
        entity: getRequirementName(projectConfig),
        entity1: artifactName
      });
    case 'Update Release':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'Update Defect':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'Clone Folder':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Clone Defect':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Create Folder':
      return i18next.t('createFolderData', {
        entity: artifactName
      });
    case 'Delete Folder':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Move Folder':
      return i18next.t('moveFolderIntoAontherFolder', {
        entity: artifactName
      });
    case 'Move Test Run':
      return i18next.t('moveFolderIntoAontherFolder', {
        entity: artifactName
      });
    case 'Update Folder':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'View Folder Details':
      return i18next.t('viewFolderDetails', {
        entity: artifactName
      });
    case 'View Jira Folder Details':
      return i18next.t('viewJiraFolderDetail', {
        entity: artifactName
      });
    case 'Clone Requirement':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Create Requirement':
      return i18next.t('createFolderData', {
        entity: artifactName
      });
    case 'Delete Requirement':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Move Requirement':
      return i18next.t('moveFolderIntoAontherData', {
        entity: artifactName
      });
    case 'Assign Test Case':
      return i18next.t('assingReq', {
        entity: getTestCaseName(projectConfig)
      });
    case 'Assign Test Case in Requirement Detail Page':
      return i18next.t('assignTestCaseInRequirementDetailPage', {
        entity: getTestCaseName(projectConfig),
        entity1: artifactName
      });
    case 'Export Requirement':
      return i18next.t('exportRequirementData', {
        entity: artifactName
      });
    case 'Export Defect':
      return i18next.t('exportRequirementData', {
        entity: artifactName
      });
    case 'Create New Jira Requirement Detail':
      return i18next.t('CreateNewJiraRequirementDetail', {
        entity: artifactName
      });
    case 'Import Requirement':
      return i18next.t('importRequirementData', {
        entity: artifactName
      });
    case 'Unassign Test Case':
      return i18next.t('unassignRequirement1', {
        entity: getTestCaseName(projectConfig)
      });
    case 'Unassign Test Case in Requirement Detail Page':
      return i18next.t('unassignTestCaseInRequirementDetailPage', {
        entity: getTestCaseName(projectConfig),
        entity1: artifactName
      });
    case 'Update Requirement':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'Update Test Case':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'Update Jira Requirement Details':
      return i18next.t('updateJiraRequirementDetails', {
        entity: artifactName
      });
    case 'View Requirement Details':
      return i18next.t('viewFolderDetails', {
        entity: artifactName
      });
    case 'View Jira Details in Requirement Detail Page':
      return i18next.t('viewJiraDetailsInRequirementDetailPage', {
        entity: artifactName
      });
    case 'View Test Cases':
      return i18next.t('viewTestCase', {
        entity: artifactName
      });
    case 'View Test Cases in Requirement Detail Page':
      return i18next.t('viewTestCaseInRequirementDetailPage', {
        entity: getTestCaseName(projectConfig),
        entity1: artifactName
      });
    case 'Update Defect Status':
      return i18next.t('updateDefectStatus', {
        entity: artifactName
      });
    case 'Update Defect Priority':
      return i18next.t('updateDefectPriorty', {
        entity: artifactName
      });
    case 'Update Defect Type':
      return i18next.t('updateDefectType', {
        entity: artifactName
      });
    case 'View defect page, details':
      return i18next.t('viewDefectPageDetail', {
        entity: artifactName
      });
    case 'Clone Test Container':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Create Test Container':
      return i18next.t('createFolderData', {
        entity: artifactName
      });
    case 'Delete Test Container':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Move Test Container':
      return i18next.t('moveFolderIntoAontherData', {
        entity: artifactName
      });
    case 'Update Test Container':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'View Test Container Details':
      return i18next.t('viewFolderDetails', {
        entity: artifactName
      });
    case 'Approve Test Case':
      return i18next.t('approveTestCaseDate', {
        entity: artifactName
      });
    case 'Assign Requirement in test case detail page':
      return i18next.t('assignTestCaseInRequirementDetailPage', {
        entity: getRequirementName(projectConfig),
        entity1: artifactName
      });
    case 'Clone Test Case':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Create Test Case':
      return i18next.t('createReleaseData', {
        entity: artifactName
      });
    case 'Delete Test Case':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Export Test Case':
      return i18next.t('exportRequirementData', {
        entity: artifactName
      });
    case 'Export Test Run':
      return i18next.t('exportRequirementData', {
        entity: artifactName
      });
    case 'Import Test Case':
      return i18next.t('importRequirementData', {
        entity: artifactName
      });
    case 'Move Test Case':
      return i18next.t('moveTestCaseIntoAnotherContainer', {
        entity: artifactName,
        entity1: getContainerName(projectConfig)
      });
    case 'Create New Version of Test Case':
      return i18next.t('testCaseNewVersion', {
        entity: artifactName
      });
    case 'Unassign Requirement in test case detail page':
      return i18next.t('unassignTestCaseInRequirementDetailPage', {
        entity: getRequirementName(projectConfig),
        entity1: artifactName
      });
    case 'View Test Case Details':
      return i18next.t('viewFolderDetails', {
        entity: artifactName
      });
    case 'View Requirements in test case detail page':
      return i18next.t('viewTestCaseInRequirementDetailPage', {
        entity: getRequirementName(projectConfig),
        entity1: artifactName
      });
    case 'View Requirements in  detail page':
      return i18next.t('viewRequirementsInDetailPage', {
        entity: getRequirementName(projectConfig)
      });
    case 'View Requirements':
      return i18next.t('viewRequirement', {
        entity: getRequirementName(projectConfig)
      });
    case 'Clone Test Folder':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Create Test Folder':
      return i18next.t('createReleaseData', {
        entity: artifactName
      });
    case 'Delete Test Folder':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Move Test Folder':
      return i18next.t('moveFolderIntoAontherData', {
        entity: artifactName
      });
    case 'Update Test Folder':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'View Test Folder Details':
      return i18next.t('viewFolderDetails', {
        entity: artifactName
      });
    case 'Clone Test Cycle':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Create Test Cycle':
      return i18next.t('createReleaseData', {
        entity: artifactName
      });
    case 'Delete Test Cycle':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Move Test Cycle':
      return i18next.t('moveTestCycleIntoAnotherDataOrRelease', {
        entity: artifactName
      });
    case 'Update Test Cycle':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'Assign Defect to user':
      return i18next.t('assignDefectToUser', {
        entity: artifactName
      });
    case 'View Test Cycle Details':
      return i18next.t('viewFolderDetails', {
        entity: artifactName
      });
    case 'Update Jira Requirements':
      return i18next.t('updateJiraRequirements', {
        entity: getRequirementName(projectConfig)
      });
    case 'Update related Jira Requirements':
      return i18next.t('updateReletedJiraRequirements', {
        entity: getRequirementName(projectConfig)
      });
    case 'Clone Test Suite':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Create Test Suite':
      return i18next.t('createReleaseData', {
        entity: artifactName
      });
    case 'Delete Test Suite':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'Move Test Suite':
      return i18next.t('moveFolderIntoAontherFolder', {
        entity: artifactName
      });
    case 'View Test Suite Details':
      return i18next.t('viewFolderDetails', {
        entity: artifactName
      });
    case 'Update Test Suite':
      return i18next.t('updateReleaseDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'Clone Test Run':
      return i18next.t('cloneFolderData', {
        entity: artifactName
      });
    case 'Delete Test Run':
      return i18next.t('deleteReleaseData', {
        entity: artifactName
      });
    case 'View Test Run Details':
      return i18next.t('viewFolderDetails', {
        entity: artifactName
      });
    case 'Create/Update Test Run':
      return i18next.t('createUpdateTestRunDataAddDeleteAttachments', {
        entity: artifactName
      });
    case 'Delete Test Execution':
      return i18next.t('deleteTestExecution', {
        entity: artifactName
      });
    case 'Update Test Execution':
      return i18next.t('updateOrComplateTestExecution', {
        entity: artifactName
      });
    case 'Create Test Execution':
      return i18next.t('executeRunANewExecution', {
        entity: artifactName
      });
    case 'Update Test Cycle Status':
      return i18next.t('updateTestCycleStatus', {
        entity: artifactName
      });
    default:
      return i18next.t(type);
  }
};
