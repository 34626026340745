import React from 'react';
import { SidePaneRightContent } from '../../../../components/Layout';
import { Heading } from '../../../../designSystem/DesignSystem';
import WorkFlow from '../../../../components/workFlow/WorkFlow';
import { getCycleName } from '../../../../helpers/ArtifactNameHelper';
import UseProjectConfig from '../../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';

const CycleWorkFlowPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const { t } = useTranslation();

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading
          type="large"
          text={`${getCycleName(projectConfig)} ${t('Workflow')}`}
        />
      </SidePaneRightContent.Top>
      <WorkFlow relatedEntity="testCycle" />
    </>
  );
};

export default CycleWorkFlowPage;
