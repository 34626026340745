// DESIGN SYSTEM IMPORTS
import { X } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import RequirementApi from '../../../api/RequirementApi';
import TestCaseApi from '../../../api/TestCaseApi';
// Component Imports
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import Table from '../../../components/table/Table';
import { TableColumns } from '../../../components/table/TableColumns';
// HELPER IMPORTS
import { ENTITY_TYPES, PRIVILEGES } from '../../../constants';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
import { getPageFilterInput } from '../../../helpers/Helper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { hasPrivilege, isProjectManager } from '../../../helpers/PriviligeHelper';
import {
  DEFAULT_PAGE_PARAMS,
  disableResizingForColumns,
  getNotResizedColumnList,
  getTableActions,
  handleSelectedRow,
  translateColumns
} from '../../../helpers/TableHelper';
// API & HOOK IMPORTS
import { useApi } from '../../../hooks/UseApi';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseTableColumns from '../../../hooks/UseTableColumns';
import UseTableHelper from '../../../hooks/UseTableHelper';
import UseUserTablePreferences from '../../../hooks/UseUserTablePreferences';
import { getContainerName, getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import { getI18n, useTranslation } from 'react-i18next';

const TestCaseReactTable = (props) => {
  const {
    showCheckbox,
    filter,
    projectConfig,
    setRefreshStatus,
    refreshStatus,
    entityType,
    entityId,
    users,
    tableHeight,
    submitCallBack
  } = props;

  const { projectId } = UseQueryProject('projectId');
  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('code', 'ASC'));

  const [selectedItem, setSelectedItem] = useState({});
  const [unassignModalOpen, setUnassignModalOpen] = useState(false);
  const [customFilters, setCustomFilters] = useState({});
  // const [linkModalOpen,setLinkModalOpen] = useState(false)
  const { createTableData } = UseTableHelper();
  const { t } = useTranslation();
  const [rerenderKey, setRerenderKey] = useState(0);

  //<-- API SETUP-->
  const dataLoader = useApi({
    name: 'fetching testcases',
    api: TestCaseApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
      setRefreshStatus(false);
    }
  });

  const unassignData = useApi({
    name: 'Unassign data',
    api: RequirementApi.unassignTestcase,
    onSuccess: () => {
      setUnassignModalOpen(false);

      refreshTable();
      (projectConfig?.configuration?.requirement?.assignedTestCaseControl == 'true' ||
        projectConfig?.configuration?.requirement?.testAutomationControl == 'true') &&
        submitCallBack();
      showSuccessMes(t('unassignRequirement', { entity: getTestCaseName(projectConfig) }));
    },
    onError: (err) => showErrorMes(err)
  });

  const handleUnassign = () => {
    if (
      entityType === ENTITY_TYPES.REQUIREMENT &&
      (hasPrivilege(projectId, PRIVILEGES.UNASSIGN_REQUIREMENT_TESTCASE) || isProjectManager(projectId))
    ) {
      unassignData.execute({ id: entityId, testCaseId: selectedItem?.id });
    }
  };

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    getItems(pageInput, customFilters);
  };

  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.TEST_CASE);

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) {
      fil = { ...fil, ...userSavedFilters?.filterInput };
    }

    let body = { ...fil, ...getPageFilterInput(pageInput), projectId };
    dataLoader.execute(body);
  };

  const refreshTable = () => getByPage({});

  const optionsFn = (row) => {
    if (entityType === ENTITY_TYPES.REQUIREMENT && hasPrivilege(projectId, PRIVILEGES.UNASSIGN_REQUIREMENT_TESTCASE)) {
      return [
        {
          name: 'Unassign',
          icon: (weight) => (
            <X
              size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
              weight={weight}
            />
          ),
          disabled: false,
          onClick: () => {
            handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
            setUnassignModalOpen(true);
          }
        }
      ];
    } else return [];
  };

  const loading = useMemo(() => dataLoader?.loading, [dataLoader?.loading]);

  useEffect(() => {
    if (refreshStatus) {
      refreshTable();
      setRerenderKey((prevKey) => prevKey + 1);
    }
  }, [refreshStatus]);

  const tData = useMemo(() => {
    const columnsConfig = TableColumns[ENTITY_TYPES.TEST_CASE];

    // Container is  a configurable field, so it should be updated
    const containerNameColumn = columnsConfig.find((column) => column?.key === 'parentName');
    if (containerNameColumn?.name)
      containerNameColumn.name = `${t('entityName', { entity: getContainerName(projectConfig) })} `;

    const containerCodeColumn = columnsConfig.find((column) => column?.key === 'parentCode');
    if (containerCodeColumn?.name)
      containerCodeColumn.name = `${t('entityCode', { entity: getContainerName(projectConfig) })} `;

    let data = [];
    if (dataLoader?.data?.content)
      data = dataLoader?.data?.content.map((item) =>
        createTableData(item, columnsConfig, projectId, false, ENTITY_TYPES.TEST_CASE, users)
      );

    return data;
  }, [dataLoader?.data?.content, projectId, getI18n().language]);

  const { selectedColumns } = UseTableColumns(projectId, ENTITY_TYPES.TEST_CASE);
  let columns = [];
  const options = optionsFn();
  if (options.length > 0) {
    columns.push(getTableActions(optionsFn));
  }
  columns.push(...selectedColumns);
  columns = disableResizingForColumns(columns, getNotResizedColumnList(ENTITY_TYPES.TEST_CASE));
  columns = translateColumns(columns, t);

  return (
    <>
      <Table
        key={rerenderKey}
        columns={columns}
        data={tData}
        pagination
        loading={loading}
        pageParams={pageParams}
        getByPage={getByPage}
        onFilter={onFilter}
        entityType={ENTITY_TYPES.TEST_CASE}
        showCheckbox={showCheckbox}
        submitCallBack={() => refreshTable()}
        height={tableHeight}
      />
      {unassignModalOpen && (
        <>
          <ConfirmationModal
            isOpen={unassignModalOpen}
            loading={unassignData?.loading}
            closeModal={() => setUnassignModalOpen(false)}
            handleConfirm={handleUnassign}
            header={t('Unassign')}
            content={t('Are you sure to unassign data?')}
          />
        </>
      )}
    </>
  );
};
export default TestCaseReactTable;
