import React, { useEffect, useState } from 'react';
// DESIGN SYSTEM & COMPONENTS
import { ArrowRight } from 'phosphor-react';
import { Button } from '../../designSystem/DesignSystem';
import { Modal } from '../../components/modal';
// API & HOOKS IMPORTS
import HistoryApi from '../../api/HistoryApi';
import { useApi } from '../../hooks/UseApi';
// HELPER & CONSTANTS
import { formatHistoryTableTitle } from './HistoryHelper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
import { startDateEndDateControl } from '../../helpers/Helper';
import { translateColumns } from '../../helpers/TableHelper';

const HistoryModal = ({ isOpen, closeModal, history }) => {
  const { t } = useTranslation();
  const [diff, setDiff] = useState([]);

  const dataLoader = useApi({
    name: 'get history',
    api: HistoryApi.getDiff,
    autoLoad: false,
    onSuccess: () => setDiff(dataLoader.data),
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    dataLoader.execute({ id: history?.id });
  }, [history]);

  let columns = translateColumns(diff, t);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t('History')}</Modal.Title>

        <Modal.Content h={'md'}>
          <table className="w-full border-collapse text-left text-sm">
            <thead>
              <tr className="h-8">
                <th className=" border-grey-light  border-b text-xs">{t('Field')}</th>
                <th className=" border-grey-light  border-b text-xs"></th>
                <th className=" border-grey-light  border-b text-xs"></th>
                <th className=" border-grey-light  border-b text-xs">{t('Changes')}</th>
              </tr>
            </thead>
            <tbody>
              {columns?.map((elem, index) => {
                return (
                  <tr
                    key={index}
                    className="hover:bg-grey-lighter h-8"
                  >
                    <td className="border-grey-light border-b">{t(formatHistoryTableTitle(elem?.name))}</td>
                    <td className="border-grey-light border-b">{startDateEndDateControl(elem?.oldValue, t)}</td>
                    <td className="border-grey-light border-b">
                      {' '}
                      <ArrowRight size={16} />
                    </td>
                    <td className="border-grey-light border-b">{startDateEndDateControl(elem?.newValue, t)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="historyModal-close-button"
            secondary
            text={t('Close')}
            onClick={() => closeModal()}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default HistoryModal;
