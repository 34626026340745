import { ENTITY_TYPES } from '../../../../constants';
import i18next from 'i18next';
import { isValidValue } from '../../../../helpers/Helper';

export const showJiraAssignee = (projectConfig, isUpdate, entityType) => {
  if (entityType === ENTITY_TYPES.DEFECT) {
    return showDefectJiraAssignee(projectConfig, isUpdate);
  }
  if (entityType === ENTITY_TYPES.REQUIREMENT) {
    return showRequirementJiraAssignee(projectConfig, isUpdate);
  }
  return false;
};

export const showJiraLink = (projectConfig, isUpdate, entityType) => {
  if (entityType === ENTITY_TYPES.DEFECT) {
    return showDefectJiraLink(projectConfig, isUpdate);
  }
  if (entityType === ENTITY_TYPES.REQUIREMENT) {
    return showRequirementJiraLink(projectConfig, isUpdate);
  }
  return false;
};

export const showJiraDescription = (projectConfig, isUpdate, entityType) => {
  if (entityType === ENTITY_TYPES.DEFECT) {
    return showJiraDescriptionDefect(projectConfig, isUpdate);
  }
  if (entityType === ENTITY_TYPES.REQUIREMENT) {
    return showJiraDescriptionRequirement(projectConfig, isUpdate);
  }
  return false;
};

export const showJiraPriority = (projectConfig, isUpdate, entityType) => {
  if (entityType === ENTITY_TYPES.DEFECT) {
    return showJiraPriorityDefect(projectConfig, isUpdate);
  }
  if (entityType === ENTITY_TYPES.REQUIREMENT) {
    return showJiraPriorityRequirement(projectConfig, isUpdate);
  }
  return false;
};

const showDefectJiraAssignee = (projectConfig, isUpdate) => {
  if (isUpdate) {
    return projectConfig?.configuration?.jira?.showDefectJiraAssigneeToUpdate + '' === 'true';
  }
  return projectConfig?.configuration?.jira?.showDefectJiraAssigneeToCreate + '' === 'true';
};

const showDefectJiraLink = (projectConfig, isUpdate) => {
  if (isUpdate) {
    return projectConfig?.configuration?.jira?.showDefectJiraLinkToUpdate + '' === 'true';
  }
  return projectConfig?.configuration?.jira?.showDefectJiraLinkToCreate + '' === 'true';
};

const showJiraDescriptionDefect = (projectConfig, isUpdate) => {
  if (isUpdate) {
    return projectConfig?.configuration?.jira?.showDefectJiraDescriptionToUpdate + '' === 'true';
  }
  return projectConfig?.configuration?.jira?.showDefectJiraDescriptionToCreate + '' === 'true';
};

const showRequirementJiraAssignee = (projectConfig, isUpdate) => {
  if (isUpdate) {
    return projectConfig?.configuration?.jira?.showRequirementJiraAssigneeToUpdate + '' === 'true';
  }
  return projectConfig?.configuration?.jira?.showRequirementJiraAssigneeToCreate + '' === 'true';
};

const showRequirementJiraLink = (projectConfig, isUpdate) => {
  if (isUpdate) {
    return projectConfig?.configuration?.jira?.showRequirementJiraLinkToUpdate + '' === 'true';
  }
  return projectConfig?.configuration?.jira?.showRequirementJiraLinkToCreate + '' === 'true';
};

const showJiraDescriptionRequirement = (projectConfig, isUpdate) => {
  if (isUpdate) {
    return projectConfig?.configuration?.jira?.showRequirementJiraDescriptionToUpdate + '' === 'true';
  }
  return projectConfig?.configuration?.jira?.showRequirementJiraDescriptionToCreate + '' === 'true';
};

const showJiraPriorityDefect = (projectConfig, isUpdate) => {
  if (isUpdate) {
    return projectConfig?.configuration?.jira?.showDefectJiraPriorityToUpdate + '' === 'true';
  }
  return projectConfig?.configuration?.jira?.showDefectJiraPriorityToCreate + '' === 'true';
};

const showJiraPriorityRequirement = (projectConfig, isUpdate) => {
  if (isUpdate) {
    return projectConfig?.configuration?.jira?.showRequirementJiraPriorityToUpdate + '' === 'true';
  }
  return projectConfig?.configuration?.jira?.showRequirementJiraPriorityToCreate + '' === 'true';
};

export const getInfo = (entityType, name) => {
  if (!isValidValue(entityType) || !isValidValue(name)) {
    return '';
  }

  switch (entityType) {
    case ENTITY_TYPES.RELEASE:
      return (
        <>
          <p>{i18next.t('visium_entity', { name })}</p>
          <p>{i18next.t('support_entity', { name })}</p>
          <p>{i18next.t('Vmanage_entity', { name })}</p>
        </>
      );
    case ENTITY_TYPES.DEFECT:
      return (
        <>
          <p>{i18next.t('Visium_defect_entity', { name })}</p>
          <p>{i18next.t('Vmanage_defect_entity', { name })}</p>
          <p>{i18next.t('vmanage_jira_entity', { name })}</p>
        </>
      );
    case ENTITY_TYPES.REQUIREMENT:
      return (
        <>
          <p>{i18next.t('Vmanage_requirement_entity', { name })}</p>
          <p>{i18next.t('vmanage_requirement_jira_entity', { name })}</p>
          <p>{i18next.t('vmanage_content_enitiy', { name })}</p>
        </>
      );
    default:
      return '';
  }
};
