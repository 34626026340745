import { ChartBar } from 'phosphor-react';
// API & HOOKS
import React, { useState, useEffect } from 'react';
// DESIGN SYSTEM IMPORTS
import { ENTITY_TYPES } from '../../../constants';
import EntityDetailModalForReport from '../requirement/EntityDetailModalForReport';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
import Table from '../../table/Table';
// HELPERS & CONSTANTS
import { getCycleName, getRunName } from '../../../helpers/ArtifactNameHelper';
import { getUserFirstLastNameById, translatedDateFormat } from '../../../helpers/Helper';
import { getReportEntityStatusPill } from '../ReportHelper';
import { useTranslation } from 'react-i18next';

const EntityDataTable = ({ response, projectId, projectConfig, users, entityType, jiraConfig }) => {
  const [entityModal, setentityModal] = useState(false);
  const [rowItem, setRowItem] = useState(null);
  const [rowItemType, setRowItemType] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const { t } = useTranslation();

  const tableActions = (row) => {
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          setRowItem(row);
          setRowItemType(entityType);
          setentityModal(true);
        }}
      >
        <ChartBar
          size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
          weight="bold"
        />
      </div>
    );
  };

  const setTable = (response, projectConfig, entityType, users) => {
    if (response !== undefined) {
      if (entityType === ENTITY_TYPES.TEST_CASE) {
        // Set table columns
        setTableColumns([
          { Header: t('Code'), accessor: 'code' },
          { Header: t('Name'), accessor: 'name' },
          { Header: t('Created By'), accessor: 'createdBy' },
          { Header: t('Created Time'), accessor: 'createdTime' }
        ]);
        // Set table data
        setTableData(
          response?.map((item) => {
            return {
              code: item?.code,
              name: item?.name,
              createdBy: getUserFirstLastNameById(users, item?.createdBy),
              createdTime: translatedDateFormat(item?.createdTime, 'DD MMM YYYY HH:mm', t)
            };
          })
        );
      }

      if (entityType === ENTITY_TYPES.TEST_RUN) {
        // Set table columns
        setTableColumns([
          { Header: t('Code'), accessor: 'code' },
          { Header: t('Test Case'), accessor: 'testCase' },
          {
            Header: t('Last Status'),
            accessor: 'lastStatus',
            Cell: ({ value }) => {
              return getReportEntityStatusPill(value, entityType);
            }
          },
          { Header: t('Tester'), accessor: 'tester' },
          { Header: t('Last Execution Start'), accessor: 'lastExecutionStart' },
          { Header: t('Last Execution End'), accessor: 'lastExecutionEnd' },
          {
            Header: t('Actions'),
            accessor: 'actions',
            Cell: ({ row }) => {
              return tableActions(row);
            }
          }
        ]);
        // Set table data
        setTableData(
          response?.map((item) => {
            return {
              code: item?.code,
              testCase: `${item?.code} ${item?.summary}`,
              lastStatus: item?.lastTestRunExecution?.status,
              tester: getUserFirstLastNameById(users, item?.tester?.id),
              lastExecutionStart: translatedDateFormat(
                item?.lastTestRunExecution?.executionStartTime,
                'DD MMM YYYY HH:mm',
                t
              ),
              lastExecutionEnd: translatedDateFormat(
                item?.lastTestRunExecution?.executionEndTime,
                'DD MMM YYYY HH:mm',
                t
              )
            };
          })
        );
      }

      if (entityType === ENTITY_TYPES.DEFECT) {
        // Set table columns
        setTableColumns([
          { Header: t('Name'), accessor: 'name' },
          {
            Header: t('Status'),
            accessor: 'status',
            Cell: ({ value }) => {
              return getReportEntityStatusPill(value, entityType);
            }
          },
          { Header: t('Priority'), accessor: 'priority' },
          { Header: t('Type'), accessor: 'type' },
          { Header: t('Assignee'), accessor: 'assignee' },
          { Header: `${getCycleName(projectConfig)}`, accessor: 'testCycle' },
          { Header: `${getRunName(projectConfig)}`, accessor: 'testRun' },
          {
            Header: t('Actions'),
            accessor: 'actions',
            Cell: ({ row }) => {
              return tableActions(row);
            }
          }
        ]);
        // Set table data
        setTableData(
          response?.map((item) => {
            return {
              name: item?.summary,
              status: item?.status,
              priority: item?.priority,
              type: item?.type,
              assignee: getUserFirstLastNameById(users, item?.assignee?.id),
              testCycle: getCycleName(projectConfig),
              testRun: getRunName(projectConfig),
              jiraPriority: item?.jiraPriority,
              jiraStatus: item?.jiraStatus
            };
          })
        );
      }
    }
  };

  useEffect(() => {
    response && setTable(response, projectConfig, entityType, users);
  }, [response]);

  return (
    <>
      <Table
        data={tableData}
        columns={tableColumns}
        compact
        loading={response ? false : true}
      />
      {entityModal && rowItem !== null && rowItemType !== null && (
        <EntityDetailModalForReport
          item={rowItem}
          users={users}
          projectConfig={projectConfig}
          projectId={projectId}
          entityType={rowItemType}
          jiraConfig={jiraConfig}
          isOpen={entityModal}
          closeModal={() => {
            setentityModal(false);
            setRowItem(null);
            setRowItemType(null);
          }}
        />
      )}
    </>
  );
};

export default EntityDataTable;
