import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { editModalMapping, isValidValue } from '../../../../helpers/Helper';
import { useApi } from '../../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';

import { Input, TextArea, Button, Password } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import JiraApi from '../../../../api/JiraApi';
import { showErrorMes, showSuccessMes, INFO_MESSAGES } from '../../../../helpers/NotificationHelper';
import { encryptData } from '../../../../helpers/CryptoHelper';

const JiraRequestModal = (props) => {
  const { isOpen, closeModal, jiraConfig } = props;
  const { t } = useTranslation();

  // TODO: check if set data is used
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState({});
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const sendRequestLoader = useApi({
    name: 'send jira request',
    api: JiraApi.sendGetRequest,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.sendRequest);

      let mes = '';
      if (isValidValue(sendRequestLoader?.data)) mes = JSON.stringify(sendRequestLoader.data, null, '\t');

      data.response = mes;
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    },
    onError: (err) => {
      data.response = showErrorMes(err);
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  });

  const onSubmit = async (form) => {
    data.path = form?.path;
    let mappedArray = editModalMapping(data);
    mappedArray.forEach(({ name, value }) => setValue(name, value));

    const { username, password } = form;
    const encrUserName = await encryptData(username);
    const enctPassword = await encryptData(password);

    const body = {
      path: form?.path,
      username: encrUserName,
      password: enctPassword
    };
    sendRequestLoader.execute(body);
  };

  useEffect(() => {
    if (jiraConfig) {
      data.path = jiraConfig?.serverUrl + 'rest/api/2/project';
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, [jiraConfig?.id]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t('Get Request')}</Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-5">
              <div className="flex gap-4">
                <Input
                  required={true}
                  name="path"
                  label={t('Path')}
                  placeholder={t(`Enter`)}
                  control={control}
                />
              </div>
              <div className="flex gap-4">
                <Input
                  required={true}
                  name="username"
                  label={t('Username')}
                  placeholder={t(`Enter`)}
                  control={control}
                />
                <Password
                  required={true}
                  name="password"
                  label={t('Password')}
                  control={control}
                />
              </div>

              <TextArea
                control={control}
                name="response"
                label={t('Response')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="jiraRequestModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={sendRequestLoader?.loading}
            />
            <Button
              id="jiraRequestModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default JiraRequestModal;
