import React, { useEffect, useMemo, useState } from 'react';
// DESIGN SYSTEM IMPORT
import { Heading, Button } from '../../../../designSystem/DesignSystem';
import { Circle, PencilLine, Trash } from 'phosphor-react';
// COMPONENT IMPORTS
import BasicTable from '../../../../components/table/BasicTable';
import TokenModal from './TokenModal';
import ConfirmationModal from '../../../../components/confirm/ConfirmationModal';
import { SidePaneRightContent } from '../../../../components/Layout';
// HELPER IMPORTS
import {
  disableResizingForColumns,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../../helpers/TableHelper';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../../helpers/ButtonConstants';
import { getUserFirstLastNameById } from '../../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
// API AND HOOK IMPORTS
import UseAllUsers from '../../../../hooks/UseAllUsers';
import { useApi } from '../../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import ProjectApi from '../../../../api/ProjectApi';
import TokenApi from '../../../../api/TokenApi';

const TokenPage = () => {
  const users = UseAllUsers();
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [openTokenModal, setOpenTokenModal] = useState(false);

  const getProjects = useApi({
    name: 'fetching projects',
    api: ProjectApi.getAll,
    autoLoad: true,
    onError: (err) => showErrorMes(err)
  });

  const getTokens = useApi({
    name: 'fetching tokens',
    api: TokenApi.getTokens,
    autoLoad: false,
    onError: (err) => showErrorMes(err)
  });

  const deleteToken = useApi({
    name: 'delete token',
    api: TokenApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      getTokenList();
      setSelectedItem(null);
      setConfirmOpen(false);
      showSuccessMes(INFO_MESSAGES.delete);
    },
    onError: (err) => showErrorMes(err)
  });

  const getTokenList = () => getTokens.execute();
  const handleDelete = () => deleteToken.execute({ id: selectedItem.id });

  // TABLE OPERATIONS

  const optionsFn = (row) => {
    return [
      {
        name: t('Edit'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(getTokens?.data, row, setSelectedItem);
          setOpenTokenModal(true);
        }
      },
      {
        name: t('Delete'),
        icon: (weight) => (
          <Trash
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(getTokens?.data, row, setSelectedItem);
          setConfirmOpen(true);
        }
      }
    ];
  };

  const convertToTableData = (token) => ({
    name: token.name,
    project: (getProjects.data || []).find((x) => x.id === token.projectId)?.name,
    tokenOwner: getUserFirstLastNameById(users, token.tokenOwner),
    created: getUserFirstLastNameById(users, token.createdBy),
    modified: getUserFirstLastNameById(users, token.modifiedBy),
    activation: token.valid ? (
      <Circle
        size={15}
        color="#348014"
        weight="fill"
      />
    ) : (
      <Circle
        size={15}
        color="#d40808"
        weight="fill"
      />
    ),
    id: token.id
  });

  const { data, tableColumns } = useMemo(() => {
    let tableData,
      columns = [];
    if (getTokens?.data) {
      tableData = getTokens?.data?.map(convertToTableData);
      columns = generateTableColumns(tableData, optionsFn);
    }
    return {
      data: tableData,
      tableColumns: columns
    };
  }, [getTokens?.data, users, getProjects.data]);

  let columns = disableResizingForColumns(tableColumns, [{ accessor: 'actions', width: 30 }]);
  columns = translateColumns(columns, t);

  useEffect(() => {
    getTokenList();
  }, []);

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading.Group
          text={t('Token')}
          type="large"
        >
          <Button
            id="tokenPage-add-button"
            primary
            text={t('Add')}
            size="small"
            icon="Plus"
            onClick={() => setOpenTokenModal(true)}
          />
        </Heading.Group>
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Center colFull={true}>
        <div className="mt-6">
          <BasicTable
            columns={columns}
            data={data}
            loading={getTokens?.loading}
          />
        </div>
      </SidePaneRightContent.Center>
      {confirmOpen && (
        <ConfirmationModal
          isOpen={confirmOpen}
          loading={deleteToken?.loading}
          closeModal={() => setConfirmOpen(false)}
          handleConfirm={handleDelete}
          header={t('Remove Token from Project')}
          content={t('Are you sure to remove this token from project?')}
        />
      )}
      {openTokenModal && (
        <TokenModal
          {...(selectedItem && { data: selectedItem })}
          isOpen={openTokenModal}
          users={users}
          closeModal={() => {
            getTokenList();
            setOpenTokenModal(false);
            setSelectedItem(null);
          }}
        />
      )}
    </>
  );
};

export default TokenPage;
