import React from 'react';
import Tippy from '@tippyjs/react';
import { classNames, translatedDateFormat } from '../../helpers/Helper';
import { isSystemAdmin } from '../../helpers/PriviligeHelper';
import { Body, Heading, Button } from '../../designSystem/DesignSystem';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/themes/light.css';
import { useTranslation } from 'react-i18next';

const ProjectCard = ({ item, index, openProject, openViewModal, openEditModal, setIsUpdate, handleDeleteClick }) => {
  const { t } = useTranslation();

  const returnHeader = () => {
    return (
      <div
        className="flex cursor-pointer flex-col justify-between "
        onClick={() => openProject(item)}
      >
        <Heading
          uppercase={true}
          type="base"
          color="text-primary-500"
          text={item?.prefix}
        />
        <Heading
          notCapitalize={true}
          isNotBold={true}
          className="truncate hover:bg-gray-50"
          type="base"
          color="text-primary-900"
          text={item?.name}
        />
      </div>
    );
  };

  return (
    <div
      id="project-card"
      key={index}
      className={classNames(
        'h-24 w-full cursor-pointer rounded-lg pl-4 pr-4 pt-2',
        'border border-gray-100 bg-white shadow hover:bg-gray-50'
      )}
    >
      <Tippy
        theme="custom-theme"
        tooltip="top"
        className="whitespace-pre-wrap break-all"
        animation="scale-extreme"
        placement={'top'}
        arrow={true}
        content={item?.name}
      >
        {returnHeader()}
      </Tippy>

      {/* <-- CARD EDIT BUTTONS START --> */}
      <div className="flex justify-between">
        <div className="flex items-center">
          <Body
            text={`${translatedDateFormat(item?.startDate, 'DD MMM YYYY', t)} / ${translatedDateFormat(
              item?.endDate,
              'DD MMM YYYY',
              t
            )}`}
            type="small"
            color="text-primary-700"
            // className="text-xs py-1"
          ></Body>
        </div>
        <div>
          <Button
            id="project-view-button"
            noBorder
            secondary
            size="xsmall"
            icon="BookOpen"
            onClick={() => openViewModal(item)}
          />
          {isSystemAdmin() && (
            <>
              <Button
                id="project-edit-button"
                noBorder
                secondary
                size="xsmall"
                icon="Pencil"
                onClick={() => {
                  openEditModal(item);
                  setIsUpdate(true);
                }}
              />
              <Button
                id="project-delete-button"
                noBorder
                secondary
                size="xsmall"
                icon="Trash"
                onClick={() => handleDeleteClick(item)}
              />
            </>
          )}
        </div>
      </div>
      {/* <-- CARD EDIT BUTTONS END --> */}
    </div>
  );
};

export default ProjectCard;
