import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import CustomEntityDetail from '../../../components/detail/CustomEntityDetail';

import { ENTITY_TYPES, PRIVILEGES } from '../../../constants';
import { getNewUrlModalPath } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { hasPrivilege } from '../../../helpers/PriviligeHelper';

import { useApi } from '../../../hooks/UseApi';
import DefectApi from '../../../api/DefectApi';
import JiraEntityApi from '../../../api/JiraEntityApi';
import UseCustomField from '../../../hooks/UseCustomField';
import UseJiraConfig from '../../../hooks/UseJiraConfig';
import UseProjectUsers from '../../../hooks/UseProjectUsers';

const DefectDetailsModal = ({ defectId, projectId, onClose }) => {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  const [dataForm, setDataForm] = useState(null);
  const [loading, setLoading] = useState(null);

  const users = UseProjectUsers();
  const customFields = UseCustomField(projectId, ENTITY_TYPES.DEFECT);
  const jiraConfig = UseJiraConfig(projectId);

  const getDefect = useApi({
    name: 'fetching defect',
    api: DefectApi.get,
    autoLoad: false,
    onSuccess: () => setDataForm(getDefect.data),
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const getDefectWithJira = useApi({
    name: 'fetching defect item with Jira',
    api: JiraEntityApi.fetchDefectJira,
    autoLoad: false,
    onSuccess: () => {
      let temp = {
        jiraContent: getDefectWithJira?.data?.jiraContent,
        jiraId: getDefectWithJira?.data?.jiraId,
        jiraKey: getDefectWithJira?.data?.jiraKey,
        jiraProjectId: getDefectWithJira?.data?.jiraProjectId,
        jiraReleases: getDefectWithJira?.data?.jiraReleases,
        jiraSprints: getDefectWithJira?.data?.jiraSprints
      };
      setDataForm((prev) => ({ ...prev, ...temp }));
    },
    onError: (err) => showErrorMes(err),
    onFinally: () => setLoading(false)
  });

  const refreshData = () => {
    setLoading(true);
    getDefect.execute(defectId);
    if (hasPrivilege(projectId, PRIVILEGES.VIEW_JIRA_DEFECT) && jiraConfig && jiraConfig.activeDefect === true)
      getDefectWithJira.execute({ id: defectId, jiraConfigId: jiraConfig.id });
  };

  const afterClone = (data) => navigate(getNewUrlModalPath(projectId, path, data, ENTITY_TYPES.DEFECT));

  useEffect(() => {
    if (defectId) refreshData();
  }, [defectId]);

  return (
    <>
      <CustomEntityDetail
        config={{
          entityId: defectId,
          entityType: ENTITY_TYPES.DEFECT,
          users,
          loading: loading,
          dataForm: dataForm,
          submitCallBack: refreshData,
          submitCallBackAfterClone: afterClone,
          submitCallBackAfterDeletion: onClose,
          customFields,
          closeModal: onClose,
          projectId
        }}
      />
    </>
  );
};

export default DefectDetailsModal;
