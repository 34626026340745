export const TABLE_ACTION_COLUMNS_STYLE = {
    header: "Actions",
    className: "flex flex-row gap-0",
    iconSize: 14,
    iconWeight: "duotone",
    actionDotIconSize: 18
}
//
export const TABLE_ACTION_BUTTON_CONSTANTS = {
    saveIcon: "FloppyDisk",
    saveColor: "green",
    deleteIcon: "TrashSimple",
    deleteColor: "red",
    addIcon: "Plus",
    addColor: "blue",
    editIcon: "PencilLine",
    editColor: "#4d4da3",
    cloneIcon: "Copy",
    cloneColor: "orange",
    settingIcon: "GearSix",
    settingColor: "#4d4da3",
    previewIcon: "MagnifyingGlassPlus",
    previewColor: "blue",
    visibleIcon: "Eye",
    visibleColor: "green",
    invisibleIcon: "EyeSlash",
    invisibleColor: "grey",
    testIcon: "",
    testColor: "blue",
    size: "small",
    weight: "bold"
}

