import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { Heading } from '../../designSystem/DesignSystem';
import { CaretDown } from 'phosphor-react';
import ReportsSubMenuItem from './ReportsSubMenuItem';
import { useAppDispatch } from '../../context/AppContext';
import { setSelectedReport } from '../../context/ProjectActions';

const ReportsMenuItem = ({ item, key, active, setActive, projectId }) => {
  const [isSubNav2Open, setIsSubNav2Open] = useState();

  const location = useLocation();
  const { t } = useTranslation();

  const menuName = location.pathname.split('/')[2];
  const subMenuName = location.pathname.split('/')[3];
  const dispatch = useAppDispatch();

  const isMenuNameActive = (givenMenuName) => {
    return givenMenuName === menuName;
  };

  const isSubMenuNameActive = (givenSubMenuName) => {
    return givenSubMenuName === subMenuName;
  };

  const handleClick = (e, path) => {
    if (path === active) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    let path = location.pathname.split('/');

    if (path) {
      if (path.length > 0) {
        setActive(path[1]);
        if (path.length > 1) {
          setIsSubNav2Open(path[2]);
        }
      }
    }
  }, []);

  return (
    <>
      <NavLink
        key={key}
        onClick={(e) => {
          handleClick(e, item.path);
        }}
        to={`/reports/${item.path}${item.subNav.length > 0 ? `/${item?.subNav[0]?.id}` : ''}?projectId=${projectId}`}
        className={
          'flex cursor-pointer flex-col py-2 pl-8 text-base ' +
          (!isMenuNameActive(item.path)
            ? 'py-2 text-gray-700'
            : ' border-r-4 border-primary-500 bg-primary-100 font-semibold text-primary-500')
        }
      >
        <div
          className="flex items-center gap-4 pb-1"
          onClick={() => {
            setActive(item.path === active ? ' ' : item.path);
            dispatch(setSelectedReport(item?.subNav[0]));
          }}
        >
          <Heading
            type="base"
            color={({ isActive }) => (isActive ? 'text-primary-500' : 'text-gray-700')}
            text={t(item.name)}
          />
          {item.subNav && item.subNav.length > 0 && (
            <div className={({ isActive }) => (!isActive ? 'text-gray-700' : 'text-primary-700')}>
              <CaretDown
                size="16"
                weight="bold"
              />
            </div>
          )}
        </div>
        {item.subNav &&
          active === item.path &&
          item.subNav.map((subItem, index) => {
            return (
              <ReportsSubMenuItem
                key={index}
                subItem={subItem}
                index={index}
                item={item}
                projectId={projectId}
                isSubNav2Open={isSubNav2Open}
                setIsSubNav2Open={setIsSubNav2Open}
                isSubMenuNameActive={isSubMenuNameActive}
              />
            );
          })}
      </NavLink>
    </>
  );
};

export default ReportsMenuItem;
