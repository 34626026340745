import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const REQ_PATH = BASE_URL + 'requirements';

class RequirementApi {
  async getAll(data) {
    const resp = await axios.get(`${REQ_PATH}`, { params: data });
    return resp.data;
  }

  async get(data) {
    const resp = await axios.get(`${REQ_PATH}/${data.id}`);
    return resp.data;
  }

  async getByParent(data) {
    const resp = await axios.get(`${REQ_PATH}/byParent`, { params: data });
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${REQ_PATH}/${data.id}`);
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${REQ_PATH}/`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${REQ_PATH}/${data.id}`, data);
    return resp.data;
  }

  async clone(data) {
    const resp = await axios.post(`${REQ_PATH}/clone`, data);
    return resp.data;
  }

  async assignTestcase(data) {
    const resp = await axios.put(`${REQ_PATH}/${data?.id}/assign-testcase`, data);
    return resp.data;
  }

  async unassignTestcase(data) {
    const resp = await axios.put(`${REQ_PATH}/${data?.id}/unassign-testcase`, data);
    return resp.data;
  }

  async bulkUnassignTestcase(data) {
    const resp = await axios.put(`${REQ_PATH}/${data?.id}/bulk-unassign-testcases`, data?.ids);
    return resp.data;
  }

  async getParens(id) {
    const resp = await axios.get(`${REQ_PATH}/${id}/parents`);
    return resp.data;
  }

  async updateRequirementParent(id, body) {
    const resp = await axios.put(`${REQ_PATH}/${id}/parent`, body);
    return resp.data;
  }

  async getLockActions() {
    const resp = await axios.get(`${REQ_PATH}/lock-actions`);
    return resp.data;
  }

  async getTreeData(params) {
    const resp = await axios.get(`${REQ_PATH}/tree`, { params });
    return resp.data;
  }
}

export default new RequirementApi();
