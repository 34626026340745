import React, { Fragment } from 'react';
import { Heading } from '../../designSystem/DesignSystem';

const CollectionList = (props) => {
  const { collections, selectArticle } = props;

  return (
    <div className="treeview-container">
      {collections?.map((item) => {
        return (
          <>
            <Fragment key={item?.id}>
              <div className="flex cursor-pointer flex-col py-2 pl-8 text-lg">
                <Heading
                  type="medium"
                  color={'text-gray-700'}
                  text={item?.name}
                />
              </div>
              {item?.articles?.map((art) => {
                return (
                  <div key={art.id}>
                    <div
                      onClick={() => selectArticle(art.id)}
                      className={
                        ' cursor pointer flex flex-col py-2 pl-12 ml-1' +
                        (props.articleId != art.id
                          ? 'py-2'
                          : ' border-r-4 border-primary-700 bg-primary-100  font-semibold text-primary-700')
                      }
                    >
                      <Heading
                        type="base"
                        color={'text-gray-700'}
                        text={art?.title}
                      />
                    </div>
                  </div>
                );
              })}
            </Fragment>
          </>
        );
      })}
    </div>
  );
};

export default CollectionList;
