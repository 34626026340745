import React from 'react';
import BulkDropdown from '../bulk/BulkDropdown';

const TableBulkOperations = (props) => {
  const { entityType, submitCallBack, entityId } = props;

  return (
    <>
      <BulkDropdown
        entityId={entityId}
        entityType={entityType}
        submitCallBack={submitCallBack}
      />
    </>
  );
};
export default TableBulkOperations;
