import { ENTITY_TYPES } from '../../constants';
import { ReleaseTreeActions, TestCaseTreeActions, TreeActions } from './actions';
import { RequirementTreeActions } from './actions/RequirementTreeActions';
import { TestSuiteTreeActions } from './actions/TestSuiteTreeActions';

export class TreeActionsFactory {
  static get(module, projectConfig, checkable = false) {
    switch (module) {
      case ENTITY_TYPES.RELEASE:
        return new ReleaseTreeActions(projectConfig, checkable);
      case ENTITY_TYPES.TEST_CASE:
        return new TestCaseTreeActions(projectConfig, checkable);
      case ENTITY_TYPES.REQUIREMENT:
        return new RequirementTreeActions(projectConfig, checkable);
      case ENTITY_TYPES.TEST_SUITE:
        return new TestSuiteTreeActions(projectConfig, checkable);
      default:
        return new TreeActions(projectConfig, checkable);
    }
  }
}
