import { axios } from './ApiClient';

class UserApi {
  async getAllUsers() {
    const resp = await axios.get(`/api/users`);
    return resp.data;
  }

  async getUser(data) {
    const resp = await axios.get(`/api/users/${data.id}`);
    return resp.data;
  }

  async getPasswordRule() {
    const resp = await axios.get(`/api/users/password-rule`);
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`/api/users`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`/api/users/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`/api/users/${data.id}`);
    return resp.data;
  }

  async recover(data) {
    const resp = await axios.put(`/api/users/${data.id}/recover`);
    return resp.data;
  }

  async getProjectUsersWithAdmins(data) {
    const resp = await axios.get(`/api/users/project-users`, { params: data });
    return resp.data;
  }

  async getUserProjectRoles(data) {
    const resp = await axios.get(`/api/users/roles`, { params: data });
    return resp.data;
  }

  async assignRole(data) {
    const resp = await axios.put(`/api/users/${data.userId}/assign-roles`, data);
    return resp.data;
  }

  async unassignRole(data) {
    const resp = await axios.put(`/api/users/${data.userId}/unassign-roles`, data);
    return resp.data;
  }

  async unassignProject(data) {
    const resp = await axios.put(`/api/users/${data.userId}/unassign-projects`, { id: data.projectId });
    return resp.data;
  }

  async searchUser(data) {
    const resp = await axios.get(`/api/users`, { params: data });
    return resp.data;
  }

  async getProjectUserRoleDetails(data) {
    const resp = await axios.get(`/api/users/project-user-role-details`, { params: data });
    return resp.data;
  }

  async addUsersIntoProject(data) {
    const resp = await axios.put(`/api/users/assign-projects`, data);
    return resp.data;
  }

  async searchLdapUser(data) {
    const resp = await axios.get(`/api/users/search-ldap`, { params: data });
    return resp.data;
  }

  async addLdapUser(data) {
    const resp = await axios.post(`/api/users/ldap-user`, data);
    return resp.data;
  }
}

export default new UserApi();
