// Design Import
import {
  Bug,
  ChartBar,
  ChartLine,
  Eye,
  Files,
  GearSix,
  List,
  Package,
  PlayCircle,
  PresentationChart,
  Queue,
  SignOut,
  Target,
  // Translate,
  User,
  UserCircle,
  Info
} from 'phosphor-react';
import React, { useMemo, useState, useId, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { logout } from '../../../api/ApiClient';
// Helper & Constant Import
import { PRIVILEGES } from '../../../constants';
import { classNames, isValidValue } from '../../../helpers/Helper';
import { PageNameWithProject } from '../../../helpers/PageNameWithProject';
import {
  hasPrivilege,
  isMemberOfProject,
  isProjectManager,
  isSystemAdmin,
  isViewDefectPrivileged,
  isViewReleasePrivileged,
  isViewReport,
  isViewRequirementPrivileged,
  isViewTestCycle,
  isViewTesterPage,
  isViewTestFolder,
  isViewTestSuite
} from '../../../helpers/PriviligeHelper';
import { useTranslation } from 'react-i18next';

// Hooks Import
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
import NavbarLink from './NavbarLink';

import { ReactComponent as TrFlag } from './assets/tr.svg';
import { ReactComponent as EnFlag } from './assets/en.svg';
import { useApp } from '../../../context/AppContext';

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState('hidden');
  const [isUserMenuVisible, setUserMenuVisible] = useState(false);

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('lang') || 'en');

  // TODO: handle language change üzerine çalışılmalı.
  //eslint-disable-next-line no-unused-vars
  const handleLanguageChange = (lang) => {
    localStorage.setItem('lang', lang);
    window.location.reload();
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
  }, [language]);

  const { project, projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const id = useId();
  const iconProps = { color: '#ffffff', weight: 'regular', size: 20 };
  const iconPropsNoColor = { weight: 'regular', size: 20 };

  const app = useApp();
  const selectedReport = app?.selectedReport;

  const templateMenu = {
    showIcon: true,
    text: t('Templates'),
    to: `/project-templates`,
    icon: () => <PresentationChart {...iconProps} />,
    display: !project && isSystemAdmin(),
    position: 'right'
  };
  const systemSettingMenu = {
    showIcon: true,
    text: t('System Settings'),
    to: `/system-settings`,
    icon: () => <GearSix {...iconProps} />,
    display: !project && isSystemAdmin(),
    position: 'right'
  };

  const userDropDownMenu = [
    !project?.isTemplate &&
      projectId && {
        text: t('Subscriptions'),
        to: `subscriptions${project ? '?projectId=' + project?.id : ''}`,
        icon: (styles) => (
          <Eye
            className={styles}
            {...iconPropsNoColor}
          />
        ),
        display: true,
        position: 'right'
      },
    !project?.isTemplate && {
      text: t('Profile'),
      to: `profile-page${project ? '?projectId=' + project?.id : ''}`,
      icon: (styles) => (
        <UserCircle
          className={styles}
          {...iconPropsNoColor}
        />
      ),
      display: true,
      position: 'right'
    },
    {
      text: 'translate',
      display: true,
      icon: () => {
        return null;
      }
    },
    {
      text: t('Help Center'),
      to: `/documentation${project ? '?projectId=' + project?.id : ''}`,
      display: true,
      icon: (styles) => (
        <Info
          className={styles}
          {...iconPropsNoColor}
        />
      )
    },
    {
      text: t('Log Out'),
      to: `/logout`,
      icon: (styles) => (
        <SignOut
          className={styles}
          {...iconPropsNoColor}
        />
      ),
      display: true,
      position: 'right'
    }
  ];

  const treeHistory = JSON.parse(localStorage.getItem('tree-history') || '{}');

  const getLastTreeItem = (projectIdInput, page) => {
    if (treeHistory[projectIdInput]) {
      return treeHistory[projectIdInput][page] || {};
    }
    return {};
  };

  const getUrlWithTreeHistory = (page) => {
    let url = `/${page}?projectId=${project?.id}`;
    const last = getLastTreeItem(project?.id, page);
    if (last?.id && last?.type) {
      url += `&type=${last?.type}&id=${last?.id}`;
    }
    if (last?.version) {
      url += `&version=${last?.version}`;
    }
    return url;
  };

  const getUrlWithSelectedReportIds = (page) => {
    let url = `/${page}?projectId=${project?.id}`;
    const location = useLocation();

    if (location.pathname.includes('default')) {
      url = `/${page}/default?projectId=${project?.id}`;
      if (location.pathname.includes(selectedReport?.id)) {
        url = `/${page}/default/${selectedReport?.id}?projectId=${project?.id}`;
      }
    }

    if (location.pathname.includes('kibana')) {
      url = `/${page}/kibana?projectId=${project?.id}`;
      if (location.pathname.includes(selectedReport?.id)) {
        url = `/${page}/kibana/${selectedReport?.id}?projectId=${project?.id}`;
      }
    }
    return url;
  };

  const projectMenus = useMemo(
    () => [
      !project?.isTemplate && {
        text: projectConfig?.configuration?.menu?.dashboard,
        to: `/dashboard?projectId=${project?.id}`,
        icon: (styles) => (
          <ChartBar
            className={styles}
            {...iconProps}
          />
        ),
        display: isValidValue(project?.id) && isMemberOfProject(project?.id),
        showTitleOnHover: false
      },
      !project?.isTemplate && {
        text: projectConfig?.configuration?.menu?.release,
        to: `/releases?projectId=${project?.id}`,
        icon: (styles) => (
          <Target
            className={styles}
            {...iconProps}
          />
        ),
        display: project && isViewReleasePrivileged(project?.id),
        showTitleOnHover: false
      },
      !project?.isTemplate && {
        text: projectConfig?.configuration?.menu?.requirement,
        to: getUrlWithTreeHistory('requirements'),
        icon: (styles) => (
          <Files
            className={styles}
            {...iconProps}
          />
        ),
        display: project && isViewRequirementPrivileged(project?.id),
        showTitleOnHover: false
      },
      !project?.isTemplate && {
        text: projectConfig?.configuration?.menu?.testCase,
        to: getUrlWithTreeHistory(`test-cases`),
        icon: (styles) => (
          <Package
            className={styles}
            {...iconProps}
          />
        ),
        display: project && hasPrivilege(project?.id, PRIVILEGES.VIEW_TEST_CASE),
        showTitleOnHover: false
      },
      !project?.isTemplate && {
        text: projectConfig?.configuration?.menu?.testCycle,
        to: getUrlWithTreeHistory(`planning`),
        icon: (styles) => (
          <PlayCircle
            className={styles}
            {...iconProps}
          />
        ),
        display:
          project && (isViewTestSuite(project?.id) || isViewTestFolder(project?.id) || isViewTestCycle(project?.id))
      },
      !project?.isTemplate && {
        text: projectConfig?.configuration?.menu?.task,
        to: `/tasks?projectId=${project?.id}`,
        icon: (styles) => (
          <Queue
            className={styles}
            {...iconProps}
          />
        ),
        display: project && isViewTesterPage(project?.id),
        showTitleOnHover: false
      },
      !project?.isTemplate && {
        text: projectConfig?.configuration?.menu?.defect,
        to: `/defects?projectId=${project?.id}`,
        icon: (styles) => (
          <Bug
            className={styles}
            {...iconProps}
          />
        ),
        display: project && isViewDefectPrivileged(project?.id),
        showTitleOnHover: false
      },
      !project?.isTemplate && {
        text: projectConfig?.configuration?.menu?.report,
        to: getUrlWithSelectedReportIds('reports'),
        icon: (styles) => (
          <ChartLine
            className={styles}
            {...iconProps}
          />
        ),
        display: isValidValue(project?.id) && isMemberOfProject(project?.id) && isViewReport(project?.id),
        showTitleOnHover: false
      },
      {
        showIcon: true,
        to: `/settings?projectId=${project?.id}`,
        icon: (styles) => (
          <GearSix
            className={styles}
            {...iconPropsNoColor}
          />
        ),
        display: project && isProjectManager(project?.id),
        position: 'right',
        showTitleOnHover: false
      }
    ],
    [treeHistory]
  );

  //eslint-disable-next-line no-unused-vars
  const returnUserMenu = () => {
    let userMenu;

    userMenu = userDropDownMenu.map((item) => {
      if (item?.display) {
        if (item.text === 'translate') {
          return (
            <>
              <div className="flex items-center divide-x py-2 pl-4">
                <div className="flex gap-2 pr-2 text-sm">
                  <div
                    className={classNames(
                      'flex cursor-pointer content-center gap-1.5 rounded-full pl-0.5 pt-0.5 text-sm',
                      localStorage.getItem('lang') === 'tr'
                        ? 'bg-primary-200 pr-2 text-primary-700'
                        : 'pr-2 text-gray-800 hover:bg-primary-200 hover:text-primary-700'
                    )}
                    onClick={() => setLanguage('tr')}
                  >
                    <div className="h-[18px] w-[18px] overflow-hidden rounded-full">
                      <TrFlag />
                    </div>
                    <span>Tr</span>
                  </div>
                </div>
                <div className="flex gap-2 pl-2 text-sm">
                  <div
                    className={classNames(
                      'flex cursor-pointer content-center gap-1.5 rounded-full pl-0.5 pt-0.5 text-sm',
                      localStorage.getItem('lang') === 'en'
                        ? 'bg-primary-200 pr-2 text-primary-700'
                        : 'pr-2 text-gray-800 hover:bg-primary-200 hover:text-primary-700'
                    )}
                    onClick={() => setLanguage('en')}
                  >
                    <div className="h-[18px] w-[18px] overflow-hidden rounded-full">
                      <EnFlag />
                    </div>
                    <span>En</span>
                  </div>
                </div>
              </div>
            </>
          );
        } else {
          return (
            <NavLink
              key={item.to}
              to={item.to}
              {...(item.text === t('Log Out') && { onClick: () => logout() })}
              className={({ isActive }) =>
                'flex items-center px-4 py-2 text-sm text-gray-800 ' +
                (!isActive ? 'hover:bg-gray-100' : 'bg-primary-200')
              }
            >
              {item.icon('text-primary-500 mr-2')}
              <p>{item.text}</p>
            </NavLink>
          );
        }
      } else return null;
    });

    return userMenu;
  };

  return (
    <>
      <div className="sticky top-0 z-[2000] bg-primary-800">
        <div className="max-w-8xl mx-auto  sm:px-4 ">
          <div className="flex items-center justify-between md:justify-start md:space-x-10">
            <div className="flex justify-start py-4 lg:w-0 lg:flex-1">
              <NavLink to="/projects">
                {' '}
                <img
                  src="/logo/logo_navbar.png"
                  alt="logo-full"
                  className="w-28"
                />
              </NavLink>
            </div>
            {/* Hamburger Menu Start */}
            <div className="relative -my-2 mr-2 sm:mr-0 md:hidden">
              <button
                type="button"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                className="inline-flex items-center justify-center rounded-md bg-white p-2 text-sm text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded="false"
              >
                <span className="sr-only">Open menu</span>
                <List
                  weight="bold"
                  size={20}
                />
              </button>
              <nav
                className={`${
                  showMobileMenu ? '' : 'translate-x-full'
                } absolute -right-2 top-[47px] bg-primary-800 duration-500 ease-out sm:-right-4 md:hidden`}
                onMouseEnter={() => setUserMenuVisible(true)}
                onMouseLeave={() => setUserMenuVisible(false)}
              >
                {projectId && (
                  <>
                    {projectMenus.map((item, index) => {
                      if (item?.display && !item?.showIcon) {
                        return (
                          <NavbarLink
                            key={`${id}-${index}`}
                            item={item}
                          />
                        );
                      } else if (item?.display && item?.showIcon) {
                        const { pathname } = useLocation();
                        return (
                          <NavLink
                            to={item.to}
                            key={index}
                            className={classNames(
                              'flex items-center gap-2 px-4 text-sm text-white',
                              (item?.to || '').includes(pathname.split('/')[1])
                                ? 'border-b border-white bg-primary-700'
                                : 'hover:bg-primary-700'
                            )}
                          >
                            {t('Settings')}
                          </NavLink>
                        );
                      }
                    })}
                  </>
                )}
                {!projectId && (
                  <>
                    {/* template */}
                    {isSystemAdmin() && <NavbarLink item={{ ...templateMenu, showIcon: false }} />}
                    {/* system  */}
                    <NavbarLink item={{ ...systemSettingMenu, showIcon: false }} />
                  </>
                )}
                {projectId && <PageNameWithProject />}
                <button
                  onMouseUp={() => setShowSubMenu('transition ease-out duration-1000 opacity-100 translate-y-0')}
                  type="button"
                  className={'flex items-center px-4 py-2 text-sm text-white'}
                  aria-expanded="false"
                >
                  {t('Profile')}
                </button>
                <div
                  onMouseLeave={() => setShowSubMenu('transition ease-in duration-750 hidden')}
                  className={
                    showSubMenu +
                    ' absolute right-full top-full z-10 max-w-md transform border border-gray-100 sm:px-0 lg:ml-0 '
                  }
                >
                  <div className="w-40 overflow-hidden shadow">
                    <div className="flex flex-col divide-y divide-gray-200 bg-white  ">{returnUserMenu()}</div>
                  </div>
                </div>
              </nav>
            </div>
            {/* Hamburger Menu End */}
            <nav className="hidden self-stretch md:flex">
              {projectId && (
                <>
                  {projectMenus.map((item, index) => {
                    if (item?.display) {
                      return (
                        <NavbarLink
                          key={`${id}-${index}`}
                          item={item}
                        />
                      );
                    }
                  })}
                </>
              )}
              {!projectId && (
                <>
                  {/* template */}
                  {isSystemAdmin() && <NavbarLink item={templateMenu} />}
                  {/* system  */}
                  <NavbarLink item={systemSettingMenu} />
                </>
              )}
              {projectId && <PageNameWithProject />}
              <button
                onMouseUp={() => setUserMenuVisible(true)}
                onMouseLeave={() => setUserMenuVisible(false)}
                type="button"
                className={'inline-flex items-center gap-4 pl-4 text-base font-medium text-white'}
                aria-expanded="false"
              >
                <User size={20} />
                {/* <CaretDown weight="bold" size={16} /> */}
              </button>
              <div
                onMouseEnter={() => setUserMenuVisible(true)}
                onMouseLeave={() => setUserMenuVisible(false)}
                className={`${
                  isUserMenuVisible ? 'translate-y-0 opacity-100 transition duration-1000 ease-out' : 'hidden'
                } absolute right-2 top-14 z-10 max-w-md transform border border-gray-100 sm:px-0 lg:ml-0`}
              >
                <div className="w-40 overflow-hidden shadow">
                  <div className="flex flex-col divide-y divide-gray-200 bg-white  ">{returnUserMenu()}</div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
