import { toast } from 'react-toastify';
import i18next, { t } from 'i18next';
import { File, FileAudio, FilePdf, FileVideo, MicrosoftExcelLogo, MicrosoftWordLogo } from 'phosphor-react';
import ModalImage from 'react-modal-image';
import { infoMessage, showError } from './NotificationHelper';
import { isValidValue, openInNewTab } from './Helper';

export const displayToast = (filename, id) =>
  toast(infoMessage(i18next.t('Uploading ') + filename), { autoClose: false, toastId: id });

export const dismissToast = (id) => setTimeout(() => toast.dismiss(id), 500);

export const fileRejectionItems = (fileRejections, systemParams) => {
  const attachmentCountLimit = systemParams?.find((item) => item.paramKey === 'attachmentUploadCount')?.paramVal;
  fileRejections.map(({ errors }) => {
    errors.map((e) => {
      switch (e.code) {
        case 'too-many-files':
          showError(t(e.message, { attachmentCountLimit: attachmentCountLimit }));
          break;
        case 'file-too-large':
          showError(t(e.message));
          break;
        case 'file-too-small':
          showError(t(e.message));
          break;
        case 'file-invalid-type':
          showError(t(e.message));
          break;
      }
    });
  });
};

/**
 * @description Show file for file type
 * @param {Object} item
 * @param {String} value
 * @param {Boolean} isQuickExecution
 */
export const fileTypeControl = (item, value, isQuickExecution) => {
  if (!isValidValue(value)) return;
  if (value?.endsWith('pdf')) {
    return (
      <FilePdf
        className="cursor-pointer"
        weight="duotone"
        color="#94A3B8"
        size="40"
      />
    );
  } else if (value?.endsWith('.xlsx') || value?.endsWith('.xls')) {
    return (
      <MicrosoftExcelLogo
        className="cursor-pointer"
        weight="duotone"
        color="#94A3B8"
        size="40"
      />
    );
  } else if (value?.endsWith('.docx') || value?.endsWith('.doc')) {
    return (
      <MicrosoftWordLogo
        className="cursor-pointer"
        weight="duotone"
        color="#94A3B8"
        size="40"
      />
    );
  } else if (
    value?.endsWith('.mp4') ||
    value?.endsWith('.webm') ||
    value?.endsWith('.ogv') ||
    value?.endsWith('.ogg') ||
    value?.endsWith('.avi') ||
    value?.endsWith('.mov') ||
    value?.endsWith('.wmv') ||
    value?.endsWith('.flv')
  ) {
    return (
      <FileVideo
        className="cursor-pointer"
        weight="duotone"
        color="#94A3B8"
        size="40"
        onClick={() => openInNewTab(item?.path)}
      />
    );
  } else if (item?.fileType?.startsWith('audio') || item?.type?.startsWith('audio')) {
    return (
      <FileAudio
        className="cursor-pointer"
        weight="duotone"
        color="#94A3B8"
        size="40"
        onClick={() => openInNewTab(item?.path)}
      />
    );
  } else if (item?.fileType?.startsWith('image') || item?.type?.startsWith('image')) {
    return (
      <ModalImage
        small={isQuickExecution ? URL.createObjectURL(item) : item?.thumbnailPath ?? item?.path}
        large={item?.path}
      />
    );
  } else {
    return (
      <File
        className="cursor-pointer"
        weight="duotone"
        color="#94A3B8"
        size="40"
        onClick={() => openInNewTab(item?.path)}
      />
    );
  }
};

/**
 * @description Download file from server Fn
 * @param {{caption: String, createdBy: String, createdTime:String, fileSize: number,fileType: string, modifiedBy: string, modifiedTime: string, name: string, originalName: string, path: string, source:string}} file
 * @returns {Promise<void>}
 */
export const downloadFile = async (file) => {
  if (file?.path && file?.fileType) {
    let blob = await fetch(file.path).then((r) => r.blob());
    const url = window.URL.createObjectURL(new Blob([blob], { type: file.fileType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file?.originalName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  }
};
