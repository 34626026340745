import React, { useEffect, useMemo, useState } from 'react';
// DESIGN SYSTEM & LAYOUTS
import { Check, PencilLine, X } from 'phosphor-react';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
import { saveEntitySystemFieldParams } from '../../../context/ProjectActions';
// HELPER & CONSTANTS
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useAppDispatch } from '../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { capitalize, isValidValue } from '../../../helpers/Helper';
import { disableResizingForColumns, generateTableColumns, translateColumns } from '../../../helpers/TableHelper';
// API & HOOKS IMPORTS
import { useApi } from '../../../hooks/UseApi';
import SystemFieldApi from '../../../api/SystemFieldApi';
// COMPONENTS
import SystemFieldModal from './SystemFieldModal';
import BasicTable from '../../../components/table/BasicTable';

const SystemFieldPageTable = (props) => {
  const { activeItem, projectId, relatedEntity, projectConfig, project } = props;

  const { t } = useTranslation();

  const [entityItems, setEntityItems] = useState([]);
  const [fields, setFields] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [updateState, setUpdateState] = useState(false);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);

  const systemFieldLoader = useApi({
    name: 'Get system fields',
    api: SystemFieldApi.fetchSystemFields,
    onSuccess: () => {
      setEntityItems(systemFieldLoader?.data);
      dispatch(saveEntitySystemFieldParams(projectId, relatedEntity, systemFieldLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const updateRequired = useApi({
    name: `Related field updated.`,
    api: SystemFieldApi.updateRequired,
    onSuccess: (data) => {
      showSuccessMes(t('entity_updated', { entity: t(capitalize(data?.name)) }));
      setUpdateState(false);
      retrieveData();
    },
    onError: (err) => showErrorMes(err)
  });

  const retrieveData = () => {
    const body = { projectId, relatedEntity: activeItem };
    systemFieldLoader.execute(body);
  };

  const getRequired = (name) => entityItems.find((item) => capitalize(item?.name) === name)?.required;

  const optionsFn = (row) => {
    return [
      {
        id: 'action-edit-button',
        name: t('Edit'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(row);
          setOpenModal(true);
        }
      },
      {
        id: 'action-required-button',
        name: getRequired(row?.original?.name) ? t('Unrequired') : t('Required'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: isValidValue(project?.templateBy),
        onClick: () => {
          handleSelectedRow(row);
          setUpdateState(true);
        }
      }
    ];
  };

  const handleSelectedRow = (row) => {
    const selectedData = fields?.find((item) => item?.fieldName === row?.original?.id);
    if (isValidValue(selectedData)) {
      const obj = { fieldName: selectedData?.fieldName, required: selectedData?.required };
      setSelectedItem(obj);
    }
  };

  const prepareData = (dd) => {
    let items = [];
    dd.forEach((item) => {
      items.push({ fieldName: item?.name, required: item?.required });
    });
    setFields(items);
  };

  const convertToTableData = (field) => ({
    id: field?.fieldName,
    name: t(capitalize(field?.fieldName)),
    required: field?.required ? (
      <Check
        size={15}
        color="#348014"
        weight="fill"
      />
    ) : (
      <X
        size={15}
        color="#348014"
        weight="fill"
      />
    )
  });

  const { data, tableColumns } = useMemo(() => {
    let tableData = [];
    let columns = [];
    if (fields) {
      tableData = fields.map(convertToTableData);
      columns = generateTableColumns(tableData, optionsFn, ['id']);
    }
    return {
      data: tableData,
      tableColumns: columns
    };
  }, [fields]);

  let columns = disableResizingForColumns(tableColumns, [{ accessor: 'actions', width: 50 }]);
  columns = translateColumns(columns, t);

  useEffect(() => {
    prepareData(entityItems);
  }, [entityItems]);

  useEffect(() => {
    retrieveData();
  }, [activeItem]);

  useEffect(() => {
    if (updateState) {
      const body = {
        projectId,
        relatedEntity,
        relatedField: selectedItem?.fieldName,
        required: !selectedItem?.required
      };
      selectedItem && updateRequired.execute(body);
    }
  }, [selectedItem]);
  return (
    <>
      <BasicTable
        columns={columns}
        data={data}
      />
      {openModal && (
        <SystemFieldModal
          isOpen={openModal}
          closeModal={() => setOpenModal(false)}
          relatedEntity={relatedEntity}
          selectedItem={selectedItem}
          projectId={projectId}
          relatedField={selectedItem.fieldName}
          projectConfig={projectConfig}
        />
      )}
    </>
  );
};
export default SystemFieldPageTable;
