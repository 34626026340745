import React from 'react';
import { Modal } from '../modal';
import { useTranslation } from 'react-i18next';
import { Button, Body, Heading } from '../../designSystem/DesignSystem';

const UploadErrorModal = ({ open, close, errorFiles }) => {
  const { t } = useTranslation();

  const handleErrorMessage = (errorMessage) => {
    if (errorMessage.startsWith('File is larger than')) {
      const fileSizeLimit = errorMessage.split(' ')[4];
      return t('file_size_limit_error', { fileSizeLimit: fileSizeLimit });
    } else {
      return t(errorMessage);
    }
  };

  return (
    <Modal
      open={open}
      close={close}
      w="lg"
    >
      <Modal.Title>{t('Some files failed to upload')}</Modal.Title>
      <Modal.Content h={'md'}>
        <Body
          type="large"
          text={t('The following files could not be uploaded:')}
        />
        <div className="mt-4">
          <div className="max-h-40 overflow-auto">
            <table className="flex min-w-full flex-col py-4">
              <thead className="flex flex-grow bg-gray-50">
                <tr className="flex flex-grow">
                  <th className="flex w-12 px-2 py-4">
                    <Heading
                      type="xsmall"
                      text="#"
                      uppercase
                    />
                  </th>
                  {errorFiles && (
                    <th className="flex w-1/3 py-4">
                      <Heading
                        type="xsmall"
                        text={t('File Name')}
                        uppercase
                      />
                    </th>
                  )}
                  <th className="flex w-2/3 p-4">
                    <Heading
                      type="xsmall"
                      text={t('Error Message')}
                      uppercase
                    />
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 overflow-x-hidden  bg-white">
                {(errorFiles || []).map((file, index) => (
                  <tr
                    key={index}
                    className="flex flex-grow"
                  >
                    <td className="flex w-12 px-2 py-4 ">
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-gray-500">
                        {index + 1}
                      </div>
                    </td>

                    {errorFiles && (
                      <td className="flex w-1/3 py-4">
                        <div className="overflow-hidden overflow-ellipsis whitespace-normal text-sm text-gray-500">
                          <Body
                            type="medium"
                            text={file.name}
                          />
                        </div>
                      </td>
                    )}

                    <td className="flex w-2/3 whitespace-nowrap p-4">
                      <div className="overflow-hidden overflow-ellipsis whitespace-normal text-sm text-gray-500">
                        <Body
                          type="medium"
                          text={handleErrorMessage(file.error)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          id="error-close-button"
          secondary
          text={t('Close')}
          onClick={close}
        />
      </Modal.Buttons>
    </Modal>
  );
};

export default UploadErrorModal;
