import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import LicenseApi from '../../../api/LicenseApi';
import { Body, Button, Heading } from '../../../designSystem/DesignSystem';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';

const LicensePage = () => {
  const [licenseFile, setLicenseFile] = useState(null);
  const { t } = useTranslation();

  const getLicenseUUID = useApi({
    name: 'Get License UUID',
    api: LicenseApi.getUUID,
    onSuccess: () => {},
    onError: (err) => showErrorMes(err)
  });

  const getLicenseDetails = useApi({
    name: 'Get License Details',
    api: LicenseApi.get,
    onSuccess: () => {},
    onError: (err) => showErrorMes(err)
  });

  const uploadLicense = useApi({
    name: 'Uplaod License',
    api: LicenseApi.upload,
    onSuccess: () => {
      getLicenseDetails.execute();
      showSuccessMes(t('License uploaded successfully.'));
      setLicenseFile(null);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    getLicenseUUID.execute();
    getLicenseDetails.execute();
  }, []);

  const licenseDetails = useMemo(() => {
    let licenseResponse = getLicenseDetails.data;
    if (licenseResponse) {
      licenseResponse = licenseResponse
        .replace(/:INT/g, '')
        .replace(/:DATE/g, '')
        .replace(/:DATE/g, '')
        .replace(/:BINARY/g, '')
        .replace(/:UUID/g, '')
        .split(/\n|=/)
        .filter((value) => {
          return value !== '';
        });

      let licDetails = [];
      for (let i = 0; i < licenseResponse.length; i += 2) {
        let camelCaseToNormal = _.startCase(licenseResponse[i]);
        licDetails.push([camelCaseToNormal, licenseResponse[i + 1]]);
      }
      licDetails = Object.fromEntries(licDetails);
      return licDetails;
    }
  }, [getLicenseDetails.data]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    let licenseFile = new FormData();
    licenseFile.append('file', file, file.name);
    setLicenseFile(licenseFile);
  };

  const handleUploadButton = () => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    uploadLicense.execute({ licenseFile, config });
  };

  return (
    <div className="my-2 flex flex-col rounded-md p-6 shadow-md shadow-violet-100">
      <Heading.Group
        type="normal"
        text={t('License Information')}
      />

      <table className="mt-2">
        <tbody className="bg-white">
          <div className="divide-gray-200s divide-y">
            <tr className="py-1 odd:bg-white even:bg-slate-50">
              <td className="flex w-36">
                <Heading
                  type="small"
                  text={t('Device UUID')}
                />
              </td>
              <td className="font-regular items-center whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                {getLicenseUUID.data}
              </td>
            </tr>
            {Object.keys(licenseDetails || {}).map((detail, index) => {
              return (
                <tr
                  key={index}
                  className="py-1 odd:bg-white even:bg-slate-50"
                >
                  <td className="flex w-36">
                    <Heading
                      type="small"
                      text={t(detail)}
                    />
                  </td>
                  <td className="items-center whitespace-nowrap px-3 py-1">
                    <div className="overflow-hidden overflow-ellipsis whitespace-pre-wrap break-all">
                      <Body
                        type="medium"
                        color="text-gray-500"
                        text={licenseDetails[detail]}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </div>
        </tbody>
      </table>

      <Heading.Group
        className="mt-2"
        type="base"
        text={t('New License')}
      />
      <div className="flex gap-4">
        <input
          value={licenseFile ? licenseFile.name : ''}
          className="border border-gray-300 bg-gray-50 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0"
          type="file"
          id="file"
          accept=".lic"
          onChange={handleFileChange}
          disabled={uploadLicense.loading}
        />
        <Button
          id="license-upload-button"
          primary
          text={t('Upload')}
          size="small"
          disabled={!licenseFile}
          onClick={handleUploadButton}
          loading={uploadLicense.loading}
        />
      </div>
    </div>
  );
};

export default LicensePage;
