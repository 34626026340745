import { TreeActions } from './TreeActions';
import ReleaseApi from '../../../api/ReleaseApi';
import { ENTITY_TYPES, TREENODE_ROOT } from '../../../constants';

export class ReleaseTreeActions extends TreeActions {
  constructor(projectConfig, checkable) {
    super(projectConfig, checkable, ENTITY_TYPES.RELEASE);
  }

  async getTreeData(projectId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const treeData = await ReleaseApi.getReleaseTreeData({ projectId, root: true, parentId: null });
      return this.createNodes(treeData, ENTITY_TYPES.RELEASE, TREENODE_ROOT.id);
    } catch (err) {
      throw err;
    }
  }
}
