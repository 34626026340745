import { axios } from './ApiClient';

class SearchApi {
  async search({ value, size, from }) {
    const res = await axios.get(`/api/query/_search`, { params: { value, size, from } });
    return res.data;
  }
}

export default new SearchApi();
