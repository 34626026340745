import React, { useState, useEffect } from 'react';
// HOOK & API IMPORTS
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseCustomField from '../../hooks/UseCustomField';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import UseQueryProject from '../../hooks/UseQueryProject';
import TestRunApi from '../../api/TestRunApi';
import UseSystemField from '../../hooks/UseSystemField';
// HELPER & CONSTANT IMPORTS
import { getArtifactName, getRunName } from '../../helpers/ArtifactNameHelper';
import { showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { Dropdown, Button, Datepicker } from '../../designSystem/DesignSystem';
import { ENTITY_TYPES } from '../../constants';
import { isValidValue, editModalMapping } from '../../helpers/Helper';
import { getSystemFieldOptions } from '../../helpers/EnumHelper';
import { defaultValue } from '../../helpers/SystemFieldHelper';
import { getCustomFields, createEntityFields } from '../../helpers/CustomFieldHelper';

// COMPONENT IMPORTS
import { Modal } from '../modal';
import TabBar from '../TabBar';
import RequirementSearchTree from '../treeView/RequirementSearchTree';
import TestCaseSearchTree from '../treeView/TestCaseSearchTree';
import { useAppDispatch } from '../../context/AppContext';
import { setSelectedRunTestCases } from '../../context/ProjectActions';
import EntityCustomFieldForm from '../../components/customField/EntityCustomFieldForm';

const checkIfTestrunsHaveParameters = (testRuns) => {
  if (!testRuns) return null;
  return testRuns.some((testRun) => testRun?.testCase?.parameters?.length > 0);
};

const TestSuiteCreateRunModal = ({
  isOpen,
  closeModal,
  testSuiteId,
  submitCallBack,
  setConfigureParametersModalOpen,
  data
}) => {
  // TRANSLATE
  const { t } = useTranslation();
  // FORM
  const { control, handleSubmit, reset, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  // HOOKS
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const userList = UseProjectUsers(projectId);
  const dispatch = useAppDispatch();
  // SELECT STATES
  const [selectedTestCases, setSelectedTestCases] = useState([]);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);

  const entityCustomFields = UseCustomField(projectId, ENTITY_TYPES.TEST_RUN);
  const [cFields, setCFields] = useState([]);
  const [customFieldForm, setCustomFieldForm] = useState({});
  const [dataForm, setDataForm] = useState({});
  const type = getSystemFieldOptions(UseSystemField(projectId, ENTITY_TYPES.TEST_RUN, 'type'));

  const createTestRuns = useApi({
    name: 'creating new test runs',
    api: TestRunApi.create,
    autoLoad: false,
    onSuccess: () => {
      if (submitCallBack) {
        submitCallBack();
      }
      closeModal();
      showSuccessMes(t('createdTestRun', { entity: getRunName(projectConfig) }));
      if (checkIfTestrunsHaveParameters(createTestRuns.data)) {
        setConfigureParametersModalOpen(true);
      }
      dispatch(setSelectedRunTestCases(createTestRuns.data));
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const onSubmit = (form) => {
    const testCaseIds = selectedTestCases;
    const testContainerIds = selectedContainers;
    const requirementIds = selectedRequirements;
    const folderIds = selectedFolders;
    let cFieldForm = getCustomFields(cFields, form);

    const body = {
      ...form,
      testerId: form.testerId && form.testerId[0].id,
      customFields: createEntityFields(form, cFieldForm),
      testSuiteId,
      testCaseIds,
      testContainerIds,
      requirementIds,
      folderIds
    };
    createTestRuns.execute(body);
  };

  useEffect(() => {
    setValue('type', defaultValue(type));
    setDataForm((prev) => ({ ...prev, typeId: defaultValue(type)?.value }));
    if (isValidValue(data)) {
      const form = { ...data, typeId: data?.type?.id };
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setDataForm(form);
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>
          {t('createTestRun', {
            entity: getRunName(projectConfig)
          })}
        </Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <div className="flex flex-grow gap-4">
                <Datepicker
                  clearable
                  control={control}
                  endDateName="endDate"
                  startDateName="startDate"
                  startDateForm=""
                  endDateForm=""
                  startDateLabel={t('Planned Start Date')}
                  endDateLabel={t('Planned End Date')}
                />
              </div>
              <Dropdown
                clearable
                required
                control={control}
                name="testerId"
                label={t('Tester')}
                options={userList}
                placeholder={t('Select Tester')}
              />
              <EntityCustomFieldForm
                customFieldForm={customFieldForm}
                setCustomFieldForm={setCustomFieldForm}
                control={control}
                setValue={setValue}
                entityFields={data?.customFields}
                entityCustomFields={entityCustomFields}
                dataForm={dataForm}
                customControlForJiraModal={false}
                setCFields={setCFields}
              />
            </div>
            <div className="flex flex-grow flex-col gap-4">
              <TabBar
                defaultIndex={0}
                options={[
                  {
                    name: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE),
                    disabled: false,
                    render: () => (
                      <div className="overflow-y-hidden">
                        <TestCaseSearchTree
                          type={ENTITY_TYPES.TEST_SUITE}
                          setSelectedTestCases={setSelectedTestCases}
                          setSelectedContainers={setSelectedContainers}
                        />
                      </div>
                    )
                  },
                  {
                    name: getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT),
                    disabled: false,
                    render: () => (
                      <div className="overflow-y-hidden">
                        <RequirementSearchTree
                          setSelectedRequirements={setSelectedRequirements}
                          setSelectedFolders={setSelectedFolders}
                          showJiraKey={true}
                        />
                      </div>
                    )
                  }
                ]}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="testSuiteCreateRunModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
              type="button"
            />
            <Button
              id="testSuiteCreateRunModal-submit-button"
              primary
              text={t('Submit')}
              loading={createTestRuns?.loading}
              type="submit"
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default TestSuiteCreateRunModal;
