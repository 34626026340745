import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const TABLE_PERSONAL_PREFENCES = BASE_URL + 'personalized-tables';

class TablePersonalPrefencesApi {
  getSavedPersonalPrefencesList = async (entity) => {
    const resp = await axios.get(`${TABLE_PERSONAL_PREFENCES}/${entity}`);
    return resp.data;
  };

  savePersonalTablePrefences = async (data) => {
    const resp = await axios.post(`${TABLE_PERSONAL_PREFENCES}`, data);
    return resp.data;
  };

  updatePersonalTablePrefences = async (data) => {
    const resp = await axios.put(`${TABLE_PERSONAL_PREFENCES}/${data?.id}`, data);
    return resp.data;
  };

  deletePersonalTablePrefences = async (id) => {
    const resp = await axios.delete(`${TABLE_PERSONAL_PREFENCES}/${id}/`);
    return resp.data;
  };
}

export default new TablePersonalPrefencesApi();
