export const openProject = async (project, setProjectToken, dispatch, navigate, saveProject) => {
  try {
    await setProjectToken(project.id);
    dispatch(saveProject(project));
    navigate(`/dashboard?projectId=${project.id}`);
  } catch (err) {
    console.error(err);
  }
};

export const openTemplate = async (project, setProjectToken, dispatch, navigate, saveProject) => {
  try {
    await setProjectToken(project?.id);
    dispatch(saveProject(project));
    navigate(`/settings/configuration/artifact-names?projectId=${project?.id}`);
  } catch (err) {
    console.error(err);
  }
};
