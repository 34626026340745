import React, { useState, useEffect } from 'react';
// API & HOOKS
import CustomReportApi from '../../../api/CustomReportApi';
import { useApi } from '../../../hooks/UseApi';
import UseJiraConfig from '../../../hooks/UseJiraConfig';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
// DESIGN SYSTEM & COMPONENTS
import PercentageBar from '../../general/PercentageBar';
import { Modal } from '../../modal';
import DefectPriorityAndStatusReport from '../general/DefectPriorityAndStatusReport';
import EntityDataTable from '../general/EntityDataTable';
import TesterExecutionInsight2 from './TesterExecutionInsight2.js';
import CreatedByDefectInsight from './CreatedByDefectInsight';
import ReportMailModal from '../general/ReportMailModal';

import { Heading, Button } from '../../../designSystem/DesignSystem';
import { ENTITY_TYPES } from '../../../constants';
import { downloadExportingFile } from '../ReportHelper';
import { getArtifactName, getDefectName, getRunName } from '../../../helpers/ArtifactNameHelper';
import { getElasticRunStatusBarOptions } from '../../../helpers/Helper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';

const TestPlanningReportModal = ({ isOpen, closeModal, entityId, users, entityData, entityType }) => {
  const { t } = useTranslation();

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const jiraConfig = UseJiraConfig(projectId);

  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [response, setResponse] = useState({});
  const headingSize = 'base';

  const dataLoader = useApi({
    name: 'fetching ',
    api: CustomReportApi.getEntityReport,
    onSuccess: () => setResponse(dataLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const dowloadReport = useApi({
    name: 'dowloadReport',
    api: CustomReportApi.downloadEntityReport,
    autoLoad: false,
    onSuccess: () => downloadExportingFile(dowloadReport?.data, entityType),
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    dataLoader.execute({ entityId, entityType });
  }, [entityId]);

  return (
    <>
      <Modal
        w={'lg'}
        open={isOpen}
        close={() => closeModal}
      >
        <div className="text-sm">
          <Modal.Title>
            <Heading.Group
              text={getArtifactName(projectConfig, entityType) + t(' Report')}
              notCapitalize={true}
              subText={entityData?.code + '     ' + entityData?.name}
              subTextType={'small'}
            >
              <div className="flex gap-4">
                <Button
                  id="testplanningReportModal-sendEmail-button"
                  secondary
                  text={t('Send Email')}
                  onClick={() => {
                    setOpenEmailModal(true);
                  }}
                  size={'xsmall'}
                />
                <Button
                  id="testplanningReportModal-export-button"
                  secondary
                  text={t('Export')}
                  icon="UploadSimple"
                  onClick={() => {
                    showSuccessMes('Report is downloading');
                    dowloadReport.execute({ entityType, entityId });
                  }}
                  loading={dowloadReport?.loading}
                  size={'xsmall'}
                />
                <Button
                  id="testplanningReportModal-close-button"
                  size={'xsmall'}
                  icon="X"
                  onClick={closeModal}
                />
              </div>
            </Heading.Group>
          </Modal.Title>
          <Modal.Content h={'lg'}>
            <>
              <div className=" flex flex-grow flex-col gap-4">
                <div className="flex flex-grow flex-col">
                  <Heading
                    type={headingSize}
                    text={t('Test Coverage')}
                  />
                  <PercentageBar
                    showLabel={true}
                    datas={getElasticRunStatusBarOptions(response?.testRunStatusInsight)}
                  />
                </div>
                <div className="flex flex-grow flex-col gap-4">
                  <Heading
                    notCapitalize="true"
                    type={headingSize}
                    text={t('Tester Executions')}
                  />
                  <TesterExecutionInsight2
                    response={response?.testerExecutionInsight}
                    users={users}
                  />
                </div>
                {jiraConfig?.activeDefect === true ? (
                  <>
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        notCapitalize="true"
                        type={headingSize}
                        text={t('testPlanningReportModalTester', { entity: getDefectName(projectConfig) })}
                      />
                      <CreatedByDefectInsight
                        response={response?.createdByDefectInsightByJiraStatus}
                        users={users}
                      />
                    </div>
                    <div className="flex flex-grow flex-col">
                      <Heading
                        notCapitalize="true"
                        type={headingSize}
                        text={t('testPlanningReportModalDefect', { entity: getDefectName(projectConfig) })}
                      />
                      <DefectPriorityAndStatusReport
                        response={response}
                        projectId={projectId}
                        projectConfig={projectConfig}
                        users={users}
                        jiraConfig={jiraConfig}
                        isJiraStatus={true}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        notCapitalize="true"
                        type={headingSize}
                        text={t('testPlanningReportModalTester', { entity: getDefectName(projectConfig) })}
                      />
                      <CreatedByDefectInsight
                        response={response?.createdByDefectInsightByStatus}
                        users={users}
                      />
                    </div>
                    <div className="flex flex-grow flex-col gap-4">
                      <Heading
                        type={headingSize}
                        text={t('testPlanningReportModalDefect', { entity: getDefectName(projectConfig) })}
                      />
                      <DefectPriorityAndStatusReport
                        response={response}
                        projectId={projectId}
                        projectConfig={projectConfig}
                        users={users}
                        jiraConfig={jiraConfig}
                      />
                    </div>
                  </>
                )}
                <div className="flex flex-grow flex-col gap-4">
                  <Heading
                    type={headingSize}
                    text={getDefectName(projectConfig)}
                  />
                  <EntityDataTable
                    response={response?.defects}
                    projectId={projectId}
                    projectConfig={projectConfig}
                    users={users}
                    entityType={ENTITY_TYPES.DEFECT}
                    jiraConfig={jiraConfig}
                  />
                </div>
                <div className="flex flex-grow flex-col gap-4">
                  <Heading
                    type={headingSize}
                    text={getRunName(projectConfig)}
                  />
                  <EntityDataTable
                    response={response?.testRuns}
                    projectId={projectId}
                    projectConfig={projectConfig}
                    users={users}
                    entityType={ENTITY_TYPES.TEST_RUN}
                  />
                </div>
              </div>
            </>
          </Modal.Content>
        </div>
      </Modal>
      {openEmailModal && (
        <ReportMailModal
          entityId={entityId}
          entityType={entityType}
          isOpen={openEmailModal}
          closeModal={() => setOpenEmailModal(false)}
          users={users}
        />
      )}
    </>
  );
};

export default TestPlanningReportModal;
