import React, { useEffect, useState } from 'react';
// CHARTS IMPORT
import CustomMetricChart from '../charts/CustomMetricChart';
import InsightChart from '../charts/InsightChart';
import ChartComponent from './ChartComponent';
// HELPER IMPORT
import _ from 'lodash';
import { CHART_TYPE } from '../helper/DashboardHelper';
import { isValidList, sortArr } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
// API
import { useApi } from '../../../hooks/UseApi';
import DashboardApi from '../../../api/DashboardApi';
import UseJiraConfig from '../../../hooks/UseJiraConfig';

const cardClass =
  'flex justify-center px-4 py-4 bg-white border border-gray-200 rounded rounded-[14px] h-[100px] w-full';

const PageCharts = ({ dashboardId, updateCharts, setUpdateCharts, projectId, dateFilter, projectConfig }) => {
  const jiraConfig = UseJiraConfig(projectId);

  const [charts, setCharts] = useState([]);
  const [metricCharts, setMetricCharts] = useState([]);
  const [insightCharts, setInsightCharts] = useState([]);

  const dataLoader = useApi({
    name: 'fetching charts',
    api: DashboardApi.fetchDashboardCharts,
    autoLoad: false,
    onSuccess: () => fillCharts(dataLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const fillCharts = (chartList) => {
    if (isValidList(chartList)) {
      if (!jiraConfig.active) {
        chartList = chartList.filter((x) => !_.endsWith(x?.name, '(Jira)'));
      }
      let metrics = chartList.filter((x) => x?.type === CHART_TYPE.metric || x?.type === CHART_TYPE.fieldMetric);
      let ins = chartList.filter((x) => x?.type === CHART_TYPE.insight);

      let others = chartList.filter(
        (x) => x?.type !== CHART_TYPE.metric && x?.type !== CHART_TYPE.fieldMetric && x?.type !== CHART_TYPE.insight
      );

      metrics = isValidList(metrics) ? metrics.sort(sortArr('order')) : [];
      others = isValidList(others) ? others.sort(sortArr('order')) : [];
      ins = isValidList(ins) ? ins.sort(sortArr('order')) : [];
      setCharts(others);
      setMetricCharts(metrics);
      setInsightCharts(ins);
    } else {
      setCharts([]);
      setMetricCharts([]);
      setInsightCharts([]);
    }
  };

  useEffect(() => {
    if (updateCharts === true && projectId && dashboardId) {
      dataLoader.execute({ projectId, dashboardId });
      if (setUpdateCharts) setUpdateCharts(false);
    }
  }, [updateCharts, dashboardId]);

  useEffect(() => {
    if (projectId && dashboardId) {
      dataLoader.execute({ projectId, dashboardId });
    }
  }, [projectId, dashboardId, dateFilter]);

  return (
    <div className="grid-row  mt-5 grid">
      <div className="grid grid-cols-7 gap-8">
        {metricCharts.map((metricChart) => {
          return (
            <>
              <div
                key={metricChart.id}
                className={cardClass}
              >
                <CustomMetricChart
                  projectConfig={projectConfig}
                  chart={metricChart}
                  projectId={projectId}
                  dateFilter={dateFilter}
                />
              </div>
            </>
          );
        })}
      </div>
      <div className="mb-3 mt-3 grid grid-cols-2 gap-8">
        {insightCharts.map((chart) => {
          return (
            <>
              <div
                key={chart.id}
                className={'flex w-full rounded-[14px] border border-gray-200 bg-white px-4 py-4'}
              >
                <InsightChart
                  projectConfig={projectConfig}
                  chart={chart}
                  projectId={projectId}
                  dateFilter={dateFilter}
                />
              </div>
            </>
          );
        })}
      </div>
      <div className="mt-5 grid grid-cols-2 gap-4">
        {charts.map((chart) => {
          return (
            <ChartComponent
              projectConfig={projectConfig}
              key={chart.id}
              chart={chart}
              dateFilter={dateFilter}
              projectId={projectId}
              index={chart.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PageCharts;
