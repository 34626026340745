import React, { useState, useEffect } from 'react';
import _ from 'lodash';
//Component Import
import InputCustomField from './fields/InputCustomField';
import ListCustomField from './fields/ListCustomField';
import MemoCustomField from './fields/MemoCustomField';
import DateCustomField from './fields/DateCustomField';
import UserCustomField from './fields/UserCustomField';
import MultiSelectDropdownCustomField from './fields/MultiSelectDropdownCustomField';
import MultiUserSelectDropdownCustomField from './fields/MultiUserSelectDropdownCustomField';
import CheckboxCustomField from './fields/CheckBoxCustomField';
//Helper Import
import {
  getCascadedCustomFields,
  changeJiraStatusPlace,
  isValidCustomFieldValue,
  getDefaultSelectedOptionList,
  getDefaultSelectedMultipleUserList,
  getDefaultSelectedList
} from '../../helpers/CustomFieldHelper';
import { getUserOptionsWithGroups, isValidList, isValidValue } from '../../helpers/Helper';
import { CUSTOM_FIELD_TYPES, ENTITY_TYPES } from '../../constants';
import UseProjectUserGroups from '../../hooks/UseProjectUserGroups';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import UseQueryProject from '../../hooks/UseQueryProject';

const EntityCustomFieldForm = ({
  control,
  setValue,
  entityCustomFields,
  dataForm,
  setCFields,
  entityFields,
  customControlForJiraModal,
  customFieldForm,
  projectConfig,
  entityType,
  setCustomFieldForm,
  disabled
}) => {
  const [sortedFields, setSortedFields] = useState([]);
  const { projectId } = UseQueryProject('projectId');

  const users = UseProjectUsers(projectId);
  const userGroups = UseProjectUserGroups(projectId);
  const userOptions = getUserOptionsWithGroups(users, userGroups);

  const fillSortedObjects = () => {
    const cFields = getCascadedCustomFields(entityCustomFields, dataForm, customFieldForm);
    let sortedfieldlist = [];
    if (cFields.length > 0) {
      const sortedObjs = _.sortBy(cFields, 'place');
      sortedfieldlist = changeJiraStatusPlace(sortedObjs);
    }
    setSortedFields(sortedfieldlist);
    return cFields;
  };

  const setFieldList = (customFieldList) => setCustomFieldFormFieldList(customFieldList);

  const setCustomFieldFormFieldList = (customFieldList) => {
    customFieldList.map((customField) => {
      if (
        customField?.customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN ||
        customField?.customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX
      ) {
        setCustomValue(customField?.customFieldId, getDefaultSelectedOptionList(customField));
      } else if (customField?.customFieldEnumId === CUSTOM_FIELD_TYPES.MULTI_LIST_USERS) {
        setCustomValue(customField?.customFieldId, getDefaultSelectedMultipleUserList(customField));
      } else if (customField?.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST) {
        setCustomValue(customField?.customFieldId, getDefaultSelectedList(customField));
      } else {
        setCustomValue(customField?.customFieldId, customField?.config?.value);
      }
    });

    if (entityFields) {
      customFieldList.map((customField) => {
        const entityCField = entityFields.find((elem) => elem?.customFieldId === customField?.customFieldId);
        if (isValidCustomFieldValue(entityCField?.value)) {
          if (
            (customField?.customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN ||
              customField?.customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX ||
              customField?.customFieldEnumId === CUSTOM_FIELD_TYPES.MULTI_LIST_USERS) &&
            isValidCustomFieldValue(entityCField?.value)
          ) {
            setCustomValue(customField?.customFieldId, entityCField?.value.split(','));
          } else {
            setCustomValue(customField?.customFieldId, entityCField?.value);
          }
        }
      });
    }
  };

  const setCustomValue = (fieldId, value) => {
    customFieldForm[fieldId] = value;
    setCustomFieldForm({
      ...customFieldForm,
      [fieldId]: value
    });
    setValue(fieldId, value);
  };

  useEffect(() => {
    const cFields = fillSortedObjects();
    setCFields(cFields);
  }, [dataForm, entityFields, customFieldForm]);

  useEffect(() => {
    const cFields = fillSortedObjects();
    if (isValidValue(cFields) && isValidValue(entityFields)) {
      const cFieldForm = {};
      if (customControlForJiraModal === true) {
        entityFields.forEach((item) => {
          const cfield = entityFields.find((field) => field.customFieldId === item.customFieldId);
          cFieldForm[item.customFieldId] = cfield ? cfield.value : item.value;
        });
      } else {
        cFields.forEach((item) => {
          const cfield = entityFields.find((field) => field.customFieldId === item.customFieldId);
          cFieldForm[item.customFieldId] = cfield ? cfield.value : item.value;
        });
      }
      setCustomFieldForm(cFieldForm);
    }
  }, [entityFields]);

  useEffect(() => {
    setFieldList(entityCustomFields);
  }, [entityCustomFields]);

  //TODO: custom field yerleşimle iligli karmaşıklığı düzeltilecek
  return (
    <>
      {isValidList(sortedFields) ? (
        <div className="grid grid-cols-2 gap-3">
          {sortedFields.map((field) => {
            // eslint-disable-next-line default-case
            switch (field.customFieldEnumId) {
              case CUSTOM_FIELD_TYPES.TEXT:
                return (
                  <>
                    <InputCustomField
                      control={control}
                      disabled={disabled}
                      field={field}
                      type="text"
                    />
                  </>
                );
              case CUSTOM_FIELD_TYPES.LIST:
                return (
                  <>
                    <ListCustomField
                      {...(entityType === ENTITY_TYPES.REQUIREMENT && { projectConfig })}
                      dataForm={dataForm}
                      control={control}
                      field={field}
                      disabled={disabled}
                      selectedItem={customFieldForm[field.customFieldId]}
                      setCustomFieldForm={setCustomFieldForm}
                      setCustomValue={setCustomValue}
                    />
                  </>
                );
              case CUSTOM_FIELD_TYPES.MEMO:
                return (
                  <>
                    <div className="col-span-full">
                      <MemoCustomField
                        control={control}
                        disabled={disabled}
                        field={field}
                      />
                    </div>
                  </>
                );
              case CUSTOM_FIELD_TYPES.NUMBER:
                return (
                  <>
                    <InputCustomField
                      control={control}
                      disabled={disabled}
                      field={field}
                      type="number"
                    />
                  </>
                );
              case CUSTOM_FIELD_TYPES.CHECKBOX:
                return (
                  <>
                    <CheckboxCustomField
                      disabled={disabled}
                      control={control}
                      field={field}
                      selectedItem={customFieldForm[field.customFieldId]}
                      setCustomFieldForm={setCustomFieldForm}
                      customFieldForm={customFieldForm}
                    />
                  </>
                );
              case CUSTOM_FIELD_TYPES.DATE:
                return (
                  <>
                    <DateCustomField
                      control={control}
                      disabled={disabled}
                      field={field}
                      selected={customFieldForm[field.customFieldId]}
                    />
                  </>
                );
              case CUSTOM_FIELD_TYPES.USER:
                return (
                  <>
                    <UserCustomField
                      control={control}
                      disabled={disabled}
                      field={field}
                      selectedItem={customFieldForm[field.customFieldId]}
                      userOptions={userOptions}
                      setValue={setValue}
                    />
                  </>
                );
              case CUSTOM_FIELD_TYPES.MULTI_LIST_USERS:
                return (
                  <>
                    <MultiUserSelectDropdownCustomField
                      control={control}
                      field={field}
                      disabled={disabled}
                      selectedItem={customFieldForm[field.customFieldId]}
                      setCustomFieldForm={setCustomFieldForm}
                      userOptions={userOptions}
                    />
                  </>
                );
              case CUSTOM_FIELD_TYPES.DROP_DOWN:
                return (
                  <>
                    <MultiSelectDropdownCustomField
                      control={control}
                      field={field}
                      disabled={disabled}
                      selectedItem={customFieldForm[field.customFieldId]}
                      setCustomFieldForm={setCustomFieldForm}
                    />
                  </>
                );
              default:
                return <></>;
            }
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default EntityCustomFieldForm;
