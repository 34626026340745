import { axios } from './ApiClient';

const BASE_URL = '/api/';
const JIRA_INTEGRATION_PATH = BASE_URL + 'integration/jira';
const JIRA_CONFIG_PATH = JIRA_INTEGRATION_PATH + '/config';
const JIRA_ISSUE_PATH = JIRA_INTEGRATION_PATH + '/issue';

class JiraApi {
  async getActiveJiraConfig(data) {
    const resp = await axios.get(`/api/integration/jira/activeSetting`, { params: data });
    return resp.data;
  }

  async searchJiraLinksByProjectConfig(data) {
    const resp = await axios.post(`${JIRA_ISSUE_PATH}/search-jira-links`, data);
    return resp.data;
  }

  async fetchJiraIntegrationConfigs(data) {
    const resp = await axios.get(`${JIRA_INTEGRATION_PATH}`, { params: data });
    return resp.data;
  }
  async fetchJiraIntegrationConfig(data) {
    const resp = await axios.get(`${JIRA_INTEGRATION_PATH}/${data?.id}`);
    return resp.data;
  }
  async createJiraIntegrationConfig(data) {
    const resp = await axios.post(`${JIRA_INTEGRATION_PATH}`, data);
    return resp.data;
  }
  async updateJiraIntegrationConfig(data) {
    const resp = await axios.put(`${JIRA_INTEGRATION_PATH}/${data?.id}`, data);
    return resp.data;
  }
  async deleteJiraIntegrationConfig(data) {
    const resp = await axios.delete(`${JIRA_INTEGRATION_PATH}/${data?.id}`);
    return resp.data;
  }
  async updateJiraConfigActivation(data) {
    const resp = await axios.put(`${JIRA_INTEGRATION_PATH}/${data?.id}/activation`, data);
    return resp.data;
  }

  async checkJiraConnection(data) {
    const resp = await axios.get(`${JIRA_INTEGRATION_PATH}/${data?.id}/connection-check`);
    return resp.data;
  }

  async fetchActiveJiraConfig(data) {
    const resp = await axios.get(`${JIRA_INTEGRATION_PATH}/activeSetting`, { params: data });
    return resp.data;
  }

  async updateVManageJiraHook(data) {
    const resp = await axios.put(`${JIRA_INTEGRATION_PATH}/${data?.id}/hook`);
    return resp.data;
  }

  async sendGetRequest(data) {
    const resp = await axios.post(`${JIRA_ISSUE_PATH}/sendGetRequest`, data);
    return resp.data;
  }

  async fetchJIRAAllProjects(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/projects`, { params: data });
    return resp.data;
  }

  async fetchJiraLinkTypes(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/linkTypes`, { params: data });
    return resp.data;
  }

  async fetchJIRAEntityProjects(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/entity/projects`, { params: data });
    return resp.data;
  }

  async fetchJIRASystemFields(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/system-fields`, { params: data });
    return resp.data;
  }

  async fetchJIRACustomFields(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/custom-fields`, { params: data });
    return resp.data;
  }

  async fetchJIRAProjectWithFields(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/project-with-fields`, { params: data });
    return resp.data;
  }

  // async fetchJIRAProjectStatuses(data) {
  //     const resp = await axios.get(`${JIRA_ISSUE_PATH}/project-statuses`, { params: data }); return resp.data;
  // }

  // async fetchJiraTransitionFieldList(data) {
  //     const resp = await axios.get(`${JIRA_ISSUE_PATH}/issue-transition-fields`, { params: data }); return resp.data;
  // }

  // async fetchJIRAAllStatuses(data) {
  //     const resp = await axios.get(`${JIRA_ISSUE_PATH}/statuses`, { params: data }); return resp.data;
  // }

  async fetchJIRAPriorities(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/priorities`, { params: data });
    return resp.data;
  }

  async fetchJIRAIssue(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}`, { params: data });
    return resp.data;
  }

  async fetchJIRAIssueFields(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/system-fields`, { params: data });
    return resp.data;
  }

  async fetchJiraConfigs(data) {
    const resp = await axios.get(`${JIRA_CONFIG_PATH}`, {
      params: data
    });
    return resp.data;
  }

  async fetchJiraDefaultConfigs() {
    const resp = await axios.get(`${JIRA_CONFIG_PATH}/default`);
    return resp.data;
  }

  async createJIRAConfig(data) {
    const resp = await axios.post(`${JIRA_CONFIG_PATH}`, data);
    return resp.data;
  }
  async updateJiraConfig(data) {
    const resp = await axios.put(`${JIRA_CONFIG_PATH}/${data?.id}`, data);
    return resp.data;
  }
  async deleteJiraConfig(data) {
    const resp = await axios.delete(`${JIRA_CONFIG_PATH}/${data?.id}`);
    return resp.data;
  }

  async fetchJiraProjectIssueTypes(data) {
    const resp = await axios.get(`${JIRA_ISSUE_PATH}/project-issue-types`, { params: data });
    return resp.data;
  }
}

export default new JiraApi();
