import { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CaretDown, X } from 'phosphor-react';
import { Controller } from 'react-hook-form';

import { classNames } from '../../helpers/Helper';
import { findId } from '../../helpers/Helper';
import { dropdownOptions } from './Dropdown.Utils';
const labelSpanClass = 'mt-2';

export default function SearchDropdown({
  clearable,
  passValue,
  onClear,
  disabled,
  label,
  required,
  control,
  name,
  rules,
  selectedItem,
  options,
  isSort = true,
  backgroundColor,
  textColor
}) {
  const [selectedOption, setSelectedOption] = useState();
  const [query, setQuery] = useState('');

  const mapOptions = dropdownOptions(options, isSort);

  const filteredOptions =
    query === ''
      ? mapOptions
      : mapOptions.filter((option) =>
          option.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const dropdownIcon = () => {
    if (clearable) {
      if (!selectedOption) return <CaretDown className="ml-4 " />;
      if (selectedOption)
        return (
          <X
            className="cursor-pointer text-gray-500"
            weight="duotone"
            size="16"
            onClick={() => {
              setSelectedOption('');
              if (passValue) {
                passValue('');
              }
              onClear('');
            }}
          />
        );
    } else {
      return (
        <>
          <div>
            <CaretDown className={classNames(textColor ? textColor : 'text-gray-500', 'ml-4')} />
          </div>
        </>
      );
    }
  };

  const renderComponent = (field) => {
    return (
      <div className="flex flex-auto cursor-pointer p-1">
        <div className="flex flex-grow flex-col ">
          <Combobox
            value={selectedOption}
            onChange={(e) => {
              field.onChange(findId(mapOptions, e));
              setSelectedOption(e);
              if (passValue) {
                passValue(findId(mapOptions, e?.name));
              }
            }}
          >
            {label && (
              <Combobox.Label
                className={classNames(
                  'mb-2 block text-sm font-medium leading-5 text-gray-700',
                  disabled ? ' text-gray-300 ' : 'text-gray-700'
                )}
              >
                {label}
                {required && <span className={!disabled && labelSpanClass}>*</span>}
              </Combobox.Label>
            )}
            <div className="relative mt-1">
              <div
                className={classNames(
                  backgroundColor ? backgroundColor : 'bg-white',
                  'relative w-full cursor-default  overflow-hidden rounded-lg text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'
                )}
              >
                <Combobox.Input
                  className={classNames(
                    backgroundColor ? backgroundColor : 'bg-white',
                    textColor ? textColor : 'text-gray-900',
                    'w-full border-none  py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 ',
                    'placeholder-style'
                  )}
                  placeholder={selectedItem?.name}
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  {dropdownIcon()}
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="fixed mt-1 max-h-60 w-48 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredOptions.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div>
                  ) : (
                    filteredOptions.map((option) => (
                      <Combobox.Option
                        key={option.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-4 pr-4 ${
                            active ? 'bg-primary-600 text-white' : 'text-gray-900'
                          }`
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                              {option.name}
                            </span>
                            {selected && (
                              <span
                                className={`${
                                  active ? 'text-white' : 'text-primary-500'
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setSelectedOption(selectedItem ? selectedItem : null);
  }, [selectedItem]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ ...rules, required }}
        render={({ field }) => renderComponent(field)}
      />
    </>
  );
}
