import { axios } from "./ApiClient";
const INSIGHT_PATH = "/api/insights";

class InsightApi {

    async getTestRunInsight(data) {
        const resp = await axios.get(`${INSIGHT_PATH}/test-run-stats`,{params: data });
        return resp.data;
    }

    async getDefectInsight(data) {
        const resp = await axios.get(`${INSIGHT_PATH}/defect-insight`,{params: data });
        return resp.data;
    }

    async getAttachmentAndCommentCount(entityId) {
        const resp = await axios.get(`${INSIGHT_PATH}/indicator/${entityId}`);
        return resp.data;
    }

}

export default new InsightApi();
