import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// API & HOOKS
import { useApi } from '../../../hooks/UseApi';
import DashboardApi from '../../../api/DashboardApi';
import { useTranslation } from 'react-i18next';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
// COMPONENTS
import { Modal } from '../../../components/modal';
import { Body, Heading, Button, Dropdown } from '../../../designSystem/DesignSystem';
// HELPERS
import {
  editModalMapping,
  getUserFirstLastNameById,
  isValidList,
  isValidValue,
  sortArr,
  translatedDateFormat
} from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { DASHBOARD_PAGE_ENUM } from '../helper/DashboardHelper';
import { getItemName, getItemStyle, getListStyle } from './DashboardModal.Helper';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';

const DashboardProjectAssignModal = ({ closeModal, isOpen, users, projects, projectId }) => {
  const { project } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);

  const { control, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();

  const [items, setItems] = useState([]);
  const [fixed, setFixed] = useState(false);
  const [newItem, setnewItem] = useState({});
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [dashboardOptions, setDashboardOptions] = useState([]);

  //#region API
  const dashboardOptionLoader = useApi({
    name: 'dashboardOptions',
    api: DashboardApi.fetchDashboardOptions,
    autoLoad: false,
    onSuccess: () => fillDashboardoptions()
  });

  const fetchProjectPageConfig = useApi({
    name: 'fetchProjectPageConfig',
    api: DashboardApi.fetchProjectPageConfig,
    onSuccess: () => {
      let d = fetchProjectPageConfig?.data;
      if (d && d?.[DASHBOARD_PAGE_ENUM.DASHBOARD_PAGE]) {
        let arr = d?.[DASHBOARD_PAGE_ENUM.DASHBOARD_PAGE];
        setItems(arr.sort(sortArr('order', null)));
      }
    },
    onError: (err) => showErrorMes(err)
  });

  const saveProjectDashboardOrders = useApi({
    name: 'saveProjectDashboardOrders',
    api: DashboardApi.saveProjectDashboardOrders,
    onSuccess: () => showSuccessMes(INFO_MESSAGES.save),
    onError: (err) => showErrorMes(err)
  });
  //#endregion

  const fillDashboardoptions = () => {
    let arr = [];
    if (isValidList(dashboardOptionLoader?.data)) {
      dashboardOptionLoader.data.map((x) => {
        let n = x?.text || getArtifactName(projectConfig, x?.name);
        let createdby = getUserFirstLastNameById(users, x?.createdBy);
        if (isValidValue(createdby)) {
          n += t(' created by ') + createdby + ',  ' + translatedDateFormat(x?.modifiedTime, 'DD MMM YYYY', t);
        }

        if (n) {
          arr.push({
            value: parseInt(x?.id + '', 10),
            key: parseInt(x?.id + '', 10),
            id: parseInt(x?.id + '', 10),
            name: n
          });
        }
      });
    }
    setDashboardOptions(arr);
  };

  const removeItem = (id) => {
    if (id && isValidList(items)) {
      let arr = [];
      items.forEach((el) => {
        if (el?.dashboardId !== id) arr.push(el);
      });
      setItems(arr);
    }
    return true;
  };

  const addItemItem = (id) => {
    let t = items.find((x) => x?.dashboardId === id);
    if (id && !isValidValue(t?.dashboardId)) {
      let arr = isValidList(items) ? [...items] : [];
      arr.push({ dashboardId: id, order: 99 });
      setItems(arr);
    }
    setValue('addItem', null);
    setnewItem({});
  };

  // TODO: Birden fazla yerde aynı onDragEnd fonksiyonu kulllanılmış.
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };
    const elems = reorder(items, result.source.index, result.destination.index);
    setItems(elems);
  };

  const saveChanges = () => {
    let input = [];
    items.forEach((x, i) => {
      let el = { ...x, order: i + 1 };
      input.push(el);
    });

    saveProjectDashboardOrders.execute({
      projectId: selectedProjectId,
      dashboards: input,
      page: DASHBOARD_PAGE_ENUM.DASHBOARD_PAGE
    });
  };

  useEffect(() => {
    if (projectId) {
      let p = projects.find((i) => i?.key === projectId);
      if (p) setSelectedProjectId(projectId);
    }
    dashboardOptionLoader.execute();
  }, [projectId]);

  useEffect(() => {
    setItems([]);
    if (selectedProjectId) {
      let inp = {};
      let mappedArray = editModalMapping(inp);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      fetchProjectPageConfig.execute({ projectId: selectedProjectId });
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (fixed === true) {
      saveChanges();
      setFixed(false);
    }
  }, [fixed]);

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal()}
      >
        <Modal.Title>
          <Heading.Group
            text={t('Project Dashboard Order Setting')}
            fontSize="text-xl"
          >
            <div className="flex gap-4">
              <Button
                id="dashboardProjectAssignModal-close-button"
                icon="X"
                onClick={closeModal}
                size="xsmall"
              />
            </div>
          </Heading.Group>
        </Modal.Title>
        <Modal.Content h={'md'}>
          <div className="flex flex-grow flex-col gap-8">
            <div className="ml-2 mt-2 flex w-[500px] gap-1">
              <Heading
                className={'w-36 text-base'}
                text={t('Selected Project')}
              />
              <Body
                text={project?.name}
                className={'text-base'}
              />
            </div>
            <div className="flex w-[500px] gap-1">
              <Dropdown
                label={t('Add New Dashboard')}
                clearable
                disabled={!isValidValue(selectedProjectId)}
                control={control}
                placeholder={t('Select Dashboard')}
                name="addItem"
                options={dashboardOptions}
                selectedItem={newItem}
                passValue={(value) => addItemItem(value?.[0]?.id)}
              />
            </div>
            <Body
              type="medium"
              text={t('You can change order of dashboards. Use drag and drop functionality on row.')}
            />
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item?.dashboardId}
                          draggableId={`${item?.dashboardId}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...{
                                ...provided.draggableProps,
                                ...provided.dragHandleProps
                              }}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-1 self-center">
                                  <Body
                                    type="medium"
                                    text={index + 1}
                                  />
                                </div>
                                <div className="col-span-8 self-center">
                                  <Body
                                    type="medium"
                                    text={getItemName(item?.dashboardId, dashboardOptions, removeItem, setFixed)}
                                  />
                                </div>
                                <div className="col-span-1">
                                  <Button
                                    id="dashboardProjectAssignModal-remove-button"
                                    secondary
                                    icon="TrashSimple"
                                    size="xsmall"
                                    onClick={() => removeItem(item?.dashboardId)}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="dashboardProjectAssignModal-cancel-button"
            secondary
            text={t('Close')}
            onClick={() => closeModal()}
          />
          <Button
            id="dashboardProjectAssignModal-submit-button"
            primary
            text={t('Submit')}
            loading={saveProjectDashboardOrders.loading}
            onClick={() => saveChanges()}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default DashboardProjectAssignModal;
