import { ENTITY_TYPES } from '../constants';
import i18next from 'i18next';

import {
  getRequirementName,
  getTestCaseName,
  getCycleName,
  getRunName,
  getExecutionName,
  getSuiteName,
  getArtifactName
} from '../helpers/ArtifactNameHelper';
// <-- ## -->

export const getTextForWithChildButton = (entityType, projectConfig) => {
  if (entityType === ENTITY_TYPES.TEST_SUITE) {
    return i18next.t('childrenEntityClone', { entity: getRunName(projectConfig) });
  } else if (entityType === ENTITY_TYPES.TEST_CYCLE) {
    return i18next.t('childrenEntitiesClone', {
      entity1: getSuiteName(projectConfig),
      entity2: getRunName(projectConfig)
    });
  } else if (entityType === ENTITY_TYPES.TEST_FOLDER) {
    return i18next.t('childrenMultiEntitiesClone', {
      entity1: getCycleName(projectConfig),
      entity2: getSuiteName(projectConfig),
      entity3: getRunName(projectConfig)
    });
  } else if (entityType === ENTITY_TYPES.FOLDER) {
    return i18next.t('childrenEntityClone', { entity: getRequirementName(projectConfig) });
  } else if (entityType === ENTITY_TYPES.TEST_CONTAINER) {
    return i18next.t('childrenEntityClone', { entity: getTestCaseName(projectConfig) });
  } else if (entityType === ENTITY_TYPES.TEST_CASE) {
    return i18next.t('childrenEntityClone', { entity: getRequirementName(projectConfig) });
  }
  return '';
};

export const allCheckboxList = (entityType, projectConfig) => {
  return [
    {
      fieldName: 'withChilds',
      label: `${getTextForWithChildButton(entityType, projectConfig)}`,
      entityTypes: [
        ENTITY_TYPES.FOLDER,
        ENTITY_TYPES.TEST_CYCLE,
        ENTITY_TYPES.TEST_CONTAINER,
        ENTITY_TYPES.TEST_FOLDER,
        ENTITY_TYPES.TEST_SUITE
      ],
      defaultValue: false
    },
    {
      fieldName: 'withSteps',
      label: i18next.t('entityStepsClone', {
        entity: getTestCaseName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.TEST_CASE, ENTITY_TYPES.TEST_CONTAINER],
      defaultValue: false
    },
    {
      fieldName: 'withRequirements',
      label: i18next.t('entitiesClone', {
        entity1: getTestCaseName(projectConfig),
        entity2: getRequirementName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.TEST_CASE, ENTITY_TYPES.TEST_CONTAINER],
      defaultValue: false
    },
    {
      fieldName: 'withRequirementTestCases',
      label: i18next.t('entitiesClone', {
        entity1: getRequirementName(projectConfig),
        entity2: getTestCaseName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.FOLDER, ENTITY_TYPES.REQUIREMENT],
      defaultValue: false
    },
    {
      fieldName: 'withTestCycles',
      label: i18next.t('entityClone', {
        entity: getCycleName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.DEFECT],
      defaultValue: false
    },
    {
      fieldName: 'withTestRuns',
      label: i18next.t('entityClone', {
        entity: getRunName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.DEFECT],
      defaultValue: false
    },
    {
      fieldName: 'withExecutions',
      label: i18next.t('entityClone', {
        entity: getExecutionName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.DEFECT],
      defaultValue: false
    },
    {
      fieldName: 'withCustomFields',
      label: i18next.t('Custom Fields'),
      entityTypes: [
        ENTITY_TYPES.TEST_CASE,
        ENTITY_TYPES.TEST_CONTAINER,
        ENTITY_TYPES.REQUIREMENT,
        ENTITY_TYPES.FOLDER,
        ENTITY_TYPES.TEST_RUN,
        ENTITY_TYPES.TEST_FOLDER,
        ENTITY_TYPES.TEST_CYCLE,
        ENTITY_TYPES.TEST_SUITE,
        ENTITY_TYPES.DEFECT
      ],
      defaultValue: false
    },
    {
      fieldName: 'withTestCaseCustomFields',
      label: i18next.t('entityCustomFieldClone', {
        entity: getTestCaseName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.TEST_CONTAINER],
      defaultValue: false
    },
    {
      fieldName: 'withSystemFields',
      label: i18next.t('System Fields'),
      entityTypes: [ENTITY_TYPES.REQUIREMENT, ENTITY_TYPES.TEST_CYCLE, ENTITY_TYPES.TEST_SUITE, ENTITY_TYPES.DEFECT],
      defaultValue: false
    },
    {
      fieldName: 'withRequirementCustomFields',
      label: i18next.t('entityCustomFieldClone', {
        entity: getRequirementName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.FOLDER],
      defaultValue: false
    },
    {
      fieldName: 'withRequirementSystemFields',
      label: i18next.t('entitySystemFieldClone', {
        entity: getRequirementName(projectConfig)
      }),
      entityTypes: [ENTITY_TYPES.FOLDER],
      defaultValue: false
    }
    // {
    //   fieldName: "withLinks",
    //   label: "With Links",
    //   entityTypes:  [ENTITY_TYPES.TEST_CASE,ENTITY_TYPES.TEST_CONTAINER,ENTITY_TYPES.REQUIREMENT,ENTITY_TYPES.FOLDER,ENTITY_TYPES.TEST_RUN
    //        ENTITY_TYPES.TEST_FOLDER,ENTITY_TYPES.TEST_CYCLE,ENTITY_TYPES.TEST_SUITE,ENTITY_TYPES.DEFECT],
    //   defaultValue: false,
    // },
  ];
};

export const getEntityTypeList = (projectConfigs, relatedEntity) => {
  let list = [];

  list.push({
    id: ENTITY_TYPES.TEST_CYCLE,
    key: ENTITY_TYPES.TEST_CYCLE,
    value: ENTITY_TYPES.TEST_CYCLE,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CYCLE),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CYCLE)
  });
  list.push({
    id: ENTITY_TYPES.REQUIREMENT,
    key: ENTITY_TYPES.REQUIREMENT,
    value: ENTITY_TYPES.REQUIREMENT,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.REQUIREMENT),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.REQUIREMENT)
  });
  list.push({
    id: ENTITY_TYPES.DEFECT,
    key: ENTITY_TYPES.DEFECT,
    value: ENTITY_TYPES.DEFECT,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.DEFECT),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.DEFECT)
  });
  list.push({
    id: ENTITY_TYPES.TEST_FOLDER,
    key: ENTITY_TYPES.TEST_FOLDER,
    value: ENTITY_TYPES.TEST_FOLDER,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_FOLDER),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_FOLDER)
  });
  list.push({
    id: ENTITY_TYPES.TEST_CASE,
    key: ENTITY_TYPES.TEST_CASE,
    value: ENTITY_TYPES.TEST_CASE,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CASE),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CASE)
  });
  list.push({
    id: ENTITY_TYPES.TEST_RUN,
    key: ENTITY_TYPES.TEST_RUN,
    value: ENTITY_TYPES.TEST_RUN,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_RUN),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_RUN)
  });
  list.push({
    id: ENTITY_TYPES.TEST_EXECUTION,
    key: ENTITY_TYPES.TEST_EXECUTION,
    value: ENTITY_TYPES.TEST_EXECUTION,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_EXECUTION),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_EXECUTION)
  });
  list.push({
    id: ENTITY_TYPES.RELEASE,
    key: ENTITY_TYPES.RELEASE,
    value: ENTITY_TYPES.RELEASE,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.RELEASE),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.RELEASE)
  });
  list.push({
    id: ENTITY_TYPES.TEST_SUITE,
    key: ENTITY_TYPES.TEST_SUITE,
    value: ENTITY_TYPES.TEST_SUITE,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_SUITE),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_SUITE)
  });
  list.push({
    id: ENTITY_TYPES.FOLDER,
    key: ENTITY_TYPES.FOLDER,
    value: ENTITY_TYPES.FOLDER,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.FOLDER),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.FOLDER)
  });
  list.push({
    id: ENTITY_TYPES.TEST_CONTAINER,
    key: ENTITY_TYPES.TEST_CONTAINER,
    value: ENTITY_TYPES.TEST_CONTAINER,
    text: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CONTAINER),
    name: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CONTAINER)
  });

  return list.filter((item) => item?.value !== relatedEntity);
};
