import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { CHART_TYPE, fillChartData, getChartHeading } from '../helper/DashboardHelper';
import { isValidList } from '../../../helpers/Helper';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const CustomLineChart = ({ chart, projectId, dateFilter, projectConfig }) => {
  const [data, setData] = useState([]);
  const levelStatus =
    chart?.configuration?.dateHistogram === true ||
    (isValidList(chart?.configuration?.fields) && chart?.configuration?.fields.length > 1);

  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      fillChartData(dataLoader?.data, chart, setData, projectId, null);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);

  return (
    <>
      {getChartHeading(chart, projectConfig)}
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: '0',
          max: 'auto',
          stacked: levelStatus,
          reverse: false
        }}
        enableArea={chart?.type === CHART_TYPE.area ? true : false}
        // areaBaselineValue={0}
        axisTop={null}
        axisRight={null}
        colors={{ scheme: 'accent' }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Count',
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        lineWidth={3}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />{' '}
    </>
  );
};

export default CustomLineChart;
