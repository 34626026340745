import React from 'react'
import { Disclosure } from '@headlessui/react'
import { CaretDown, CaretRight } from 'phosphor-react'

const DEFAULT_ICON_SIZE = 12;

const Accordion = ({ button, children, iconSize, defaultOpen, border }) => {
    return (
        <Disclosure defaultOpen={defaultOpen} >
            {({ open }) => (
                <>
                    <Disclosure.Button className={`flex items-center gap-2 pb-4 flex-grow justify-between ${border && "border-b border-gray-300"}`}>
                        {button}
                        {open && (<CaretDown size={iconSize ? iconSize : DEFAULT_ICON_SIZE} />)}
                        {!open && (<CaretRight size={iconSize ? iconSize : DEFAULT_ICON_SIZE} />)}
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                        {children}
                    </Disclosure.Panel>
                </>
            )
            }
        </Disclosure>
    )
}


export default Accordion
