import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// API & HOOKS
import { useApi } from '../../hooks/UseApi';
import TestRunExecutionApi from '../../api/TestRunExecutionApi';
// DESIGN SYSTEM & COMPONENTS
import { StatusPill, Heading } from '../../designSystem/DesignSystem';
import ExecutionStepAttachmentModal from '../tasks/modal/ExecutionStepAttachmentModal';
// HELPERS & CONSTANTS
import { NoDataMessage } from '../../helpers/TableHelper';
import { getUrl, isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { ENTITY_TYPES } from '../../constants';

const LastRunExecutionDetails = ({ lastTestRunExecution, lastTestRun, projectId, tableHeight }) => {
  const { t } = useTranslation();

  const [testRunExecutionSteps, setTestRunExecutionSteps] = useState([]);
  const [isIncluded, setisIncludedStep] = useState(false);

  const testRunStepsLoader = useApi({
    name: 'fetching test run steps',
    api: TestRunExecutionApi.getSteps,
    onSuccess: () => setTestRunExecutionSteps(testRunStepsLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const isIncludedStep = () => {
    testRunExecutionSteps.forEach((step) => {
      if (step?.testCaseStep.includedStep === true) setisIncludedStep(true);
    });
  };

  const onAttachmentChange = (value, index) => {
    let newState = [...testRunExecutionSteps];
    newState[index].attachmentCount = value;
    setTestRunExecutionSteps(newState);
  };

  const lastRunLink = () => {
    return (
      <>
        <Link
          className={'text-xs font-medium text-blue-900 underline-offset-4 hover:underline'}
          to={getUrl(ENTITY_TYPES.TEST_RUN, lastTestRun, projectId)}
        >
          {` ${lastTestRun?.code + ' ' + (isValidValue(lastTestRun?.summary) ? lastTestRun?.summary : '')} `}
        </Link>
      </>
    );
  };

  const exeLink = () => {
    return (
      <>
        <Link
          className={'text-xs font-medium text-blue-900 underline-offset-4 hover:underline'}
          to={getUrl(ENTITY_TYPES.TEST_RUN, lastTestRun, projectId)}
        >
          {` ${lastTestRunExecution?.code}`}
        </Link>
      </>
    );
  };

  useEffect(() => {
    if (lastTestRunExecution?.id) testRunStepsLoader.execute(lastTestRunExecution?.id);
    isIncludedStep();
  }, [lastTestRunExecution]);

  return (
    <>
      {!isValidValue(lastTestRunExecution) ? (
        <NoDataMessage message={t('No data available')} />
      ) : (
        <>
          <div className="flex items-center justify-between p-4">
            <div className="flex flex-col">
              {lastRunLink()}
              {exeLink()}
            </div>

            <StatusPill value={lastTestRunExecution?.status} />
          </div>
          <div
            className="overflow-y-auto"
            style={{ height: tableHeight }}
          >
            <table className="w-full">
              <thead className="overflow-x-auto overflow-y-auto bg-gray-50">
                <tr className=" text-left text-xs font-medium tracking-wide  text-gray-500">
                  <th className=" px-2 py-3"> # </th>
                  {isIncluded && <th className=" px-2 py-3">{t('Related Test Case')}</th>}
                  {['Step', 'Expected Result', 'Actual Result', 'Step Result', 'Attachments'].map((header, index) => (
                    <th
                      key={index}
                      className=" px-2 py-3"
                    >
                      {t(header)}{' '}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 overflow-x-auto overflow-y-auto bg-white">
                {(testRunExecutionSteps || []).map((step, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap px-1 py-4">
                      <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                        {index + 1}
                      </div>
                    </td>
                    {isIncluded && (
                      <td className="whitespace-nowrap px-3 py-4">
                        <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                          {step?.testCaseStep?.testCase?.code}
                        </div>
                      </td>
                    )}
                    <td className="whitespace-nowrap px-2 py-4">
                      <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                        {step?.testCaseStep?.description}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-2 py-4">
                      <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                        {step?.testCaseStep?.expectedResult}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-2 py-4">
                      <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                        {step?.actualResult}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                        <StatusPill value={step?.status} />
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="flex gap-2  overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-gray-500">
                        <ExecutionStepAttachmentModal
                          step={step}
                          submitCallBack={(value) => onAttachmentChange(value, index)}
                        />
                        <Heading
                          type="small"
                          text={step.attachmentCount}
                          color="text-gray-500"
                          className="place-self-center"
                        ></Heading>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
export default LastRunExecutionDetails;
