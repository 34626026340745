import { isValidList } from "../../../helpers/Helper";



export const getPieChartData = (buckets) => {

    let arr = [];
    if(isValidList(buckets)){
    buckets.map((x) => {
      if (x?.key && x?.doc_count)
        arr.push({
          id: x?.key,
          label: x?.key,
          value: x?.doc_count
        
        });
    });
  }
    return arr;
  
  };