import React from 'react';
// COMPONENT IMPORTS
import { Loader } from '../../../designSystem/DesignSystem';
import { NoDataMessage } from '../../../helpers/TableHelper';
// HELPER IMPORTS
import { isValidList } from '../../../helpers/Helper';

const SearchResults = (props) => {
  const { isLoading, results, handleOnClick } = props;

  if (isLoading) return <Loader />;
  if (!isLoading && !isValidList(results)) return <NoDataMessage message="No data available" />;

  return (
    <>
      <div className="flex flex-grow gap-2 px-1">
        <div className="w-full rounded-lg">
          <div className="divide-y divide-gray-200 overflow-hidden rounded border border-gray-200 shadow-sm">
            {results?.map((item) => (
              <div
                key={item?._id}
                onClick={() => handleOnClick(item)}
                className="group flex h-8 cursor-pointer items-center justify-between pl-2 text-sm font-normal text-gray-900 odd:bg-white even:bg-slate-50 hover:bg-primary-600 hover:text-white"
              >
                {item._source?.code +
                  ' ' +
                  (item._source?.name || '') +
                  (item._source?.summary || '') +
                  (item?._source?.majorVersion ? ' v' + item?._source?.majorVersion : '')}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;
