/* eslint-disable */

import { CaretLeft, CaretRight } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// HELPERS & CONSTANTS
import { paginationPageSizeDD } from '../../constants';
import { classNames, isValidValue } from '../../helpers/Helper';
// DESIGN SYSTEM
import { Body } from '../../designSystem/DesignSystem';
import { DropdownPagination } from './DropdownPagination';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { translateArrItemName } from '../../helpers/TranslationHelper';

const TablePagination = ({ pageIndex, pageCount, pageParams, pageSize, gotoPage, setPageSize }) => {
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();
  const isActive = (current, i) => current === i;

  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);
  const [currentButton, setCurrentButton] = useState(1);

  const numberOfPages = [];
  for (let i = 1; i <= pageCount; i++) {
    numberOfPages.push(i);
  }

  const dotsInitial = '...';
  const dotsLeft = '... ';
  const dotsRight = ' ...';
  const dotList = [dotsInitial, dotsLeft, dotsRight];

  useEffect(() => {
    let newCurrentButton = pageIndex + 1;
    fillPageList(newCurrentButton);
  }, [pageSize, pageCount]);

  const fillPageList = (newCurrentButton) => {
    let tempNumberOfPages = [...arrOfCurrButtons];

    if (numberOfPages.length < 6) {
      tempNumberOfPages = numberOfPages;
    } else if (newCurrentButton >= 1 && newCurrentButton <= 3) {
      tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages.length];
    } else if (newCurrentButton === 4) {
      const sliced = numberOfPages.slice(0, 5);
      tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
    } else if (newCurrentButton > 4 && newCurrentButton < numberOfPages.length - 2) {
      const sliced1 = numberOfPages.slice(newCurrentButton - 2, newCurrentButton);
      const sliced2 = numberOfPages.slice(newCurrentButton, newCurrentButton + 1);

      tempNumberOfPages = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight, numberOfPages.length];
    } else if (newCurrentButton > numberOfPages.length - 3) {
      const sliced = numberOfPages.slice(numberOfPages.length - 4);
      tempNumberOfPages = [1, dotsLeft, ...sliced];
    } else if (newCurrentButton === dotsInitial) {
      setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (newCurrentButton === dotsRight) {
      setCurrentButton(arrOfCurrButtons[3] + 2);
    } else if (newCurrentButton === dotsLeft) {
      setCurrentButton(arrOfCurrButtons[3] - 2);
    }

    setArrOfCurrButtons(tempNumberOfPages);
  };

  const canPreviousPage = currentButton > 1;
  const canNextPage = currentButton < numberOfPages.length;

  const handlePreviousPage = () => {
    if (canPreviousPage) {
      let newCurrentButton = currentButton - 1;
      setCurrentButton(newCurrentButton);
      fillPageList(newCurrentButton);
      gotoPage(newCurrentButton);
    }
  };

  const handleNextPage = () => {
    if (canNextPage) {
      let newCurrentButton = currentButton + 1;
      setCurrentButton(newCurrentButton);
      fillPageList(newCurrentButton);
      gotoPage(newCurrentButton);
    }
  };

  const handleCurrentPage = (item) => {
    if (!dotList.includes(item)) {
      setCurrentButton(item);
      fillPageList(item);
      gotoPage(item);
    }
  };
  let pageOptions = translateArrItemName(paginationPageSizeDD, t);
  return (
    <div className="flex h-8 items-end justify-between ">
      <div
        id="pagination-l"
        className="flex flex-grow items-center space-x-2"
      >
        <div
          id="pagination-l-dropdown"
          className="flex max-w-[150px]"
        >
          <div className="flex flex-grow flex-col">
            <DropdownPagination
              name="pagination"
              textSize="text-xs"
              control={control}
              buttonH="h-8"
              options={pageOptions}
              selectedItem={paginationPageSizeDD.find((item) => t(item.value) === pageSize) || paginationPageSizeDD[0]}
              passValue={(value) => {
                setPageSize(value[0]?.value);
              }}
            />
          </div>
        </div>
        {
          <div
            id="pagination-l-text"
            className="-ml-4 flex items-center gap-1"
          >
            <Body
              type="small"
              color="text-gray-700"
              text={i18next.t('Showing ')}
            />
            <Body
              type="small"
              color="text-gray-700"
              bold
              text={`${
                currentButton > 1
                  ? (currentButton - 1) * pageSize + 1
                  : pageParams?.totalElements > 0
                  ? currentButton
                  : 0
              }`}
            />
            <Body
              type="small"
              color="text-gray-700"
              text={i18next.t(' to ')}
            />
            <Body
              type="small"
              color="text-gray-700"
              bold
              text={
                pageSize * currentButton >= pageParams?.totalElements
                  ? pageParams?.totalElements
                  : pageSize * currentButton
              }
            />
            <Body
              type="small"
              color="text-gray-700"
              text={i18next.t(' of ')}
            />
            <Body
              type="small"
              color="text-gray-700"
              bold
              text={pageParams?.totalElements}
            />
            <Body
              type="small"
              color="text-gray-700"
              text={i18next.t(' results.')}
            />
          </div>
        }
      </div>
      <div
        id="pagination-r"
        className="flex"
      >
        <div
          className={classNames(
            'flex h-6 w-8 flex-grow items-center',
            'rounded-l border-b-2 border-l-2 border-r-2 border-t-2 border-gray-100 p-2',
            'text-gray-300 hover:bg-gray-100',
            !canPreviousPage ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
          onClick={() => handlePreviousPage()}
        >
          <CaretLeft
            weight="bold"
            color={!canPreviousPage ? '#9CA3AF' : '#374151'}
          />
        </div>
        {arrOfCurrButtons.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className={classNames(
                  'flex h-6 flex-grow items-center justify-center',
                  'w-6 p-1.5',
                  isActive(currentButton, item)
                    ? ' cursor-none  border-2 border-primary-600 bg-primary-200 text-primary-200'
                    : ' cursor-pointer border-b-2 border-l border-r border-t-2 border-gray-100 bg-transparent  hover:bg-gray-100'
                )}
                onClick={() => {
                  if (!dotList.includes(item)) {
                    handleCurrentPage(item);
                  }
                }}
              >
                <Body
                  type="medium"
                  bold={isActive(currentButton, item)}
                  color={isActive(currentButton, item) ? 'text-primary-600' : 'text-gray-700'}
                  text={item}
                />
              </div>
            </>
          );
        })}
        <div
          className={classNames(
            'flex h-6 w-8 flex-grow items-center',
            'rounded-r border-b-2 border-l-2 border-r-2 border-t-2 border-gray-100 p-2',
            'text-gray-300 hover:bg-gray-100',
            !canNextPage ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
          onClick={() => handleNextPage()}
        >
          <CaretRight
            weight="bold"
            color={!canNextPage ? '#9CA3AF' : '#374151'}
          />
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
