import { axios } from "./ApiClient";

class FolderApi {

    async getTreeData(data) {
        const resp = await axios.get(`/api/folders/tree`, { params: data });
        return resp.data;
    }

    async get(data) {
        const resp = await axios.get(`/api/folders/${data.id}`);
        return resp.data;
    }

    async delete(data) {
        const resp = await axios.delete(`/api/folders/${data.id}`);
        return resp.data;
    }

    async create(data) {
        const resp = await axios.post(`/api/folders/`, data);
        return resp.data;
    }

    async edit(data) {
        const resp = await axios.put(`/api/folders/${data.id}`, data);
        return resp.data;
    }

    async clone(data) {
        const resp = await axios.post(`/api/folders/clone`, data);
        return resp.data;
    }

    async getParens(id) {
        const resp = await axios.get(`/api/folders/${id}/parents`);
        return resp.data;
    }

    async updateFolderParent(id, body) {
        const resp = await axios.put(`/api/folders/${id}/parent`, body);
        return resp.data;
    }
}

export default new FolderApi();