import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TestRunApi from '../../../api/TestRunApi';
import TestSuiteApi from '../../../api/TestSuiteApi';
import EntityCustomFieldForm from '../../../components/customField/EntityCustomFieldForm';
import { Dropdown, Datepicker, Button } from '../../../designSystem/DesignSystem';
//Component Imports
import { Modal } from '../../../components/modal';
import VersionDropdown from '../../../components/version/VersionDropdown';
import { ENTITY_TYPES } from '../../../constants';
//Helper Imports
import { getRunName, getSuiteName } from '../../../helpers/ArtifactNameHelper';
import { createEntityFields, getCustomFields } from '../../../helpers/CustomFieldHelper';
import { isValidValue, isValidList } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import UseCustomField from '../../../hooks/UseCustomField';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
//Api and Hook Imports
import UseProjectUsers from '../../../hooks/UseProjectUsers';
import UseQueryProject from '../../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';

const TestRunModal = (props) => {
  const { isOpen, closeModal, data } = props;
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { projectId } = UseQueryProject('projectId');
  const userList = UseProjectUsers(projectId);
  const projectConfig = UseProjectConfig(projectId);
  const entityCustomFields = UseCustomField(projectId, ENTITY_TYPES.TEST_RUN);

  const [dataForm, setDataForm] = useState(null);
  const [suiteList, setSuiteList] = useState([]);
  const [version, setVersion] = useState(data?.testCase?.majorVersion);
  const [cFields, setCFields] = useState([]);
  const [customFieldForm, setCustomFieldForm] = useState({});
  const { t } = useTranslation();

  const testRunSave = useApi({
    name: 'saving test run',
    api: isValidValue(dataForm) ? TestRunApi.edit : TestRunApi.create,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal(testRunSave?.data);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const suiteOptionLoader = useApi({
    name: 'get suite OptionList',
    api: TestSuiteApi.getSuiteOptionList,
    onSuccess: () => {
      let list = [];
      suiteOptionLoader?.data.map((suiteOption) => {
        list.push({
          id: suiteOption.id,
          value: suiteOption.code,
          text: suiteOption.code + ' ' + suiteOption.name,
          name: suiteOption.code + ' ' + suiteOption.name
        });
      });
      setSuiteList(list);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const onSubmit = (form) => {
    let cFieldForm = getCustomFields(cFields, form);
    const body = {
      ...(isValidValue(data) && { id: data?.id }),
      endDate: form?.endDate ? moment(form.endDate).format('YYYY-MM-DDTHH:mm:ss') : null,
      startDate: form?.startDate ? moment(form.startDate).format('YYYY-MM-DDTHH:mm:ss') : null,
      testerId: form?.testerId[0]?.id,
      testCaseVersion: version?.value,
      customFields: createEntityFields(form, cFieldForm),
      testSuiteId: form?.testSuiteId[0]?.id
    };
    testRunSave.execute(body);
  };

  useEffect(() => {
    if (isValidValue(data)) {
      setValue('endDate', data?.endDate);
      setValue('startDate', data?.startDate);
      setValue('testerId', [data?.tester]);
      setValue('testCaseVersion', version);
      setValue('testSuiteId', [data?.testSuite]);
      setDataForm(data);
    }
    suiteOptionLoader.execute({ projectId });
  }, [data]);

  return (
    <Modal
      open={isOpen}
      close={() => closeModal}
      w={'md'}
    >
      <Modal.Title>
        {isValidValue(dataForm) ? 'Edit ' : 'Create '} {getRunName(projectConfig)}
      </Modal.Title>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Content h={'md'}>
          <div className="flex flex-grow flex-col gap-4">
            <Datepicker
              clearable
              startDateForm={data?.startDate}
              endDateForm={data?.endDate}
              control={control}
              endDateName="endDate"
              startDateName="startDate"
              startDateLabel="Planned Start Date"
              endDateLabel="Planned End Date"
            />
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <Dropdown
                  clearable
                  required
                  control={control}
                  name="testerId"
                  label={t('Tester')}
                  options={userList}
                  selectedItem={dataForm ? dataForm?.tester : null}
                  passValue={(value) => {
                    if (!isValidList(value)) setValue('testerId', null);
                  }}
                />
              </div>
              <VersionDropdown
                entityType={ENTITY_TYPES.TEST_RUN}
                disabled={data?.executionCount > 0}
                name={'testCaseVersion'}
                data={data?.testCase}
                submitCallBack={(data) => setVersion(data)}
              />
            </div>
            <Dropdown
              clearable
              required
              control={control}
              name="testSuiteId"
              label={`${getSuiteName(projectConfig)}`}
              options={suiteList}
              selectedItem={suiteList.filter((item) => item.value === dataForm?.testSuite?.code)[0]}
              passValue={(value) => {
                if (!isValidList(value)) setValue('testSuiteId', null);
              }}
            />
            <EntityCustomFieldForm
              customFieldForm={customFieldForm}
              setCustomFieldForm={setCustomFieldForm}
              control={control}
              setValue={setValue}
              entityFields={data?.customFields}
              entityCustomFields={entityCustomFields}
              dataForm={dataForm}
              customControlForJiraModal={false}
              setCFields={setCFields}
            />
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="testRunModal-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={() => {
              closeModal();
              reset();
            }}
          />
          <Button
            id="testRunModal-submit-button"
            primary
            text={t('Submit')}
            type="submit"
            loading={testRunSave?.loading}
          />
        </Modal.Buttons>
      </form>
    </Modal>
  );
};

export default TestRunModal;
