import React, { useEffect, useMemo, useState } from 'react';
// API AND HOOK IMPORTS
import LdapApi from '../../../api/LdapApi';
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
// DESIGN SYSTEM IMPORT
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { PencilLine, TestTube, Trash } from 'phosphor-react';
// COMPONENT IMPORTS
import BasicTable from '../../../components/table/BasicTable';
import LdapSettingsModal from './LdapSettingsModal';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import { SidePaneRightContent } from '../../../components/Layout';
import LdapTestModal from './LdapTestModal';

// HELPER IMPORTS
import {
  convertToTableData,
  disableResizingForColumns,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../helpers/TableHelper';
import { isSystemAdmin } from '../../../helpers/PriviligeHelper';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';

const LdapSettingsPage = () => {
  const { t } = useTranslation();

  const [ldapModalOpen, setLdapModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [testUserModalOpen, setTestUserModalOpen] = useState(false);
  const [ldap, setLdap] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  // <-- API SETUP -->
  const getLdap = useApi({
    name: 'Getting ldap server settings',
    api: LdapApi.getAll,
    onSuccess: () => setLdap(getLdap?.data),
    onError: (err) => showErrorMes(err)
  });

  const deleteLdap = useApi({
    name: 'Delete Ldap server settings',
    api: LdapApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      setLdap((prevState) => prevState.filter((item) => item.id !== selectedItem.id));
      setDeleteModalOpen(false);
      setSelectedItem(null);
      showSuccessMes('Deleted successfuly.');
    },
    onError: (err) => showErrorMes(err)
  });

  const handleDelete = () => deleteLdap.execute({ id: selectedItem.id });

  const optionsFn = (row) => {
    return [
      {
        name: t('Edit'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(getLdap?.data, row, setSelectedItem);
          setLdapModalOpen(true);
        }
      },
      {
        name: t('Delete'),
        icon: (weight) => (
          <Trash
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(getLdap?.data, row, setSelectedItem);
          setDeleteModalOpen(true);
        }
      },
      {
        name: t('Authentication Test'),
        icon: (weight) => (
          <TestTube
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(getLdap?.data, row, setSelectedItem);
          setTestUserModalOpen(true);
        }
      }
    ];
  };

  const { data, tableColumns } = useMemo(() => {
    let tableData,
      columns = [];
    if (getLdap?.data) {
      tableData = convertToTableData(ldap, 'ldap');
      columns = generateTableColumns(tableData, optionsFn);
    }
    return {
      data: tableData,
      tableColumns: columns
    };
  }, [ldap]);

  let columns = disableResizingForColumns(tableColumns, [{ accessor: t('actions'), width: 30 }]);
  columns = translateColumns(columns, t);

  useEffect(() => {
    getLdap.execute();
  }, []);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('Ldap Servers')}
          >
            <Button
              id="ldapSettings-add-button"
              primary
              text={t('Add')}
              size="small"
              icon="plus"
              onClick={() => setLdapModalOpen(true)}
            />
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center>
            {isSystemAdmin() && (
              <>
                <div className="mt-6">
                  <BasicTable
                    columns={columns}
                    data={data}
                    loading={getLdap?.loading}
                  />
                </div>
              </>
            )}
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>
      {ldapModalOpen && (
        <LdapSettingsModal
          {...(selectedItem && { data: selectedItem })}
          isOpen={ldapModalOpen}
          closeModal={() => {
            setLdapModalOpen(false);
            setSelectedItem(null);
            getLdap.execute();
          }}
        />
      )}
      {deleteModalOpen && (
        <ConfirmationModal
          header={t('Confirm Delete')}
          content={t('Are you sure you want to delete this item?')}
          isOpen={deleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          handleConfirm={handleDelete}
          loading={deleteLdap?.loading}
        />
      )}
      {testUserModalOpen && (
        <LdapTestModal
          {...(selectedItem && { data: selectedItem })}
          isOpen={testUserModalOpen}
          closeModal={() => {
            setTestUserModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default LdapSettingsPage;
