export const ENTITY_TYPES = {
  TEST_FOLDER: 'testFolder',
  TEST_CASE: 'testCase',
  TEST_CYCLE: 'testCycle',
  TEST_SUITE: 'testSuite',
  TEST_RUN: 'testRun',
  TEST_EXECUTION: 'testExecution',
  TEST_CONTAINER: 'testContainer',
  FOLDER: 'folder',
  REQUIREMENT: 'requirement',
  RELEASE: 'release',
  DEFECT: 'defect',
  STATUS_HISTORY: 'statusHistory',
  HISTORY: 'history',
  SUBSCRIPTIONS: 'subscriptions',
  PROJECT: 'project'
};

export const TREENODE_ROOT = {
  type: 'root',
  id: '1'
};

export const CUSTOM_FIELD_TYPES = {
  TEXT: 1,
  LIST: 2,
  MEMO: 3,
  NUMBER: 4,
  CHECKBOX: 5,
  DATE: 6,
  USER: 7,
  MULTI_LIST_USERS: 8,
  DROP_DOWN: 9
};

export const defaultFilter = {
  name: '',
  code: '',
  jiraKey: ''
};

export const systemFieldOperatorOptions = [{ key: 3, name: 'Is One of', value: 'in' }];

export const listfilterOperatorOptions = [
  { key: 1, name: 'Equal', value: 'eq' },
  { key: 3, name: 'Is One of', value: 'in' }
];
export const filterOperatorOptions = [...listfilterOperatorOptions, { key: 2, name: 'Like', value: 'like' }];

export const CUSTOM_FIELD_PREFIX = 'cf';

export const LINK_MENU_NAME = 'Links';

export const STATUS_HISTORY_MENU_NAME = 'Status History';
export const STATUS_HISTORY_MENU_NAME_TR = 'Durum Tarihçesi';

export const HISTORY_MENU_NAME = 'History';
export const HISTORY_MENU_NAME_TR = 'Tarihçe';

export const HISTORY_MENU = 'History';

export const TCASE_VERSION_FILTER_TYPES = {
  FIRST_VERSION: 'FIRST_VERSION',
  LAST_VERSION: 'LAST_VERSION',
  LAST_APPROVED: 'LAST_APPROVED',
  ALL_APPROVED: 'ALL_APPROVED',
  ALL_VERSIONS: 'ALL_VERSIONS',
  DRAFT_VERSION: 'DRAFT_VERSION'
};

export const TestCaseApproveType = {
  SENT_FOR_APPROVAL: 'SENT_FOR_APPROVAL',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT'
};

export const RunExecutionStatus = {
  PASSED: { key: 1, name: 'PASSED', text: 'PASSED', value: 'PASSED', color: 'teal' },
  FAILED: { key: 2, name: 'FAILED', text: 'FAILED', value: 'FAILED', color: 'red' },
  NOT_COMPLETE: { key: 3, name: 'NOT COMPLETE', text: 'NOT_COMPLETE', value: 'NOT_COMPLETE', color: 'orange' },
  BLOCKED: { key: 4, name: 'BLOCKED', text: 'BLOCKED', value: 'BLOCKED', color: 'purple' },
  NA: { key: 5, name: 'NA', text: 'NA', value: 'NA', color: 'grey' },
  NO_RUN: { key: 6, name: 'NO RUN', text: 'NO_RUN', value: 'NO_RUN', color: 'grey' }
};

export const approveType = (approveType) => {
  if (approveType === TestCaseApproveType.APPROVED) {
    return TestCaseApproveType.APPROVED;
  } else if (approveType === TestCaseApproveType.SENT_FOR_APPROVAL) {
    return 'Pending For Approval';
  } else if (approveType === TestCaseApproveType.DRAFT) {
    return TestCaseApproveType.DRAFT;
  } else if (approveType === TestCaseApproveType.REJECTED) {
    return TestCaseApproveType.REJECTED;
  }
};

export const PRIVILEGES = {
  PROJECT_MANAGEMENT: 1,
  // Release Privileges
  CREATE_RELEASE: 2,
  UPDATE_RELEASE: 3,
  DELETE_RELEASE: 4,
  ASSIGN_RELEASE_REQUIREMENT: 5,
  UNASSIGN_RELEASE_REQUIREMENT: 6,
  VIEW_RELEASE: 7,
  VIEW_JIRA_RELEASE: 8,
  // Folder Privileges
  VIEW_FOLDER: 9,
  CLONE_FOLDER: 10,
  CREATE_FOLDER: 11,
  UPDATE_FOLDER: 12,
  DELETE_FOLDER: 13,
  VIEW_JIRA_FOLDER: 14,
  MOVE_FOLDER: 15,
  // Requirement Privileges
  VIEW_REQUIREMENT: 16,
  CLONE_REQUIREMENT: 17,
  IMPORT_REQUIREMENT: 18,
  EXPORT_REQUIREMENT: 19,
  CREATE_REQUIREMENT: 20,
  UPDATE_REQUIREMENT: 21,
  DELETE_REQUIREMENT: 22,
  VIEW_REQUIREMENT_TESTCASE: 23,
  ASSIGN_REQUIREMENT_TESTCASE: 24,
  UNASSIGN_REQUIREMENT_TESTCASE: 25,
  VIEW_JIRA_REQUIREMENT: 26,
  MOVE_REQUIREMENT: 29,
  // Container Privileges
  VIEW_TEST_CONTAINER: 30,
  CLONE_TEST_CONTAINER: 31,
  CREATE_TEST_CONTAINER: 32,
  UPDATE_TEST_CONTAINER: 33,
  DELETE_TEST_CONTAINER: 34,
  MOVE_TEST_CONTAINER: 35,
  // Testcase Privileges
  VIEW_TEST_CASE: 36,
  APPROVE_TEST_CASE: 40,
  DELETE_TEST_CASE: 41,
  UPDATE_TEST_CASE: 37,
  CREATE_TEST_CASE: 38,
  NEW_VERSION_TEST_CASE: 39,
  MOVE_TEST_CASE: 42,
  IMPORT_TEST_CASE: 43,
  EXPORT_TEST_CASE: 44,
  CLONE_TEST_CASE: 45,
  VIEW_TESTCASE_REQUIREMENT: 46,
  ASSIGN_TESTCASE_REQUIREMENT: 47,
  UNASSIGN_TESTCASE_REQUIREMENT: 48,
  // Cycle Privileges
  MOVE_TEST_CYCLE: 51,
  CREATE_TEST_CYCLE: 52,
  UPDATE_TEST_CYCLE: 53,
  UPDATE_TEST_CYCLE_STATUS: 55,
  DELETE_TEST_CYCLE: 49,
  VIEW_TEST_CYCLE: 50,
  CLONE_TEST_CYCLE: 54,
  // Suite Privileges
  MOVE_TEST_SUITE: 59,
  CREATE_TEST_SUITE: 60,
  UPDATE_TEST_SUITE: 61,
  DELETE_TEST_SUITE: 57,
  VIEW_TEST_SUITE: 58,
  CLONE_TEST_SUITE: 62,
  // Run Privileges
  VIEW_TEST_RUN_MANAGEMENT: 63,
  CREATE_UPDATE_TEST_RUN: 64,
  DELETE_TEST_RUN: 65,
  CLONE_TEST_RUN: 66,
  MOVE_TEST_RUN: 67,
  EXPORT_RUN: 68,
  // Execution Privileges
  VIEW_TEST_EXECUTION: 69,
  CREATE_TEST_EXECUTION: 70,
  UPDATE_TEST_EXECUTION: 71,
  DELETE_TEST_EXECUTION: 72,
  // Defect Privileges
  VIEW_DEFECT: 73,
  VIEW_JIRA_DEFECT: 74,
  CREATE_DEFECT: 75,
  UPDATE_DEFECT: 76,
  CLONE_DEFECT: 77,
  ASSIGN_DEFECT: 78,
  DELETE_DEFECT: 79,
  EXPORT_DEFECT: 80,
  UPDATE_DEFECT_STATUS: 81,
  UPDATE_DEFECT_TYPE: 82,
  UPDATE_DEFECT_PRIORITY: 83,
  // Test Folder Privileges
  CREATE_TEST_FOLDER: 91,
  UPDATE_TEST_FOLDER: 92,
  DELETE_TEST_FOLDER: 93,
  CLONE_TEST_FOLDER: 94,
  VIEW_TEST_FOLDER: 95,
  MOVE_TEST_FOLDER: 96,
  // Jira Privileges
  CREATE_JIRA_REQUIREMENT: 27,
  UPDATE_JIRA_REQUIREMENT: 28,
  UPDATE_JIRA_BY_TEST_CYCLE_REQUIREMENT: 56,
  CREATE_JIRA_DEFECT: 84,
  UPDATE_JIRA_DEFECT: 85,
  VIEW_REPORT: 86,
  JIRA_DEFECT_SAVE: 89,
  JIRA_REQUIREMENT_SAVE: 90,
  // General Privileges
  MANAGE_KIBANA_REPORT: 88,
  REOPEN_TEST_CYCLE_STATUS: 97,
  REOPEN_DEFECT_STATUS: 98,
  //script should be written to clean the db privilege role_privilege tables
  BULK_OPERATION_TEST_CASE: 99,
  //User Privileges
  VIEW_USER: 100,
  ADD_USER: 101,
  DELETE_USER: 102,
  ASSIGN_ROLE: 103,
  //Role Privileges
  VIEW_ROLE: 104,
  CREATE_ROLE: 105,
  UPDATE_ROLE: 106,
  DELETE_ROLE: 107,
  ASSIGN_PRIVILEGE: 108,
  //User Group Privileges
  VIEW_USERGROUP: 109,
  CREATE_USERGROUP: 110,
  UPDATE_USERGROUP: 111,
  DELETE_USERGROUP: 112,
  ASSIGN_USER: 113
};

export const ACCEPTED_FILE_TYPES = {
  'image/*': ['.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'],
  'application/*': ['.docx', '.doc', '.pdf', '.xlsx', '.xls'],
  'video/*': ['.mp4', '.webm', '.ogg', '.ogv', '.avi', '.mov', '.wmv', '.flv'],
  'audio/*': ['.mp3', '.wav', '.ogg', '.oga', '.flac', '.m4a', '.aac'],
  'text/*': ['.txt']
};

export const paginationPageSizeDD = [
  { key: 1, name: 'Show 10', value: 10 },
  { key: 2, name: 'Show 20', value: 20 },
  { key: 5, name: 'Show 50', value: 50 },
  { key: 6, name: 'Show 100', value: 100 }
];

export const DATE_FORMAT = 'DD MMM YYYY HH:mm';

export const MENU_DEFAULT_TITLES = {
  dashboard: 'Dashboard',
  release: 'Release',
  requirement: 'Requirement',
  testCase: 'Test Cases',
  testCycle: 'Test Cycles',
  task: 'Tasks',
  defect: 'Defect',
  report: 'Reports'
};

{
  /* please do not apply translation to Subject field strings */
}
export const TEST_MAIL_SUBJECT = 'Testing SMTP Settings';
export const REPORT_MAIL_SUBJECT = ' Report';

export let userSearchTypesDropdown = [
  { key: 'fullName', value: 'fullName', text: 'Full Name', name: 'Full Name' },
  { key: 'userName', value: 'userName', text: 'Username', name: 'Username' },
  { key: 'email', value: 'email', text: 'Email', name: 'Email' }
];

export let ldapUserSearchTypesDropdown = [
  { key: 'department', value: 'department', text: 'Department', name: 'Department' },
  { key: 'email', value: 'email', text: 'Email', name: 'Email' },
  { key: 'userName', value: 'userName', text: 'Username', name: 'Username' },
  { key: 'fullName', value: 'fullName', text: 'Full Name', name: 'Full Name' }
];

export const PARAMETER_STATUS_DROPDOWN = [
  { key: 'active', value: 'ACTIVE', text: 'Active', name: 'Active' },
  { key: 'inactive', value: 'INACTIVE', text: 'Inactive', name: 'Inactive' },
  { key: 'archived', value: 'ARCHIVED', text: 'Archived', name: 'Archived' },
  { key: 'used', value: 'USED', text: 'Used', name: 'Used' }
];

export const USE_FORM_OPTIONS = {
  mode: 'onBlur',
  shouldFocusError: true,
  criteriaMode: 'all'
};

export const TEMPLATES_PROJECTS_SYNC_LIMIT = 5;

export const ONE_MB_IN_BYTES = 1048576;
