import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import KibanaReportsApi from '../../../api/KibanaReportsApi';
import { Input, TextArea, Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';

const EditReportModal = ({ isOpen, closeModal, projectId, selectedReport }) => {
  const { control, handleSubmit, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();

  const editReport = useApi({
    name: 'Creating report',
    api: KibanaReportsApi.edit,
    onSuccess: () => {
      closeModal();
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (data) => {
    const body = { id: selectedReport.id, projectId, name: data.name, url: { url: data.reportUrl } };
    editReport.execute(body);
  };

  useEffect(() => {
    setValue('name', selectedReport?.name);
    setValue('reportUrl', selectedReport?.url.url);
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        close={closeModal}
        w={'md'}
      >
        <Modal.Title>{t('Edit Report')}</Modal.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <Input
                control={control}
                required
                label={t('Name')}
                name="name"
              />
              <TextArea
                required
                placeholder={t('Enter your text here')}
                control={control}
                name="reportUrl"
                label={t('Report Url')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="editReportModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={closeModal}
            />
            <Button
              id="editReportModal-submit-button"
              primary
              text={t('Submit')}
              loading={editReport?.loading}
              type="submit"
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default EditReportModal;
