import {axios} from "./ApiClient";

class RoleApi {

    async getProjectRoleList(data) {
        const resp = await axios.get(`/api/roles`, { params: data});
        return resp.data;
    }

    async create(data) {
        const resp = await axios.post(`/api/roles`, data);
        return resp.data;
    }

    async edit(data) {
        const resp = await axios.put(`/api/roles/${data.id}`, data);
        return resp.data;
    }

    async delete(data) {
        const resp = await axios.delete(`/api/roles/${data.id}`);
        return resp.data;
    }

    async addRolePrivilege(data) {
        const resp = await axios.put(`/api/roles/${data.id}/assign-privilege`, data.value);
        return resp.data;
    }

    async deleteRolePrivilege(data) {
        const resp = await axios.put(`/api/roles/${data.id}/unassign-privilege`,  data.value);
        return resp.data;
    }

}

export default new RoleApi();