import { axios } from "./ApiClient";
import { BASE_URL } from "./CommonApiHelper";

export const SUBSCRIBE_PATH = BASE_URL + "subscribe";

class SubscribeApi {
    async getSubscribe(data) {
        const resp = await axios.get(`${SUBSCRIBE_PATH}`, { params: data });
        return resp.data;
    }

    async subscribeUser(data) {
        const resp = await axios.post(`${SUBSCRIBE_PATH}`, data);
        return resp.data;
    }

    async unsubscribeUser(data) {
        const resp = await axios.delete(`${SUBSCRIBE_PATH}/${data.id}`);
        return resp.data;
    }

    async getAllSubscribeForUser(params) {
        const resp = await axios.get(`${SUBSCRIBE_PATH}/user`, { params });
        return resp.data;
    }

}

export default new SubscribeApi();