import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TestCaseApi from '../../api/TestCaseApi';
import { ENTITY_TYPES } from '../../constants';
import { getArtifactName } from '../../helpers/ArtifactNameHelper';
// Helper Import
import { getVersionText, isValidValue } from '../../helpers/Helper';
// Api Import
import { useApi } from '../../hooks/UseApi';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import { Dropdown } from '../../designSystem/DesignSystem';
// Design System Import
import MenuDropdown from '../menu/MenuDropdown';

const VersionDropdown = (props) => {
  const { data, submitCallBack, setExistDraft, name, disabled, entityType } = props;

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const { control } = useForm({ mode: 'onChange' });

  let [versions, setVersions] = useState([]);

  const getVersion = useApi({
    name: 'get testcase versions',
    api: TestCaseApi.getTestCaseVersion,
    autoLoad: false,
    onSuccess: (form) => {
      let optionList = [];
      optionList = form.map((item) => {
        if (isValidValue(setExistDraft)) {
          setExistDraft(item.approveType === 'DRAFT' ? true : false);
        }
        return {
          key: item.majorVersion,
          value: item.majorVersion,
          majorVersion: item.majorVersion,
          approveType: item.approveType,
          text: getVersionText(item),
          name: getVersionText(item)
        };
      });
      var sortedObjs = _.sortBy(optionList, 'majorVersion');
      setVersions(sortedObjs);
    }
  });

  useEffect(() => {
    if (isValidValue(data?.rootId && !getVersion?.loading)) {
      getVersion.execute({ id: data?.rootId });
    }
  }, [data]);

  return (
    <>
      <form className="flex">
        {entityType === ENTITY_TYPES.TEST_RUN ? (
          <Dropdown
            label={`${getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE)} Version`}
            control={control}
            disabled={disabled}
            options={versions}
            passValue={(value) => submitCallBack(value[0])}
            selectedItem={(versions || []).find((item) => item.value === data?.majorVersion)}
            name={name}
          />
        ) : (
          <MenuDropdown
            label={getVersionText({ majorVersion: data?.majorVersion, approveType: data?.approveType })}
            control={control}
            options={versions}
            passValue={(value) => submitCallBack({ ...value, versionChanged: true })}
            name={name}
          />
        )}
      </form>
    </>
  );
};

export default VersionDropdown;
