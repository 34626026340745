import React, { useEffect, useMemo, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import ReleaseApi from '../../api/ReleaseApi';
import Table from '../../components/table/Table';
import { TableColumns } from '../../components/table/TableColumns';
import { ENTITY_TYPES } from '../../constants';
import { getPageFilterInput } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import {
  DEFAULT_PAGE_PARAMS,
  disableResizingForColumns,
  getNotResizedColumnList,
  translateColumns
} from '../../helpers/TableHelper';
import { useApi } from '../../hooks/UseApi';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseTableColumns from '../../hooks/UseTableColumns';
import UseTableHelper from '../../hooks/UseTableHelper';
import UseUserTablePreferences from '../../hooks/UseUserTablePreferences';

const ReleaseReactTable = (props) => {
  const { filter, setRefreshStatus, refreshStatus, users, height } = props;
  const { projectId } = UseQueryProject('projectId');

  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('name', 'ASC'));
  const [customFilters, setCustomFilters] = useState({});
  const { t } = useTranslation();
  const { createTableData } = UseTableHelper();
  const [rerenderKey, setRerenderKey] = useState(0);

  const dataLoader = useApi({
    name: 'fetching releases',
    api: ReleaseApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
      setRefreshStatus(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    getItems(pageInput, customFilters);
  };
  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.RELEASE);

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) {
      fil = { ...fil, ...userSavedFilters?.filterInput };
    }
    let body = { ...fil, ...getPageFilterInput(pageInput), projectId };
    dataLoader.execute(body);
  };

  const refreshTable = () => getByPage({});
  const loading = useMemo(() => dataLoader?.loading, [dataLoader?.loading]);

  useEffect(() => {
    if (refreshStatus === true) {
      refreshTable();
      setRerenderKey((prevKey) => prevKey + 1);
    }
  }, [refreshStatus]);

  const tData = useMemo(() => {
    let data = [];
    if (dataLoader?.data?.content) {
      const columnsConfig = TableColumns[ENTITY_TYPES.RELEASE];
      data = dataLoader?.data?.content.map((item) =>
        createTableData(item, columnsConfig, projectId, false, ENTITY_TYPES.RELEASE, users)
      );
    }
    return data;
  }, [dataLoader?.data?.content, getI18n().language]);

  let columns;
  const { selectedColumns } = UseTableColumns(projectId, ENTITY_TYPES.RELEASE);
  columns = disableResizingForColumns(selectedColumns, getNotResizedColumnList(ENTITY_TYPES.RELEASE));
  columns = translateColumns(columns, t);

  return (
    <Table
      key={rerenderKey}
      columns={columns}
      data={tData}
      pagination
      loading={loading}
      pageParams={pageParams}
      getByPage={getByPage}
      onFilter={onFilter}
      entityType={ENTITY_TYPES.RELEASE}
      submitCallBack={() => refreshTable()}
      height={height}
    />
  );
};
export default ReleaseReactTable;
