import { useState } from 'react';
import RequirementApi from '../../../api/RequirementApi';
import { Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import TestCaseSearchTree from '../../../components/treeView/TestCaseSearchTree';
import { useApi } from '../../../hooks/UseApi';

import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';
import { ENTITY_TYPES } from '../../../constants';

const RequirementAddTestCasesModal = (props) => {
  const { isOpen, closeModal, entityId, submitCallBack } = props;

  const [selectedTestCases, setSelectedTestCases] = useState([]);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const { t } = useTranslation();

  const assign = useApi({
    name: 'Assigning Test Case',
    api: RequirementApi.assignTestcase,
    onSuccess: () => {
      if (submitCallBack) submitCallBack();
      closeModal();
      showSuccessMes(`Added ${getTestCaseName(projectConfig)} successfully.`);
    },
    onError: (err) => showErrorMes(err)
  });

  const addTestCase = () => {
    const body = {
      testCaseIds: selectedTestCases,
      containerIds: selectedContainers,
      id: entityId
    };
    assign.execute(body);
  };

  return (
    <>
      <>
        <Modal
          open={isOpen}
          close={() => closeModal}
          w={'md'}
        >
          <Modal.Title>{t('add_entity', { entity: getTestCaseName(projectConfig) })}</Modal.Title>
          <Modal.Content>
            <TestCaseSearchTree
              type={ENTITY_TYPES.REQUIREMENT}
              setSelectedTestCases={setSelectedTestCases}
              setSelectedContainers={setSelectedContainers}
            />
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="requirementAddTestCasesModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              id="requirementAddTestCasesModal-submit-button"
              primary
              text={t('Submit')}
              onClick={() => addTestCase()}
              loading={assign?.loading}
            />
          </Modal.Buttons>
        </Modal>
      </>
    </>
  );
};

export default RequirementAddTestCasesModal;
