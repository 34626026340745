import { CaretDown } from 'phosphor-react';
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Heading } from '../../../designSystem/DesignSystem';
import { useTranslation } from 'react-i18next';

const SettingsMenuItem2 = ({ key, item, projectId, active, setActive }) => {
  const [isSubNav2Open, setIsSubNav2Open] = useState('system-fields');

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeSubNav = query.get('activeSubNav');
  const { t } = useTranslation();

  useEffect(() => {
    let path = location.pathname.split('/');

    if (path) {
      if (path.length > 1) {
        setActive(path[2]);
        if (path.length > 2) {
          setIsSubNav2Open(path[3]);
        }
      }
    }

    // setIsSubNav2Open(path === item.path)
  }, []);

  const isActive = (param) => activeSubNav === param;
  const handleClick = (e, path) => {
    if (path === active) {
      e.preventDefault();
    }
  };
  return (
    <>
      <NavLink
        key={key}
        onClick={(e) => handleClick(e, item.path)}
        to={`/settings/${item.path}?projectId=${projectId}`}
        className={({ isActive }) =>
          'flex cursor-pointer flex-col py-2 pl-8 text-lg ' +
          (!isActive
            ? 'py-2 text-gray-700'
            : ' border-r-4 border-primary-500 bg-primary-100 font-semibold text-primary-500')
        }
      >
        <div
          className="flex items-center gap-4 pb-1"
          onClick={() => {
            setIsSubNav2Open('');
            setActive(item.path === active ? ' ' : item.path);
          }}
        >
          <Heading
            type="base"
            color={({ isActive }) => (isActive ? 'text-primary-500' : 'text-gray-700')}
            text={t(item.name)}
          />
          {item.subNav && (
            <div className={({ isActive }) => (!isActive ? 'text-gray-700' : 'text-primary-700')}>
              <CaretDown
                size="16"
                weight="bold"
              />
            </div>
          )}
        </div>
        {item.subNav &&
          active === item.path &&
          item.subNav.map((subItem, index) => {
            return (
              <NavLink
                key={index}
                to={`/settings/${item.path}/${subItem.path}?projectId=${projectId}`}
                className={({ isActive }) =>
                  `flex cursor-pointer flex-col font-normal ${
                    subItem.subNav ? 'text-base' : 'text-sm'
                  } py-0.5 text-gray-500 ` + (isActive && 'font-semibold text-primary-500')
                }
              >
                <div
                  className="flex items-center gap-2 "
                  onClick={() => {
                    setIsSubNav2Open(subItem.path === isSubNav2Open ? ' ' : subItem.path);
                  }}
                >
                  <div className="ml-3">
                    <Heading
                      type="base"
                      color={({ isActive }) => (isActive ? 'text-blue-700' : 'text-gray-700')}
                      text={t(subItem.name)}
                    />
                  </div>
                  {subItem.subNav && (
                    <div className={({ isActive }) => (!isActive ? 'text-gray-700' : 'text-primary-700')}>
                      <CaretDown
                        size="12"
                        weight="bold"
                      />
                    </div>
                  )}
                </div>
                <div className="pt-1">
                  {subItem.subNav &&
                    isSubNav2Open === subItem.path &&
                    subItem.subNav.map((subItem2, index) => {
                      return (
                        <NavLink
                          key={index}
                          to={`/settings/${item.path}/${subItem.path}?projectId=${projectId}&activeSubNav=${subItem2.type}`}
                          className={
                            `flex cursor-pointer flex-col py-1 text-sm font-normal text-gray-700 ` +
                            (isActive(subItem2.type) && 'font-semibold')
                          }
                        >
                          <div className="ml-6">
                            <Heading
                              type="base"
                              color={isActive(subItem2.type) ? 'text-primary-700' : 'text-gray-700'}
                              text={subItem2.name}
                            />
                          </div>
                        </NavLink>
                      );
                    })}
                </div>
              </NavLink>
            );
          })}
      </NavLink>
    </>
  );
};

export default SettingsMenuItem2;
