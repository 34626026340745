// DESIGN SYSTEM IMPORTS
import React, { useEffect, useMemo, useState } from 'react';
import TestRunApi from '../../../api/TestRunApi';

// COMPONENT IMPORTS
import { Link } from 'react-router-dom';
import Table from '../../../components/table/Table';
import { StatusPill } from '../../../designSystem/DesignSystem';

// HOOK IMPORTS
import { useApi } from '../../../hooks/UseApi';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseTableHelper from '../../../hooks/UseTableHelper';
import UseSystemField from '../../../hooks/UseSystemField';
import UseUserTablePreferences from '../../../hooks/UseUserTablePreferences';

// HELPERS & CONSTANTS IMPORTS
import { ENTITY_TYPES } from '../../../constants';
import { getPageFilterInput, getUserName } from '../../../helpers/Helper';
import {
  DEFAULT_PAGE_PARAMS,
  disableSortingForColumns,
  disableResizingForColumns,
  generateTableColumns,
  getNotResizedColumnList,
} from '../../../helpers/TableHelper';


const TestCaseRunHistoryReactTable = (props) => {
  const {
    filter,
    refreshStatus,
    setRefreshStatus,
    tableHeight
  } = props;

  const { projectId } = UseQueryProject('projectId');
  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('code', 'ASC'));
  const [customFilters, setCustomFilters] = useState({});
  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.TEST_RUN);
  const { openModal } = UseTableHelper();
  const [rerenderKey, setRerenderKey] = useState(0);

  UseSystemField(projectId, ENTITY_TYPES.TEST_RUN, 'status');

  // <-- API SETUP ->
  const dataLoader = useApi({
    name: 'fetching test runs',
    api: TestRunApi.getAllByTester,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
      setRefreshStatus(true);
    }
  });

  const convertToTableData = (item) => ({
    id: item?.id,
    testRun: (
      <Link
        className={'elipsis text-blue-900'}
        onClick={(e) => {
          e.preventDefault();
          openModal('runId', item.id);
        }}
        to="/"
      >
        {item?.code + ' ' + item.summary}
      </Link>
    ),
    tester: getUserName(item?.tester),
    executions: item?.executionCount,
    defects: item?.defectCount,
    status: <StatusPill value={item?.lastTestRunExecution?.status} />
  });

  const { tableData, tableColumns, loading } = useMemo(() => {
    let data = [];
    let columns = [];

    if (dataLoader?.data?.content) {
      data = dataLoader?.data?.content.map(convertToTableData);
      columns = generateTableColumns(data, null, ['id'], false);
      columns = disableSortingForColumns(columns, ['testRun', 'tester', 'executions', 'defects', 'status']);
      columns = disableResizingForColumns(columns, getNotResizedColumnList(ENTITY_TYPES.TEST_RUN));
    }

    return {
      tableData: data,
      tableColumns: columns,
      loading: dataLoader?.loading
    };
  }, [dataLoader?.data?.content]);

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    getItems(pageInput, customFilters);
  };

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) {
      fil = { ...fil, ...userSavedFilters?.filterInput };
    }

    let body = { ...fil, ...getPageFilterInput(pageInput), projectId };
    dataLoader.execute(body);
  };

  const refreshTable = () => getByPage({});

  useEffect(() => {
    refreshTable();
    setRerenderKey((prevKey) => prevKey + 1);
  }, [refreshStatus]);

  return (
    <>
      <Table
        key={rerenderKey}
        columns={tableColumns}
        data={tableData}
        pagination
        loading={loading}
        pageParams={pageParams}
        getByPage={getByPage}
        onFilter={onFilter}
        entityType={ENTITY_TYPES.TEST_RUN}
        height={tableHeight}
      />
    </>
  );
};

export default TestCaseRunHistoryReactTable;
