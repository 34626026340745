import React, { useEffect, useRef, useState } from 'react';
import { useApi } from '../../hooks/UseApi';
import RequirementApi from '../../api/RequirementApi';
import JiraEntityApi from '../../api/JiraEntityApi';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseCustomField from '../../hooks/UseCustomField';
import UseJiraConfig from '../../hooks/UseJiraConfig';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import CustomEntityDetail from '../../components/detail/CustomEntityDetail';
import { isTrue, isValidValue } from '../../helpers/Helper';
import { ENTITY_TYPES, PRIVILEGES, TCASE_VERSION_FILTER_TYPES } from '../../constants';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { hasPrivilege } from '../../helpers/PriviligeHelper';

const RequirementDetails = (props) => {
  const { selectedItem, submitCallBack, submitCallBackAfterDeletion, submitCallBackAfterClone } = props;
  const { projectId } = UseQueryProject('projectId');
  const customFields = UseCustomField(projectId, ENTITY_TYPES.REQUIREMENT);
  const jiraConfig = UseJiraConfig(projectId);
  const users = UseProjectUsers(projectId);
  const projectConfig = UseProjectConfig(projectId);
  const ref = useRef();

  const [dataForm, setDataForm] = useState(null);
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(null);
  const [refreshStatus, setRefreshStatus] = useState(false);

  const dataLoader = useApi({
    name: 'fetching requirement item',
    api: RequirementApi.get,
    autoLoad: false,
    onSuccess: () => {
      setDataForm(dataLoader?.data);
      setLoading(false);
    },
    onError: (err) => {
      showErrorMes(err);
      setLoading(false);
    }
  });

  const dataLoaderWithJira = useApi({
    name: 'fetching requirement item with Jira',
    api: JiraEntityApi.fetchRequirementJira,
    autoLoad: false,
    onSuccess: () => {
      setDataForm(dataLoaderWithJira?.data);
      setLoading(false);
    },
    onError: (err) => {
      showErrorMes(err);
      setLoading(false);
    }
  });

  const refreshData = (form) => {
    if (isValidValue(form)) {
      setDataForm(form);
      let title =
        isTrue(projectConfig?.configuration?.jira?.requirementTreeShowJiraIssueId) && form?.jiraKey
          ? form?.jiraKey + ' - ' + form?.name
          : form?.name;
      submitCallBack({ ...form, name: title });
      setRefreshStatus(true);
    } else if (isValidValue(selectedItem) && !loading) {
      setLoading(true);
      if (
        hasPrivilege(projectId, PRIVILEGES.VIEW_JIRA_REQUIREMENT) &&
        jiraConfig &&
        jiraConfig.activeRequirement === true
      )
        dataLoaderWithJira.execute({ id: selectedItem.id, jiraConfigId: jiraConfig.id });
      else {
        dataLoader.execute({ id: selectedItem?.id });
      }
    }
  };

  const afterDelete = (data) => {
    if (isValidValue(data)) {
      submitCallBackAfterDeletion(data);
    }
  };

  useEffect(() => {
    setDataForm(null);
    refreshData();
    setFilter({
      requirementId: selectedItem?.id,
      versionFilter: TCASE_VERSION_FILTER_TYPES.LAST_VERSION
    });
  }, [selectedItem?.id]);

  return (
    <>
      <CustomEntityDetail
        ref={ref}
        config={{
          entityId: selectedItem?.id,
          entityType: ENTITY_TYPES.REQUIREMENT,
          users,
          refreshStatus,
          setRefreshStatus,
          loading: loading,
          dataForm: dataForm,
          selectedItem,
          submitCallBack: refreshData,
          submitCallBackAfterDeletion: afterDelete,
          submitCallBackAfterClone,
          projectId,
          customFields,
          filter
        }}
      />
    </>
  );
};

export default RequirementDetails;
