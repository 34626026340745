import React, { useEffect, useState } from 'react';
// API & HOOKS IMPORTS
import { useApi } from '../../hooks/UseApi';
import HistoryApi from '../../api/HistoryApi';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseProjectConfig from '../../hooks/UseProjectConfig';
// DESIGN SYSTEM & COMPONENTS
import { UserCircle } from 'phosphor-react';
import HistoryModal from './HistoryModal';
import { Body, Heading } from '../../designSystem/DesignSystem';
// HELPER & CONSTANTS
import { getUserName, translatedDateFormat } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { getHistoryEventAction } from './HistoryHelper';
import { useTranslation } from 'react-i18next';

export const CARD_HEADING_SIZE = 'small';
export const CARD_DATE_SIZE = 'small';
export const CARD_BODY_SIZE = 'medium';
export const CARD_USER_ICON_SIZE = '24';

const HistoryDetails = (props) => {
  const { entityId, entityType, historyChanged, setHistoryChange, refreshStatus, setRefreshStatus } = props;

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);

  const [histories, setHistories] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);

  const { t } = useTranslation();

  const dataLoader = useApi({
    name: 'get history',
    api: HistoryApi.get,
    autoLoad: false,
    onSuccess: () => setHistories(dataLoader.data),
    onError: (err) => showErrorMes(err)
  });

  const getHistories = () => {
    if (!dataLoader.loading) dataLoader.execute({ projectId, entityId, entityType });
  };

  useEffect(() => {
    getHistories();
  }, [entityId, entityType]);

  useEffect(() => {
    if (refreshStatus === true) {
      getHistories();
      if (setRefreshStatus) setRefreshStatus(false);
    }
  }, [refreshStatus]);

  useEffect(() => {
    if (historyChanged === true) {
      getHistories();
      if (setHistoryChange) setHistoryChange(false);
    }
  }, [historyChanged]);

  return (
    <div>
      {/* <div>     
        <div className="flex gap-4" style= {{margin:20}}>
          <Button
            // text="Refresh"
            icon="ArrowsClockwise"
            secondary
            size="small"
            weight="fill"
            onClick={() => getHistories()}
          />
        </div>
      </div> */}
      <div className="h-160 overflow-y-auto">
        {histories.map((elem) => {
          return (
            <>
              <div
                className="mt-3 flex cursor-pointer items-center gap-5 rounded-md
                border border-gray-200 bg-gray-50 p-2"
                onClick={() => {
                  setSelectedHistory(elem);
                  setModalVisibility(true);
                }}
              >
                <UserCircle
                  weight="duotone"
                  size={CARD_USER_ICON_SIZE}
                  color={'#7171beeb'}
                />
                <div className="flex flex-col gap-1">
                  <Heading
                    type={CARD_HEADING_SIZE}
                    text={`${getUserName(elem?.user)}`}
                  />

                  <Body
                    type={CARD_DATE_SIZE}
                    text={translatedDateFormat(elem?.modifiedTime, 'DD MMM YYYY HH:mm', t)}
                  />
                  <Body
                    type={CARD_BODY_SIZE}
                    text={t(getHistoryEventAction(elem?.historyEvent, projectConfig))}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
      {modalVisibility === true && (
        <HistoryModal
          submitCallback={getHistories}
          history={selectedHistory}
          isOpen={modalVisibility}
          closeModal={() => setModalVisibility(false)}
        />
      )}
    </div>
  );
};

export default HistoryDetails;
