
import { isValidList } from '../../../helpers/Helper';

import moment from "moment";

export const getCirclePackingChartData = (buckets, chart) => {
  const fieldCount = isValidList(chart?.configuration?.fields) ? chart?.configuration?.fields.length : 0;
  let histogram = chart?.configuration?.dateHistogram === true;
    if(fieldCount === 2 || histogram){
      return {
        label:  isValidList(chart?.configuration?.fields)
        ? chart?.configuration?.fields[0].fieldKey
        : "-",
         children: getChildrenForSecondField(buckets, "1",  histogram)
      };

    }
    else if(fieldCount === 3){
      return {
        label:  isValidList(chart?.configuration?.fields)
        ? chart?.configuration?.fields[0].fieldKey
        : "-",
         children: getChildrenForThirdField(buckets, "2")
      };
    }
    else{
      return {
        label:  isValidList(chart?.configuration?.fields)
        ? chart?.configuration?.fields[0].fieldKey
        : "-",
         children:  getChildren(buckets, false)
      };
    }
  
  };
  

  const getChildren = (buckets, histogram) =>{
    let arr = [];
    if(isValidList(buckets)){
        buckets.map((x) => {
          if (x?.key && x?.doc_count)
            arr.push({        
              label:  histogram
              ? moment(x?.key).format("DD MMM YYYY")
              : x?.key,
              count: x?.doc_count,
              children: []
            
            });
        });
    }
    return arr;
  }


  const getChildrenForSecondField = (buckets, indexStr, histogram) =>{
    
    let arr = [];
    if(isValidList(buckets)){
      buckets.map((x) => {
      if (x?.key && x?.doc_count)
        arr.push({        
          label: x?.key,
          children: getChildren(x?.[indexStr]?.["buckets"],  histogram)
        
        });
    });
  }
    return arr;
  }

  const getChildrenForThirdField = (buckets, indexStr) =>{
    let arr = [];
    if(isValidList(buckets)){
        buckets.map((x) => {
          if (x?.key && x?.doc_count)
            arr.push({        
              label: x?.key,
              children: getChildrenForSecondField(x?.["1"]?.["buckets"], indexStr, false)
            
            });
        });
      }
    return arr;
  }