import {axios} from "./ApiClient";

class UserGroupApi {

    async getUserGroups(data) {
        const resp = await axios.get(`/api/user-groups`,{ params: data});
        return resp.data;
    }

    async getUserGroupOptions(data) {
        const resp = await axios.get(`/api/user-groups/options`,{ params: data});
        return resp.data;
    }

    async create(data) {
        const resp = await axios.post(`/api/user-groups`, data);
        return resp.data;
    }

    async edit(data) {
        const resp = await axios.put(`/api/user-groups/${data.id}`, data);
        return resp.data;
    }

    async delete(data) {
        const resp = await axios.delete(`/api/user-groups/${data.id}`);
        return resp.data;
    }

    async addToUserGroup(data) {
        const resp = await axios.put(`/api/user-groups/assign/${data.id}`, data);
        return resp.data;
    }

    async removeFromUserGroup(data) {
        const resp = await axios.put(`/api/user-groups/unassign/${data.id}`, data);
        return resp.data;
    }
  
}

export default new UserGroupApi();