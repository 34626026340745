import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/themes/light.css';
// API IMPORTS
import ProjectApi from '../../api/ProjectApi';
// COMPONENT IMPORTS
import ConfirmationModal from '../../components/confirm/ConfirmationModal';
import ProjectCard from '../../components/card/ProjectCard';
import CreateEditProjectModal from './Modals/CreateEditProjectModal';
import ProjectDetailModal from './Modals/ProjectDetailModal';

// DESIGN SYSTEM IMPORTS
import { FullPageContainer, FullPageContent } from '../../components/Layout';
import { Heading, Button } from '../../designSystem/DesignSystem';
// HELPER & CONSTANT IMPORTS
import { classNames, isValidValue, applyFilter } from '../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { isSystemAdmin } from '../../helpers/PriviligeHelper';
import { setProjectToken } from '../../helpers/ProjectTokenHelper';
// HOOK && CONTEXT IMPORTS+
import { useApi } from '../../hooks/UseApi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../context/AppContext';
import { saveProject, saveProjectConfig } from '../../context/ProjectActions';
import ProjectListPageSearch from './ProjectListPageSearch';
import { useTranslation } from 'react-i18next';
import SelectedTableFilters from '../../components/filtering/SelectedTableFilters';
import ProjectReactTable from './ProjectReactTable';
import { ENTITY_TYPES } from '../../constants';
import { useRef } from 'react';
import UseAllUsers from '../../hooks/UseAllUsers';
import { ListDashes, SquaresFour } from 'phosphor-react';
import { openProject } from './ProjectHelper';

const ProjectListPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = UseAllUsers();

  const ref = useRef();
  const tableHeight = ref?.current?.clientHeight - ref?.current?.childNodes[0]?.clientHeight + 300;

  const { control, reset, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [filters, setFilters] = useState({ name: '', prefix: '', isTemplate: false });
  const [dataForm, setDataForm] = useState(null);
  const [projects, setProjects] = useState(null);
  const [deletedProject, setdeletedProject] = useState(null);
  const { t } = useTranslation();

  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [allFilters, setAllFilters] = useState({});

  const [tableView, setTableView] = useState(() => {
    const storedValue = localStorage.getItem('tableView');
    return storedValue ? JSON.parse(storedValue) : false;
  });

  // <-- API SET UP START -->;
  const projectLoader = useApi({
    name: 'fetching projects',
    api: ProjectApi.getAll,
    autoLoad: false,
    initialValue: [],
    params: null,
    onSuccess: () => {
      setProjects(projectLoader?.data);
      dispatch(saveProjectConfig(null, null));
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteProject = useApi({
    name: 'Deleting project',
    api: ProjectApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      refreshData();
      showSuccessMes(INFO_MESSAGES.delete);
      setdeletedProject(null);
      setIsDeleteModalOpen(false);
    },
    onError: (err) => showErrorMes(err)
  });
  // <-- ## -->
  // <-- FN -->

  const refreshData = () => projectLoader.execute(filters);

  // <-- MODAL FN -->
  const openEditModal = (item) => {
    setIsUpdate(true);
    setDataForm(item);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsUpdate(false);
    setIsOpen(false);
    reset();
    setDataForm(null);
    refreshData();
  };

  const openViewModal = (item) => {
    setIsUpdate(false);
    setDataForm(item);
    setViewModal(true);
  };

  // <-- HANDLE EVENT FN -->
  const handleDeleteClick = (item) => {
    setdeletedProject(item);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteProject = () => deleteProject.execute(deletedProject);

  const debounceSearch = useCallback(
    debounce(() => projectLoader.execute(filters), 200),
    [filters]
  );
  // <-- HANDLE EVENT FN END --> //

  // <-- EFFECT -->
  useEffect(() => {
    localStorage.setItem('project', '');
    projectLoader.execute();
    dispatch(saveProject(null));
  }, []);

  useEffect(() => {
    if (isValidValue(filters)) debounceSearch();
    else setProjects([]);
    return debounceSearch.cancel;
  }, [filters, debounceSearch]);

  useEffect(() => {
    localStorage.setItem('tableView', JSON.stringify(tableView));
  }, [tableView]);

  return (
    <>
      <FullPageContainer>
        <Heading.Group
          type="large"
          text={t('Projects')}
          className="mr-3"
        >
          <div className=" grid grid-cols-none gap-4">
            <div className="col-start-1 col-end-5 mt-1">
              <Button
                text={tableView == false ? <ListDashes size={28} /> : <SquaresFour size={28} />}
                size="xsmall"
                secondary2
                weight="bold"
                tooltip={tableView == false ? t('List View') : t('Card View')}
                name={'tableView'}
                onClick={() => {
                  setTableView((prevValue) => !prevValue);
                }}
              />
            </div>
            {isSystemAdmin() && (
              <div className="col-span-2 col-end-7">
                <Button
                  id="project-add-button"
                  primary
                  text={t('New Project')}
                  icon="Plus"
                  onClick={() => {
                    setDataForm(null);
                    setIsUpdate(false);
                    setIsOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        </Heading.Group>
        {tableView ? (
          <div className="overflow-scroll-y">
            <SelectedTableFilters
              allFilters={{ ...allFilters, isTemplate: false }}
              setAllFilters={setAllFilters}
              entityType={ENTITY_TYPES.PROJECT}
              setRefreshTable={setRefreshTable}
              refreshTable={refreshTable}
              showSaveFilter={false}
            />
            <ProjectReactTable
              filter={{ ...allFilters, isTemplate: false }}
              refreshStatus={refreshTable}
              setRefreshStatus={setRefreshTable}
              users={users}
              height={tableHeight}
              showCheckbox={false}
              isTemplate={false}
            />
          </div>
        ) : (
          <>
            <ProjectListPageSearch
              control={control}
              setValue={(type, value) => setValue(type, value)}
              applyFilter={(filter, value) => applyFilter(filter, value, filters, setFilters)}
            />
            <FullPageContent>
              <div className="h-full overflow-y-auto">
                <div
                  className={classNames(
                    'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
                    'w-full gap-5'
                  )}
                >
                  {projects?.map((item, index) => {
                    return (
                      !item.isTemplate && (
                        <ProjectCard
                          index={index}
                          item={item}
                          openProject={(item) => openProject(item, setProjectToken, dispatch, navigate, saveProject)}
                          openViewModal={(item) => openViewModal(item)}
                          openEditModal={(item) => openEditModal(item)}
                          setIsUpdate={(item) => setIsUpdate(item)}
                          handleDeleteClick={(item) => handleDeleteClick(item)}
                        />
                      )
                    );
                  })}
                </div>
              </div>
            </FullPageContent>
          </>
        )}
        {/* <-- MODALS START --> */}
        {isOpen && (
          <CreateEditProjectModal
            isOpen={isOpen}
            setDataForm={setDataForm}
            dataForm={dataForm}
            closeModal={() => closeModal()}
            isUpdate={isUpdate}
            isTemplate={false}
          />
        )}
        {viewModal && (
          <ProjectDetailModal
            project={dataForm}
            isOpen={viewModal}
            projectId={dataForm?.id}
            closeModal={() => setViewModal(false)}
          />
        )}
        {isDeleteModalOpen && (
          <ConfirmationModal
            isOpen={isDeleteModalOpen}
            loading={deleteProject?.loading}
            closeModal={() => setIsDeleteModalOpen(false)}
            handleConfirm={handleDeleteProject}
            header={t('Confirm Delete')}
            content={t('Do you really want to delete project?')}
          />
        )}
        {/* <-- MODALS END --> */}
      </FullPageContainer>
    </>
  );
};

export default ProjectListPage;
