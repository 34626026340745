import React from 'react';
import {
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd,
} from 'react-flow-renderer';
import SystemFieldApi from '../../api/SystemFieldApi';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useApi } from '../../hooks/UseApi';
import './workFlow.css';

const foreignObjectSize = 40;

export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
}) {

    const unAssignNextNode = useApi({
        name:"Unassigning next node",
        api: SystemFieldApi.unAssignNextNode,
        onSuccess: () =>{     
            data.reload();
        },
        onError:(err) => showErrorMes(err)
    })

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const onEdgeClick = (evt, id) => {
        evt.stopPropagation();
        let arrayOfId = id.split("//")
        let fromId = arrayOfId[0]
        let toId = arrayOfId[1]
        unAssignNextNode.execute({ id:fromId, nextStatus:toId})
    };

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <body>
                    <button
                        className="edgebutton"
                        onClick={(event) => onEdgeClick(event, id)}
                    >
                        ×
          </button>
                </body>
            </foreignObject>
        </>
    );
}