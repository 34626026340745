import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const EXPORT_PATH = 'export';
export const PROJECT_PATH = BASE_URL + 'projects';
export const REQUIREMENT_PATH = BASE_URL + 'requirements';
export const TESTCASE_PATH = BASE_URL + 'test-cases';
export const DEFECT_PATH = BASE_URL + 'defects';
export const TRUN_PATH = BASE_URL + 'test-runs';

class ExportFileApi {
  async getExportTemplate(type) {
    const resp = await axios.get(`${BASE_URL}file-template/export/${type}/fields`);
    return resp.data;
  }

  async getExportTemplateWithoutCustomFields(type) {
    const resp = await axios.get(`${BASE_URL}file-template/export/${type}/fields/without-custom-fields`);
    return resp.data;
  }

  async exportRequirements(data) {
    const resp = await axios.put(`${REQUIREMENT_PATH}/${EXPORT_PATH}/`, data?.selectedResults, {
      params: { projectId: data.projectId, ...data.filters },
      responseType: 'arraybuffer'
    });
    return resp.data;
  }

  async exportTestCases(data) {
    const resp = await axios.put(`${TESTCASE_PATH}/${EXPORT_PATH}/`, data?.selectedResults, {
      params: { projectId: data.projectId, ...data.filters },
      responseType: 'arraybuffer'
    });
    return resp.data;
  }

  async exportDefects(data) {
    const resp = await axios.put(`${DEFECT_PATH}/${EXPORT_PATH}/`, data?.selectedResults, {
      params: { projectId: data.projectId, ...data.filters },
      responseType: 'arraybuffer'
    });
    return resp.data;
  }
  async exportTestRun(data) {
    const resp = await axios.put(`${TRUN_PATH}/${EXPORT_PATH}/`, data?.selectedResults, {
      params: { projectId: data.projectId, ...data.filters },
      responseType: 'arraybuffer'
    });
    return resp.data;
  }
  async exportProjects(data) {
    const resp = await axios.put(`${PROJECT_PATH}/${EXPORT_PATH}`, data?.selectedResults, {
      params: { ...data.filters },
      responseType: 'arraybuffer'
    });
    return resp.data;
  }
}

export default new ExportFileApi();
