import { useState, useEffect } from 'react';
// Context Imports
import { useAppDispatch, useApp } from '../context/AppContext';
import { saveEntitySystemFieldParams } from '../context/ProjectActions';
// Api Imports
import { useApi } from './UseApi';
import SystemFieldApi from '../api/SystemFieldApi';
import { showErrorMes } from '../helpers/NotificationHelper';

/**
 * Stores SystemFieldParams to AppContext
 * @param projectId
 * @param entity
 * @returns [ {name:string, required:boolean},..]
 *
 */
const UseSystemFieldParams = (projectId, entity) => {
  const app = useApp();
  const dispatch = useAppDispatch();
  const systemFieldParams = app?.systemFieldParams[entity];
  let [values, setValues] = useState([]);

  const entitySystemFieldParamLoader = useApi({
    name: 'fetching entity system field',
    api: SystemFieldApi.fetchSystemFields,
    onSuccess: () => {
      setValues(entitySystemFieldParamLoader?.data);
      dispatch(saveEntitySystemFieldParams(projectId, entity, entitySystemFieldParamLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (projectId && entity) {
      if (systemFieldParams && systemFieldParams.projectId === projectId) {
        // If already
        setValues(systemFieldParams.values); // setValues
      } else {
        const body = { projectId, relatedEntity: entity };
        entitySystemFieldParamLoader.execute(body);
      }
    }
  }, [projectId, entity]);

  return values;
};

export default UseSystemFieldParams;
