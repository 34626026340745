import i18next from 'i18next';
import jp from 'jsonpath';
// DESIGN SYSTEM
import { Check, Circle, DotsThree, Eye, EyeSlash, MathOperations, WarningCircle } from 'phosphor-react';
import Dropdown from '../components/menu/Dropdown';
// HELPER & CONSTANT
import { ENTITY_TYPES } from '../constants';
import { getArtifactName } from './ArtifactNameHelper';
import { TABLE_ACTION_COLUMNS_STYLE } from './ButtonConstants';
import {
  capitalize,
  CHART_TYPE_TITLES,
  getStatusTag,
  getUserFirstLastNameById,
  isValidList,
  isValidValue,
  translatedDateFormat
} from './Helper';

export const DEFAULT_PAGE_PARAMS = (sortbyInput, sortDirInput) => {
  return {
    page: 0,
    size: 10,
    sortBy: sortbyInput,
    sortDir: sortDirInput,
    defaultSize: 10,
    totalPages: 1,
    totalElements: 0
  };
};
const nameSelect = (name, entityType) => {
  name = name.toLowerCase();
  switch (entityType) {
    case ENTITY_TYPES.RELEASE:
      return name.replace('release', '');
    case ENTITY_TYPES.DEFECT:
      return name.replace('defect', '');
    case ENTITY_TYPES.FOLDER:
      return name.replace('folder', '');
    case ENTITY_TYPES.REQUIREMENT:
      return name.replace('requirement', '');
    case ENTITY_TYPES.TEST_CONTAINER:
      return name.replace('test container', '');
    case ENTITY_TYPES.TEST_CASE:
      return name.replace('test case', '');
    case ENTITY_TYPES.TEST_FOLDER:
      return name.replace('test folder', '');
    case ENTITY_TYPES.TEST_CYCLE:
      return name.replace('test cycle', '');
    case ENTITY_TYPES.TEST_SUITE:
      return name.replace('test suite', '');
    case ENTITY_TYPES.TEST_RUN:
      return name.replace('test run', '');
    case ENTITY_TYPES.TEST_EXECUTION:
      return name.replace('test execution', '');
    default:
      return name;
  }
};
const translateFunction = (name, entityType, projectConfig) => {
  let type = nameSelect(name, entityType).trim();
  let artifactName = getArtifactName(projectConfig, entityType);
  switch (type) {
    case 'priority':
      return i18next.t('chartPriority', {
        entity: artifactName
      });
    case 'status':
      return i18next.t('chartStatus', {
        entity: artifactName
      });
    case 'priority (jira)':
      return i18next.t('chartPriorityJira', {
        entity: artifactName
      });
    case 'priority/status':
      return i18next.t('chartPriorityStatus', {
        entity: artifactName
      });
    case 'priority/status (jira)':
      return i18next.t('chartPriorityStatusJira', {
        entity: artifactName
      });
    case 'type':
      return i18next.t('chartType', {
        entity: artifactName
      });
    case 'type/priority/status':
      return i18next.t('chartTypePriorityStatus', {
        entity: artifactName
      });
    case 'status (jira)':
      return i18next.t('chartStatusJira', {
        entity: artifactName
      });
    case 'total':
      return i18next.t('chartTotal', {
        entity: artifactName
      });
    default:
      return name;
  }
};

export const convertToTableData = (dataArr, type, users, projectConfig) => {
  let arr = [];
  if (isValidList(dataArr)) {
    arr = dataArr?.map((item) => {
      let obj = {};

      if (type === ENTITY_TYPES.STATUS_HISTORY) {
        obj = {
          previousStatus: getStatusTag(item?.prevStatus, item?.prevStatusColor),
          status: getStatusTag(item?.newStatus, item?.newStatusColor),
          user: getUserFirstLastNameById(users, item?.modifiedBy),
          date: translatedDateFormat(item?.modifiedTime, 'DD MMM YYYY HH:mm', i18next.t),
          total: item?.total
        };
      } else if (type === 'ldap') {
        obj = {
          id: item.id,
          name: item.name,
          url: item.url,
          // groupSearchFilter: item.groupSearchFilter,
          // managerDN: item.managerDn,
          // userSearchFilter: item.userSearchFilter,
          active: getActiveIconColumn(item?.active, true),
          connection: getActiveIconColumn(item?.connection)
        };
      } else if (type === 'dashboard') {
        obj = {
          //    "#": idx + 1,
          id: item.id,
          name: item?.systemDefault + '' === 'true' ? capitalize(item?.name) : item?.name,
          visible: getVisibleIconColumn(item?.visible),
          description: item?.description,
          // createdBy: getUserFirstLastNameById(users, item?.createdBy),
          modifiedBy: getUserFirstLastNameById(users, item?.modifiedBy),
          modifiedTime: translatedDateFormat(item?.modifiedTime, 'DD MMM YYYY HH:mm', i18next.t),
          systemDefault: item?.systemDefault + '' === 'true' ? 'Yes' : 'No'
        };
      } else if (type === 'chart') {
        obj = {
          //    "#": idx + 1,
          id: item.id,
          name: translateFunction(item?.name, item?.entityType, projectConfig),
          // description: item?.description,
          type: isValidValue(item?.type) ? CHART_TYPE_TITLES?.[item.type] : '',
          dateHistogram: getCheckIconColumn(item?.configuration?.dateHistogram),
          entityType: getArtifactName(projectConfig, item?.entityType),
          // configuration: item?.configuration,
          // projectId: item?.configuration?.projectId,
          // createdBy: getUserFirstLastNameById(users, item?.createdBy),
          modifiedBy: getUserFirstLastNameById(users, item?.modifiedBy),
          modifiedTime: translatedDateFormat(item?.modifiedTime, 'DD MMM YYYY HH:mm', i18next.t),
          systemDefault: item?.systemDefault + '' === 'true' ? 'Yes' : 'No'
        };
      } else if (type === 'jira') {
        obj = {
          id: item.id,
          name: item?.name,
          serverUrl: item?.serverUrl,
          active: getActiveIconColumn(item?.active),
          templateBy: item?.templateBy
        };
      } else if (type === 'mail') {
        obj = {
          id: item.id,
          host: item?.host,
          username: item?.username,
          active: getActiveIconColumn(item?.active),
          templateBy: item?.templateBy
        };
      } else if (type === 'parameter') {
        let values = [];
        for (const key in item?.parameterValues) {
          values.push(item?.parameterValues?.[key]);
        }
        obj = {
          id: item?.id,
          name: item?.name,
          description: item?.description,
          values: values.toString(),
          status: item?.status
        };
      } else if (type === 'visiumGo') {
        obj = {
          id: item.id,
          name: item?.name,
          url: item?.url,
          active: getActiveIconColumn(item?.active),
          templateBy: item?.templateBy
        };
      }
      return obj;
    });
  }
  return arr;
};

export const getTableActions = (optionsFn) => {
  if (optionsFn === null || optionsFn === undefined || optionsFn.length === 0) return undefined;

  return {
    Header: () => (
      <div className="ml-1  flex">
        <MathOperations
          size="16"
          weight="duotone"
        />
      </div>
    ),
    id: 'table-action-button',
    accessor: 'actions',
    Cell: ({ row }) => (
      <div
        id="tableDropwon-action-button"
        className={TABLE_ACTION_COLUMNS_STYLE.className}
      >
        <Dropdown options={optionsFn(row)}>
          <DotsThree
            size={TABLE_ACTION_COLUMNS_STYLE.actionDotIconSize}
            weight={TABLE_ACTION_COLUMNS_STYLE.iconWeight}
          />
        </Dropdown>
      </div>
    ),
    disableResizing: true,
    disableSortBy: true,
    width: 50
  };
};

export const generateTableColumns = (dataArr, optionsFn, hiddenColumns) => {
  let columns = [];

  if (optionsFn && optionsFn('').length > 0) {
    columns.push(getTableActions(optionsFn));
  }

  if (dataArr?.[0]) {
    const dataKeys = Object.keys(dataArr?.[0]);
    let arr = [];
    arr = dataKeys.map((item) => {
      return { Header: item, accessor: item, textAlign: 'left' };
    });
    if (isValidList(arr)) columns = columns.concat(arr);
  }

  if (isValidList(hiddenColumns)) columns = columns.filter((x) => !hiddenColumns.includes(x?.Header));

  columns = columns.filter((x) => x?.Header != 'configuration');
  // columns = columns.filter((x) => x?.Header != 'description');
  columns = columns.filter((x) => x?.Header != 'id');
  columns = columns.filter((x) => x?.Header != 'projectId');
  columns = columns.filter((x) => x?.Header != 'password');
  columns = columns.filter((x) => x?.Header != 'templateBy');
  return columns;
};

export const TABLE_PAGE_OPTIONS = [10, 25, 50, 100, 500];

export const TableWrapper = ({ children }) => {
  return (
    <>
      <div
        id="table"
        className="overflow-hidden rounded border border-gray-200 shadow-sm"
      >
        {children}
      </div>
    </>
  );
};

export const NoDataMessage = ({ message }) => {
  return (
    <div className="my-8 flex flex-col items-center justify-center text-xs text-gray-300">
      <WarningCircle
        size={40}
        color="#6B7280"
      />
      <span className="font-semibold text-gray-500 ">{message} </span>
    </div>
  );
};

export const getPageParamsRequestInput = (params) => {
  return {
    page: params?.page,
    size: params?.size,
    sortBy: params?.sortBy,
    sortDir: params?.sortDir
  };
};

export const getActiveIconColumn = (data, visible) => {
  if (visible == true) return getCheckIconColumn(data);
  if (visible != true)
    return (
      <Circle
        color={data + '' === 'true' ? 'green' : 'gray'}
        size="16"
        weight="fill"
      />
    );
};

export const getVisibleIconColumn = (data) => {
  return data + '' === 'true' ? (
    <Eye
      size={15}
      color="#348014"
      weight="fill"
    />
  ) : (
    <EyeSlash
      size={15}
      color="grey"
    />
  );
};

export const getCheckIconColumn = (data) => {
  return data + '' === 'true' ? (
    <Check
      size={15}
      color="#348014"
      weight="fill"
    />
  ) : (
    ''
  );
};

export const getLoading = () => {
  return (
    <div
      className="flex h-full w-full items-center justify-center"
      style={{ marginTop: 50 }}
    >
      <div className="flex items-center justify-center space-x-1 text-sm text-gray-700">
        <svg
          fill="none"
          className="h-6 w-6 animate-spin"
          viewBox="0 0 32 32"
        >
          <path
            clipRule="evenodd"
            d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
        <div>{i18next.t('Loading ...')}</div>
      </div>
    </div>
  );
};

export const findRow = (datalist, row) => {
  if (isValidList(datalist)) return datalist.find((c) => c?.id === row?.original?.id);
  else return null;
};
/*eslint-disable */
export const handleSelectedRow = (datalist, row, setSelectedItem) => {
  if (setSelectedItem) {
    if (isValidList(datalist) && row) {
      const selectedData = findRow(datalist, row);
      if (isValidValue(selectedData)) setSelectedItem(selectedData);
      else setSelectedItem(null);
    } else {
      setSelectedItem(null);
    }
  }
};

/**
 *
 * @param {object []} datalist
 * @param {string} id
 * @returns {obj | null}
 */
export const findRowWithId = (datalist, id) => {
  if (isValidList(datalist)) return datalist.find((c) => c?.id === id);
  else return null;
};

/**
 *
 * @param {object []} datalist
 * @param {string} id
 * @param {*} setSelectedItem
 */
export const handleSelectedRowWithId = (datalist, id, setSelectedItem) => {
  if (setSelectedItem) {
    if (isValidList(datalist) && id) {
      const selectedData = findRowWithId(datalist, id);
      if (isValidValue(selectedData)) setSelectedItem(selectedData);
      else setSelectedItem(null);
    } else {
      setSelectedItem(null);
    }
  }
};

export const buildParam = (data, pageParams = [], projectId, index = 0) => {
  const params = new URLSearchParams({ projectId });
  pageParams.forEach((p) => {
    let value = p?.value;
    if (value?.path) {
      const result = jp.query(data, value.path);
      value = result[index];
    }
    params.set(p?.name, value);
  });

  return params.toString();
};

export const createTableColumns = (columns = [], customFields = []) => {
  let c = columns.map((c) => ({
    Header: c?.name,
    accessor: c?.key,
    textAlign: c?.textAlign && 'left',
    ...(c?.width && { width: c?.width })
  }));
  let cf = customFields.map((x) => ({ Header: x?.fieldName, accessor: x?.fieldName, textAlign: 'left' }));
  return [...c, ...cf];
};

export const disableResizingForColumn = (columns, accessor, width) => {
  return columns.map((obj) => {
    if (obj.accessor === accessor) {
      return {
        ...obj,
        width: width || 100,
        disableResizing: true,
        getResizerProps: () => {}
      };
    } else {
      return obj;
    }
  });
};

/**
 * Disable Resizing for Columns
 * @param {object[]} columns - Columns Array
 * @param {object[]} list - Array of setting objects
 * @returns {object[]} Array of columns
 */
export const disableResizingForColumns = (columns, list) => {
  if (!columns || !list) return null;
  const listArr = list.map((item) => item.accessor);

  const DEFAULT_COL_SIZE = 150;
  return columns.map((column) => {
    if (listArr.includes(column.accessor)) {
      const t = list.filter((a) => a?.accessor === column?.accessor);
      const widthT = isValidList(t) ? t[0]?.width : DEFAULT_COL_SIZE;
      return {
        ...column,
        disableResizing: true,
        width: widthT ? widthT : DEFAULT_COL_SIZE
      };
    }
    return column;
  });
};

/**
 * Translate Columns
 * @param {object[]} data - Columns array
 * @callback t - Translation function
 * @returns {object[]} - Array of columns with translated header
 */
export const translateColumns = (data, t) => {
  if (!isValidList(data)) return [];
  let columns = data;
  columns = columns.map((column) => {
    if (typeof column.Header !== 'string' && column.Header !== '') {
      return column;
    } // When the header is not a string do not translate
    const header = capitalize(column?.Header);
    return { ...column, Header: t(header) };
  });
  return columns;
};

export const makeColumnSticky = (columns, accessor, direction) => {
  return columns.map((obj) => {
    if (obj.accessor === accessor) {
      return {
        ...obj,
        sticky: direction
      };
    } else {
      return obj;
    }
  });
};

export const COLUMN_CODE_WIDTH = 175;
export const COLUMN_SYSTEMFIELD_WIDTH = 300;
export const COLUMN_DATE_WIDTH = 200;
export const COLUMN_NUMBER_WIDTH = 125;
export const COLUMN_USER_WIDTH = 600;

export const getNotResizedColumnList = (entityType) => {
  switch (entityType) {
    case ENTITY_TYPES.REQUIREMENT:
      return [
        { accessor: 'code', width: COLUMN_CODE_WIDTH },
        { accessor: 'status', width: COLUMN_SYSTEMFIELD_WIDTH },
        { accessor: 'contribution', width: COLUMN_NUMBER_WIDTH },
        { accessor: 'type', width: COLUMN_SYSTEMFIELD_WIDTH },
        { accessor: 'priority', width: COLUMN_SYSTEMFIELD_WIDTH }
      ];
    case ENTITY_TYPES.RELEASE:
      return [
        { accessor: 'code', width: COLUMN_CODE_WIDTH },
        { accessor: 'status', width: COLUMN_SYSTEMFIELD_WIDTH },
        { accessor: 'startDate', width: COLUMN_DATE_WIDTH },
        { accessor: 'endDate', width: COLUMN_DATE_WIDTH }
      ];
    case ENTITY_TYPES.TEST_CASE:
      return [
        { accessor: 'code', width: COLUMN_CODE_WIDTH },
        { accessor: 'status', width: COLUMN_SYSTEMFIELD_WIDTH },
        { accessor: 'approval', width: 200 },
        { accessor: 'version', width: COLUMN_NUMBER_WIDTH }
      ];

    case ENTITY_TYPES.TEST_CYCLE:
      return [
        { accessor: 'code', width: COLUMN_CODE_WIDTH },
        { accessor: 'parentCode', width: COLUMN_CODE_WIDTH },
        { accessor: 'testFolderCode', width: COLUMN_CODE_WIDTH },
        { accessor: 'status', width: COLUMN_SYSTEMFIELD_WIDTH },
        { accessor: 'startDate', width: COLUMN_DATE_WIDTH },
        { accessor: 'endDate', width: COLUMN_DATE_WIDTH }
      ];
    case ENTITY_TYPES.TEST_SUITE:
      return [
        { accessor: 'code', width: COLUMN_CODE_WIDTH },
        { accessor: 'testCycleCode', width: COLUMN_CODE_WIDTH },
        { accessor: 'testFolderCode', width: COLUMN_CODE_WIDTH },
        { accessor: 'parentCode', width: COLUMN_CODE_WIDTH }
      ];
    case ENTITY_TYPES.TEST_RUN:
      return [
        { accessor: 'code', width: COLUMN_CODE_WIDTH },
        { accessor: 'testCycleCode', width: COLUMN_CODE_WIDTH },
        { accessor: 'testFolderCode', width: COLUMN_CODE_WIDTH },
        { accessor: 'testSuiteCode', width: COLUMN_CODE_WIDTH },
        { accessor: 'testCaseCode', width: COLUMN_CODE_WIDTH },
        // { accessor: "testerFirstName", width: COLUMN_USER_WIDTH },
        { accessor: 'status', width: COLUMN_SYSTEMFIELD_WIDTH },
        { accessor: 'startDate', width: COLUMN_DATE_WIDTH },
        { accessor: 'defectCount', width: COLUMN_NUMBER_WIDTH },
        { accessor: 'executionCount', width: COLUMN_NUMBER_WIDTH },
        { accessor: 'endDate', width: COLUMN_DATE_WIDTH }
      ];
    case ENTITY_TYPES.DEFECT:
      return [
        { accessor: 'code', width: COLUMN_CODE_WIDTH },
        { accessor: 'testCycleCode', width: COLUMN_CODE_WIDTH }
      ];
    default:
      return [];
  }
};

/**
 * @description disable sorting for column accessor array
 * @param {Array} - Columns array
 * @param {String[]} - Array of column accessor strings to disable sorting
 * @returns {Array | null} - columns with disableSorting set to true for the columns in the list
 */
export const disableSortingForColumns = (columns, list) => {
  if (!columns || !list) return null;
  return columns.map((column) => {
    if (list.includes(column.accessor)) {
      return {
        ...column,
        disableSorting: true
      };
    } else return column;
  });
};
