import React, { useEffect } from 'react';
import { useApp, useAppDispatch } from '../../../../context/AppContext';
import { PROJECT_ACTION_KEYS } from '../../../../context/Actions';
import { useForm } from 'react-hook-form';
// Api Imports
import ProjectConfigApi from '../../../../api/ProjectConfigApi';
import { useApi } from '../../../../hooks/UseApi';
//Design System Imports
import { Input, Toggle, Button, Heading, P } from '../../../../designSystem/DesignSystem';
import Accordion from '../../../../components/accordion/Accordion';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { getRequirementName, getTestCaseName } from '../../../../helpers/ArtifactNameHelper';
import { useTranslation } from 'react-i18next';
import { isTrue, isValidValue } from '../../../../helpers/Helper';
import { saveProjectConfig } from '../../../../context/ProjectActions';
import UseQueryProject from '../../../../hooks/UseQueryProject';

const HEADING_SIZE = 'small';

const ProjectJiraRequirementSetting = ({ jiraConfig }) => {
  const app = useApp();
  let projectConfig = app?.config;
  const { projectId } = UseQueryProject('projectId');
  const dispatch = useAppDispatch();
  const { control, setValue, getValues } = useForm({ shouldFocusError: true, criteriaMode: 'all' });

  // TRANSLATE
  const { t } = useTranslation();

  const updateProjectConfig = useApi({
    name: 'Updating project config',
    api: ProjectConfigApi.updateProjectConfiguration,
    onSuccess: (response) => {
      showSuccessMes(INFO_MESSAGES.save);
      dispatch(saveProjectConfig(projectId, response));
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });
  const handleFieldSubmit = (value, key) => {
    let submitValue = value;

    let obj = projectConfig;
    switch (key) {
      case 'createNewJiraRequirement':
        obj.configuration.jira.createNewJiraRequirement = submitValue;
        break;
      case 'updateJiraRequirement':
        obj.configuration.jira.updateJiraRequirement = submitValue;
        break;
      case 'showRequirementJiraAssigneeToCreate':
        obj.configuration.jira.showRequirementJiraAssigneeToCreate = submitValue;
        break;
      case 'jiraAndVManageCommentIntegrationForRequirement':
        obj.configuration.jira.jiraAndVManageCommentIntegrationForRequirement = submitValue;
        break;
      case 'showRequirementJiraLinkToCreate':
        obj.configuration.jira.showRequirementJiraLinkToCreate = submitValue;
        break;
      case 'showRequirementJiraAssigneeToUpdate':
        obj.configuration.jira.showRequirementJiraAssigneeToUpdate = submitValue;
        break;
      case 'showRequirementJiraLinkToUpdate':
        obj.configuration.jira.showRequirementJiraLinkToUpdate = submitValue;
        break;
      case 'addRequirementJiraReporterToCreate':
        obj.configuration.jira.addRequirementJiraReporterToCreate = submitValue;
        break;
      case 'requirementTreeShowJiraIssueId':
        obj.configuration.jira.requirementTreeShowJiraIssueId = submitValue;
        break;
      case 'showRequirementJiraDescriptionToUpdate':
        obj.configuration.jira.showRequirementJiraDescriptionToUpdate = submitValue;
        break;
      case 'showRequirementJiraDescriptionToCreate':
        obj.configuration.jira.showRequirementJiraDescriptionToCreate = submitValue;
        break;
      case 'showRequirementJiraPriorityToUpdate':
        obj.configuration.jira.showRequirementJiraPriorityToUpdate = submitValue;
        break;
      case 'showRequirementJiraPriorityToCreate':
        obj.configuration.jira.showRequirementJiraPriorityToCreate = submitValue;
        break;
      default:
        break;
    }

    if (!isValidValue(value)) return;

    dispatch({
      type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_CONFIG,
      config: { ...obj }
    });

    const body = { id: app.project.id, key, value: submitValue };
    updateProjectConfig.execute(body);
  };

  const saveStringInput = (value, key) => {
    if (projectConfig?.configuration?.jira?.[key] == value) return;

    var body = { id: projectId, key: key, value };
    var obj = {
      ...projectConfig,
      configuration: { ...projectConfig.configuration, jira: { ...projectConfig.configuration.jira, [key]: value } }
    };

    dispatch({
      type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_CONFIG,
      config: { ...obj }
    });

    updateProjectConfig.execute(body);
  };

  useEffect(() => {
    setValue(
      'requirementJiraAssigneeCustomField',
      projectConfig?.configuration?.jira?.requirementJiraAssigneeCustomField
    );
    setValue('requirementJiraLinkType', projectConfig?.configuration?.jira?.requirementJiraLinkType);
    setValue('requirementJiraLinkQuery', projectConfig?.configuration?.jira?.requirementJiraLinkQuery);
    setValue('requirementSummaryFieldToCreate', projectConfig?.configuration?.jira?.requirementSummaryFieldToCreate);
    setValue(
      'requirementDescriptionFieldToCreate',
      projectConfig?.configuration?.jira?.requirementDescriptionFieldToCreate
    );
    setValue('requirementManageLinkField', projectConfig?.configuration?.jira?.requirementManageLinkField);
    setValue(
      'assignedTestCaseNumberInRequirementCustomField',
      projectConfig?.configuration?.jira?.assignedTestCaseNumberInRequirementCustomField
    );
    setValue(
      'testAutomationControlInRequirementCustomField',
      projectConfig?.configuration?.jira?.testAutomationControlInRequirementCustomField
    );
  }, [projectConfig]);

  return (
    <div className="flex flex-grow flex-col gap-4">
      <Accordion
        border
        button={
          <Heading
            type={HEADING_SIZE}
            bold
            text={t('Other Settings')}
          />
        }
      >
        <div>
          <div className="mt-2.5">
            <div className="flex w-2/4 items-center gap-3">
              <Input
                disabled={jiraConfig?.templateBy}
                name="requirementJiraAssigneeCustomField"
                label={t('jiraCustomEntity', { param: getRequirementName(projectConfig) })}
                control={control}
                passValue={(value) => setValue('requirementJiraAssigneeCustomField', value)}
              />
              <div className="mb-1 flex self-end">
                <Button
                  id="jiraReqSetting-assignCFSave-button"
                  primary
                  text={t('Save')}
                  size="small"
                  disabled={jiraConfig?.templateBy}
                  onClick={() =>
                    saveStringInput(
                      getValues('requirementJiraAssigneeCustomField'),
                      'requirementJiraAssigneeCustomField'
                    )
                  }
                />
              </div>
            </div>
            <P
              text={t('customFieldSettingsEntity', { param: getRequirementName(projectConfig) })}
              small
              className="mb-4 ml-2"
            />
          </div>
          <div className="flex w-2/4  items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="requirementJiraLinkType"
              label={t('jiraLinkType_entity', { param: getRequirementName(projectConfig) })}
              control={control}
              passValue={(value) => setValue('requirementJiraLinkType', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraReqSetting-linkTypeSave-button"
                primary
                text={t('Save')}
                size="small"
                disabled={jiraConfig?.templateBy}
                onClick={() => saveStringInput(getValues('requirementJiraLinkType'), 'requirementJiraLinkType')}
              />
            </div>
          </div>
          <P
            text={t(
              'This field setting will be used for define link type. Link type will be sent according to determinated type. (Example link type : clones )'
            )}
            small
            className="mb-4 ml-2"
          />
          <div className="flex w-2/4  items-center gap-3">
            <Input
              name="requirementJiraLinkQuery"
              label={t('Link Query Used to Get Linked Items from Jira For ')}
              control={control}
              passValue={(value) => setValue('requirementJiraLinkQuery', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraReqSetting-linkQuerySave-button"
                primary
                text={t('Save')}
                onClick={() => saveStringInput(getValues('requirementJiraLinkQuery'), 'requirementJiraLinkQuery')}
                size="small"
              />
            </div>
          </div>
          <P
            text={t('jiraBuild_entity', { param: getRequirementName(projectConfig) })}
            small
            className="mb-4 ml-2"
          />
          <div className="flex w-2/4  items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="requirementManageLinkField"
              label={t('Jira Custom Field Id to Send Visium Manage Link')}
              control={control}
              passValue={(value) => setValue('requirementManageLinkField', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraReqSetting-linkFieldSave-button"
                disabled={jiraConfig?.templateBy}
                onClick={() => saveStringInput(getValues('requirementManageLinkField'), 'requirementManageLinkField')}
                text={t('Save')}
                primary
                size="small"
              />
            </div>
          </div>
          <P
            text={t('visiumNotSend_Jira', { param: getRequirementName(projectConfig) })}
            small
            className="mb-4 ml-2"
          />
          <div className="flex w-2/4  items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="assignedTestCaseNumberInRequirementCustomField"
              label={t('jiraRequrimentSendTo', {
                testCase: getTestCaseName(projectConfig),
                requirement: getRequirementName(projectConfig)
              })}
              control={control}
              passValue={(value) => setValue('assignedTestCaseNumberInRequirementCustomField', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraReqSetting-assignTestCaseCFSave-button"
                disabled={jiraConfig?.templateBy}
                onClick={() =>
                  saveStringInput(
                    getValues('assignedTestCaseNumberInRequirementCustomField'),
                    'assignedTestCaseNumberInRequirementCustomField'
                  )
                }
                text={t('Save')}
                primary
                size="small"
              />
            </div>
          </div>
          <P
            text={t('jiraRequirementInformation', {
              TestCaseName: getTestCaseName(projectConfig),
              RequirementName: getRequirementName(projectConfig)
            })}
            small
            className="mb-4 ml-2"
          />
          <div className="flex w-2/4  items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="testAutomationControlInRequirementCustomField"
              label={t('Jira Custom Field ID to Send for Control the Test Automation')}
              control={control}
              passValue={(value) => setValue('testAutomationControlInRequirementCustomField', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraReqSetting-assignTestCaseCFSave-button"
                disabled={jiraConfig?.templateBy}
                onClick={() =>
                  saveStringInput(
                    getValues('testAutomationControlInRequirementCustomField'),
                    'testAutomationControlInRequirementCustomField'
                  )
                }
                text={t('Save')}
                primary
                size="small"
              />
            </div>
          </div>
          <P
            text={t(
              'This custom field setting will be used to send for control the test automation . If there is no custom field id, this field not send to Jira.'
            )}
            small
            className="mb-4 ml-2"
          />

          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraComment_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.jiraAndVManageCommentIntegrationForRequirement)}
            name="jiraAndVManageCommentIntegrationForRequirement"
            passValue={(value) => handleFieldSubmit(value, 'jiraAndVManageCommentIntegrationForRequirement')}
          />
        </div>
      </Accordion>
      <Accordion
        border
        button={
          <Heading
            type={HEADING_SIZE}
            bold
            text={t('Jira Issue Creation')}
          />
        }
      >
        <div className="flex flex-col gap-2">
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('createJira_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.createNewJiraRequirement)}
            name="createNewJiraRequirement"
            passValue={(value) => {
              handleFieldSubmit(value, 'createNewJiraRequirement');
            }}
          />

          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t(`Add reporter user information into put request payload`)}
            value={isTrue(projectConfig?.configuration?.jira?.addRequirementJiraReporterToCreate)}
            name="addRequirementJiraReporterToCreate"
            passValue={(value) => handleFieldSubmit(value, 'addRequirementJiraReporterToCreate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraAssignee_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showRequirementJiraAssigneeToCreate)}
            name="showRequirementJiraAssigneeToCreate"
            passValue={(value) => handleFieldSubmit(value, 'showRequirementJiraAssigneeToCreate')}
          />

          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('JiraLink_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showRequirementJiraLinkToCreate)}
            name="showRequirementJiraLinkToCreate"
            passValue={(value) => handleFieldSubmit(value, 'showRequirementJiraLinkToCreate')}
          />

          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraIssueKey_show_tree', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.requirementTreeShowJiraIssueId)}
            name="requirementTreeShowJiraIssueId"
            passValue={(value) => handleFieldSubmit(value, 'requirementTreeShowJiraIssueId')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jira_description_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showRequirementJiraDescriptionToCreate)}
            name="showRequirementJiraDescriptionToCreate"
            passValue={(value) => handleFieldSubmit(value, 'showRequirementJiraDescriptionToCreate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jira_priority_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showRequirementJiraPriorityToCreate)}
            name="showRequirementJiraPriorityToCreate"
            passValue={(value) => handleFieldSubmit(value, 'showRequirementJiraPriorityToCreate')}
          />

          <div className="flex w-2/4  items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="requirementSummaryFieldToCreate"
              label={t('customField_entity', { param: getRequirementName(projectConfig) })}
              control={control}
              passValue={(value) => setValue('requirementSummaryFieldToCreate', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraReqSetting-summarySave-button"
                primary
                text={t('Save')}
                size="small"
                disabled={jiraConfig?.templateBy}
                onClick={() =>
                  saveStringInput(getValues('requirementSummaryFieldToCreate'), 'requirementSummaryFieldToCreate')
                }
              />
            </div>
          </div>
          <P
            text={t('summaryJira_entity', { param: getRequirementName(projectConfig) })}
            small
            className="mb-4 ml-2"
          />

          <div className="flex w-2/4  items-center gap-3">
            <Input
              disabled={jiraConfig?.templateBy}
              name="requirementDescriptionFieldToCreate"
              label={t('customField_jira_entity', { param: getRequirementName(projectConfig) })}
              control={control}
              passValue={(value) => setValue('requirementDescriptionFieldToCreate', value)}
            />
            <div className="mb-1 flex self-end">
              <Button
                id="jiraReqSetting-descriptionSave-button"
                primary
                text={t('Save')}
                size="small"
                disabled={jiraConfig?.templateBy}
                onClick={() =>
                  saveStringInput(
                    getValues('requirementDescriptionFieldToCreate'),
                    'requirementDescriptionFieldToCreate'
                  )
                }
              />
            </div>
          </div>
        </div>
        <P
          text={t('descriptionJira_entity', { param: getRequirementName(projectConfig) })}
          small
          className="mb-4 ml-2"
        />
      </Accordion>
      <Accordion
        border
        button={
          <Heading
            type={HEADING_SIZE}
            bold
            text={t('Jira Issue Modification')}
          />
        }
      >
        <div className="flex flex-col gap-2">
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraUpdate_entity', { projectConfig: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.updateJiraRequirement)}
            name="updateJiraRequirement"
            passValue={(value) => handleFieldSubmit(value, 'updateJiraRequirement')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jiraAssignee_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showRequirementJiraAssigneeToUpdate)}
            name="showRequirementJiraAssigneeToUpdate"
            passValue={(value) => handleFieldSubmit(value, 'showRequirementJiraAssigneeToUpdate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('JiraLink_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showRequirementJiraLinkToUpdate)}
            name="showRequirementJiraLinkToUpdate"
            passValue={(value) => handleFieldSubmit(value, 'showRequirementJiraLinkToUpdate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jira_description_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showRequirementJiraDescriptionToUpdate)}
            name="showRequirementJiraDescriptionToUpdate"
            passValue={(value) => handleFieldSubmit(value, 'showRequirementJiraDescriptionToUpdate')}
          />
          <Toggle
            disabled={jiraConfig?.templateBy}
            control={control}
            labelRight
            labelText={t('jira_priority_entity', { param: getRequirementName(projectConfig) })}
            value={isTrue(projectConfig?.configuration?.jira?.showRequirementJiraPriorityToUpdate)}
            name="showRequirementJiraPriorityToUpdate"
            passValue={(value) => handleFieldSubmit(value, 'showRequirementJiraPriorityToUpdate')}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default ProjectJiraRequirementSetting;
