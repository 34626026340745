import moment from 'moment';
import React, { useEffect } from 'react';
// API & HOOKS
import { useApi } from '../../../hooks/UseApi';
import ElasticsearchApi from '../../../api/ElasticsearchApi';
// DESIGN SYSTEM & COMPONENTS
import { SidePaneRightContent } from '../../../components/Layout';
import { Body, Heading, Button } from '../../../designSystem/DesignSystem';
// HELPERS & CONSTANTS
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import LicensePage from './LicensePage';
import { useTranslation } from 'react-i18next';

const OtherSettingsPage = () => {
  const { t } = useTranslation();

  const massIndexingStarter = useApi({
    name: 'Mass Indexing Starter',
    api: ElasticsearchApi.startMassIndexing,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes('Mass Indexing started successfully.');
      massIndexingStatusLoader.execute();
    },
    onError: (err) => {
      showErrorMes(err);
      massIndexingStatusLoader.execute();
    }
  });

  const massIndexingStatusLoader = useApi({
    name: 'Fetch Mass Indexing Status',
    api: ElasticsearchApi.statusMassIndexing,
    autoLoad: false
  });

  useEffect(() => {
    massIndexingStatusLoader.execute();
  }, []);

  return (
    <SidePaneRightContent>
      <SidePaneRightContent.Top>
        <Heading.Group
          type="large"
          text={t('Other')}
        ></Heading.Group>
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Center>
        <div className="my-2 flex rounded-md p-2 shadow-md shadow-violet-100">
          <div className="w-full p-4">
            <Heading.Group
              type="normal"
              text={t('Mass Indexing')}
            >
              <div className="flex gap-2 self-end">
                <Button
                  id="otherSettings-start-button"
                  primary
                  text={t('Start')}
                  size="small"
                  icon="Play"
                  loading={massIndexingStarter.loading}
                  onClick={() => massIndexingStarter.execute()}
                />
                <Button
                  id="otherSettings-refresh button"
                  secondary
                  size="small"
                  loading={massIndexingStatusLoader.loading}
                  disabled={massIndexingStatusLoader.loading}
                  icon="ArrowCounterClockwise"
                  onClick={() => massIndexingStatusLoader.execute()}
                />
              </div>
            </Heading.Group>
            <div className="mt-2 text-sm font-normal">
              <Body
                text={t('Running the Mass Indexer with many threads will require many connections to the database!')}
              />
              {massIndexingStatusLoader.data ? (
                <Body
                  text={`Last mass indexing status: ${massIndexingStatusLoader.data?.status}.
               It started ${
                 massIndexingStatusLoader.data?.startTime
                   ? moment(massIndexingStatusLoader.data?.startTime, 'x').format('DD MMM YYYY HH:mm')
                   : '-'
               }
               and ended at ${
                 massIndexingStatusLoader.data?.endTime
                   ? moment(massIndexingStatusLoader.data?.endTime, 'x').format('DD MMM YYYY HH:mm')
                   : '-'
               }`}
                />
              ) : null}
            </div>
          </div>
        </div>

        <LicensePage />
      </SidePaneRightContent.Center>
    </SidePaneRightContent>
  );
};

export default OtherSettingsPage;
