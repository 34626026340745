import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import MailConfigApi from '../../../../api/MailConfigApi';
import { Input, Toggle, Password, Button } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import { editModalMapping, isValidValue } from '../../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { useApi } from '../../../../hooks/UseApi';
import { encryptData } from '../../../../helpers/CryptoHelper';
import { useTranslation } from 'react-i18next';

const MailModal = (props) => {
  const { isOpen, data, closeModal, projectId } = props;

  const [activation, setActivation] = useState(false);
  const [ssl, setSSLActivation] = useState(false);
  const [starttls, setStarttlsActivation] = useState(false);

  const { t } = useTranslation();

  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const createEdit = useApi({
    name: 'saving ',
    api: isValidValue(data) ? MailConfigApi.edit : MailConfigApi.add,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal(createEdit?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = async (form) => {
    const { username, password } = form;
    const encrUsername = await encryptData(username);
    const encrPassword = await encryptData(password);
    let input = {
      ...form,
      username: encrUsername,
      password: encrPassword,
      projectId,
      active: activation,
      ssl: ssl,
      starttls: starttls
    };
    createEdit.execute({ ...input, id: data?.id });
  };

  useEffect(() => {
    if (isValidValue(data)) {
      delete data.password;
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setActivation(data?.active + '' === 'true');
      setSSLActivation(data?.ssl + '' === 'true');
      setStarttlsActivation(data?.starttls + '' === 'true');
    }
  }, [data]);
  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
        w={'md'}
      >
        <Modal.Title>
          {isValidValue(data) ? t('Edit ') : t('Create New ')} {t('Mail Server Config')}
        </Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-5">
              <Input
                required
                label={t('Host')}
                name={'host'}
                placeholder={t(`Enter`)}
                control={control}
              />
              <Input
                required
                type={'number'}
                label={t('Port')}
                name={'port'}
                placeholder={t(`Enter`)}
                control={control}
              />
              <Input
                required
                label={t('Username')}
                name={'username'}
                placeholder={`Enter`}
                control={control}
              />
              <Password
                required={!isValidValue(data)}
                label={t(isValidValue(data) ? 'New Password' : 'Password')}
                name={'password'}
                placeholder={t(isValidValue(data) ? 'Enter new password' : 'Enter password')}
                control={control}
              />
              <Input
                required
                label={t('Mail From')}
                name={'mailFrom'}
                placeholder={t(`Enter`)}
                control={control}
              />
              <div className="flex gap-4">
                <>
                  <Toggle
                    name={'active'}
                    labelText={t('Active')}
                    labelLeft={true}
                    control={control}
                    value={activation}
                    passValue={(value) => setActivation(value)}
                  />
                  <Toggle
                    name={'ssl'}
                    labelText={t('Use SSL')}
                    labelLeft={true}
                    control={control}
                    value={ssl}
                    passValue={(value) => setSSLActivation(value)}
                  />
                  <Toggle
                    name={'starttls'}
                    labelText={t('Use STARTTLS')}
                    labelLeft={true}
                    control={control}
                    value={starttls}
                    passValue={(value) => setStarttlsActivation(value)}
                  />
                </>
              </div>
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="mailModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="mailModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEdit?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default MailModal;
