/**
 * It takes an index name and returns the entity name
 * @param index - The index name
 * @returns The entity name from the index.
 */
export const getEntityNameFromIndex = (index) => {
  if (index) {
    const reg = /(.+)-(.+)-(.+)/g;
    if (index?.match(reg)) {
      return index?.split('-')[1];
    }
  }
  return null;
};

/**
 * Given a string, return the third part of the string split by a hyphen.
 * @param index - The index of the code.
 * @returns The code number from the code.
 */
export const getCodeNumberFromCode = (index) => {
  if (index) {
    const reg = /(.+)-(.+)-(.+)/g;
    if (index?.match(reg)) {
      return index?.split('-')[2];
    }
  }
  return null;
};

export const routeObj = {
  testcase: {
    route: '/test-cases',
    params: {
      type: 'testCase'
    }
  },
  testcontainer: {
    route: '/test-cases',
    params: {
      type: 'testContainer'
    }
  },
  folder: {
    route: '/requirements',
    params: {
      type: 'folder'
    }
  },
  requirement: {
    route: '/requirements',
    params: {
      type: 'requirement'
    }
  },
  defect: {
    route: '/defects'
  },
  release: {
    route: '/releases'
  },
  testfolder: {
    route: '/planning',
    params: {
      type: 'testFolder'
    }
  },
  testcycle: {
    route: '/planning',
    params: {
      type: 'testCycle'
    }
  },
  testsuite: {
    route: '/planning',
    params: {
      type: 'testSuite'
    }
  },
  testrun: {
    route: '/tasks'
  }
};
