import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const Comment_Path = BASE_URL + 'comments';

class CommentApi {
  async getAll(data) {
    const resp = await axios.get(Comment_Path, { params: data });
    return resp.data;
  }

  async get(data) {
    const resp = await axios.get(`${Comment_Path}/${data.id}`);
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(Comment_Path, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${Comment_Path}/${data.id}`, data);
    return resp.data;
  }

  async delete(id) {
    const resp = await axios.delete(`${Comment_Path}/${id}`);
    return resp.data;
  }
}

export default new CommentApi();
