import React from 'react';
import { useForm } from 'react-hook-form';
import { X } from 'phosphor-react';
import { useApp, useAppDispatch } from '../../../../context/AppContext';
import { PROJECT_ACTION_KEYS } from '../../../../context/Actions';
import UseProjectConfig from '../../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../../hooks/UseQueryProject';
// Api Imports
import ProjectConfigApi from '../../../../api/ProjectConfigApi';
import { useApi } from '../../../../hooks/UseApi';
//Design System Imports
import { Dropdown, Heading, P } from '../../../../designSystem/DesignSystem';
import TabBar from '../../../../components/TabBar';
import ProjectJiraDefectSettings from './ProjectJiraDefectSettings';
import ProjectJiraRequirementSetting from './ProjectJiraRequirementSetting';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { getDefectName, getRequirementName } from '../../../../helpers/ArtifactNameHelper';
import { Modal } from '../../../../components/modal';
import { useTranslation } from 'react-i18next';

const ProjectJiraConfigSetting = ({ isOpen, closeModal, jiraConfig }) => {
  const app = useApp();
  const dispatch = useAppDispatch();
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();

  let projectConfig = UseProjectConfig(projectId);

  let userAssignmentDDOptions = [
    { key: 1, value: 'Username', name: 'Username' },
    { key: 2, value: 'Email', name: 'Email' }
  ];

  userAssignmentDDOptions = userAssignmentDDOptions.map((item) => {
    return {
      ...item,
      name: t(item.name)
    };
  });
  const updateProjectConfig = useApi({
    name: 'Updating project config',
    api: ProjectConfigApi.updateProjectConfiguration,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const handleFieldSubmit = (value, key) => {
    let submitValue = value;

    let obj = projectConfig;

    if (key === 'jiraUserIntegrationWith') obj.configuration.jira.jiraUserIntegrationWith = submitValue;

    dispatch({
      type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_CONFIG,
      config: { ...obj }
    });

    const body = { id: app.project.id, key, value: submitValue };
    updateProjectConfig.execute(body);
  };

  return (
    <Modal
      open={isOpen}
      close={() => closeModal}
      w={'lg'}
    >
      <Modal.Title>
        <Heading.Group
          text={t('Common Project Jira Settings')}
          type="large"
        >
          <div
            className="flex gap-4"
            style={{ cursor: 'pointer' }}
            onClick={closeModal}
          >
            <X
              size="20"
              weight="duotone"
            />
          </div>
        </Heading.Group>
      </Modal.Title>

      <Modal.Content h={'lg'}>
        <div className="flex  w-full ">
          <div className="ml-2 mt-2 flex flex-grow bg-white">
            <div className="flex flex-grow flex-col ">
              <div>
                <Dropdown
                  disabled={jiraConfig?.templateBy}
                  label={t('Select to use for user assignments on jira')}
                  control={control}
                  options={userAssignmentDDOptions}
                  name="jiraUserIntegrationWith"
                  passValue={(value) => handleFieldSubmit(value[0].value, 'jiraUserIntegrationWith')}
                  selectedItem={{
                    name:
                      t(projectConfig?.configuration?.jira?.jiraUserIntegrationWith).charAt(0).toLocaleUpperCase() +
                      t(projectConfig?.configuration?.jira?.jiraUserIntegrationWith).slice(1)
                  }}
                />
                <P
                  text={t(
                    'This field will be used for user assignment. User information will be sent according to chosen type.'
                  )}
                  small
                  className="ml-2 mt-2"
                />
              </div>

              <div className="mt-5">
                <TabBar
                  defaultIndex={0}
                  options={[
                    {
                      name: getRequirementName(projectConfig),
                      disabled: false,
                      render: () => (
                        <div className="mt-6">
                          <ProjectJiraRequirementSetting
                            projectConfig={projectConfig}
                            jiraConfig={jiraConfig}
                          />
                        </div>
                      )
                    },
                    {
                      name: getDefectName(projectConfig),
                      disabled: false,
                      render: () => (
                        <div className="mt-6">
                          <ProjectJiraDefectSettings
                            projectConfig={projectConfig}
                            jiraConfig={jiraConfig}
                          />
                        </div>
                      )
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ProjectJiraConfigSetting;
