import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { editModalMapping, isValidValue } from '../../../../helpers/Helper';
import { useApi } from '../../../../hooks/UseApi';

import { Input, TextArea, Toggle, Button, Password } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import JiraApi from '../../../../api/JiraApi';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { encryptData } from '../../../../helpers/CryptoHelper';
import { useTranslation } from 'react-i18next';

const JiraModal = (props) => {
  const { isOpen, data, closeModal, projectId } = props;
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const [activation, setActivation] = useState(false);

  const createEditData = useApi({
    name: 'saving jira server config',
    api: isValidValue(data) ? JiraApi.updateJiraIntegrationConfig : JiraApi.createJiraIntegrationConfig,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal();
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const onSubmit = async (form) => {
    const { username, password, matchingPassword } = form;
    const encrUserName = await encryptData(username);
    const encrPassword = await encryptData(password);
    const encrMatchingPassword = await encryptData(matchingPassword);

    const body = {
      ...(isValidValue(data) && { id: data.id }),
      projectId,
      name: form?.name,
      note: form?.note,
      username: encrUserName,
      serverUrl: form?.serverUrl,
      password: encrPassword,
      active: activation,
      matchingPassword: encrMatchingPassword
    };
    createEditData.execute(body);
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setActivation(data?.active + '' === 'true');
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>
          {isValidValue(data) ? t('Edit ') : t('Create New ')} {t('Jira Server Config')}
        </Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-5">
              <Input
                required={true}
                name="name"
                label={t('Name')}
                placeholder={t(`Enter`)}
                control={control}
                maxLength="255"
              />
              <div className="flex gap-4">
                <Input
                  required={true}
                  name="serverUrl"
                  label={t('Server Url')}
                  placeholder={t(`Enter`)}
                  control={control}
                  maxLength="255"
                />
                <Input
                  required={true}
                  name="username"
                  label={t('Username')}
                  placeholder={t(`Enter`)}
                  control={control}
                  maxLength="255"
                />
              </div>
              <div className="flex gap-4">
                <Password
                  placeholder={t(isValidValue(data) ? t('Enter new password') : t('Enter password'))}
                  required={!isValidValue(data)}
                  name="password"
                  label={isValidValue(data) ? t('New Password') : t('Password')}
                  control={control}
                />
                <Password
                  placeholder={isValidValue(data) ? t('Confirm new password') : t('Confirm password')}
                  required={!isValidValue(data)}
                  label={isValidValue(data) ? t('Confirm Password') : t('Confirm Password')}
                  name="matchingPassword"
                  control={control}
                />
              </div>
              <Toggle
                labelLeft
                labelText={t('Activation')}
                value={activation}
                control={control}
                name="active"
                passValue={(value) => setActivation(value)}
              />
              <TextArea
                placeholder={t('Enter your text here')}
                control={control}
                name="note"
                label={t('Note')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="jiraModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="jiraModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEditData?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default JiraModal;
