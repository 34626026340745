import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UseAllUsers from '../../../hooks/UseAllUsers';
import UseQueryProject from '../../../hooks/UseQueryProject';
import { SidePaneRightContent } from '../../../components/Layout';
import { Heading } from '../../../designSystem/DesignSystem';

import SelectedTableFilters from '../../../components/filtering/SelectedTableFilters';
import ProjectReactTable from '../../project/ProjectReactTable';

import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { ENTITY_TYPES } from '../../../constants';

const RelatedProjectsPage = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const users = UseAllUsers();
  const { projectId } = UseQueryProject('projectId');

  const [allFilters, setAllFilters] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const tableHeight = ref?.current?.clientHeight - ref?.current?.childNodes[0]?.clientHeight - 50;

  return (
    <div>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          {isProjectManager(projectId) && (
            <Heading.Group
              type="large"
              text={t('Related Projects')}
            />
          )}
        </SidePaneRightContent.Top>
        {projectId && (
          <>
            <SidePaneRightContent.Center>
              <SelectedTableFilters
                allFilters={{ ...allFilters, templateBy: projectId }}
                setAllFilters={setAllFilters}
                entityType={ENTITY_TYPES.PROJECT}
                setRefreshTable={setRefreshTable}
                refreshTable={refreshTable}
                showBulkButton={true}
              />
              <ProjectReactTable
                filter={{ ...allFilters, templateBy: projectId }}
                refreshStatus={refreshTable}
                setRefreshStatus={setRefreshTable}
                users={users}
                height={tableHeight}
                showCheckbox={true}
                isTemplate={false}
              />
            </SidePaneRightContent.Center>
          </>
        )}
      </SidePaneRightContent>
    </div>
  );
};

export default RelatedProjectsPage;
