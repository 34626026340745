import { isValidValue } from './Helper';
import { ENTITY_TYPES, PRIVILEGES } from '../constants';
import { logout } from '../api/ApiClient';

export const getSessionUser = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    const jwt = JSON.parse(atob(token.token.split('.')[1]));

    return {
      id: token?.userId,
      username: token?.username,
      systemAdmin: jwt.isSystemAdmin,
      canAddUser: jwt.isCanAddUser,
      projects: jwt.projects
    };
  }
  logout();
};

export const isOwner = (targetUserId) => {
  let sessionUser = getSessionUser();
  if (sessionUser) return targetUserId === sessionUser?.id || targetUserId === sessionUser?.username;
  return false;
};

/**
 * @description Check if user has privilege to delete attachment
 * @param {String} targetUserId
 * @returns {Boolean}
 */
export const isPriviligedDeleteAttachment = (targetUserId) => {
  return isSystemAdmin() || isOwner(targetUserId);
};

/**
 * @description Check if user has privilege to edit comment
 * @param {String} targetUserId
 * @returns {Boolean}
 */
export const isPriviligedEditComment = (targetUserId) => {
  return isOwner(targetUserId);
};

/**
 * @description Check if user has privilege to delete comment
 * @param {String} targetUserId
 * @returns {Boolean}
 */
export const isPriviligedDeleteComment = (targetUserId) => {
  return isSystemAdmin() || isOwner(targetUserId);
};

export const isSystemAdmin = () => {
  let sessionUser = getSessionUser();
  if (sessionUser) return sessionUser.systemAdmin;
  return false;
};

export const isCanAddUser = () => {
  let sessionUser = getSessionUser();
  if (sessionUser) return sessionUser.canAddUser;
  return false;
};

export const isHasUserViewRoles = (projectId) => {
  return (
    hasPrivilege(projectId, PRIVILEGES.VIEW_USER) ||
    hasPrivilege(projectId, PRIVILEGES.VIEW_ROLE) ||
    hasPrivilege(projectId, PRIVILEGES.VIEW_USERGROUP)
  );
};

const checkPrivilege = (projectId, targetPriv) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const jwt = token && JSON.parse(atob(token.token.split('.')[1]));

  if (projectId && jwt?.projects && jwt?.projects[projectId] && jwt?.projects[projectId].length > 0) {
    let priv = jwt.projects[projectId].find((item) => item === targetPriv);
    return isValidValue(priv);
  }
  return false;
};

/**
 * @description Check if the user is a project manager.
 * @param {String} projectId
 * @returns {Boolean}
 */
export const isProjectManager = (projectId) => {
  return isSystemAdmin() || checkPrivilege(projectId, PRIVILEGES.PROJECT_MANAGEMENT);
};

export const hasPrivilege = (projectId, targetPriv) => {
  if (!targetPriv) return false;
  if (!projectId) return false;
  if (isSystemAdmin() || isProjectManager(projectId)) return true;
  if (isValidValue(projectId)) return checkPrivilege(projectId, targetPriv);
};

export const isViewDefectPrivileged = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_DEFECT);
};

export const isViewReport = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_REPORT);
};

export const isViewReleasePrivileged = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_RELEASE);
};

export const isViewRequirementPrivileged = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_REQUIREMENT);
};

export const isViewTestSuite = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_SUITE);
};

export const isViewTestCycle = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_CYCLE);
};

export const isViewTestRun = (projectId) => {
  return isViewTestExecutionPage(projectId) || isViewTesterPage(projectId);
};

export const isViewTestExecutionPage = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_RUN_MANAGEMENT);
};

export const isViewTesterPage = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_EXECUTION);
};

export const isViewTestCase = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_CASE);
};

export const isViewTestFolder = (projectId) => {
  return hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_FOLDER);
};

export const isMemberOfProject = (projectId) => {
  if (isSystemAdmin() || isProjectManager(projectId)) return true;

  const token = JSON.parse(localStorage.getItem('token'));
  const jwt = JSON.parse(atob(token.token.split('.')[1]));

  if (isValidValue(projectId) && jwt?.projects && jwt?.projects[projectId] && jwt?.projects[projectId].length > 0) {
    return true;
  }
  return false;
};

export const isDeletePrivileged = (type, projectId) => {
  switch (type) {
    case ENTITY_TYPES.RELEASE:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_RELEASE);
    case ENTITY_TYPES.FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_FOLDER);
    case ENTITY_TYPES.REQUIREMENT:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_REQUIREMENT);
    case ENTITY_TYPES.TEST_CONTAINER:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_CONTAINER);
    case ENTITY_TYPES.TEST_CASE:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_CASE);
    case ENTITY_TYPES.TEST_CYCLE:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_CYCLE);
    case ENTITY_TYPES.TEST_SUITE:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_SUITE);
    case ENTITY_TYPES.TEST_RUN:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_RUN);
    case ENTITY_TYPES.TEST_EXECUTION:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_EXECUTION);
    case ENTITY_TYPES.DEFECT:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_DEFECT);
    case ENTITY_TYPES.TEST_FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_FOLDER);
    default:
      return false;
  }
};

export const isUpdatePrivileged = (type, projectId) => {
  switch (type) {
    case ENTITY_TYPES.RELEASE:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_RELEASE);
    case ENTITY_TYPES.FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_FOLDER);
    case ENTITY_TYPES.REQUIREMENT:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_REQUIREMENT);
    case ENTITY_TYPES.TEST_CONTAINER:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CONTAINER);
    case ENTITY_TYPES.TEST_CASE:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CASE);
    case ENTITY_TYPES.TEST_CYCLE:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE);
    case 'UPDATE_TEST_CYCLE_STATUS':
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE_STATUS);
    case ENTITY_TYPES.TEST_SUITE:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_SUITE);
    case ENTITY_TYPES.TEST_RUN:
      return hasPrivilege(projectId, PRIVILEGES.CREATE_UPDATE_TEST_RUN);
    case ENTITY_TYPES.TEST_EXECUTION:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_EXECUTION);
    case ENTITY_TYPES.DEFECT:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_STATUS);
    case 'UPDATE_DEFECT_TYPE':
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_TYPE);
    case 'UPDATE_DEFECT_PRIORITY':
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_PRIORITY);
    case ENTITY_TYPES.TEST_FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_FOLDER);
    case 'UPDATE_JIRA_BY_TEST_CYCLE_REQUIREMENT':
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_JIRA_BY_TEST_CYCLE_REQUIREMENT);
    case 'UPDATE_JIRA_DEFECT':
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_JIRA_DEFECT);
    case 'UPDATE_DEFECT':
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT);
    case 'UPDATE_JIRA_REQUIREMENT':
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_JIRA_REQUIREMENT);
    default:
      return false;
  }
};
/*
DefectModal ve TestCycleModal içerisinde lock yetkileri ve privilege değerlerine göre disabled durum ataması yapmak için kullanılmaktadır.
lock ifadelerin varlığı privilege değere baskın olduğu için öncelikli olarak bu ifadeler kontrol edilmiştir.
*/
export const checkDisabledStatus = (lockStatus, privilege, summaryUpdate = true) => {
  if (lockStatus == false && summaryUpdate == true) {
    if (privilege == true) {
      return false;
    }
  }
  return true;
};

export const statusUpdatePrivileged = (type, projectId) => {
  switch (type) {
    case ENTITY_TYPES.DEFECT:
      return (
        hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT) ||
        hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_STATUS) ||
        hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_PRIORITY) ||
        hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT_TYPE)
      );
    case ENTITY_TYPES.TEST_FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_FOLDER);
    case ENTITY_TYPES.TEST_CYCLE:
      return (
        hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE) ||
        hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE_STATUS)
      );
    case ENTITY_TYPES.TEST_SUITE:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_SUITE);
    case ENTITY_TYPES.TEST_CONTAINER:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CONTAINER);
    case ENTITY_TYPES.TEST_CASE:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CASE);
    case ENTITY_TYPES.FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_FOLDER);
    case ENTITY_TYPES.REQUIREMENT:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_REQUIREMENT);
    case ENTITY_TYPES.RELEASE:
      return hasPrivilege(projectId, PRIVILEGES.UPDATE_RELEASE);
  }
};

export const isMovePrivileged = (type, projectId) => {
  switch (type) {
    case ENTITY_TYPES.FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.MOVE_FOLDER);
    case ENTITY_TYPES.REQUIREMENT:
      return hasPrivilege(projectId, PRIVILEGES.MOVE_REQUIREMENT);
    case ENTITY_TYPES.TEST_CONTAINER:
      return hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_CONTAINER);
    case ENTITY_TYPES.TEST_CASE:
      return hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_CASE);
    case ENTITY_TYPES.TEST_CYCLE:
      return hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_CYCLE);
    case ENTITY_TYPES.TEST_SUITE:
      return hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_SUITE);
    case ENTITY_TYPES.TEST_RUN:
      return hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_RUN);
    case ENTITY_TYPES.TEST_FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_FOLDER);
    default:
      return false;
  }
};

export const isImportPrivileged = (type, projectId) => {
  switch (type) {
    case ENTITY_TYPES.TEST_CASE:
      return hasPrivilege(projectId, PRIVILEGES.IMPORT_TEST_CASE);
    case ENTITY_TYPES.REQUIREMENT:
      return hasPrivilege(projectId, PRIVILEGES.IMPORT_REQUIREMENT);
    default:
      return false;
  }
};

export const isExportPrivileged = (type, projectId) => {
  switch (type) {
    case ENTITY_TYPES.REQUIREMENT:
      return hasPrivilege(projectId, PRIVILEGES.EXPORT_REQUIREMENT);
    case ENTITY_TYPES.TEST_CASE:
      return hasPrivilege(projectId, PRIVILEGES.EXPORT_TEST_CASE);
    case ENTITY_TYPES.TEST_RUN:
      return hasPrivilege(projectId, PRIVILEGES.EXPORT_RUN);
    case ENTITY_TYPES.DEFECT:
      return hasPrivilege(projectId, PRIVILEGES.EXPORT_DEFECT);
    default:
      return false;
  }
};

export const isClonePrivileged = (type, projectId) => {
  switch (type) {
    case ENTITY_TYPES.FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_FOLDER);
    case ENTITY_TYPES.REQUIREMENT:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_REQUIREMENT);
    case ENTITY_TYPES.TEST_CONTAINER:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_TEST_CONTAINER);
    case ENTITY_TYPES.TEST_CASE:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_TEST_CASE);
    case ENTITY_TYPES.TEST_CYCLE:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_TEST_CYCLE);
    case ENTITY_TYPES.TEST_SUITE:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_TEST_SUITE);
    case ENTITY_TYPES.TEST_RUN:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_TEST_RUN);
    case ENTITY_TYPES.DEFECT:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_DEFECT);
    case ENTITY_TYPES.TEST_FOLDER:
      return hasPrivilege(projectId, PRIVILEGES.CLONE_TEST_FOLDER);
    default:
      return false;
  }
};

/**
 * @description checks for cases where you do not have the necessary permissions to move files
 * @param {String} dragType
 * @param {String} projectId
 * @returns {String}
 */
export const dropPrivilegeCheck = (dragType, projectId) => {
  if (dragType === ENTITY_TYPES.FOLDER && !hasPrivilege(projectId, PRIVILEGES.MOVE_FOLDER))
    return 'You are not allowed to move folder';
  if (dragType === ENTITY_TYPES.FOLDER && !hasPrivilege(projectId, PRIVILEGES.MOVE_REQUIREMENT))
    return 'You are not allowed to move requirement';
  if (dragType === ENTITY_TYPES.REQUIREMENT && !hasPrivilege(projectId, PRIVILEGES.MOVE_REQUIREMENT))
    return 'You are not allowed to move requirement';
  if (dragType === ENTITY_TYPES.TEST_CONTAINER && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_CONTAINER))
    return 'You are not allowed to move test container';
  if (dragType === ENTITY_TYPES.TEST_CONTAINER && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_CASE))
    return 'You are not allowed to move test case';
  if (dragType === ENTITY_TYPES.TEST_CASE && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_CASE))
    return 'You are not allowed to move test case';
  if (dragType === ENTITY_TYPES.TEST_FOLDER && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_FOLDER))
    return 'You are not allowed to move test folder';
  if (dragType === ENTITY_TYPES.TEST_FOLDER && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_CYCLE))
    return 'You are not allowed to move test cycle';
  if (dragType === ENTITY_TYPES.TEST_FOLDER && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_SUITE))
    return 'You are not allowed to move test suite';
  if (dragType === ENTITY_TYPES.TEST_CYCLE && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_CYCLE))
    return 'You are not allowed to move test cycle';
  if (dragType === ENTITY_TYPES.TEST_CYCLE && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_SUITE))
    return 'You are not allowed to move test suite';
  if (dragType === ENTITY_TYPES.TEST_SUITE && !hasPrivilege(projectId, PRIVILEGES.MOVE_TEST_SUITE))
    return 'You are not allowed to move test suite';
};
