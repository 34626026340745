import React, { useEffect, useState } from 'react';
import { fillChartData, getChartHeading } from '../helper/DashboardHelper';
import { ResponsiveSunburst } from '@nivo/sunburst';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const CustomSunburstChart = ({ chart, projectId, dateFilter, projectConfig }) => {
  const [data, setData] = useState([]);
  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      fillChartData(dataLoader?.data, chart, setData, projectId, null);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);

  return (
    <>
      {getChartHeading(chart, projectConfig)}
      <ResponsiveSunburst
        data={data}
        height={300}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        id="label"
        value="count"
        valueFormat=" >-"
        cornerRadius={2}
        borderWidth={9}
        borderColor={{ theme: 'background' }}
        colors={{ scheme: 'pastel1' }}
        inheritColorFromParent={false}
        childColor={{ from: 'color', modifiers: [['brighter', 0.1]] }}
        enableArcLabels={true}
        arcLabelsSkipAngle={0}
        arcLabel={function (e) {
          return e.id + ' (' + e.value + ')';
        }}
        arcLabelsRadiusOffset={1}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
        motionConfig="slow"
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 110,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 60,
            itemHeight: 14,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle'
          }
        ]}
      />
    </>
  );
};

export default CustomSunburstChart;

// 'left-to-right'
