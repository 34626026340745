import { axios } from './ApiClient';
const CYCLE_PATH = '/api/test-cycles';

class TestCycleApi {
  async getTestCycle(data) {
    const resp = await axios.get(`${CYCLE_PATH}/${data.id}`);
    return resp.data;
  }

  async getAll(data) {
    const resp = await axios.get(`${CYCLE_PATH}`, { params: data });
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${CYCLE_PATH}/`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${CYCLE_PATH}/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${CYCLE_PATH}/${data.id}`);
    return resp.data;
  }

  async clone(data) {
    const resp = await axios.post(`${CYCLE_PATH}/clone`, data);
    return resp.data;
  }

  async getLockActions() {
    const resp = await axios.get(`${CYCLE_PATH}/lock-actions`);
    return resp.data;
  }

  async getParens(id) {
    const resp = await axios.get(`${CYCLE_PATH}/${id}/parents`);
    return resp.data;
  }

  async updateTestCycleParent(id, body) {
    const resp = await axios.put(`${CYCLE_PATH}/${id}/parent`, body);
    return resp.data;
  }

  async updateTestCycleWithEmptyParent(id) {
    const resp = await axios.put(`${CYCLE_PATH}/${id}/empty-parent`, {});
    return resp.data;
  }

  async updateTestCycleFolder(id, body) {
    const resp = await axios.put(`${CYCLE_PATH}/${id}/test-folder`, body);
    return resp.data;
  }

  async updateTestCycleRelease(id, body) {
    const resp = await axios.put(`${CYCLE_PATH}/${id}/release`, body);
    return resp.data;
  }

  async getCycleNotCompletedTaskCount(id) {
    const resp = await axios.get(`${CYCLE_PATH}/${id}/notCompletedTaskCount`);
    return resp.data;
  }

  async getTree(data) {
    const resp = await axios.get(`${CYCLE_PATH}/tree`, { params: data });
    return resp.data;
  }

  async editStatus(data) {
    const resp = await axios.put(`${CYCLE_PATH}/${data?.id}/status`, data);
    return resp.data;
  }

  async getTreeData(params) {
    const resp = await axios.get(`${CYCLE_PATH}/tree`, { params });
    return resp.data;
  }
}

export default new TestCycleApi();
