import { PROJECT_ACTION_KEYS, SYSTEM_ACTION_KEYS } from './Actions';

export function reducer(state, action) {
  switch (action.type) {
    case PROJECT_ACTION_KEYS.UPDATED_SELECTED_PROJECT:
      return { ...state, project: action.project };
    case PROJECT_ACTION_KEYS.UPDATED_PROJECT_CONFIG:
      return {
        ...state,
        config: action.config
      };
    case PROJECT_ACTION_KEYS.USER_TABLE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.entity]: {
            values: action.values,
            projectId: action.projectId
          }
        }
      };
    case PROJECT_ACTION_KEYS.USER_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          [action.entity]: {
            values: action.values,
            projectId: action.projectId
          }
        }
      };
    case PROJECT_ACTION_KEYS.ENTITY_ALL_FIELDS:
      return {
        ...state,
        entityFields: {
          ...state.entityFields,
          [action.entity]: {
            values: action.values,
            projectId: action.projectId
          }
        }
      };
    case PROJECT_ACTION_KEYS.UPDATED_CUSTOM_FIELDS: {
      return {
        ...state,
        customFields: {
          ...state.customFields,
          [action.entity]: {
            values: action.values,
            projectId: action.projectId
          }
        }
      };
    }
    case PROJECT_ACTION_KEYS.UPDATED_SYSTEM_FIELD: {
      return {
        ...state,
        systemFields: {
          ...state.systemFields,
          [action.entity]: {
            ...state.systemFields[action.entity],
            [action.field]: {
              values: action.values,
              projectId: action.projectId
            }
          }
        }
      };
    }

    case PROJECT_ACTION_KEYS.UPDATED_SYSTEM_FIELD_PARAMS: {
      return {
        ...state,
        systemFieldParams: {
          ...state.systemFieldParams,
          [action.entity]: {
            values: action.values,
            projectId: action.projectId
          }
        }
      };
    }
    case PROJECT_ACTION_KEYS.UPDATED_PROJECT_USERS:
      return {
        ...state,
        users: {
          projectId: action.projectId,
          values: action.values
        }
      };
    case PROJECT_ACTION_KEYS.SAVE_RUN_STATUSES:
      return {
        ...state,
        runStatuses: action.values
      };
    case PROJECT_ACTION_KEYS.SET_ACTIVE_SUBNAV_SETTINGS:
      return {
        ...state,
        settingsActiveMenuItem: action.value
      };
    case PROJECT_ACTION_KEYS.UPDATE_SELECTED_COLUMNS:
      return {
        ...state,
        tablePrefences: {
          ...state.tablePrefences,
          [action.entityType]: {
            ...state.tablePrefences[action.entity],
            [action.projectId]: action.values
          }
        }
      };
    case PROJECT_ACTION_KEYS.SAVED_JIRA_CONFIG:
      return {
        ...state,
        jiraConfig: action.jiraConfig
      };
    case PROJECT_ACTION_KEYS.ADD_REQUEST: {
      const activeRequests = [...state.activeRequests];
      const isExist = activeRequests.some((item) => item === action.data);
      if (!isExist) {
        activeRequests.push(action.data);
      }
      return {
        ...state,
        activeRequests
      };
    }
    case PROJECT_ACTION_KEYS.REMOVE_REQUEST: {
      const activeRequests = state.activeRequests.filter(action.predicate);
      return {
        ...state,
        activeRequests
      };
    }
    case PROJECT_ACTION_KEYS.UPDATED_PROJECT_USER_GROUPS: {
      return {
        ...state,
        projectUserGroups: action.projectUserGroups
      };
    }
    case PROJECT_ACTION_KEYS.SET_SELECTED_ROWS: {
      return {
        ...state,
        selectedRows: action.selectedRows
      };
    }
    case PROJECT_ACTION_KEYS.SET_SELECTED_RUN_TESTCASES: {
      return {
        ...state,
        selectedRunTestCases: action.selectedRunTestCases
      };
    }
    case PROJECT_ACTION_KEYS.SET_REFRESH_DATA: {
      return {
        ...state,
        refreshData: action.refreshData
      };
    }
    case SYSTEM_ACTION_KEYS.UPDATED_SYSTEM_PARAMS: {
      return {
        ...state,
        systemParams: action.systemParams
      };
    }
    case PROJECT_ACTION_KEYS.SET_REFRESH_TREE: {
      return {
        ...state,
        refreshTree: action.refreshTree
      };
    }
    case PROJECT_ACTION_KEYS.WEB_SOCKET_UPDATE: {
      return {
        ...state,
        webSocket: action.webSocket,
        wsStatus: action.wsStatus
      };
    }
    case PROJECT_ACTION_KEYS.SET_SELECTED_REPORT: {
      return {
        ...state,
        selectedReport: action.selectedReport
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
