import { useApp } from '../context/AppContext';
import { isValidList } from '../helpers/Helper';

const UseSelectRow = () => {
  const app = useApp();

  let showButton = false;

  if (isValidList(app?.selectedRows)) {
    showButton = true;
  }
  return { showButton };
};

export default UseSelectRow;
