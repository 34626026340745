import { useEffect, useMemo } from 'react';
import { useApp, useAppDispatch } from '../context/AppContext';
import { useApi } from './UseApi';
import SystemParamApi from '../api/SystemParamApi';
import { addRequest, saveSystemParameterConfig } from '../context/ProjectActions';
import { uniqueId } from 'lodash-es';

const UseSystemParamsConfig = (projectId) => {
  const app = useApp();
  const dispatch = useAppDispatch();
  const systemParamsConfig = app?.systemParams;
  const activeRequests = app?.activeRequests || [];

  const id = useMemo(() => uniqueId(), []);

  useEffect(() => {
    if (projectId && systemParamsConfig) dispatch(addRequest({ order: id, type: 'systemParamsConfig', projectId }));
  }, [projectId]);

  const getSystemParamsConfigLoader = useApi({
    name: 'Getting system params config',
    api: SystemParamApi.getAllSystemParams,
    onSuccess: () => {
      dispatch(saveSystemParameterConfig(getSystemParamsConfigLoader?.data));
    },
    onError: () => {}
  });

  useEffect(() => {
    if (activeRequests?.length) {
      const allRequestsForEntity = activeRequests
        .filter((item) => item.projectId === projectId && item.type === 'systemParamsConfig')
        .map((item) => item.order);
      if ((allRequestsForEntity || []).indexOf(id) === 0) {
        app?.systemParams?.length === 0 && getSystemParamsConfigLoader.execute();
      }
    }
  }, [activeRequests]);

  return systemParamsConfig;
};

export default UseSystemParamsConfig;
