import { axios } from './ApiClient';

const TRE_PATH = '/api/test-run-executions';

class TestRunExecutionApi {
  async getAll(params) {
    const resp = await axios.get(`${TRE_PATH}`, { params });
    return resp.data;
  }

  async get(id) {
    const resp = await axios.get(`${TRE_PATH}/${id}`);
    return resp.data;
  }

  async quickExecute(data) {
    const resp = await axios.post(`${TRE_PATH}/quick`, data);
    return resp.data;
  }

  async quickExecuteWithAttachment({ body, config }) {
    const resp = await axios.post(`${TRE_PATH}/quickWithAttachment`, body, config);
    return resp.data;
  }

  async bulkExecution(data) {
    const resp = await axios.post(`${TRE_PATH}/bulkquick`, data);
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${TRE_PATH}`, data);
    return resp.data;
  }

  async update({ id, data }) {
    const resp = await axios.put(`${TRE_PATH}/${id}`, data);
    return resp.data;
  }

  async complete(id) {
    const resp = await axios.put(`${TRE_PATH}/${id}/complete`, {});
    return resp.data;
  }

  async getSteps(id) {
    const resp = await axios.get(`${TRE_PATH}/${id}/step-executions`);
    return resp.data;
  }

  async getExecutionAttachments(id) {
    const resp = await axios.get(`${TRE_PATH}/${id}/attachments`);
    return resp.data;
  }

  async delete(id) {
    const resp = await axios.delete(`${TRE_PATH}/${id}`);
    return resp.data;
  }
}

export default new TestRunExecutionApi();
