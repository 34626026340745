import React, { useMemo } from 'react';
// HOOKS & API
import UseProjectUsers from '../../../../hooks/UseProjectUsers';
import UseQueryProject from '../../../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';
// DESIGN & COMPONENTS
import { PencilLine, Trash } from 'phosphor-react';
import BasicTable from '../../../../components/table/BasicTable';
// HELPERS
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../../helpers/ButtonConstants';
import {
  convertToTableData,
  disableResizingForColumns,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../../helpers/TableHelper';

const ParametersTable = ({
  parameters,
  isLoading,
  setSelectedItem,
  setEditParameterModalOpen,
  setDeleteConfirmationModalOpen
}) => {
  const { t } = useTranslation();

  const { projectId } = UseQueryProject('projectId');
  const users = UseProjectUsers(projectId);

  const optionsFn = (row) => {
    return [
      {
        name: t('Edit'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(parameters, row, setSelectedItem);
          setEditParameterModalOpen(true);
        }
      },
      {
        name: t('Delete'),
        icon: (weight) => (
          <Trash
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(parameters, row, setSelectedItem);
          setDeleteConfirmationModalOpen(true);
        }
      }
    ];
  };

  const { data, tableColumns } = useMemo(() => {
    let tableData,
      columns = [];
    if (parameters) {
      tableData = convertToTableData(parameters, 'parameter', users, null);
      columns = generateTableColumns(tableData, optionsFn);
    }
    return {
      data: tableData,
      tableColumns: columns
    };
  }, [parameters]);

  let columns = disableResizingForColumns(tableColumns, [{ accessor: 'actions', width: 30 }]);
  columns = translateColumns(columns, t);

  return (
    <>
      <div className="mt-6">
        <BasicTable
          columns={columns}
          data={data}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default ParametersTable;
