import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// Api and Hook Imports
import UseQueryProject from '../../hooks/UseQueryProject';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import { useApi } from '../../hooks/UseApi';
import FolderApi from '../../api/FolderApi';
import RequirementApi from '../../api/RequirementApi';
import ContainerApi from '../../api/ContainerApi';
import TestCaseApi from '../../api/TestCaseApi';
import TestFolderApi from '../../api/TestFolderApi';
import TestCycleApi from '../../api/TestCycleApi';
import TestSuiteApi from '../../api/TestSuiteApi';
import { useTranslation } from 'react-i18next';
// Design System Imports
import { Modal } from '../modal';
import { Input, Toggle, Button } from '../../designSystem/DesignSystem';
//Helper Imports
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { isValidValue, editModalMapping } from '../../helpers/Helper';
import { allCheckboxList } from '../../helpers/CloneHelper';
import { ENTITY_TYPES } from '../../constants';
import TestRunApi from '../../api/TestRunApi';
import DefectApi from '../../api/DefectApi';
import { useApp, useAppDispatch } from '../../context/AppContext';
import Markdown from '../markdown/Markdown';
import { setRefreshTree } from '../../context/ProjectActions';

const CloneModal = ({ isOpen, data, closeModal, entityType, isBulk, submitCallBack }) => {
  const app = useApp();
  const dispatch = useAppDispatch();

  const selectedRows = app?.selectedRows.map((item) => item.original.id);

  const { t } = useTranslation();
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const [checkboxList, setCheckboxList] = useState([]);
  const [loading, setLoading] = useState(false);
  // !todo: bir bak
  const cloneApi = () => {
    switch (entityType) {
      case ENTITY_TYPES.FOLDER:
        return FolderApi.clone;
      case ENTITY_TYPES.REQUIREMENT:
        return RequirementApi.clone;
      case ENTITY_TYPES.TEST_CONTAINER:
        return ContainerApi.clone;
      case ENTITY_TYPES.TEST_CASE:
        return TestCaseApi.clone;
      case ENTITY_TYPES.TEST_FOLDER:
        return TestFolderApi.clone;
      case ENTITY_TYPES.TEST_CYCLE:
        return TestCycleApi.clone;
      case ENTITY_TYPES.TEST_SUITE:
        return TestSuiteApi.clone;
      case ENTITY_TYPES.TEST_RUN:
        return TestRunApi.clone;
      case ENTITY_TYPES.DEFECT:
        return DefectApi.clone;
      default:
        return null;
    }
  };

  const clone = useApi({
    name: 'clone item',
    api: cloneApi(),
    onSuccess: () => {
      closeModal(clone?.data);
      showSuccessMes(INFO_MESSAGES.clone);
      setLoading(false);
    },
    onError: (err) => {
      showErrorMes(err);
      setLoading(false);
    }
  });

  const bulkClone = useApi({
    name: 'bulk clone',
    api: TestCaseApi.bulkClone,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.clone);
      closeModal();
      submitCallBack();
      dispatch(setRefreshTree(true));
      setLoading(false);
    },
    onError: (err) => {
      showErrorMes(err);
      setLoading(false);
    }
  });

  const onSubmit = (form) => {
    setLoading(true);
    let body = {
      withChilds: form?.withChilds,
      withCustomFields: form?.withCustomFields,
      withRequirementTestCases: form?.withRequirementTestCases,
      withRequirementSystemFields: form?.withRequirementSystemFields,
      withRequirementCustomFields: form?.withRequirementCustomFields,
      withSystemFields: form?.withSystemFields,
      withRequirements: form?.withRequirements,
      withTestCaseCustomFields: form?.withTestCaseCustomFields,
      withTestCycles: form?.withTestCycles,
      withExecutions: form?.withExecutions,
      withTestRuns: form?.withTestRuns,
      withSteps: form?.withSteps
      // ...(isValidValue(form?.withLinks) && { "withLinks": form?.withLinks, }),
    };
    if (isBulk) {
      body = {
        ...body,
        ids: selectedRows
      };
      bulkClone.execute(body);
    } else {
      body = {
        ...body,
        id: data?.id,
        name: form?.name,
        description: form?.description,
        projectId: projectId
      };

      clone.execute(body);
    }
  };

  const fillCheckbox = (isDefault = true) => {
    const arr = allCheckboxList(entityType, projectConfig).filter((f) => f.entityTypes.includes(entityType));
    setCheckboxList(arr);

    if (isDefault) {
      checkboxList.forEach((item) => {
        setValue(item.fieldName, item.defaultValue);
      });
    }
  };

  useEffect(() => {
    if (isValidValue(data)) {
      const mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, []);

  useEffect(() => {
    fillCheckbox();
  }, [entityType]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        {isBulk ? (
          <Modal.Title subTitle={t('selected_rows_total_number', { selectedRows: selectedRows?.length })}>
            {t('Confirm Bulk Clone')}
          </Modal.Title>
        ) : (
          <Modal.Title subTitle={data?.code}>{t('Clone From')}</Modal.Title>
        )}
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h="md">
            <div className="flex flex-grow flex-col gap-5">
              {!isBulk &&
                (entityType !== ENTITY_TYPES.TEST_RUN && entityType !== ENTITY_TYPES.DEFECT ? (
                  <Input
                    name="name"
                    label={t('Name')}
                    placeholder={t('Enter a Name')}
                    control={control}
                    minLength="3"
                    maxLength="255"
                  />
                ) : (
                  <Input
                    name="name"
                    label={t('Summary')}
                    placeholder={t('Enter a Summary')}
                    control={control}
                    {...(entityType === ENTITY_TYPES.TEST_RUN && { maxLength: '255' })}
                  />
                ))}
              {checkboxList.map((item, index) => {
                return (
                  <Toggle
                    key={index}
                    labelRight
                    labelText={item.label}
                    value={getValues(item?.fieldName)}
                    control={control}
                    name={item.fieldName}
                    passValue={(e) => {
                      setValue(item.fieldName, e);
                      fillCheckbox(false);
                    }}
                  />
                );
              })}
              {!isBulk && entityType !== ENTITY_TYPES.TEST_RUN && (
                <Markdown
                  control={control}
                  name="description"
                  label={t('Description')}
                />
              )}
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="clone-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => closeModal()}
            />
            <Button
              id="clone-submit-button"
              primary
              type="submit"
              loading={loading}
              text={t('Submit')}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};
export default CloneModal;
