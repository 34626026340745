import React, { useState, forwardRef, useEffect } from 'react';
// DESIGN SYSTEM IMPORTS
import { Heading, Loader, Body } from '../../designSystem/DesignSystem';
import { isValidList, isValidValue } from '../../helpers/Helper';
import { isJiraContentValid } from '../../helpers/jira/JiraContentHelper';
import { SidePaneRightContent } from '../Layout';
// API & HOOKS IMPORTS
import UseJiraConfig from '../../hooks/UseJiraConfig';
import { useTranslation } from 'react-i18next';
// COMPONENT IMPORTS
import EntityDetails from '../entityDetails/EntityDetails';
import EntityMetaData from '../entityDetails/EntityMetaData';
import CommonEntityAdvancedTab from './CommonEntityAdvancedTab';
import EntityDescription from '../entityDetails/EntityDescription';
import EntityJiraLink from '../entityDetails/EntityJiraLink';

/* eslint-disable react/display-name */
const CustomEntityDetail = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { config } = props;
  const {
    entityType,
    users,
    loading,
    dataForm,
    submitCallBack,
    submitCallBackAfterDeletion,
    submitCallBackAfterClone,
    submitCallBackVersion,
    customFields,
    getTopContent,
    extraButtons,
    closeModal,
    projectId
  } = config;

  const localStateOfPane = localStorage.getItem('detailPaneIsOpen');
  const [rightContainerOpen, setRightContainerOpen] = useState(
    isValidValue(localStateOfPane) && localStateOfPane + '' === 'false' ? false : true
  ); //default true
  const [descriptionWidth, setDescriptionWidth] = useState(ref?.current?.childNodes[0]?.clientWidth);

  const jiraConfig = UseJiraConfig(projectId);
  const tableHeight =
    ref?.current?.clientHeight -
    ref?.current?.childNodes[0].clientHeight -
    ref?.current?.childNodes[1].clientHeight -
    200;

  useEffect(() => {
    if (ref?.current) setDescriptionWidth(ref?.current?.childNodes[0]?.clientWidth);
  }, [rightContainerOpen, ref?.current]);

  if (!loading && dataForm) {
    return (
      <>
        <SidePaneRightContent.Top>
          <EntityDetails
            users={users}
            entityType={entityType}
            loading={loading}
            data={dataForm}
            submitCallBack={submitCallBack}
            submitCallBackAfterDeletion={submitCallBackAfterDeletion}
            submitCallBackAfterClone={(dd) => submitCallBackAfterClone(dd)}
            submitCallBackVersion={submitCallBackVersion}
            extraButtons={extraButtons}
            closeModal={closeModal}
            jiraConfig={jiraConfig}
          />
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center
            ref={ref}
            isModal={false}
            paneIsOpen={rightContainerOpen}
          >
            <>
              <EntityDescription
                dataForm={dataForm}
                jiraConfig={jiraConfig}
                rightContainerOpen={rightContainerOpen}
                descriptionWidth={descriptionWidth}
              />
              {isJiraContentValid(jiraConfig, dataForm) && isValidList(dataForm?.jiraContent?.fields?.issuelinks) && (
                <EntityJiraLink
                  issuelinks={dataForm.jiraContent.fields.issuelinks}
                  jiraConfig={jiraConfig}
                ></EntityJiraLink>
              )}

              <div className="mb-4 mt-2 flex gap-4">
                {getTopContent && getTopContent()}
                {isValidValue(config?.dataForm?.preconditions) && (
                  <div className=" flex flex-1 grow flex-row items-center">
                    <div className={`h-full grow flex-row items-center rounded border border-gray-200 bg-gray-50 p-4`}>
                      <Heading
                        className="w-36"
                        type="small"
                        text={t('Preconditions')}
                      />
                      <div className={'max-h-32 overflow-y-auto'}>
                        <Body
                          type="medium"
                          text={config?.dataForm?.preconditions}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
            <CommonEntityAdvancedTab
              tableHeight={tableHeight}
              config={config}
            />
          </SidePaneRightContent.Center>
          <SidePaneRightContent.Pane
            isModal={false}
            open={rightContainerOpen}
            setOpen={(state) => {
              setRightContainerOpen(state);
              localStorage.setItem('detailPaneIsOpen', state);
            }}
          >
            <EntityMetaData
              users={users}
              entityType={entityType}
              data={dataForm}
              customFields={customFields}
              projectId={projectId}
              jiraConfig={jiraConfig}
            />
          </SidePaneRightContent.Pane>
        </SidePaneRightContent.Main>
      </>
    );
  } else {
    return (
      <>
        <div style={{ marginTop: '20%' }}>
          <Loader />
        </div>
      </>
    );
  }
});

export default CustomEntityDetail;
