import { useFloating, offset, flip, shift } from '@floating-ui/react-dom';
import { useEffect, useRef, useState } from 'react';
import { dropdownIcon, dropdownOptions, IconComponent } from '../../designSystem/Dropdown/Dropdown.Utils';
import { classNames, findId, isValidValue } from '../../helpers/Helper';
import * as Icons from 'phosphor-react';
import { useElementSize, useOnClickOutside } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
const DropdownPagination = ({
  disabled,
  icon,
  buttonH,
  textSize,
  clearable,
  onClear,
  selectedItem,
  passValue,
  placeholder,
  options,
  isSort = false
}) => {
  const mapOptions = dropdownOptions(options, isSort);
  const { t } = useTranslation();
  const [buttonRef, { width }] = useElementSize();
  const handleClickOutside = () => setFloatingElement(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, handleClickOutside);
  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom',
    strategy: 'absolute',
    middleware: [
      offset({
        crossAxis: 0,
        mainAxis: 10
      }),
      flip(),
      shift()
    ]
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [floatingElement, setFloatingElement] = useState(false);

  const isSelected = (option) => selectedOption === option.name;

  const handleSelection = (option) => {
    setFloatingElement(false);
    setSelectedOption(option.name);
    if (passValue) passValue(findId(mapOptions, option.name));
  };

  useEffect(() => {
    setSelectedOption(selectedItem ? selectedItem?.name : null);
  }, [selectedItem]);

  return (
    <>
      <div
        ref={dropdownRef}
        className="flex flex-grow flex-col"
      >
        <div
          ref={buttonRef}
          className=" flex-grow"
        >
          <div
            onClick={() => setFloatingElement(floatingElement ? false : true)}
            ref={reference}
            className={classNames(
              'rounded-md border border-gray-300 bg-white',
              'flex w-full flex-grow items-center justify-between py-2 pl-3 pr-3',
              'transition duration-150 ease-in-out',
              'focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0',
              'text-left focus:outline-none sm:text-sm sm:leading-5',
              disabled && 'border-gray-100 bg-gray-50 text-gray-400 placeholder-gray-300',
              buttonH ? buttonH : ' h-9 ',
              textSize ? textSize : ' text-sm '
            )}
          >
            {IconComponent(icon) !== null && (
              <span className="mr-3">
                {' '}
                <IconComponent size={12} />{' '}
              </span>
            )}
            <span className={' truncate ' + isValidValue(textSize) ? textSize : ' mr-2.5 text-xs'}>
              {isValidValue(selectedOption) ? t(selectedOption) : t(placeholder)}
            </span>
            {dropdownIcon(disabled, clearable, selectedOption, setSelectedOption, passValue, onClear)}
          </div>
          {floatingElement && (
            <div
              ref={floating}
              style={{
                width,
                position: strategy,
                top: y,
                left: x
              }}
              className={classNames(
                'absolute',
                'z-9999',
                'max-h-56 overflow-auto rounded-md border border-gray-300 bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              )}
            >
              {mapOptions.map((option) => {
                return (
                  <div
                    key={option?.id}
                    value={option?.name}
                    disabled={option?.disabled}
                    onClick={() => handleSelection(option)}
                  >
                    <div
                      className={classNames(
                        'flex h-9 flex-grow cursor-pointer select-none justify-between py-2 pl-3 pr-4',
                        'hover:bg-primary-600 hover:text-white',
                        isSelected(option) ? 'bg-primary-700 text-white' : 'text-gray-900',
                        option.disabled && 'bg-gray-50 text-gray-400'
                      )}
                    >
                      <span
                        className={classNames('block truncate', isSelected(option) ? 'font-semibold' : 'font-normal')}
                      >
                        {option?.name}
                      </span>
                      {isSelected(option) && (
                        <span
                          className={classNames(
                            'flex items-center pl-1.5',
                            isSelected(option) ? 'text-white' : 'text-primary-500'
                          )}
                        >
                          <Icons.Check
                            className="h-5 w-5"
                            weight="duotone"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { DropdownPagination };
