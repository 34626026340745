import { axios } from './ApiClient';

class LoginApi {
  async Login(data) {
    const resp = await axios.post('/api/auth/signin', data);
    return resp;
  }

  async logout(data) {
    const resp = await axios.put('/api/auth/logout', data);
    return resp;
  }
}

export default new LoginApi();
