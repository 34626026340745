import jp from 'jsonpath';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import PercentageBar from '../components/general/PercentageBar';
import { StatusPill } from '../designSystem/DesignSystem';
import { getStatusBarOptionsDynamic, getUserFirstLastNameById, translatedDateFormat } from '../helpers/Helper';
import { buildParam } from '../helpers/TableHelper';
import { useTranslation } from 'react-i18next';

const UseTableHelper = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const openModal = (name, value) => {
    searchParams.delete(name);
    searchParams.append(name, value);
    setSearchParams(searchParams);
  };

  const createTableData = (data, columns = [], projectId, showContribution, entityType, users) => {
    let body = {};

    if (data?.id) body['id'] = data?.id;

    columns.forEach((c) => {
      const values = jp.query(data, c?.path) || [];
      if (c?.type === 'link') {
        const page = c?.page;
        const pageParams = c?.pageParams;
        body[c?.key] = (
          <>
            {values.map((v, index) => {
              return (
                <>
                  {index > 0 && <span className="text-gray-500">, </span>}
                  <Link
                    className="elipsis text-blue-900"
                    to={`/${page}?${buildParam(data, pageParams, projectId, index)}`}
                  >
                    {v}
                  </Link>
                </>
              );
            })}
          </>
        );
      } else if (c?.type === 'open-modal') {
        const handleClick = (e) => {
          e.preventDefault();

          const paramName = c?.params?.name;
          const paramValue = (jp.query(data, c?.params?.value) || []).toString();

          openModal(paramName, paramValue);
        };

        body[c?.key] = (
          <Link
            className="elipsis whitespace-nowrap text-blue-900 "
            onClick={handleClick}
            to="/"
          >
            {values.toString()}
          </Link>
        );
      } else if (c?.type === 'contribution') {
        if (showContribution === true)
          body[c?.key] = values && values.length ? `% ${Number.parseFloat(values.toString()).toFixed(2)}` : '-';
      } else if (c?.type === 'coverage') {
        body[c?.key] = <PercentageBar datas={getStatusBarOptionsDynamic(values[0])} />;
      } else if (c?.type === 'status') {
        body[c?.key] = (
          <StatusPill
            value={values.toString()}
            entityType={entityType}
          />
        );
      } else if (c?.type === 'date') {
        body[c?.key] = values.toString() ? translatedDateFormat(values.toString(), 'DD MMM YYYY HH:mm', t) : '-';
      } else if (c?.type === 'user') {
        body[c?.key] = getUserFirstLastNameById(users, values.toString());
      } else {
        body[c?.key] = values.toString();
      }
    });

    if (data?.customFields) Object.keys(data?.customFields).forEach((key) => (body[key] = data?.customFields[key]));
    return body;
  };
  return { createTableData, openModal };
};

export default UseTableHelper;
