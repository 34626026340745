import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, H5, H6, Heading } from '../../../designSystem/DesignSystem';
import { useApi } from '../../../hooks/UseApi';
import SystemParamApi from '../../../api/SystemParamApi';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import _ from 'lodash';
import { SidePaneRightContent } from '../../../components/Layout';
import { ENTITY_TYPES } from '../../../constants';
import ExportFileApi from '../../../api/ExportFileApi';

export default function DefaultExportFieldsEditPage({ entityType }) {
  const { t } = useTranslation();

  const [entityExportFields, setEntityExportFields] = useState({});
  const [exportTemplate, setExportTemplate] = useState([]);
  const [clear, setClear] = useState(false);

  const getEntityExportFields = useApi({
    name: 'Get entity export fields',
    api: SystemParamApi.getAllSystemParams,
    onSuccess: () => {
      setEntityExportFields(JSON.parse(getEntityExportFieldParamValueFromSystemParams(entityType)?.paramVal));
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const getExportFieldDefaultTemplate = useApi({
    name: 'Get export field default template',
    api: ExportFileApi.getExportTemplateWithoutCustomFields,
    onSuccess: () => {
      setExportTemplate(getExportFieldDefaultTemplate.data);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const updateEntityExportFields = useApi({
    name: 'Update entity export fields',
    api: SystemParamApi.updateSystemParam,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.update);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const getEntityExportFieldParamValueFromSystemParams = (entityType) => {
    switch (entityType) {
      case ENTITY_TYPES.PROJECT:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'projectExportFieldDefaults'
        )[0];
      case ENTITY_TYPES.REQUIREMENT:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'requirementExportFieldDefaults'
        )[0];
      case ENTITY_TYPES.TEST_CASE:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'testCaseExportFieldDefaults'
        )[0];
      case ENTITY_TYPES.TEST_RUN:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'testRunExportFieldDefaults'
        )[0];
      case ENTITY_TYPES.DEFECT:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'defectExportFieldDefaults'
        )[0];
      default:
        return null;
    }
  };

  const getEntityNameByEntityType = (entityType) => {
    switch (entityType) {
      case ENTITY_TYPES.PROJECT:
        return 'Project';
      case ENTITY_TYPES.REQUIREMENT:
        return 'Requirement';
      case ENTITY_TYPES.TEST_CASE:
        return 'Test Case';
      case ENTITY_TYPES.TEST_RUN:
        return 'Test Run';
      case ENTITY_TYPES.DEFECT:
        return 'Defect';
      default:
        return null;
    }
  };

  const exportFieldByName = (fieldName) => {
    return exportTemplate.filter((field) => field.name === fieldName)[0];
  };

  const onClickAdd = (fieldName) => {
    setEntityExportFields({ ...entityExportFields, [fieldName]: true });
  };

  const onClickRemove = (fieldName) => {
    setEntityExportFields({ ...entityExportFields, [fieldName]: false });
  };

  const save = () => {
    const toUpdateObject = {
      ...getEntityExportFieldParamValueFromSystemParams(entityType),
      paramVal: JSON.stringify(entityExportFields)
    };
    updateEntityExportFields.execute(toUpdateObject);
  };

  const clearSelecteds = () => {
    let clearSelectedsObject = {};
    for (const fieldName in entityExportFields) {
      clearSelectedsObject = { ...clearSelectedsObject, [fieldName]: false };
    }
    setEntityExportFields(clearSelectedsObject);
  };

  const selectAll = () => {
    let selectAllObject = {};
    for (const fieldName in entityExportFields) {
      selectAllObject = { ...selectAllObject, [fieldName]: true };
    }
    setEntityExportFields(selectAllObject);
  };

  const specificEntityFieldNames = (entityType, fieldName) => {
    switch (entityType) {
      case ENTITY_TYPES.REQUIREMENT:
        if (fieldName === 'parent') {
          return 'Folder';
        } else if (fieldName === 'testCases') {
          return 'Test Cases';
        } else {
          return t(exportFieldByName(fieldName)?.value);
        }
      case ENTITY_TYPES.TEST_CASE:
        if (fieldName === 'requirements') {
          return 'Requirements';
        } else {
          return t(exportFieldByName(fieldName)?.value);
        }
      case ENTITY_TYPES.TEST_RUN:
        if (fieldName === 'defects') {
          return 'Defects';
        } else {
          return t(exportFieldByName(fieldName)?.value);
        }
      default:
        return t(exportFieldByName(fieldName)?.value);
    }
  };

  const isAllSelected = () => {
    return (
      Object.keys(entityExportFields).filter((fieldName) => entityExportFields[fieldName] === true).length ===
      Object.keys(entityExportFields).length
    );
  };

  useEffect(() => {
    getEntityExportFields.execute();
    getExportFieldDefaultTemplate.execute(entityType);
  }, [entityType]);

  useEffect(() => {
    if (Object.keys(entityExportFields).length !== 0) {
      if (Object.keys(entityExportFields).filter((fieldName) => entityExportFields[fieldName] === true).length === 0) {
        setClear(true);
      } else {
        setClear(false);
      }
    }
  }, [entityExportFields]);

  return (
    <SidePaneRightContent>
      <SidePaneRightContent.Top>
        <Heading
          type="large"
          text={t('export_field', { entityName: getEntityNameByEntityType(entityType) })}
        ></Heading>
      </SidePaneRightContent.Top>
      <div className="p-4">
        <span>
          <H6
            text={t('info_text_entity_default_export_field', { entityName: getEntityNameByEntityType(entityType) })}
            color={'text-gray-500'}
          />
          <H6
            text={t('entity_default_export_field_edit_confirmation')}
            color={'text-gray-500'}
          />
        </span>
        <div className="flex flex-grow flex-col">
          <div className="grid grid-cols-2 divide-x">
            <div className="px-3">
              <div className="flex ">
                <H5
                  text={t('Template Fields')}
                  className={'mb-1 font-semibold'}
                />
              </div>
              <div className="flex">
                <table className="flex-grow">
                  <thead className="flex-grow border-b-2 border-gray-300 ">
                    <tr className="flex flex-grow text-xs font-normal">
                      <div className="flex-grow">
                        <th>{t('Field')}</th>
                      </div>
                      <th className="w-1/4">{t('Add')}</th>
                    </tr>
                  </thead>
                  <tbody className="flex-grow ">
                    {entityExportFields.loading ? (
                      <span>{t('Loading')}</span>
                    ) : !_.isEmpty(entityExportFields) ? (
                      Object.keys(entityExportFields).map((fieldName, index) => {
                        return (
                          <tr
                            key={index}
                            className="flex flex-grow"
                          >
                            <td className="w-3/4 place-content-start self-start p-1">
                              <H5
                                color={'text-black'}
                                text={specificEntityFieldNames(entityType, fieldName)}
                              />
                            </td>
                            <td className="flex w-1/4 place-content-center self-center">
                              <Button
                                id="exportFileModal-add-button"
                                secondary
                                icon="ArrowRight"
                                size="xsmall"
                                disabled={entityExportFields[fieldName]}
                                onClick={() => onClickAdd(fieldName)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <span className="text-xs font-semibold text-gray-500 ">{t('No data found with this query')}</span>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="px-3">
              <div className="flex ">
                <H5
                  text={t('Selected Fields')}
                  className={'mb-1 font-semibold'}
                />
              </div>
              <div className="flex">
                <table className="flex-grow">
                  <thead className="flex-grow border-b-2 border-gray-300 ">
                    <tr className="flex flex-grow text-xs font-normal">
                      <th className="w-1/4">{t('Remove')}</th>
                      <th>{t('Field')}</th>
                    </tr>
                  </thead>
                  <tbody className="flex-grow ">
                    {!_.isEmpty(entityExportFields) ? (
                      Object.keys(entityExportFields)
                        .filter((fieldName) => entityExportFields[fieldName] === true)
                        .map((fieldName, index) => {
                          return (
                            <tr
                              key={index}
                              className="flex flex-grow"
                            >
                              <td className="flex w-1/4 place-content-center self-center ">
                                <Button
                                  id="exportFileModal-remove-button"
                                  secondary
                                  onClick={() => onClickRemove(fieldName)}
                                  icon="ArrowLeft"
                                  size="xsmall"
                                />
                              </td>
                              <td className="p-1">
                                <H5
                                  color={'text-black'}
                                  text={specificEntityFieldNames(entityType, fieldName)}
                                />
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <span className="text-xs font-semibold text-gray-500 ">{t('There is no data selected.')}</span>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-x-4 py-4">
        <Button
          id="defaultExportFieldEdit-selectNone-button"
          secondary
          text={t('Select None')}
          disabled={clear}
          onClick={() => clearSelecteds()}
        />
        <Button
          id="defaultExportFieldEdit-selectAll-button"
          secondary
          text={t('Select All')}
          disabled={(exportTemplate && exportTemplate.length === 0) || isAllSelected() ? true : false}
          onClick={() => selectAll()}
        />
        <Button
          id="defaultExportFieldEdit-save-button"
          secondary
          text={t('Save')}
          onClick={() => save()}
        />
      </div>
    </SidePaneRightContent>
  );
}
