import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageContainer, SidePaneRightContent } from '../../components/Layout';
import { Heading } from '../../designSystem/DesignSystem';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import SubscriptionsTab from './SubscriptionsTab';
const SubscriptionPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();
  const projectConfig = UseProjectConfig(projectId);

  return (
    <FullPageContainer p="p-1">
      <SidePaneRightContent.Top>
        <Heading
          type="medium"
          text={t('Subscriptions')}
        />
      </SidePaneRightContent.Top>
      <div className="m-5">
        <SubscriptionsTab
          projectId={projectId}
          projectConfig={projectConfig}
        />
      </div>
    </FullPageContainer>
  );
};

export default SubscriptionPage;
