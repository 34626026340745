import { useTranslation } from 'react-i18next';

export const LoginWrapper = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex h-screen w-full flex-grow items-center justify-center bg-gray-50">
        <div className="w-12/12 container flex overflow-hidden sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12">
          <div className="flex flex-grow">
            <div className="flex flex-grow p-8"> {children}</div>
          </div>
        </div>
      </div>
      <footer className="-mt-8 rounded-lg">
        <span className="block text-center text-sm text-gray-500">
          © 2022 Powered by{' '}
          <a
            href="https://www.visiumlabs.com/"
            target="_blank"
            rel="noreferrer"
            className="hover:underline"
          >
            Visium Labs
          </a>
          . {t('All Rights Reserved.')}
        </span>
      </footer>
    </>
  );
};
