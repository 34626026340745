import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// API & HOOKS IMPORTS
import { useForm } from 'react-hook-form';
// HElPER & CONSTANTS IMPORTS
import { dateFormatterWithUniversalDate, isValidList, isValidValue, sortArr } from '../../helpers/Helper';
// DESIGN SYSTEM IMPORTS
import { Dropdown, Input, Button, MultiSelectDropdownPill, Datepicker } from '../../designSystem/DesignSystem';
import { translateArrItemName } from '../../helpers/TranslationHelper';
import { ENTITY_TYPES } from '../../constants';

let equalOperatorOptions = [{ key: 1, name: 'Equal', value: 'eq' }];
let multipleOperatorOptions = [{ key: 2, name: 'Is One of', value: 'in' }];
let likeOperatorOptions = [{ key: 3, name: 'Like', value: 'like' }];
let multipleNotOperatorOptions = [{ key: 4, name: 'Is Not One of', value: 'not in' }];

const AddFilterModal = ({ setFieldType, allEntityFields, users, userGroups, onSubmit, entityType }) => {
  const { control, handleSubmit, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [filterValue, setFilterValue] = useState([]);
  let [operatorOptions, setOperatorOptions] = useState([]);
  let [selectedField, setSelectedField] = useState(null);
  const allFilterOperatorOptions = [...equalOperatorOptions, ...multipleOperatorOptions];

  let fieldOptions = useMemo(() => {
    if (isValidList(allEntityFields)) {
      return allEntityFields;
    }
    return [];
  }, [allEntityFields]);

  fieldOptions = translateArrItemName(fieldOptions, t);

  const fieldConfig = useMemo(
    () => (selectedField !== null ? fieldOptions.find((item) => item?.id === selectedField?.id) : {}),
    [selectedField]
  );

  const renderValueInput = useCallback(() => {
    setFieldType(fieldConfig?.type);

    if (fieldConfig?.type === 'list') {
      const options = translateArrItemName(fieldConfig?.options, t);
      if (selectedOperator?.value === 'in') {
        return (
          <MultiSelectDropdownPill
            label={t('Value')}
            control={control}
            name={fieldConfig?.key}
            options={options}
            passValue={(value) => setFilterValue(value)}
          />
        );
      }
      return (
        <Dropdown
          clearable
          label={t('Value')}
          control={control}
          name={fieldConfig?.key}
          placeholder={t(fieldConfig?.name)}
          options={options}
          passValue={(value) => setFilterValue(value)}
        />
      );
    } else if (
      fieldConfig?.type === 'multi_user' ||
      fieldConfig?.type === 'multi_list' ||
      fieldConfig?.type === 'user'
    ) {
      let userOptions = isValidList(users) ? users : [];
      if (fieldConfig?.key !== 'createdBy' && fieldConfig?.key !== 'modifiedBy') {
        userOptions = isValidList(userGroups) ? users.concat(userGroups) : userOptions;
      }

      let options =
        fieldConfig?.type === 'multi_user' || fieldConfig?.type === 'user' ? userOptions : fieldConfig?.options;
      options = translateArrItemName(options, t);
      return (
        <MultiSelectDropdownPill
          label={t('Value')}
          control={control}
          name={t(fieldConfig?.key)}
          options={options}
          passValue={(value) => {
            setFilterValue(value);
          }}
        />
      );
    } else if (fieldConfig?.type === 'date') {
      return (
        <Datepicker
          clearable
          startDateLabel={t('Value')}
          control={control}
          startDateName={fieldConfig?.key}
          passValue={(value) => {
            setFilterValue([{ value: dateFormatterWithUniversalDate(value?.startDate) }]);
          }}
        />
      );
    } else {
      return (
        <Input
          label={t('Value')}
          name={fieldConfig?.key}
          placeholder={t(fieldConfig?.name)}
          control={control}
          type={fieldConfig?.type}
          passValue={(value) => setFilterValue([{ value: value?.toString().trim() }])}
        />
      );
    }
  }, [fieldConfig, selectedOperator]);

  const handleSubmitButton = () => onSubmit(fieldConfig, selectedOperator, filterValue);

  const handleOperatorOptions = () => {
    return selectedField?.name !== 'Status'
      ? operatorOptions.filter((option) => option?.name !== 'Is Not One of')
      : operatorOptions;
  };

  useEffect(() => {
    if (!isValidValue(fieldConfig)) {
      return;
    }
    if (fieldConfig?.type === 'multi_list' || fieldConfig?.type === 'multi_user' || fieldConfig?.type === 'user') {
      const options =
        entityType == ENTITY_TYPES.TEST_RUN
          ? [...multipleOperatorOptions, ...multipleNotOperatorOptions]
          : [...multipleOperatorOptions];

      setOperatorOptions(translateArrItemName(options, t));
      setSelectedOperator(options[0]);
    } else if (fieldConfig?.type === 'list') {
      const options = fieldConfig?.custom ? [...allFilterOperatorOptions] : [...equalOperatorOptions];
      setOperatorOptions(translateArrItemName(options, t));
      setSelectedOperator(options[0]);
    } else {
      const options = fieldConfig?.custom
        ? [...equalOperatorOptions, ...likeOperatorOptions]
        : [...equalOperatorOptions];
      setOperatorOptions(translateArrItemName(options, t));
      setSelectedOperator(options[0]);
    }
  }, [fieldConfig]);

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    setFilterValue([]);
  }, [selectedField]);
  return (
    <form
      className="flex w-[440px] flex-col gap-3"
      onSubmit={handleSubmit(handleSubmitButton)}
    >
      <div className="flex flex-grow flex-col gap-1">
        <div className="flex flex-grow gap-1">
          <div className="flex flex-grow">
            <Dropdown
              clearable
              control={control}
              label={t('Field')}
              name="field"
              options={fieldOptions.sort(sortArr('name', 'string'))}
              selectedItem={selectedField}
              placeholder={t('Select Field')}
              passValue={(value) => setSelectedField(value[0])}
              fluid
            />
          </div>
          <div className="flex flex-grow">
            <Dropdown
              clearable
              control={control}
              // required
              label={t('Operator')}
              disabled={!selectedField}
              name="operator"
              options={handleOperatorOptions()}
              selectedItem={selectedOperator}
              passValue={(value) => setSelectedOperator(value[0])}
              fluid
              onClear={() => {
                setFilterValue([]);
                setSelectedOperator(null);
                reset();
              }}
            />
          </div>
        </div>
        {selectedField && selectedOperator && <div className="flex gap-4 ">{renderValueInput()}</div>}
      </div>
      <div className="ml-1 w-20">
        <Button
          id="addFilterModal-add-button"
          primary
          text={t('Add')}
          fluid
          size="small"
          onClick={handleSubmitButton}
          disabled={!(filterValue && filterValue[0]?.value?.toString().trim().length > 0)}
        />
      </div>
    </form>
  );
};

export default AddFilterModal;
