import React, { useEffect, useState } from 'react';
import { encryptData } from '../../helpers/CryptoHelper';
// DESIGN SYSTEM IMPORTS
import { Input, Password, Button, Heading, P } from '../../designSystem/DesignSystem';
import { WarningCircle } from 'phosphor-react';
import { LoginWrapper } from './LoginPage.utils';
import { useApi } from '../../hooks/UseApi';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoginApi from '../../api/LoginApi';
import { useLocation } from 'react-router-dom';

const LoginPage = () => {
  const { t } = useTranslation();

  const [apiError, setApiError] = useState(null);
  const localStoreErrorKey = 'error';

  const location = useLocation();
  const from = location.state?.from;

  const LoginLoader = useApi({
    name: `Logging in`,
    api: LoginApi.Login,
    onSuccess: (res) => {
      localStorage.setItem('token', JSON.stringify({ ...res.data, firstToken: res.data.token }));
      if (from) {
        window.location.replace(from);
      } else {
        window.location.reload();
      }
    },
    onError: (error) => {
      if (error?.response.data) {
        localStorage.setItem(localStoreErrorKey, JSON.stringify(error?.response.data));
      }
      setInterval(() => {
        window.location.reload();
      }, 1000);
    }
  });

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({ criteriaMode: 'all', mode: 'onChange' });

  const onSubmit = async (data) => {
    const { username, password, captcha: captchaValue } = data;
    const encUserName = await encryptData(username);
    const encPassword = await encryptData(password);
    const body = {
      username: encUserName,
      password: encPassword,
      captchaId: apiError === null ? null : apiError?.captchaResponse?.id,
      captcha: captchaValue
    };
    localStorage.removeItem(localStoreErrorKey);
    LoginLoader.execute(body);
  };

  useEffect(() => {
    localStorage.removeItem('publicKey');
    // Getting error messages because the page is refreshed
    const errorJSON = JSON.parse(localStorage.getItem(localStoreErrorKey));
    setApiError(errorJSON);
  }, []);

  return (
    <LoginWrapper>
      <form
        className="flex flex-grow flex-col"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex justify-center">
          <img
            className="w-1/3 xl:w-6/12"
            src="/logo/logo_login.png"
            alt="logo-full"
          />
        </div>
        <div className="mb-6 mt-6 flex flex-col items-center justify-center">
          <div className="mb-1">
            <Heading
              type="medium"
              text={t('Login to your account')}
            />
          </div>
        </div>
        <div
          className="-mt-4 space-y-4"
          onChange={() =>
            // error message removed
            setApiError(apiError && { ...apiError, message: null })
          }
        >
          <Input
            control={control}
            name="username"
            placeholder={t('Enter your username')}
            icon="User"
            required
          />
          <Password
            control={control}
            name="password"
            password
            placeholder={t('Enter your password')}
            required
          />
        </div>
        {apiError?.captchaResponse?.id && (
          <div className="mt-4 space-y-3">
            <div className="mt-2 flex justify-center rounded-lg px-1">
              <img
                className="rounded-lg"
                src={apiError?.captchaResponse?.image}
              />
            </div>

            <Input
              control={control}
              name="captcha"
              placeholder={t('Enter the text above')}
              required
            />
          </div>
        )}
        <div className="flex h-2 items-center  justify-center space-y-2">
          {apiError?.message && (
            <>
              <div className="mt-5 flex items-center gap-2">
                <WarningCircle
                  className=" text-red-500 "
                  size={16}
                  weight="duotone"
                />
                <P
                  small
                  color="text-red-400"
                  text={apiError?.message}
                />
              </div>
            </>
          )}
        </div>
        <div className="ml-1 mt-7 flex">
          <Button
            id="login-submit-button"
            primary
            text={t('Login')}
            type="submit"
            disabled={!isValid}
            loading={LoginLoader?.loading}
            fluid
          />
        </div>
        {/* <div className="flex mt-6 justify-center">
          <img className="w-1/5" src="/logo/company_logo.png" alt="NetasLogo" />
        </div> */}
      </form>
    </LoginWrapper>
  );
};

export default LoginPage;
