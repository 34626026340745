import { Controller } from 'react-hook-form';
import { errorMessage } from '../../helpers/Helper';
import { Trans, useTranslation } from 'react-i18next';

const Checkbox = (props) => {
  const { control, name, checkboxList, label, setCheckboxList, passValue, required } = props;

  const { t } = useTranslation();

  let LABEL_SPAN_CLASS = ' ml-2 ';

  const onHandleCheckbox = (e, index, field) => {
    let newList = [...checkboxList];
    newList[index] = {
      ...checkboxList[index],
      selected: e.target.checked
    };
    setCheckboxList(newList);

    let activeCheckboxIds = [];
    newList.forEach((item) => {
      if (item.selected === true) activeCheckboxIds.push(item.id);
    });

    field.onChange(activeCheckboxIds);
    if (passValue) passValue(activeCheckboxIds);
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: required }}
        render={({ field, formState }) => (
          <>
            <div className="flex flex-col flex-grow mb-1">
              <label className="block text-sm leading-5 mb-2 font-medium text-gray-800">
                {label}
                {required && label && <span className={LABEL_SPAN_CLASS}>*</span>}
              </label>
              <div className="flex flex-col  ml-1">
                {checkboxList.map((item, index) => {
                  return (
                    item?.visible && (
                      <label className="inline-flex items-center mt-2">
                        <input
                          type="checkbox"
                          className="h-5 w-5 text-gray-600 rounded-md"
                          disabled={item?.disabled}
                          checked={item?.selected}
                          onChange={(e) => onHandleCheckbox(e, index, field)}
                        />
                        <span className="ml-2 text-gray-700">{item?.value}</span>
                      </label>
                    )
                  );
                })}
              </div>
              {formState?.errors[name] && !checkboxList.filter((item) => item?.selected === true).length > 0 && (
                <>
                  {formState?.errors[name]?.type === 'required' && (
                    <Trans t={t}>{errorMessage('required', label, name, t)}</Trans>
                  )}
                </>
              )}
            </div>
          </>
        )}
      />
    </>
  );
};

export { Checkbox };
