import React, { useState, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// API & HOOKS
import UsePrevious from '../../hooks/UsePrevious';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
// COMPINENTS & DESIGN SYSTEM
import { FullPageContainer, SidePaneRightContent, TOP_HEADING_SIZE } from '../../components/Layout';
import { Heading } from '../../designSystem/DesignSystem';
import SelectedTableFilters from '../../components/filtering/SelectedTableFilters';
import AlertModal from '../../components/alert/AlertModal';
import DetailsModals from '../../components/general/DetailsModals';
// PAGES
import AllTestRunReactTable from './AllTestRunReactTable';
import TestRunStatusCount from './TestRunStatusCount';
// HELPERS & CONSTANTS
import { ENTITY_TYPES } from '../../constants';
import { getProjectMainMenusByKey } from '../../helpers/ArtifactNameHelper';
import { isValidValue } from '../../helpers/Helper';
import { isViewTesterPage } from '../../helpers/PriviligeHelper';

const TasksPage = () => {
  const { t } = useTranslation();

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  let [searchParams] = useSearchParams();

  const [refreshTable, setRefreshTable] = useState(false);
  const [allFilters, setAllFilters] = useState({});
  const [refreshStatus, setRefreshStatus] = useState(false);

  const runId = useMemo(() => (searchParams?.get('runId') ? searchParams?.get('runId') : null), [searchParams]);
  const prevRunId = UsePrevious(runId);

  useEffect(() => {
    if (isValidValue(prevRunId) && runId === null) {
      setRefreshTable(true);
      setRefreshStatus(true);
    }
  }, [runId]);

  return (
    <>
      {!isViewTesterPage(projectId) && (
        <AlertModal
          header={t('Access Denied')}
          content={t(`You have no permission to access this page.`)}
        />
      )}
      {runId ? (
        <DetailsModals />
      ) : (
        <FullPageContainer p="p-1">
          <SidePaneRightContent.Top px="px-6">
            <Heading
              type={TOP_HEADING_SIZE}
              text={`${getProjectMainMenusByKey(projectConfig, 'task')}`}
            />
          </SidePaneRightContent.Top>
          <div className="ml-5 mr-5 mt-2">
            <TestRunStatusCount
              projectId={projectId}
              filter={allFilters}
              setFilter={setAllFilters}
              refreshStatus={refreshStatus}
              setRefreshStatus={setRefreshStatus}
              setRefreshTable={setRefreshTable}
            />
            <div className="mt-3">
              <SelectedTableFilters
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                entityType={ENTITY_TYPES.TEST_RUN}
                setRefreshTable={setRefreshTable}
                setRefreshStatus={setRefreshStatus}
              />
              <AllTestRunReactTable
                filter={{ projectId, ...allFilters }}
                projectConfig={projectConfig}
                refreshTable={refreshTable}
                setRefreshTable={setRefreshTable}
                setRefreshStatus={setRefreshStatus}
              />
            </div>
          </div>
        </FullPageContainer>
      )}
    </>
  );
};

export default TasksPage;
