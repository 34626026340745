import React, { useState, useEffect } from 'react';
// API and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import RoleApi from '../../../api/RoleApi';
// Design System Imports
import { H6, H5, Button } from '../../../designSystem/DesignSystem';
//Helper Imports
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
const RoleAddTable = (props) => {
  const { selectedUserGroup, setSelectedRow, selectedResults, addApi, removeApi, projectId } = props;
  const [allResults, setAllResults] = useState([]);
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState(null);
  const dataLoader = useApi({
    name: 'Get roles',
    api: RoleApi.getProjectRoleList,
    onSuccess: () => setAllResults(dataLoader?.data?.filter((item) => item.name !== 'Project Users')),
    onError: (err) => {
      showErrorMes(err);
      setAllResults([]);
    }
  });

  const getResults = () => {
    dataLoader.execute({ projectId });
  };

  const onClickAddRole = (role) => {
    setSelectedRow(role);
    setSelectedRole(role);
    addApi.execute({ id: selectedUserGroup?.id, type: 'Role', itemId: role?.id });
  };

  const onClickDeleteRole = (role) => {
    setSelectedRow(role);
    setSelectedRole(role);
    removeApi.execute({ id: selectedUserGroup?.id, type: 'Role', itemId: role?.id });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <div className="flex flex-grow flex-col gap-8">
        <div className="grid grid-cols-2 divide-x">
          <div className="px-3">
            <div className="flex py-6">
              <table className="flex-grow">
                <thead className="flex-grow border-b-2 border-gray-300 ">
                  <tr className="flex flex-grow">
                    <div className="flex-grow">
                      <th>{t('Role')}</th>
                    </div>
                    <th className="w-1/4">{t('Add')}</th>
                  </tr>
                </thead>
                <tbody className="flex-grow ">
                  {allResults.map((role, index) => {
                    return (
                      <tr
                        key={index}
                        className="flex flex-grow"
                      >
                        <td className="w-3/4 place-content-start self-start p-1">
                          <H5
                            color={'text-black'}
                            text={role.name}
                          />
                          <ReactMarkdown className="markdownViewer text-xs">{role.description}</ReactMarkdown>
                        </td>
                        <td className="flex w-1/4 place-content-center self-center">
                          <Button
                            id="roleAddTable-add-button"
                            primary
                            icon="ArrowRight"
                            size="small"
                            disabled={
                              selectedResults
                                ? selectedResults.some((value) => {
                                    return value.id === role.id;
                                  })
                                : false || selectedUserGroup?.templateRoles?.some((element) => element.id === role?.id)
                            }
                            onClick={() => onClickAddRole(role)}
                            loading={selectedRole === role && addApi?.loading}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="px-3">
            <div className="flex">
              <H5
                text={t('Selected Roles')}
                className={'mb-1 font-semibold'}
              />
            </div>
            <div className="flex">
              <table className="flex-grow">
                <thead className="flex-grow border-b-2 border-gray-300 ">
                  <tr className="flex flex-grow">
                    <th className="w-1/4">{t('Delete')}</th>
                    <th>{t('Role')}</th>
                  </tr>
                </thead>
                <tbody className="flex-grow ">
                  {selectedResults.map((role, index) => {
                    return (
                      <tr
                        key={index}
                        className="flex flex-grow"
                      >
                        <td className="flex w-1/4 place-content-center self-center ">
                          <Button
                            id="roleAddTable-delete-button"
                            primary
                            size="small"
                            icon="ArrowLeft"
                            disabled={selectedUserGroup?.templateRoles?.some((element) => element.id === role.id)}
                            onClick={() => onClickDeleteRole(role)}
                            loading={selectedRole === role && removeApi?.loading}
                          />
                        </td>
                        <td className="p-1">
                          <H5
                            color={'text-black'}
                            text={role.name}
                          />
                          <H6 text={role.description} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleAddTable;
