import { axios } from './ApiClient';

export const CF_BASE = '/api/custom-fields';

class CustomFieldApi {
  async get(data) {
    const resp = await axios.get(CF_BASE, { params: data });
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(CF_BASE, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${CF_BASE}/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${CF_BASE}/${data.id}`);
    return resp.data;
  }

  async clone(data) {
    const resp = await axios.put(`${CF_BASE}/${data.id}/clone`, data);
    return resp.data;
  }

  async multiClone(data) {
    const resp = await axios.put(`${CF_BASE}/multi-clone`, data);
    return resp.data;
  }

  async getTypes() {
    const resp = await axios.get(`${CF_BASE}/customFieldTypes`);
    return resp.data;
  }

  async fetchCascadingEntityCustomFields(data) {
    const resp = await axios.get(`${CF_BASE}/cascading`, { params: data });
    return resp.data;
  }

  async addJiraStatusCustomField(data) {
    const resp = await axios.post(`${CF_BASE}/jira-status-field`, data);
    return resp.data;
  }

  async addJiraCustomFields(data) {
    const resp = await axios.post(`${CF_BASE}/jira-fields`, data);
    return resp.data;
  }
}
export default new CustomFieldApi();
