import { PencilLine, TestTube, Trash, UserGear } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
// API & HOOKS IMPORTS
import MailConfigApi from '../../../api/MailConfigApi';
import { useApi } from '../../../hooks/UseApi';
import UseQueryProject from '../../../hooks/UseQueryProject';
// COMPONENTS
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import MailModal from './modal/MailModal';
import MailServerTestModal from './modal/MailServerTestModal';
import MailSettingModal from './modal/MailSettingModal';
// DESIGN SYSTEM & LAYOUTS
import { Button, Heading } from '../../../designSystem/DesignSystem';
import Table from '../../../components/table/Table';
// HELPER & CONSTANTS
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import {
  convertToTableData,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../helpers/TableHelper';
import { SidePaneRightContent } from '../../../components/Layout';
import { isValidValue } from '../../../helpers/Helper';
import { useTranslation } from 'react-i18next';

const MailPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(null);
  const [settingVisibility, setSettingVisibility] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [testModalVisibility, setTestModalVisibility] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [pageParams, setPageParams] = useState({
    page: 0,
    size: 10,
    sortBy: 'host',
    sortDir: 'ASC',
    defaultSize: 10
  });

  const deleteLoader = useApi({
    name: 'delete',
    api: MailConfigApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      setConfirmOpen(false);
      setSelectedItem(null);
      refreshTable();
    }
  });

  const dataLoader = useApi({
    name: 'get',
    api: MailConfigApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
    },
    onError: (err) => showErrorMes(err)
  });

  const refreshTable = () => {
    getByPage({});
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    setPageParams(pageInput);
    getItems(pageInput, {});
  };

  const getItems = (pageInput, filterInput) => {
    const body = { ...pageInput, projectId, ...filterInput };
    dataLoader.execute(body);
  };

  const onDeleteData = () => {
    deleteLoader.execute({ id: selectedItem?.id });
  };
  const optionsFn = (row) => {
    return [
      {
        name: t('Edit'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: isValidValue(row?.original?.templateBy),
        onClick: () => {
          handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
          !isValidValue(row?.original?.templateBy) && setModalVisibility(true);
        }
      },
      {
        name: t('Manage User'),
        icon: (weight) => (
          <UserGear
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
          setSettingVisibility(true);
        }
      },
      {
        name: t('Test'),
        icon: (weight) => (
          <TestTube
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
          setTestModalVisibility(true);
        }
      },
      {
        name: t('Delete'),
        icon: (weight) => (
          <Trash
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: isValidValue(row?.original?.templateBy),
        onClick: () => {
          handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
          !isValidValue(row?.original?.templateBy) && setConfirmOpen(true);
        }
      }
    ];
  };

  const tableData = useMemo(() => convertToTableData(dataLoader?.data?.content, 'mail'), [dataLoader?.data?.content]);
  let tableColumns = useMemo(() => generateTableColumns(tableData, optionsFn, ['id']), [dataLoader?.data?.content]);

  tableColumns = translateColumns(tableColumns, t);

  const loading = useMemo(() => dataLoader?.loading, [dataLoader?.loading]);

  useEffect(() => {
    if (projectId) refreshTable();
  }, [projectId]);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('Mail')}
          >
            <div className="flex gap-4">
              <Button
                id="mailPage-add-button"
                primary
                text={t('Add')}
                size="small"
                icon="plus"
                onClick={() => {
                  setSelectedItem(null);
                  setModalVisibility(true);
                }}
              />
            </div>
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center colFull={true}>
            <div className="mt-6">
              <Table
                columns={tableColumns}
                data={tableData}
                pagination
                loading={loading}
                pageParams={pageParams}
                getByPage={getByPage}
                entityType={'mail'}
              />
            </div>
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>
      {confirmOpen === true && (
        <ConfirmationModal
          isOpen={confirmOpen}
          loading={deleteLoader?.loading}
          closeModal={() => setConfirmOpen(false)}
          handleConfirm={onDeleteData}
          header={t('Delete')}
          content={t('Do you want to delete data?')}
        />
      )}
      {modalVisibility === true && (
        <MailModal
          isOpen={modalVisibility}
          closeModal={() => {
            setModalVisibility(false);
            setSelectedItem(null);
            refreshTable();
          }}
          data={selectedItem}
          projectId={projectId}
        />
      )}
      {testModalVisibility === true && (
        <MailServerTestModal
          isOpen={testModalVisibility}
          closeModal={() => {
            setTestModalVisibility(false);
          }}
          selectedItem={selectedItem}
        />
      )}
      {settingVisibility === true && (
        <MailSettingModal
          projectId={projectId}
          isOpen={settingVisibility}
          closeModal={() => {
            setSettingVisibility(false);
            refreshTable();
          }}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

export default MailPage;
