import { TestCaseApproveType } from '../constants';
import { isValidValue } from './Helper';
import _ from 'lodash';

export const isApproved = (approveType) => {
  return isValidValue(approveType) ? approveType === TestCaseApproveType.APPROVED : false;
};

export const defaultValues = { description: '', expectedResult: '' };
export const linkClass = 'text-blue-900 text-sm font-medium hover:underline underline-offset-4';

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'white' : '#white',
  padding: 0,
  width: '98%'
});

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '2px 18px 2px 0px',
  marginRight: 0,

  // change background colour if dragging
  background: isDragging ? '#e6f0f5' : 'white',
  borderBottom: `1px solid rgba(28,110,164,0.12)`,

  // styles we need to apply on draggables
  ...draggableStyle
});

export const useRegex = (input) => {
  let regex = RegExp(/@[a-zA-Z]+\s/i, 'g');
  return _.words(input, regex);
};
