import React, { useState, useEffect } from 'react';
// API & HOOKS
import { useForm } from 'react-hook-form';
import TestCaseApi from '../../api/TestCaseApi';
import DefectApi from '../../api/DefectApi';
import { useApi } from '../../hooks/UseApi';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import { getI18n, useTranslation } from 'react-i18next';
// DESIGN SYSTEM & COMPONENTS
import ConfirmationModal from '../confirm/ConfirmationModal';
import MenuDropdown from '../menu/MenuDropdown';
import CloneModal from '../clone/CloneModal';
import BulkAssignModal from './BulkAssignModal';
import BulkCreateTestRunModal from './BulkCreateTestRunModal';
import BulkExecuteModal from './BulkExecuteModal';
import BulkassignTestCasesFromRequirementsModal from './BulkassignTestCasesFromRequirementsModal';
// HELPERS & CONSTANTS
import { showSuccessMes, showErrorMes, INFO_MESSAGES, showError } from '../../helpers/NotificationHelper';
import {
  getArtifactName,
  getDefectName,
  getRequirementName,
  getTestCaseName,
  getRunName,
  getExecutionName
} from '../../helpers/ArtifactNameHelper';
import { ENTITY_TYPES, TEMPLATES_PROJECTS_SYNC_LIMIT } from '../../constants';
import { useApp, useAppDispatch } from '../../context/AppContext';
import { isValidList } from '../../helpers/Helper';
import ProjectApi from '../../api/ProjectApi';
import { translateArrItemText } from '../../helpers/TranslationHelper';
import { setRefreshData, setRefreshTree } from '../../context/ProjectActions';
import RequirementApi from '../../api/RequirementApi';
import TestRunApi from '../../api/TestRunApi';
import { useBulkDropDownStates } from './UseBulkDropDownStates';
import { getPrivilegeOptions } from './PrivilegeOptions';

const BulkDropdown = ({ entityType, submitCallBack, entityId }) => {
  const app = useApp();
  const dispatch = useAppDispatch();

  const selectedRows = app?.selectedRows;
  const { t } = useTranslation();
  const { control } = useForm({ mode: 'onChange' });

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);

  const [options, setOptions] = useState([]);

  const {
    openDefectBulkAssignModal,
    setDefectBulkAssignModal,
    openUnassignRequirementFromTestCaseModal,
    setOpenUnassignRequirementFromTestCaseModal,
    bulkUnassignTestCasesFromRequirementModalOpen,
    setBulkUnassignTestCasesFromRequirementModalOpen,
    bulkAssignTestCasesFromRequirementsModalOpen,
    setBulkAssignTestCasesFromRequirementsModalOpen,
    openTestCaseBulkApproveModal,
    setOpenTestCaseBulkApproveModal,
    openTestCaseSendApproveModal,
    setOpenTestCaseSendApproveModal,
    openTestCaseDeleteConfirmModal,
    setOpenTestCaseDeleteConfirmModal,
    openDefectDeleteConfirmModal,
    setOpenDefectDeleteConfirmModal,
    openBulkRunDeleteModal,
    setOpenBulkRunDeleteModal,
    openCloneModal,
    setOpenCloneModal,
    openBulkExecutionModal,
    setOpenBulkExecutionModal,
    openBulkCreateTestRunModal,
    setOpenBulkCreateTestRunModal,
    syncConfModal,
    setSyncConfModal
  } = useBulkDropDownStates();

  //#region Api
  const bulkApprove = useApi({
    name: 'bulk approve test case',
    api: TestCaseApi.bulkApprove,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(t('Approved Successfully.'));
      setOpenTestCaseBulkApproveModal(false);
      submitCallBack();
      dispatch(setRefreshTree(true));
    },
    onError: (err) => showErrorMes(err)
  });

  const bulkSendToApprove = useApi({
    name: 'send approve test case',
    api: TestCaseApi.bulkSendToApprove,
    autoLoad: false,
    onSuccess: (form) => {
      setOpenTestCaseSendApproveModal(false);
      submitCallBack(form);
      dispatch(setRefreshTree(true));
      showSuccessMes(t('sentApprove', { entity: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE) }));
    }
  });

  const sycnRelatedProject = useApi({
    name: 'sync projects',
    api: ProjectApi.syncRelatedProject,
    onSuccess: () => {
      setSyncConfModal(false);
      showSuccessMes(INFO_MESSAGES.update);
    },
    onError: (err) => showErrorMes(err)
  });

  const bulkDelete = useApi({
    name: 'bulk delete test case',
    api: TestCaseApi.bulkDelete,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      setOpenTestCaseDeleteConfirmModal(false);
      submitCallBack();
      dispatch(setRefreshTree(true));
      dispatch(setRefreshData(true));
    },
    onError: (err) => {
      if (err.response.data.message.startsWith('Test case could not be deleted. Related Test case(s):')) {
        let notificationText = t('testCaseRelatedErrorMessage', {
          entity: getTestCaseName(projectConfig)
        });
        var fragmentedMessage = err.response.data.message.split(':');
        var relatedRequirements = fragmentedMessage[1].trim();
        let totalErrorMessage = notificationText + relatedRequirements;
        showError(totalErrorMessage);
      } else {
        showErrorMes(err);
      }
    }
  });

  const bulkDefectDelete = useApi({
    name: 'bulk delete defect',
    api: DefectApi.bulkDelete,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      setOpenDefectDeleteConfirmModal(false);
      submitCallBack();
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const unassignData = useApi({
    name: 'Unassign Requirement',
    api: TestCaseApi.unassignRequirement,
    onSuccess: () => {
      setOpenUnassignRequirementFromTestCaseModal(false);
      showSuccessMes(t('unassignRequirement', { entity: getRequirementName(projectConfig) }));
      submitCallBack();
    },
    onError: () =>
      showError(t('unassignError', { entity: getRequirementName(projectConfig, ENTITY_TYPES.REQUIREMENT) }))
  });

  const bulkUnassignTestCasesFromRequirement = useApi({
    name: 'Unassign Test Case',
    api: RequirementApi.bulkUnassignTestcase,
    onSuccess: () => {
      setBulkUnassignTestCasesFromRequirementModalOpen(false);
      showSuccessMes(t('unassignRequirement', { entity: getTestCaseName(projectConfig) }));
      submitCallBack();
    },
    onError: () => showError(t('unassignError', { entity: getTestCaseName(projectConfig, ENTITY_TYPES.TEST_CASE) }))
  });

  const bulkRunDelete = useApi({
    name: 'bulk delete run',
    api: TestRunApi.bulkDelete,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      setOpenBulkRunDeleteModal(false);
      submitCallBack();
    },
    onError: (err) => {
      if (err?.response?.data?.message.startsWith('Test run(s) can not be deleted. Executed test run(s) : ')) {
        let notificationText = t('bulk_run_delete_error', {
          entity: getRunName(projectConfig, ENTITY_TYPES.TEST_RUN),
          entity1: getExecutionName(projectConfig, ENTITY_TYPES.TEST_EXECUTION)
        });
        var fragmentedMessage = err.response.data.message.split(':');
        var relatedRequirements = fragmentedMessage[1].trim();
        let totalErrorMessage = notificationText + relatedRequirements;
        showError(totalErrorMessage);
      } else if (err?.response?.data?.message.startsWith("You don't have permission to delete test run")) {
        showError(t('bulk_run_delete_permission_error', { entity: getRunName(projectConfig, ENTITY_TYPES.TEST_RUN) }));
      } else {
        showErrorMes(err);
      }
    }
  });
  //#endregion

  const selectModalType = (value) => {
    const modals = {
      approve: setOpenTestCaseBulkApproveModal,
      clone: setOpenCloneModal,
      'send to approve': setOpenTestCaseSendApproveModal,
      execute: setOpenBulkExecutionModal,
      delete: setOpenTestCaseDeleteConfirmModal,
      defectBulkDelete: setOpenDefectDeleteConfirmModal,
      defectAssign: setDefectBulkAssignModal,
      testRun: setOpenBulkCreateTestRunModal,
      unassign: setOpenUnassignRequirementFromTestCaseModal,
      'sync related projects': setSyncConfModal,
      bulkUnassignTestCasesFromRequirement: setBulkUnassignTestCasesFromRequirementModalOpen,
      bulkAssignTestCasesFromRequirements: setBulkAssignTestCasesFromRequirementsModalOpen,
      bulkRunDelete: setOpenBulkRunDeleteModal
    };

    const modalFunction = modals[value];

    if (modalFunction) {
      modalFunction(true);
    }
  };

  const selectedRowsIds = isValidList(selectedRows) ? selectedRows.map((row) => row?.original?.id) : [];

  const handleApprove = () => {
    bulkApprove.execute({ ids: selectedRowsIds });
  };

  const handleSendApprove = () => bulkSendToApprove.execute({ ids: selectedRowsIds });
  const handleDelete = () => bulkDelete.execute({ ids: selectedRowsIds });
  const handleUnassign = () => {
    unassignData.execute({ id: entityId, ids: selectedRowsIds });
  };
  const handleBulkDefectDelete = () => {
    bulkDefectDelete.execute({ ids: selectedRowsIds });
  };
  const handleBulkUnassignTestCasesFromRequirement = () => {
    bulkUnassignTestCasesFromRequirement.execute({ id: entityId, ids: selectedRowsIds });
  };

  const handleSyncProject = () => {
    if (!isValidList(selectedRowsIds)) {
      showErrorMes(t('Please select releated project'));
      setSyncConfModal(false);
    } else {
      if (selectedRowsIds.length > TEMPLATES_PROJECTS_SYNC_LIMIT) {
        showErrorMes(t('templateProjectSyncLimit', { entity: TEMPLATES_PROJECTS_SYNC_LIMIT }));
      } else {
        sycnRelatedProject.execute({ id: projectId, ids: selectedRowsIds });
      }
    }
  };

  const handleBulkRunDelete = () => {
    bulkRunDelete.execute({ testRunIds: selectedRowsIds });
  };

  useEffect(() => {
    let url = window.location.href;
    let hasURLIncludeTestCase = url.toLowerCase().includes('testcase');
    let hasURLIncludeRequirement = url.toLowerCase().includes('requirements');
    let hasURLIncludeContainer = url.toLowerCase().includes('testContainer');
    let hasURLIncludeVersion = url.toLowerCase().includes('version');
    const updatedOptions = getPrivilegeOptions(
      projectId,
      entityType,
      t,
      projectConfig,
      hasURLIncludeTestCase,
      hasURLIncludeRequirement,
      hasURLIncludeContainer,
      hasURLIncludeVersion
    )
      .filter((option) => option.check())
      .map((option) => ({
        key: option.key,
        value: option.value,
        text: t(option.text),
        name: option.name
      }));

    setOptions(updatedOptions);
  }, [entityType, getI18n().language]);

  let translateOptions = translateArrItemText(options, t);

  return (
    <>
      {translateOptions?.length > 0 && (
        <div className="z-20  flex justify-start">
          <form>
            <div>
              <MenuDropdown
                button
                floatingClass="w-28"
                name="bulk"
                label={t('Bulk')}
                control={control}
                options={translateOptions}
                passValue={(value) => selectModalType(value.key)}
              />
            </div>
          </form>
        </div>
      )}
      {openDefectBulkAssignModal && (
        <BulkAssignModal
          runIds={selectedRowsIds}
          submitCallBack={submitCallBack}
          isOpen={openDefectBulkAssignModal}
          closeModal={() => setDefectBulkAssignModal(false)}
        />
      )}
      {bulkAssignTestCasesFromRequirementsModalOpen && (
        <BulkassignTestCasesFromRequirementsModal
          runIds={selectedRowsIds}
          submitCallBack={submitCallBack}
          isOpen={bulkAssignTestCasesFromRequirementsModalOpen}
          entityType={entityType}
          closeModal={() => setBulkAssignTestCasesFromRequirementsModalOpen(false)}
        />
      )}
      {openCloneModal && (
        <CloneModal
          isOpen={openCloneModal}
          entityType={ENTITY_TYPES.TEST_CASE}
          closeModal={() => setOpenCloneModal(false)}
          isBulk={true}
          selectedRows={selectedRowsIds}
          submitCallBack={submitCallBack}
        />
      )}
      {openBulkExecutionModal && (
        <BulkExecuteModal
          runIds={selectedRowsIds}
          submitCallBack={submitCallBack}
          isOpen={openBulkExecutionModal}
          closeModal={() => setOpenBulkExecutionModal(false)}
        />
      )}
      {openBulkCreateTestRunModal && (
        <BulkCreateTestRunModal
          selectedRowsIds={selectedRowsIds}
          entityType={entityType}
          submitCallBack={submitCallBack}
          isOpen={openBulkCreateTestRunModal}
          closeModal={() => setOpenBulkCreateTestRunModal(false)}
        />
      )}
      {/* Confirmation Modals Start*/}
      {openTestCaseBulkApproveModal && (
        <ConfirmationModal
          isOpen={openTestCaseBulkApproveModal}
          closeModal={() => setOpenTestCaseBulkApproveModal(false)}
          handleConfirm={() => handleApprove()}
          loading={bulkApprove?.loading}
          header={t('Confirm Bulk Approve')}
          content={t('confirmation_modal_content', {
            selectedRowsIds: selectedRowsIds?.length,
            getTestCaseName: getTestCaseName(projectConfig)
          })}
        />
      )}
      {openTestCaseDeleteConfirmModal && (
        <ConfirmationModal
          isOpen={openTestCaseDeleteConfirmModal}
          closeModal={() => setOpenTestCaseDeleteConfirmModal(false)}
          handleConfirm={() => handleDelete()}
          loading={bulkDelete?.loading}
          header={t('Confirm Bulk Delete')}
          content={t('confirmation_delete_modal_content', {
            selectedRowsIds: selectedRowsIds?.length,
            entityName: getTestCaseName(projectConfig)
          })}
        />
      )}
      {openDefectDeleteConfirmModal && (
        <ConfirmationModal
          isOpen={openDefectDeleteConfirmModal}
          closeModal={() => setOpenDefectDeleteConfirmModal(false)}
          handleConfirm={() => handleBulkDefectDelete()}
          loading={bulkDefectDelete?.loading}
          header={t('Confirm Bulk Delete')}
          content={t('confirmation_delete_modal_content', {
            selectedRowsIds: selectedRowsIds?.length,
            entityName: getDefectName(projectConfig)
          })}
        />
      )}
      {openTestCaseSendApproveModal && (
        <ConfirmationModal
          isOpen={openTestCaseSendApproveModal}
          closeModal={() => setOpenTestCaseSendApproveModal(false)}
          handleConfirm={() => handleSendApprove()}
          loading={bulkSendToApprove?.loading}
          header={`Send ${getTestCaseName(projectConfig)} For Approve`}
          content={`Are you sure to send ${getTestCaseName(projectConfig)} to approve?`}
        />
      )}
      {openUnassignRequirementFromTestCaseModal && (
        <>
          <ConfirmationModal
            isOpen={openUnassignRequirementFromTestCaseModal}
            loading={unassignData?.loading}
            closeModal={() => setOpenUnassignRequirementFromTestCaseModal(false)}
            handleConfirm={handleUnassign}
            header={t('Unassign')}
            content={t('Are you sure to unassign data?')}
          />
        </>
      )}
      {bulkUnassignTestCasesFromRequirementModalOpen && (
        <>
          <ConfirmationModal
            isOpen={bulkUnassignTestCasesFromRequirementModalOpen}
            loading={bulkUnassignTestCasesFromRequirement?.loading}
            closeModal={() => setBulkUnassignTestCasesFromRequirementModalOpen(false)}
            handleConfirm={handleBulkUnassignTestCasesFromRequirement}
            header={t('Unassign')}
            content={t('Are you sure to unassign data?')}
          />
        </>
      )}
      {syncConfModal && (
        <ConfirmationModal
          isOpen={syncConfModal}
          closeModal={() => setSyncConfModal(false)}
          handleConfirm={() => handleSyncProject()}
          header={t('Confirm Synchronization')}
          loading={sycnRelatedProject?.loading}
          content={t('Do you want to synchronization related projects?')}
        />
      )}
      {openBulkRunDeleteModal && (
        <>
          <ConfirmationModal
            isOpen={openBulkRunDeleteModal}
            loading={bulkRunDelete?.loading}
            closeModal={() => setOpenBulkRunDeleteModal(false)}
            handleConfirm={() => handleBulkRunDelete()}
            header={t('Move To Trash')}
            content={t('confirmation_delete_modal_content', {
              selectedRowsIds: selectedRows?.length,
              entityName: getRunName(projectConfig)
            })}
          />
        </>
      )}
      {/* Confirmation Modals End*/}
    </>
  );
};

export default BulkDropdown;
