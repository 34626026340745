import { ENTITY_TYPES } from "../../constants";
import { isValidValue } from "../Helper";
//
export const getJiraStatus = (jiraContent) => {
    if (jiraContent && isValidValue(jiraContent?.fields?.status?.name)) {
        return jiraContent?.fields?.status?.name;
    }
    return "";
}
//
export const getJiraPriority = (jiraContent) => {
    if (jiraContent && isValidValue(jiraContent?.fields?.priority?.name)) {
        return jiraContent?.fields?.priority?.name;
    }
    return "";
}
//
export const getJiraKeyByJiraConfig = (item, jiraConfig) => {
    let jiraKey = "";
    //
    if (jiraConfig && jiraConfig !== null && item?.jiraKey) {
        if (jiraConfig?.activeRequirement === true && item?.entityType === ENTITY_TYPES.REQUIREMENT)
            jiraKey = " (Jira: " + item.jiraKey + ")";
        else if (jiraConfig?.activeDefect === true && item?.entityType === ENTITY_TYPES.DEFECT)
            jiraKey = " (Jira: " + item.jiraKey + ")";
    }
    return jiraKey;
}
//
const getJiraKeyText = (key) => {
    return " (Jira: " + key + ")";
}
//
const getJiraKeyAndStatusText = (key, status) => {
    if (isValidValue(status))
        return " (Jira: " + key + ") (Jira Status: " + status + ")";
    return getJiraKeyText(key);
}
//
export const getJiraKeyAndStatusJiraConfig = (item, jiraConfig) => {
    let jiraKey = "";
    //
    if (jiraConfig && jiraConfig !== null && item?.jiraKey) {
        if (jiraConfig?.activeRequirement === true && item?.entityType === ENTITY_TYPES.REQUIREMENT)
            jiraKey = getJiraKeyAndStatusText(item?.jiraKey, getJiraStatus(item?.jiraContent));
        else if (jiraConfig?.activeDefect === true && item?.entityType === ENTITY_TYPES.DEFECT)
            jiraKey = getJiraKeyAndStatusText(item?.jiraKey, getJiraStatus(item?.jiraContent));
    }
    //
    return jiraKey;
}
//
export const isJiraContentValid = (jiraConfig, data) => {
    return isValidValue(jiraConfig) && jiraConfig?.active === true && isValidValue(data?.jiraContent) && isValidValue(data?.jiraContent?.id);
}
