import { CaretLeft, CaretRight } from 'phosphor-react';
import { forwardRef } from 'react';
import './Layout.css';
import { classNames } from '../helpers/Helper';
export const TOP_HEADING_SIZE = 'medium';

//TODO: scroll bar pozisyon değiştirilecek project list sayfasında
export function FullPageContainer({ children, p }) {
  let css = 'flex flex-col flex-grow p-8';
  if (p) {
    css = 'flex flex-col flex-grow ' + p;
  }

  return (
    <>
      <div className={`${css} overflow-auto`}>{children}</div>
    </>
  );
}

export function FullPageContent(props) {
  return (
    <>
      <div className="fullpage-scroll-container mt-8">{props?.children}</div>
    </>
  );
}

export function SidePaneLeftContent({ children }) {
  return <div className={`h-full w-80 flex-shrink-0 bg-gray-50`}>{children}</div>;
}

const Buttons = ({ children }) => {
  return (
    <>
      <div className="treeview-buttons">{children}</div>
    </>
  );
};

const Heading = ({ children }) => {
  return (
    <>
      <div className="treeview-heading"> {children}</div>
    </>
  );
};

const Treeview = ({ children }) => {
  return (
    <>
      <div className="treeview-container">{children}</div>
    </>
  );
};
SidePaneLeftContent.Buttons = Buttons;
SidePaneLeftContent.Heading = Heading;
SidePaneLeftContent.Treeview = Treeview;

//---//----------------------------------------------------------------
// SidePane Right Content
//---//
export function SidePaneRightContent(props) {
  // TODO: inline style kaldırılacak
  let customStyle = { zIndex: 1 };
  if (props?.overflow === true) {
    customStyle = { ...customStyle };
  }

  return (
    <>
      <div
        id="layout_sidePaneRightContent"
        className="flex flex-grow flex-col"
        style={customStyle}
      >
        {' '}
        {props?.children}
      </div>
    </>
  );
}

const Top = ({ children, px }) => {
  let css = 'bg-white px-8  py-4 border-b';
  if (px) {
    css = 'bg-white ' + px + ' py-4 border-b';
  }
  return (
    <>
      <div
        id="layout_top"
        className={`${css} max-h-20 w-full`}
      >
        {children}
      </div>
    </>
  );
};

const Pane = ({ children, open, setOpen, isModal }) => {
  const returnHideShowButton = () => {
    const btnClassName = `relative top-3 -left-3 bg-primary-600 h-6 w-6 p-1 rounded-full flex items-center justify-center text-white cursor-pointer`;

    if (isModal !== true) {
      if (open) {
        return (
          <>
            <div
              onClick={() => {
                setOpen(open ? false : true);
              }}
              className={btnClassName}
            >
              <CaretRight weight="bold" />
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              onClick={() => {
                setOpen(!open);
              }}
              className={btnClassName}
            >
              <CaretLeft weight="bold" />
            </div>
          </>
        );
      }
    }
  };

  if (open || isModal) {
    return (
      <>
        <div className="col-span-3 block min-h-screen border-l bg-gray-100 ">
          {returnHideShowButton()}
          <div className="rightPane-container p-4">{children}</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className="h-screen border-l bg-gray-100 "
          style={{ gridColumn: 'none' }}
        >
          {returnHideShowButton()}
        </div>
      </>
    );
  }
};

const Main = ({ children, isfullPage }) => {
  return (
    <>
      <div className={classNames(isfullPage ? `sp_rightContent-Full-Page--main` : `sp_rightContent--main`)}>
        {children}
      </div>
    </>
  );
};

/* eslint-disable react/display-name */
const Center = forwardRef((props, ref) => {
  const { children, paneIsOpen, colFull, isModal } = props;

  let customGridCol = paneIsOpen || isModal ? ' span 9 / span 9 ' : ' span 12 / span 11 ';

  if (colFull === true) {
    customGridCol = ' span 12 / span 12 ';
  }

  return (
    <>
      <div
        ref={ref}
        className="sp_rightContent--center"
        style={{ gridColumn: customGridCol }}
      >
        {children}
      </div>
    </>
  );
});

SidePaneRightContent.Pane = Pane;
SidePaneRightContent.Top = Top;
SidePaneRightContent.Main = Main;
SidePaneRightContent.Center = Center;

//----------------------------------------------------------------
// SidePane Main Content
//
// TODO! DELETE
export function SidePaneMainContent(props) {
  return (
    <>
      <div className="bg-white ">{props.children}</div>
    </>
  );
}

const Layout = () => {
  return <></>;
};

export default Layout;
