import { Controller } from 'react-hook-form';
import { classNames, errorMessage } from '../../helpers/Helper';
import { Trans, useTranslation } from 'react-i18next';

const TextArea = ({ value, label, placeholder, required, disabled, control, name, passValue }) => {
  const { t } = useTranslation();

  let LABEL_CLASS = 'text-sm font-medium  block mb-2 capitalize';
  let LABEL_SPAN_CLASS = ' ml-2 ';

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: required }}
        render={({ field, formState }) => (
          <>
            <div className="mb-1 flex flex-grow flex-col px-1">
              <label className={LABEL_CLASS}>
                {label}
                {required && <span className={!disabled && LABEL_SPAN_CLASS}> *</span>}
              </label>
              <textarea
                className={classNames(
                  'rounded-lg p-2 text-sm text-gray-800 placeholder-gray-400 focus:border-primary-400 focus:outline-none',
                  'focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0',
                  !disabled && 'border border-gray-300 bg-white text-gray-900'
                )}
                placeholder={placeholder ? '  ' + placeholder : ''}
                disabled={disabled ? true : false}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  if (passValue) passValue(e?.target?.value);
                }}
              >
                {value}
              </textarea>
              {formState?.errors?.[name] && formState?.errors[name]?.type === 'required' && (
                <Trans t={t}>{errorMessage('required', label, name, t)}</Trans>
              )}
            </div>
          </>
        )}
      />
    </>
  );
};

export { TextArea };
