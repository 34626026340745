import { getRunStatusName, isValidList } from '../../../helpers/Helper';
import Table from '../../table/Table';
import { useTranslation } from 'react-i18next';

const TesterExecutionInsight2 = ({ response }) => {
  const { t } = useTranslation();

  const getArrList = () => {
    if (isValidList(response)) {
      let arr2 = [];
      let statusColumnKey = [];
      response.map((item) => {
        let statusList2 = [];

        let bucketList = item?.['1']?.buckets;
        if (isValidList(bucketList)) {
          bucketList.map((i) => {
            let statusName = getRunStatusName(i?.key);
            if (!statusColumnKey.includes(statusName)) statusColumnKey.push(statusName);
            statusList2.push({ status: statusName, total: i?.doc_count });
          });
        }

        arr2.push({
          tester: item?.key,
          total: item?.doc_count,
          statuses: statusList2
        });
      });
      return { dataList: arr2, statusList: statusColumnKey.sort() };
    } // if end

    return { dataList: [], statusList: [] };
  };

  const { dataList, statusList } = getArrList();

  const findStatusTotalVal = (statusKey, item) => {
    let d = item?.statuses.find((x) => x?.status === statusKey);
    if (d && d?.total > 0) return d?.total;
    return 0;
  };

  let tableData = [];
  // Define static table columns
  let tableColumns = [
    { Header: t('Tester / Execution Status'), accessor: 'tester' },
    { Header: t('Total'), accessor: 'total' }
  ];
  // Push additional columns to arr
  statusList.forEach((statusKey) => {
    tableColumns.push({ Header: t(statusKey), accessor: statusKey });
  });

  tableData = dataList.map((item) => {
    let obj;
    item.statuses.forEach((i) => {
      obj = {
        ...obj,
        [i.status]: findStatusTotalVal(i.status, item)
      };
    });
    return {
      tester: item?.tester,
      total: item?.total,
      ...obj
    };
  });

  return (
    <>
      <Table data={tableData} columns={tableColumns} compact />
    </>
  );
};

export default TesterExecutionInsight2;
