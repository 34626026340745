import React from 'react';
import { useForm } from 'react-hook-form';

import { useApi } from '../../../../hooks/UseApi';
import MailConfigApi from '../../../../api/MailConfigApi';

import { Input, TextArea, Button } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import { showErrorMes, showSuccessMes, INFO_MESSAGES } from '../../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';

const MailServerTestModal = ({ isOpen, selectedItem, closeModal }) => {
  const { control, handleSubmit, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();

  const testMailServer = useApi({
    name: 'testMailServer ',
    api: MailConfigApi.test,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.mailSend);
      closeModal(testMailServer?.data);
    },
    onError: () => showErrorMes(t('Mail could not be send.'))
  });

  const onSubmit = (form) => {
    let input = {
      emails: form?.emails,
      subject: form?.subject,
      message: form?.message
    };
    let item = {
      testMessage: input,
      ...selectedItem
    };
    testMailServer.execute(item);
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
        w={'md'}
      >
        <Modal.Title>{t('Test Your Mail Server Settings')}</Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-5">
              <Input
                label={t('Email Address')}
                name={'emails'}
                placeholder={t(`Enter`)}
                control={control}
                required
              />
              {/* please do not apply translation to Subject field string */}
              <Input
                label={t('Subject')}
                name={'subject'}
                placeholder={t('Testing SMTP Settings')}
                control={control}
                disabled
              />
              <TextArea
                placeholder={t('Enter your text here')}
                control={control}
                name="message"
                label={t('Message')}
              />
            </div>
          </Modal.Content>

          <Modal.Buttons>
            <Button
              id="mailServerTestModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={testMailServer?.loading}
            />
            <Button
              id="mailServerTestModal-close-button"
              secondary
              text={t('Close')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default MailServerTestModal;
