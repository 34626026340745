import { axios } from "./ApiClient";

const BASE_URL = "/api/";
const PATH = BASE_URL + "user-preferences";


class UserPreferenceApi {

    async getTablePreferences(data) {
        const resp = await axios.get(`${PATH}/table-preferences`, { params: data })
        return resp.data;
    }

    async saveTableColumns(data) {
        const resp = await axios.post(`${PATH}/table-columns`, data);
        return resp.data;
    }

    async saveTableFilters(data) {
        const resp = await axios.post(`${PATH}/table-filters`, data);
        return resp.data;
    }
    
}

export default new UserPreferenceApi();