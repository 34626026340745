import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ExportFileApi from '../../api/ExportFileApi';
// Design System Imports
import { H5, Button, H6 } from '../../designSystem/DesignSystem';
import { ENTITY_TYPES } from '../../constants';
// Helper Imports
import { getArtifactName, getFieldsArtifactName } from '../../helpers/ArtifactNameHelper';
import { getReportExcelNameDate } from '../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { useApi } from '../../hooks/UseApi';
// Api and Hook Imports
import UseProjectConfig from '../../hooks/UseProjectConfig';
import { Modal } from '../modal';
import { useTranslation } from 'react-i18next';
import SystemParamApi from '../../api/SystemParamApi';

const ExportFileModal = ({ isOpen, closeModal, entityType, projectId, filters, defaultFilters }) => {
  const { t } = useTranslation();
  const projectConfig = UseProjectConfig(projectId);
  const [exportTemplate, setExportTemplate] = useState([]);
  const [clear, setClear] = useState(false);
  const [entityExportFields, setEntityExportFields] = useState({});

  useEffect(() => {
    getEntityExportFields.execute();
    getExportFieldDefaultTemplate.execute(entityType);
  }, [entityType]);

  useEffect(() => {
    if (Object.keys(entityExportFields).length !== 0) {
      if (Object.keys(entityExportFields).filter((fieldName) => entityExportFields[fieldName] === true).length === 0) {
        setClear(true);
      } else {
        setClear(false);
      }
    }
  }, [entityExportFields]);

  const exportApi = () => {
    switch (entityType) {
      case ENTITY_TYPES.REQUIREMENT:
        return ExportFileApi.exportRequirements;
      case ENTITY_TYPES.TEST_CASE:
        return ExportFileApi.exportTestCases;
      case ENTITY_TYPES.DEFECT:
        return ExportFileApi.exportDefects;
      case ENTITY_TYPES.TEST_RUN:
        return ExportFileApi.exportTestRun;
      case ENTITY_TYPES.PROJECT:
        return ExportFileApi.exportProjects;
      default:
        return null;
    }
  };

  const getExportFieldDefaultTemplate = useApi({
    name: 'Get export field default template',
    api: ExportFileApi.getExportTemplateWithoutCustomFields,
    onSuccess: () => {
      setExportTemplate(getExportFieldDefaultTemplate.data);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const getEntityExportFields = useApi({
    name: 'Get entity export fields',
    api: SystemParamApi.getExportFields,
    onSuccess: () => {
      setEntityExportFields(JSON.parse(getEntityExportFieldParamValueFromSystemParams(entityType)?.paramVal));
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const exportFile = useApi({
    name: 'exportRequirements',
    api: exportApi(),
    onSuccess: () => {
      downloadExportingFile(exportFile?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const getEntityExportFieldParamValueFromSystemParams = (entityType) => {
    switch (entityType) {
      case ENTITY_TYPES.PROJECT:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'projectExportFieldDefaults'
        )[0];
      case ENTITY_TYPES.REQUIREMENT:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'requirementExportFieldDefaults'
        )[0];
      case ENTITY_TYPES.TEST_CASE:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'testCaseExportFieldDefaults'
        )[0];
      case ENTITY_TYPES.TEST_RUN:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'testRunExportFieldDefaults'
        )[0];
      case ENTITY_TYPES.DEFECT:
        return getEntityExportFields?.data?.filter(
          (entityExportField) => entityExportField?.paramKey === 'defectExportFieldDefaults'
        )[0];
      default:
        return null;
    }
  };

  const exportFieldByName = (fieldName) => {
    return exportTemplate.filter((field) => field.name === fieldName)[0];
  };

  const populateToUpdateData = () => {
    let toUpdateObject = [];
    for (const fieldName in entityExportFields) {
      toUpdateObject.push({
        ...exportFieldByName(fieldName),
        value: getFieldsArtifactName(projectConfig, entityType, fieldName, exportFieldByName(fieldName)?.value),
        required: entityExportFields[fieldName]
      });
    }
    return toUpdateObject.filter((field) => field.required === true);
  };

  const downloadExportingFile = (data) => {
    const fileName = getReportExcelNameDate() + '_' + entityType + '_report.xls';
    const url = window.URL.createObjectURL(new Blob([data], { entityType: 'application/vnd.ms-excel' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    closeModal();
    showSuccessMes(INFO_MESSAGES.export);
  };

  const onClickAdd = (fieldName) => {
    setEntityExportFields({ ...entityExportFields, [fieldName]: true });
  };

  const onClickRemove = (fieldName) => {
    setEntityExportFields({ ...entityExportFields, [fieldName]: false });
  };

  const downloadFile = () => {
    const parentId = defaultFilters?.parentId;
    exportFile.execute({
      selectedResults: populateToUpdateData(),
      filters: { ...filters, parentId, ...defaultFilters },
      projectId
    });
  };

  const selectAll = () => {
    let selectAllObject = {};
    for (const fieldName in entityExportFields) {
      selectAllObject = { ...selectAllObject, [fieldName]: true };
    }
    setEntityExportFields(selectAllObject);
  };

  const clearSelecteds = () => {
    let clearSelectedsObject = {};
    for (const fieldName in entityExportFields) {
      clearSelectedsObject = { ...clearSelectedsObject, [fieldName]: false };
    }
    setEntityExportFields(clearSelectedsObject);
  };

  const isAllSelected = () => {
    return (
      Object.keys(entityExportFields).filter((fieldName) => entityExportFields[fieldName] === true).length ===
      Object.keys(entityExportFields).length
    );
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t('export_entity', { entity: getArtifactName(projectConfig, entityType) })}</Modal.Title>

        <Modal.Content h={'md'}>
          <div>
            <span>
              <H6
                text={t('export_file_entity', { entity: getArtifactName(projectConfig, entityType) })}
                color={'text-gray-500'}
              ></H6>
              <H6
                text={t(
                  'Make sure all fields are chosen as you want. The excel file will be generated in as mapped by you.'
                )}
                color={'text-gray-500'}
              ></H6>
            </span>
            <div className="flex flex-grow flex-col">
              <div className="grid grid-cols-2 divide-x">
                <div className="px-3">
                  <div className="flex ">
                    <H5
                      text={t('Template Fields')}
                      className={'mb-1 font-semibold'}
                    />
                  </div>
                  <div className="flex">
                    <table className="flex-grow">
                      <thead className="flex-grow border-b-2 border-gray-300 ">
                        <tr className="flex flex-grow text-xs font-normal">
                          <div className="flex-grow">
                            <th>{t('Field')}</th>
                          </div>
                          <th className="w-1/4">{t('Add')}</th>
                        </tr>
                      </thead>
                      <tbody className="flex-grow ">
                        {getEntityExportFields?.loading ? (
                          <span>{t('Loading')}</span>
                        ) : !_.isEmpty(entityExportFields) ? (
                          Object.keys(entityExportFields).map((fieldName, index) => {
                            return (
                              <tr
                                key={index}
                                className="flex flex-grow"
                              >
                                <td className="w-3/4 place-content-start self-start p-1">
                                  <H5
                                    color={'text-black'}
                                    text={getFieldsArtifactName(
                                      projectConfig,
                                      entityType,
                                      fieldName,
                                      exportFieldByName(fieldName)?.value
                                    )}
                                  />
                                </td>
                                <td className="flex w-1/4 place-content-center self-center">
                                  <Button
                                    id="exportFileModal-add-button"
                                    secondary
                                    icon="ArrowRight"
                                    size="xsmall"
                                    disabled={entityExportFields[fieldName]}
                                    onClick={() => onClickAdd(fieldName)}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <span className="text-xs font-semibold text-gray-500 ">
                            {t('No data found with this query')}{' '}
                          </span>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="px-3">
                  <div className="flex ">
                    <H5
                      text={t('Selected Fields')}
                      className={'mb-1 font-semibold'}
                    />
                  </div>
                  <div className="flex">
                    <table className="flex-grow">
                      <thead className="flex-grow border-b-2 border-gray-300 ">
                        <tr className="flex flex-grow text-xs font-normal">
                          <th className="w-1/4">{t('Remove')}</th>
                          <th>{t('Field')}</th>
                        </tr>
                      </thead>
                      <tbody className="flex-grow ">
                        {!_.isEmpty(entityExportFields) ? (
                          Object.keys(entityExportFields)
                            .filter((fieldName) => entityExportFields[fieldName] === true)
                            .map((fieldName, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="flex flex-grow"
                                >
                                  <td className="flex w-1/4 place-content-center self-center ">
                                    <Button
                                      id="exportFileModal-remove-button"
                                      secondary
                                      onClick={() => onClickRemove(fieldName)}
                                      icon="ArrowLeft"
                                      size="xsmall"
                                    />
                                  </td>
                                  <td className="p-1">
                                    <H5
                                      color={'text-black'}
                                      text={getFieldsArtifactName(
                                        projectConfig,
                                        entityType,
                                        fieldName,
                                        exportFieldByName(fieldName)?.value
                                      )}
                                    />
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <span className="text-xs font-semibold text-gray-500 ">
                            {t('There is no data selected.')}
                          </span>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="exportFileModal-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={() => closeModal()}
          />
          <Button
            id="exportFileModal-selectNone-button"
            secondary
            text={t('Select None')}
            disabled={clear}
            onClick={() => clearSelecteds()}
          />
          <Button
            id="exportFileModal-selectAll-button"
            secondary
            text={t('Select All')}
            disabled={(exportTemplate && exportTemplate.length === 0) || isAllSelected() ? true : false}
            onClick={() => selectAll()}
          />
          <Button
            id="exportFileModal-export-button"
            primary
            text={t('Export')}
            onClick={() => downloadFile()}
            disabled={clear}
            loading={exportFile?.loading}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};
export default ExportFileModal;
