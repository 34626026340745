import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useApi } from '../../../../../hooks/UseApi';
import VisiumGoIntegrationApi from '../../../../../api/VisiumGoIntegrationApi';

import { Modal } from '../../../../../components/modal';
import { Button, Input, TextArea, Toggle } from '../../../../../designSystem/DesignSystem';

import { encryptData } from '../../../../../helpers/CryptoHelper';
import { editModalMapping, isValidValue } from '../../../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../../helpers/NotificationHelper';

const VisiumGoIntegrationModal = ({ isOpen, data, closeModal, projectId }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const [activation, setActivation] = useState(false);

  const createEditData = useApi({
    name: 'saving visium go integration config',
    api: isValidValue(data) ? VisiumGoIntegrationApi.update : VisiumGoIntegrationApi.create,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal();
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = async (form) => {
    const encrApiKey = await encryptData(form?.goApiKey);
    let input = {
      ...form,
      name: form?.name,
      url: form?.url,
      active: activation,
      goApiKey: encrApiKey,
      note: form?.note,
      projectId
    };
    createEditData.execute({ ...input, id: data?.id });
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setActivation(data?.active + '' === 'true');
    }
  }, [data]);

  return (
    <Modal
      //TODO:MODAL CSS DEĞİŞİKLİKLERİ UYGULANACAK
      open={isOpen}
      close={() => closeModal()}
    >
      <Modal.Title>
        {isValidValue(data) ? t('Edit Visium Go Integration') : t('Create New Visium Go Integration')}
      </Modal.Title>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Content>
          <div className="flex flex-grow flex-col gap-4">
            <div className="flex flex-grow gap-4">
              <Input
                required
                label={t('Name')}
                name={'name'}
                placeholder={t('Enter a Name')}
                control={control}
              />
              <Input
                required
                label={t('Url')}
                name={'url'}
                placeholder={t('Enter a Url')}
                control={control}
              />
            </div>
            <TextArea
              placeholder={t('Enter your Api Key')}
              control={control}
              name="goApiKey"
              label={t('Api Key')}
            />
            <Toggle
              labelLeft
              labelText={t('Activation')}
              value={activation}
              control={control}
              name="active"
              passValue={(value) => setActivation(value)}
            />
            <TextArea
              placeholder={t('Enter your note')}
              control={control}
              name="note"
              label={t('Note')}
            />
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="visiumGo-submit-button"
            primary
            text={t('Submit')}
            type="submit"
            loading={createEditData?.loading}
          />
          <Button
            id="visiumGo-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={() => {
              closeModal();
              reset();
            }}
          />
        </Modal.Buttons>
      </form>
    </Modal>
  );
};

export default VisiumGoIntegrationModal;
