import React, { useState } from 'react';
import TestCaseStepApi from '../../../api/TestCaseStepApi';
import { Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import TestCaseSearchTree from '../../../components/treeView/TestCaseSearchTree';
import { getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import { showErrorMes, INFO_MESSAGES, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import { ENTITY_TYPES } from '../../../constants';

const TestCaseAddStepModal = (props) => {
  const { isOpen, closeModal, testCaseId, submitCallBack, projectConfig } = props;
  const [selectedTestCases, setSelectedTestCases] = useState([]);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const { t } = useTranslation();

  const assignTestCaseStep = useApi({
    name: 'assign test case step',
    api: TestCaseStepApi.assignStepTestCases,
    autoLoad: false,
    onSuccess: () => {
      closeModal(true);
      showSuccessMes(INFO_MESSAGES.add);
      submitCallBack();
    },
    onError: (err) => {
      const errorMessage = err.response.data.message;
      if (errorMessage.startsWith('Since')) {
        const testCaseName = getSubstringBetween(errorMessage, 'Since', 'is');
        const testCaseToBeAssignedName = getSubstringBetween(errorMessage, 'inside', ',');
        showErrorMes(
          t('add_test_case_as_a_step_to_includes_assigned_test_case_error', {
            testCaseName: testCaseName,
            testCaseToBeAssignedName: testCaseToBeAssignedName
          })
        );
      } else if (errorMessage.includes('You cannot add itself as a step into')) {
        const testCaseName = errorMessage.split('into')[1].trim();
        showErrorMes(t('add_test_case_as_a_step_to_itself_error', { testCaseName: testCaseName }));
      } else {
        showErrorMes(err);
      }
    }
  });

  const getSubstringBetween = (input, startWord, endWord) => {
    const regex = new RegExp(`${startWord}(.*?)${endWord}`);
    const match = input.match(regex);
    return match ? match[1].trim() : null;
  };

  const onSubmit = () => {
    const testCaseIds = selectedTestCases;
    const testContainerIds = selectedContainers;
    assignTestCaseStep.execute({ testCaseId, testCaseIds, testContainerIds });
  };

  return (
    <Modal
      open={isOpen}
      close={() => closeModal()}
      w="md"
    >
      <Modal.Title>{getTestCaseName(projectConfig)}</Modal.Title>
      <Modal.Content>
        <TestCaseSearchTree
          type={ENTITY_TYPES.TEST_CASE}
          setSelectedTestCases={setSelectedTestCases}
          setSelectedContainers={setSelectedContainers}
        />
      </Modal.Content>
      <Modal.Buttons>
        <Button
          id="testCaseAddStepModal-cancel-button"
          secondary
          text={t('Cancel')}
          onClick={() => closeModal()}
        />
        <Button
          id="testCaseAddStepModal-submit-button"
          primary
          text={t('Submit')}
          onClick={() => onSubmit()}
          loading={assignTestCaseStep?.loading}
        />
      </Modal.Buttons>
    </Modal>
  );
};
export default TestCaseAddStepModal;
