import React from 'react';
import { attachmentandCommentListSize } from '../../helpers/Helper';
import AttachmentItem from './AttachmentItem';

const AttachmentList = ({ attachments, getFiles, users, disabled, entityType }) => {
  return (
    <>
      <div
        id="attachment-list"
        className="flex h-[300px] w-full flex-col  space-y-4 divide-y-2 overflow-y-auto"
        style={{ height: attachmentandCommentListSize(entityType, 'attachment') }}
      >
        {attachments?.map((item) => (
          <>
            <AttachmentItem
              item={item}
              getFiles={getFiles}
              users={users}
              disabled={disabled}
            />
          </>
        ))}
      </div>
    </>
  );
};

export default AttachmentList;
