import React, { useEffect, useState } from 'react';
// HOOKS & API
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks/UseApi';
import TablePersonalPrefencesApi from '../../api/TablePersonalPrefencesApi';
import UseTableColumns from '../../hooks/UseTableColumns';
// HELPER & CONSTANTS
import { isValidList, isValidValue } from '../../helpers/Helper';
import { isProjectManager } from '../../helpers/PriviligeHelper';
import { showError, showErrorMes } from '../../helpers/NotificationHelper';
// DESIGN SYSTEM & COMPONENTS
import Popup from '../popup/Popup';
import ConfirmationModal from '../confirm/ConfirmationModal';
import { Dropdown, Input, Toggle, Button } from '../../designSystem/DesignSystem';
import { isEmpty } from 'lodash-es';

export const TABLE_PREFENCES_STR = 'table-prefences';
export const BUTTON_SIZE = 'small';

const TablePersonalPrefences = ({ entityType, projectId, filters, setFilters, setRefreshTable }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const {
    selectedFields = [],
    onChangeSelectedColumns,
    onResetSelectedColumns
  } = UseTableColumns(projectId, entityType);

  const [savedTables, setSavedTables] = useState([]);
  const [name, setName] = useState();
  const [isGlobal, setIsGlobal] = useState(false);
  const [isTableModalOpened, setIsTableModalOpened] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const tablePrefences = JSON.parse(localStorage.getItem(TABLE_PREFENCES_STR));
  const optionsfilter = (savedTables || []).map((item) => ({ key: item?.id, name: item?.name, value: item?.id }));

  const getSavedPersonalPrefencesList = useApi({
    name: 'get saved personal filters',
    api: TablePersonalPrefencesApi.getSavedPersonalPrefencesList,
    autoLoad: false,
    onSuccess: (res) => {
      setSavedTables(res);
      changedFields(res);
    },
    onError: (err) => showErrorMes(err)
  });

  const updatePersonalTablePrefences = useApi({
    name: 'update personal filter',
    api: TablePersonalPrefencesApi.updatePersonalTablePrefences,
    autoLoad: false,
    onSuccess: (response) => {
      getSavedTables();
      setIsTableModalOpened(false);
      updatePreferenceAndLocalStorage({
        key: response.id,
        name: response.name,
        value: response.id
      });
    },
    onError: (err) => showErrorMes(err)
  });

  const savePersonalTablePrefences = useApi({
    name: 'create personal filter',
    api: TablePersonalPrefencesApi.savePersonalTablePrefences,
    autoLoad: false,
    onSuccess: (response) => {
      getSavedTables();
      setIsTableModalOpened(false);
      updatePreferenceAndLocalStorage({
        key: response.id,
        name: response.name,
        value: response.id
      });
    },
    onError: (err) => showError(err)
  });

  const deletePersonalTablePrefences = useApi({
    name: 'delete personal filter',
    api: TablePersonalPrefencesApi.deletePersonalTablePrefences,
    autoLoad: false,
    onSuccess: () => {
      setIsGlobal(false);
      setName();
      updatePreferenceAndLocalStorage(null);
      getSavedTables();
      onResetSelectedColumns();
      setOpenDeleteModal(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const updateLocalStorage = (key) => {
    const newTablePrefences = { ...tablePrefences } || {};
    if (tablePrefences) {
      newTablePrefences[projectId] = newTablePrefences[projectId] || {};
      newTablePrefences[projectId][entityType] = key;
    }
    localStorage.setItem(TABLE_PREFENCES_STR, JSON.stringify(newTablePrefences));
  };

  const updateFields = () => {
    const selectedTable =
      selectedPreference != null ? savedTables?.find((item) => item?.id === selectedPreference?.key) : null;
    if (selectedTable) {
      setName(selectedTable?.name);
      setIsGlobal(selectedTable?.haveEveryoneAccess);
      onChangeSelectedColumns(JSON.parse(selectedTable?.selectedAndOrderedFields));
      const f = JSON.parse(selectedTable?.filterParams);
      setFilters(f);
      setRefreshTable(true);
    } else if (!selectedTable && isEmpty(selectedPreference)) {
      setName();
      setIsGlobal(false);
      onResetSelectedColumns();
      setFilters({});
      setRefreshTable(true);
    }
  };

  const getSavedTables = () => {
    if (entityType) {
      getSavedPersonalPrefencesList.execute(entityType);
    }
  };

  const hasPersonalPreferenceExist = (body) => {
    let alreadyExist = false;
    if (getSavedPersonalPrefencesList?.data?.length > 0) {
      const tempArray = getSavedPersonalPrefencesList?.data;
      tempArray.forEach((preference) => {
        if (body.name === preference.name) {
          alreadyExist = true;
        }
      });
    }
    return alreadyExist;
  };

  const addAction = () => {
    const body = {
      name,
      haveEveryoneAccess: isGlobal,
      entity: entityType,
      selectedAndOrderedFields: selectedFields,
      filterParams: filters
    };

    const alreadyExist = hasPersonalPreferenceExist(body);
    if (!alreadyExist) {
      savePersonalTablePrefences.execute(body);
    }
  };

  const updateAction = () => {
    const body = {
      name,
      haveEveryoneAccess: isGlobal,
      entity: entityType,
      selectedAndOrderedFields: selectedFields,
      filterParams: filters
    };

    updatePersonalTablePrefences.execute({ ...body, id: selectedPreference?.key });
  };

  const onDelete = () => {
    if (selectedPreference?.key) {
      deletePersonalTablePrefences.execute(selectedPreference?.key);
    }
  };

  const updatePreferenceAndLocalStorage = (key) => {
    setSelectedPreference(key);
    updateLocalStorage(key);
  };

  const changedFields = (savedFilters) => {
    if (savedFilters && savedFilters.length > 0) {
      let lastSelected;
      if (tablePrefences && tablePrefences[projectId] && tablePrefences[projectId][entityType]) {
        lastSelected = tablePrefences[projectId][entityType];
      }
      const selectedTable = savedFilters.find((item) => item.id === lastSelected?.key);

      if (selectedTable) {
        updatePreferenceAndLocalStorage({
          key: selectedTable?.id,
          name: selectedTable?.name,
          value: selectedTable?.key
        });
      } else {
        updateFields();
      }
    } else if (savedFilters.length == 0 && selectedPreference === null) {
      updateFields();
    }
  };

  useEffect(() => {
    getSavedTables();
  }, [entityType, projectId]);

  useEffect(() => {
    if (selectedPreference != null) updateFields();
  }, [selectedPreference]);

  useEffect(() => setValue('name', name), [name]);

  return (
    <div className="flex items-start gap-2">
      <div className="flex min-w-[250px] items-start gap-4">
        <Dropdown
          textSize="text-xs"
          buttonH="h-8"
          control={control}
          name="selectedPreference"
          placeholder={t('Select Table Setting')}
          disabled={optionsfilter.length === 0}
          selectedItem={selectedPreference}
          options={optionsfilter}
          passValue={(value) => {
            if (isValidList(value)) updatePreferenceAndLocalStorage(value[0]);
            else updatePreferenceAndLocalStorage({});
          }}
          clearable
          style={{ width: 250 }}
        />
      </div>
      <div className="flex items-center gap-2">
        <Popup
          open={isTableModalOpened}
          setOpen={setIsTableModalOpened}
          panelWidth={500}
          button={() => (
            <>
              <Button
                secondary2
                weight="bold"
                size="xsmall"
                onClick={() => setIsTableModalOpened(true)}
                icon={isValidValue(selectedPreference) ? 'PencilLine' : 'FloppyDisk'}
              />
            </>
          )}
          content={() => (
            <>
              <form
                className="flex flex-col gap-1"
                onSubmit={handleSubmit(() => {})}
              >
                <Popup.Title textSize="small">{t('Save Table Setting with columns')}</Popup.Title>
                <div className="flex flex-grow flex-col gap-4">
                  <Input
                    name="name"
                    placeholder={t('Name')}
                    control={control}
                    type="text"
                    passValue={(value) => setName(value)}
                  />
                  {isProjectManager(projectId) && (
                    <Toggle
                      labelRight
                      control={control}
                      value={isGlobal}
                      name="isGlobal"
                      labelText={t('Everyone can access?')}
                      passValue={(value) => {
                        setIsGlobal(value);
                      }}
                    />
                  )}
                </div>
                <Popup.Buttons>
                  {!isEmpty(selectedPreference) && (
                    <Button
                      id="selectedTable-save-button"
                      disabled={!isValidValue(name?.trim()) || isEmpty(selectedPreference)}
                      onClick={() => updateAction()}
                      text={t('Save')}
                      loading={updatePersonalTablePrefences?.loading}
                      secondary
                      size="small"
                    />
                  )}
                  <Button
                    id="selectedTable-saveAsNew-button"
                    text={t('Save as new')}
                    weight="bold"
                    size="xsmall"
                    disabled={!isValidValue(name?.trim()) || getValues('name') === selectedPreference?.name}
                    loading={savePersonalTablePrefences?.loading}
                    primary
                    onClick={() => addAction()}
                  />
                </Popup.Buttons>
              </form>
            </>
          )}
        ></Popup>
        <div>
          {isValidValue(selectedPreference) ? (
            <Button
              id="selectedTable-deleteFilter-button"
              tooltip={t('Delete Filter')}
              secondary2
              weight="bold"
              size="xsmall"
              icon="Trash"
              onClick={() => setOpenDeleteModal(true)}
            />
          ) : null}
        </div>
      </div>
      {openDeleteModal && (
        <ConfirmationModal
          isOpen={openDeleteModal}
          loading={deletePersonalTablePrefences?.loading}
          closeModal={() => setOpenDeleteModal(false)}
          handleConfirm={onDelete}
          header={t('Confirm Delete')}
          content={t('Do you really want to delete this filter?')}
        />
      )}
    </div>
  );
};

export default TablePersonalPrefences;
