import React, { useState, useEffect, useMemo } from 'react';
// API & HOOKS IMPORTS
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// API & HOOKS IMPORTS
import JiraApi from '../../../../api/JiraApi';
import { useApi } from '../../../../hooks/UseApi';
import UseProjectConfig from '../../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../../hooks/UseQueryProject';
// COMPONENTS
import ConfirmationModal from '../../../../components/confirm/ConfirmationModal';
import JiraEntitySettingModal from '../modal/JiraEntitySettingModal';
import JiraRetrieveOptProgressModal from '../modal/JiraRetrieveOptProgressModal';
import JiraRetriveStartModal from '../modal/JiraRetriveStartModal';
import BasicTable from '../../../../components/table/BasicTable';
// DESIGN SYSTEM & LAYOUTS
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../../helpers/ButtonConstants';
import { Toggle, Button, Heading } from '../../../../designSystem/DesignSystem';
import { PencilLine, Sliders, Trash } from 'phosphor-react';
// HELPER & CONSTANTS
import { ENTITY_TYPES } from '../../../../constants';
import { isValidValue } from '../../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { getArtifactName, getArtifactNameLower } from '../../../../helpers/ArtifactNameHelper';
import {
  disableResizingForColumns,
  findRow,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../../helpers/TableHelper';
import { createItemJQLQuery, createJQLQuery, convertJiraEntityTableData } from '../../../../helpers/jira/JiraHelper';
import { getInfo } from './JiraConfigHelper';

const JiraEntityConfigGrid = (props) => {
  const {
    jiraConfig,
    setJiraConfig,
    jiraProjects,
    defaultConfigs,
    entityType,
    jiraIssueFields,
    jiraStringFields,
    loadingProject
  } = props;

  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();
  const { projectId } = UseQueryProject('projectId');
  let projectConfig = UseProjectConfig(projectId);

  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [customQuery, setCustomQuery] = useState('');
  const [fetchedConfig, setFetchedConfig] = useState(false);

  const [entityConfigs, setEntityConfigs] = useState([]);
  const [activationStatus, setActivationStatus] = useState(false);

  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalProgressVisibility, setModalProgressVisibility] = useState(false);
  const [retriveStartModalVis, setRetriveStartModalVis] = useState(false);
  const [input, setInput] = useState('');

  const deleteJiraConfig = useApi({
    name: 'deleteJiraConfig',
    api: JiraApi.deleteJiraConfig,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      getEntityConfigs();
      showSuccessMes(INFO_MESSAGES.delete);
    },
    onError: (err) => showErrorMes(err)
  });

  const updateJiraConfigActivation = useApi({
    name: 'updateJiraConfigActivation',
    api: JiraApi.updateJiraConfigActivation,
    autoLoad: false,
    onSuccess: () => {
      setJiraConfig(updateJiraConfigActivation?.data);
      setActivationStatus(getActivationStatus(updateJiraConfigActivation?.data));
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err)
  });

  const getJiraConfig = useApi({
    name: 'fetchJiraConfigs',
    api: JiraApi.fetchJiraConfigs,
    autoLoad: false,
    onSuccess: () => {
      setEntityConfigs(getJiraConfig?.data);
    },
    onError: (err) => {
      showErrorMes(err);
      setEntityConfigs([]);
    }
  });

  const onDelete = () => {
    if (isValidValue(selectedItem?.id)) {
      deleteJiraConfig.execute({ id: selectedItem?.id }).then(() => {
        getEntityConfigs();
      });
      setConfirmVisibility(false);
    }
  };

  const getActivationStatus = (config) => {
    let conf = isValidValue(config) ? config : jiraConfig;

    if (entityType === ENTITY_TYPES.RELEASE) return conf?.activeRelease;
    if (entityType === ENTITY_TYPES.REQUIREMENT) return conf?.activeRequirement;
    if (entityType === ENTITY_TYPES.DEFECT) return conf?.activeDefect;

    return false;
  };

  const optionsFn = (row) => {
    return [
      {
        name: t('Retrieve from JIRA'),
        icon: (weight) => (
          <Sliders
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: !activationStatus,
        onClick: () => {
          openDownloadModal(row);
        }
      },
      ...(entityType === ENTITY_TYPES.REQUIREMENT
        ? [
            {
              name: t('Edit'),
              icon: (weight) => (
                <PencilLine
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: !activationStatus,
              onClick: () => {
                handleSelectedRow(getJiraConfig?.data, row, setSelectedItem);
                setModalVisibility(true);
              }
            }
          ]
        : []),
      {
        name: t('Delete'),
        icon: (weight) => (
          <Trash
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: !activationStatus,
        onClick: () => {
          handleSelectedRow(getJiraConfig?.data, row, setSelectedItem);
          setConfirmVisibility(true);
        }
      }
    ];
  };

  const { tableData, tableColumns } = useMemo(() => {
    let dd = [];
    let columns2 = [];
    if (getJiraConfig?.data) {
      dd = convertJiraEntityTableData(getJiraConfig?.data, entityType, jiraIssueFields);
      columns2 = generateTableColumns(dd, optionsFn, ['id']);
    }
    return {
      tableData: dd,
      tableColumns: columns2
    };
  }, [getJiraConfig?.data]);

  const getEntityConfigs = () => {
    getJiraConfig.execute({ integrationConfigId: jiraConfig.id, entityType });
  };
  const openDownloadModal = (row) => {
    let elem = null;

    if (row) {
      elem = findRow(getJiraConfig?.data, row);
    }

    if (entityType !== ENTITY_TYPES.RELEASE) {
      setSelectedItem('');
      setCustomQuery('');
      if (elem && elem !== null) {
        setSelectedItem(elem);
        setCustomQuery(createItemJQLQuery(elem));
      }
      setRetriveStartModalVis(true);
    } else {
      setFetchedConfig('');
      setSelectedItem('');
      if (elem && elem !== null) {
        setFetchedConfig(elem);
        setSelectedItem(elem);
      }
      setModalProgressVisibility(true);
    }
  };

  const startRetrieveOperation = (inputData) => {
    setInput(inputData);
    setRetriveStartModalVis(false);
    setModalProgressVisibility(true);
  };

  const onHandleActivateCheckbox = (value) => {
    let body = { id: jiraConfig?.id };
    if (entityType === ENTITY_TYPES.RELEASE) body = { ...body, activeRelease: value };
    if (entityType === ENTITY_TYPES.REQUIREMENT) body = { ...body, activeRequirement: value };
    if (entityType === ENTITY_TYPES.DEFECT) body = { ...body, activeDefect: value };

    updateJiraConfigActivation.execute(body);
  };

  let columns = disableResizingForColumns(tableColumns, [{ accessor: 'actions', width: 30 }]);
  columns = translateColumns(columns, t);
  useEffect(() => {
    setActivationStatus(getActivationStatus());
    if (jiraConfig?.id) getEntityConfigs();
  }, [jiraConfig]);

  return (
    <div className="mt-1 grid">
      {' '}
      <div className="rounded-lg bg-gray-100 bg-slate-100 py-1 ">
        <div className="p-3">
          <div className="text-sm font-normal">
            {getInfo(entityType, getArtifactNameLower(projectConfig, entityType), t)}
          </div>
        </div>
      </div>
      <div className="ml-2.5 mt-1.5">
        <Heading.Group
          type="base"
          text={getArtifactName(projectConfig, entityType) + t(' Integration')}
        >
          <div className="mt-2.5 flex gap-4	">
            <Toggle
              control={control}
              labelRight
              labelText={activationStatus ? t('Active ') : t('Activate ')}
              value={activationStatus}
              name="activationStatus"
              passValue={(value) => onHandleActivateCheckbox(value)}
            />
            <Button
              id="jiraEntityConfigGrid-add-button"
              secondary
              text={t('Add')}
              size="small"
              icon="Plus"
              disabled={!activationStatus}
              onClick={() => {
                setSelectedItem(undefined);
                setModalVisibility(true);
              }}
            />
            <Button
              id="jiraEntityConfigGrid-refresh-button"
              secondary
              size="small"
              icon={'Sliders'}
              disabled={entityConfigs === null || entityConfigs.length === 0 || !activationStatus}
              text={t('Retrieve from JIRA')}
              onClick={() => {
                openDownloadModal();
              }}
            />
          </div>
        </Heading.Group>
        <div className="mt-2">
          <BasicTable
            columns={columns}
            data={tableData}
            loading={getJiraConfig?.loading}
          />
        </div>
      </div>
      {modalVisibility === true && (
        <JiraEntitySettingModal
          loadingProject={loadingProject}
          projectConfig={projectConfig}
          jiraStringFields={jiraStringFields}
          defaultConfigs={defaultConfigs}
          isOpen={modalVisibility}
          closeModal={() => setModalVisibility(false)}
          submitCallback={getEntityConfigs}
          selectedIntegrationConfigId={jiraConfig.id}
          data={selectedItem}
          jiraProjects={jiraProjects}
          jiraIssueFields={jiraIssueFields}
          entityType={entityType}
        />
      )}
      {confirmVisibility === true && (
        <ConfirmationModal
          isOpen={confirmVisibility}
          loading={deleteJiraConfig?.loading}
          closeModal={() => setConfirmVisibility(false)}
          handleConfirm={onDelete}
          header={t('Confirm Delete')}
          content={t('Do you really want to delete this?')}
        />
      )}
      {modalProgressVisibility === true && (
        <JiraRetrieveOptProgressModal
          isOpen={modalProgressVisibility}
          closeModal={() => setModalProgressVisibility(false)}
          entityType={entityType}
          selectedIntegrationConfigId={jiraConfig?.id}
          input={input}
          fetchedConfig={fetchedConfig}
        />
      )}
      {retriveStartModalVis === true && entityType !== ENTITY_TYPES.RELEASE && (
        <JiraRetriveStartModal
          jiraConfig={jiraConfig}
          isOpen={retriveStartModalVis}
          closeModal={() => setRetriveStartModalVis(false)}
          startRetrieveOperation={startRetrieveOperation}
          defaultQuery={createJQLQuery(entityConfigs)}
          customQuery={customQuery}
        />
      )}
    </div>
  );
};

export default JiraEntityConfigGrid;
