import React, { useEffect, useState } from 'react';
// API & HOOKS
import { useApi } from '../../../hooks/UseApi';
import TestSuiteApi from '../../../api/TestSuiteApi';
import UseProjectUsers from '../../../hooks/UseProjectUsers';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseCustomField from '../../../hooks/UseCustomField';
// COMPONENTS
import CustomEntityDetail from '../../../components/detail/CustomEntityDetail';
import InsightGrid from '../../../components/insight/InsightGrid';
// HELPERS & CONSTANTS
import { ENTITY_TYPES } from '../../../constants';
import { isValidValue } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const TestSuiteDetails = ({ selectedItem, submitCallBack, submitCallBackAfterDeletion, submitCallBackAfterClone }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const users = UseProjectUsers(projectId);
  const customFields = UseCustomField(projectId, ENTITY_TYPES.TEST_SUITE);

  const [dataForm, setDataForm] = useState(null);
  const [filter, setFilter] = useState({});
  const [refreshStatus, setRefreshStatus] = useState(false);

  const getTestSuite = useApi({
    name: 'fetching test suite item',
    api: TestSuiteApi.getTestSuite,
    autoLoad: false,
    onSuccess: () => setDataForm(getTestSuite.data),
    onError: (err) => showErrorMes(err)
  });

  const refreshData = (form) => {
    if (isValidValue(form)) {
      setDataForm(form);
      submitCallBack(form);
      setRefreshStatus(true);
    } else {
      getTestSuite.execute({ id: selectedItem.id });
    }
  };

  const afterDelete = (data) => {
    if (isValidValue(data)) submitCallBackAfterDeletion(data);
  };

  const getTopContent = () => {
    return (
      <div className="mb-4 mt-4 flex flex-grow">
        <InsightGrid
          projectConfig={projectConfig}
          entityId={selectedItem?.id}
          entityType={ENTITY_TYPES.TEST_SUITE}
        />
      </div>
    );
  };

  useEffect(() => {
    refreshData();
    setFilter({ testSuiteId: selectedItem?.id, withChildTestSuiteRuns: true });
  }, [selectedItem]);

  return (
    <>
      <CustomEntityDetail
        config={{
          entityId: selectedItem?.id,
          entityType: ENTITY_TYPES.TEST_SUITE,
          users,
          refreshStatus,
          setRefreshStatus,
          loading: getTestSuite?.loading,
          dataForm: dataForm,
          selectedItem,
          submitCallBack: refreshData,
          submitCallBackAfterDeletion: afterDelete,
          submitCallBackAfterClone,
          customFields,
          getTopContent,
          filter,
          projectId
        }}
      />
    </>
  );
};
export default TestSuiteDetails;
