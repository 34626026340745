import React from 'react';
import { Input } from '../../designSystem/DesignSystem';
import { useTranslation } from 'react-i18next';

const ProjectListPageSearch = ({ control, applyFilter, setValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="-mb-2 flex gap-1">
        <div className="w-80">
          <Input
            clearable
            type={'search'}
            onClear={() => {
              applyFilter('name', null);
              setValue('filterName', '');
            }}
            placeholder={t('Project Name')}
            control={control}
            name="filterName"
            passValue={(value) => applyFilter('name', value)}
          />
        </div>
        <div className="w-24">
          <Input
            type={'search'}
            clearable
            onClear={() => {
              applyFilter('prefix', null);
              setValue('filterPrefix', '');
            }}
            placeholder={t('Project Prefix')}
            control={control}
            name="filterPrefix"
            passValue={(value) => applyFilter('prefix', value)}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(ProjectListPageSearch);
