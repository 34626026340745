import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { classNames } from '../../helpers/Helper';
import { CaretDown } from 'phosphor-react';
import { Heading } from '../../designSystem/DesignSystem';
import { t } from 'i18next';

export default function SystemSettingsMenuItem({ item, isClickedMenu, setIsClickedMenu }) {
  const { pathname } = useLocation();

  const [isClickedSubMenu, setIsClickedSubMenu] = useState(false);

  const isActive = (item) => {
    return pathname?.includes(item.path);
  };

  const handleClick = (e, path) => {
    if (path === pathname) {
      e.preventDefault();
    }
  };

  const handleClickMenuItem = (item) => {
    if (item?.subMenu && isClickedMenu) {
      setIsClickedMenu(false);
      setIsClickedSubMenu(false);
    } else if (item?.subMenu && !isClickedMenu) {
      setIsClickedMenu(true);
    }

    if (!item?.subMenu && isClickedMenu) {
      setIsClickedMenu(false);
      setIsClickedSubMenu(false);
    }
  };

  const handleClickSubMenuItem = (subMenuItem) => {
    if (subMenuItem?.nestedSubMenu && isClickedSubMenu) {
      setIsClickedSubMenu(false);
    } else if (subMenuItem?.nestedSubMenu && !isClickedSubMenu) {
      setIsClickedSubMenu(true);
    }

    if (!subMenuItem?.nestedSubMenu && isClickedSubMenu) {
      setIsClickedSubMenu(false);
    }
  };

  return (
    <div
      className="mb-1"
      key={item.id}
    >
      <div>
        <NavLink
          key={item.id}
          onClick={(e) => handleClick(e, item.path)}
          to={item.path}
          className={classNames(
            ' cursor pointer flex flex-col py-2 pl-8 ' +
              (!isActive(item)
                ? 'py-2'
                : ' border-r-4 border-primary-700 bg-primary-100  font-semibold text-primary-700')
          )}
        >
          <div
            onClick={() => handleClickMenuItem(item)}
            className="flex items-center gap-4 pb-1"
          >
            <Heading
              type="base"
              color={isActive(item) ? 'text-primary-500' : 'text-gray-700'}
              text={t(item.name)}
            />
            {item.subMenu && (
              <div className={!isActive(item) ? 'text-gray-700' : 'text-primary-700'}>
                <CaretDown
                  size="16"
                  weight="bold"
                />
              </div>
            )}
          </div>
          {item.subMenu &&
            isClickedMenu &&
            isActive(item) &&
            item.subMenu.map((subItem, index) => {
              return (
                <NavLink
                  key={index}
                  to={`${item.path}/${subItem.path}`}
                  className={({ isActive }) =>
                    `flex cursor-pointer flex-col font-normal ${
                      subItem.subMenu ? 'text-base' : 'text-sm'
                    } py-0.5 text-gray-500 ` + (isActive && 'font-semibold text-primary-500')
                  }
                >
                  <div
                    className="flex items-center gap-2"
                    onClick={() => handleClickSubMenuItem(subItem)}
                  >
                    <div className="ml-3">
                      <Heading
                        type="base"
                        color={({ isActive }) => (isActive ? 'text-blue-700' : 'text-gray-700')}
                        text={t(subItem.title)}
                      />
                    </div>
                    {subItem.nestedSubMenu && (
                      <div className={({ isActive }) => (!isActive ? 'text-gray-700' : 'text-primary-700')}>
                        <CaretDown
                          size="12"
                          weight="bold"
                        />
                      </div>
                    )}
                  </div>
                  <div className="pt-1">
                    {subItem.nestedSubMenu &&
                      isClickedSubMenu &&
                      subItem.nestedSubMenu.map((nestedSubMenuItem, index) => {
                        return (
                          <NavLink
                            key={index}
                            to={`${item.path}/${subItem.path}/${nestedSubMenuItem.path}`}
                            className={`flex cursor-pointer flex-col py-1 text-sm text-gray-700 ${
                              isActive(nestedSubMenuItem) ? 'font-semibold' : 'font-normal'
                            }`}
                          >
                            <div className="ml-6">
                              <Heading
                                type="base"
                                color={`${isActive(nestedSubMenuItem) ? 'text-primary-700' : 'text-gray-700'}`}
                                text={nestedSubMenuItem?.title}
                              />
                            </div>{' '}
                          </NavLink>
                        );
                      })}
                  </div>
                </NavLink>
              );
            })}
        </NavLink>
      </div>
    </div>
  );
}
