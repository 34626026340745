import { CHART_TYPE } from '../helper/DashboardHelper';
import CustomPieChart from '../charts/CustomPieChart';
import CustomRadialChart from '../charts/CustomRadialChart';
import CustomSunburstChart from '../charts/CustomSunburstChart';
import CustomScatterPlotChart from '../charts/CustomScatterPlotChart';
import CustomCirclePackingChart from '../charts/CustomCirclePackingChart';
import CustomAreaBumpChart from '../charts/CustomAreaBumpChart';
import CustomLineChart from '../charts/CustomLineChart';
import CustomBarChart from '../charts/CustomBarChart';
import CustomBumpChart from '../charts/CustomBumpChart';

const ChartComponent = (props) => {
  const { chart, projectId, dateFilter, index, projectConfig } = props;

  const getCss = () => {
    let customCss = 'flex justify-center px-4 py-4 bg-white border border-gray-200 rounded';
    if (
      chart?.type === CHART_TYPE.bar ||
      chart?.type === CHART_TYPE.line ||
      chart?.type === CHART_TYPE.area ||
      chart?.type === CHART_TYPE.areaBump ||
      chart?.type === CHART_TYPE.bump ||
      chart?.type === CHART_TYPE.scatterPlot
    ) {
      return `${customCss} col-span-2`;
    } else {
      return customCss;
    }
  };

  return (
    <div
      key={index + 654}
      className={getCss()}
      style={{
        backgroundColor: 'white',
        padding: 16,
        borderRadius: 12,

        marginBottom: 16,
        height: 450,
        width: '100%',
        minWidth: 400
      }}
    >
      {chart?.type === CHART_TYPE.pie && (
        <>
          <CustomPieChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}
      {chart?.type === CHART_TYPE.sunburst && (
        <>
          <CustomSunburstChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}

      {chart?.type === CHART_TYPE.radialBar && (
        <>
          <CustomRadialChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}

      {chart?.type === CHART_TYPE.bump && (
        <>
          <CustomBumpChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}

      {chart?.type === CHART_TYPE.bar && (
        <>
          <CustomBarChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}
      {chart?.type === CHART_TYPE.areaBump && (
        <>
          <CustomAreaBumpChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}
      {chart?.type === CHART_TYPE.circlePacking && (
        <>
          <CustomCirclePackingChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}
      {chart?.type === CHART_TYPE.scatterPlot && (
        <>
          <CustomScatterPlotChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}
      {(chart?.type === CHART_TYPE.line || chart?.type === CHART_TYPE.area) && (
        <>
          <CustomLineChart
            projectConfig={projectConfig}
            chart={chart}
            projectId={projectId}
            dateFilter={dateFilter}
          />
        </>
      )}
    </div>
  );
};

export default ChartComponent;
