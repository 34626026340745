
import { axios } from "./ApiClient";
import { BASE_URL } from "./CommonApiHelper";

export const HISTORY_PATH = BASE_URL + "history";

class HistoryApi {
    async getStatusHistory(data) {
        const resp = await axios.get(HISTORY_PATH + `/status`, { params: data });
        return resp.data;
    }

    async get(data){
        const resp = await  axios.get(HISTORY_PATH, { params: data });
        return resp.data;
    }

    async getDiff(data){
        const resp = await  axios.get(HISTORY_PATH + `/diff/${data?.id}`);
        return resp.data;
    }


}

export default new HistoryApi();
