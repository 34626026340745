import { CaretDown, XCircle } from 'phosphor-react';
import { useState } from 'react';
import { classNames, isValidList } from '../../helpers/Helper';
import { useFloating, autoUpdate, useDismiss, useInteractions } from '@floating-ui/react-dom-interactions';

export default function Dropdown({ text, options, children, id }) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: (reference, floating, update) =>
      autoUpdate(reference, floating, update, {
        animationFrame: true
      }),
    placement: 'bottom-start',
    strategy: 'fixed'
  });

  useInteractions([
    useDismiss(context, {
      ancestorScroll: true
    })
  ]);

  const handleBtnClick = () => setOpen(!open ? true : false);

  const returnButton = (ref) => {
    if (children) {
      return (
        <>
          <div
            ref={ref}
            onClick={() => handleBtnClick()}
          >
            {children}
          </div>
        </>
      );
    }

    return (
      <>
        <div
          id={id}
          ref={ref}
          onClick={() => handleBtnClick()}
          className={classNames(
            'inline-flex w-full justify-center px-4 py-2 text-xs font-medium',
            'rounded-md bg-black bg-opacity-20 text-white hover:bg-opacity-30',
            'focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0'
          )}
        >
          {text}
          <CaretDown
            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
          />
          <XCircle
            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
          />
        </div>
      </>
    );
  };

  return (
    <>
      {isValidList(options) && (
        <>
          <div className="inline-block p-1 text-right">
            <div className="flex text-left">{returnButton(reference)}</div>
          </div>
        </>
      )}
      {open && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y,
            left: x
          }}
          className="fixed w-32 overflow-hidden rounded-md bg-white shadow-lg"
        >
          {options.map((item) => {
            return (
              <>
                <div>
                  <button
                    onClick={() => item.onClick()}
                    id={item?.id}
                    disabled={item?.disabled}
                    className={classNames(
                      'group flex w-full items-center gap-2 px-2 py-2 text-xs',
                      !item.disabled && 'hover:bg-primary-600 hover:text-white',
                      item?.disabled ? 'text-gray-400' : 'text-gray-900'
                    )}
                  >
                    {item.icon('duotone')}
                    {item.name}
                  </button>
                </div>
              </>
            );
          })}
        </div>
      )}
    </>
  );
}
