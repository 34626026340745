import React, { useState, useId, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'phosphor-react';
// API & HOOKS IMPORTS
import UseProjectUserGroups from '../../hooks/UseProjectUserGroups';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseTableFields from '../../hooks/UseEntityAllFields';
import UseSelectRow from '../../hooks/UseSelectRow';
import UseAllUsers from '../../hooks/UseAllUsers';
//  COMPONENT IMPORTS
import AddFilterButton from './AddFilterButton';
import ManageTableColumn from './ManageTableColumn';
import ExportFileModal from '../file/ExportFileModal';
import { Body, Button } from '../../designSystem/DesignSystem';
import TableBulkOperations from './TableBulkOperations';
import TablePersonalPrefences from './TablePersonalPrefences';
// HELPER & CONSTANTS IMPORT
import { CUSTOM_FIELD_PREFIX, ENTITY_TYPES } from '../../constants';
import { getUserOptionsWithGroups, isValidValue, setFilterOperator } from '../../helpers/Helper';
import { isExportPrivileged, isSystemAdmin } from '../../helpers/PriviligeHelper';

const SelectedTableFilters = (props) => {
  const {
    entityType,
    setAllFilters,
    allFilters,
    setRefreshTable,
    defaultFilters,
    showContribution,
    setRefreshStatus,
    entityId,
    showSaveFilter
  } = props;

  const { showButton } = UseSelectRow();
  const { t } = useTranslation();
  const { projectId } = UseQueryProject('projectId');
  const users = projectId ? UseProjectUsers(projectId) : UseAllUsers();
  const userGroups = UseProjectUserGroups(projectId);
  const allEntityFields = UseTableFields(projectId, entityType);
  const id = useId();
  const [exportModalVis, setExportModalVis] = useState(false);
  const [hasRefreshed, setHasRefreshed] = useState(false);

  const exportTypes = [
    ENTITY_TYPES.TEST_CASE,
    ENTITY_TYPES.REQUIREMENT,
    ENTITY_TYPES.TEST_RUN,
    ENTITY_TYPES.DEFECT,
    ENTITY_TYPES.PROJECT
  ];
  const showExportButton = exportTypes.includes(entityType);

  const handleRemoveFilter = (filterName) => {
    const newFilters = { ...allFilters, projectId };
    delete newFilters[filterName];
    setAllFilters(newFilters);
    setRefreshTable(true);
  };

  const filterLabel = (f, filterOperator) => {
    if (f === 'projectId') {
      return null;
    }
    if (!isValidValue(f)) {
      return null;
    }

    const len = (CUSTOM_FIELD_PREFIX + ':').length;
    const fKey = f.startsWith(CUSTOM_FIELD_PREFIX) ? f.substring(len) : f;

    const filterConf = allEntityFields.find((x) => x?.key === fKey);
    if (filterConf) {
      let op = setFilterOperator(f, filterOperator);

      let val = allFilters[f];
      if (filterConf?.custom) {
        const slc = allFilters[f].split(':');
        op = slc[0];
        val = slc[1];
      }
      if (filterConf?.options) {
        const selectedOptions = val.split(',');
        val = filterConf?.options
          .filter((x) => selectedOptions.includes(x?.id) || selectedOptions.includes(x?.name))
          .map((x) => t(x?.name))
          .join(', ');
      }
      if (filterConf?.type === 'user' || filterConf?.type === 'multi_user') {
        const selectedOptions = val.split(',');
        const userOptions = getUserOptionsWithGroups(users, userGroups);
        val = (userOptions || [])
          .filter((x) => selectedOptions.includes(x?.id) || selectedOptions.includes(x?.name))
          .map((x) => t(x?.name))
          .join(', ');
      }

      return (
        <div
          key={`${id}-${f}`}
          className="flex h-8 items-center rounded bg-primary-700 px-2 py-1 text-white"
        >
          <Body
            color="text-white"
            type="small"
            text={`${t(filterConf?.name)} ${t(op)} ${val}`}
          />
          <div
            className="ml-3 cursor-pointer"
            onClick={() => handleRemoveFilter(f)}
          >
            <X
              weight="bold"
              size={10}
            />
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (showSaveFilter === false && !hasRefreshed) {
      setRefreshTable(true);
      setHasRefreshed(true);
    }
  }, [showSaveFilter, hasRefreshed]);

  return (
    <>
      <div className="mb-2 mt-4 flex flex-col">
        <div className="flex items-start justify-start gap-2 text-primary-700">
          <div className="flex flex-row flex-wrap gap-2 gap-y-1">
            <>
              {showButton && (
                <TableBulkOperations
                  entityId={entityId}
                  entityType={entityType}
                  submitCallBack={() => {
                    if (setRefreshTable) {
                      setRefreshTable(true);
                    }
                    if (setRefreshStatus) {
                      setRefreshStatus(true);
                    }
                  }}
                />
              )}
              {showExportButton === true && (isExportPrivileged(entityType, projectId) || isSystemAdmin()) && (
                <div>
                  <Button
                    id="selectedTable-export-button"
                    tooltip={t('Export')}
                    secondary2
                    weight="bold"
                    icon={'UploadSimple'}
                    size="xsmall"
                    onClick={() => setExportModalVis(true)}
                  />
                </div>
              )}
              {setRefreshTable && (
                <div>
                  <Button
                    id="selectedTable-refresh-button"
                    tooltip={t('Refresh')}
                    secondary2
                    weight="bold"
                    icon={'ArrowsClockwise'}
                    size="xsmall"
                    onClick={() => {
                      setRefreshTable(true);
                    }}
                  />
                </div>
              )}
            </>
            <ManageTableColumn
              entityType={entityType}
              showContribution={showContribution}
            />
            {showSaveFilter != false && (
              <>
                <TablePersonalPrefences
                  entityType={entityType}
                  projectId={projectId}
                  filters={allFilters}
                  setFilters={setAllFilters}
                  setRefreshTable={setRefreshTable}
                />
              </>
            )}
            <AddFilterButton
              entityType={entityType}
              labelFilters={allFilters}
              setLabelFilters={setAllFilters}
              allEntityFields={allEntityFields}
              users={users}
              userGroups={userGroups}
              setRefreshTable={setRefreshTable}
            />
            {(Object.keys(allFilters || {}) || []).map((f) => filterLabel(f, allFilters?.filter))}
          </div>
        </div>
      </div>
      {exportModalVis && (
        <ExportFileModal
          isOpen={exportModalVis}
          closeModal={() => setExportModalVis(false)}
          entityType={entityType}
          projectId={projectId}
          filters={allFilters}
          defaultFilters={defaultFilters}
        />
      )}
    </>
  );
};
export default SelectedTableFilters;
