import React, { useState, useEffect, useMemo } from 'react';
// API & HOOKS IMPORTS
import JiraApi from '../../../../api/JiraApi';
import { useApi } from '../../../../hooks/UseApi';
// DESIGN SYSTEM & LAYOUTS
import { GearSix, PencilLine, TestTube, Trash } from 'phosphor-react';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../../helpers/ButtonConstants';
import { Body, Heading, Button } from '../../../../designSystem/DesignSystem';
// COMPONENTS
import JiraSettingModal from '../modal/JiraSettingModal';
import Table from '../../../../components/table/Table';
import JiraModal from '../modal/JiraModal';
import ConfirmationModal from '../../../../components/confirm/ConfirmationModal';
// HELPER & CONSTANTS
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { SidePaneRightContent } from '../../../../components/Layout';
import { useAppDispatch } from '../../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { saveJiraConfig } from '../../../../context/ProjectActions';
import { getDefectName, getReleaseName, getRequirementName } from '../../../../helpers/ArtifactNameHelper';
import { isValidList, isValidValue } from '../../../../helpers/Helper';
import {
  convertToTableData,
  findRow,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../../helpers/TableHelper';

const JiraConfigTablePage = ({ projectId, projectConfig, users }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmActivation, setConfirmActivation] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemForCheck, setSelectedItemForCheck] = useState(null);
  const [pageParams, setPageParams] = useState({ page: 0, size: 10, sortBy: 'name', sortDir: 'ASC', defaultSize: 10 });

  const updateJiraConfigActivation = useApi({
    name: 'updateJiraConfigActivation',
    api: JiraApi.updateJiraConfigActivation,
    autoLoad: false,
    onSuccess: () => {
      refreshTable();
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const checkJiraConnection = useApi({
    name: 'checkJiraConnection',
    api: JiraApi.checkJiraConnection,
    autoLoad: false,
    onSuccess: () => {
      if (checkJiraConnection?.data === false) {
        updateJiraConfigActivation.execute({
          id: selectedItemForCheck?.id,
          active: false
        });
      }
    },
    onError: (err) => {
      showErrorMes(err);
      updateJiraConfigActivation.execute({
        id: selectedItemForCheck?.id,
        active: false
      });
    }
  });

  const deleteJiraIntegrationConfig = useApi({
    name: 'deleteJiraIntegrationConfig',
    api: JiraApi.deleteJiraIntegrationConfig,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      refreshTable();
      setConfirmOpen(false);
      setSelectedItem(null);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const checkJiraConnection2 = useApi({
    name: 'checkJiraConnection',
    api: JiraApi.checkJiraConnection,
    autoLoad: false,
    onSuccess: () => {
      if (checkJiraConnection2?.data === false) {
        showErrorMes('Jira connection is failed.');
      } else {
        showSuccessMes('Jira connection is success.');
      }
    }
  });

  const getJiraIntegrationConfig = useApi({
    name: 'fetchJiraIntegrationConfigs',
    api: JiraApi.fetchJiraIntegrationConfigs,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: getJiraIntegrationConfig?.data.size,
        pageSize: getJiraIntegrationConfig?.data.pageSize,
        totalPages: getJiraIntegrationConfig?.data?.totalPages,
        totalElements: getJiraIntegrationConfig?.data?.totalElements
      }));
      if (isValidList(getJiraIntegrationConfig?.data?.content)) {
        getJiraIntegrationConfig?.data?.content.map((item) => {
          if (item?.active === true && isValidValue(item?.id)) {
            setSelectedItemForCheck(item);
            checkJiraConnection.execute({ id: item?.id });
          }
        });
        getJiraConfig.execute({ projectId });
      }
    },
    onError: (err) => showErrorMes(err)
  });

  const getJiraConfig = useApi({
    name: 'fetching jira config item',
    api: JiraApi.getActiveJiraConfig,
    onSuccess: () => dispatch(saveJiraConfig(projectId, getJiraConfig?.data)),
    onError: (err) => showErrorMes(err)
  });

  const onDelete = () => deleteJiraIntegrationConfig.execute({ id: selectedItem.id });

  const changeActivation = () => {
    updateJiraConfigActivation.execute({
      id: selectedItem?.id,
      active: selectedItem?.active + '' === 'false' ? true : false
    });
    setConfirmActivation(false);
  };

  const refreshTable = () => getByPage({});

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    setPageParams(pageInput);
    getItems(pageInput, {});
  };

  const getItems = (newPageParams, newFilters) => {
    let filterParams = { ...newFilters };

    const body = { ...newPageParams, projectId, ...filterParams };
    getJiraIntegrationConfig.execute(body);
  };

  const openConfirmActivation = () => setConfirmActivation(true);

  const checkConnectionSetActivation = (row) => {
    let d = findRow(getJiraIntegrationConfig?.data?.content, row);
    if (isValidValue(d)) {
      setSelectedItemForCheck(d);
      checkJiraConnection2.execute({ id: d?.id });
    }
  };

  const optionsFn = (row) => {
    return [
      {
        name:
          findRow(getJiraIntegrationConfig?.data?.content, row)?.active + '' === 'true'
            ? t('Inactivate')
            : t('Activate'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(getJiraIntegrationConfig?.data?.content, row, setSelectedItem);
          openConfirmActivation();
        }
      },
      {
        name: t('Check'),
        icon: (weight) => (
          <TestTube
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          checkConnectionSetActivation(row);
        }
      },
      {
        name: t('Settings'),
        icon: (weight) => (
          <GearSix
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: findRow(getJiraIntegrationConfig?.data?.content, row)?.active + '' === 'false',
        onClick: () => {
          handleSelectedRow(getJiraIntegrationConfig?.data?.content, row, setSelectedItem);
          setOpenSetting(true);
        }
      },
      {
        name: t('Edit'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: isValidValue(row?.original?.templateBy),
        onClick: () => {
          handleSelectedRow(getJiraIntegrationConfig?.data?.content, row, setSelectedItem);
          setModalVisibility(true);
        }
      },
      {
        name: t('Delete'),
        icon: (weight) => (
          <Trash
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: isValidValue(row?.original?.templateBy),
        onClick: () => {
          handleSelectedRow(getJiraIntegrationConfig?.data?.content, row, setSelectedItem);
          setConfirmOpen(true);
        }
      }
    ];
  };

  const tableData = useMemo(
    () => convertToTableData(getJiraIntegrationConfig?.data?.content, 'jira', users),
    [getJiraIntegrationConfig?.data?.content]
  );

  let tableColumns = useMemo(
    () => generateTableColumns(tableData, optionsFn, ['id', 'configuration', 'note']),
    [getJiraIntegrationConfig?.data?.content]
  );

  tableColumns = translateColumns(tableColumns, t);

  const loading = useMemo(() => getJiraIntegrationConfig?.loading, [getJiraIntegrationConfig?.loading]);

  useEffect(() => {
    refreshTable();
  }, []);

  //TODO TRANSLATION CALISMASI YAPIALCAK
  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            text={t('Jira')}
            type="large"
          >
            <Button
              id="jiraConfigTablePage-add-button"
              primary
              text={t('Add')}
              size="small"
              icon="Plus"
              onClick={() => setModalVisibility(true)}
            />
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center colFull={true}>
            <div className="my-2 flex rounded-md p-2 shadow-md shadow-violet-100">
              <div className="max-w-l  p-4">
                <div className="text-base md:text-base"> {t('Jira Software')}</div>
                <div className="mt-2 text-sm font-normal">
                  {t('jiraConfigTable_entity', {
                    defect: getDefectName(projectConfig),
                    requirement: getRequirementName(projectConfig),
                    release: getReleaseName(projectConfig)
                  })}
                  <Body
                    text={t(
                      ' Please set your firewall settings to accept requests from our  servers to integrate Jira and Visium Manage. '
                    )}
                  />
                </div>
              </div>
            </div>
            <Table
              columns={tableColumns}
              data={tableData}
              pagination
              loading={loading}
              pageParams={pageParams}
              getByPage={getByPage}
              entityType={'jira'}
            />
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>

      {modalVisibility === true && (
        <JiraModal
          isOpen={modalVisibility}
          closeModal={() => {
            refreshTable();
            setModalVisibility(false);
            setSelectedItem(null);
          }}
          data={selectedItem}
          projectId={projectId}
        />
      )}

      {openSetting === true && (
        <JiraSettingModal
          setJiraConfig={setSelectedItem}
          isOpen={openSetting}
          closeModal={() => {
            refreshTable();
            setOpenSetting(false);
            setSelectedItem(null);
          }}
          selectedItem={selectedItem}
        />
      )}

      {confirmActivation === true && (
        <ConfirmationModal
          isOpen={confirmActivation}
          loading={updateJiraConfigActivation?.loading}
          closeModal={() => setConfirmActivation(false)}
          handleConfirm={changeActivation}
          header={t('Activation Update')}
          content={t(
            "Are you sure to update activation?  If you set the config 'active', other configs will be inactive. Or, You can set inactive."
          )}
        />
      )}

      {confirmOpen === true && (
        <ConfirmationModal
          isOpen={confirmOpen}
          loading={deleteJiraIntegrationConfig?.loading}
          closeModal={() => setConfirmOpen(false)}
          handleConfirm={onDelete}
          header={t('Delete')}
          content={t('Are you sure to delete?')}
        />
      )}
    </>
  );
};

export default JiraConfigTablePage;
