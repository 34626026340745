import React from 'react';
import { isValidList } from '../../../helpers/Helper';
import Table from '../../../components/table/Table';
import { useTranslation } from 'react-i18next';

//eslint-disable-next-line
const findStatusTotalVal = (statusKey, item) => {
  const d = item?.statuses.find((x) => x?.status === statusKey);
  if (d && d?.total > 0) {
    return d?.total;
  }
  return 0;
};

const getArrList = (data) => {
  if (isValidList(data)) {
    const arr2 = [];
    const statusColumnKey = [];
    data.forEach((item) => {
      const statusList2 = [];
      const bucketList = item?.['1']?.buckets;
      if (isValidList(bucketList)) {
        bucketList.map((i) => {
          if (!statusColumnKey.includes(i?.key)) {
            statusColumnKey.push(i?.key);
          }
          statusList2.push({ status: i?.key, total: i?.doc_count });
        });
      }
      arr2.push({
        priority: item?.key,
        total: item?.doc_count,
        statuses: statusList2
      });
    });
    return { dataList: arr2, statusList: statusColumnKey.sort() };
  } //if end
  return { dataList: [], statusList: [] };
};

const DefectPriorityAndStatusReport = ({ response, isJiraStatus }) => {
  const { t } = useTranslation();

  let insightData;
  if (isJiraStatus) {
    insightData = response?.defectInsightByJiraStatus?.aggregations?.['0']?.buckets;
  }
  if (!isJiraStatus) {
    insightData = response?.defectInsightByStatus?.aggregations?.['0']?.buckets;
  }

  const { dataList, statusList } = getArrList(insightData);

  // <-- COLUMN SETUP -->
  const tableColumns = [
    { Header: t('Priority'), accessor: 'priority' },
    { Header: t('Total'), accessor: 'total' }
  ];

  statusList.forEach((status) => {
    tableColumns.push({
      Header: t(status),
      accessor: status.toLowerCase()
    });
  });

  // <-- DATA SETUP -->
  const tableData = [];

  dataList.forEach((prio) => {
    const arr = prio?.statuses?.map((item) => {
      return { [item.status.toLowerCase()]: item.total };
    });

    tableData.push({
      priority: prio?.priority,
      total: prio?.total || 0,
      ...arr?.reduce((acc, curr) => ({ ...acc, ...curr }), {})
    });
  });

  return (
    <>
      <div className="flex flex-col gap-4 flex-grow ">
        <Table data={tableData} columns={tableColumns} compact loading={response ? false : true} />
      </div>
    </>
  );
};

export default DefectPriorityAndStatusReport;
