import { axios } from './ApiClient';

export const DOCUMENT_PATH = `/docsJson`;

class DocumentApi {
  async getCollection(lang) {
    const resp = await axios.get(`${DOCUMENT_PATH}/collection/${lang}.json`);
    return resp.data;
  }

  async getAvailableLanguages() {
    const resp = await axios.get(`${DOCUMENT_PATH}/languages.json`);
    return resp.data;
  }

  async getArticle(id) {
    const resp = await axios.get(`${DOCUMENT_PATH}/article/${id}.json`);
    return resp.data;
  }
}

export default new DocumentApi();
