import React from 'react';
import FieldSelection from '../../designSystem/FieldSelection';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseTableColumns from '../../hooks/UseTableColumns';

const ManageTableColumn = (props) => {
  const { entityType, showContribution = false } = props;
  const { projectId } = UseQueryProject('projectId');
  const {
    allColumns = [],
    selectedFields = [],
    onChangeSelectedColumns,
    onResetSelectedColumns
  } = UseTableColumns(projectId, entityType);
  let columns = showContribution !== true ? allColumns.filter((x) => x?.accessor !== 'contribution') : allColumns;

  return (
    <>
      <FieldSelection
        fields={columns}
        selectedFields={selectedFields}
        onChange={onChangeSelectedColumns}
        onReset={onResetSelectedColumns}
      />
    </>
  );
};
export default ManageTableColumn;
