import { axios } from './ApiClient';

import { DEFECT_PATH } from './ExportFileApi';
import { RELEASE_PATH } from './ReleaseApi';
import { REQ_PATH } from './RequirementApi';

const REQ_JIRA_PATH = REQ_PATH + '/jira';
const DEFECT_JIRA_PATH = DEFECT_PATH + '/jira';
const RELEASE_JIRA_PATH = RELEASE_PATH + '/jira';

class JiraEntityApi {
  async updateJiraRequirements(data) {
    const resp = await axios.put(`${REQ_JIRA_PATH}/updateJiraRequirements`, data);
    return resp.data;
  }

  // async updateJiraRequirementBySuite = (body){ const resp = await axios.put(`${REQ_JIRA_PATH}/updateByTestSuite`, body) return resp.data; }

  async fetchRequirementJira(data) {
    const resp = await axios.get(`${REQ_JIRA_PATH}/${data?.id}`, { params: data });
    return resp.data;
  }

  async createRequirementJira(data) {
    const resp = await axios.post(`${REQ_JIRA_PATH}`, data);
    return resp.data;
  }
  async updateRequirementJira(data) {
    const resp = await axios.put(`${REQ_JIRA_PATH}/${data?.id}`, data);
    return resp.data;
  }
  async getRequirementFetchingStatus() {
    const resp = await axios.get(`${REQ_JIRA_PATH}/retrieve-status`);
    return resp.data;
  }

  async startRequirementFetchOperation(data) {
    const resp = await axios.post(`${REQ_JIRA_PATH}/retrieve-all`, data);
    return resp.data;
  }
  async fetchJiraBoardStatus(data) {
    const resp = await axios.get(`${REQ_JIRA_PATH}/${data?.id}/board-status`, {
      params: data
    });
    return resp.data;
  }

  async fetchDefectJira(data) {
    const resp = await axios.get(`${DEFECT_JIRA_PATH}/${data?.id}/jiraConfig/${data?.jiraConfigId}`);
    return resp.data;
  }

  async createDefectJira(data) {
    const resp = await axios.post(`${DEFECT_JIRA_PATH}`, data);
    return resp.data;
  }

  async updateDefectJira(data) {
    const resp = await axios.put(`${DEFECT_JIRA_PATH}/${data?.id}`, data);
    return resp.data;
  }

  async getDefectFetchingStatus() {
    const resp = await axios.get(`${DEFECT_JIRA_PATH}/retrieve-status`);
    return resp.data;
  }

  async startDefectFetchOperation(data) {
    const resp = await axios.post(`${DEFECT_JIRA_PATH}/retrieve-all`, data);
    return resp.data;
  }

  async fetchReleaseJira(id) {
    const resp = await axios.get(`${RELEASE_JIRA_PATH}/${id}`);
    return resp.data;
  }

  async getReleaseFetchingStatus() {
    const resp = await axios.get(`${RELEASE_JIRA_PATH}/retrieve-status`);
    return resp.data;
  }

  async startReleaseFetchOperation(data) {
    const resp = await axios.get(`${RELEASE_JIRA_PATH}/retrieve-all`, { params: data });
    return resp.data;
  }

  async create3rdpartyDefect({ data, params }) {
    const resp = await axios.post(`${DEFECT_JIRA_PATH}/3rd`, data, { params });
    return resp.data;
  }
}

export default new JiraEntityApi();
