import React, { useState, useEffect } from 'react';
import { MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import { isValidList } from '../../../helpers/Helper';

const MultiUserSelectDropdownCustomField = (props) => {
  const { control, field, selectedItem, userOptions, disabled } = props;

  const [item, setItem] = useState([]);

  useEffect(() => {
    const selectItems = [];
    userOptions.forEach((option) => {
      // !todo: fix
      if (isValidList(selectedItem)) {
        if (isValidList(selectedItem.filter((item2) => item2 === option?.id))) {
          selectItems.push(option);
        }
      }
    });
    setItem(selectItems);
  }, [field, userOptions]);

  return (
    <>
      <MultiSelectDropdownPill
        control={control}
        disabled={disabled}
        required={field?.required}
        name={field?.customFieldId}
        label={field?.fieldName}
        options={userOptions}
        value={item}
      />
    </>
  );
};
export default MultiUserSelectDropdownCustomField;
