import React from 'react';
import TabBar from '../../components/TabBar';
import { ENTITY_TYPES } from '../../constants';
import { getArtifactName } from '../../helpers/ArtifactNameHelper';
import SubscriptionsTable from './SubscriptionsTable';
import { useTranslation } from 'react-i18next';
import {
  isViewDefectPrivileged,
  isViewReleasePrivileged,
  isViewRequirementPrivileged,
  isViewTestCase,
  isViewTestCycle,
  isViewTestSuite,
  isViewTesterPage
} from '../../helpers/PriviligeHelper';

const SubscriptionsTab = ({ projectId, projectConfig }) => {
  const { t } = useTranslation();

  const entityList = [
    ...(isViewReleasePrivileged(projectId) ? [ENTITY_TYPES.RELEASE] : []),
    ...(isViewRequirementPrivileged(projectId) ? [ENTITY_TYPES.REQUIREMENT] : []),
    ...(isViewTestCase(projectId) ? [ENTITY_TYPES.TEST_CASE] : []),
    ...(isViewTestCycle(projectId) ? [ENTITY_TYPES.TEST_CYCLE] : []),
    ...(isViewTestSuite(projectId) ? [ENTITY_TYPES.TEST_SUITE] : []),
    ...(isViewTesterPage(projectId) ? [ENTITY_TYPES.TEST_RUN] : []),
    ...(isViewDefectPrivileged(projectId) ? [ENTITY_TYPES.DEFECT] : [])
  ];

  return (
    <>
      <TabBar
        defaultIndex={0}
        options={[
          { name: t('All'), disabled: false, render: () => <SubscriptionsTable projectId={projectId} /> },
          ...entityList.map((entity) => ({
            name: getArtifactName(projectConfig, entity),
            disabled: false,
            render: () => (
              <SubscriptionsTable
                projectId={projectId}
                entityType={entity}
              />
            )
          }))
        ]}
      />
    </>
  );
};

export default SubscriptionsTab;
