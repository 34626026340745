import React, { useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// DESIGN SYSTEM
//import { Heading } from '../designSystem/DesignSystem';
import SearchDropdown from '../designSystem/Dropdown/Dropdown.Search';
import { Heading } from '../designSystem/DesignSystem';

// HOOKS & API
import { useApi } from '../hooks/UseApi';
import ProjectApi from '../api/ProjectApi';
import UseQueryProject from '../hooks/UseQueryProject';
import { useAppDispatch } from '../context/AppContext';
import { setProjectToken } from './ProjectTokenHelper';
import { saveProject } from '../context/ProjectActions';

// HELPERS
import { TippyVisible } from './Helper';
import { showErrorMes } from './NotificationHelper';
import { openProject } from '../pages/project/ProjectHelper';

export const PageNameWithProject = () => {
  const { project } = UseQueryProject('projectId');
  const { control } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  // <-- API SET UP START -->;
  const projectLoader = useApi({
    name: 'fetching projects',
    api: ProjectApi.getAll,
    autoLoad: false,
    initialValue: [],
    params: null,
    onSuccess: () => {
      const newArray = projectLoader?.data?.map((item) => ({
        ...item,
        name: `${item.prefix} - ${item.name}`
      }));
      setProjects(newArray);
    },
    onError: (err) => showErrorMes(err)
  });

  const setProject = (value) => {
    localStorage.setItem('project', '');
    openProject(value, setProjectToken, dispatch, navigate, saveProject);
  };

  useEffect(() => {
    projectLoader.execute({ isTemplate: false });
  }, []);

  return (
    <Tippy
      theme="custom-theme"
      tooltip="top"
      className="whitespace-pre-wrap break-all"
      disabled={TippyVisible(`${project?.name}`)}
      animation="scale-extreme"
      placement={'bottom'}
      arrow={true}
      content={` ${project?.name}`}
    >
      <div className="m-2 flex max-w-60 flex-col truncate md:flex">
        {project?.isTemplate ? (
          <Heading
            color="text-white"
            type="small"
            notCapitalize={false}
            isNotBold={true}
            className="my-auto truncate"
            text={`${project?.prefix} -  ${project?.name}`}
          />
        ) : (
          <SearchDropdown
            control={control}
            name="test"
            options={projects}
            selectedItem={projects?.filter((element) => element?.id === project?.id)[0]}
            backgroundColor={'bg-primary-800'}
            textColor={'text-white'}
            passValue={(value) => {
              setProject(projects.filter((element) => element?.id === value[0]?.id)[0]);
            }}
          ></SearchDropdown>
        )}
      </div>
    </Tippy>
  );
};
