import React from 'react';
import { useForm } from 'react-hook-form';
import CustomFieldApi from '../../../../api/CustomFieldApi';
import { Input, Button } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import { isValidValue } from '../../../../helpers/Helper';
import { showErrorMes } from '../../../../helpers/NotificationHelper';
import { useApi } from '../../../../hooks/UseApi';
import UseQueryProject from '../../../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';

const JiraAddStatusField = (props) => {
  const { isOpen, closeModal, entityType, jiraConfig, getItems } = props;
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const addJiraStatusCustomField = useApi({
    name: 'addJiraCustomFields',
    api: CustomFieldApi.addJiraStatusCustomField,
    autoLoad: false,
    onSuccess: () => {
      closeModal();
      if (getItems) getItems();
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const onSubmit = (formData) => {
    addJiraStatusCustomField.execute({
      issueKey: isValidValue(formData?.issueKey) ? formData.issueKey : null,
      entityType,
      integrationId: jiraConfig?.id,
      projectId
    });
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
        w={'md'}
      >
        <Modal.Title>{t('Add/Refresh Jira Statuses ')}</Modal.Title>

        {/* <HeaderSubHeader>Get transitions from jira (todo, in progress, done etc.)</HeaderSubHeader> */}

        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col">
              <Input
                required
                placeholder={t('Jira Issue Key')}
                control={control}
                name="issueKey"
                label={t('Jira Issue Key')}
              />
              <i style={{ color: '#736f6f', fontSize: 12, margin: 10 }}>
                {t('Get transitions from jira (todo, in progress, done etc.)')}
                <br />
                {t('Example issue key: PRJ-123 ')}
                <br />
                {t('Issue key or id will be used to get transition ids from jira.')}
                {t(' Transition id is mandatory to change status on jira. ')}
              </i>
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="jiraAddStatusField-cancel-button"
              secondary
              text={t('Cancel')}
              type="button"
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="jiraAddStatusField-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={addJiraStatusCustomField?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default JiraAddStatusField;
