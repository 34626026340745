import { getParentId, TreeActions } from './TreeActions';
import ContainerApi from '../../../api/ContainerApi';
import * as constants from '../../../constants';
import { ENTITY_TYPES, TREENODE_ROOT } from '../../../constants';
import { isValidValue } from '../../../helpers/Helper';
import TestCaseApi from '../../../api/TestCaseApi';
import { isEmpty } from 'lodash-es';

const testCaseModuleActionDefinitions = {
  [constants.ENTITY_TYPES.TEST_CONTAINER]: {
    parents: ContainerApi.getParens,
    page: 'test-cases',
    updateParent: {
      getApi: () => ContainerApi.updateContainerParent,
      getBody: (dragNode, dropNode) => {
        let parentId = dropNode.id;
        if (dropNode.type === constants.ENTITY_TYPES.TEST_CASE) {
          parentId = getParentId(dropNode);
        } else if (dropNode.type === TREENODE_ROOT.type) {
          parentId = null;
        }
        return { parentId };
      }
    },
    children: [
      {
        type: constants.ENTITY_TYPES.TEST_CONTAINER,
        api: ContainerApi.getTreeData,
        getParams: ({ projectId, parentId }) => ({
          root: false,
          projectId,
          parentId
        })
      },
      {
        type: constants.ENTITY_TYPES.TEST_CASE,
        api: TestCaseApi.getTreeData,
        getParams: ({ projectId, parentId, version }) => ({
          versionFilter: isValidValue(version) ? version : 'LAST_VERSION',
          parentId,
          projectId
        })
      }
    ]
  },
  [constants.ENTITY_TYPES.TEST_CASE]: {
    parents: TestCaseApi.getParens,
    page: 'test-cases',
    updateParent: {
      getApi: () => TestCaseApi.updateTestCaseParent,
      getBody: (dragNode, dropNode) => {
        let parentId = dropNode.id;
        if (dropNode.type === constants.ENTITY_TYPES.TEST_CASE) {
          parentId = getParentId(dropNode);
        }
        return { parentId };
      }
    }
  }
};

export class TestCaseTreeActions extends TreeActions {
  constructor(projectConfig, checkable) {
    super(projectConfig, checkable, ENTITY_TYPES.TEST_CASE, testCaseModuleActionDefinitions);
  }

  async getTreeData(projectId, filters) {
    // eslint-disable-next-line no-useless-catch
    try {
      if (!isEmpty(filters) && (filters.code || filters.name)) return this.getFilteredTreeData(projectId, filters);

      const treeData = await ContainerApi.getTreeData({
        root: true,
        projectId,
        parentId: null
      });
      return this.createNodes(treeData, ENTITY_TYPES.TEST_CONTAINER, TREENODE_ROOT.id);
    } catch (err) {
      throw err;
    }
  }

  getFilteredTreeData = async (projectId, filters) => {
    const treeData = await TestCaseApi.getAll({ projectId, ...filters });
    return this.createNodes(treeData, ENTITY_TYPES.TEST_CASE, null);
  };

  createNodeData(data = [], entityType, parent) {
    const node = super.createNodeData(data, entityType, parent);
    const { id, name, majorVersion, approveType, rootId } = data;

    return {
      ...node,
      ...(entityType === ENTITY_TYPES.TEST_CASE && {
        id: this.checkable ? id : rootId,
        name: approveType === 'DRAFT' ? name + ' * ' : name + ' (v' + majorVersion + ')',
        majorVersion,
        approveType,
        testCaseId: id,
        rootId
      })
    };
  }
}
