import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const TCASE_STEPS_PATH = BASE_URL + 'test-case-steps';

class TestCaseStepApi {
  async getTestCaseSteps(data) {
    const resp = await axios.get(`${TCASE_STEPS_PATH}`, { params: { testCaseId: data.testCaseId } });
    return resp.data;
  }

  async createTestCaseStep(data) {
    const resp = await axios.post(`${TCASE_STEPS_PATH}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${TCASE_STEPS_PATH}/${data.id}`);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${TCASE_STEPS_PATH}/${data.id}`, data);
    return resp.data;
  }

  async assignStepTestCases(data) {
    const resp = await axios.post(`${TCASE_STEPS_PATH}/assign-testcases`, data);
    return resp.data;
  }
}

export default new TestCaseStepApi();
