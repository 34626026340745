import React, { useState, useEffect } from 'react';
import { useApi } from '../../hooks/UseApi';
import TestCycleApi from '../../api/TestCycleApi';
import { Heading, Loader } from '../../designSystem/DesignSystem';
import { getDefectName, getRunName } from '../../helpers/ArtifactNameHelper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';

const CycleInsight = (props) => {
  const { t } = useTranslation();
  const { projectConfig, entityId } = props;
  const [customInsightResult, setCustomInsightResult] = useState('');

  const testCycleNotCompletedItems = useApi({
    name: 'get test cycle not completed items',
    api: TestCycleApi.getCycleNotCompletedTaskCount,
    autoLoad: false,
    onSuccess: () => setCustomInsightResult(testCycleNotCompletedItems?.data),
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    testCycleNotCompletedItems.execute(entityId);
  }, [entityId]);

  const defectName = getDefectName(projectConfig);
  const runName = getRunName(projectConfig);

  return (
    <>
      <div className="flex flex-grow flex-row items-center justify-around bg-white border border-gray-100 p-4 rounded-lg shadow-md">
        {testCycleNotCompletedItems?.loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex header flex-col lg:flex-col  items-center">
              <Heading type="small" text={t('defect_notClosed', { entity: defectName })} />
              <Heading type="small" text={customInsightResult?.countOfNotCompletedDefect} />
            </div>
            <div className="flex header flex-col lg:flex-col justify-between items-center">
              <Heading type="small" text={t('run_notClosed', { entity: runName })} />
              <Heading type="small" text={customInsightResult?.countOfNotCompletedRun} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CycleInsight;
