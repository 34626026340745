import React from "react";

import UseProjectConfig from "../../../hooks/UseProjectConfig";

import JiraConfigTablePage from "./table/JiraConfigTablePage";

import UseProjectUsers from "../../../hooks/UseProjectUsers";
import UseQueryProject from "../../../hooks/UseQueryProject";

const JiraConfigPage = () => {
  const { projectId } = UseQueryProject("projectId");
  const users = UseProjectUsers(projectId);
  let projectConfig = UseProjectConfig(projectId);

  return (
    <>
      <JiraConfigTablePage
        projectId={projectId}
        projectConfig={projectConfig}
        users={users}
       
      />
    </>
  );
};

export default JiraConfigPage;
