import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomFieldApi from '../../../api/CustomFieldApi';
import { Modal } from '../../../components/modal';
import { Button, H5, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { isValidList } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import { getEntityTypeList } from '../../../helpers/CloneHelper';
import { useTranslation } from 'react-i18next';

const CustomFieldMultiCloneModal = (props) => {
  const { entityType, isOpen, closeModal, projectConfigs, projectId, customFields } = props;
  const { t } = useTranslation();

  const [fieldOptions, setFieldOptions] = useState([]);
  const { control, handleSubmit, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const multiCloneCustomField = useApi({
    name: 'clone custom field',
    api: CustomFieldApi.multiClone,
    onSuccess: () => {
      closeModal(multiCloneCustomField?.data);
      showSuccessMes(INFO_MESSAGES.clone);
      reset();
    },
    onError: (err) => showErrorMes(err)
  });

  const onsubmit = (data) => {
    let body = {
      entityTypeNames: (data?.entityTypeNames || []).map((item) => item.value),
      ids: (data?.ids || []).map((item) => item.value),
      projectId
    };

    multiCloneCustomField.execute(body);
  };

  useEffect(() => {
    if (isValidList(customFields)) {
      const arr = customFields.map((item) => ({
        id: item?.customFieldId,
        key: item?.customFieldId,
        value: item?.customFieldId,
        text: item?.fieldName
      }));

      setFieldOptions(arr);
    }
  }, [customFields]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
        w={'md'}
      >
        <Modal.Title subTitle={`${getArtifactName(projectConfigs, entityType)}`}>
          {' '}
          {t('Custom Field Clone')}
        </Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onsubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <div className="flex flex-col justify-between ">
                <>
                  <H5
                    text={t('If entity artifact has same field names, fields will be not added and check field table.')}
                  />
                  <H5 text={t('Rules are not copied for new fields.')} />
                </>
              </div>
              <MultiSelectDropdownPill
                label={t('Fields')}
                control={control}
                required
                placeholder={t('Select')}
                name="ids"
                options={fieldOptions}
              />
              <MultiSelectDropdownPill
                label={t('Clone to Entity Types')}
                control={control}
                required
                placeholder={t('Select')}
                name="entityTypeNames"
                options={getEntityTypeList(projectConfigs, entityType)}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="customFieldMultiCloneModal-cancel-button"
              secondary
              text={t('Cancel')}
              type="button"
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="customFieldMultiCloneModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={multiCloneCustomField?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default CustomFieldMultiCloneModal;
