import { axios } from './ApiClient';

class DashboardApi {
  async saveProjectDashboardOrders(data) {
    const resp = await axios.put(`/api/dashboards/dashboard-orders`, data);
    return resp.data;
  }

  async saveDashboardChartOrders(data) {
    const resp = await axios.put(`/api/dashboards/chart-orders`, data);
    return resp.data;
  }

  async deleteDashboard(data) {
    const resp = await axios.delete(`/api/dashboards/${data?.id}`);
    return resp.data;
  }
  async addDashBoard(data) {
    const resp = await axios.post(`/api/dashboards`, data);
    return resp.data;
  }
  async updateDashboard(data) {
    const resp = await axios.put(`/api/dashboards/${data.id}`, data);
    return resp.data;
  }

  async fetchDashboards(data) {
    const resp = await axios.get(`/api/dashboards`, { params: data });
    return resp.data;
  }
  async fetchDashboard(id) {
    const resp = await axios.get(`/api/dashboards/${id}`);
    return resp.data;
  }
  async fetchPageDashboards(data) {
    const resp = await axios.get(
      `/api/dashboards/page-dashboards?projectId=${data.projectId}&pageName=${data.pageName}`
    );
    return resp.data;
  }
  async fetchDashboardCommonConfigs() {
    const resp = await axios.get(`/api/dashboards/common-configs`);
    return resp.data;
  }

  async fetchDashboardOptions() {
    const resp = await axios.get(`/api/dashboards/options`);
    return resp.data;
  }
  async fetchChartOptions() {
    const resp = await axios.get(`/api/charts/options`);
    return resp.data;
  }
  async fetchProjectPageConfig(data) {
    const resp = await axios.get(`/api/dashboards/project-configs?projectId=${data?.projectId}`);
    return resp.data;
  }

  async cloneDashboard(data) {
    const resp = await axios.put(`/api/dashboards/${data?.id}/clone`);
    return resp.data;
  }
  async changeDashboardVisible(data) {
    const resp = await axios.put(`/api/dashboards/${data?.id}/visible?visible=${data?.visible}`);
    return resp.data;
  }

  async cloneChart(data) {
    const resp = await axios.put(`/api/charts/${data?.id}/clone`);
    return resp.data;
  }
  async fetchKibanaFields(data) {
    const resp = await axios.get(`/api/charts/kibana-fields?projectId=${data?.projectId}`);
    return resp.data;
  }
  async deleteChart(data) {
    const resp = await axios.delete(`/api/charts/${data?.id}`);
    return resp.data;
  }
  async addChart(data) {
    const resp = await axios.post(`/api/charts`, data);
    return resp.data;
  }
  async updateChart(data) {
    const resp = await axios.put(`/api/charts/${data?.id}`, data);
    return resp.data;
  }
  async fetchCharts(data) {
    const resp = await axios.get(`/api/charts`, { params: data });
    return resp.data;
  }

  async fetchDashboardCharts(data) {
    const resp = await axios.get(`/api/charts/dashboard-charts`, { params: data });
    return resp.data;
  }

  async fetchChart(data) {
    const resp = await axios.get(`/api/charts/${data?.id}`);
    return resp.data;
  }
  async getChartElasticResult(data) {
    const resp = await axios.get(`/api/charts/${data.id}/elastic-result`, { params: data });
    return resp.data;
  }

  async fetchChartsByDashboard(data) {
    const resp = await axios.get(`/api/charts/byDashboard?dashboardId=${data?.id}`);
    return resp.data;
  }
}
export default new DashboardApi();
