import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const TCASE_AUTOMATION_STEPS_PATH = BASE_URL + 'test-case-automation-steps';

class TestCaseAutomationStepApi {
  async getSteps(data) {
    const resp = await axios.get(`${TCASE_AUTOMATION_STEPS_PATH}`, { params: { testCaseId: data.testCaseId } });
    return resp.data;
  }
}

export default new TestCaseAutomationStepApi();
