import React, { useEffect, useMemo, useRef, useState } from 'react';
// API & HOOKS IMPORTS
import { useApi } from '../../../hooks/UseApi';
import DashboardApi from '../../../api/DashboardApi';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
// HELPER & CONSTANT IMPORTS
import {
  convertToTableData,
  disableSortingForColumns,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../helpers/TableHelper';
import { isValidList } from '../../../helpers/Helper';
import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
// DESIGN SYSTEM & LAYOUT IMPORTS
import Table from '../../../components/table/Table';
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { Copy, PencilLine, Trash } from 'phosphor-react';
import { SidePaneRightContent } from '../../../components/Layout';
// COMPONENT IMPORTS
import ChartAddModal from '../modal/ChartAddModal';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import { showTableAction } from '../helper/DashboardHelper';
import { getI18n, useTranslation } from 'react-i18next';

const ChartsTable = ({ projectId, users, commonConfigs, managerProjects }) => {
  const { project } = UseQueryProject('projectId');
  const { t } = useTranslation();

  const projects =
    isValidList(managerProjects) && isProjectManager(projectId)
      ? managerProjects
      : [{ text: project?.name, id: project?.id }];
  const projectConfig = UseProjectConfig(projectId);

  const [selectedItem, setSelectedItem] = useState(null);

  const [confirmToClone, setConfirmToClone] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [pageParams, setPageParams] = useState({
    page: 0,
    size: 10,
    sortBy: 'name',
    sortDir: 'ASC',
    defaultSize: 10
  });

  // ## <-- API SETUP -->
  // ##
  const dataLoader = useApi({
    name: 'fetching charts',
    api: DashboardApi.fetchCharts,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
    },
    onError: (err) => showErrorMes(err)
  });

  const clone = useApi({
    name: 'chart clone',
    api: DashboardApi.cloneChart,
    autoLoad: false,
    onSuccess: () => {
      setSelectedItem(null);
      setConfirmToClone(false);
      refreshTable();
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteLoader = useApi({
    name: 'chart delete',
    api: DashboardApi.deleteChart,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      setConfirmOpen(false);
      setSelectedItem(null);
      refreshTable();
    },
    onError: (err) => showErrorMes(err)
  });
  // ##
  // ## <-- EVENT FN -->
  // ##
  const refreshTable = () => {
    getByPage({});
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    setPageParams(pageInput);
    getItems(pageInput, {});
  };

  const getItems = (pageInput, filterInput) => {
    const body = { ...pageInput, ...filterInput };
    if (!dataLoader?.loading) dataLoader.execute(body);
  };

  const onDeleteData = () => {
    deleteLoader.execute({ id: selectedItem?.id });
  };

  const onClone = () => {
    clone.execute({ id: selectedItem?.id });
  };
  const showAction = (row) => {
    return showTableAction(row, dataLoader?.data?.content);
  };

  const optionsFn = (row) => {
    return [
      {
        name: t('Clone'),
        icon: (weight) => (
          <Copy
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
          setConfirmToClone(true);
        }
      },
      ...(showAction(row)
        ? [
            {
              name: t('Edit'),
              icon: (weight) => (
                <PencilLine
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: false,
              onClick: () => {
                handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
                setModalVisibility(true);
              }
            }
          ]
        : []),
      ...(showAction(row)
        ? [
            {
              name: t('Delete'),
              icon: (weight) => (
                <Trash
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: false,
              onClick: () => {
                handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
                setConfirmOpen(true);
              }
            }
          ]
        : [])
    ];
  };

  const tableData = useMemo(
    () => convertToTableData(dataLoader?.data?.content, 'chart', users, projectConfig),
    [dataLoader?.data?.content, users, getI18n().language]
  );

  let tableColumns = useMemo(
    () => generateTableColumns(tableData, optionsFn, ['id']),
    [dataLoader?.data?.content, users]
  );
  tableColumns = disableSortingForColumns(tableColumns, ['dateHistogram']);
  tableColumns = translateColumns(tableColumns, t);

  const loading = useMemo(() => dataLoader?.loading, [dataLoader?.loading]);

  useEffect(() => {
    refreshTable();
  }, []);

  const ref = useRef();

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('All Charts')}
          >
            <div className="flex gap-4">
              <Button
                id="chartsTable-add-button"
                primary
                text={t('Add')}
                size="small"
                icon="plus"
                onClick={() => {
                  setSelectedItem(null);
                  setModalVisibility(true);
                }}
              />
            </div>
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center
            ref={ref}
            colFull={true}
          >
            <div className="pt-4">
              <Table
                columns={tableColumns}
                data={tableData}
                pagination
                loading={loading}
                pageParams={pageParams}
                getByPage={getByPage}
                entityType={'chart'}
                height={ref?.current?.clientHeight - 24}
              />
            </div>
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>
      {/* MODALS */}
      {confirmOpen === true && (
        <ConfirmationModal
          isOpen={confirmOpen}
          loading={deleteLoader?.loading}
          closeModal={() => setConfirmOpen(false)}
          handleConfirm={onDeleteData}
          header={t('Confirm')}
          content={t('Do you want to delete chart?')}
        />
      )}
      {confirmToClone === true && (
        <ConfirmationModal
          isOpen={confirmToClone}
          closeModal={() => setConfirmToClone(false)}
          loading={clone?.loading}
          handleConfirm={onClone}
          header={t('Confirm')}
          content={t('Do you want to clone chart?')}
        />
      )}
      {modalVisibility === true && (
        <ChartAddModal
          isOpen={modalVisibility}
          closeModal={() => {
            setModalVisibility(false);
            setSelectedItem(null);
            refreshTable();
          }}
          data={selectedItem}
          projectId={projectId}
          projectConfig={projectConfig}
          projects={projects}
          callAfterChartChanges={() => refreshTable()}
          commonConfigs={commonConfigs}
        />
      )}
    </>
  );
};

export default ChartsTable;
