import React, { useEffect, useState } from 'react';
// HOOKS & API
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import ParameterApi from '../../../api/ParameterApi';
import ProjectApi from '../../../api/ProjectApi';
// HELPERS
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { SidePaneRightContent } from '../../../components/Layout';
import { Button, Heading } from '../../../designSystem/DesignSystem';
// COMPONENTS
import ParametersTable from './modals/ParametersTable';
import EditParameterModal from './modals/EditParameterModal';
import CreateParameterModal from './modals/CreateParameterModal';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';

const ParametersPage = () => {
  // TRANSLATION
  const { t } = useTranslation();
  //#region STATES
  // SELECTED PARAMETER
  const [selectedItem, setSelectedItem] = useState(null);
  // ACTIVE MODAL STATES
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [editParameterModalOpen, setEditParameterModalOpen] = useState(false);
  const [createParameterModalOpen, setCreateParameterModalOpen] = useState(false);
  //#endregion

  //#region API
  const getParameters = useApi({
    name: 'Getting parameters',
    api: ParameterApi.findAll,
    autoLoad: false,
    onError: (err) => showErrorMes(err)
  });

  const createParameter = useApi({
    name: 'Creating parameter',
    api: ParameterApi.create,
    autoLoad: false,
    onSuccess: () => {
      getParameters.execute();
      setCreateParameterModalOpen(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteParameter = useApi({
    name: 'Deleting parameter',
    api: ParameterApi.delete,
    autoLoad: false,
    onSuccess: () => {
      getParameters.execute();
      setDeleteConfirmationModalOpen(false);
      setSelectedItem(null);
    },
    onError: (err) => showErrorMes(err)
  });

  const updateParameter = useApi({
    name: 'Updating parameter',
    api: ParameterApi.update,
    autoLoad: false,
    onSuccess: () => {
      getParameters.execute();
      setEditParameterModalOpen(false);
      setSelectedItem(null);
    },
    onError: (err) => showErrorMes(err)
  });

  const getProjects = useApi({
    name: 'Getting projects',
    api: ProjectApi.getAll,
    onError: (error) => showErrorMes(error)
  });
  //#endregion

  //#region EFFECTS
  const handleDeleteParameter = (parameter) => deleteParameter.execute(parameter?.id);
  const handleUpdateParameter = (parameter) => updateParameter.execute(parameter);
  const handleCreateParameter = (parameter) => createParameter.execute(parameter);

  useEffect(() => {
    getProjects.execute();
    getParameters.execute(); // INITIAL PARAMETER LOAD
  }, []);
  //#endregion

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('Parameters')}
          >
            <Button
              id="ldapSettings-add-button"
              primary
              text={t('Add')}
              size="small"
              icon="plus"
              onClick={() => setCreateParameterModalOpen(true)}
            />
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center>
            <ParametersTable
              parameters={getParameters?.data}
              setSelectedItem={setSelectedItem}
              setEditParameterModalOpen={setEditParameterModalOpen}
              setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
              loading={getParameters.loading}
            />
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>
      {createParameterModalOpen && (
        <CreateParameterModal
          open={createParameterModalOpen}
          setOpen={setCreateParameterModalOpen}
          handleCreate={handleCreateParameter}
          closeModal={() => setCreateParameterModalOpen(false)}
        />
      )}
      {editParameterModalOpen && (
        <EditParameterModal
          open={editParameterModalOpen}
          projects={getProjects?.data}
          closeModal={() => setEditParameterModalOpen(false)}
          selectedItem={selectedItem}
          handleUpdate={(item) => handleUpdateParameter(item)}
          loading={updateParameter?.loading}
        />
      )}
      {deleteConfirmationModalOpen && (
        <ConfirmationModal
          isOpen={deleteConfirmationModalOpen}
          closeModal={() => setDeleteConfirmationModalOpen(false)}
          handleConfirm={() => handleDeleteParameter(selectedItem)}
          loading={deleteParameter?.loading}
          header={t('Confirm Delete Parameter')}
          content={t('Parameter will be deleted!')}
        />
      )}
    </>
  );
};

export default ParametersPage;
