import { axios } from './ApiClient';

const SYSTEM_CONFIG_BASE = '/api/system-params';

class SystemParamApi {
  async getAllSystemParams() {
    const resp = await axios.get(`${SYSTEM_CONFIG_BASE}`);
    return resp.data;
  }
  async updateSystemParam(data) {
    const resp = await axios.put(`${SYSTEM_CONFIG_BASE}/${data.paramKey}`, data);
    return resp.data;
  }

  async getAttachmentUploadCount() {
    const resp = await axios.get(`${SYSTEM_CONFIG_BASE}/attachmentUploadCount`);
    return resp.data;
  }

  async getExportFields() {
    const resp = await axios.get(`${SYSTEM_CONFIG_BASE}/exportFields`);
    return resp.data;
  }
}

export default new SystemParamApi();
