import { X } from 'phosphor-react';
import { Controller } from 'react-hook-form';
import { classNames, errorMessage } from '../../helpers/Helper';
import * as Icons from 'phosphor-react';
import { Trans, useTranslation } from 'react-i18next';

const Input = ({
  control,
  name,
  placeholder,
  required,
  disabled,
  minLength,
  maxLength,
  hasError,
  label,
  type,
  passValue,
  clearable,
  onClear,
  icon,
  onChangeFn,
  onKeyDown,
  classCss
}) => {
  const { t } = useTranslation();

  const returnIcon = () => {
    let IconComponent;
    if (!icon && type?.toLowerCase() !== 'search') return null;
    if (icon) IconComponent = Icons[icon];
    if (type?.toLowerCase() === 'search') IconComponent = Icons.MagnifyingGlass;
    return <IconComponent weight="duotone" />;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="flex flex-grow flex-col p-1">
        {label && (
          <label
            htmlFor={label}
            className={classNames(
              !disabled ? 'text-gray-900' : 'text-gray-400',
              'mb-2 block  text-sm font-medium capitalize'
            )}
          >
            {label}
            {required && label && (
              <span className={classNames(!disabled ? 'text-red-500' : 'text-gray-600', 'ml-2')}>*</span>
            )}
          </label>
        )}
        <div className="flex flex-row gap-2">
          <Controller
            control={control}
            name={name}
            rules={{ maxLength: maxLength, minLength: minLength, required: required }}
            render={({ field, formState }) => (
              <>
                <div className="flex flex-1 flex-col">
                  <div className="relative flex items-center text-gray-400 focus-within:text-gray-600">
                    {(type?.toLowerCase() === 'search' || icon) && (
                      <>
                        <div className="absolute ml-3 mt-0.5 h-5 w-5">{returnIcon()}</div>
                      </>
                    )}
                    <input
                      placeholder={placeholder}
                      name={name}
                      className={classNames(
                        (type?.toLowerCase() === 'search' || icon) && 'pl-10 pr-3',
                        disabled && 'border-gray-100 bg-gray-50 text-gray-400 placeholder-gray-300',
                        hasError && !disabled && 'border border-red-300 bg-red-50 text-gray-900 placeholder-gray-400',
                        ' flex h-9 flex-grow rounded-lg border border-gray-300 bg-white p-2.5 focus:outline-none sm:text-sm',
                        'border border-gray-300 bg-gray-50 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0',
                        classCss
                      )}
                      type={type}
                      {...field}
                      onChange={(e) => {
                        onChangeFn && onChangeFn(e);
                        field.onChange(e);
                        if (passValue) passValue(e.target.value);
                      }}
                      disabled={disabled}
                      onKeyDown={onKeyDown && handleKeyDown}
                    />
                    {clearable && !disabled && (
                      <>
                        <div
                          className="relative right-8 flex cursor-pointer self-center text-gray-500"
                          onClick={() => onClear()}
                        >
                          <X
                            weight="bold"
                            size="10"
                            color="#6B7280"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {formState.errors[name] && (
                    <>
                      {formState.errors[name]?.type === 'minLength' && (
                        <Trans t={t}>{errorMessage('minLength', label, name, minLength)}</Trans>
                      )}
                      {formState.errors[name]?.type === 'maxLength' && (
                        <Trans t={t}>{errorMessage('maxLength', label, name, null, maxLength)}</Trans>
                      )}
                      {formState.errors[name]?.type === 'required' && (
                        <Trans t={t}>{errorMessage('required', label, name)}</Trans>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          />
        </div>
      </div>
    </>
  );
};

export { Input };
