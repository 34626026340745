import { useState, useEffect } from 'react';
import { Dropdown, Button } from '../../designSystem/DesignSystem';
import { isValidValue } from '../../helpers/Helper';
import { useApi } from '../../hooks/UseApi';
import ProjectApi from '../../api/ProjectApi';
import { useForm } from 'react-hook-form';
import DefectModal from '../defect/Modal/DefectModal';
import ProjectConfigApi from '../../api/ProjectConfigApi';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useSearchParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useTranslation } from 'react-i18next';

function IntegrationProjectSelect() {
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [searchParams, setSearchParams] = useSearchParams();

  const [modalStatus, setModalStatus] = useState(false);
  const [selectedProject, setSelectedProject] = useState();

  //Hooks for capturing token, vftsid(Visium farm test session id) and encoded integration data
  const [vftsid, setVftsid] = useState('');
  const [token, setToken] = useState('');
  const [encodedIntegrationData, setEncodedIntegrationData] = useState('');
  const [url, setUrl] = useState('');
  const { t } = useTranslation();

  const projectLoader = useApi({
    name: 'fetching projects',
    api: ProjectApi.getAll,
    onError: (err) => showErrorMes(err)
  });

  const projectConfigLoader = useApi({
    name: 'Get project configs',
    api: ProjectConfigApi.get
  });

  useEffect(() => {
    projectLoader.execute();
    searchParams.delete('projectId');
    setSearchParams(searchParams);

    setVftsid(searchParams.get('vftsid'));
    setToken(searchParams.get('token'));
    setEncodedIntegrationData(searchParams.get('data'));
    setUrl(decodeURI(searchParams.get('url')));
  }, [modalStatus]);

  useEffect(() => {
    if (isValidValue(selectedProject)) {
      projectConfigLoader.execute({ id: selectedProject?.id });
    }
  }, [selectedProject]);

  const handleSelectChange = (e) => {
    setSelectedProject(e[0]);
  };

  const triggerDefectModal = (e) => {
    e.preventDefault();
    if (isValidValue(selectedProject)) {
      setModalStatus(true);
    }
  };

  return (
    <>
      <div
        style={{ margin: 'auto', justifyContent: 'center', padding: '4rem', width: '50%' }}
        className="my-2 flex rounded-md p-2 shadow-md shadow-violet-100"
      >
        <form className="flex w-3/5 flex-grow flex-col gap-10">
          <h2 style={{ textAlign: 'center' }}>{t('Create New Defect')}</h2>
          <Dropdown
            clearable
            control={control}
            label={t('Project')}
            name="templateBy"
            placeholder="Select Project"
            options={projectLoader.data}
            passValue={handleSelectChange}
          />

          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              id="integrationProjectSelect-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                window.close();
              }}
            />
            <Button
              id="integrationProjectSelect-next-button"
              primary
              text={t('Next')}
              disabled={!isValidValue(selectedProject)}
              onClick={triggerDefectModal}
            />
          </div>
        </form>
      </div>

      <div>
        {modalStatus && (
          <DefectModal
            isOpen={modalStatus}
            closeModal={() => {
              setModalStatus(false);
            }}
            projectConfig={projectConfigLoader.data}
            project={selectedProject?.id}
            vftsid={vftsid}
            token={token}
            decodedIntegrationData={JSON.parse(Base64.decode(encodedIntegrationData))}
            url={url}
          />
        )}
      </div>
    </>
  );
}

export default IntegrationProjectSelect;
