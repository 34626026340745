import React, { useState, useEffect, useCallback } from 'react';

// API and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import UserApi from '../../../api/UserApi';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
// HELPER & CONSTANTS IMPORTS
import { filterByDifference, isValidValue } from '../../../helpers/Helper';
import { userSearchTypesDropdown } from '../../../constants';
import _, { debounce } from 'lodash';
// DESIGN SYSTEM IMPORTS
import { Dropdown, Input, H5, Loader } from '../../../designSystem/DesignSystem';
// COMPONENT IMPORTS
import UserItem from './UserItem';
import { translateArrItemName } from '../../../helpers/TranslationHelper';

const UsersTabInModal = ({ selectedUserGroup, setSelectedRow, selectedResults, addApi, removeApi, projectId }) => {
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();

  const [allResults, setAllResults] = useState([]);
  const [selectedSearchType, setSelectedSearchType] = useState(userSearchTypesDropdown[0]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  let searchOptions = translateArrItemName(userSearchTypesDropdown, t);

  let selectedUserGroupAndResults = filterByDifference(selectedResults, selectedUserGroup?.templateUsers)
    .concat(selectedUserGroup?.templateUsers || [])
    .filter((item) => item?.deleted === false);

  const userLoader = useApi({
    name: 'Get search users',
    api: UserApi.searchUser,
    onSuccess: () => setAllResults(userLoader?.data),
    onError: () => setAllResults([])
  });

  const onClickAddUser = (user) => {
    setSelectedRow(user);
    setSelectedUser(user);
    addApi.execute({ id: selectedUserGroup?.id, type: 'User', itemId: user?.id });
  };

  const onClickDeleteUser = (user) => {
    setSelectedRow(user);
    setSelectedUser(user);
    removeApi.execute({ id: selectedUserGroup?.id, type: 'User', itemId: user?.id });
  };

  const getResults = (value) => {
    let email = null;
    let userName = null;
    let fullName = null;
    if (selectedSearchType.value !== 'all') {
      if (selectedSearchType.value === 'email') email = value;
      if (selectedSearchType.value === 'userName') userName = value;
      if (selectedSearchType.value === 'fullName') fullName = value;
    }
    userLoader.execute({ email, userName, fullName, deleted: false, projectId });
  };

  const debounceSearch = useCallback(
    debounce(() => getResults(searchKeyword), 500),
    [searchKeyword]
  );

  const onChangeSearchKeyword = (value) => {
    if (value === '') {
      setLoading(false);
      setSearchKeyword('');
      getResults('');
    } else {
      if (selectedSearchType.value != 'all' && value.length > 3) {
        // TODO: jsx'e loading indicator eklenecek.
        setLoading(false);
        setSearchKeyword(value);
      } else if (selectedSearchType.value === 'all') setLoading(false);
    }
  };

  useEffect(() => {
    if (isValidValue(searchKeyword) && searchKeyword.length > 0) debounceSearch();
    else setAllResults([]);
    return debounceSearch.cancel;
  }, [searchKeyword, debounceSearch]);

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      {' '}
      <div className="flex flex-grow flex-col gap-8">
        <div className="grid grid-cols-2 divide-x">
          <div className="-ml-1 pr-3">
            <div className="flex items-center py-2">
              <div className="relative min-w-[25%]">
                <Dropdown
                  control={control}
                  name={'search'}
                  options={searchOptions}
                  selectedItem={selectedSearchType}
                  passValue={(value) => {
                    setSelectedSearchType(translateArrItemName(value, t)[0]);
                    setSearchKeyword('');
                  }}
                />
              </div>
              <Input
                classCss={'w-[2px]'}
                name="name"
                placeholder={t(`Search`)}
                control={control}
                passValue={(value) => onChangeSearchKeyword(value)}
              />
            </div>
            <div className="flex">
              <table className="flex-grow">
                <thead className="flex-grow border-b-2 border-gray-300 ">
                  <tr className="flex flex-grow">
                    <div className="flex-grow text-sm">
                      <th>{t('User')}</th>
                    </div>
                    <th className="w-1/4 text-sm">{t('Add')}</th>
                  </tr>
                </thead>
                <tbody className="flex-grow ">
                  {loading ? (
                    <Loader />
                  ) : !_.isEmpty(allResults) ? (
                    [...new Set(allResults)].map((user) => {
                      return (
                        <div key={user?.id}>
                          <UserItem
                            user={user}
                            type="add"
                            selectedUserGroupAndResults={selectedUserGroupAndResults}
                            handleOnclick={onClickAddUser}
                            loading={selectedUser === user && addApi?.loading}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <span className="text-sm font-semibold text-gray-500">{t('No user found with this query')} </span>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="px-3">
            <div className="flex py-4">
              <H5
                text={t('Selected Users')}
                className={'mb-1 font-semibold'}
              />
            </div>
            <div className="flex">
              <table className="flex-grow">
                <thead className="flex-grow border-b-2 border-gray-300 ">
                  <tr className="flex flex-grow">
                    <th className="w-1/4 text-sm">{t('Delete')}</th>
                    <th className="w-1/4 text-sm">{t('User')}</th>
                  </tr>
                </thead>
                <tbody className="flex-grow ">
                  {!_.isEmpty(selectedUserGroupAndResults) ? (
                    [...new Set(selectedUserGroupAndResults)].map((user, index) => {
                      return (
                        <div key={index}>
                          <UserItem
                            user={user}
                            type="remove"
                            selectedUserGroupAndResults={selectedUserGroupAndResults}
                            handleOnclick={(user) => onClickDeleteUser(user)}
                            loading={selectedUser === user && removeApi?.loading}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <span className="text-sm font-semibold text-gray-500">{t('There is no user selected.')}</span>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersTabInModal;
