import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { ThumbsUp, ThumbsDown, StopCircle, Columns } from 'phosphor-react';
// API & HOOK IMPORTS
import { useApi } from '../../hooks/UseApi';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseCustomField from '../../hooks/UseCustomField';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import TestCaseApi from '../../api/TestCaseApi';
import HistoryApi from '../../api/HistoryApi';
// DESIGN SYSTEM & COMPONENTS
import { Body, Loader } from '../../designSystem/DesignSystem';
import CustomEntityDetail from '../../components/detail/CustomEntityDetail';
// HELPER & CONSTANT
import EnumHelper from '../../helpers/EnumHelper';
import { classNames, getUrl, getUserFirstLastNameById, isValidValue, translatedDateFormat } from '../../helpers/Helper';
import { ENTITY_TYPES, RunExecutionStatus } from '../../constants';
import { getExecutionName, getRunName, getTestCaseName } from '../../helpers/ArtifactNameHelper';
import { showErrorMes } from '../../helpers/NotificationHelper';

const TestCaseDetails = ({
  selectedItem,
  submitCallBack,
  submitCallBackAfterDeletion,
  submitCallBackAfterClone,
  changeUrlVersion,
  submitCallBackVersion,
  majorVersion
}) => {
  const { t } = useTranslation();

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const customFields = UseCustomField(projectId, ENTITY_TYPES.TEST_CASE);
  const users = UseProjectUsers(projectId);
  const [dataForm, setDataForm] = useState(null);
  const [executionStatus, setExecutionStatus] = useState(null);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [runPageModalVis, setRunPageModalVis] = useState(false);

  const dataLoader = useApi({
    name: 'fetching testcase item',
    api: TestCaseApi.getRootTestCase,
    autoLoad: false,
    onSuccess: () => {
      setDataForm(dataLoader?.data);
      let entityId = dataLoader?.data?.id;
      historyLoader.execute({ projectId, entityId: entityId, entityType: ENTITY_TYPES.TEST_CASE });
      setExecutionStatus(
        dataLoader?.data?.lastTestRunExecution?.status ? dataLoader?.data?.lastTestRunExecution?.status : null
      );
    },
    onError: (err) => showErrorMes(err)
  });

  // get modified_by and modified_time correct values
  const historyLoader = useApi({
    name: 'get history',
    api: HistoryApi.get,
    autoLoad: false,
    onSuccess: () => {
      setDataForm((prevState) => ({
        ...prevState,
        modifiedBy: historyLoader?.data[0].modifiedBy,
        modifiedTime: historyLoader?.data[0].modifiedTime
      }));
    },
    onError: (err) => showErrorMes(err)
  });

  const refreshData = (form) => {
    let isLastVersion = false;
    let version = majorVersion;

    if (selectedItem?.majorVersion === null) {
      isLastVersion = true;
      version = null;
    }

    if (isValidValue(form)) {
      if (form?.versionChanged && form?.majorVersion == majorVersion) return;
      if (form?.majorVersion) {
        if (form?.majorVersion != majorVersion || form?.approveType != dataForm.approveType) {
          setDataForm(form);
          changeUrlVersion(form?.majorVersion);
        } else {
          setDataForm(form);
        }
      } else {
        setDataForm(form);
      }

      if (submitCallBack) submitCallBack(form);
    } else if (selectedItem?.rootId) {
      dataLoader.execute({
        projectId,
        rootId: selectedItem?.rootId,
        majorVersion: version,
        isLastVersion
      });
    }
    setRefreshStatus(true);
  };

  const afterDelete = (data) => {
    if (isValidValue(data)) {
      submitCallBackAfterDeletion(data);
    }
  };

  const getStatusIcon = useMemo(() => {
    if (dataForm?.lastTestRunExecution) {
      if (executionStatus === 'PASSED')
        return (
          <ThumbsUp
            size={36}
            color={RunExecutionStatus[executionStatus]?.color}
            weight="duotone"
          />
        );
      else if (executionStatus === 'FAILED')
        return (
          <ThumbsDown
            size={36}
            color={RunExecutionStatus[executionStatus]?.color}
            weight="duotone"
          />
        );
      else if (executionStatus === 'BLOCKED')
        return (
          <Columns
            size={36}
            color={RunExecutionStatus[executionStatus]?.color}
            weight="duotone"
          />
        );
      else
        return (
          <StopCircle
            size={36}
            color={RunExecutionStatus[executionStatus]?.color}
            weight="duotone"
          />
        );
    } else
      return (
        <StopCircle
          size={36}
          color={RunExecutionStatus.NO_RUN?.color}
          weight="duotone"
        />
      );
  }, [executionStatus]);

  const getTopContent = () => {
    return (
      <div
        className={classNames(
          dataForm?.lastTestRunExecution && executionStatus === 'PASSED' && 'border-teal-100 bg-teal-50',
          dataForm?.lastTestRunExecution && executionStatus === 'FAILED' && 'border-red-100 bg-red-50',
          dataForm?.lastTestRunExecution && executionStatus === 'NOT_COMPLETE' && 'border-orange-100 bg-orange-50',
          dataForm?.lastTestRunExecution && executionStatus === 'BLOCKED' && 'border-purple-100 bg-purple-50',
          'flex flex-1 flex-row items-center rounded border border-gray-200 bg-gray-50 p-6'
        )}
      >
        <div className="pr-4">{getStatusIcon}</div>
        <div className={`flex-row`}>
          <div className={'text-sm font-semibold text-gray-600'}>
            {t('last_Execution_Result', { entity: getExecutionName(projectConfig) })}
          </div>
          <div className={'text-xs text-gray-600'}>
            {!dataForm?.lastTestRunExecution ? (
              <> {t('TestCaseDetailsDescription', { entity: getTestCaseName(projectConfig) })} </>
            ) : (
              <>
                {t('testCaseDetailsV1', {
                  entity: getTestCaseName(projectConfig)
                })}{' '}
                <strong>{getUserFirstLastNameById(users, dataForm?.lastTestRunExecution?.modifiedBy)} </strong>
                {t('testCaseDetailsV2', {
                  entity: translatedDateFormat(dataForm?.lastTestRunExecution?.createdTime, 'DD MMM YYYY HH:mm', t),
                  entity1: getRunName(projectConfig)
                })}
                <div className="flex gap-1">
                  <Link
                    className={'text-xs font-medium text-blue-900 underline-offset-4 hover:underline'}
                    to={getUrl(ENTITY_TYPES.TEST_RUN, dataForm?.lastTestRun, projectId)}
                  >
                    {`${
                      dataForm?.code +
                      ' ' +
                      (isValidValue(dataForm?.lastTestRun?.summary) ? dataForm?.lastTestRun?.summary : '' + ' ')
                    } `}
                  </Link>

                  <Body
                    type="small"
                    text={t(' and status is ')}
                  />
                  <Body
                    type="small"
                    bold
                    text={
                      t(EnumHelper('RunExecutionStatus', dataForm?.lastTestRunExecution?.status)) ||
                      i18next.t('Not Executed')
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setDataForm(null);
    selectedItem && refreshData();
  }, [selectedItem]);

  useEffect(() => {
    if (!majorVersion) return;
    setDataForm(null);
    let isLastVersion = false;

    if (selectedItem?.majorVersion === null) {
      isLastVersion = true;
    }
    let vers = majorVersion && majorVersion !== null ? majorVersion : selectedItem?.majorVersion;

    if (selectedItem?.rootId) {
      dataLoader.execute({
        projectId,
        rootId: selectedItem?.rootId,
        majorVersion: vers,
        isLastVersion
      });
    }
  }, [majorVersion]);

  const ref = useRef();

  return (
    <>
      {dataLoader?.loading ? (
        <div className="flex flex-grow place-content-center">
          {' '}
          <Loader />{' '}
        </div>
      ) : (
        <CustomEntityDetail
          ref={ref}
          config={{
            entityId: dataForm?.id,
            entityType: ENTITY_TYPES.TEST_CASE,
            users,
            refreshStatus,
            setRefreshStatus,
            loading: dataLoader?.loading,
            dataForm: dataForm,
            selectedItem,
            submitCallBack: refreshData,
            submitCallBackAfterDeletion: afterDelete,
            submitCallBackAfterClone,
            submitCallBackVersion,
            customFields,
            getTopContent,
            runPageModalVis,
            setRunPageModalVis,
            projectId
          }}
        />
      )}
    </>
  );
};

export default TestCaseDetails;
