// Api and Hook Imports
import React, { useEffect, useMemo, useState } from 'react';
import SubscribeApi from '../../api/SubscribeApi';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks/UseApi';
import { ENTITY_TYPES } from '../../constants';
// Helper Import
import { getSessionUser } from '../../helpers/PriviligeHelper';
import {
  disableResizingForColumns,
  disableSortingForColumns,
  generateTableColumns,
  getNotResizedColumnList,
  translateColumns
} from '../../helpers/TableHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { getUrl } from '../../helpers/Helper';
// Design System Import
import Table from '../../components/table/Table';
import { Button } from '../../designSystem/DesignSystem';
import { Link } from 'react-router-dom';

const SubscriptionsTable = ({ projectId, entityType }) => {
  const user = getSessionUser();

  const [pageParams, setPageParams] = useState({
    page: 0,
    size: 10,
    sortBy: 'entityType',
    sortDir: 'ASC',
    defaultSize: 10
  });

  const { t } = useTranslation();

  //#region API
  const subscriptionsLoader = useApi({
    name: 'fetching subscriptions',
    api: SubscribeApi.getAllSubscribeForUser,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        totalPages: subscriptionsLoader?.data?.totalPages,
        totalElements: subscriptionsLoader?.data?.totalElements
      }));
    },
    onError: (err) => showErrorMes(err)
  });

  const unsubscribe = useApi({
    name: 'unsubscribe item',
    api: SubscribeApi.unsubscribeUser,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.unsubscribe);
      refreshTable();
    },
    onError: (err) => showErrorMes(err)
  });
  //#endregion

  const handleUnsubscribe = (subscriptionId) => {
    unsubscribe.execute({ id: subscriptionId });
  };

  const getItems = (pageInput, filterInput) => {
    const body = { ...pageInput, ...filterInput };
    subscriptionsLoader.execute(body);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    setPageParams(pageInput);
    getItems(pageInput, {});
  };

  const refreshTable = () => {
    getByPage({ projectId, userId: user.id, entityType: entityType });
  };

  const convertToTableData = (index, subscription) => ({
    code: (
      <Link
        to={getUrl(subscription?.entityType, subscription, projectId)}
        className={'whitespace-pre-wrap break-all text-xs text-blue-900'}
      >
        {subscription?.code + ' ' + subscription?.name || subscription?.summary || ''}
      </Link>
    ),
    desc: subscription?.description,
    ...(!entityType && { entity: subscription?.entityTypeName }),
    ...(!projectId && { project: subscription?.project?.name }),
    actions: (
      <Button
        primary
        onClick={() => handleUnsubscribe(subscription?.subscriptionId)}
        icon={'Eye'}
        size="xsmall"
        weight="fill"
      />
    )
  });

  const { data, tableColumns } = useMemo(() => {
    let tableData = [];
    let columns = [];
    if (subscriptionsLoader?.data) {
      tableData = subscriptionsLoader?.data?.content?.map((sub, index) => convertToTableData(index, sub));
      columns = generateTableColumns(tableData);
    }

    return {
      data: tableData,
      tableColumns: columns
    };
  }, [subscriptionsLoader?.data]);

  useEffect(() => {
    refreshTable();
  }, [projectId, entityType]);

  let columns;
  columns = disableResizingForColumns(tableColumns, getNotResizedColumnList(ENTITY_TYPES.SUBSCRIPTIONS));
  columns = disableSortingForColumns(columns, ['code', 'desc', 'entity', 'actions']);
  columns = translateColumns(columns, t);

  return (
    <div className="mt-5">
      <Table
        columns={columns}
        data={data}
        pagination
        pageParams={pageParams}
        getByPage={getByPage}
        loading={subscriptionsLoader?.loading}
        entityType={ENTITY_TYPES.SUBSCRIPTIONS}
        rowHeight={'h-12'}
      />
    </div>
  );
};

export default SubscriptionsTable;
