import { axios } from './ApiClient';

class KibanaReportsApi {
  async get(data) {
    const resp = await axios.get(`/api/kibana-reports?projectId=${data.projectId}`);
    return resp.data;
  }
  async create(data) {
    const resp = await axios.post(`/api/kibana-reports?projectId=${data.projectId}`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`/api/kibana-reports/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`/api/kibana-reports/${data.id}`);
    return resp.data;
  }
}

export default new KibanaReportsApi();
