import React, { useEffect, useState } from 'react';
import ReactFlow, { MiniMap, Controls, Background } from 'react-flow-renderer';
//API and Hook Imports
import SystemFieldApi from '../../api/SystemFieldApi';
import { useApi } from '../../hooks/UseApi';
import UseQueryProject from '../../hooks/UseQueryProject';
//Design System Imports
import ButtonEdge from './ButtonEdge';
//Helper Imports
import { isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
const WorkFlow = ({ relatedEntity }) => {
  const { projectId, project } = UseQueryProject('projectId');

  const [flowElements, setFlowElements] = useState();
  const isConnectable = !isValidValue(project?.templateBy);

  const { t } = useTranslation();
  const entitySystemFieldsWithPermissionsFetcher = useApi({
    name: ' Fetching entity system fields with permissions',
    api: SystemFieldApi.fetchEntitySystemFieldsWithPermissions,
    onSuccess: (res) => {
      let arr = [];
      const visible = res.filter((item) => item?.visible === true);
      const options = visible.map((item) => ({
        id: item.id,
        isDefault: item?.isDefault === true ? true : false,
        isSystemEnum: item.isSystemEnum,
        name: item.name,
        nextNodes: item?.nextNodes && item?.nextNodes.length > 0 ? item?.nextNodes : [],
        permissions: item?.permissions,
        relatedEntity: item.relatedEntity,
        relatedField: item.relatedEntity,
        templateBy: item.templateBy,
        isFirstStatus: item.isFirstStatus,
        isLastStatus: item.isLastStatus,
        positionX: item.positionX,
        positionY: item.positionY
      }));
      options.map((fieldOptionItem, index) => {
        const x = parseInt(fieldOptionItem.positionX || 0, 10);
        const y = parseInt(fieldOptionItem.positionY || index * 75, 10);
        let node = {
          id: fieldOptionItem.id,
          data: { label: t(fieldOptionItem.name) },
          position: { x, y }
        };
        arr.push(node);
      });

      options.map((fieldOptionItem) => {
        if (fieldOptionItem?.nextNodes && fieldOptionItem.nextNodes.length > 0) {
          fieldOptionItem.nextNodes.map((node) => {
            let edge = {
              id: fieldOptionItem.id + '//' + node.id,
              source: fieldOptionItem.id,
              target: node.id,
              type: 'buttonedge',
              data: { reload: getSystemFieldOptions }
            };
            arr.push(edge);
          });
        }
      });
      setFlowElements(arr);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const updateNodeWorkflowPosition = useApi({
    name: 'Updating node workflow poistion',
    api: SystemFieldApi.updateNodeWorkflowPosition
  });

  const assignNextNode = useApi({
    name: ' Actionsassigning next node',
    api: SystemFieldApi.assignNextNode,
    onSuccess: () => getSystemFieldOptions()
  });

  const getSystemFieldOptions = () => {
    const body = { projectId, relatedEntity, relatedField: 'status' };
    entitySystemFieldsWithPermissionsFetcher.execute(body);
  };

  const onLoad = (reactFlowInstance) => reactFlowInstance.fitView();
  const edgeTypes = { buttonedge: ButtonEdge };

  const onConnect = (params) => {
    if (params?.source && params?.target) assignNextNode.execute({ id: params.source, nextStatus: params.target });
  };

  const onNodeDragStop = (event, node) => {
    event.preventDefault();
    if (!node) return;
    updateNodeWorkflowPosition.execute({
      id: node.id,
      body: { positionX: node?.position?.x, positionY: node?.position?.y }
    });
  };

  useEffect(() => {
    getSystemFieldOptions();
  }, []);

  return (
    <>
      <div className="h-[700px]">
        <ReactFlow
          elements={flowElements}
          onConnect={onConnect}
          nodesConnectable={isConnectable}
          snapToGrid={true}
          onNodeDragStop={onNodeDragStop}
          {...(isConnectable && { edgeTypes })}
          onLoad={onLoad}
          key="edge-with-button"
        >
          <MiniMap />
          <Controls showInteractive={isConnectable} />
          <Background />
        </ReactFlow>
      </div>
    </>
  );
};

export default WorkFlow;
