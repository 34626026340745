import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// Design System & COMPONENTs
import { Dropdown, Input, Button, Datepicker } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import EntityCustomFieldForm from '../../../components/customField/EntityCustomFieldForm';
// HELPER & CONSTANTS
import { ENTITY_TYPES } from '../../../constants';
import { isValidValue, editModalMapping, isValidList, checkRequired } from '../../../helpers/Helper';
import { getCustomFields, createEntityFields } from '../../../helpers/CustomFieldHelper';
import { getArtifactName, getReleaseName } from '../../../helpers/ArtifactNameHelper';
import { getSystemFieldOptions } from '../../../helpers/EnumHelper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
// Api & Hooks
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseSystemField from '../../../hooks/UseSystemField';
import ReleaseApi from '../../../api/ReleaseApi';
import { useApi } from '../../../hooks/UseApi';
import UseCustomField from '../../../hooks/UseCustomField';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import useSystemFieldParams from '../../../hooks/UseSystemFieldParams';
import { titleTranslation, translateArrItemName } from '../../../helpers/TranslationHelper';
import { defaultValue } from '../../../helpers/SystemFieldHelper';
import Markdown from '../../../components/markdown/Markdown';
import { t } from 'i18next';

const ReleaseModal = ({ isOpen, data, closeModal, submitCallBack }) => {
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { projectId } = UseQueryProject('projectId');
  const entityCustomFields = UseCustomField(projectId, ENTITY_TYPES.RELEASE);
  let status = getSystemFieldOptions(UseSystemField(projectId, ENTITY_TYPES.RELEASE, 'status'));
  const projectConfig = UseProjectConfig(projectId);
  const [dataForm, setDataForm] = useState({});
  const [cFields, setCFields] = useState([]);
  const [customFieldForm, setCustomFieldForm] = useState({});

  const systemFieldParams = useSystemFieldParams(projectId, ENTITY_TYPES.RELEASE);
  // <-- API SETUP -->ß
  const createEditRelease = useApi({
    name: 'saving release',
    api: isValidValue(data) ? ReleaseApi.edit : ReleaseApi.create,
    onSuccess: () => {
      closeModal(createEditRelease?.data);
      if (submitCallBack) submitCallBack(createEditRelease?.data);
      showSuccessMes(t('releaseSave', { release: getReleaseName(projectConfig) }));
    },
    onError: (err) => {
      if (err.response?.data?.message?.includes('Release name is mandatory')) {
        showErrorMes(t('release_name_is_mandatory', { entity: getArtifactName(projectConfig, ENTITY_TYPES.RELEASE) }));
      } else {
        showErrorMes(err);
      }
    }
  });

  const onSubmit = (form) => {
    let cFieldForm = getCustomFields(cFields, form);
    const body = {
      ...(isValidValue(data) && { id: data.id }),
      name: form.name,
      description: isValidValue(form.description) ? form.description : '',
      projectId: projectId,
      startDate: form.startDate,
      endDate: form.endDate,
      status: isValidValue(form?.status?.value) ? form?.status?.value : form?.status?.id,
      customFields: createEntityFields(form, cFieldForm)
    };
    createEditRelease.execute(body);
  };
  status = translateArrItemName(status, t);
  useEffect(() => {
    setValue('status', defaultValue(status));
    setDataForm((prev) => ({ ...prev, statusId: defaultValue(status)?.value }));
    if (isValidValue(data)) {
      const form = { ...data, statusId: data?.status?.id };
      let mappedArray = editModalMapping(form);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setDataForm(form);
    }
  }, []);

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title>{titleTranslation(getArtifactName(projectConfig, ENTITY_TYPES.RELEASE), data, '', t)}</Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'lg'}>
            <div className="flex flex-grow flex-col gap-4">
              <Input
                required
                name="name"
                label={t('Name')}
                placeholder={t('name_entity', { entity: getArtifactName(projectConfig, ENTITY_TYPES.RELEASE) })}
                control={control}
                maxLength="255"
              />
              <div className="flex flex-grow gap-3">
                <Datepicker
                  clearable
                  startDateForm={data?.startDate}
                  endDateForm={data?.endDate}
                  control={control}
                  startDateLabel={t('Start Date')}
                  endDateLabel={t('End Date')}
                  endDateName="endDate"
                  startDateName="startDate"
                />
              </div>
              <div className="flex gap-4">
                <Dropdown
                  clearable
                  type="search"
                  control={control}
                  label={t('Status')}
                  name="status"
                  required={checkRequired('status', systemFieldParams)}
                  options={status}
                  selectedItem={isValidValue(getValues('status')) ? getValues('status') : defaultValue(status)}
                  passValue={(value) => {
                    setValue('status', isValidList(value) ? value[0] : null);
                    setDataForm((prev) => ({ ...prev, statusId: isValidList(value) ? value[0]?.id : null }));
                  }}
                  placeholder={t('Select Status')}
                />
              </div>
              <EntityCustomFieldForm
                customFieldForm={customFieldForm}
                setCustomFieldForm={setCustomFieldForm}
                control={control}
                setValue={setValue}
                entityFields={data?.customFields}
                entityCustomFields={entityCustomFields}
                dataForm={dataForm}
                customControlForJiraModal={false}
                setCFields={setCFields}
              />
              <Markdown
                control={control}
                name="description"
                label={t('Description')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="release-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
              }}
              disabled={createEditRelease?.loading}
            />
            <Button
              id="release-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEditRelease?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default ReleaseModal;
