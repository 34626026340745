import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Hooks & API Imports
import UseQueryProject from '../../hooks/UseQueryProject';
import UseProjectConfig from '../../hooks/UseProjectConfig';
// Component Imports
import { SidePaneLeftContent, SidePaneRightContent } from '../../components/Layout';
import PageLeftTreeButton from '../../components/treeView/PageLeftTreeButton';
import { Loader } from '../../designSystem/DesignSystem';
// Page Imports
import AllRequirementPage from './AllRequirementPage';
import FolderDetails from './folder/FolderDetails';
import RequirementDetails from './RequirementDetails';
import AlertModal from '../../components/alert/AlertModal';
// Helpers & Constant Imports
import { ENTITY_TYPES } from '../../constants';
import { getNewUrlPath, isValidValue } from '../../helpers/Helper';
import { isViewRequirementPrivileged } from '../../helpers/PriviligeHelper';
import UseAllUsers from '../../hooks/UseAllUsers';
import UseJiraConfig from '../../hooks/UseJiraConfig';
import TreeArborist from '../../components/tree/TreeArborist';
import * as TreeEvents from '../../components/tree/TreeEvents';
import { EVENT_TYPES } from '../../components/tree/TreeEvents';

const RequirementPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const jiraConfig = UseJiraConfig(projectId);

  const users = UseAllUsers();

  const { pathname: path } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  let [searchParams] = useSearchParams();
  const { id, type } = useMemo(() => {
    return {
      id: searchParams.get('id'),
      type: searchParams.get('type')
    };
  }, [searchParams]);

  const selectedItem = useMemo(
    () => ({
      id,
      type
    }),
    [id, type]
  );

  const renderSwitch = useCallback(
    (param) => {
      let type = isValidValue(param) ? param?.type : null;
      // eslint-disable-next-line default-case
      switch (type) {
        case ENTITY_TYPES.FOLDER:
          return (
            <FolderDetails
              selectedItem={selectedItem}
              submitCallBack={(item) =>
                TreeEvents.dispatch(ENTITY_TYPES.REQUIREMENT, EVENT_TYPES.UPDATED, {
                  ...item,
                  type: ENTITY_TYPES.FOLDER
                })
              }
              submitCallBackAfterDeletion={(item) =>
                TreeEvents.dispatch(ENTITY_TYPES.REQUIREMENT, EVENT_TYPES.DELETED, item)
              }
              submitCallBackAfterClone={(item) => afterCreate(ENTITY_TYPES.FOLDER, item)}
            />
          );
        case ENTITY_TYPES.REQUIREMENT:
          return (
            <RequirementDetails
              selectedItem={selectedItem}
              submitCallBack={(item) =>
                TreeEvents.dispatch(ENTITY_TYPES.REQUIREMENT, EVENT_TYPES.UPDATED, {
                  ...item,
                  type: ENTITY_TYPES.REQUIREMENT
                })
              }
              submitCallBackAfterDeletion={(item) =>
                TreeEvents.dispatch(ENTITY_TYPES.REQUIREMENT, EVENT_TYPES.DELETED, item)
              }
              submitCallBackAfterClone={(item) => afterCreate(ENTITY_TYPES.REQUIREMENT, item)}
            />
          );
        default:
          return (
            <AllRequirementPage
              users={users}
              jiraConfig={jiraConfig}
            />
          );
      }
    },
    [selectedItem]
  );

  const selectItem = (item) => {
    navigate(getNewUrlPath(projectId, path, item));
  };

  const afterCreate = (type, form) => {
    if (isValidValue(form)) {
      TreeEvents.dispatch(ENTITY_TYPES.REQUIREMENT, EVENT_TYPES.CREATED, {
        ...form,
        type
      });
    }
  };

  return (
    <>
      {!isViewRequirementPrivileged(projectId) && (
        <AlertModal
          header={t('Access Denied')}
          content={t(`You have no permission to access this page.`)}
        />
      )}
      <SidePaneLeftContent>
        <PageLeftTreeButton
          pageKey={ENTITY_TYPES.REQUIREMENT}
          selectedItem={selectedItem}
          exportEntityType={ENTITY_TYPES.REQUIREMENT}
          importEntityType={ENTITY_TYPES.REQUIREMENT}
          afterCreate={afterCreate}
          projectConfig={projectConfig}
        />
        <SidePaneLeftContent.Treeview>
          {!isValidValue(projectConfig?.projectId) ? (
            <Loader />
          ) : (
            <TreeArborist
              module={ENTITY_TYPES.REQUIREMENT}
              draggable
              projectId={projectId}
              projectConfig={projectConfig}
              onSelect={selectItem}
              params={selectedItem}
            />
          )}
        </SidePaneLeftContent.Treeview>
      </SidePaneLeftContent>
      <SidePaneRightContent>{renderSwitch(selectedItem)}</SidePaneRightContent>
    </>
  );
};

export default RequirementPage;
