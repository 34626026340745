import { default as a } from 'axios';
import { showErrorMes } from '../helpers/NotificationHelper';
import LoginApi from './LoginApi';

export let axios = a.create();

export const getTokens = () => {
  let lsToken = localStorage.getItem('token');
  lsToken = JSON.parse(lsToken || '{}');
  const { token, refreshToken, firstToken } = lsToken;
  return { token, refreshToken, firstToken };
};

let isRefreshing = false;
let failedQueue = [];

export const logout = (err) => {
  const logoutActions = async () => {
    const { token } = getTokens();
    localStorage.clear();
    try {
      if (token) {
        await LoginApi.logout({ accessToken: token });
      }
    } catch (e) {
      console.error(e);
    }
    return (window.location.href = '/login');
  };

  if (err) {
    showErrorMes(err, { onClose: logoutActions });
  } else {
    logoutActions();
  }
};

const processQueue = (error, accesToken = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(accesToken);
    }
  });

  failedQueue = [];
};

export const refreshInstance = () => {
  axios = a.create();
};

export const setProjecHeader = (projectId) => {
  axios.defaults.headers.common['X-Project-Id'] = projectId;
};

const getResponseUnauthorized = (error) => {
  return error.response && error.response.status === 401;
};
const isOriginalRequest = (originalRequest, refreshToken) => {
  return originalRequest.url !== '/api/auth/refresh' && !originalRequest._retry && refreshToken;
};

export const setupInterceptors = (accessToken) => {
  axios.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;

      if (originalRequest.url === '/api/auth/logout') {
        return;
      }

      if (getResponseUnauthorized(error)) {
        const { refreshToken, token } = getTokens();
        if (isOriginalRequest(originalRequest, refreshToken)) {
          if (isRefreshing) {
            // if the refresh request in proccess, then push the failed request to process queue
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((accessToken) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise(function (resolve, reject) {
            const projectId = originalRequest.headers['X-Project-Id'];

            // in order to refresh publicKey simultaneously with the token,
            // we need to remove publicKey from local storage
            // PublicKeyHelper.getKey will take care of the rest
            localStorage.removeItem('publicKey');

            axios
              .post('/api/auth/refresh', { accessToken: token, refreshToken, projectId })
              .then(({ data }) => {
                const tok = JSON.parse(localStorage.getItem('token') || '{}');
                tok.token = data.accessToken;
                tok.firstToken = data.accessToken;
                localStorage.setItem('token', JSON.stringify(tok));

                originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
                refreshInstance();
                setupInterceptors(data.accessToken);
                if (projectId) {
                  setProjecHeader(projectId);
                }
                resolve(axios(originalRequest));
                processQueue(null, data.accessToken);
              })
              .catch((err) => {
                reject(err);
                processQueue(err, null);
                logout(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } else {
          logout(error);
        }
      }

      return Promise.reject(error);
    }
  );
};

const BASE_URL = 'http://10.254.186.59/api/';
export const PROJECT_PATH = BASE_URL + 'projects';
