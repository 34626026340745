export const setLastTreeItem = (projectId, page, type, id, version) => {
  let localTreeHistory = localStorage.getItem('tree-history');
  let treeHistory = {};

  if (localTreeHistory) treeHistory = JSON.parse(localTreeHistory);
  if (projectId && page && type && id) {
    treeHistory[projectId] = treeHistory[projectId] || {};
    treeHistory[projectId][page] = { type, id, version };
  }

  localStorage.setItem('tree-history', JSON.stringify(treeHistory));
};
