import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Dropdown, Button } from '../../../designSystem/DesignSystem';
import { ENTITY_TYPES } from '../../../constants';
import { isValidValue } from '../../../helpers/Helper';
import { useTranslation } from 'react-i18next';
import { translateArrItemName } from '../../../helpers/TranslationHelper';

const allStates = [
  {
    id: 'customField',
    key: 'customField',
    value: 'customField',
    text: 'Custom Field',
    name: 'Custom Field'
  },
  {
    id: 'systemField',
    key: 'systemField',
    value: 'systemField',
    text: 'System Field',
    name: 'System Field'
  }
];

const customFieldStates = [
  {
    id: 'customField',
    key: 'customField',
    value: 'customField',
    text: 'Custom Field',
    name: 'Custom Field'
  }
];

const CustomFieldRule = (props) => {
  const { rule, relatedEntity, entitySystemFields, customFieldItems, onDeleteRule, submitCallBack, isViewMode } = props;
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [ruleOption, setRuleOption] = useState('');
  const { t } = useTranslation();

  const translateEntitySystemFiels = () => {
    return translateArrItemName(entitySystemFields, t);
  };

  const getRuleStates = (relatedEntity) => {
    if (
      isValidValue(relatedEntity) &&
      (relatedEntity === ENTITY_TYPES.REQUIREMENT ||
        relatedEntity === ENTITY_TYPES.DEFECT ||
        relatedEntity === ENTITY_TYPES.RELEASE ||
        relatedEntity === ENTITY_TYPES.TEST_CYCLE)
    )
      return translateArrItemName(allStates, t);

    return translateArrItemName(customFieldStates, t);
  };

  const getValues = (optionList, id) => {
    let valueList = [];
    if (id === '') {
      return [];
    }
    if (optionList && optionList.length > 0) {
      optionList.forEach((element) => {
        if (element?.id === id && element?.values && element.values.length > 0) {
          element.values.map((x) => {
            if (x?.visible === true) valueList.push(x);
          });
        }
      });
    }

    return translateArrItemName(valueList, t);
  };

  const InputRuleOption = useMemo(() => {
    return (
      <td className="w-1/4 p-1 ">
        <Dropdown
          disabled={!isValidValue(ruleOption.type) || isViewMode}
          control={control}
          name={'id'}
          placeholder={t('Select Field')}
          options={
            isValidValue(ruleOption?.type)
              ? ruleOption?.type === 'systemField'
                ? translateArrItemName(entitySystemFields, t)
                : customFieldItems
              : []
          }
          selectedItem={
            ruleOption?.type === 'systemField'
              ? translateEntitySystemFiels().filter((field) => field.id === ruleOption?.id)[0]
              : customFieldItems.filter((field) => field.id === ruleOption?.id)[0]
          }
          passValue={(value) => {
            setRuleOption({ ...ruleOption, id: value[0].id });
          }}
        />
      </td>
    );
  }, [ruleOption?.type]);

  const InputRuleValue = useMemo(() => {
    return (
      <td className="w-1/4 p-1 ">
        <Dropdown
          control={control}
          name={'value'}
          placeholder={t('Select Field Value')}
          disabled={!isValidValue(ruleOption.id) || isViewMode}
          options={
            isValidValue(ruleOption?.id)
              ? ruleOption?.type === 'systemField'
                ? getValues(entitySystemFields, ruleOption?.id)
                : getValues(customFieldItems, ruleOption?.id)
              : []
          }
          selectedItem={
            ruleOption?.type === 'systemField'
              ? getValues(entitySystemFields, ruleOption?.id).filter((field) => field.id === ruleOption.value)[0]
              : getValues(customFieldItems, ruleOption?.id).filter((field) => field.id === ruleOption.value)[0]
          }
          passValue={(value) => setRuleOption({ ...ruleOption, value: value[0].value })}
        />
      </td>
    );
  }, [ruleOption?.id]);

  useEffect(() => {
    if (rule) {
      if (rule.type === 'systemField') {
        setRuleOption({ ...rule, name: t('System Field') });
      } else if (rule.type === 'customField') {
        setRuleOption({ ...rule, name: t('Custom Field') });
      } else {
        setRuleOption(rule);
      }
    }
  }, [rule]);

  useEffect(() => {
    if (isValidValue(ruleOption)) {
      submitCallBack(ruleOption);
    }
  }, [ruleOption?.value]);

  return (
    <>
      <td className="flex-grow p-1">
        <Dropdown
          disabled={isViewMode}
          control={control}
          name={'type'}
          placeholder={t('Select Type')}
          options={getRuleStates(relatedEntity)}
          selectedItem={ruleOption}
          passValue={(value) => {
            setRuleOption({ ...ruleOption, type: value[0].id, name: value[0].name });
          }}
        />
      </td>
      {InputRuleOption}
      {InputRuleValue}
      <td className="w-1/12 self-center p-2">
        <Button
          id="cloneFieldsRule-delete-button"
          tertiary
          size="small"
          icon="Trash"
          weight={'bold'}
          type={'button'}
          disabled={isViewMode}
          onClick={() => onDeleteRule(ruleOption)}
        />
      </td>
    </>
  );
};
export default CustomFieldRule;
