/* eslint-disable */
import { ENTITY_TYPES, TREENODE_ROOT } from '../../../constants';

export const PARENTS = [
  TREENODE_ROOT.type,
  ENTITY_TYPES.TEST_CONTAINER,
  ENTITY_TYPES.FOLDER,
  ENTITY_TYPES.TEST_FOLDER,
  ENTITY_TYPES.TEST_CYCLE,
  ENTITY_TYPES.TEST_SUITE
];
