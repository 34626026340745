import { axios } from "./ApiClient";

import { BASE_URL } from "./CommonApiHelper";
export const LICENSE_PATH = BASE_URL + "license";

class LicenseApi {

  async getUUID() {
    const resp = await axios.get(`${LICENSE_PATH}/uuid`);
    return resp.data;
  }

  async get() {
    const resp = await axios.get(`${LICENSE_PATH}`);
    return resp.data;
  }

  async upload({ licenseFile, config }) {
    const resp = await axios.post(`${LICENSE_PATH}`, licenseFile, config);
    return resp.data;
  }

}

export default new LicenseApi();