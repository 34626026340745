import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { getTokens } from './ApiClient';

const WebSocketService = (projectId) => {
  const { token } = getTokens();
  const socket = new SockJS(`/api/ws`);
  const stompClient = Stomp.over(socket);

  stompClient.debug = () => {};

  const headers = {
    Authorization: `Bearer ${token}`,
    'X-Project-Id': projectId
  };

  const connect = (callback) => {
    stompClient.connect(headers, function () {
      stompClient.connected = true;
      callback();
    });
  };

  const subscribe = ({ destination, callback }) => {
    stompClient.subscribe(
      destination,
      function (message) {
        callback(message.body);
      },
      headers
    );
  };

  const unsubscribe = (destination, callback) => {
    stompClient.unsubscribe(destination, function (message) {
      callback(message.body);
    });
  };

  const sendMessage = (destination, message) => {
    stompClient.send(destination, headers, JSON.stringify({ message }));
  };

  const disconnect = () => {
    if (stompClient.connected) {
      stompClient.disconnect();
      stompClient.connected = false;
    }
  };

  const isConnected = () => {
    return stompClient.connected;
  };

  return { connect, subscribe, unsubscribe, sendMessage, disconnect, isConnected };
};

export default WebSocketService;
