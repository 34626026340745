import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentApi from '../../api/DocumentApi';
import { SidePaneLeftContent, SidePaneRightContent } from '../../components/Layout';
import { NoDataMessage } from '../../helpers/TableHelper';
import { useApi } from '../../hooks/UseApi';
import ArticleViewer from './ArticleViewer';
import CollectionList from './CollectionList';

const DocumentationPage = (props) => {
  const [collections, setCollections] = useState([]);
  const [articleId, setArticleId] = useState(
    props?.match?.params?.articleId === undefined ? null : props?.match?.params?.articleId
  );
  const [headerList, setHeaderList] = useState([]);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const selectedLangCode = useMemo(() => localStorage.getItem('lang') || 'en', [localStorage.getItem('lang')]);

  const collectionLoader = useApi({
    name: 'fetching collection item',
    api: DocumentApi.getCollection,
    autoLoad: false,
    onSuccess: (res) => {
      setCollections(res?.collections);
      if (!props?.match?.params?.articleId) {
        selectArticle(res[0]?.articles[0]?.id || null);
      }
      setArticleId(res?.collections[0]?.articles[0]?.id || null);
    },
    onError: () => {
      setError(true);
    }
  });

  const avaibleLanguageLoader = useApi({
    name: 'fetching language item',
    api: DocumentApi.getAvailableLanguages,
    autoLoad: false,
    onSuccess: (res) => {
      getCollections(res.languages.map((l) => l.code));
    },
    onError: () => {
      setError(true);
    }
  });

  const getHeaders = () => {
    const headerList = document.querySelectorAll('#article-viewer h2');
    setHeaderList(Array.from(headerList));
  };

  const getCollections = (availableDocumentLanguages) => {
    let lang = selectedLangCode;
    if (!availableDocumentLanguages?.includes(lang)) {
      lang = 'en';
    }
    collectionLoader.execute(lang);
  };

  const handleChangeLanguage = (value) => {
    getCollections(value);
  };

  const selectArticle = (id) => {
    setArticleId(id);
  };

  useEffect(() => {
    avaibleLanguageLoader.execute();
  }, []);

  useEffect(() => {
    handleChangeLanguage(selectedLangCode);
  }, [selectedLangCode]);

  return (
    <>
      <SidePaneLeftContent>
        {!error ? (
          <CollectionList
            collections={collections}
            articleId={articleId}
            selectArticle={selectArticle}
            headerList={headerList}
          />
        ) : null}
      </SidePaneLeftContent>
      <SidePaneRightContent>
        {error ? (
          <NoDataMessage message={t('Failed to load data. Please try again.')} />
        ) : articleId ? (
          <div className={`mb-10 overflow-y-auto`}>
            <ArticleViewer
              articleId={articleId}
              getHeaders={getHeaders}
            />
          </div>
        ) : (
          <NoDataMessage message={t('Select an article to display its content here.')}></NoDataMessage>
        )}
      </SidePaneRightContent>
    </>
  );
};
export default DocumentationPage;
