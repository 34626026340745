import { ChartBar } from 'phosphor-react';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../helpers/ButtonConstants';
import { getReportExcelNameDate, isValidValue } from '../../helpers/Helper';
import { INFO_MESSAGES, showSuccessMes } from '../../helpers/NotificationHelper';
import { isViewReport } from '../../helpers/PriviligeHelper';
import { StatusPill } from '../../designSystem/DesignSystem';
import i18next from 'i18next';

export const getReportActionForTable = (
  projectId,
  handleSelectedRow,
  dataLoader,
  setReportModal,
  row,
  setSelectedItem
) => {
  if (isViewReport(projectId)) {
    return {
      name: i18next.t('Report'),
      icon: (weight) => <ChartBar size={TABLE_ACTION_COLUMNS_STYLE.iconSize} weight={weight} />,
      disabled: false,
      onClick: () => {
        handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
        setReportModal(true);
      }
    };
  }
  return null;
};

export const getReportEntityStatusPill = (stats, entityType) => {
  if (isValidValue(stats)) {
    return <StatusPill value={stats} entityType={entityType} />;
  }
  return '-';
};

export const downloadExportingFile = (input, entityType) => {
  const fileName = getReportExcelNameDate() + '_' + entityType + '_report.xls';
  const url = window.URL.createObjectURL(new Blob([input], { entityType: 'application/vnd.ms-excel' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();

  showSuccessMes(INFO_MESSAGES.export);
};
