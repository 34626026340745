import React, { useEffect, useState } from 'react';
// API & HOOKS IMPORTS
import { useApi } from '../../../hooks/UseApi';
import DashboardApi from '../../../api/DashboardApi';
// HELPER & CONSTANT IMPORTS
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { getDefaultDateFilter, getDefaultDateRange } from '../helper/DashboardHelper';
import { convertDateToMs, editModalMapping, getActiveIndex, isValidList, isValidValue } from '../../../helpers/Helper';
import TabBar from '../../../components/TabBar';
import PageCharts from './PageCharts';
import { Body, Heading, Loader } from '../../../designSystem/DesignSystem';
import { SidePaneRightContent } from '../../../components/Layout';
import DashboardFilterComponent from './DashboardFilterComponent';
import { useForm } from 'react-hook-form';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { useTranslation } from 'react-i18next';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseSystemParamsConfig from '../../../hooks/UseSystemParamsConfig';

const PageAllDashboards = ({ pageName }) => {
  const { control, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const [panes, setPanes] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [dateFilter, setDateFilter] = useState(getDefaultDateFilter());
  const [updateCharts, setUpdateCharts] = useState(false);
  const [data, setData] = useState({});
  const [dates, setDates] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  UseSystemParamsConfig(projectId);

  const dashboardLoader = useApi({
    name: 'fetching project dashboards',
    api: DashboardApi.fetchPageDashboards,
    autoLoad: false,
    onSuccess: () => {
      setDashboards(dashboardLoader?.data);
      fillPanes(dashboardLoader?.data);
    },
    onError: (err) => showErrorMes(err)
  });
  const fillPanes = (dashboards) => {
    if (isValidList(dashboards)) {
      let arr = [];
      dashboards.forEach((x) => {
        arr.push({
          name: getArtifactName(projectConfig, x?.name),
          disabled: false,
          render: () => (
            <>
              <PageCharts
                updateCharts={updateCharts}
                setUpdateCharts={setUpdateCharts}
                dashboardId={x?.id}
                projectId={projectId}
                dateFilter={dateFilter}
                projectConfig={projectConfig}
              />
            </>
          )
        });
      });
      setPanes(arr);
    }
    setIsLoading(false);
  };

  const onSubmit = (formData) => {
    const msDates = {
      startMs: convertDateToMs(formData?.startDate),
      endMs: convertDateToMs(formData?.endDate)
    };
    setData(formData);
    setDateFilter(msDates);
  };

  const handleDateChange = () => {
    const index = getActiveIndex(panes);
    const activeDb = dashboards[index];

    if (isValidList(dashboards)) {
      showSuccessMes('Date is applied');
      let arr = [];

      dashboards.forEach((x) => {
        arr.push({
          name: getArtifactName(projectConfig, x?.name),
          disabled: false,
          render: () => (
            <PageCharts
              updateCharts={x?.id === activeDb?.id}
              setUpdateCharts={setUpdateCharts}
              dashboardId={x?.id}
              projectId={projectId}
              dateFilter={dateFilter}
            />
          )
        });
      });
      setPanes(arr);
      setIsLoading(false);
    }
  };

  useEffect(() => handleDateChange(), [dateFilter]);

  useEffect(() => {
    const defaultDateRange = getDefaultDateRange();
    data.startDate = defaultDateRange?.startDate;
    data.endDate = defaultDateRange?.endDate;
    const mappedArray = editModalMapping(data);
    mappedArray.forEach(({ name, value }) => setValue(name, value));
    setData(data);
    setDates({ startDate: data?.startDate, endDate: data?.endDate });
  }, []);

  useEffect(() => {
    if (isValidValue(projectConfig?.projectId)) {
      dashboardLoader.execute({
        projectId: projectConfig?.projectId,
        pageName: pageName
      });
    }
  }, [projectConfig]);

  useEffect(() => {
    if (isValidValue(dates)) {
      onSubmit(dates);
    }
  }, [dates]);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('Dashboard')}
          >
            <>
              {' '}
              {panes.length > 0 && (
                <>
                  <div className="max-w-450 float-right">
                    <DashboardFilterComponent
                      data={data}
                      control={control}
                      setDates={setDates}
                    />
                  </div>
                </>
              )}
            </>
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main isfullPage={true}>
          <SidePaneRightContent.Center colFull={true}>
            <div>
              {isLoading ? (
                <Loader />
              ) : (
                projectConfig &&
                (panes.length > 0 ? (
                  <TabBar
                    defaultIndex={0}
                    options={panes}
                  />
                ) : (
                  <div className="my-8 flex flex-col items-center justify-center text-xl text-gray-700">
                    <Body
                      type="large"
                      text={t('There is no dashboard was prepared for this page.')}
                    />
                  </div>
                ))
              )}
            </div>
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>
    </>
  );
};

export default PageAllDashboards;
