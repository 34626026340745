import { X } from 'phosphor-react';
import React, { useState, useEffect } from 'react';
// API & HOOKS
import JiraApi from '../../../../api/JiraApi';
import { useApi } from '../../../../hooks/UseApi';
import UseProjectConfig from '../../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';
// COMPONENTS
import { Modal } from '../../../../components/modal';
import TabBar from '../../../../components/TabBar';
import JiraCommonSettingsGrid from '../details/JiraCommonSettingsGrid';
import JiraEntityConfigGrid from '../details/JiraEntityConfigGrid';
// DESIGN SYSTEM
import { Heading } from '../../../../designSystem/DesignSystem';
// HELPER
import { ENTITY_TYPES } from '../../../../constants';
import { getDefectName, getReleaseName, getRequirementName } from '../../../../helpers/ArtifactNameHelper';
import { getJiraProjectDropdownList } from '../../../../helpers/jira/JiraHelper';

const JiraSettingModal = ({ isOpen, closeModal, setJiraConfig, selectedItem }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const { t } = useTranslation();

  const [jiraIssueFields, setJiraIssueFields] = useState([]);
  const [jiraStringFields, setJiraStringFields] = useState([]);
  const [jiraProjects, setJiraProjects] = useState([]);

  const fetchJIRAAllProjects = useApi({
    name: 'fetching jira config item',
    api: JiraApi.fetchJIRAAllProjects,
    autoLoad: false,
    onSuccess: () => setJiraProjects(getJiraProjectDropdownList(fetchJIRAAllProjects?.data))
  });

  const fetchJIRAIssueFields = useApi({
    name: 'fetching jira config item',
    api: JiraApi.fetchJIRAIssueFields,
    autoLoad: false,
    onSuccess: () => setIssueFieldDropdownList(fetchJIRAIssueFields?.data)
  });

  const getIssueFields = () => fetchJIRAIssueFields.execute({ projectId });

  const setIssueFieldDropdownList = (issueFields) => {
    if (issueFields != null && issueFields.length !== 0) {
      let list = [];

      issueFields.map((issueField) => {
        if (
          issueField.schema &&
          issueField.schema?.type &&
          (issueField.schema.type === 'status' ||
            issueField.schema.type === 'issuetype' ||
            issueField.schema.type === 'priority' ||
            issueField.schema.type === 'any' ||
            issueField.schema.type === 'option' ||
            issueField.schema.type === 'string')
        )
          list.push({
            id: issueField?.id,
            name: issueField?.name,
            type: issueField?.schema?.type
          });
      });

      setJiraIssueFields(list);

      list = [];

      issueFields.map((issueField) => {
        if (issueField.schema && issueField?.schema?.type && issueField?.schema?.type === 'string')
          list.push({
            id: issueField?.id,
            name: issueField?.name,
            type: issueField?.schema?.type
          });
      });
      setJiraStringFields(list);
    }
  };

  useEffect(() => {
    if (projectId) {
      if (jiraIssueFields.length === 0) getIssueFields();
      if (jiraProjects.length === 0) fetchJIRAAllProjects.execute({ projectId });
    }
  }, [projectId]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'xl'}
      >
        <Modal.Title>
          <Heading.Group
            text={t('Jira Settings')}
            type="large"
          >
            <div
              className="flex cursor-pointer gap-4"
              onClick={closeModal}
            >
              <X
                size="20"
                weight="duotone"
              />
            </div>
          </Heading.Group>
        </Modal.Title>
        <Modal.Content h={'lg'}>
          <div className="m-4">
            <JiraCommonSettingsGrid jiraConfig={selectedItem} />
            <div className=" mt-5">
              <TabBar
                defaultIndex={0}
                options={[
                  {
                    name: getRequirementName(projectConfig),
                    disabled: false,
                    render: () => (
                      <JiraEntityConfigGrid
                        entityType={ENTITY_TYPES.REQUIREMENT}
                        jiraConfig={selectedItem}
                        setJiraConfig={setJiraConfig}
                        jiraProjects={jiraProjects}
                        jiraIssueFields={jiraIssueFields}
                        jiraStringFields={jiraStringFields}
                        loadingProject={fetchJIRAAllProjects?.loading}
                      />
                    )
                  },
                  {
                    name: getDefectName(projectConfig),
                    disabled: false,
                    render: () => (
                      <JiraEntityConfigGrid
                        entityType={ENTITY_TYPES.DEFECT}
                        jiraConfig={selectedItem}
                        setJiraConfig={setJiraConfig}
                        jiraProjects={jiraProjects}
                        loadingProject={fetchJIRAAllProjects?.loading}
                      />
                    )
                  },
                  {
                    name: getReleaseName(projectConfig),
                    disabled: false,
                    render: () => (
                      <JiraEntityConfigGrid
                        entityType={ENTITY_TYPES.RELEASE}
                        jiraConfig={selectedItem}
                        setJiraConfig={setJiraConfig}
                        jiraProjects={jiraProjects}
                        loadingProject={fetchJIRAAllProjects?.loading}
                      />
                    )
                  }
                ]}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Buttons></Modal.Buttons>
      </Modal>
    </>
  );
};

export default JiraSettingModal;
