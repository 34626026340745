import React, { useState, Fragment } from 'react';
import { WarningCircle, Eye, EyeClosed, LockKey } from 'phosphor-react';
import { P } from '../DesignSystem';
import { Controller } from 'react-hook-form';
import { capitalize } from '../../helpers/Helper';
import i18next from 'i18next';

let labelClass = ' text-sm font-medium  block mb-2 capitalize';
let labelSpanClass = ' ml-2 ';

const Password = (props) => {
  const { control, name, placeholder, required, minLength, hasError, label, disabled } = props;

  const [showPassword, setShowPassword] = useState(false);

  let inputBaseClass =
    ' flex bg-white flex-grow p-2.5 sm:text-sm rounded-lg h-9 focus:outline-none border border-gray-300  ';
  inputBaseClass += ' pr-3 pl-10';

  if (disabled) {
    inputBaseClass += ' text-gray-400 bg-gray-50 placeholder-gray-300 border-none ';
    labelClass += ' text-gray-300 ';
    labelSpanClass += ' text-gray-300';
  } else if (hasError && !disabled) {
    inputBaseClass += ' bg-red-50 border border-red-300 placeholder-gray-400 text-gray-900';
    labelSpanClass += ' text-red-500 ';
  } else {
    inputBaseClass += ' bg-gray-50 border border-gray-300 placeholder-gray-400 text-gray-900 focus:border-primary-400 ';
    labelSpanClass += ' text-red-500 ';
    labelClass += ' text-gray-900';
  }

  let type;
  if (showPassword) type = 'text';
  if (!showPassword) type = 'password';

  const togglePassword = () => setShowPassword(!showPassword);

  return (
    <>
      <div className="flex flex-grow flex-col p-1">
        <label
          htmlFor={name}
          className={labelClass}
        >
          {label}
          {required && label && <span className={!disabled && labelSpanClass}>*</span>}
        </label>
        <div className="flex flex-row gap-2">
          <Controller
            control={control}
            name={name}
            rules={{ minLength: minLength, required: required }}
            render={({ field, formState }) => (
              <>
                <div className="flex flex-1 flex-col">
                  <div className="relative flex items-center text-gray-400 focus-within:text-gray-600">
                    <div className="absolute ml-3 mt-0.5 h-5 w-5">
                      <LockKey weight="duotone" />
                    </div>
                    <input
                      placeholder={placeholder}
                      name={name}
                      className={inputBaseClass + 'justify-self-stretch bg-white'}
                      type={type}
                      {...field}
                      disabled={disabled}
                    />
                    {showPassword ? (
                      <div
                        className="absolute right-0 mr-4 ml-3 flex h-5 w-5 items-center"
                        onClick={togglePassword}
                      >
                        <Eye
                          weight="duotone"
                          size={24}
                        />{' '}
                      </div>
                    ) : (
                      <div
                        className="absolute right-0 mr-4 ml-3 flex h-5 w-5 items-center "
                        onClick={togglePassword}
                      >
                        {' '}
                        <EyeClosed
                          weight="duotone"
                          size={24}
                        />
                      </div>
                    )}
                  </div>
                  {formState.errors[name] && (
                    <>
                      {formState.errors[name]?.type === 'minLength' && (
                        <div className="my-2 -mb-6 flex items-center gap-2">
                          <WarningCircle
                            className=" text-red-500"
                            size={16}
                            weight="duotone"
                          />
                          <P
                            small
                            color="text-red-400"
                            text={`${label ? capitalize(label) : name} ${i18next.t(
                              `minimum length should be 10 characters.`
                            )}`}
                          />
                        </div>
                      )}
                      {formState.errors[name]?.type === 'required' && (
                        <div className="my-2 -mb-6 flex items-center gap-2">
                          <WarningCircle
                            className=" text-red-500"
                            size={16}
                            weight="duotone"
                          />
                          <P
                            small
                            color="text-red-400"
                            text={`${label ? capitalize(label) : name} ${i18next.t(`is required.`)}`}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          />
        </div>
      </div>
    </>
  );
};

export { Password };
