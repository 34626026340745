import React, { useState } from 'react';
// DESIGN SYSTEM IMPORTS
import { Button, Dropdown, H5 } from '../../designSystem/DesignSystem';
import { Modal } from '../modal';
// HELPER & CONSTANT IMPORTS
import { showSuccessMes, showInfoMes, showErrorMes, dismissAllMes } from '../../helpers/NotificationHelper';
import { RunExecutionStatus } from '../../constants';
import { isValidList } from '../../helpers/Helper';
// HOOK && CONTEXT IMPORTS+
import { useApp, useAppDispatch } from '../../context/AppContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UseQueryProject from '../../hooks/UseQueryProject';
import { setRefreshData, bulkExecutionWithWS } from '../../context/ProjectActions';

const BulkExecuteModal = ({ runIds, isOpen, closeModal, submitCallBack }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { projectId } = UseQueryProject('projectId');
  const app = useApp();
  const dispatch = useAppDispatch();
  const selectedRows = app?.selectedRows.map((item) => item.original.id);
  const [dataForm, setDataForm] = useState({});
  const [executionLoading, setExecutionLoading] = useState(false);

  const bulkExecutionWS = (body) => {
    setExecutionLoading(true);
    bulkExecutionWithWS({
      dispatch: dispatch,
      body: body,
      connectionCallback: () => {
        setExecutionLoading(false);
        showInfoMes(
          t(
            'Execution started. When it finished, you will be notified. Do not close browser tab until execution finished!'
          )
        );
        closeModal();
      },
      subscriptionCallback: () => {
        showSuccessMes(t('Bulk execution finished successfully at background. You can continue using Visium Manage.'));
        submitCallBack();
        dispatch(setRefreshData(true));
      },
      errorCallback: (msg) => {
        dismissAllMes();
        showErrorMes(t(msg));
      },
      projectId: projectId
    });
  };

  let bulkTestRunOptions = Object.values(RunExecutionStatus).map((item) => {
    return {
      ...item,
      text: t(item.text),
      name: t(item.name)
    };
  });

  const onSubmit = () => {
    let status = '';
    if (dataForm?.status) {
      status = dataForm?.status;
    }
    const body = {
      testRunIds: runIds,
      status,
      projectId
    };

    bulkExecutionWS(body);
  };

  return (
    <Modal
      open={isOpen}
      close={closeModal}
      w={'md'}
    >
      <Modal.Title>{t('Execute Test Runs')}</Modal.Title>
      <form
        className="flex flex-grow flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Content h={'md'}>
          <div className="flex min-h-[240px] flex-grow flex-col gap-5">
            <H5
              text={t('bulk_Execute', { selectedRows: selectedRows?.length })}
              className={'font-semibold'}
            />
            <div className="flex gap-4">
              <Dropdown
                required
                clearable
                control={control}
                label={t('Status')}
                name="status"
                placeholder={t('Select Status')}
                options={bulkTestRunOptions || []}
                passValue={(value) => {
                  setDataForm((prev) => ({ ...prev, status: isValidList(value) ? value[0]?.value : null }));
                  if (!isValidList(value)) setValue('status', null);
                }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="bulkTestRunModal-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={closeModal}
          />
          <Button
            id="bulkTestRunModal-submit-button"
            primary
            text={t('Execute')}
            type="submit"
            loading={executionLoading}
          />
        </Modal.Buttons>
      </form>
    </Modal>
  );
};

export default BulkExecuteModal;
