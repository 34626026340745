import React, { useRef, useState } from 'react';
// API & HOOKS
import UseQueryProject from '../../hooks/UseQueryProject';
import UseProjectConfig from '../../hooks/UseProjectConfig';
// DESIGN SYSTEM & COMPONENTS
import SelectedTableFilters from '../../components/filtering/SelectedTableFilters';
import { Heading } from '../../designSystem/DesignSystem';
import { SidePaneRightContent, TOP_HEADING_SIZE } from '../../components/Layout';
import TestCaseReactTable from './table/TestCaseReactTable';
// HELPERS & CONSTANTS
import { TCASE_VERSION_FILTER_TYPES, ENTITY_TYPES } from '../../constants';
import { getArtifactName } from '../../helpers/ArtifactNameHelper';
import UseSelectRow from '../../hooks/UseSelectRow';
import UseProjectUsers from '../../hooks/UseProjectUsers';

const AllTestCaseTablePage = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const users = UseProjectUsers(projectId);
  const { showButton } = UseSelectRow();

  // eslint-disable-next-line no-unused-vars
  const [filter, setFilter] = useState({ versionFilter: TCASE_VERSION_FILTER_TYPES.LAST_VERSION });
  const [allFilters, setAllFilters] = useState({});
  const [refreshTable, setRefreshTable] = useState(true);

  const ref = useRef();
  const tableHeight = ref?.current?.clientHeight - ref?.current?.childNodes[0]?.clientHeight - 65;

  return (
    <SidePaneRightContent overflow={true}>
      <SidePaneRightContent.Top>
        <Heading
          type={TOP_HEADING_SIZE}
          text={`${getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE)}`}
        />
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Main>
        <SidePaneRightContent.Center ref={ref}>
          <SelectedTableFilters
            defaultFilters={{ ...filter, ...allFilters, projectId }}
            setAllFilters={setAllFilters}
            allFilters={allFilters}
            entityType={ENTITY_TYPES.TEST_CASE}
            setRefreshTable={setRefreshTable}
            showBulkButton={showButton}
          />
          <TestCaseReactTable
            filter={{ ...allFilters, projectId, ...filter }}
            projectConfig={projectConfig}
            showCheckbox={true}
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            users={users}
            tableHeight={tableHeight - 20}
          />
        </SidePaneRightContent.Center>
      </SidePaneRightContent.Main>
    </SidePaneRightContent>
  );
};

export default AllTestCaseTablePage;
