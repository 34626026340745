export const ToggleLabel = ({ textSize, disabled, labelText }) => {
  return (
    <>
      <span
        className={`font-normal capitalize   
              ${textSize ? textSize : ' text-sm '} 
              ${disabled ? 'text-gray-400 ' : 'text-gray-500'}`}
      >
        {labelText}
      </span>
    </>
  );
};
