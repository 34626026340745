import { useState, useEffect, useCallback, useRef, useMemo } from 'react';

// Not:
// Api'den dönen responsedaki hata mesajını almak için onError: (error) => console.error(error.response.data.message)

const useApi = ({ api, autoLoad = false, name, params, onSuccess, onError, onFinally, initialValue } = {}) => {
  const [loaderState, setLoaderState] = useState({ data: initialValue, loading: autoLoad });
  const didCancel = useRef(false);
  const loadingRef = useRef(false);
  const paramRef = useRef({ api, name, onSuccess, onError, onFinally });

  paramRef.current.api = api;
  paramRef.current.name = name;
  paramRef.current.onSuccess = onSuccess;
  paramRef.current.onError = onError;
  paramRef.current.onFinally = onFinally;

  const execute = useCallback((executeParams) => {
    const param = paramRef.current;
    if (loadingRef.current) {
      process.env.NODE_ENV !== 'production' && console.error('Api called again while loading');
    }
    didCancel.current = false;
    loadingRef.current = true;
    setLoaderState({ data: initialValue, loading: true });

    return param
      .api(executeParams)
      .then((response) => {
        if (didCancel.current) {
          return;
        }

        loadingRef.current = false;
        setLoaderState({ data: response, loading: false });
      })
      .catch((err) => {
        if (didCancel.current) return;
        loadingRef.current = false;
        setLoaderState((prev) => ({ data: prev.data, loading: false }));
        if (err?.response?.status === 403 || err?.response?.status === 404) return; // This is handled in axios response interceptor
        if (param.onError && param.onError(err)) return;
      })
      .finally(() => {
        if (param.onFinally) param.onFinally();
      });
  }, []);

  useEffect(() => {
    if (loaderState?.data) {
      onSuccess && onSuccess(loaderState?.data);
    }
  }, [loaderState?.data]);
  const paramJson = JSON.stringify(params);

  useEffect(() => {
    if (autoLoad) {
      execute(params);
    }
    //Component unMount
    return () => {
      didCancel.current = true;
    };
  }, [autoLoad, paramJson, execute]);

  return useMemo(() => ({ data: loaderState.data, loading: loaderState.loading, execute }), [loaderState, execute]);
};

export { useApi };
