import React, { useEffect, useState } from 'react';
import { ResponsiveBump } from '@nivo/bump';
import { fillChartData, getChartHeading } from '../helper/DashboardHelper';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const CustomBumpChart = ({ chart, projectId, dateFilter, projectConfig }) => {
  const [data, setData] = useState([]);

  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      fillChartData(dataLoader?.data, chart, setData, projectId, null);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);

  return (
    <>
      {getChartHeading(chart, projectConfig)}
      <ResponsiveBump
        data={data}
        colors={{ scheme: 'accent' }}
        lineWidth={3}
        activeLineWidth={6}
        inactiveLineWidth={3}
        inactiveOpacity={0.15}
        pointSize={10}
        activePointSize={16}
        inactivePointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={3}
        activePointBorderWidth={3}
        pointBorderColor={{ from: 'serie.color' }}
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: -36
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Count',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
        axisRight={null}
      />
    </>
  );
};

export default CustomBumpChart;
