import React, { useState, useEffect } from 'react';
import { Dropdown } from '../../../designSystem/DesignSystem';
import { useTranslation } from 'react-i18next';

const UserCustomField = ({ control, field, selectedItem, userOptions, disabled, setValue }) => {
  const { t } = useTranslation();
  const [item, setItem] = useState({});

  useEffect(() => {
    userOptions.forEach((option) => option?.id === selectedItem && setItem(option));
  }, [field, userOptions]);

  const onClear = () => {
    setValue(field?.customFieldId, null);
  };

  return (
    <>
      <Dropdown
        control={control}
        disabled={disabled}
        clearable
        name={field?.customFieldId}
        label={field?.fieldName}
        required={field?.required}
        options={userOptions}
        selectedItem={item}
        placeholder={t('select_item', { item: field?.fieldName })}
        onClear={onClear}
      />
    </>
  );
};

export default UserCustomField;
