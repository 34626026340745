import React, { useEffect, useState } from 'react';
import JiraEntityApi from '../../../../api/JiraEntityApi';

import { Button } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import { ENTITY_TYPES } from '../../../../constants';
import { isValidValue, regexNumericValueControl } from '../../../../helpers/Helper';
import { showError, showErrorMes } from '../../../../helpers/NotificationHelper';
import { useApi } from '../../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import UseQueryProject from '../../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../../hooks/UseProjectConfig';
import { getArtifactName } from '../../../../helpers/ArtifactNameHelper';

const JiraRetrieveOptProgressModal = (props) => {
  const { isOpen, closeModal, entityType, selectedIntegrationConfigId, fetchedConfig, input } = props;
  const { projectId } = UseQueryProject('projectId');

  const projectConfig = UseProjectConfig(projectId);

  useEffect(() => {
    if (isOpen === true) {
      fetchJiraItems();
    }
  }, [isOpen]);

  const [isInProgress, setIsInProgress] = useState(false);
  const [retrieveCount, setRetrieveCount] = useState(0);
  const [resultMessage, setRetrieveMessage] = useState('');
  const [isShowLastStatus, setIsShowLastStatus] = useState(false);
  const { t } = useTranslation();

  const statusApi = {
    defect: JiraEntityApi.getDefectFetchingStatus,
    release: JiraEntityApi.getReleaseFetchingStatus,
    requirement: JiraEntityApi.getRequirementFetchingStatus
  };

  const fetchApi = {
    defect: JiraEntityApi.startDefectFetchOperation,
    release: JiraEntityApi.startReleaseFetchOperation,
    requirement: JiraEntityApi.startRequirementFetchOperation
  };

  const getFetchingStatus = useApi({
    name: 'getFetchingStatus',
    api: statusApi?.[entityType],
    autoLoad: false,
    onSuccess: () => {
      if (getFetchingStatus?.data) setCountStatus(getFetchingStatus?.data, null);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const startFetchOperation = useApi({
    name: 'startFetchOperation',
    api: fetchApi?.[entityType],
    autoLoad: false,
    onSuccess: () => {
      if (startFetchOperation?.data) setFieldsAfterOperation(startFetchOperation?.data);
    },
    onError: (err) => {
      setErrorStatus(err, null);
      if (err.response.data.message.startsWith('There is an ongoing')) {
        let numericValue = regexNumericValueControl(err.response.data.message);
        showError(
          t('JiraRetrieveOptProgressErrorMessage', {
            entity: getArtifactName(projectConfig, entityType),
            queueSize: numericValue
          })
        );
      } else {
        showErrorMes(err);
      }
    }
  });

  const fetchJiraItems = () => {
    setIsShowLastStatus(false);

    if (retrieveCount > 0) {
      setIsInProgress(true);
      setRetrieveMessage('Retrieving operation is in progress');
    } else {
      setIsInProgress(false);
      setRetrieveMessage('');
    }
    if (
      entityType === ENTITY_TYPES.REQUIREMENT ||
      entityType === ENTITY_TYPES.DEFECT ||
      entityType === ENTITY_TYPES.RELEASE
    ) {
      // const interval = setInterval(() => {
      //   getFetchingStatus.execute()
      // }, 40000);

      if (retrieveCount < 1) {
        setIsInProgress(true);
        setRetrieveMessage('Retrieving operation is in progress');
      }
      if (entityType === ENTITY_TYPES.REQUIREMENT || entityType === ENTITY_TYPES.DEFECT) {
        if (retrieveCount < 1) {
          startFetchOperation.execute(input);
        }
      } else {
        if (retrieveCount < 1) {
          startFetchOperation.execute({
            integrationConfigId: selectedIntegrationConfigId,
            entityConfigId: isValidValue(fetchedConfig?.id) ? fetchedConfig.id : null
          });
        }
      }
    } //ifend
  };

  function setCountStatus(response, interval) {
    setRetrieveCount(response);
    if (response > 0) {
      setIsInProgress(true);
      setRetrieveMessage('Retrieving jira issues...');
    } else {
      setIsInProgress(false);
      clearInterval(interval);
      setRetrieveMessage('Retrieving');
    }
  }

  function setFieldsAfterOperation(response) {
    setIsInProgress(false);
    setRetrieveCount(0);
    if (response === false) {
      setRetrieveMessage('Error is occurred while retrieving jira issues');
      setIsShowLastStatus(false);
    } else if (response === true) {
      setIsShowLastStatus(true);
      setRetrieveMessage('Retrieved Jira data');
    }
  }

  function setErrorStatus(error, interval) {
    setIsInProgress(false);
    clearInterval(interval);
    setRetrieveMessage('');
    // setRetrieveMessage("Unexpected error");
  }

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t('Retrieving Jira Data')}</Modal.Title>
        <Modal.Content h={'md'}>
          <div className="flex flex-grow flex-col gap-5">
            {isShowLastStatus ? (
              <div>
                {/* <Progress size="medium" percent={100} autoSuccess /> */}
                <div className="w-full rounded-full bg-gray-200">
                  {/* <div
                    className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"
                    style={{ width: "100%" }}
                  >
                  
                  </div> */}
                  <div className=" flex flex-col space-y-3">
                    <div className="relative w-full  rounded bg-gray-200">
                      <div
                        style={{ width: '100%' }}
                        className="absolute top-0 h-4 rounded bg-blue-200"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {isShowLastStatus === false &&
            (isInProgress || resultMessage === 'Retrieving' || resultMessage === 'Retrieving jira issues...') ? (
              <>
                <div>
                  <div className=" flex flex-col space-y-3">
                    <div className="relative w-full  rounded bg-gray-200">
                      <div
                        style={{ width: '100%' }}
                        className="progress-blue absolute top-0 h-4 rounded"
                      ></div>
                    </div>
                  </div>
                </div>
                <span className="text-sm">{t(' Retrieving')}</span>
              </>
            ) : (
              <>
                <span className="text-sm"> {i18next.t(resultMessage)}</span>
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="jiraRetrieveOptProgressModal-ok-button"
            primary
            text={t('Okay')}
            onClick={() => {
              closeModal();
            }}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default JiraRetrieveOptProgressModal;
