import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, P } from '../../designSystem/DesignSystem';
import { isValidValue, showMoreButtonVisibility } from '../../helpers/Helper';
import { isJiraContentValid } from '../../helpers/jira/JiraContentHelper';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const EntityDescription = ({ dataForm, jiraConfig, descriptionWidth }) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const [descriptionVis, setDescriptionVis] = useState(false);
  const [jiraDescription, setJiraDescription] = useState('');
  const [jiraDescriptionVis, setJiraDescriptionVis] = useState(false);

  useEffect(() => {
    if (dataForm) {
      setDescription(dataForm?.description);
    }
    if (jiraConfig && dataForm) {
      setJiraDescription(dataForm?.jiraContent?.fields?.description);
    }
  }, [dataForm, jiraConfig]);

  return (
    <>
      <div
        className={`mb-4 mt-4 grid rounded border border-gray-200 bg-gray-50 p-2 grid-cols-${
          isJiraContentValid(jiraConfig, dataForm) ? 2 : 1
        } gap-5`}
      >
        <div className="m-2">
          <div className="flex-row">
            <Heading
              className="w-36"
              type="small"
              text={t('Description')}
            />
            {showMoreButtonVisibility(
              description,
              isJiraContentValid(jiraConfig, dataForm) ? descriptionWidth / 2 - 44 : descriptionWidth - 24
            ) ? (
              <div className="flex flex-col">
                <P
                  text={description}
                  color="text-gray-800"
                  className={`font-regular ${!descriptionVis && 'truncate'} `}
                />
                <div className="flex justify-end	">
                  <span
                    className="text-xs font-medium text-blue-900 underline-offset-4 hover:underline"
                    onClick={() => setDescriptionVis(!descriptionVis)}
                  >
                    {!descriptionVis ? t('Show More') + ' >>' : t('Show Less') + ' <<'}
                  </span>
                </div>
              </div>
            ) : (
              <ReactMarkdown className="markdownViewer text-sm">
                {isValidValue(description) ? description : t('No description added.')}
              </ReactMarkdown>
            )}
          </div>
        </div>
        {isJiraContentValid(jiraConfig, dataForm) && (
          <div className="m-2">
            <div className={`flex-row`}>
              <Heading
                className="w-36"
                type="small"
                text={t('Jira Description')}
              />
              {showMoreButtonVisibility(jiraDescription, descriptionWidth / 2 - 44) ? (
                <div className="flex flex-col">
                  <P
                    text={jiraDescription}
                    color="text-gray-800"
                    className={`font-regular ${!jiraDescriptionVis && 'truncate'} `}
                  />
                  <div className="flex justify-end	">
                    <span
                      className="text-xs font-medium text-blue-900 underline-offset-4 hover:underline"
                      onClick={() => setJiraDescriptionVis(!jiraDescriptionVis)}
                    >
                      {!jiraDescriptionVis ? t('Show More') + ' >>' : t('Show Less') + ' <<'}
                    </span>
                  </div>
                </div>
              ) : (
                <P
                  text={isValidValue(jiraDescription) ? jiraDescription : t('No jira description added.')}
                  color="text-gray-800"
                  className="font-regular truncate"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default memo(EntityDescription);
