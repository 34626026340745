import React, { useCallback, useEffect, useMemo } from 'react';
import { FolderNotch, FolderNotchOpen } from 'phosphor-react';
import clsx from 'clsx';
import styles from './arborist.module.css';
import { PARENTS } from './utils/TreeConsts';
import Tippy from '@tippyjs/react';
import { useDragHook } from './dnd/useDragHook';

const TreeNode = ({
  tree,
  node,
  style,
  checkable,
  checkedNodes = [],
  expandedNodes = [],
  handleNodeClick,
  handleArrowClick,
  handleNodeSelect = () => {},
  getChildrenOfNode,
  activeNodeId,
  setSelectedItem
}) => {
  const dragRef = useDragHook(tree, node);

  const checked = useMemo(() => checkedNodes.includes(node.id), [checkedNodes, node]);

  useEffect(() => {
    const handleNodeOpenState = async () => {
      if (expandedNodes.includes(node.id) && !node.isOpen) {
        await getChildrenOfNode(node);
        node.open();
      } else if (!expandedNodes.includes(node.id) && node.isOpen) {
        node.close();
      }
    };

    handleNodeOpenState();
  }, [expandedNodes]);

  useEffect(() => {
    if (activeNodeId && activeNodeId === node?.id && !node.isSelected) {
      node.select();
      setSelectedItem(node.data);
    }
  }, [node]);

  const getNodeNameAndTooltip = useCallback(
    () => (
      <Tippy
        theme="custom-theme"
        content={node.data?.name}
        placement="bottom"
        disabled={activeNodeId === node.id}
        delay={[500, 250]}
      >
        <span
          id="node-text"
          onClick={handleNodeClick}
          className={styles.text}
        >
          {node.data?.name}
        </span>
      </Tippy>
    ),
    [node.data?.name]
  );

  return (
    <div
      id={node.id}
      ref={dragRef}
      style={style}
      className={clsx(styles.node, !checkable ? node.state : null)}
    >
      <FolderNotchIcon
        node={node}
        handleArrowClick={handleArrowClick}
      />
      {node?.data?.loading && (
        <svg
          fill="none"
          className="h-6 w-6 animate-spin"
          viewBox="0 0 32 32"
        >
          <path
            clipRule="evenodd"
            d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      )}
      {checkable && (
        <input
          type="checkbox"
          checked={checked}
          onChange={() => handleNodeSelect(node)}
          className="mr-1 h-3 w-3 cursor-pointer rounded-md text-gray-600"
        />
      )}
      {getNodeNameAndTooltip()}
    </div>
  );
};

const FolderNotchIcon = ({ node, handleArrowClick }) => {
  const { isOpen, data } = node;
  const { type } = data;
  const isFolder = useMemo(() => PARENTS.includes(type), [type]);

  return (
    <span
      onClick={handleArrowClick}
      className={isFolder ? styles.arrow : styles.noIcon}
    >
      {isFolder ? (
        isOpen ? (
          <FolderNotchOpen
            id="folder-notch-open"
            weight="duotone"
            width={17}
            height={17}
          />
        ) : (
          <FolderNotch
            id="folder-notch"
            weight="duotone"
            width={17}
            height={17}
          />
        )
      ) : null}
    </span>
  );
};

export default TreeNode;
