import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubscribeApi from '../../api/SubscribeApi';
// Design System Import
import { Button } from '../../designSystem/DesignSystem';
import { isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
// Helper Import
import { getSessionUser } from '../../helpers/PriviligeHelper';
import { useApi } from '../../hooks/UseApi';
// API and Hook Imports
import UseQueryProject from '../../hooks/UseQueryProject';

const SubscribeButton = (props) => {
  const { projectId } = UseQueryProject('projectId');
  const { entityId, entityType } = props;
  const [subscribe, setSubscribe] = useState(null);
  let sessionUser = getSessionUser();
  const { t } = useTranslation();

  const subscribeLoader = useApi({
    name: 'Fetch subscribe',
    api: SubscribeApi.getSubscribe,
    autoLoad: false,
    onSuccess: () => {
      setSubscribe(subscribeLoader?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const unsubscribeUser = useApi({
    name: 'Unsubscribe',
    api: SubscribeApi.unsubscribeUser,
    autoLoad: false,
    onSuccess: () => {
      setSubscribe(unsubscribeUser?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const subscribeToUser = useApi({
    name: 'Subscribe',
    api: SubscribeApi.subscribeUser,
    autoLoad: false,
    onSuccess: () => {
      setSubscribe(subscribeToUser?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const getSubscribe = async () => {
    if (!subscribeLoader.loading)
      await subscribeLoader.execute({ projectId, entityId, entityType, userId: sessionUser.id });
  };

  const subscribed = async () => {
    if (isValidValue(subscribe?.id)) {
      await unsubscribeUser.execute({ id: subscribe?.id });
    } else {
      const body = { projectId, entityId, entityType };
      await subscribeToUser.execute(body);
    }
  };

  useEffect(() => {
    if (entityId) getSubscribe();
  }, [entityId]);

  function getButtonMessage() {
    if (subscribe?.user) {
      return { message: !subscribeLoader?.loading ? 'Watching' : '', color: '#ffffff' };
    } else {
      return { message: !subscribeLoader?.loading ? 'Watch' : '', color: '#4B5563' };
    }
  }

  const buttonMes = getButtonMessage();

  const returnIcon = () => {
    if (buttonMes?.message === 'Watching') return 'Eye';
    if (buttonMes?.message === 'Watch') return 'EyeSlash';
  };

  const returnColor = () => {
    if (buttonMes?.message === 'Watching') return 'bg-primary-700';
    if (buttonMes?.message === 'Watch') return 'bg-gray-100';
  };

  const returnTooltipMessage = () => {
    if (buttonMes?.message === 'Watching') return t('Unwatch');
    if (buttonMes?.message === 'Watch') return t('Watch');
  };

  return (
    <>
      {projectId && (
        <Button
          id="commmon-subscribe-button"
          tooltip={returnTooltipMessage()}
          tertiary
          bgColor={returnColor()}
          onClick={() => subscribed()}
          loading={subscribeLoader?.loading}
          icon={returnIcon()}
          color={buttonMes?.color}
          size="small"
          // text={buttonMes?.message}
          weight="fill"
        />
      )}
    </>
  );
};

export default SubscribeButton;
