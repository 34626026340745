import React, { useEffect, useState } from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { fillChartData, getChartHeading } from '../helper/DashboardHelper';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const CustomScatterPlotChart = ({ chart, projectId, dateFilter, projectConfig }) => {
  const [data, setData] = useState([]);
  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      fillChartData(dataLoader?.data, chart, setData, projectId, null);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);
  return (
    <>
      {getChartHeading(chart, projectConfig)}
      <ResponsiveScatterPlot
        data={data}
        margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
        xScale={{ type: 'linear', min: 0, max: 'auto' }}
        xFormat=" >-"
        yScale={{ type: 'linear', min: 0, max: 'auto' }}
        yFormat=" >-"
        colors={{ scheme: 'accent' }}
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Count',
          legendPosition: 'middle',
          legendOffset: 46
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'size',
          legendPosition: 'middle',
          legendOffset: -60
        }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 130,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 5,
            itemDirection: 'left-to-right',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </>
  );
};

export default CustomScatterPlotChart;
