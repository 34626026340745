import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../designSystem/DesignSystem';
import { getOptionList } from '../../../helpers/CustomFieldHelper';
import { dateIsValid, isValidList } from '../../../helpers/Helper';

const ListCustomField = ({
  control,
  field,
  selectedItem,
  setCustomFieldForm,
  projectConfig,
  dataForm,
  disabled,
  setCustomValue
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState(getOptionList(field));
  const [item, setItem] = useState({});

  const requirementConfiguration = projectConfig?.configuration?.requirement;
  const jiraConfiguration = projectConfig?.configuration?.jira;

  /**
   * This function has been written for Assigned Test Case Control.
   * Edits the options that will be disabled in case of scenarios that have been assigned to the Requirement.
   */
  const disabledControlForAssignedTestCaseActive = (opt) => {
    switch (opt?.jiraId) {
      case requirementConfiguration?.runTestAutomationTranstionId:
        opt.disabled = true;
        break;
      case requirementConfiguration?.hasNotAssignedTestCaseWithTestAutomationTranstionId:
        opt.disabled = true;
        break;
      case requirementConfiguration?.hasNotAssignedTestCaseWithoutTestAutomationTranstionId:
        opt.disabled = true;
        break;
      case requirementConfiguration?.hasAssignedTestCaseWithoutTestAutomationTranstionId:
        opt.disabled = true;
        break;
      case requirementConfiguration?.hasAssignedTestCaseWithTestAutomationTranstionId:
        opt.disabled = true;
        break;
      case requirementConfiguration?.hasAssignedTestCaseTranstionId:
        if (dataForm.testCaseCount > 0) return (opt.disabled = false);
        opt.disabled = true;
        break;
      case requirementConfiguration?.hasNotAssignedTestCaseTranstionId:
        if (dataForm.testCaseCount == 0) return (opt.disabled = false);
        opt.disabled = true;
        break;
      default:
        opt.disabled = false;
    }
  };

  /**
   * This function has been written for Test Automation Control.
   * Edit the options that will be disabled if there are scenarios assigned to the Requirement and test automation is running.
   */
  const disableControlForTestAutomationActive = (isRunAutomation, opt) => {
    if (isRunAutomation) {
      switch (opt?.jiraId) {
        case requirementConfiguration?.runTestAutomationTranstionId:
          opt.disabled = false;
          break;
        case requirementConfiguration?.hasNotAssignedTestCaseWithTestAutomationTranstionId:
          if (dataForm?.testCaseCount === 0) return (opt.disabled = false);
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasNotAssignedTestCaseWithoutTestAutomationTranstionId:
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasAssignedTestCaseWithoutTestAutomationTranstionId:
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasAssignedTestCaseWithTestAutomationTranstionId:
          if (dataForm.testCaseCount > 0) return (opt.disabled = false);
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasAssignedTestCaseTranstionId:
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasNotAssignedTestCaseTranstionId:
          opt.disabled = true;
          break;
        default:
          opt.disabled = false;
          break;
      }
    } else {
      switch (opt?.jiraId) {
        case requirementConfiguration?.runTestAutomationTranstionId:
          opt.disabled = false;
          break;
        case requirementConfiguration?.hasNotAssignedTestCaseWithoutTestAutomationTranstionId:
          if (dataForm?.testCaseCount === 0) return (opt.disabled = false);
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasNotAssignedTestCaseWithTestAutomationTranstionId:
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasAssignedTestCaseWithoutTestAutomationTranstionId:
          if (dataForm.testCaseCount > 0) return (opt.disabled = false);
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasAssignedTestCaseWithTestAutomationTranstionId:
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasAssignedTestCaseTranstionId:
          opt.disabled = true;
          break;
        case requirementConfiguration?.hasNotAssignedTestCaseTranstionId:
          opt.disabled = true;
          break;
        default:
          opt.disabled = false;
          break;
      }
    }
  };

  /**
   * This function has been written disable control of Jira Status Dropdown items depending on whether there is a test case depending on the requirement.
   */
  const disabledAdd = (options) => {
    if (field?.jiraStatusTransition) {
      if (requirementConfiguration?.testAutomationControl === 'true') {
        options.forEach((opt) => {
          disableControlForTestAutomationActive(
            dateIsValid(
              dataForm?.jiraContent?.fields?.[jiraConfiguration?.testAutomationControlInRequirementCustomField]
            ),
            opt
          );
        });
        options.forEach((opt) => {
          Object.assign(opt, { active: !opt?.disabled });
        });
      } else {
        if (requirementConfiguration?.assignedTestCaseControl === 'true') {
          options.forEach((opt) => {
            disabledControlForAssignedTestCaseActive(opt);
          });
        }
        options.forEach((opt) => {
          Object.assign(opt, { active: !opt?.disabled });
        });
      }
    }
    setOptions(options);
  };

  useEffect(() => {
    disabledAdd(getOptionList(field));
    options.forEach((option) => option?.value === selectedItem && setItem(option));
  }, [field]);

  return (
    <>
      <Dropdown
        control={control}
        required={field?.required}
        name={field?.customFieldId}
        label={field?.fieldName}
        options={options}
        clearable
        disabled={disabled}
        placeholder={t('select_item', { item: field?.fieldName })}
        selectedItem={item}
        passValue={(value) => {
          setCustomValue(field.customFieldId, isValidList(value) ? value[0]?.id : null);
          setCustomFieldForm((prev) => ({ ...prev, [field.customFieldId]: isValidList(value) ? value[0]?.id : null }));
        }}
      />
    </>
  );
};
export default ListCustomField;
