import { useFloating, useInteractions, useDismiss, offset, shift, flip } from '@floating-ui/react-dom-interactions';
import { Body, Heading } from '../../designSystem/DesignSystem';
import { usePopup } from './Popup.Utils';
import ReactDOM from 'react-dom';

const Popup = ({ button, content, panelHeight, panelWidth, open, setOpen }) => {
  const { panelStyle } = usePopup({ panelHeight, panelWidth });

  const { context, x, y, strategy, reference, floating } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom-start',
    middleware: [offset(40), shift(), flip()]
  });

  useInteractions([useDismiss(context)]);

  const returnPopup = () => {
    return ReactDOM.createPortal(
      <>
        {open && (
          <div
            ref={floating}
            style={{
              top: y,
              left: x,
              position: strategy
            }}
            className="z-[60] mt-0.5 max-w-sm transform px-4 sm:px-0 lg:max-w-3xl  "
          >
            <div
              className="rounded border border-gray-200 bg-white p-4 text-gray-800 shadow-lg "
              style={panelStyle}
            >
              {content()}
            </div>
          </div>
        )}
      </>,
      document.getElementById('headlessui-portal-root')
    );
  };

  return (
    <>
      <div className="max-w-md">
        <div className="relative ">
          <>
            <div
              ref={reference}
              onClick={() => setOpen(true)}
            >
              {button && button()}
            </div>
            {returnPopup()}
          </>
        </div>
      </div>
    </>
  );
};

const Title = ({ children, subTitle, textSize }) => {
  return (
    <>
      <div className="pl-1">
        <Heading
          type={textSize ? textSize : 'base'}
          text={children}
        />
        {subTitle && (
          <Body
            type="large"
            text={subTitle}
          />
        )}
      </div>
    </>
  );
};

const Buttons = ({ children }) => {
  return (
    <>
      <div className="-m-4 mt-2 flex gap-4 bg-gray-50 p-4 ">{children}</div>
    </>
  );
};

Popup.Title = Title;
Popup.Buttons = Buttons;

export default Popup;
