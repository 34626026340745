import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// API & HOOKS
import { useNavigate, useSearchParams } from 'react-router-dom';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
// COMPONENTS
import AllTestCaseTablePage from './AllTestCaseTablePage';
import ContainerDetails from './container/ContainerDetails';
import TestCaseDetails from './TestCaseDetails';
import PageLeftTreeButton from '../../components/treeView/PageLeftTreeButton';
import { SidePaneLeftContent, SidePaneRightContent } from '../../components/Layout';
import { Loader } from '../../designSystem/DesignSystem';
import DetailsModals from '../../components/general/DetailsModals';
// HELPERS & CONSTANTS
import { ENTITY_TYPES } from '../../constants';
import { getNewUrlPath, isValidValue } from '../../helpers/Helper';
import AlertModal from '../../components/alert/AlertModal';
import { isViewTestCase } from '../../helpers/PriviligeHelper';
import TreeArborist from '../../components/tree/TreeArborist';
import * as TreeEvents from '../../components/tree/TreeEvents';
import { EVENT_TYPES } from '../../components/tree/TreeEvents';

const TestCasePage = (props) => {
  const { t } = useTranslation();
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  let [searchParams] = useSearchParams();
  const path = props.match?.path;
  const navigate = useNavigate();

  let { urlId, entityType, urlVersion, runId } = useMemo(() => {
    return {
      urlId: searchParams.get('id'),
      entityType: searchParams.get('type'),
      urlVersion: searchParams.get('version'),
      runId: searchParams.get('runId')
    };
  }, [searchParams]);

  const selectedItem = useMemo(
    () => ({
      id: urlId,
      rootId: urlId,
      type: entityType,
      majorVersion: urlVersion
    }),
    [urlId, urlVersion, entityType]
  );

  const renderSwitch = useCallback(() => {
    switch (entityType) {
      case ENTITY_TYPES.TEST_CONTAINER:
        return (
          <ContainerDetails
            selectedItem={selectedItem}
            submitCallBack={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_CASE, EVENT_TYPES.UPDATED, {
                ...item,
                type: ENTITY_TYPES.TEST_CONTAINER
              })
            }
            submitCallBackAfterClone={(item) => afterCreate(ENTITY_TYPES.TEST_CONTAINER, item)}
            submitCallBackAfterDeletion={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_CASE, EVENT_TYPES.DELETED, item)
            }
          />
        );
      case ENTITY_TYPES.TEST_CASE:
        return (
          <TestCaseDetails
            submitCallBackVersion={(item) => {
              TreeEvents.dispatch(ENTITY_TYPES.TEST_CASE, EVENT_TYPES.UPDATED, {
                ...item,
                type: ENTITY_TYPES.TEST_CASE
              });
              changeUrlVersion(item.majorVersion);
            }}
            majorVersion={urlVersion}
            urlId={urlId !== null ? urlId : selectedItem?.id}
            selectedItem={selectedItem}
            submitCallBack={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_CASE, EVENT_TYPES.UPDATED, {
                ...item,
                type: ENTITY_TYPES.TEST_CASE
              })
            }
            submitCallBackAfterClone={(item) => afterCreate(ENTITY_TYPES.TEST_CASE, item)}
            submitCallBackAfterDeletion={(item) =>
              TreeEvents.dispatch(ENTITY_TYPES.TEST_CASE, EVENT_TYPES.DELETED, item)
            }
            changeUrlVersion={changeUrlVersion}
          />
        );
      default:
        return <AllTestCaseTablePage />;
    }
  }, [selectedItem]);

  const selectItem = (item) => {
    navigate(getNewUrlPath(projectId, path, item));
  };

  const changeUrlVersion = (newVersion) => {
    navigate(getNewUrlPath(projectId, path, { ...selectedItem, majorVersion: newVersion }));
    urlVersion = newVersion;
  };

  const afterCreate = (type, form) =>
    isValidValue(form) &&
    TreeEvents.dispatch(ENTITY_TYPES.TEST_CASE, EVENT_TYPES.CREATED, {
      ...form,
      type
    });

  return (
    <>
      {!isViewTestCase(projectId) && (
        <AlertModal
          header={t('Access Denied')}
          content={t(`You have no permission to access this page.`)}
        />
      )}
      {runId ? (
        <DetailsModals />
      ) : (
        <>
          <SidePaneLeftContent>
            <PageLeftTreeButton
              pageKey={ENTITY_TYPES.TEST_CASE}
              selectedItem={selectedItem}
              exportEntityType={ENTITY_TYPES.TEST_CASE}
              importEntityType={ENTITY_TYPES.TEST_CASE}
              afterCreate={afterCreate}
              projectConfig={projectConfig}
            />
            <SidePaneLeftContent.Treeview>
              {!isValidValue(projectConfig?.projectId) ? (
                <Loader />
              ) : (
                <TreeArborist
                  module={ENTITY_TYPES.TEST_CASE}
                  draggable
                  projectId={projectId}
                  projectConfig={projectConfig}
                  onSelect={selectItem}
                  params={selectedItem}
                />
              )}
            </SidePaneLeftContent.Treeview>
          </SidePaneLeftContent>
          <SidePaneRightContent>{renderSwitch()}</SidePaneRightContent>
        </>
      )}
    </>
  );
};

export default TestCasePage;
