import React from 'react';

import { Heading, Loader } from '../../designSystem/DesignSystem';
import EntityActionButtonGroup from './EntityActionButtonGroup';
import { getEntityName } from '../../helpers/EntityHelper';

const EntityDetails = (props) => {
  const {
    data,
    users,
    loading,
    entityType,
    submitCallBack,
    submitCallBackAfterDeletion,
    submitCallBackAfterClone,
    submitCallBackVersion,
    extraButtons,
    closeModal,
    jiraConfig
  } = props;

  const entityName = getEntityName(data, entityType);

  const calculateHeadingType = (string) => {
    if (string.length > 160) return 'small';
    if (string.length > 100) return 'base';
    return 'medium';
  };

  return (
    <>
      {!loading ? (
        <div>
          <Heading.Group
            text={entityName}
            isNotBold={true}
            type={calculateHeadingType(entityName)}
          >
            <EntityActionButtonGroup
              data={data}
              users={users}
              extraButtons={extraButtons}
              entityType={entityType}
              submitCallBack={submitCallBack}
              submitCallBackAfterDeletion={submitCallBackAfterDeletion}
              submitCallBackAfterClone={submitCallBackAfterClone}
              submitCallBackVersion={submitCallBackVersion}
              closeModal={closeModal}
              jiraConfig={jiraConfig}
            />
          </Heading.Group>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default EntityDetails;
