import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AttachmentsApi from '../../api/AttachmentsApi';
import { useApi } from '../../hooks/UseApi';
import { Button } from '../../designSystem/DesignSystem';
import ConfirmationModal from '../confirm/ConfirmationModal';
import { fileSizeFormatter, getUserFirstLastNameById, openInNewTab, translatedDateFormat } from '../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { isPriviligedDeleteAttachment } from '../../helpers/PriviligeHelper';
import { downloadFile, fileTypeControl } from '../../helpers/AttachmentHelpers';

const AttachmentItem = ({ item, getFiles, users, disabled }) => {
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const deleteAttachment = useApi({
    name: 'Deleting attachment',
    api: AttachmentsApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      setOpenDeleteModal(false);
      getFiles();
      showSuccessMes(INFO_MESSAGES.delete);
    },
    onError: (err) => showErrorMes(err)
  });

  const handleDelete = () => {
    if (isPriviligedDeleteAttachment(item?.createdBy)) deleteAttachment.execute(item.name);
  };
  return (
    <>
      <div
        className="flex items-start gap-4 py-1"
        style={{ borderTopWidth: 'inherit' }}
      >
        <div
          id="attachment-thumbnail"
          className="mt-3 flex h-12 w-24 flex-shrink-0 items-center justify-center overflow-hidden"
        >
          {fileTypeControl(item, item?.originalName)}
        </div>
        <div
          id="attachment-metadata"
          className="flex flex-col justify-between overflow-x-hidden"
        >
          <div id="attachment-metadata-name">
            <span
              className="cursor-pointer break-words text-sm font-semibold"
              onClick={() => openInNewTab(item?.path, item)}
            >
              {item?.caption}
            </span>{' '}
            {'  '}
            <span className="whitespace-nowrap text-xs text-gray-500">{fileSizeFormatter(item?.fileSize)}</span>
          </div>
          <div
            id="attachment-metadata-size"
            className="text-xs text-gray-500"
          >
            {
              <>
                {' '}
                {translatedDateFormat(item?.modifiedTime, 'DD MMM YYYY HH:mm', t)} {'     '}
                {getUserFirstLastNameById(users, item?.createdBy)}{' '}
              </>
            }
          </div>
          <div
            id="attachment-buttons"
            className="flex cursor-pointer"
          >
            {isPriviligedDeleteAttachment(item?.createdBy) && (
              <Button
                id="attachmentItem-delete-button"
                secondary
                compact
                noBorder
                size="small"
                icon="Trash"
                weight="duotone"
                color={'#7171beeb'}
                disabled={disabled}
                onClick={() => setOpenDeleteModal(true)}
              />
            )}
            <Button
              id="attachmentItem-downloadFile-download-button"
              secondary
              compact
              noBorder
              icon="Download"
              size="small"
              weight="duotone"
              color={'#7171beeb'}
              onClick={() => downloadFile(item)}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={openDeleteModal}
        loading={deleteAttachment?.loading}
        closeModal={() => setOpenDeleteModal(false)}
        handleConfirm={handleDelete}
        header={t('Confirm Delete')}
        content={t('Do you really want to delete this?')}
      />
    </>
  );
};

export default memo(AttachmentItem);
