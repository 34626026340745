// DESIGN SYSTEM IMPORTS
import { X } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import ReleaseApi from '../../api/ReleaseApi';
import RequirementApi from '../../api/RequirementApi';
import TestCaseApi from '../../api/TestCaseApi';
// COMPONENT IMPORTS
import ConfirmationModal from '../../components/confirm/ConfirmationModal';
import { getReportActionForTable } from '../../components/report/ReportHelper';
import RequirementReportModal from '../../components/report/requirement/RequirementReportModal';
import Table from '../../components/table/Table';
import { TableColumns } from '../../components/table/TableColumns';
import { ENTITY_TYPES, PRIVILEGES } from '../../constants';
import { getFolderName, getReleaseName, getRequirementName } from '../../helpers/ArtifactNameHelper';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../helpers/ButtonConstants';
import { getPageFilterInput } from '../../helpers/Helper';
import { showError, showSuccessMes } from '../../helpers/NotificationHelper';
import { hasPrivilege } from '../../helpers/PriviligeHelper';
import { getI18n, useTranslation } from 'react-i18next';

// HELPERS & CONSTANTS IMPORTS
import {
  DEFAULT_PAGE_PARAMS,
  disableResizingForColumns,
  getNotResizedColumnList,
  getTableActions,
  handleSelectedRow,
  translateColumns
} from '../../helpers/TableHelper';

// HOOK IMPORTS
import { useApi } from '../../hooks/UseApi';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseTableColumns from '../../hooks/UseTableColumns';
import UseTableHelper from '../../hooks/UseTableHelper';
import UseUserTablePreferences from '../../hooks/UseUserTablePreferences';
import UseSystemField from '../../hooks/UseSystemField';

const RequirementReactTable = (props) => {
  const {
    users,
    filter,
    jiraConfig,
    refreshStatus,
    setRefreshStatus,
    entityType,
    entityId,
    showContribution,
    showCheckbox,
    tableHeight
  } = props;
  const [customFilters, setCustomFilters] = useState({});
  const { createTableData } = UseTableHelper();
  const { projectId } = UseQueryProject('projectId');

  UseSystemField(projectId, ENTITY_TYPES.REQUIREMENT, 'status');

  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('code', 'ASC'));
  const projectConfig = UseProjectConfig(projectId);

  const [selectedItem, setSelectedItem] = useState({});
  const [unassignModalOpen, setUnassignModalOpen] = useState(false);
  const { t } = useTranslation();
  const [rerenderKey, setRerenderKey] = useState(0);

  const unassignData = useApi({
    name: 'Unassign Requirement',
    api: entityType === ENTITY_TYPES.RELEASE ? ReleaseApi.unassignRequirement : TestCaseApi.unassignRequirement,
    onSuccess: () => {
      setUnassignModalOpen(false);
      showSuccessMes(t('unassignRequirement', { entity: getRequirementName(projectConfig) }));
      refreshTable();
    },
    onError: () =>
      showError(t('unassignError', { entity: getRequirementName(projectConfig, ENTITY_TYPES.REQUIREMENT) }))
  });

  const dataLoader = useApi({
    name: 'fetching requirement',
    api: entityType === ENTITY_TYPES.FOLDER ? RequirementApi.getByParent : RequirementApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
      setRefreshStatus(false);
    }
  });

  const [reportModal, setReportModal] = useState(false);
  const optionsFn = (row) => {
    let ops = [];
    if (
      (entityType === ENTITY_TYPES.RELEASE && hasPrivilege(projectId, PRIVILEGES.UNASSIGN_RELEASE_REQUIREMENT)) ||
      (entityType === ENTITY_TYPES.TEST_CASE && hasPrivilege(projectId, PRIVILEGES.UNASSIGN_TESTCASE_REQUIREMENT))
    ) {
      ops.push({
        name: t('Unassign'),
        icon: (weight) => (
          <X
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
          setUnassignModalOpen(true);
        }
      });
    }

    let opt = getReportActionForTable(projectId, handleSelectedRow, dataLoader, setReportModal, row, setSelectedItem);
    if (opt !== null) {
      ops.push(opt);
    }
    return ops;
  };

  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.REQUIREMENT);

  const tData = useMemo(() => {
    let data = [];
    if (dataLoader?.data?.content) {
      const columnsConfig = TableColumns[ENTITY_TYPES.REQUIREMENT];

      // Folder Name and Release are a configurable fields, so they should be updated
      const folderNameColumn = columnsConfig.find((column) => column?.key === 'parentName');
      if (folderNameColumn?.name)
        folderNameColumn.name = `${t('entityName', { entity: getFolderName(projectConfig) })} `;

      const folderCodeColumn = columnsConfig.find((column) => column?.key === 'parentCode');
      if (folderCodeColumn?.name)
        folderCodeColumn.name = `${t('entityCode', { entity: getFolderName(projectConfig) })} `;

      const releaseColumn = columnsConfig.find((column) => column?.key === 'releases');
      if (releaseColumn?.name) releaseColumn.name = `${getReleaseName(projectConfig)}`;

      data = dataLoader?.data?.content.map((item) =>
        createTableData(item, columnsConfig, projectId, showContribution, ENTITY_TYPES.REQUIREMENT, users)
      );
    }
    return data;
  }, [dataLoader?.data?.content, getI18n().language]);

  const { selectedColumns } = UseTableColumns(projectId, ENTITY_TYPES.REQUIREMENT);

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    getItems(pageInput, customFilters);
  };

  const refreshTable = () => getByPage({});

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) {
      fil = { ...fil, ...userSavedFilters?.filterInput };
    }

    let body = { ...fil, ...getPageFilterInput(pageInput), projectId };

    if (body?.sortBy === 'contribution' || body?.sortBy === 'coverage') {
      body = { ...body, sortBy: 'code' };
    }
    dataLoader.execute(body);
  };

  const handleUnassign = () => {
    if (
      (entityType === ENTITY_TYPES.RELEASE && hasPrivilege(projectId, PRIVILEGES.UNASSIGN_RELEASE_REQUIREMENT)) ||
      (entityType === ENTITY_TYPES.TEST_CASE && hasPrivilege(projectId, PRIVILEGES.UNASSIGN_TESTCASE_REQUIREMENT))
    ) {
      unassignData.execute({ id: entityId, ids: [selectedItem?.id] });
    }
  };

  useEffect(() => {
    if (refreshStatus === true) {
      refreshTable();
      setRerenderKey((prevKey) => prevKey + 1);
    }
  }, [refreshStatus]);

  let columns = [getTableActions(optionsFn), ...selectedColumns];
  columns = disableResizingForColumns(columns, getNotResizedColumnList(ENTITY_TYPES.REQUIREMENT));
  columns = showContribution !== true ? columns.filter((x) => x?.accessor !== 'contribution') : columns;
  columns = translateColumns(columns, t);

  return (
    <>
      <Table
        key={rerenderKey}
        columns={columns}
        data={tData}
        pagination
        showCheckbox={showCheckbox}
        loading={dataLoader?.loading}
        pageParams={pageParams}
        getByPage={getByPage}
        entityType={ENTITY_TYPES.REQUIREMENT}
        onFilter={onFilter}
        jiraConfig={jiraConfig}
        height={tableHeight}
      />
      {reportModal === true && (
        <RequirementReportModal
          entityData={selectedItem}
          isOpen={reportModal}
          closeModal={() => setReportModal(false)}
          entityId={selectedItem?.id}
          users={users}
        />
      )}
      {unassignModalOpen && (
        <>
          <ConfirmationModal
            isOpen={unassignModalOpen}
            loading={unassignData?.loading}
            closeModal={() => setUnassignModalOpen(false)}
            handleConfirm={handleUnassign}
            header={t('Unassign')}
            content={t('Are you sure to unassign data?')}
          />
        </>
      )}
    </>
  );
};

export default RequirementReactTable;
