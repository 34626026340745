import { setProjecHeader } from '../api/ApiClient';
import { PROJECT_ACTION_KEYS, SYSTEM_ACTION_KEYS } from './Actions';
import WebSocketService from '../api/WebSocketService';

export const loadProject = () => {
  try {
    const serializedProject = localStorage.getItem('project');
    if (serializedProject === null) {
      return undefined;
    }
    return JSON.parse(serializedProject);
  } catch (err) {
    return undefined;
  }
};

export const saveProject = (project) => {
  try {
    if (project) {
      const serializedProject = JSON.stringify(project);
      setProjecHeader(project?.id);

      localStorage.setItem('project', serializedProject);
    }

    return {
      type: PROJECT_ACTION_KEYS.UPDATED_SELECTED_PROJECT,
      project
    };
  } catch (err) {
    console.error(err);
  }
};

export const saveProjectConfig = (projectId, data) => ({
  type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_CONFIG,
  config: { projectId, ...data }
});

export const saveProjectUserGroups = (projectId, data) => ({
  type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_USER_GROUPS,
  projectUserGroups: { projectId, ...data }
});

export const saveEntityCustomFields = (projectId, entity, values) => ({
  type: PROJECT_ACTION_KEYS.UPDATED_CUSTOM_FIELDS,
  entity,
  values,
  projectId
});

export const saveEntitySystemField = (projectId, entity, field, values) => ({
  type: PROJECT_ACTION_KEYS.UPDATED_SYSTEM_FIELD,
  entity,
  field,
  values,
  projectId
});

export const saveEntitySystemFieldParams = (projectId, entity, values) => ({
  type: PROJECT_ACTION_KEYS.UPDATED_SYSTEM_FIELD_PARAMS,
  entity,
  values,
  projectId
});

export const saveEntityAllFields = (projectId, entityType, values) => ({
  type: PROJECT_ACTION_KEYS.ENTITY_ALL_FIELDS,
  entityType,
  values,
  projectId
});

export const saveUserCustomTableFiltersByType = (projectId, entityType, values) => ({
  type: PROJECT_ACTION_KEYS.USER_TABLE_FILTERS,
  entityType,
  values,
  projectId
});

export const saveUserCustomTableColumnsByType = (projectId, entityType, values) => ({
  type: PROJECT_ACTION_KEYS.USER_TABLE_COLUMNS,
  entityType,
  values,
  projectId
});

export const resetProject = () => {
  localStorage.removeItem('project');
  return {
    type: PROJECT_ACTION_KEYS.UPDATED_SELECTED_PROJECT
  };
};

export const saveProjectUsers = (projectId, values) => ({
  type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_USERS,
  values,
  projectId
});

export const saveSystemParameterConfig = (data) => ({
  type: SYSTEM_ACTION_KEYS.UPDATED_SYSTEM_PARAMS,
  systemParams: data
});

export const saveRunStatuses = (values) => ({
  type: PROJECT_ACTION_KEYS.SAVE_RUN_STATUSES,
  values
});

export const saveSelectedColumns = (projectId, entityType, values) => ({
  type: PROJECT_ACTION_KEYS.UPDATE_SELECTED_COLUMNS,
  projectId,
  entityType,
  values
});

export const saveJiraConfig = (projectId, data) => ({
  type: PROJECT_ACTION_KEYS.SAVED_JIRA_CONFIG,
  jiraConfig: { projectId, ...data }
});

export const addRequest = (data) => ({
  type: PROJECT_ACTION_KEYS.ADD_REQUEST,
  data
});

export const removeRequest = (predicate = () => {}) => ({
  type: PROJECT_ACTION_KEYS.REMOVE_REQUEST,
  predicate
});

export const setSelectedRows = (data) => ({
  type: PROJECT_ACTION_KEYS.SET_SELECTED_ROWS,
  selectedRows: data
});

export const setSelectedRunTestCases = (data) => ({
  type: PROJECT_ACTION_KEYS.SET_SELECTED_RUN_TESTCASES,
  selectedRunTestCases: data
});

export const setRefreshData = (data) => ({
  type: PROJECT_ACTION_KEYS.SET_REFRESH_DATA,
  refreshData: data
});

export const setRefreshTree = (data) => ({
  type: PROJECT_ACTION_KEYS.SET_REFRESH_TREE,
  refreshTree: data
});

export const webSocketUpdate = (webSocket, status) => ({
  type: PROJECT_ACTION_KEYS.WEB_SOCKET_UPDATE,
  webSocket: webSocket,
  wsStatus: status
});

export const setSelectedReport = (data) => ({
  type: PROJECT_ACTION_KEYS.SET_SELECTED_REPORT,
  selectedReport: data
});

export const bulkExecutionWithWS = ({ dispatch, body, connectionCallback, subscriptionCallback, errorCallback, projectId }) => {
  const webSocket = WebSocketService(projectId);

  webSocket.connect(() => {
    connectionCallback();

    webSocket.subscribe({
      destination: '/bulk-execution-client',
      callback: (msg) => {
        if (msg != null && msg.length > 6 && msg.substring(0, 6) === "ERROR:") {
          msg = msg.substring(6, msg.length);
          errorCallback(msg);
        } else {
          subscriptionCallback();
        }
        webSocket.disconnect();
      }
    });

    webSocket.sendMessage('/bulk/execution', body);
    dispatch(webSocketUpdate(webSocket, 'CONNECTED'));
  });

  return webSocketUpdate(webSocket, 'CREATED');
};
