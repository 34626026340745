import React from 'react';
// Design System Import
import { Button } from '../../designSystem/DesignSystem';
import { Modal } from '../modal';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ isOpen, closeModal, handleConfirm, header, content, loading }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      close={() => closeModal}
      w="sm"
    >
      <Modal.Title>{header}</Modal.Title>
      <Modal.Content h={'md'}>{content}</Modal.Content>
      <Modal.Buttons>
        <Button
          id="confirmation-cancel-button"
          secondary
          text={t('Cancel')}
          onClick={() => closeModal()}
        />
        <Button
          id="confirmation-confirm-button"
          primary
          text={t('Confirm')}
          loading={loading}
          onClick={() => handleConfirm()}
        />
      </Modal.Buttons>
    </Modal>
  );
};

export default ConfirmationModal;
