import { Dialog } from '@headlessui/react';
import { classNames } from '../../helpers/Helper';
import { Buttons, Content, ContentContainer, Overlay, Title, Text } from './Modal.helper';

const Modal = ({ open, close, z, w, children }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        className={classNames('fixed inset-0 flex', z ? z : `z-[2500]`)}
      >
        <Overlay />
        <ContentContainer w={w}>{children}</ContentContainer>
      </Dialog>
    </>
  );
};

Modal.Content = Content;
Modal.Title = Title;
Modal.Buttons = Buttons;
Modal.Text = Text;

export { Modal };
