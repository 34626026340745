import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const MAIL_SERVER = BASE_URL + 'mailservers';
export const MAIL_SERVER_RECIPIENT = BASE_URL + 'mailserver-recipient';
export const SYSTEM_FIELD_PATH = BASE_URL + 'system-fields';

class MailConfigApi {
  async getAll(data) {
    const resp = await axios.get(`${MAIL_SERVER}`, { params: data });
    return resp.data;
  }
  async delete(data) {
    const resp = await axios.delete(`${MAIL_SERVER}/${data?.id}`);
    return resp.data;
  }
  async add(data) {
    const resp = await axios.post(`${MAIL_SERVER}`, data);
    return resp.data;
  }
  async edit(data) {
    const resp = await axios.put(`${MAIL_SERVER}/${data?.id}`, data);
    return resp.data;
  }
  async test(data) {
    const resp = await axios.post(`${MAIL_SERVER}/test`, data);
    return resp.data;
  }
  async addUser(data) {
    const resp = await axios.put(`${MAIL_SERVER}/assign/${data?.id}`, data);
    return resp.data;
  }
  async removeUser(data) {
    const resp = await axios.put(`${MAIL_SERVER}/unassign/${data?.id}`, data);
    return resp.data;
  }
  async getRecipients(data) {
    const resp = await axios.get(`${MAIL_SERVER}/${data?.id}/mail-server-recipient`);
    return resp.data;
  }

  async getRecipientConfigs(data) {
    const resp = await axios.get(`${SYSTEM_FIELD_PATH}/configs`, { params: data });
    return resp.data;
  }

  //MAIL SERVERS RECIPIENTS
  async assignRecipientPermission(data) {
    const resp = await axios.put(`${MAIL_SERVER_RECIPIENT}/${data?.id}/assign-permission/`, data?.permissions);
    return resp.data;
  }
}

export default new MailConfigApi();
