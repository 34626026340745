import React from 'react';
import * as Icons from 'phosphor-react';
import { classNames } from '../../helpers/Helper';
import { Body } from '../DesignSystem';
import { returnIcon } from '../DesignSystem.Utils';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/translucent.css';

const Button = ({
  primary,
  secondary,
  secondary2,
  tertiary,
  inlineAdd,
  disabled,
  loading,
  text,
  icon,
  iconPosition,
  size,
  compact,
  type,
  onClick,
  color,
  weight,
  tooltip,
  tooltipPosition,
  right,
  noBorder,
  fluid,
  bgColor,
  id,
  rounded
}) => {
  const returnButton = () => {
    return (
      <button
        type={type}
        id={id}
        onClick={type !== 'submit' ? (e) => onClick(e) : () => {}}
        disabled={disabled || loading}
        style={{ float: right ? 'right' : 'left', borderWidth: noBorder ? 0 : 1 }}
        className={classNames(
          right ? 'float-right' : 'float-left',
          noBorder ? 'border-0' : 'border',
          // Base Classes
          'flex items-center justify-center tracking-wide transition duration-500  ease-in-out',
          fluid && 'w-full',
          disabled && 'bg-gray-50',
          // Size Classes
          (size === 'base' || !size) && 'h-10 rounded-lg px-5 py-3 text-sm font-medium',
          size === 'small' && 'h-8 rounded-md px-3 py-1.5 text-xs font-medium',
          size === 'xsmall' && 'h-8 rounded px-2 py-1 text-xs font-normal',
          size === 'large' && 'h-12 rounded-lg px-7 py-0 text-sm font-medium leading-none ',
          // State Classes
          loading ? 'cursor-wait gap-2' : 'hover:cursor-pointer ',
          icon && text && 'gap-2',
          rounded && 'rounded-full',
          // Type Classes
          primary && !disabled && 'cursor-wait bg-primary-700 text-white hover:bg-primary-600',
          primary && disabled && '  text-gray-300',
          secondary && !disabled && 'border-violet-200 bg-transparent text-primary-700 hover:bg-primary-100',
          secondary && disabled && '  text-gray-400 ',
          secondary2 && !disabled && 'cursor-wait bg-gray-100 text-gray-500 hover:bg-gray-200',
          secondary2 && disabled && '  text-gray-300',
          tertiary && !disabled && ' border-none bg-gray-100 text-gray-700 hover:bg-gray-300 hover:text-gray-800',
          tertiary && !disabled && bgColor && bgColor,
          tertiary && disabled && ' border-none bg-gray-100 text-gray-400 hover:cursor-not-allowed',
          inlineAdd &&
            !disabled &&
            'border-none bg-gray-100 text-gray-700 hover:border-dashed hover:border-primary-400 hover:text-primary-700'
        )}
      >
        {(iconPosition?.toLowerCase() === 'left' || iconPosition === null || iconPosition === undefined) && (
          <>
            {loading && (
              <>
                <Icons.SpinnerGap
                  className="animate-spin"
                  size={size === 'small' ? 16 : 24}
                  weight="duotone"
                />
              </>
            )}
            {icon && !loading && returnIcon(icon, color, size, weight)}
          </>
        )}
        {!compact && (
          <>
            <Body
              type={classNames(size === 'xsmall' && 'xsmall', size === 'small' && 'small')}
              color={classNames(
                'whitespace-nowrap',
                primary && !disabled && 'text-white',
                secondary && !disabled && 'text-primary-700',
                disabled && 'text-gray-400',
                tertiary && 'text-gray-700'
              )}
              text={text}
            />
          </>
        )}
        {iconPosition?.toLowerCase() === 'right' && (
          <>
            {loading && (
              <>
                <Icons.SpinnerGap
                  className="animate-spin"
                  size={size === 'small' ? 16 : 24}
                  weight="duotone"
                />
              </>
            )}
            {icon && !loading && returnIcon(icon, color, size, weight)}
          </>
        )}
      </button>
    );
  };

  if (tooltip) {
    return (
      <>
        <Tippy
          theme="custom-theme"
          content={tooltip}
          animation="scale-extreme"
          placement={tooltipPosition ? tooltipPosition : 'bottom'}
        >
          {returnButton()}
        </Tippy>
      </>
    );
  } else {
    return <>{returnButton()}</>;
  }
};

export { Button };
