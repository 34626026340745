import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//Api and Hook Imports
import CustomFieldApi from '../../../api/CustomFieldApi';
import { useApi } from '../../../hooks/UseApi';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
// Design System Imports
import { Input, Dropdown, Button, H5, Body } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
//Helper Imports
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { isValidValue } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { getEntityTypeList } from '../../../helpers/CloneHelper';

const CustomFieldCloneModal = (props) => {
  const { isOpen, data, closeModal, projectId, relatedEntity } = props;
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const projectConfig = UseProjectConfig(projectId);
  const { t } = useTranslation();

  const cloneCustomField = useApi({
    name: 'clone custom field',
    api: CustomFieldApi.clone,
    onSuccess: () => {
      closeModal(cloneCustomField?.data);
      showSuccessMes(INFO_MESSAGES.clone);
      reset();
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (isValidValue(data)) {
      setValue('fieldName', data.fieldName);
    }
  }, [data]);

  const onSubmit = (form) => {
    let body = {
      id: data.customFieldId,
      fieldName: form.fieldName,
      entityType: form.entityType[0].id
    };
    cloneCustomField.execute(body);
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
        w={'md'}
      >
        <Modal.Title>
          {' '}
          {t('Custom Field Clone')} <H5 text={`From ${getArtifactName(projectConfig, relatedEntity)}`} />
        </Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-8">
              <div className="flex flex-col justify-between ">
                <>
                  <Body
                    text={t('If entity artifact has same field names, fields will be not added and check field table.')}
                    type="large"
                  />
                  <Body
                    text={t('Rules are not copied for new fields.')}
                    type="large"
                  />
                </>
              </div>
              <Input
                placeholder={t('Custom Field Display Name')}
                control={control}
                name="fieldName"
                label={t('Display Name')}
                required
                type="text"
              />
              <Dropdown
                clearable
                control={control}
                name={'entityType'}
                label={t('Clone to Entity Type')}
                options={getEntityTypeList(projectConfig, relatedEntity)}
                required
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="customFieldCloneModal-cancel-button"
              secondary
              text={t('Cancel')}
              type="button"
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="customFieldCloneModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={cloneCustomField?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};
export default CustomFieldCloneModal;
