import React, { useState } from 'react';

import { ACCEPTED_FILE_TYPES } from '../../constants';
import { isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';

import AttachmentsApi from '../../api/AttachmentsApi';
import TestRunExecutionApi from '../../api/TestRunExecutionApi';
import { useApi } from '../../hooks/UseApi';
import UseQueryProject from '../../hooks/UseQueryProject';

import AttachmentList from './AttachmentList';
import AttachmentUpload from './AttachmentUpload';
import { Loader } from '../../designSystem/DesignSystem';
import { useEffect } from 'react';

const accept = ACCEPTED_FILE_TYPES;

const Upload = ({ entityType, entityId, users, submitCallBack, lockStatus, stepAttachment }) => {
  const { projectId } = UseQueryProject('projectId');
  const [attachments, setAttachments] = useState();

  const attachmentLoader = useApi({
    name: 'Getting attachments',
    api: AttachmentsApi.getAll,
    onSuccess: (res) => {
      const images = res.map((item) => ({ ...item, caption: item.originalName, source: item.path }));
      setAttachments(images);
      if (submitCallBack) submitCallBack(res?.length);
    },
    onError: (err) => showErrorMes(err)
  });

  const executionStepAttachments = useApi({
    name: 'fetch test run execution attachments',
    api: TestRunExecutionApi.getExecutionAttachments,
    onSuccess: () => {
      const images = executionStepAttachments?.data.map((item) => ({
        ...item,
        caption: item.originalName,
        source: item.path
      }));
      setAttachments(images);
    }
  });

  const getAttachments = () => {
    if (entityId && stepAttachment) executionStepAttachments.execute(entityId);
    else attachmentLoader.execute({ id: entityId });
  };

  useEffect(() => {
    setAttachments([]);
    if (entityId && entityType) getAttachments();
  }, [entityId, entityType]);

  return (
    <>
      {isValidValue(entityId) && (
        <AttachmentUpload
          disabled={lockStatus?.addAttach || stepAttachment}
          accept={accept}
          projectId={projectId}
          entityType={entityType}
          entityId={entityId}
          getFiles={() => getAttachments()}
          attachmentCountExisting={attachments?.length}
        />
      )}
      {attachmentLoader.loading ? (
        <Loader />
      ) : (
        <AttachmentList
          attachments={attachments}
          getFiles={() => getAttachments()}
          users={users}
          entityType={entityType}
          disabled={lockStatus?.deleteAttach}
        />
      )}
    </>
  );
};

export default Upload;
