import React, { useState, useEffect, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { useApi } from '../../../hooks/UseApi';
import JiraApi from '../../../api/JiraApi';
import { isValidList, isValidValue } from '../../../helpers/Helper';
import {
  getOptionListByIdName,
  getDefectProjectOptions,
  findIssueTypesInConfigByProject
} from '../../../helpers/jira/JiraHelper';
import { Dropdown, Input, Loader, TextArea } from '../../../designSystem/DesignSystem';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import UseAllUsers from '../../../hooks/UseAllUsers';
import UseProjectConfig from '../../../hooks/UseProjectConfig';

import {
  showJiraAssignee,
  showJiraLink,
  showJiraDescription,
  showJiraPriority
} from '../../../pages/settings/jiraConfigSettings/details/JiraConfigHelper';
import { ENTITY_TYPES } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { translateArrItemName } from '../../../helpers/TranslationHelper';

const JiraIssueModalContent = (props) => {
  const { entityType, jiraConfig, dataForm, setDataForm, projectId, control, setValue, isUpdate } = props;

  const { t } = useTranslation();
  const [configJiraProjectOptions, setConfigJiraProjectOptions] = useState([]);
  const [configJiraProjects, setConfigJiraProjects] = useState([]);
  const [jiraPriorities, setJiraPriorities] = useState([]);
  const [linkTypes, setlinkTypes] = useState([]);
  const [linkOptions, setLinkOptions] = useState([]);
  const [searchKey, setSearchKey] = useState('');

  const projectConfig = UseProjectConfig(projectId);
  const users = UseAllUsers();

  const getJiraConfigs = useApi({
    name: 'fetch jira config',
    api: JiraApi.fetchJiraConfigs,
    onSuccess: () => {
      const [optionList, allConfigProjects] = getDefectProjectOptions(
        getJiraConfigs?.data,
        getJiraEntityProjects?.data
      );
      setConfigJiraProjects(allConfigProjects);
      setConfigJiraProjectOptions(optionList);
    },
    onError: (err) => showErrorMes(err)
  });

  const getJiraPriorities = useApi({
    name: 'fetch jira priorities',
    api: JiraApi.fetchJIRAPriorities,
    onSuccess: () => setJiraPriorities(getOptionListByIdName(getJiraPriorities?.data)),
    onError: (err) => showErrorMes(err)
  });

  const getJiraLinkTypes = useApi({
    name: 'fetch jira link types',
    api: JiraApi.fetchJiraLinkTypes,
    onSuccess: () => setlinkTypes(getJiraLinkTypes?.data),
    onError: (err) => showErrorMes(err)
  });

  const getJiraEntityProjects = useApi({
    name: 'fetch jira entity projects',
    api: JiraApi.fetchJIRAEntityProjects,
    onSuccess: () => {
      if (isValidList(getJiraEntityProjects?.data)) {
        getJiraConfigs.execute({ entityType, integrationConfigId: jiraConfig.id });
      }
    },
    onError: (err) => showErrorMes(err)
  });

  const searchJiraLinksByProjectConfig = useApi({
    name: 'searchJiraLinksByProjectConfig',
    api: JiraApi.searchJiraLinksByProjectConfig,
    onSuccess: () => setLinkOptions(searchJiraLinksByProjectConfig?.data),
    onError: (err) => {
      setLinkOptions([]);
      showErrorMes(err);
    }
  });

  const handleValue = (value) => setSearchKey(value);

  const searchJiraLink = (value) => {
    if (isValidValue(value) && value.length >= 5 && searchJiraLinksByProjectConfig.loading !== true) {
      searchJiraLinksByProjectConfig.execute({ projectId, searchText: value, entityType });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      debounce_fun();
    }
  };

  const debounce_fun = useCallback(
    _.debounce(() => searchJiraLink(searchKey), 750),
    [searchKey]
  );

  const issueOptions = useMemo(() => {
    return findIssueTypesInConfigByProject(configJiraProjects, dataForm?.jiraProjectId);
  }, [configJiraProjects, dataForm]);

  const selectedIssue = useMemo(() => {
    return issueOptions?.filter((issue) => issue?.key === dataForm?.jiraContent?.fields?.issuetype?.id)?.[0];
  }, [issueOptions]);

  const selectedProjectName = useMemo(() => {
    return configJiraProjectOptions?.filter((issue) => issue?.id === dataForm?.jiraContent?.fields?.project?.id)?.[0];
  }, [configJiraProjectOptions]);

  const selectedPriority = useMemo(() => {
    return jiraPriorities?.filter((priority) => priority?.key === dataForm?.jiraContent?.fields?.priority?.id)?.[0];
  }, [jiraPriorities, dataForm]);

  const selectedAssignee = useMemo(() => {
    return users?.filter((x) => x?.email === dataForm?.jiraContent?.fields?.assignee?.emailAddress)?.[0];
  }, [users, dataForm]);

  const selectedLinkType = useMemo(() => {
    let cf;
    // !todo: fix this
    if (entityType === ENTITY_TYPES.DEFECT) {
      cf = projectConfig?.configuration?.jira?.defectJiraLinkType;
    } else if (entityType === ENTITY_TYPES.REQUIREMENT) {
      cf = projectConfig?.configuration?.jira?.requirementJiraLinkType;
    } else {
      cf = '';
    }

    if (isValidValue(cf)) {
      const t = linkTypes?.filter((x) => x?.name === cf || x?.id === cf || x?.key === cf);
      if (isValidList(t)) {
        return t[0];
      }
    }

    return null;
  }, [linkTypes, dataForm]);

  useEffect(() => {
    if (searchKey?.length >= 5) debounce_fun();
    return debounce_fun.cancel;
  }, [searchKey, debounce_fun]);

  useEffect(() => {
    if (isValidValue(projectId)) {
      getJiraPriorities.execute({ projectId });
      getJiraEntityProjects.execute({ projectId, entityType });
      getJiraLinkTypes.execute({ projectId });
    }
  }, [projectId]);
  let usersOptions = translateArrItemName(users, t);
  let jiraPrioritiesOptions = translateArrItemName(jiraPriorities, t);
  let linkTypesOptions = translateArrItemName(linkTypes, t);
  useEffect(() => {
    if (isValidValue(dataForm?.jiraContent?.fields?.project?.id)) {
      setValue('jiraProjectId', dataForm?.jiraContent?.fields?.project?.id);
    }
    if (isValidValue(dataForm?.jiraContent?.fields?.issuetype?.id)) {
      setValue('jiraIssueTypeId', dataForm?.jiraContent?.fields?.issuetype?.id);
    }
    if (isValidValue(dataForm?.jiraContent?.fields?.project?.name)) {
      setValue('jiraProjectName', dataForm?.jiraContent?.fields?.project?.name);
    }
    if (isValidValue(dataForm?.jiraContent?.fields?.description)) {
      setValue('jiraDescription', dataForm?.jiraContent?.fields?.description);
    }
    if (
      isValidValue(projectConfig?.configuration?.jira?.defectJiraLinkType) &&
      entityType === ENTITY_TYPES.DEFECT &&
      showJiraLink(projectConfig, isUpdate, entityType)
    ) {
      const linkTypeFilter = linkTypes?.filter(
        (x) => x?.name === projectConfig?.configuration?.jira?.defectJiraLinkType
      )?.[0];

      if (linkTypeFilter?.id) setValue('jiraLinkTypeId', linkTypeFilter?.id);
    }
    if (
      isValidValue(projectConfig?.configuration?.jira?.requirementJiraLinkType) &&
      entityType === ENTITY_TYPES.REQUIREMENT &&
      showJiraLink(projectConfig, isUpdate, entityType)
    ) {
      const linkTypeFilter = linkTypes?.filter(
        (x) => x?.name === projectConfig?.configuration?.jira?.requirementJiraLinkType
      )?.[0];

      if (linkTypeFilter?.id) setValue('jiraLinkTypeId', linkTypeFilter?.id);
    }
    if (
      isValidValue(dataForm?.jiraContent?.fields?.assignee?.emailAddress) &&
      showJiraAssignee(projectConfig, isUpdate, entityType)
    ) {
      const userEmailFilter = users?.filter(
        (x) => x?.email === dataForm?.jiraContent?.fields?.assignee?.emailAddress
      )?.[0];
      if (userEmailFilter) setValue('jiraAssigneeId', userEmailFilter?.id);
    }
  }, [dataForm, users, linkTypes]);

  return (
    <>
      <form className="flex flex-col gap-4">
        <>
          <div className="flex gap-4">
            <Dropdown
              disabled={isValidValue(dataForm?.jiraContent?.fields?.project?.name)}
              required
              clearable
              placeholder={t('Select Jira Project')}
              control={control}
              label={t('Jira Project')}
              name="jiraProjectId"
              options={configJiraProjectOptions}
              selectedItem={selectedProjectName}
              passValue={(value) => {
                setDataForm((prev) => ({ ...prev, jiraProjectId: isValidList(value) ? value[0]?.id : null }));
                if (!isValidList(value)) {
                  setValue('jiraProjectId', null);
                }
              }}
            />
            <Dropdown
              required
              control={control}
              placeholder={t('Select Jira Type')}
              label={t('Jira Issue Type (by project) ')}
              name="jiraIssueTypeId"
              options={issueOptions}
              disabled={!dataForm.jiraProjectId || isUpdate}
              selectedItem={selectedIssue}
              passValue={(value) =>
                setDataForm((prev) => ({ ...prev, jiraIssueTypeId: isValidList(value) ? value[0]?.id : null }))
              }
            />
          </div>
          <div className="flex gap-4">
            {showJiraPriority(projectConfig, isUpdate, entityType) && (
              <Dropdown
                clearable
                placeholder={t('Select Priority')}
                control={control}
                label={t('Jira Priority')}
                name="jiraPriorityId"
                options={jiraPrioritiesOptions}
                selectedItem={selectedPriority}
                passValue={(value) => {
                  setDataForm((prev) => ({ ...prev, jiraPriorityId: value[0]?.id }));
                  if (!isValidList(value)) {
                    setValue('jiraPriorityId', null);
                  }
                }}
              />
            )}
            {showJiraAssignee(projectConfig, isUpdate, entityType) && (
              <Dropdown
                control={control}
                name="jiraAssigneeId"
                label={t('Jira Assignee')}
                clearable
                placeholder={t('Select Assignee')}
                options={usersOptions}
                selectedItem={selectedAssignee}
                passValue={(value) => {
                  setDataForm({ ...dataForm, jiraAssigneeId: value[0]?.id });
                  if (!isValidList(value)) {
                    setValue('jiraAssigneeId', null);
                  }
                }}
              />
            )}
          </div>

          {showJiraLink(projectConfig, isUpdate, entityType) && (
            <>
              <div className="flex gap-4">
                <Dropdown
                  clearable
                  control={control}
                  placeholder={t('Select Link Type')}
                  label={t('Link Type ')}
                  name="jiraLinkTypeId"
                  options={linkTypesOptions}
                  selectedItem={selectedLinkType}
                  passValue={(value) => {
                    setDataForm((prev) => ({ ...prev, jiraLinkTypeId: value[0]?.id }));
                    if (!isValidList(value)) {
                      setValue('jiraLinkTypeId', null);
                    }
                  }}
                />
                <Input
                  name="searchLink"
                  label={t('Search Link Issue')}
                  control={control}
                  placeholder={t('Enter a text (min 5 character)')}
                  passValue={(value) => handleValue(value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="flex gap-4">
                {searchJiraLinksByProjectConfig.loading ? (
                  <Loader />
                ) : (
                  <Dropdown
                    clearable
                    control={control}
                    placeholder={t('Select Link Issue')}
                    label={t('Link Issue')}
                    name="jiraNewLink"
                    options={linkOptions}
                    passValue={(value) => {
                      setDataForm((prev) => ({ ...prev, jiraNewLink: value[0]?.id }));
                      if (!isValidList(value)) {
                        setValue('jiraNewLink', null);
                      }
                    }}
                  />
                )}
              </div>
            </>
          )}
          {showJiraDescription(projectConfig, isUpdate, entityType) && (
            <>
              <TextArea
                placeholder={t('Enter your text here')}
                control={control}
                name="jiraDescription"
                label={t('Jira Description')}
              />
            </>
          )}
        </>
      </form>
    </>
  );
};
export default JiraIssueModalContent;
