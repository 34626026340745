import React, { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//Api Import
import { useApi } from '../../../hooks/UseApi';
import ProjectConfigApi from '../../../api/ProjectConfigApi';
//Hook - Context Imports
import { useAppDispatch } from '../../../context/AppContext';
import { saveProjectConfig } from '../../../context/ProjectActions';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
//Component Imports
import ToggleCard from '../../../components/card/ToggleCard';
import { SidePaneRightContent } from '../../../components/Layout';
import { Button, H5, Heading, Input, P, Toggle, Loader } from '../../../designSystem/DesignSystem';
//Helper Import
import { getRequirementName, getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import { showErrorMes, showSuccessMes, INFO_MESSAGES } from '../../../helpers/NotificationHelper';
import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { isTrue } from '../../../helpers/Helper';

const RequirementProjectSetting = () => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useForm({ shouldFocusError: true, criteriaMode: 'all' });
  const { projectId, project } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(
    projectConfig?.configuration?.requirement?.requirementNameUpdate + '' === 'true'
  );

  const title = useMemo(() => getRequirementName(projectConfig), [projectConfig]);

  const updateProjectConfig = useApi({
    name: 'Updating project config',
    api: ProjectConfigApi.updateProjectConfiguration,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      dispatch(saveProjectConfig(projectId, updateProjectConfig?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const projectConfigLoader = useApi({
    name: 'Get project configs',
    api: ProjectConfigApi.get,
    onSuccess: () => {
      dispatch(saveProjectConfig(projectId, projectConfigLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const handleSubmit = (value, name) => {
    if (projectConfig?.configuration?.requirement?.[name] == value) {
      return;
    }

    var body = { id: projectId, key: name, value };
    updateProjectConfig.execute(body);
  };

  const transitionIdFields = useMemo(
    () =>
      projectConfig?.configuration?.requirement?.assignedTestCaseControl === 'true' && (
        <div className="-ml-1 mt-4 flex items-center justify-between gap-3">
          <Input
            disabled={project?.templateBy}
            name="hasAssignedTestCaseTranstionId"
            control={control}
            label={t('jiraTransitionHas', {
              requirement: getRequirementName(projectConfig),
              testcase: getTestCaseName(projectConfig)
            })}
            passValue={(value) => setValue('hasAssignedTestCaseTranstionId', value)}
          />
          <div className="mt-6">
            <Button
              id="requirementProjectSetting-save-button"
              primary
              text={t('Save')}
              size="small"
              disabled={project?.templateBy}
              onClick={() =>
                handleSubmit(getValues('hasAssignedTestCaseTranstionId'), 'hasAssignedTestCaseTranstionId')
              }
            />
          </div>
          <Input
            disabled={project?.templateBy}
            name="hasNotAssignedTestCaseTranstionId"
            control={control}
            label={t('jiraTransitionHasnt', {
              requirement: getRequirementName(projectConfig),
              testcase: getTestCaseName(projectConfig)
            })}
            passValue={(value) => setValue('hasNotAssignedTestCaseTranstionId', value)}
          />
          <div className="mt-6">
            <Button
              id="requirementProjectSetting-save-button"
              disabled={project?.templateBy}
              primary
              text={t('Save')}
              size="small"
              onClick={() =>
                handleSubmit(getValues('hasNotAssignedTestCaseTranstionId'), 'hasNotAssignedTestCaseTranstionId')
              }
            />
          </div>
        </div>
      ),
    [projectConfig]
  );

  const testAutomationTransitionFields = useMemo(
    () =>
      projectConfig?.configuration?.requirement?.testAutomationControl === 'true' && (
        <div className="-ml-1 mt-4 flex flex-col">
          <div className="flex flex-grow items-end">
            <Input
              disabled={project?.templateBy}
              name="runTestAutomationTranstionId"
              control={control}
              label={t(`Jira transitionId to run test automation`)}
              passValue={(value) => {
                setValue('runTestAutomationTranstionId', value);
              }}
            />
            <div className="pb-2">
              <Button
                id="requirementProjectSetting-save-button"
                primary
                text={t('Save')}
                size="small"
                disabled={project?.templateBy}
                onClick={() => handleSubmit(getValues('runTestAutomationTranstionId'), 'runTestAutomationTranstionId')}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-grow items-end">
              <Input
                disabled={project?.templateBy}
                name="hasNotAssignedTestCaseWithTestAutomationTranstionId"
                control={control}
                label={t(`jiraTransitionHasn'tTestCaseWithTestAutomation`, {
                  requirement: getRequirementName(projectConfig),
                  testcase: getTestCaseName(projectConfig)
                })}
                passValue={(value) => {
                  setValue('hasNotAssignedTestCaseWithTestAutomationTranstionId', value);
                }}
              />
              <div className="pb-2">
                <Button
                  id="requirementProjectSetting-save-button"
                  primary
                  text={t('Save')}
                  size="small"
                  disabled={project?.templateBy}
                  onClick={() =>
                    handleSubmit(
                      getValues('hasNotAssignedTestCaseWithTestAutomationTranstionId'),
                      'hasNotAssignedTestCaseWithTestAutomationTranstionId'
                    )
                  }
                />
              </div>
            </div>
            <div className="flex flex-grow items-end">
              <Input
                disabled={project?.templateBy}
                name="hasNotAssignedTestCaseWithoutTestAutomationTranstionId"
                control={control}
                label={t(`jiraTransitionHasn'tTestCaseWithoutTestAutomation`, {
                  requirement: getRequirementName(projectConfig),
                  testcase: getTestCaseName(projectConfig)
                })}
                passValue={(value) => {
                  setValue('hasNotAssignedTestCaseWithoutTestAutomationTranstionId', value);
                }}
              />
              <div className="pb-2">
                <Button
                  id="requirementProjectSetting-save-button"
                  primary
                  text={t('Save')}
                  size="small"
                  disabled={project?.templateBy}
                  onClick={() =>
                    handleSubmit(
                      getValues('hasNotAssignedTestCaseWithoutTestAutomationTranstionId'),
                      'hasNotAssignedTestCaseWithoutTestAutomationTranstionId'
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-grow items-end">
              <Input
                disabled={project?.templateBy}
                name="hasAssignedTestCaseWithTestAutomationTranstionId"
                control={control}
                label={t(`jiraTransitionHasTestCaseWithTestAutomation`, {
                  requirement: getRequirementName(projectConfig),
                  testcase: getTestCaseName(projectConfig)
                })}
                passValue={(value) => {
                  setValue('hasAssignedTestCaseWithTestAutomationTranstionId', value);
                }}
              />
              <div className="pb-2">
                <Button
                  id="requirementProjectSetting-save-button"
                  primary
                  text={t('Save')}
                  size="small"
                  disabled={project?.templateBy}
                  onClick={() =>
                    handleSubmit(
                      getValues('hasAssignedTestCaseWithTestAutomationTranstionId'),
                      'hasAssignedTestCaseWithTestAutomationTranstionId'
                    )
                  }
                />
              </div>
            </div>
            <div className="flex flex-grow items-end">
              <Input
                disabled={project?.templateBy}
                name="hasAssignedTestCaseWithoutTestAutomationTranstionId"
                control={control}
                label={t(`jiraTransitionHasTestCaseWithoutTestAutomation`, {
                  requirement: getRequirementName(projectConfig),
                  testcase: getTestCaseName(projectConfig)
                })}
                passValue={(value) => {
                  setValue('hasAssignedTestCaseWithoutTestAutomationTranstionId', value);
                }}
              />
              <div className="pb-2">
                <Button
                  id="requirementProjectSetting-save-button"
                  primary
                  text={t('Save')}
                  size="small"
                  disabled={project?.templateBy}
                  onClick={() =>
                    handleSubmit(
                      getValues('hasAssignedTestCaseWithoutTestAutomationTranstionId'),
                      'hasAssignedTestCaseWithoutTestAutomationTranstionId'
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ),
    [projectConfig]
  );

  useEffect(() => {
    if (projectId) {
      projectConfigLoader.execute({ id: projectId });
    }
  }, [projectId]);

  useEffect(() => {
    setValue(
      'hasAssignedTestCaseTranstionId',
      projectConfig?.configuration?.requirement?.hasAssignedTestCaseTranstionId
    );
    setValue(
      'hasNotAssignedTestCaseTranstionId',
      projectConfig?.configuration?.requirement?.hasNotAssignedTestCaseTranstionId
    );
    setValue('runTestAutomationTranstionId', projectConfig?.configuration?.requirement?.runTestAutomationTranstionId);
    setValue(
      'hasAssignedTestCaseWithTestAutomationTranstionId',
      projectConfig?.configuration?.requirement?.hasAssignedTestCaseWithTestAutomationTranstionId
    );
    setValue(
      'hasAssignedTestCaseWithoutTestAutomationTranstionId',
      projectConfig?.configuration?.requirement?.hasAssignedTestCaseWithoutTestAutomationTranstionId
    );
    setValue(
      'hasNotAssignedTestCaseTranstionId',
      projectConfig?.configuration?.requirement?.hasNotAssignedTestCaseTranstionId
    );
    setValue(
      'hasNotAssignedTestCaseWithTestAutomationTranstionId',
      projectConfig?.configuration?.requirement?.hasNotAssignedTestCaseWithTestAutomationTranstionId
    );
    setValue(
      'hasNotAssignedTestCaseWithoutTestAutomationTranstionId',
      projectConfig?.configuration?.requirement?.hasNotAssignedTestCaseWithoutTestAutomationTranstionId
    );
  }, [projectConfig]);

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading
          type="large"
          text={title}
        />
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Main>
        <SidePaneRightContent.Center colFull={true}>
          {projectConfigLoader?.loading ? (
            <Loader></Loader>
          ) : (
            isProjectManager(projectId) && (
              <>
                <div className="mt-4">
                  <ToggleCard
                    name="requirementNameUpdate"
                    disabled={project?.templateBy}
                    title={t('requirementNameUpdate_entity', { entity: t(title) })}
                    labelText={t('requirementProjectSetting_entity', { entity: t(title) })}
                    checked={checked}
                    submitCallBack={(value) => {
                      handleSubmit(value, 'requirementNameUpdate');
                      setChecked(value);
                    }}
                  />
                </div>

                {projectConfig?.configuration?.jira?.updateJiraRequirement === 'true' && (
                  <>
                    <div className="mt-4  flex-row  rounded-lg border border-gray-100 bg-white p-6 shadow-lg">
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          <H5
                            text={t('Test Automation Control')}
                            className={'mb-2 font-semibold'}
                          />
                          <P
                            text={t(
                              'If the transition process in the jira is different according to the test automation status, it should be activated.'
                            )}
                          />
                        </div>
                        <Toggle
                          control={control}
                          value={isTrue(projectConfig?.configuration?.requirement?.testAutomationControl)}
                          name="testAutomationControl"
                          passValue={(value) => {
                            handleSubmit(value, 'testAutomationControl');
                          }}
                        />
                      </div>
                      {testAutomationTransitionFields}
                    </div>
                    <div className="mt-4  flex-row  rounded-lg border border-gray-100 bg-white p-6 shadow-lg">
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          <H5
                            text={t('assignedControl', { entity: getTestCaseName(projectConfig) })}
                            className={'mb-2 font-semibold'}
                          />
                          <P
                            text={t('assignedControlText', {
                              requirement: getRequirementName(projectConfig),
                              testCase: getTestCaseName(projectConfig)
                            })}
                          />
                        </div>
                        <Toggle
                          control={control}
                          value={isTrue(projectConfig?.configuration?.requirement?.assignedTestCaseControl)}
                          name="assignedTestCaseControl"
                          passValue={(value) => {
                            handleSubmit(value, 'assignedTestCaseControl');
                          }}
                        />
                      </div>
                      {transitionIdFields}
                    </div>
                  </>
                )}
              </>
            )
          )}
        </SidePaneRightContent.Center>
      </SidePaneRightContent.Main>
    </>
  );
};

export default RequirementProjectSetting;
