import React, { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useForm } from 'react-hook-form';
import ProjectApi from '../../../../api/ProjectApi';
import TokenApi from '../../../../api/TokenApi';
// Design System Imports
import { Input, Button, Dropdown } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
//Helper Imports
import { editModalMapping, getUserOptionsWithGroups, isValidValue } from '../../../../helpers/Helper';
import { showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
// Api and Hook Imports
import { useApi } from '../../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import { titleTranslation } from '../../../../helpers/TranslationHelper';
const TokenModal = ({ isOpen, data, closeModal, users = [] }) => {
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const [dataForm, setDataForm] = useState(data ? data : '');
  const { t } = useTranslation();

  const createEditToken = useApi({
    name: 'saving token',
    api: isValidValue(data) ? TokenApi.edit : TokenApi.create,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes('Token saved successfully');
      setDataForm(createEditToken.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const projectLoader = useApi({
    name: 'fetching projects',
    api: ProjectApi.getAllByUser,
    autoLoad: false,
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    const body = {
      ...(isValidValue(data) && { id: data.id }),
      name: form.name,
      projectId: form.projectId,
      tokenOwner: form.tokenOwner
    };
    createEditToken.execute(body);
  };

  useEffect(() => {
    if (isValidValue(dataForm)) {
      let mappedArray = editModalMapping(dataForm);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, [dataForm]);

  useEffect(() => {
    if (isValidValue(dataForm?.tokenOwner)) {
      projectLoader.execute({ userId: dataForm?.tokenOwner });
    }
  }, [dataForm?.tokenOwner]);

  const userOptions = useMemo(() => getUserOptionsWithGroups(users), [users]);

  const projectOptions = useMemo(
    () => (projectLoader.data || []).map(({ id, name }) => ({ id, value: id, name, text: name })),
    [projectLoader.data]
  );

  return (
    <Modal
      open={isOpen}
      close={() => closeModal}
      w={'md'}
    >
      <Modal.Title>{titleTranslation('Token', data, '', t)}</Modal.Title>
      <form
        className="flex flex-grow flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Content h={'md'}>
          <div
            style={{ minHeight: '350px' }}
            className="flex flex-grow flex-col gap-5"
          >
            <div className="flex gap-4">
              <Input
                required={true}
                placeholder={t('API Token Name')}
                control={control}
                name="name"
                label={t('API Token Name')}
              />
            </div>
            <div className="flex gap-4">
              <Dropdown
                control={control}
                required
                name="tokenOwner"
                label={t('Token Owner')}
                placeholder={t('Select Token Owner')}
                options={userOptions}
                selectedItem={{ name: userOptions.find(({ id }) => id === dataForm?.tokenOwner)?.name }}
                passValue={(value) =>
                  setDataForm((prev) => ({
                    ...prev,
                    tokenOwner: value[0]?.id,
                    ...(prev?.tokenOwner !== value[0]?.id && { projectId: null })
                  }))
                }
                disabled={dataForm?.id}
              />
            </div>
            <div className="flex gap-4">
              <Dropdown
                control={control}
                required
                name="projectId"
                label={t('Project')}
                placeholder={t('Select Project')}
                options={projectOptions}
                selectedItem={{ name: projectOptions.find(({ value }) => value === dataForm?.projectId)?.name }}
                passValue={(value) => setDataForm((prev) => ({ ...prev, projectId: value[0]?.id }))}
                disabled={dataForm?.id || !dataForm?.tokenOwner}
              />
            </div>
            {dataForm?.token && (
              <div className="mb-2 flex items-center justify-between">
                <Input
                  control={control}
                  name="token"
                  label={t('Token')}
                  disabled={true}
                />
                <div className="mb-1 mr-2 flex self-end">
                  <CopyToClipboard text={dataForm?.token}>
                    <Button
                      id="tokenModal-copy-button"
                      secondary
                      type="button"
                      icon="Clipboard"
                      size="small"
                      weight={'bold'}
                    />
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="tokenMoadl-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={() => {
              closeModal(createEditToken?.data);
              reset();
            }}
          />
          <Button
            id="tokenModal-submit-button"
            primary
            text={t('Submit')}
            type="submit"
            loading={createEditToken?.loading}
          />
        </Modal.Buttons>
      </form>
    </Modal>
  );
};

export default TokenModal;
