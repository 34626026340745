import {
  cycleUserDefectCountDefaultReportColumns,
  suiteTestCaseExecutionCountDefaultReportColumns
} from './DefaultReportTableColumns';

export const DEFAULT_REPORT_LIST = [
  {
    id: '1',
    name: 'cycleUserDefectCount',
    api: 'cycleUserDefectCount',
    columns: cycleUserDefectCountDefaultReportColumns
  },
  {
    id: '2',
    name: 'suiteTestCaseExecutionCount',
    api: 'suiteTestCaseExecutionCount',
    columns: suiteTestCaseExecutionCountDefaultReportColumns
  }
];
