import { ENTITY_TYPES, PRIVILEGES } from '../../constants';
import { getRequirementName, getRunName } from '../../helpers/ArtifactNameHelper';
import { hasPrivilege, isSystemAdmin } from '../../helpers/PriviligeHelper';

export const getPrivilegeOptions = (
  projectId,
  entityType,
  t,
  projectConfig,
  hasURLIncludeTestCase,
  hasURLIncludeRequirement,
  hasURLIncludeContainer,
  hasURLIncludeVersion
) => [
  {
    key: 'send to approve',
    value: 'sendToApprove',
    text: t('Send to Approve'),
    name: 'SendToApprove',
    check: () =>
      !hasPrivilege(projectId, PRIVILEGES.APPROVE_TEST_CASE) &&
      hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CASE) &&
      entityType === ENTITY_TYPES.TEST_CASE
  },
  {
    key: 'approve',
    value: 'approve',
    text: t('Approve'),
    name: 'Approve',
    check: () =>
      hasPrivilege(projectId, PRIVILEGES.APPROVE_TEST_CASE) &&
      hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CASE) &&
      entityType === ENTITY_TYPES.TEST_CASE
  },
  {
    key: 'execute',
    value: 'execute',
    text: t('Execute'),
    name: 'Execute',
    check: () => hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_EXECUTION) && entityType === ENTITY_TYPES.TEST_RUN
  },
  {
    key: 'defectBulkDelete',
    value: 'defectBulkDelete',
    text: t('Move To Trash'),
    name: 'defectBulkDelete',
    check: () => hasPrivilege(projectId, PRIVILEGES.DELETE_DEFECT) && entityType === ENTITY_TYPES.DEFECT
  },
  {
    key: 'defectAssign',
    value: 'defectAssign',
    text: t('Assign'),
    name: 'defectAssign',
    check: () =>
      hasPrivilege(projectId, PRIVILEGES.ASSIGN_DEFECT) &&
      hasPrivilege(projectId, PRIVILEGES.UPDATE_DEFECT) &&
      entityType === ENTITY_TYPES.DEFECT
  },
  {
    key: 'clone',
    value: 'clone',
    text: t('Clone'),
    name: 'Clone',
    check: () => hasPrivilege(projectId, PRIVILEGES.CLONE_TEST_CASE) && entityType === ENTITY_TYPES.TEST_CASE
  },
  {
    key: 'delete',
    value: 'delete',
    text: t('Move To Trash'),
    name: 'delete',
    check: () =>
      hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_CASE) &&
      entityType === ENTITY_TYPES.TEST_CASE &&
      !hasURLIncludeRequirement
  },
  {
    key: 'testRun',
    value: 'testRun',
    text: t('createTestRun', { entity: getRunName(projectConfig) }),
    name: 'testRun',
    check: () =>
      hasPrivilege(projectId, PRIVILEGES.CREATE_UPDATE_TEST_RUN) &&
      (entityType === ENTITY_TYPES.REQUIREMENT || entityType === ENTITY_TYPES.TEST_CASE)
  },
  {
    key: 'bulkUnassignTestCasesFromRequirement',
    value: 'bulkUnassignTestCasesFromRequirement',
    text: t('Unassign'),
    name: 'bulkUnassignTestCasesFromRequirement',
    check: () =>
      hasPrivilege(projectId, PRIVILEGES.UNASSIGN_REQUIREMENT_TESTCASE) &&
      entityType === ENTITY_TYPES.TEST_CASE &&
      ((hasURLIncludeContainer && !hasURLIncludeVersion) || hasURLIncludeRequirement)
  },
  {
    key: 'unassign',
    value: 'unassign',
    text: t('Unassign'),
    name: 'unassign',
    check: () =>
      hasPrivilege(projectId, PRIVILEGES.UNASSIGN_TESTCASE_REQUIREMENT) &&
      entityType === ENTITY_TYPES.REQUIREMENT &&
      hasURLIncludeTestCase
  },
  {
    key: 'sync related projects',
    value: 'syncRelatedProjects',
    text: t('Sync Projects'),
    name: 'SyncRelatedProjects',
    check: () => isSystemAdmin() && entityType === ENTITY_TYPES.PROJECT
  },
  {
    key: 'bulkAssignTestCasesFromRequirements',
    value: 'bulkAssignTestCasesFromRequirements',
    text: t('assign_entity', { entity: getRequirementName(projectConfig) }),
    name: 'bulkAssignTestCasesFromRequirements',
    check: () =>
      hasPrivilege(projectId, PRIVILEGES.ASSIGN_TESTCASE_REQUIREMENT) &&
      entityType === ENTITY_TYPES.TEST_CASE &&
      !hasURLIncludeRequirement
  },
  {
    key: 'bulkRunDelete',
    value: 'bulkRunDelete',
    text: t('Move To Trash'),
    name: 'bulkRunDelete',
    check: () =>
      (hasPrivilege(projectId, PRIVILEGES.DELETE_TEST_RUN) || isSystemAdmin()) && entityType === ENTITY_TYPES.TEST_RUN
  }
];
