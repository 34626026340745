import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
//Api and Hook Imports
import CustomFieldApi from '../../../api/CustomFieldApi';
import { useApi } from '../../../hooks/UseApi';
// Design System Imports
import { Input, Toggle, Dropdown, Body, H5, Heading, Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import CustomFieldRule from './CustomFieldRule';
import CustomFieldDefaultValue from './CustomFieldDefaltValue';
import CustomFieldOptionList from './CustomFieldOptionList';
import { isValidValue, editModalMapping, isValidList } from '../../../helpers/Helper';
import { CUSTOM_FIELD_TYPES } from '../../../constants';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { customJiraActive } from '../../../helpers/CustomFieldHelper';
import { translateArrItemName } from '../../../helpers/TranslationHelper';
import { useTranslation } from 'react-i18next';

const CustomFieldBaseModal = (props) => {
  const {
    isOpen,
    data,
    closeModal,
    project,
    customFieldTypes,
    relatedEntity,
    entitySystemFields,
    entityCustomFields,
    jiraConfig,
    userList,
    isViewMode
  } = props;
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const [customFieldForm, setCustomFieldForm] = useState(data ? data : '');
  const [ruleOptions, setRuleOptions] = useState([]);
  const [customFieldItems, setCustomFieldItems] = useState([]);
  const [ruleVisible, setRuleVisible] = useState(false);
  const [configValue, setConfigValue] = useState({});
  const [optionList, setOptionList] = useState([]);

  const { t } = useTranslation();
  const customOptions = translateArrItemName(customFieldTypes, t);

  const createEditCustomField = useApi({
    name: 'saving custom field',
    api: isValidValue(data) ? CustomFieldApi.edit : CustomFieldApi.create,
    onSuccess: () => {
      closeModal(createEditCustomField?.data);
      setInitialValue();
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    setRuleVisible(false);
    let config = {};
    if (isValidValue(configValue)) {
      config = configValue;
    }
    let ruleConfig = { rules: ruleOptions };
    if (
      customFieldForm?.customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN ||
      customFieldForm?.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST ||
      customFieldForm?.customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX
    ) {
      let configList = [...optionList];
      configList = configList.filter((field) => field.value !== '');
      config = { objects: configList };
    }

    let body = {
      ...(isValidValue(data) && { id: data?.customFieldId }),
      customFieldEnumId: customFieldForm?.customFieldEnumId,
      customFieldId: form?.customFieldId,
      fieldName: form?.fieldName,
      required: form?.required,
      visible: form?.visible,
      place: form?.place,
      relatedEntity,
      jiraId: customFieldForm?.jiraStatusTransition === true ? '' : form?.jiraId,
      jiraStatusTransition: customFieldForm?.jiraStatusTransition,
      jiraCascadingField: customFieldForm?.jiraCascadingField,
      jiraCascadingFieldOption: customFieldForm?.jiraCascadingFieldOption,
      jiraText: form?.jiraText,
      emailIntegration: form?.emailIntegration,
      ...(isValidValue(data) && { templateBy: data?.templateBy }),
      projectId: project?.id,
      ruleConfig,
      config
    };

    createEditCustomField.execute(body);
  };

  const addRule = () => {
    let newList = [
      {
        type: '',
        id: '',
        value: '',
        ruleId: uuidv4()
      }
    ];

    if (ruleOptions && ruleOptions.length > 0) {
      newList = ruleOptions.concat({
        type: '',
        id: '',
        value: '',
        ruleId: uuidv4()
      });
    }
    setRuleOptions(newList);
    setRuleVisible(true);
  };

  const onDeleteRule = (item) => {
    let newList = ruleOptions && ruleOptions.length > 0 ? [...ruleOptions] : [];

    if (newList && newList.length > 0) {
      newList = newList.filter((field) => field.ruleId !== item.ruleId);
    }
    newList.length === 0 ? setRuleVisible(false) : setRuleVisible(true);
    setRuleOptions(newList);
  };

  const setRules = (ruleOption) => {
    let newRule = ruleOptions.filter((rule) => rule.ruleId !== ruleOption.ruleId);
    newRule.push(ruleOption);
    setRuleOptions(newRule);
    ruleOptionsNullValueChecked(newRule);
  };

  const fillCustomFieldOptionList = () => {
    if (entityCustomFields && entityCustomFields.length > 0) {
      let customFieldOptions = [];
      entityCustomFields.map((item) => {
        if (
          item?.customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX ||
          item?.customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN ||
          item?.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST
        ) {
          if ((data && data?.customFieldId !== item?.customFieldId) || !data) {
            if (item?.visible === true) {
              customFieldOptions.push({
                id: item.customFieldId,
                key: item.customFieldId,
                value: item.customFieldId,
                text: item.fieldName,
                name: item.fieldName,
                visible: item?.visible,
                values: item?.config?.objects.map((object) => {
                  if (object?.visible === true) {
                    return {
                      id: object.id,
                      key: object.id,
                      value: object.id,
                      text: object.value,
                      name: object.value,
                      visible: object?.visible
                    };
                  }
                })
              });
            }
          }
        }
      });
      setCustomFieldItems(customFieldOptions);
    } else {
      setCustomFieldItems([]);
    }
  };

  const setInitialValue = () => {
    setCustomFieldForm('');
    setRuleOptions([]);
    setCustomFieldItems([]);
    setRuleVisible(false);
    setConfigValue({});
    setOptionList([]);
    reset();
  };

  const ruleOptionsNullValueChecked = (ruleOptions) => {
    let valueIsEmpty = false;
    ruleOptions.forEach((item) => {
      if (!isValidValue(item?.value)) valueIsEmpty = true;
    });
    return valueIsEmpty;
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setCustomFieldForm(data);
    }

    let rules = [];
    if (data?.ruleConfig?.rules && data?.ruleConfig?.rules?.length > 0) {
      data.ruleConfig.rules.map((rule) => {
        if (rule?.type === 'systemField') {
          rules.push({ ...rule, name: t('System Field') });
        } else if (rule?.type === 'customField') {
          rules.push({ ...rule, name: t('Custom Field') });
        }
      });
      setRuleVisible(true);
    }
    setRuleOptions(rules);
    fillCustomFieldOptionList();

    switch (data?.customFieldEnumId) {
      case CUSTOM_FIELD_TYPES.DATE:
        // setConfigTextValue({ value });
        break;
      case CUSTOM_FIELD_TYPES.USER:
        setConfigValue({
          value: data?.config?.value,
          autoUserAssignEnabled: data?.config?.autoUserAssignEnabled || false
        });
        break;
      case CUSTOM_FIELD_TYPES.MULTI_LIST_USERS:
        setConfigValue({ objects: data?.config?.objects ? data.config.objects : [] });
        break;
      case CUSTOM_FIELD_TYPES.MEMO:
        setConfigValue(data?.config);
        break;
      case CUSTOM_FIELD_TYPES.NUMBER:
        setConfigValue(data?.config);
        break;
      case CUSTOM_FIELD_TYPES.TEXT:
        setConfigValue(data?.config);
        break;
      case CUSTOM_FIELD_TYPES.DROP_DOWN:
        setOptionList(data?.config?.objects);
        break;
      case CUSTOM_FIELD_TYPES.CHECKBOX:
        setOptionList(data?.config?.objects);
        break;
      case CUSTOM_FIELD_TYPES.LIST:
        setOptionList(data?.config?.objects);
        break;
      default:
        setConfigValue(data?.config);
    }
  }, [data]);

  useEffect(() => {
    if (!isValidValue(data)) {
      setCustomFieldForm({ required: false, visible: true });
      setValue('required', false);
      setValue('visible', true);
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
        w="lg"
      >
        <Modal.Title>{t('Custom Field')}</Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-8">
              <div className="flex justify-between gap-4">
                <Input
                  required
                  disabled={isViewMode}
                  placeholder={t('Custom Field Display Name')}
                  control={control}
                  name="fieldName"
                  label={t('Display Name')}
                />
              </div>
              <div className="grid grid-cols-2">
                <Dropdown
                  required
                  clearable
                  disabled={isViewMode || isValidValue(data)}
                  control={control}
                  name={'customFieldEnumId'}
                  placeholder={t('Select Custom Field Type')}
                  label={t('Custom Field Type')}
                  options={customOptions}
                  selectedItem={customOptions.filter((cs) => cs.key === customFieldForm.customFieldEnumId)[0]}
                  passValue={(value) => {
                    setCustomFieldForm((prev) => ({
                      ...prev,
                      customFieldEnumId: isValidList(value) ? value[0]?.id : null
                    }));
                    if (!isValidList(value)) {
                      setValue('customFieldEnumId', null);
                    }
                  }}
                />
                <Input
                  disabled={isViewMode}
                  name={'place'}
                  label={t('Order')}
                  control={control}
                  type={'number'}
                />
              </div>
              <div className="grid grid-cols-2">
                <Toggle
                  disabled={isViewMode}
                  control={control}
                  value={customFieldForm?.visible}
                  name="visible"
                  labelLeft
                  labelText={t('Visible')}
                  passValue={(value) => {
                    setCustomFieldForm({ ...customFieldForm, visible: value });
                    setValue('visible', value);
                  }}
                />
                <Toggle
                  disabled={isViewMode}
                  control={control}
                  value={customFieldForm.required}
                  name="required"
                  labelLeft
                  labelText={t('Required')}
                  passValue={(value) => {
                    let csObject = { ...customFieldForm, required: value };
                    setValue('required', value);
                    if (value) {
                      setValue('visible', true);
                      csObject = { ...csObject, visible: true };
                    }
                    setCustomFieldForm({ ...csObject });
                  }}
                />
              </div>

              {!isViewMode && (
                <div className="flex items-center justify-between pt-2">
                  <Heading.Group
                    text={t('If you want to add a cascading selection field, you must click the add rule button.')}
                    type="small"
                    color="text-gray-800"
                  ></Heading.Group>
                  <Button
                    id="customFieldBaseModal-add-button"
                    secondary
                    text={t('Rule')}
                    type="button"
                    size="small"
                    icon="Plus"
                    disabled={ruleOptionsNullValueChecked(ruleOptions)}
                    onClick={() => addRule()}
                  />
                </div>
              )}
              {ruleVisible && (
                <table style={{ marginTop: 15 }}>
                  <thead className="border-b-2 border-gray-300 ">
                    <tr className="flex flex-grow">
                      <div className="flex-grow">
                        <th>
                          <H5 text={t('Rule Type')} />
                        </th>{' '}
                      </div>
                      <th className="w-1/4 text-left">
                        <H5 text={t('Rule Option')} />
                      </th>
                      <th className="w-1/4 text-left">
                        <H5 text={t('Value')} />
                      </th>
                      <th className="w-1/12	"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {ruleOptions.map((rule, index) => {
                      return (
                        <tr
                          key={index}
                          className="flex flex-grow"
                        >
                          <CustomFieldRule
                            isViewMode={isViewMode}
                            rule={rule}
                            relatedEntity={relatedEntity}
                            customFieldItems={customFieldItems}
                            entitySystemFields={entitySystemFields}
                            onDeleteRule={onDeleteRule}
                            setRuleOptions={setRuleOptions}
                            submitCallBack={(form) => {
                              setRules(form);
                            }}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {customJiraActive(relatedEntity, jiraConfig) === true && (
                <>
                  <Body
                    text={t('Custom Jira Configuration')}
                    type="medium"
                    bold
                  />
                  {(customFieldForm?.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST ||
                    customFieldForm?.customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN) && (
                    <div className="flex items-end justify-between gap-4">
                      {customFieldForm?.jiraStatusTransition === false && (
                        <Input
                          disabled={isViewMode}
                          placeholder={t('Jira ID')}
                          control={control}
                          name="jiraId"
                          label={t('Jira ID')}
                        />
                      )}
                      <Toggle
                        disabled={isViewMode}
                        control={control}
                        value={customFieldForm?.jiraStatusTransition}
                        name="jiraStatusTransition"
                        labelLeft
                        labelText={t(
                          'If config is Jira Status, set active and enter transition id(s) into option list'
                        )}
                        passValue={(value) => {
                          setCustomFieldForm({ ...customFieldForm, jiraStatusTransition: value });
                        }}
                      />
                    </div>
                  )}

                  {(customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.USER ||
                    customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.MULTI_LIST_USERS) &&
                    customFieldForm.customFieldEnumId !== CUSTOM_FIELD_TYPES.MEMO && (
                      <Toggle
                        disabled={isViewMode}
                        control={control}
                        value={customFieldForm?.emailIntegration}
                        name="emailIntegration"
                        labelLeft
                        labelText={t('Send notification (email) to user(s) when entity detail was updated')}
                      />
                    )}

                  {customFieldForm.customFieldEnumId !== CUSTOM_FIELD_TYPES.TEXT &&
                    customFieldForm.customFieldEnumId !== CUSTOM_FIELD_TYPES.MEMO && (
                      <Toggle
                        disabled={isViewMode}
                        control={control}
                        value={customFieldForm?.jiraText}
                        name="jiraText"
                        labelLeft
                        labelText={t(
                          'Custom configuration. If jira accepts value as text, we will send text value for field'
                        )}
                      />
                    )}
                  {customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST && (
                    <Toggle
                      disabled={isViewMode}
                      control={control}
                      value={customFieldForm?.jiraCascadingField}
                      name="jiraCascadingField"
                      labelLeft
                      labelText={t('Is field Jira Cascading field?')}
                      passValue={(value) => {
                        if (value === true) {
                          setValue('jiraCascadingField', value);
                          setCustomFieldForm({
                            ...customFieldForm,
                            jiraCascadingFieldOption: false,
                            jiraCascadingField: true
                          });
                        } else setCustomFieldForm({ ...customFieldForm, jiraCascadingField: false });
                      }}
                    />
                  )}

                  {customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST && (
                    <Toggle
                      disabled={isViewMode}
                      control={control}
                      value={customFieldForm?.jiraCascadingFieldOption}
                      name="jiraCascadingFieldOption"
                      labelLeft
                      labelText={t('Is field Jira Cascading field option?')}
                      passValue={(value) => {
                        if (value === true) {
                          setValue('jiraCascadingFieldOption', value);
                          setCustomFieldForm({
                            ...customFieldForm,
                            jiraCascadingField: false,
                            jiraCascadingFieldOption: true
                          });
                        } else setCustomFieldForm({ ...customFieldForm, jiraCascadingFieldOption: false });
                      }}
                    />
                  )}
                </>
              )}
              <CustomFieldDefaultValue
                isViewMode={isViewMode}
                customFieldForm={customFieldForm}
                userList={userList}
                configValue={configValue}
                setConfigValue={setConfigValue}
              />
              {(customFieldForm?.customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN ||
                customFieldForm?.customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX ||
                customFieldForm?.customFieldEnumId === CUSTOM_FIELD_TYPES.LIST) && (
                <CustomFieldOptionList
                  isViewMode={isViewMode}
                  relatedEntity={relatedEntity}
                  jiraConfig={jiraConfig}
                  jiraStatus={data?.jiraStatusTransition}
                  customFieldEnumId={customFieldForm?.customFieldEnumId}
                  optionList={optionList}
                  setOptionList={setOptionList}
                />
              )}
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="customFieldBaseModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
                setInitialValue();
              }}
            />
            <Button
              id="customFieldBaseModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              disabled={isViewMode || ruleOptionsNullValueChecked(ruleOptions)}
              loading={createEditCustomField?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};
export default CustomFieldBaseModal;
