import React, { useEffect, useRef } from 'react';
// import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import SystemFieldApi from '../../../api/SystemFieldApi';
import { useApi } from '../../../hooks/UseApi';

// Design System Imports
import { Button, Heading, Input } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import { isValidValue, editModalMapping } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
//Translation
import { useTranslation } from 'react-i18next';

const SystemFieldEditModal = (props) => {
  const { data, closeModal, isOpen } = props;
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const { t } = useTranslation();

  const updateSystemFieldOption = useApi({
    name: 'update system field option',
    api: SystemFieldApi.edit,
    onSuccess: () => {
      closeModal(updateSystemFieldOption?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    const body = {
      ...data,
      name: form.name
    };
    updateSystemFieldOption.execute(body);
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>
          <Heading
            type="medium"
            text={t('Edit System Field Option')}
          />
        </Modal.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content h={'md'}>
            <Input
              name="name"
              label={t('Name')}
              placeholder={t(`Enter a Name`)}
              control={control}
            />
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="systemFieldEditModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={updateSystemFieldOption?.loading}
            />
            <Button
              id="systemFieldEditModal-cancel-button"
              text={t('Cancel')}
              secondary
              onClick={() => {
                closeModal();
                reset();
              }}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};
export default SystemFieldEditModal;
