import * as constants from '../constants';
//
export const getSystemFieldOptions = (entityEnums, keyIsName) => {
  if (entityEnums === undefined || entityEnums === null || entityEnums.length === 0) return [];
  //
  const options = entityEnums.map((item) => ({
    key: keyIsName === true ? item.name : item.id,
    text: item.name,
    value: keyIsName === true ? item.name : item.id,
    default: item?.isDefault === true ? true : false,
    disabled: item?.visible === true ? false : true,
    nextNodes: item?.nextNodes.length > 0 ? item?.nextNodes : [],
    isFirstStatus: item?.isFirstStatus,
    isLastStatus: item?.isLastStatus,
    name: item.name
  }));
  return options;
};
//
export default function EnumHelper(constType, enumValue) {
  if (enumValue) {
    let relatedEnumValue;
    if (constType === 'RunExecutionStatus') relatedEnumValue = constants[constType][enumValue];
    else relatedEnumValue = constants[constType].find((x) => x.value === enumValue);
    if (relatedEnumValue) return relatedEnumValue.text;
  }
  return '';
}
