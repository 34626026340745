import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// API & HOOKS
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';
// DESIGN SYSTEM & COMPONENTS
import ReleaseDashboard from './ReleaseDashboard';
import ReleaseDetails from './ReleaseDetails';
import { Loader } from '../../designSystem/DesignSystem';
import PageLeftTreeButton from '../../components/treeView/PageLeftTreeButton';
import { SidePaneLeftContent, SidePaneRightContent } from '../../components/Layout';
import AlertModal from '../../components/alert/AlertModal';

// HELPERS & CONSTANTS
import { ENTITY_TYPES, TREENODE_ROOT } from '../../constants';
import { getNewUrlPath, isValidValue } from '../../helpers/Helper';
import { isViewReleasePrivileged } from '../../helpers/PriviligeHelper';
import TreeArborist from '../../components/tree/TreeArborist';
import * as TreeEvents from '../../components/tree/TreeEvents';
import { EVENT_TYPES } from '../../components/tree/TreeEvents';

const ReleasePage = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);

  const { pathname: path } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  let [searchParams] = useSearchParams();

  const id = useMemo(() => searchParams.get('id'), [searchParams]);

  const selectedItem = { id: id, type: ENTITY_TYPES.RELEASE };

  const renderSwitch = useCallback(() => {
    if (id && TREENODE_ROOT.id !== id) {
      return (
        <ReleaseDetails
          selectedItem={{ id }}
          submitCallBack={(item) => TreeEvents.dispatch(ENTITY_TYPES.RELEASE, EVENT_TYPES.UPDATED, item)}
          submitCallBackAfterDeletion={(item) => TreeEvents.dispatch(ENTITY_TYPES.RELEASE, EVENT_TYPES.DELETED, item)}
        />
      );
    } else return <ReleaseDashboard />;
  }, [id]);

  return (
    <>
      {!isViewReleasePrivileged(projectId) && (
        <AlertModal
          header={t('Access Denied')}
          content={t(`You have no permission to access this page.`)}
        />
      )}
      {projectId && (
        <>
          <SidePaneLeftContent>
            <PageLeftTreeButton
              pageKey={ENTITY_TYPES.RELEASE}
              submitCallBack={(form) =>
                TreeEvents.dispatch(ENTITY_TYPES.RELEASE, EVENT_TYPES.CREATED, {
                  ...form,
                  type: ENTITY_TYPES.RELEASE
                })
              }
              projectConfig={projectConfig}
            />
            <SidePaneLeftContent.Treeview>
              {!isValidValue(projectConfig?.projectId) ? (
                <Loader />
              ) : (
                <TreeArborist
                  module={ENTITY_TYPES.RELEASE}
                  projectId={projectId}
                  projectConfig={projectConfig}
                  onSelect={(node) => navigate(getNewUrlPath(projectId, path, node))}
                  params={selectedItem}
                />
              )}
            </SidePaneLeftContent.Treeview>
          </SidePaneLeftContent>
          <SidePaneRightContent>{renderSwitch()}</SidePaneRightContent>
        </>
      )}
    </>
  );
};

export default ReleasePage;
