import { axios } from './ApiClient';

export const SF_BASE = '/api/system-fields';

class SystemFieldApi {
  async getSystemFields(data) {
    const resp = await axios.get(SF_BASE, { params: data });
    return resp.data;
  }

  async fetchSystemFields(data) {
    const resp = await axios.get(`${SF_BASE}/fields`, { params: data });
    return resp.data;
  }

  async fetchAllSystemFields(data) {
    const resp = await axios.get(`${SF_BASE}/all`, { params: data });
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(SF_BASE, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${SF_BASE}/${data.id}`);
    return resp.data;
  }

  //Use Project-Status System Field
  async fetchDefaultSystemFields(data) {
    const resp = await axios.get(`${SF_BASE}/defaults`, { params: data });
    return resp.data;
  }

  async getSystemFieldConfigs(data) {
    const resp = await axios.get(`${SF_BASE}/configs`, { params: data });
    return resp.data;
  }

  async fetchEntitySystemFieldsWithPermissions(data) {
    const resp = await axios.get(`${SF_BASE}/with-permissions`, { params: data });
    return resp.data;
  }

  async assignPermission(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/assign-permission`, data.jsonArr);
    return resp.data;
  }

  async updateVisible(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/visible?visible=${data.checked}`);
    return resp.data;
  }

  async updateRequired(data) {
    const resp = await axios.put(`${SF_BASE}/required`, null, { params: data });
    return resp.data;
  }

  async updateDefault(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/default?isDefault=${data.checked}`);
    return resp.data;
  }

  async updateColorCode(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/color-code`, data);
    return resp.data;
  }

  async updateIsLastStatus(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/lastStatus?isLastStatus=${data.checked}`);
    return resp.data;
  }

  async updateIsFirstStatus(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/firstStatus?isFirstStatus=${data.checked}`);
    return resp.data;
  }

  async updateNextStatus(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/next-status`, data);
    return resp.data;
  }

  async updateLockAction(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/lockAction`, data);
    return resp.data;
  }

  async unAssignNextNode(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/unassign-next`, data);
    return resp.data;
  }

  async assignNextNode(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/assign-next`, data);
    return resp.data;
  }

  async updateNodeWorkflowPosition(data) {
    const resp = await axios.put(`${SF_BASE}/${data.id}/update-workflow-position`, data.body);
    return resp.data;
  }
}

export default new SystemFieldApi();
