import React, { useEffect, useMemo, useState } from 'react';
import { SimpleTree, Tree } from 'react-arborist';
import TreeNode from './TreeNode';
import styles from './arborist.module.css';
import { FillFlexParent } from './utils/fill-flex-parent';
import { TreeActionsFactory } from './TreeActionsFactory';
import * as TreeEvents from './TreeEvents';
import { EVENT_TYPES } from './TreeEvents';
import { ENTITY_TYPES, TREENODE_ROOT } from '../../constants';
import { dropPrivilegeCheck } from '../../helpers/PriviligeHelper';
import { showError, showErrorMes } from '../../helpers/NotificationHelper';
import { PARENTS } from './utils/TreeConsts';
import { getParentId } from './actions';
import { setLastTreeItem } from '../../hooks/LastTreeItemHelper';
import { getArtifactName } from '../../helpers/ArtifactNameHelper';
import TreeRow from './TreeRow';
import { isEmpty } from 'lodash-es';

const TreeArborist = ({
  module,
  draggable,
  projectId,
  projectConfig,
  checkable,
  eventsEnabled = true,
  params = {},
  filters,
  onSelect = () => {},
  onCheck = () => {}
}) => {
  const [treeApi, setTreeApi] = useState(null);
  const [tree, setTree] = useState(new SimpleTree([]));
  const [checkedNodes, setCheckedNodes] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState([TREENODE_ROOT.id]);

  // eslint-disable-next-line no-unused-vars
  const { id, type, version } = useMemo(
    () => ({
      id: params.id,
      type: params.type,
      version: params.version
    }),
    [params]
  );

  const treeActions = useMemo(
    () => TreeActionsFactory.get(module, projectConfig, checkable),
    [module, projectConfig, checkable]
  );

  const updateTree = (param = {}) => {
    if (!tree) return;

    tree.update(param);
    setTree(new SimpleTree(tree.data));
  };

  useEffect(() => {
    const expandNodes = async () => {
      let parents = [];
      if (id) {
        const node = tree.find(id);
        if (node || node?.id === TREENODE_ROOT.id) return;
        parents = await treeActions?.getParents(type, id);
      }
      setExpandedNodes([...new Set([TREENODE_ROOT.id, ...expandedNodes, ...parents, id])]);
    };

    if (!isEmpty(filters) && (filters.code || filters.name)) {
      treeActions
        .getTreeData(projectId, filters)
        .then((data) => {
          setTree(new SimpleTree(data));
        })
        .catch((err) => {
          showErrorMes(err);
        });
    } else {
      const rootNode = tree.find(TREENODE_ROOT.id);
      if (!rootNode) {
        setTree(
          new SimpleTree([
            {
              id: TREENODE_ROOT.id,
              name:
                module === ENTITY_TYPES.TEST_SUITE
                  ? getArtifactName(projectConfig, ENTITY_TYPES.TEST_CYCLE)
                  : getArtifactName(projectConfig, module),
              type: TREENODE_ROOT.type
            }
          ])
        );
      }

      expandNodes();
    }
  }, [id, filters]);

  useEffect(() => {
    if (!module || !eventsEnabled) return;

    TreeEvents.subscribe(module, EVENT_TYPES.CREATED, onCreateEvent);
    TreeEvents.subscribe(module, EVENT_TYPES.UPDATED, onUpdateEvent);
    TreeEvents.subscribe(module, EVENT_TYPES.DELETED, onDeleteEvent);

    return () => {
      TreeEvents.unsubscribe(module, EVENT_TYPES.CREATED, onCreateEvent);
      TreeEvents.unsubscribe(module, EVENT_TYPES.UPDATED, onUpdateEvent);
      TreeEvents.unsubscribe(module, EVENT_TYPES.DELETED, onDeleteEvent);
    };
  }, [module, eventsEnabled, tree, treeApi]);

  const handleNodeClick = async (node) => {
    if (!checkable) {
      onSelect(node.data);
      setLastTreeItem(projectId, treeActions?.getPage(), node?.data?.type, node.id, node?.data?.majorVersion);
    }
    openNode(node);
  };

  const toggle = (node) => {
    if (expandedNodes.includes(node.id)) {
      setExpandedNodes(expandedNodes.filter((id) => id !== node.id));
    } else {
      setExpandedNodes([...expandedNodes, node.id]);
    }
  };

  const openNode = (node) => {
    const oldExpandedNodes = expandedNodes.filter((id) => id !== node.id);
    setExpandedNodes([...oldExpandedNodes, node.id]);
  };

  const onMove = ({ dragIds = [], dragNodes = [], parentId, parentNode }) => {
    if (!dragIds?.length || !dragNodes?.length) return;

    dragIds.forEach((dragId, index) => {
      if (dragId === TREENODE_ROOT.id) return;

      const dragNode = dragNodes[index];
      if (dragNode.id === parentId || dragNode.parent.id === parentId) return;
      if (parentId === TREENODE_ROOT.id && !PARENTS.includes(dragNode?.data?.type)) return;

      const error = dropPrivilegeCheck(dragNode.data.type, projectId);
      if (error) {
        showError(error);
        return;
      }

      const moveAction = treeActions.move(dragNode, parentNode);
      if (!moveAction) return;

      moveAction
        .then(async (response) => {
          const parentId = getParentId(response, dragNode.data.type);
          const draggedNode = tree.find(response.id);
          const newParent = tree.find(parentId);
          await getChildrenOfNode(newParent);
          newParent.addChild(treeActions.createNodeData(response, draggedNode?.data?.type, parentId), 0);
          draggedNode.drop();
          setTree(new SimpleTree(tree.data));
          openNode(newParent);
        })
        .catch((err) => {
          showErrorMes(err);
        });
    });
  };

  const onCreateEvent = (e = {}) => {
    const { detail } = e;
    if (!detail) return;

    const parentId = getParentId(detail, detail?.type);
    const nodeData = treeActions.createNodeData(detail, detail?.type, parentId);
    const maxIndex = Number.MAX_SAFE_INTEGER; 
    tree.create({ parentId, index: maxIndex, data: nodeData });

    openNode(tree.find(parentId));

    setTree(new SimpleTree(tree.data));
    treeApi.select(detail.id);
    onSelect(nodeData);
  };

  const onUpdateEvent = (e = {}) => {
    const { detail } = e;
    if (!detail) return;

    updateTree({ id: detail.rootId || detail.id, changes: treeActions.createNodeData(detail, detail.type) });
  };

  const onDeleteEvent = (e = {}) => {
    const { detail } = e;
    if (!detail) return;

    const deletedNode = tree.find(detail.id);
    if (!deletedNode) return;

    deletedNode.drop();
    setTree(new SimpleTree(tree.data));
    treeApi.select(deletedNode?.parent?.id);
    onSelect(tree.find(deletedNode?.parent?.id)?.data);
  };

  const getChildrenOfNode = (node) =>
    new Promise((resolve, reject) => {
      if (!treeActions.isFolder(node?.data?.type) || node?.children?.length || node?.data?.loading) {
        return resolve();
      }

      updateTree({ id: node.id, changes: { loading: true } });
      treeActions
        .getChildren(node.id, projectId, node?.data?.type, filters)
        .then((children) => {
          updateTree({ id: node.id, changes: { children, loading: false } });
          return resolve();
        })
        .catch((err) => {
          updateTree({ id: node.id, changes: { loading: false } });
          return reject(err);
        });
    });

  const handleNodeSelect = (node) => {
    if (node.id === TREENODE_ROOT.id) {
      node.isSelected ? treeApi?.deselectAll() : treeApi.selectAll();
    } else {
      if (node.isSelected) {
        treeActions.deselectAllChildren(node);
        treeActions.deselectAllParents(node);
      } else {
        treeActions.selectAllChildren(node);
      }
    }

    onCheck(treeApi?.selectedNodes);
    setCheckedNodes(treeApi?.selectedNodes.map((n) => n.id));
  };

  const handleRowClick = (e, node) => {
    if (e.metaKey && !node.tree.props.disableMultiSelection) {
      node.isSelected ? node.deselect() : node.selectMulti();
    } else if (e.shiftKey && !node.tree.props.disableMultiSelection) {
      node.selectContiguous();
    } else if (e.target.id === 'node-text') {
      if (!checkable) node.select();
      node.activate();
    }
  };

  return (
    <FillFlexParent>
      {({ height, width }) => (
        <Tree
          height={height}
          width={width}
          ref={(t) => setTreeApi(t)}
          data={tree?.data || []}
          disableDrag={!draggable}
          disableMultiSelection={true}
          selectionFollowsFocus={false}
          className={styles.tree}
          rowClassName={styles.row}
          padding={10}
          rowHeight={30}
          overscanCount={8}
          openByDefault={false}
          disableEdit={true}
          onMove={onMove}
          renderRow={({ node, attrs, children }) => (
            <TreeRow
              tree={treeApi}
              attrs={attrs}
              node={node}
              handleRowClick={handleRowClick}
              getChildrenOfNode={getChildrenOfNode}
            >
              {children}
            </TreeRow>
          )}
        >
          {({ node, style, dragHandle }) => (
            <TreeNode
              tree={treeApi}
              key={node.id}
              checkable={checkable}
              handleNodeClick={() => handleNodeClick(node)}
              handleArrowClick={() => toggle(node)}
              handleNodeSelect={handleNodeSelect}
              node={node}
              style={style}
              dragHandle={dragHandle}
              checkedNodes={checkedNodes}
              expandedNodes={expandedNodes}
              getChildrenOfNode={getChildrenOfNode}
              activeNodeId={id}
              setSelectedItem={(node) =>
                setLastTreeItem(projectId, treeActions?.getPage(), node?.type, node.id, node?.majorVersion)
              }
            />
          )}
        </Tree>
      )}
    </FillFlexParent>
  );
};
export default TreeArborist;
