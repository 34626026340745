import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useApi } from '../../../../../hooks/UseApi';
import VisiumGoIntegrationApi from '../../../../../api/VisiumGoIntegrationApi';

import { Modal } from '../../../../../components/modal';
import { Button, Dropdown } from '../../../../../designSystem/DesignSystem';

import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../../helpers/NotificationHelper';

const AddVisiumGoProjectModal = ({ isOpen, projects, closeModal, selectedId }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const [selectedItem, setSelectedItem] = useState(null);

  const addProjectConfig = useApi({
    name: 'addProjectConfig',
    api: VisiumGoIntegrationApi.addProjectConfig,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal();
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = () => {
    const input = {
      name: selectedItem?.name,
      goProjectId: selectedItem?.id,
      goProjectName: selectedItem?.name,
      goIntegrationId: selectedId
    };
    addProjectConfig.execute({ ...input });
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
      >
        <Modal.Title>{t('Add Visium Go Project Config')}</Modal.Title>
        <form
          className="flex  flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content>
            <Dropdown
              required
              control={control}
              label={t('Projects')}
              name="project"
              placeholder={t('Select Project')}
              options={projects}
              passValue={(value) => setSelectedItem(value[0])}
            />
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="addVgoProjectModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={addProjectConfig?.loading}
            />
            <Button
              id="addVgoProjectModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
              }}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};
export default AddVisiumGoProjectModal;
