import { Dialog } from '@headlessui/react';
import { classNames } from '../../helpers/Helper';
import { Heading, Body } from '../../designSystem/Typography/Typography';

export const Overlay = () => {
  return (
    <>
      <div
        id="overlay"
        className="flex min-h-0 items-center justify-center"
      >
        <Dialog.Overlay className={classNames('fixed inset-0', 'bg-black/50 backdrop-blur-sm')} />
      </div>
    </>
  );
};

export const ContentContainer = ({ children, w }) => {
  return (
    <>
      <div
        id="modal"
        className={classNames(
          'relative m-auto self-center',
          'rounded-lg bg-white',
          'overflow-x-auto overflow-y-hidden',
          // width
          w === 'sm' && 'w-11/12 sm:w-10/12 md:w-5/12 lg:w-4/12 xl:w-3/12 2xl:w-3/12',
          w === 'md' && 'w-11/12 sm:w-10/12 md:w-8/12 lg:w-7/12 xl:w-6/12 2xl:w-6/12',
          w === 'lg' && 'w-11/12 sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12',
          w === 'xl' && 'w-11/12 sm:w-11/12 md:w-11/12 lg:w-11/12 xl:w-11/12 2xl:w-11/12',
          !w && 'w-5/6 sm:w-4/6 md:w-3/6 lg:w-3/6 xl:w-1/6'
        )}
      >
        {children}
      </div>
    </>
  );
};

export const Content = ({ children, h }) => {
  return (
    <>
      <div
        className={classNames(
          'overflow-y-auto',
          h === 'xs' && 'max-h-[10vh]',
          h === 'sm' && 'max-h-[20vh]',
          h === 'md' && 'max-h-[40vh]',
          h === 'lg' && 'max-h-[60vh]',
          h === 'xl' && 'max-h-[80vh]'
        )}
      >
        <div className="m-4 flex flex-col flex-wrap gap-4">{children}</div>
      </div>
    </>
  );
};

export const Title = ({ children, subTitle }) => {
  return (
    <>
      <Dialog.Title className="bg-gray-50 p-4">
        <Heading
          type="medium"
          text={children}
        />
        {subTitle && (
          <Body
            type="large"
            text={subTitle}
          />
        )}
      </Dialog.Title>
    </>
  );
};

export const Text = ({ children }) => {
  return (
    <>
      <p className="m-4 text-sm text-gray-500">{children}</p>
    </>
  );
};
export const Buttons = ({ children }) => {
  return (
    <>
      <div className="flex justify-end gap-4 bg-gray-50 p-4 ">{children}</div>
    </>
  );
};
