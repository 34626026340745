import React, { useEffect, useState } from 'react';
import { isViewReport } from '../../helpers/PriviligeHelper';
import { t } from 'i18next';
import AlertModal from '../../components/alert/AlertModal';
import { Button } from '../../designSystem/DesignSystem';
import AddReportModal from './Modals/AddReportModal';
import EditReportModal from './Modals/EditReportModal';
import ConfirmationModal from '../../components/confirm/ConfirmationModal';
import { useApi } from '../../hooks/UseApi';
import KibanaReportsApi from '../../api/KibanaReportsApi';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { useNavigate } from 'react-router-dom';
import { isValidUrl } from '../../helpers/Helper';
import { WarningCircle } from 'phosphor-react';
import { useApp, useAppDispatch } from '../../context/AppContext';
import { setSelectedReport } from '../../context/ProjectActions';

const KibanaReportsPage = ({ projectId, setKibanaReports, kibanaReports }) => {
  const [isAddReportModalOpen, setIsAddReportModalOpen] = useState(false);
  const [isEditReportModalOpen, setIsEditReportModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const app = useApp();
  const selectedReport = app?.selectedReport;
  const navigate = useNavigate();

  const reportsLoader = useApi({
    name: ' Getting reports',
    api: KibanaReportsApi.get,
    onSuccess: () => {
      setKibanaReports(reportsLoader?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteReport = useApi({
    name: 'Deleting reports',
    api: KibanaReportsApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      reportsLoader.execute({ projectId });
      setDeleteConfirmModalOpen(false);
      showSuccessMes(INFO_MESSAGES.delete);
      dispatch(setSelectedReport([{}]));
      navigate(`/reports/kibana?projectId=${projectId}`);
    },
    onError: (err) => showErrorMes(err)
  });

  const handleDelete = () => {
    if (selectedReport?.id) {
      deleteReport.execute({ id: selectedReport?.id });
    }
  };

  useEffect(() => {
    if (selectedReport && Object.keys(selectedReport).length > 1) {
      navigate(`/reports/kibana/${selectedReport?.id}?projectId=${projectId}`);
    } else {
      navigate(`/reports/kibana?projectId=${projectId}`);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (selectedReport && Object.keys(selectedReport).length === 1 && kibanaReports.length === 1) {
      dispatch(setSelectedReport(kibanaReports[0]));
      navigate(`/reports/kibana/${kibanaReports[0]?.id}?projectId=${projectId}`);
    }
  }, [kibanaReports]);

  useEffect(() => {
    if (selectedReport && Object.keys(selectedReport).length === 1 && kibanaReports.length === 1) {
      navigate(`/reports/kibana/${selectedReport?.id}?projectId=${projectId}`);
    }
  }, []);

  return (
    <>
      {!isViewReport(projectId) && (
        <AlertModal
          header={t('Access Denied')}
          content={t('You have no permission to access this page.')}
        />
      )}
      <div className="flex items-center justify-end gap-x-3 px-3 py-3">
        <Button
          id="reportsPage-add-button"
          primary
          text={t('Add Report')}
          size={'small'}
          icon="plus"
          onClick={() => setIsAddReportModalOpen(true)}
        />
        {selectedReport && Object.keys(selectedReport).length > 1 && (
          <Button
            id="reportsPage-delete-button"
            secondary
            text={t('Delete')}
            size="small"
            icon="Trash"
            onClick={() => setDeleteConfirmModalOpen(true)}
          />
        )}
        {selectedReport && Object.keys(selectedReport).length > 1 && (
          <Button
            id="reportsPage-edit-button"
            primary
            text={t('Edit')}
            size="small"
            icon="Pencil"
            onClick={() => setIsEditReportModalOpen(true)}
          />
        )}
      </div>
      <div className="mb-8 flex flex-grow bg-white pl-8 pr-8 pt-2">
        {isValidUrl(selectedReport?.url?.url) ? (
          <iframe
            src={selectedReport?.url?.url}
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        ) : (
          <div style={{ marginTop: '6%', marginLeft: '50%' }}>
            <div style={{ marginLeft: 20 }}>
              <WarningCircle size="36" />
            </div>
            <span>No valid URL</span>
          </div>
        )}
      </div>
      {isAddReportModalOpen && (
        <AddReportModal
          isOpen={isAddReportModalOpen}
          projectId={projectId}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          closeModal={() => {
            setIsAddReportModalOpen(false);
            reportsLoader.execute({ projectId });
            navigate(`/reports/kibana/${selectedReport?.id}?projectId=${projectId}`);
          }}
        />
      )}
      {isEditReportModalOpen && (
        <EditReportModal
          selectedReport={selectedReport}
          isOpen={isEditReportModalOpen}
          projectId={projectId}
          closeModal={() => {
            setIsEditReportModalOpen(false);
            reportsLoader.execute({ projectId });
          }}
        />
      )}
      {deleteConfirmModalOpen && (
        <ConfirmationModal
          isOpen={deleteConfirmModalOpen}
          loading={deleteReport?.loading}
          closeModal={() => {
            setDeleteConfirmModalOpen(false);
            reportsLoader.execute({ projectId });
          }}
          handleConfirm={handleDelete}
          header={t('Confirm Delete')}
          content={t('Do you really want to delete this?')}
        />
      )}
    </>
  );
};

export default KibanaReportsPage;
