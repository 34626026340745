import React, { useState, useMemo, useEffect } from 'react';
// API and Hook Imports
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../context/AppContext';
import { saveEntityCustomFields } from '../../../context/ProjectActions';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseProjectUsers from '../../../hooks/UseProjectUsers';
import UseQueryProject from '../../../hooks/UseQueryProject';
import { useApi } from '../../../hooks/UseApi';
import JiraApi from '../../../api/JiraApi';
import SystemFieldApi from '../../../api/SystemFieldApi';
import CustomFieldApi from '../../../api/CustomFieldApi';
// Design System Import
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { PencilLine, CopySimple, Trash, MagnifyingGlassPlus } from 'phosphor-react';
//Component Imports
import BasicTable from '../../../components/table/BasicTable';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import CustomFieldBaseModal from './CustomFieldBaseModal';
import CustomFieldCloneModal from './CustomFieldCloneModal';
import JiraCustomFieldAddModal from './modal/JiraCustomFieldAddModal';
import JiraAddStatusField from './modal/JiraAddStatusField';
//Helper Imports
import { ENTITY_TYPES, CUSTOM_FIELD_TYPES } from '../../../constants';
import { capitalize, isValidValue } from '../../../helpers/Helper';
import { customFieldConvertToTableData } from '../../../helpers/CustomFieldHelper';
import { disableResizingForColumns, generateTableColumns, translateColumns } from '../../../helpers/TableHelper';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { SidePaneRightContent } from '../../../components/Layout';
import CustomFieldMultiCloneModal from './CustomFieldMultiCloneModal';
import { useTranslation } from 'react-i18next';

const CustomFieldsPage = () => {
  const { projectId, project } = UseQueryProject('projectId');
  const { t } = useTranslation();

  const location = useLocation();
  const projectConfig = UseProjectConfig(projectId);
  const query = new URLSearchParams(location.search);
  const activeItem = query.get('activeSubNav');
  const userList = UseProjectUsers(projectId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [customFieldMultiCloneModalVis, setCustomFieldMultiCloneModalVis] = useState(false);
  const [openCustomFieldCloneModal, setOpenCustomFieldCloneModal] = useState(false);
  const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false);
  const [jiraStatusModalVis, setJiraStatusModalVis] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [jiraModalVis, setJiraModalVis] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);

  const [entitySystemFields, setEntitySystemFields] = useState([]);
  const [entityCustomFields, setEntityCustomFields] = useState([]);
  const [customFieldTypes, setCustomFieldTypes] = useState([]);
  const [relatedEntity, setRelatedEntity] = useState(null);
  const [selectedItem, setSelectedItem] = useState('');
  const [jiraConfig, setJiraConfig] = useState('');

  //#region API
  const dataLoader = useApi({
    name: 'Get entity customFields',
    api: CustomFieldApi.get,
    autoLoad: false,
    onSuccess: () => {
      setEntityCustomFields(dataLoader?.data);
      dispatch(saveEntityCustomFields(projectId, relatedEntity, dataLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const getJiraConfig = useApi({
    name: 'Get active jira config',
    api: JiraApi.fetchActiveJiraConfig,
    autoLoad: false,
    onSuccess: () => {
      setJiraConfig(getJiraConfig?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const systemFieldLoader = useApi({
    name: 'Get all system fields',
    api: SystemFieldApi.fetchAllSystemFields,
    autoLoad: false,
    onSuccess: () => {
      let options = mapSystemFieldOptions(systemFieldLoader?.data);
      setEntitySystemFields(options);
    },
    onError: () => setEntitySystemFields([])
  });

  const customFieldTypesLoader = useApi({
    name: 'Custom Field Types Loader',
    api: CustomFieldApi.getTypes,
    autoLoad: false,
    onSuccess: () => {
      const types = customFieldTypesLoader.data?.customField?.map((item) => ({
        key: item.id,
        text:
          item.id === CUSTOM_FIELD_TYPES.DROP_DOWN
            ? 'Multi Select'
            : item.id === CUSTOM_FIELD_TYPES.MEMO
            ? 'Rich Text'
            : item.name,
        value: item.id,
        enum: item,
        name:
          item.id === CUSTOM_FIELD_TYPES.DROP_DOWN
            ? 'Multi Select'
            : item.id === CUSTOM_FIELD_TYPES.MEMO
            ? 'Rich Text'
            : item.name
      }));
      setCustomFieldTypes(types);
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteCustomField = useApi({
    name: 'delete custom field',
    api: CustomFieldApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      getCustomFieldList();
      setSelectedItem(null);
      setOpenDeleteModal(false);
      showSuccessMes(INFO_MESSAGES.delete);
    },
    onError: (err) => showErrorMes(err)
  });
  //#endregion

  const optionsFn = (row) => {
    return [
      {
        name: t('Edit'),
        icon: (weight) => (
          <PencilLine
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: isValidValue(row?.original?.templateBy),
        onClick: () => {
          handleSelectedRow(row);
          setOpenCustomFieldModal(true);
        }
      },
      {
        name: t('Delete'),
        icon: (weight) => (
          <Trash
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: isValidValue(row?.original?.templateBy),
        onClick: () => {
          handleSelectedRow(row);
          setOpenDeleteModal(true);
        }
      },
      {
        name: t('Clone'),
        icon: (weight) => (
          <CopySimple
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: isValidValue(row?.original?.templateBy),
        onClick: () => {
          handleSelectedRow(row);
          setOpenCustomFieldCloneModal(true);
        }
      },
      {
        name: t('Details'),
        icon: (weight) => (
          <MagnifyingGlassPlus
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          setIsViewMode(true);
          handleSelectedRow(row);
          setOpenCustomFieldModal(true);
        }
      }
    ];
  };
  const { tableData, tableColumns } = useMemo(() => {
    let dd = [];
    let columns = [];
    if (dataLoader?.data) {
      dd = customFieldConvertToTableData(
        dataLoader?.data,
        userList,
        customFieldTypes,
        dataLoader?.data,
        entitySystemFields,
        jiraConfig,
        activeItem
      );
      columns = generateTableColumns(dd, optionsFn, ['id']);
    }
    return {
      tableData: dd,
      tableColumns: columns
    };
  }, [dataLoader?.data, entitySystemFields]);

  const handleSelectedRow = (row) => {
    const selectedData = dataLoader?.data?.find((item) => item?.customFieldId === row?.original?.id);

    setSelectedItem(selectedData);
  };

  const handleDelete = () => {
    deleteCustomField.execute({ id: selectedItem?.customFieldId });
  };

  const mapSystemFieldOptions = (data) => {
    var entry = Object.entries(data);
    let systemFieldOptions = entry.map((item) => {
      return {
        id: item[0],
        key: item[0],
        value: item[0],
        text: capitalize(item[0]),
        name: capitalize(item[0]),
        values: item[1].map((object) => {
          return {
            id: object.id,
            key: object.id,
            value: object.id,
            text: object.name,
            name: object.name,
            visible: object?.visible
          };
        })
      };
    });

    return systemFieldOptions;
  };

  const getCustomFieldTypes = async () => {
    if (projectId) {
      if (customFieldTypes.length === 0) {
        await customFieldTypesLoader.execute();
      }
    }
  };

  const getCustomFieldList = () => {
    dataLoader.execute({ projectId, allFields: true, relatedEntity });
  };

  useEffect(() => {
    if (isProjectManager(projectId)) {
      if (!isValidValue(jiraConfig)) {
        getJiraConfig.execute({ projectId });
      }
      if (projectId && relatedEntity) {
        systemFieldLoader.execute({ projectId, relatedEntity });
        getCustomFieldTypes();
        getCustomFieldList();
      }
    }
  }, [projectId, relatedEntity]);

  useEffect(() => {
    if (!isValidValue(activeItem)) {
      navigate(`?projectId=${projectId}&activeSubNav=${ENTITY_TYPES.REQUIREMENT}`);
    } else {
      setRelatedEntity(activeItem);
    }
  }, [activeItem]);

  let columns = disableResizingForColumns(tableColumns, [{ accessor: 'actions', width: 50 }]);
  columns = translateColumns(columns, t);
  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('Custom Fields')}
            subText={`${getArtifactName(projectConfig, relatedEntity)}`}
            subTextType="base"
          >
            {isProjectManager(projectId) && (
              <>
                <div className="flex flex-row gap-4">
                  <Button
                    id="customField-clonefields-button"
                    onClick={() => setCustomFieldMultiCloneModalVis(true)}
                    secondary
                    icon="Plus"
                    size="small"
                    text={t('Clone Fields')}
                  />
                  {jiraConfig?.active &&
                    (((jiraConfig?.activeRequirement === true || project?.isTemplate === true) &&
                      (activeItem === ENTITY_TYPES.REQUIREMENT || activeItem === ENTITY_TYPES.TEST_CYCLE)) ||
                      ((jiraConfig?.activeDefect === true || project?.isTemplate === true) &&
                        activeItem === ENTITY_TYPES.DEFECT)) && (
                      <Button
                        id="customField-jiraStatus-button"
                        secondary
                        text={t('Jira Status')}
                        size="small"
                        icon="Plus"
                        onClick={() => setJiraStatusModalVis(true)}
                      />
                    )}
                  {jiraConfig?.active &&
                    (((jiraConfig?.activeRequirement === true || project?.isTemplate === true) &&
                      (activeItem === ENTITY_TYPES.REQUIREMENT || activeItem === ENTITY_TYPES?.TEST_CYCLE)) ||
                      ((jiraConfig?.activeDefect === true || project?.isTemplate === true) &&
                        activeItem === ENTITY_TYPES.DEFECT)) && (
                      <Button
                        id="customField-jiraFields-button"
                        secondary
                        text={t('Jira Fields')}
                        size="small"
                        icon="Plus"
                        onClick={() => setJiraModalVis(true)}
                      />
                    )}
                  <Button
                    id="customField-addField-button"
                    onClick={() => setOpenCustomFieldModal(true)}
                    primary
                    size="small"
                    icon="Plus"
                    text={t('Add New Field')}
                  />
                </div>
              </>
            )}
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center colFull={true}>
            {isProjectManager(projectId) && (
              <div className="mt-6">
                <BasicTable
                  columns={columns}
                  data={tableData}
                  loading={dataLoader?.loading}
                />
              </div>
            )}
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>
      {/* ‡ MODALS ‡ */}
      {openDeleteModal === true && (
        <ConfirmationModal
          isOpen={openDeleteModal}
          loading={deleteCustomField?.loading}
          closeModal={() => setOpenDeleteModal(false)}
          handleConfirm={handleDelete}
          header={t('Remove Custom Field from Project')}
          content={t('Are you sure to remove this custom field from project?')}
        />
      )}

      {openCustomFieldModal && (
        <CustomFieldBaseModal
          {...(selectedItem && { data: selectedItem })}
          isViewMode={isViewMode}
          project={project}
          isOpen={openCustomFieldModal}
          closeModal={(form) => {
            if (form) {
              getCustomFieldList();
            }
            setOpenCustomFieldModal(false);
            setSelectedItem(null);
            setIsViewMode(false);
          }}
          customFieldTypes={customFieldTypes}
          relatedEntity={relatedEntity}
          entitySystemFields={entitySystemFields}
          entityCustomFields={entityCustomFields}
          jiraConfig={jiraConfig}
          userList={userList}
        />
      )}

      {openCustomFieldCloneModal === true && (
        <CustomFieldCloneModal
          isOpen={openCustomFieldCloneModal}
          closeModal={(form) => {
            if (form) {
              getCustomFieldList();
            }
            setOpenCustomFieldCloneModal(false);
            setSelectedItem(null);
          }}
          relatedEntity={relatedEntity}
          projectId={projectId}
          data={selectedItem}
        />
      )}
      {jiraModalVis === true && (
        <JiraCustomFieldAddModal
          isOpen={jiraModalVis}
          closeModal={() => setJiraModalVis(false)}
          entityType={activeItem}
          jiraConfig={jiraConfig}
          getItems={getCustomFieldList}
        />
      )}

      {jiraStatusModalVis === true && (
        <JiraAddStatusField
          isOpen={jiraStatusModalVis}
          closeModal={() => setJiraStatusModalVis(false)}
          entityType={activeItem}
          jiraConfig={jiraConfig}
          getItems={getCustomFieldList}
        />
      )}
      {customFieldMultiCloneModalVis === true && (
        <CustomFieldMultiCloneModal
          projectId={projectId}
          customFields={entityCustomFields}
          isOpen={customFieldMultiCloneModalVis}
          closeModal={() => setCustomFieldMultiCloneModalVis(false)}
          projectConfigs={projectConfig}
          entityType={activeItem}
        />
      )}
    </>
  );
};

export default CustomFieldsPage;
