import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
// API and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import LdapApi from '../../../api/LdapApi';
import UserApi from '../../../api/UserApi';
// Design System Imports
import { Dropdown, Input, Button, H5, H6 } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
import { translateArrItemName } from '../../../helpers/TranslationHelper';

const searchTypesDropdown = [
  { key: 'department', value: 'department', text: 'Department', name: 'Department' },
  { key: 'email', value: 'email', text: 'Email', name: 'Email' },
  { key: 'userName', value: 'userName', text: 'Username', name: 'Username' },
  { key: 'fullName', value: 'fullName', text: 'Full Name', name: 'Full Name' }
];

const AddLdapUserModal = (props) => {
  const { isOpen, closeModal, submitCallBack, savedUsers } = props;
  const { control, handleSubmit, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();

  const [selectedSearchType, setSelectedSearchType] = useState(searchTypesDropdown[0]);
  const [allResults, setAllResults] = useState([]);
  const [selectedResults, setSelectedResults] = useState([]);
  const [selectedLdap, setSelectedLdap] = useState({
    key: 'all',
    value: 'all',
    text: 'All Servers',
    name: 'All Servers'
  });

  const [searchKey, setSearchKey] = useState('');

  const ldapLoader = useApi({
    name: 'Get all ldap servers',
    api: LdapApi.getAll,
    autoLoad: true
  });

  const searchLdapUser = useApi({
    name: 'search ldap user',
    api: UserApi.searchLdapUser,
    autoLoad: false,
    onSuccess: () => {
      setAllResults(searchLdapUser?.data);
    }
  });

  const dataArr = searchTypesDropdown.map((item) => {
    return {
      key: item.key,
      value: item.value,
      name: t(item.name),
      text: item.text
    };
  });
  const addLdapUser = useApi({
    name: 'add ldap user',
    api: UserApi.addLdapUser,
    autoLoad: false,
    onSuccess: () => {
      closeModal();
      showSuccessMes(INFO_MESSAGES.add);
      submitCallBack(addLdapUser?.data[0]);
    },
    onError: (err) => showErrorMes(err)
  });

  const ldapDropdown = useMemo(() => {
    let arr = [{ key: 'all', value: 'all', text: 'All Servers', name: 'All Servers' }];
    ldapLoader?.data?.map((ldapServer) => {
      arr.push({
        key: ldapServer.id,
        value: ldapServer.id,
        text: ldapServer.name,
        name: ldapServer?.name
      });
    });
    return translateArrItemName(arr, t);
  }, [ldapLoader?.data]);

  const getResults = (value) => {
    if (selectedLdap.value === 'all')
      searchLdapUser.execute({ ldapId: '', type: selectedSearchType.value, param: value });
    else searchLdapUser.execute({ ldapId: selectedLdap.value, type: selectedSearchType.value, param: value });
  };

  const onClickAddUser = (user) => setSelectedResults((prev) => (prev ? [...selectedResults, user] : [user]));

  const onClickDeleteUser = (index) =>
    setSelectedResults(selectedResults ? selectedResults.filter((_, i) => i !== index) : []);

  const onSubmit = () => addLdapUser.execute(selectedResults);

  // TODO: onChange search keyword kullanılıyor mu ? kullanılmıyorsa silinecek.
  //eslint-disable-next-line
  const onChangeSearchKeyword = (value) => {
    if (value === '') setAllResults([]);
    else {
      if (
        (selectedSearchType.value === 'email' ||
          selectedSearchType.value === 'userName' ||
          selectedSearchType.value === 'fullName') &&
        value.length < 3
      )
        setAllResults([]);
      else getResults(value);
    }
  };

  const debounce_fun = useCallback(
    _.debounce(() => getResults(searchKey), 750),
    [searchKey]
  );

  useEffect(() => {
    if (searchKey?.length < 3) setAllResults([]);
    else debounce_fun();

    return debounce_fun.cancel;
  }, [searchKey, debounce_fun]);

  const handleValue = (value) => setSearchKey(value);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t('Search LDAP user')}</Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-8">
              <div className="grid grid-cols-2 divide-x overflow-y-auto">
                <div className="mt-1 px-3">
                  <Dropdown
                    clearable
                    control={control}
                    name={'search'}
                    options={ldapDropdown}
                    selectedItem={selectedLdap}
                    passValue={(value) => {
                      setSelectedLdap(translateArrItemName(value, t)[0]);
                      setAllResults([]);
                    }}
                  />
                  <div className="flex items-center py-2">
                    <div className="relative">
                      <Dropdown
                        control={control}
                        name={'search'}
                        options={dataArr}
                        selectedItem={selectedSearchType}
                        passValue={(value) => {
                          setSelectedSearchType(translateArrItemName(value, t)[0]);
                          setAllResults([]);
                        }}
                      />
                    </div>
                    <Input
                      classCss={'w-[2px]'}
                      name="name"
                      placeholder={t(`Search`)}
                      control={control}
                      passValue={(value) => handleValue(value)}
                    />
                  </div>
                  <div className="flex">
                    <table className="flex-grow">
                      <thead className="flex-grow border-b-2 border-gray-300 ">
                        <tr className="flex flex-grow">
                          <div className="flex-grow">
                            <th>{t('User')}</th>
                          </div>
                          <th className="w-1/4">{t('Add')}</th>
                        </tr>
                      </thead>
                      <tbody className="h-96 flex-grow">
                        {!_.isEmpty(allResults) ? (
                          allResults.map((user, index) => {
                            let fullName = user.firstName + ' ' + user.lastName;
                            return (
                              <tr
                                key={index}
                                className="flex flex-grow"
                              >
                                <td className="w-3/4 place-content-start self-start p-1">
                                  <H5
                                    color={'text-black'}
                                    text={fullName}
                                  />
                                  <H6 text={user.email} />
                                </td>
                                <td className="flex w-1/4 place-content-center self-center">
                                  <Button
                                    id="addLdapUserModal-add-button"
                                    disabled={
                                      (selectedResults
                                        ? selectedResults.some((value) => {
                                            return value.userName === user.userName;
                                          })
                                        : false) ||
                                      savedUsers.some((value) => {
                                        return value.userName === user.userName;
                                      })
                                    }
                                    onClick={() => onClickAddUser(user)}
                                    primary
                                    icon="ArrowRight"
                                    size="small"
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <span className="font-semibold text-gray-500 ">{t('No user found with this query ')}</span>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="px-3">
                  <div className="flex py-4">
                    <H5
                      text={t('Selected Users')}
                      className={'mb-1 font-semibold'}
                    />
                  </div>
                  <div className="flex">
                    <table className="flex-grow">
                      <thead className="flex-grow border-b-2 border-gray-300 ">
                        <tr className="flex flex-grow">
                          <th className="w-1/4">{t('Delete')}</th>
                          <th>{t('User')}</th>
                        </tr>
                      </thead>
                      <tbody className="flex-grow ">
                        {!_.isEmpty(selectedResults) ? (
                          selectedResults.map((user, index) => {
                            let fullName = user.firstName + ' ' + user.lastName;
                            return (
                              <tr
                                key={index}
                                className="flex flex-grow"
                              >
                                <td className="flex w-1/4 place-content-center self-center ">
                                  <Button
                                    id="addLdapUserModal-delete-button"
                                    primary
                                    icon="ArrowLeft"
                                    size="small"
                                    onClick={() => onClickDeleteUser(index)}
                                  />
                                </td>
                                <td className="p-1">
                                  <H5
                                    color={'text-black'}
                                    text={fullName}
                                  />
                                  <H6 text={user.email} />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <span className="font-semibold text-gray-500 ">{t('There is no user selected.')}</span>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="addLdapUserModal-submit-button"
              primary
              text={t('Add Selected Users')}
              type="submit"
              icon="Plus"
              disabled={!selectedResults.length > 0}
              loading={addLdapUser?.loading}
            />
            <Button
              id="addLdapUserModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default AddLdapUserModal;
