import React, { useCallback, useRef } from 'react';
import { useDropHook } from './dnd/useDropHook';

const TreeRow = ({ tree, attrs, node, handleRowClick, children }) => {
  const el = useRef(null);
  const dropRef = useDropHook(el, node, tree);

  const innerRef = useCallback(
    (n) => {
      el.current = n;
      dropRef(n);
    },
    [dropRef]
  );

  return (
    <div
      {...attrs}
      ref={innerRef}
      onFocus={(e) => e.stopPropagation()}
      onClick={(e) => handleRowClick(e, node)}
    >
      {children}
    </div>
  );
};

export default TreeRow;
