import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//Api Import
import { useApi } from '../../../hooks/UseApi';
import ProjectConfigApi from '../../../api/ProjectConfigApi';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
//Hook - Context Imports
import { useAppDispatch } from '../../../context/AppContext';
import { saveProjectConfig } from '../../../context/ProjectActions';
import UseQueryProject from '../../../hooks/UseQueryProject';
//Component Imports
import { SidePaneRightContent } from '../../../components/Layout';
import { Button, Heading, Input, Loader } from '../../../designSystem/DesignSystem';
//Helper Import
import { getRunName } from '../../../helpers/ArtifactNameHelper';
import { showErrorMes, showSuccessMes, INFO_MESSAGES } from '../../../helpers/NotificationHelper';
import { isProjectManager } from '../../../helpers/PriviligeHelper';

const TestRunProjectSetting = () => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useForm({ shouldFocusError: true, criteriaMode: 'all' });
  const { projectId, project } = UseQueryProject('projectId');
  const dispatch = useAppDispatch();
  const projectConfig = UseProjectConfig(projectId);

  const title = useMemo(() => getRunName(projectConfig), [projectConfig]);

  const updateProjectConfig = useApi({
    name: 'Updating project config',
    api: ProjectConfigApi.updateProjectConfiguration,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      dispatch(saveProjectConfig(projectId, updateProjectConfig?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const projectConfigLoader = useApi({
    name: 'Get project configs',
    api: ProjectConfigApi.get,
    onSuccess: () => {
      dispatch(saveProjectConfig(projectId, projectConfigLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const handleSubmit = (value, name) => {
    if (name == 'bulkExecutionLimit') {
      value = getValues('bulkExecutionLimit');
      // check if the value is not a number or is less than 0
      if (isNaN(value) || value < 0) {
        return showErrorMes(t('Please enter a valid positive number'));
      }
      if (projectConfig?.configuration?.testRun?.bulkExecutionLimit == getValues('bulkExecutionLimit')) {
        return;
      }
    }

    var body = { id: projectId, key: name, value };
    updateProjectConfig.execute(body);
  };

  useEffect(() => {
    if (projectId) {
      projectConfigLoader.execute({ id: projectId });
    }
  }, [projectId]);

  useEffect(() => {
    setValue('bulkExecutionLimit', projectConfig?.configuration?.testRun?.bulkExecutionLimit);
  }, [projectConfig]);

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading
          type="large"
          text={title}
        />
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Main>
        <SidePaneRightContent.Center colFull={true}>
          {projectConfigLoader?.loading ? (
            <Loader></Loader>
          ) : (
            isProjectManager(projectId) && (
              <>
                <>
                  <div className="mt-4  flex-row  rounded-lg border border-gray-100 bg-white p-6 shadow-lg">
                    <div className="flex flex-col">
                      <div className="-ml-1 mt-4 flex items-center justify-between gap-3">
                        <Input
                          name="bulkExecutionLimit"
                          label={t('Bulk Execution Limit')}
                          control={control}
                          disabled={project?.templateBy}
                          passValue={(value) => setValue('bulkExecutionLimit', value)}
                        />
                        <div className="mt-6">
                          <Button
                            id="requirementProjectSetting-save-button"
                            primary
                            disabled={project?.templateBy}
                            text={t('Save')}
                            size="small"
                            onClick={() => handleSubmit('bulkExecutionLimit', 'bulkExecutionLimit')}
                            loading={updateProjectConfig?.loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </>
            )
          )}
        </SidePaneRightContent.Center>
      </SidePaneRightContent.Main>
    </>
  );
};

export default TestRunProjectSetting;
