import React, { useEffect } from 'react';
import ApplicationApi from '../../../api/ApplicationApi';
import { SidePaneRightContent } from '../../../components/Layout';
import { Heading } from '../../../designSystem/DesignSystem';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import gitInfo from '../../../generatedGitInfo.json';
import { useTranslation } from 'react-i18next';

const VersionPage = () => {
  const { t } = useTranslation();

  const applicationInfoLoader = useApi({
    name: 'Application version info',
    api: ApplicationApi.getApplicationVersion,
    autoLoad: false,
    onError: (err) => {
      showErrorMes(err);
    }
  });

  useEffect(() => {
    applicationInfoLoader.execute();
    console.log('Frontend build: ', gitInfo?.commitId);
  }, []);

  const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

  return (
    <SidePaneRightContent>
      <SidePaneRightContent.Top>
        <Heading.Group type="large" text={t('Versions')}></Heading.Group>
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Center>
        <div className="p-4 w-full">
          <div className="mt-2 relative overflow-x-auto shadow-md sm:rounded-lg">
            {/* TODO: birbirini ezen css classlarına bakılacak */}
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" colSpan={5} className="px-6 py-3">
                    {t('Backend')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b">
                  <th scope="row" colSpan={2} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {t('Version :')}
                  </th>
                  <td colSpan={2} className="px-6 py-4">
                    {applicationInfoLoader?.data?.git?.closest?.tag?.name || '-'}
                  </td>
                </tr>
                {IS_DEV && (
                  <tr className="bg-white border-b">
                    <th scope="row" colSpan={2} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      {t('Build :')}
                    </th>
                    <td colSpan={2} className="px-6 py-4">
                      {applicationInfoLoader?.data?.git?.commit?.id?.full || '-'}
                    </td>
                  </tr>
                )}
              </tbody>
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" colSpan={5} className="px-6 py-3">
                    {t('Frontend')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b">
                  <th scope="row" colSpan={2} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {t('Version :')}
                  </th>
                  <td colSpan={2} className="px-6 py-4">
                    {gitInfo?.version || '-'}
                  </td>
                </tr>
                {IS_DEV && (
                  <tr className="bg-white border-b">
                    <th scope="row" colSpan={2} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      {t('Build :')}
                    </th>
                    <td colSpan={2} className="px-6 py-4">
                      {gitInfo?.commitId || '-'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </SidePaneRightContent.Center>
    </SidePaneRightContent>
  );
};

export default VersionPage;
