import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UserApi from '../../../api/UserApi';
import { Modal } from '../../../components/modal';
import { Body, Heading, Button } from '../../../designSystem/DesignSystem';
import { editModalMapping, isValidValue } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
// Api and Hook Imports
import { useApi } from '../../../hooks/UseApi';
//Body and Heading Css form
const bodyFontSize = 'medium';
const fontSize = 'small';
const divClass = 'flex flex-row items-center mb-2';
const headingClass = 'w-36';
import { useTranslation } from 'react-i18next';

const ProjectUserViewModal = (props) => {
  const { isOpen, selectedItem, closeModal } = props;
  const { setValue, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [dataForm, setDataForm] = useState({});
  const { t } = useTranslation();

  const fetchUser = useApi({
    name: 'fetching user',
    api: UserApi.getUser,
    autoLoad: false,
    onSuccess: () => {
      setDataForm(fetchUser?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const userTypeText = () => {
    if (fetchUser?.data?.ldapUser) return t('LDAP User');
    else return t('System User');
  };

  useEffect(() => {
    if (selectedItem) {
      fetchUser.execute({ id: selectedItem.id });
    }
  }, [selectedItem]);

  useEffect(() => {
    if (isValidValue(dataForm)) {
      let mappedArray = editModalMapping(dataForm);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, [dataForm]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t('User Details')}</Modal.Title>
        <form className="flex flex-grow flex-col gap-4">
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-5">
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('User Name')}
                />
                <Body
                  text={fetchUser?.data?.userName || '-'}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('First Name')}
                />
                <Body
                  text={fetchUser?.data?.firstName || '-'}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Last Name')}
                />
                <Body
                  text={fetchUser?.data?.lastName || '-'}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('E-Mail')}
                />
                <Body
                  text={fetchUser?.data?.email || '-'}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('User Type')}
                />
                <Body
                  text={userTypeText()}
                  type={bodyFontSize}
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="projectUserViewModal-close-button"
              secondary
              text={t('Close')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};
export default ProjectUserViewModal;
