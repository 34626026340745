import React from 'react';
import mergeRefs from './merge-refs';
import useResizeObserver from 'use-resize-observer';

const style = {
  flex: 1,
  width: '100%',
  height: '100%',
  minHeight: 0,
  minWidth: 0
};

export const FillFlexParent = React.forwardRef(function FillFlexParent(props, forwardRef) {
  const { ref, width, height } = useResizeObserver();
  return (
    <div
      style={style}
      ref={mergeRefs(ref, forwardRef)}
    >
      {props.children({ width, height })}
    </div>
  );
});
