import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ChromePicker } from 'react-color';
// CONTEXT IMPORTS
import { useAppDispatch } from '../../../context/AppContext';
import { saveEntitySystemField } from '../../../context/ProjectActions';
// API AND HOOK IMPORTS
import { useApi } from '../../../hooks/UseApi';
import SystemFieldApi from '../../../api/SystemFieldApi';
import { useTranslation } from 'react-i18next';
// DESIGN SYSTEM IMPORTS
import { Input, Button, Toggle, Loader, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
// HELPER IMPORTS
import { capitalize, classNames, isValidValue } from '../../../helpers/Helper';
import { ENTITY_TYPES } from '../../../constants';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { TABLE_ACTION_BUTTON_CONSTANTS } from '../../../helpers/ButtonConstants';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
// COMPONENT IMPORTS
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import SystemFieldEditModal from './SystemFieldEditModal';
import SystemFieldWorkflowSetting from './SystemFieldWorkflowSetting';
import SystemFieldLockActionsModal from './SystemFieldLockActionsModal';
import { translateArrItemName, translateArrItemText } from '../../../helpers/TranslationHelper';

const defaultValues = { enumName: '' };

const SystemFieldModal = (props) => {
  const { isOpen, closeModal, projectId, relatedEntity, relatedField, selectedItem, projectConfig } = props;
  const { control, handleSubmit, reset, setValue } = useForm({ defaultValues });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // DATA STATES
  const [selectedData, setSelectedData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [dataForm, setDataForm] = useState({});
  const [systemFieldOptions, setSystemFieldOptions] = useState([]);
  // PERMISSION
  let [permissionOptions, setPermissionOptions] = useState([]);
  const [permissionFormData, setPermissionFormData] = useState('');
  // MODAL VIS
  const [confirmForVisible, setConfirmForVisible] = useState(false);
  const [confirmForDelete, setConfirmForDelete] = useState(false);
  const [editModalVis, setEditModalVis] = useState(false);
  const [flowModalVis, setFlowModalVis] = useState(false);
  const [lockActionModalVis, setLockActionModalVis] = useState(false);

  const [displayColorPicker, setDisplayColorPicker] = useState({});
  const [addEnumButonDisabled, setAddEnumButtonDisabled] = useState(true);

  const entitySystemFieldsLoader = useApi({
    name: 'Get entity system fields',
    api: SystemFieldApi.fetchEntitySystemFieldsWithPermissions,
    onSuccess: () => {
      setSystemFieldOptions(entitySystemFieldsLoader?.data);
      let tempForm = {};
      entitySystemFieldsLoader?.data.forEach((fieldOptionItem) => {
        let arr = setPermissionData(fieldOptionItem?.permissions);
        tempForm[fieldOptionItem?.id] = arr;
      });
      setPermissionFormData(tempForm);
    },
    onError: (err) => showErrorMes(err)
  });

  const systemFieldConfigs = useApi({
    name: 'Get system field configs',
    api: SystemFieldApi.getSystemFieldConfigs,
    onSuccess: () => getPermissionOptions(systemFieldConfigs?.data),
    onError: (err) => showErrorMes(err)
  });

  const assignPermission = useApi({
    name: 'assign permission',
    api: SystemFieldApi.assignPermission,
    onSuccess: () => showSuccessMes(INFO_MESSAGES.assign),
    onError: (err) => {
      showErrorMes(err);
      getSystemFieldOptions();
    }
  });

  const updateVisible = useApi({
    name: 'update visible',
    api: SystemFieldApi.updateVisible,
    onError: (err) => showErrorMes(err)
  });

  const updateDefault = useApi({
    name: 'update default',
    api: SystemFieldApi.updateDefault,
    onError: (err) => showErrorMes(err)
  });

  const updateColorCode = useApi({
    name: 'update color code',
    api: SystemFieldApi.updateColorCode,
    onError: (err) => showErrorMes(err)
  });

  const deleteSystemFieldOption = useApi({
    name: 'delete system field option',
    api: SystemFieldApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      getSystemFieldOptions();
      setConfirmForDelete(false);
    },
    onError: (err) => {
      showErrorMes(err);
      setConfirmForDelete(false);
    }
  });

  const addSystemFieldOption = useApi({
    name: 'add system field option',
    api: SystemFieldApi.create,
    onSuccess: () => {
      getSystemFieldOptions();
      dataForm.name = '';
      dataForm.value = '';
      setAddEnumButtonDisabled(true);
    },
    onError: (err) => {
      showErrorMes(err);
      setAddEnumButtonDisabled(true);
    }
  });

  const getSystemFieldOptions = () => {
    if (projectId) entitySystemFieldsLoader.execute({ projectId, relatedEntity, relatedField });
  };
  const getPermissionOptions = (permissionItems) => {
    let optionList = [];
    optionList = permissionItems.map((elem) => {
      let id = isValidValue(elem?.itemId) ? elem?.itemId : elem?.id;
      if (isValidValue(id)) {
        return {
          key: id,
          value: id,
          text: elem?.name,
          id: elem?.id,
          itemId: elem?.itemId
        };
      }
    });
    setPermissionOptions(translateArrItemText(optionList, t));
  };

  const setPermissionData = (data) => {
    let arr = [];
    if (data) {
      data.forEach((per) => {
        let id = isValidValue(per?.itemId) ? per.itemId : per?.id;
        if (isValidValue(id)) {
          if (per.type === 'ROLE') {
            let name = permissionOptions.filter((op) => op.key === per.itemId);
            arr.push({ ...per, name: name[0]?.text, value: per?.itemId });
          } else {
            arr.push({ ...per, key: per?.itemId, value: per?.itemId });
          }
        }
      });
    }
    return translateArrItemName(arr, t);
  };

  const updatePermissionData = (value, fieldOption) => {
    let jsonArr = [];
    if (value && value.length > 0) {
      permissionOptions.forEach((permission, index) => {
        value.forEach((item) => {
          if (item.key === permission.key || item === index) jsonArr.push(permission);
        });
      });
    }
    assignPermission.execute({ id: fieldOption.id, jsonArr });
  };

  const updateVisibleData = (data, index, fieldOption) => {
    let newList = [...systemFieldOptions];
    if (fieldOption) {
      newList[index] = {
        ...systemFieldOptions[index],
        visible: data
      };
      setSystemFieldOptions(newList);
      updateVisible.execute({ id: fieldOption.id, checked: data });
    } else {
      newList[index] = {
        ...systemFieldOptions[index],
        visible: !data.visible
      };
      setSystemFieldOptions(newList);
      updateVisible.execute({ id: data.id, checked: !data.visible });
      setConfirmForVisible(false);
    }
  };

  const updateIsDefault = (data, index, fieldOption) => {
    const newList = [];

    systemFieldOptions.forEach((option, i) => {
      newList.push({
        ...option,
        isDefault: i === index ? data : false
      });
    });

    setSystemFieldOptions(newList);
    if (data) {
      const otherData = newList.filter((option, i) => i !== index);
      otherData.forEach((option) => {
        updateDefault.execute({ id: option.id, checked: false });
      });
    }
    updateDefault.execute({ id: fieldOption.id, checked: data });
  };
  const updateColorChange = (color, data, index) => {
    let newList = [...systemFieldOptions];
    newList[index] = {
      ...systemFieldOptions[index],
      colorCode: color.hex
    };
    setSystemFieldOptions(newList);
    updateColorCode.execute({ id: data.id, colorCode: color.hex });
  };

  const addEnum = () => {
    dataForm.projectId = projectId;
    dataForm.relatedEntity = relatedEntity;
    dataForm.relatedField = relatedField;
    dataForm.isSystemField = false;
    addSystemFieldOption.execute(dataForm);
    reset(defaultValues);
  };

  const handleColorClick = (fieldOption) => {
    setDisplayColorPicker({
      visible: true,
      id: fieldOption.id
    });
  };

  const handleColorClose = () => setDisplayColorPicker(null);

  const showColorColumn = () => {
    return (
      (relatedEntity === ENTITY_TYPES.DEFECT ||
        relatedEntity === ENTITY_TYPES.TEST_CYCLE ||
        relatedEntity === ENTITY_TYPES.RELEASE ||
        relatedEntity === ENTITY_TYPES.REQUIREMENT) &&
      relatedField === 'status'
    );
  };

  const handleChangeEnum = (value) => {
    setDataForm((prevState) => ({
      ...prevState,
      name: value
    }));
    if (isValidValue(value)) {
      setAddEnumButtonDisabled(false);
    } else {
      setAddEnumButtonDisabled(true);
    }
  };

  useEffect(() => {
    systemFieldConfigs.execute({ projectId, relatedEntity });
    getSystemFieldOptions();
  }, [projectId]);

  useEffect(() => {
    dispatch(saveEntitySystemField(projectId, relatedEntity, relatedField, systemFieldOptions));
  }, [systemFieldOptions]);
  let systemFieldTranslatedOption = translateArrItemName(systemFieldOptions, t);
  return (
    <>
      <Modal
        open={isOpen && !editModalVis}
        close={() => closeModal}
        w={'lg'}
      >
        <Modal.Title>
          {t(getArtifactName(projectConfig, relatedEntity)) + ' - ' + t(capitalize(selectedItem?.fieldName))}
        </Modal.Title>
        <div>
          <Modal.Content h="lg">
            {entitySystemFieldsLoader.loading ? (
              <Loader />
            ) : (
              <div className="flex flex-grow flex-col">
                {relatedField === 'status' && (
                  <div className="flex flex-row-reverse">
                    {relatedEntity != 'project' && (
                      <div className=" p-2 ">
                        <Button
                          id="systemFieldModal-manageAction-button"
                          secondary
                          text={t('Manage Actions')}
                          type="button"
                          icon="ToggleRight"
                          onClick={() => {
                            setLockActionModalVis(true);
                          }}
                        />
                      </div>
                    )}
                    {(relatedEntity === ENTITY_TYPES.DEFECT || relatedEntity === ENTITY_TYPES.TEST_CYCLE) && (
                      <div className="flex flex-grow p-2 ">
                        <div className="flex flex-grow justify-end">
                          <Button
                            id="systemFieldModal-configureWorkflow-button"
                            secondary
                            text={t('Configure Workflow')}
                            type="button"
                            icon="Gear"
                            onClick={() => {
                              setFlowModalVis(true);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="flex flex-grow rounded-md border border-gray-200 shadow ">
                  <table className="flex flex-grow flex-col">
                    <thead className="flex-grow bg-gray-50">
                      <tr className="flex flex-grow">
                        <th
                          className={`group w-2/12 px-6 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                        >
                          {t('Name')}
                        </th>
                        <th
                          className={`group w-6/12 px-6 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                        >
                          {t('Permission')}
                        </th>
                        <th
                          className={`group w-1/12 px-3 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                        >
                          {t('Show/Hide')}
                        </th>
                        <th
                          className={`group w-1/12 px-3 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                        >
                          {t('Default')}
                        </th>
                        {showColorColumn() && (
                          <th
                            className={`group w-2/12 px-6 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                          >
                            {t('Color')}
                          </th>
                        )}
                        <th
                          className={`group w-1/12 px-6 py-3 text-center text-xs font-medium capitalize tracking-wide text-gray-500`}
                        ></th>
                        <th
                          className={`group w-1/12 px-6 py-3 text-center text-xs font-medium capitalize tracking-wide text-gray-500`}
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="max-h-[400px] divide-y divide-gray-200 overflow-y-auto bg-white">
                      {systemFieldTranslatedOption?.map((fieldOption, index) => {
                        return (
                          <tr
                            key={index}
                            className="flex "
                          >
                            <td className="m-2.5 w-2/12 place-self-center break-words px-3 py-3 text-sm">
                              {fieldOption?.name}
                            </td>
                            <td className="w-6/12 place-self-center px-3  py-3">
                              <MultiSelectDropdownPill
                                control={control}
                                name={fieldOption?.id}
                                options={permissionOptions}
                                value={permissionFormData[fieldOption?.id]}
                                passValue={(value) => updatePermissionData(value, fieldOption)}
                              />
                            </td>
                            <td className="w-1/12 place-self-center px-3 py-3">
                              <Toggle
                                control={control}
                                value={fieldOption?.visible}
                                name={fieldOption?.id}
                                disabled={isValidValue(fieldOption?.templateBy)}
                                passValue={(value) => {
                                  if (
                                    relatedField === 'status' &&
                                    (relatedEntity === ENTITY_TYPES.DEFECT || relatedEntity === ENTITY_TYPES.TEST_CYCLE)
                                  ) {
                                    setSelectedData(fieldOption);
                                    setSelectedIndex(index);
                                    setConfirmForVisible(true);
                                  } else {
                                    updateVisibleData(value, index, fieldOption);
                                  }
                                }}
                              />
                            </td>
                            <td className="w-1/12 place-self-center px-3 py-3">
                              <Toggle
                                control={control}
                                disabled={isValidValue(fieldOption?.templateBy)}
                                value={fieldOption?.isDefault}
                                name={fieldOption?.id}
                                passValue={(value) => updateIsDefault(value, index, fieldOption)}
                              />
                            </td>
                            {showColorColumn() && (
                              <td className="w-2/12 place-self-center px-3 py-3">
                                <div>
                                  <div
                                    className={classNames(
                                      'inline-block cursor-pointer rounded border	 p-2 shadow',
                                      isValidValue(fieldOption?.templateBy) ? 'bg-gray-100' : 'bg-white'
                                    )}
                                    onClick={() => {
                                      handleColorClick(fieldOption);
                                    }}
                                  >
                                    <div
                                      className="h-4 w-24 rounded-md "
                                      style={{ background: fieldOption?.colorCode }}
                                    />
                                  </div>
                                  {displayColorPicker?.visible && displayColorPicker?.id === fieldOption?.id ? (
                                    <div className="absolute z-10">
                                      <div
                                        className="fixed	inset-0"
                                        onClick={() => {
                                          handleColorClose();
                                        }}
                                      />
                                      {!isValidValue(fieldOption?.templateBy) && (
                                        <ChromePicker
                                          color={
                                            isValidValue(fieldOption?.colorCode) ? fieldOption.colorCode : '#FFFFFF'
                                          }
                                          onChange={(color) => updateColorChange(color, fieldOption, index)}
                                        />
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                            )}
                            <td className="w-1/12 place-self-center px-3  py-3">
                              <div>
                                <Button
                                  disabled={fieldOption?.isSystemField || isValidValue(fieldOption?.templateBy)}
                                  onClick={() => {
                                    setEditModalVis(true);
                                    setSelectedData(fieldOption);
                                  }}
                                  color={TABLE_ACTION_BUTTON_CONSTANTS.editColor}
                                  icon={TABLE_ACTION_BUTTON_CONSTANTS.editIcon}
                                  weight={TABLE_ACTION_BUTTON_CONSTANTS.weight}
                                />
                              </div>
                            </td>
                            <td className="w-1/12 place-self-center px-3  py-3">
                              <div>
                                <Button
                                  disabled={fieldOption?.isSystemField || isValidValue(fieldOption?.templateBy)}
                                  onClick={() => {
                                    setSelectedData(fieldOption);
                                    setConfirmForDelete(true);
                                  }}
                                  color={TABLE_ACTION_BUTTON_CONSTANTS.deleteColor}
                                  icon={TABLE_ACTION_BUTTON_CONSTANTS.deleteIcon}
                                  weight={TABLE_ACTION_BUTTON_CONSTANTS.weight}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <form
                  className="flex flex-grow items-center gap-1 bg-gray-50 p-2 shadow"
                  onSubmit={handleSubmit(addEnum)}
                >
                  <div className="flex-grow flex-col gap-8">
                    <Input
                      name="enumName"
                      placeholder={t(`New Option`)}
                      control={control}
                      passValue={(value) => handleChangeEnum(value)}
                    />
                  </div>
                  <div className="pt-2">
                    <Button
                      id="systemFieldModal-add-button"
                      primary
                      type="submit"
                      icon="Plus"
                      disabled={addEnumButonDisabled}
                      loading={addSystemFieldOption?.loading}
                    />
                  </div>
                </form>
              </div>
            )}
          </Modal.Content>
        </div>
        <Modal.Buttons>
          <Button
            id="systemFieldModal-close-button"
            primary
            text={t('Close')}
            type="button"
            onClick={() => {
              closeModal();
            }}
          />
        </Modal.Buttons>
      </Modal>
      {confirmForVisible && (
        <ConfirmationModal
          isOpen={confirmForVisible}
          loading={updateVisible?.loading}
          closeModal={() => {
            setValue(systemFieldOptions[selectedIndex].id, systemFieldOptions[selectedIndex].visible);
            setConfirmForVisible(false);
          }}
          handleConfirm={() => updateVisibleData(selectedData, selectedIndex)}
          header={t('Change Field Visibility')}
          content={t(
            'If the visibility of this field is changed, the workflow will be affected. Are you sure you want to change visibility?'
          )}
        />
      )}
      {confirmForDelete && (
        <ConfirmationModal
          isOpen={confirmForDelete}
          loading={deleteSystemFieldOption?.loading}
          closeModal={() => setConfirmForDelete(false)}
          handleConfirm={() => deleteSystemFieldOption.execute({ id: selectedData.id })}
          header={t('Remove this system field option from Project')}
          content={t('Are you sure to remove this system field option from project?')}
        />
      )}
      {editModalVis && (
        <SystemFieldEditModal
          isOpen={editModalVis}
          closeModal={() => {
            setEditModalVis(false);
            getSystemFieldOptions();
          }}
          data={selectedData}
        />
      )}
      {flowModalVis && (
        <SystemFieldWorkflowSetting
          isOpen={flowModalVis}
          closeModal={() => setFlowModalVis(false)}
          relatedEntity={relatedEntity}
          relatedField={relatedField}
          projectConfig={projectConfig}
          systemFieldOptions={systemFieldOptions}
          setSystemFieldOptions={setSystemFieldOptions}
        />
      )}
      {lockActionModalVis && (
        <SystemFieldLockActionsModal
          isOpen={lockActionModalVis}
          closeModal={() => setLockActionModalVis(false)}
          relatedEntity={relatedEntity}
          relatedField={relatedField}
          projectConfig={projectConfig}
          systemFieldOptions={systemFieldOptions}
          setSystemFieldOptions={setSystemFieldOptions}
        />
      )}
    </>
  );
};

export default SystemFieldModal;
