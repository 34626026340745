import React, { useState } from 'react';
// Component imports
import SettingsMenuItem2 from './SettingsMenuItem2';

const SettingsMenu = ({ data, project }) => {
  const [isSubNavOpen, setIsSubNavOpen] = useState('configuration');
  //TODO düzeltilecek

  return (
    <div className="treeview-container">
      {data?.map((item, idx) => {
        return (
          <div
            key={idx}
            className="mb-1"
          >
            <SettingsMenuItem2
              active={isSubNavOpen}
              setActive={setIsSubNavOpen}
              item={item}
              key={idx}
              projectId={project?.id}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SettingsMenu;
