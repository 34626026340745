import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircleWavyWarning } from 'phosphor-react';
import moment from 'moment';
// API && HOOKS
import { useApi } from '../../../hooks/UseApi';
import TestRunApi from '../../../api/TestRunApi';
import UseCustomField from '../../../hooks/UseCustomField';
import UseProjectUsers from '../../../hooks/UseProjectUsers';
import HistoryApi from '../../../api/HistoryApi';
// COPONENTS
import QuickTestRunModal from './QuickTestRunModal';
import TestRunExecutionModal from './TestRunExecutionModal';
import CustomEntityDetail from '../../../components/detail/CustomEntityDetail';
// DESIGN SYSTEM
import { Button } from '../../../designSystem/DesignSystem';
// HELPERS  && CONSTANTS
import { ENTITY_TYPES, PRIVILEGES } from '../../../constants';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { getNewUrlModalPath } from '../../../helpers/Helper';
import { hasPrivilege, isOwner, isSystemAdmin } from '../../../helpers/PriviligeHelper';

const TestRunDetailsModal = ({ runId, projectId, close }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname: path } = useLocation();

  const customFields = UseCustomField(projectId, ENTITY_TYPES.TEST_RUN);
  const users = UseProjectUsers(projectId);

  const [error, setError] = useState(null);
  const [quickExecutionModal, setQuickExecutionModal] = useState(false);
  const [executionModal, setExecutionModal] = useState(false);
  const [dataForm, setDataForm] = useState(null);

  const getTestRun = useApi({
    name: 'fetching test run',
    api: TestRunApi.getOne,
    autoLoad: false,
    onSuccess: () => {
      // backend holds the date in UTC format, so we need to convert it to local time when get quick execution results
      if (
        getTestRun?.data?.lastTestRunExecution?.quickRunExecution &&
        getTestRun?.data?.lastTestRunExecution?.executionEndTime
      ) {
        const executionEndTime = getTestRun.data.lastTestRunExecution.executionEndTime;
        const localEndDate = moment.utc(executionEndTime).local().format('YYYY-MM-DDTHH:mm:ss');
        getTestRun.data.lastTestRunExecution.executionEndTime = localEndDate;
      }

      setDataForm(getTestRun.data);
      let entityId = getTestRun?.data?.testCase.id;
      historyLoader.execute({ projectId, entityId: entityId, entityType: ENTITY_TYPES.TEST_CASE });
    },
    onError: (err) => {
      showErrorMes(err);
      setError(err.response.data);
    }
  });

  // get modified_by and modified_time correct values
  const historyLoader = useApi({
    name: 'get history for modifed infos',
    api: HistoryApi.get,
    autoLoad: false,
    onSuccess: () => {
      setDataForm((prevState) => ({
        ...prevState,
        modifiedBy: historyLoader?.data[0].modifiedBy,
        modifiedTime: historyLoader?.data[0].modifiedTime
      }));
    },
    onError: (err) => showErrorMes(err)
  });

  const refreshData = () => getTestRun.execute(runId);

  const afterClone = (data) => navigate(getNewUrlModalPath(projectId, path, data, ENTITY_TYPES.TEST_RUN));

  const buttonDisabledControl = () => {
    if (isSystemAdmin() || (hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_EXECUTION) && isOwner(dataForm.tester.id))) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    refreshData();
  }, [runId]);

  if (error)
    return (
      <div className="my-8 flex flex-col items-center justify-center text-xl text-gray-300">
        <CircleWavyWarning
          size={48}
          weight="duotone"
          color="#6B7280"
        />
        <span className="font-semibold text-gray-500 ">{error?.message}</span>
      </div>
    );

  const extraButtons = () => {
    return (
      <>
        <Button
          id="testRunDetailsModal-executeTestRun-button"
          secondary
          text={t('Execute Test Run')}
          size="small"
          icon="Play"
          disabled={buttonDisabledControl()}
          onClick={() => setExecutionModal(true)}
        />
        <Button
          id="testRunDetailsModal-quickExecution-button"
          secondary
          text={t('Quick Execution')}
          size="small"
          icon="Play"
          disabled={buttonDisabledControl()}
          onClick={() => setQuickExecutionModal(true)}
        />
      </>
    );
  };

  return (
    <>
      <CustomEntityDetail
        config={{
          entityId: runId,
          entityType: ENTITY_TYPES.TEST_RUN,
          users,
          loading: getTestRun?.loading,
          dataForm: dataForm,
          submitCallBack: refreshData,
          submitCallBackAfterClone: afterClone,
          customFields,
          extraButtons,
          closeModal: close,
          projectId
        }}
      />
      {quickExecutionModal && (
        <QuickTestRunModal
          isOpen={quickExecutionModal}
          preconditions={dataForm?.testCase?.preconditions}
          close={() => {
            setQuickExecutionModal(false);
            refreshData();
          }}
          runId={runId}
        />
      )}

      {executionModal && (
        <TestRunExecutionModal
          preconditions={dataForm?.testCase?.preconditions}
          isOpen={executionModal}
          users={users}
          close={() => {
            setExecutionModal(false);
            refreshData();
          }}
          projectId={projectId}
          runId={runId}
        />
      )}
    </>
  );
};

export default TestRunDetailsModal;
