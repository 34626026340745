import React, { useState, useEffect, useId } from 'react';
import TestRunApi from '../../api/TestRunApi';
import { Body, Heading, Loader } from '../../designSystem/DesignSystem';
import { classNames } from '../../helpers/Helper';
import { useApi } from '../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import UseRunStatuses from '../../hooks/UseRunStatuses';

const TestRunStatusCount = ({ projectId, filter, setFilter, refreshStatus, setRefreshStatus, setRefreshTable }) => {
  const [testRunStatus, setTestRunStatus] = useState([]);
  const runStatuses = UseRunStatuses();
  const id = useId();
  const { t } = useTranslation();
  const { status } = filter || {};

  const testRunStatusLoader = useApi({
    name: 'fetching test run counts',
    api: TestRunApi.getTestRunStatusCount,
    autoLoad: false,
    onSuccess: () => setTestRunStatus(testRunStatusLoader?.data)
  });

  const getTestRunStatusCount = () => testRunStatusLoader.execute({ projectId });

  const handleStatusFilterChange = (newStatus) => {
    if (newStatus !== 'TOTAL') {
      const selectedStatus = Object.keys(runStatuses).find((key) => runStatuses[key] === newStatus);
      const newFilter = { ...filter };

      if (status === selectedStatus) delete newFilter.status;
      else newFilter.status = selectedStatus;

      setFilter(newFilter);
    } else {
      const newFilter = { ...filter };
      delete newFilter.status;
      setFilter(newFilter);
    }
    setRefreshTable(true);
  };

  useEffect(() => {
    getTestRunStatusCount();
  }, [projectId]);

  useEffect(() => {
    if (refreshStatus === true) {
      getTestRunStatusCount();
      if (setRefreshStatus) setRefreshStatus(false);
    }
  }, [refreshStatus]);

  return (
    <>
      {testRunStatusLoader?.loading ? (
        <Loader customText={t('Loading status counts...')} />
      ) : (
        <div className=" mt-1 flex flex-wrap justify-between rounded-lg p-1 pl-10 pr-10 text-sm shadow-md shadow-violet-100">
          {(testRunStatus || []).map((item, index) => {
            const key = Object.keys(runStatuses).find((key) => runStatuses?.[key] === item?.status);
            return (
              <div
                key={`${id}-${index}`}
                className={classNames(
                  'cursor-pointer p-1',
                  runStatuses && key == status && 'border-b-2 border-primary-800'
                )}
                onClick={() => handleStatusFilterChange(item?.status)}
              >
                <div className="flex justify-center">
                  <Heading
                    type="small"
                    text={item?.count}
                  />
                </div>
                <Body text={t(item?.status)} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TestRunStatusCount;
