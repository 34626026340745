// TODO: importlar düzenlenecek.
import React, { useEffect, useState } from 'react';
import { ENTITY_TYPES, PRIVILEGES, TREENODE_ROOT } from '../../constants';
import { getArtifactName, getReleaseName } from '../../helpers/ArtifactNameHelper';
import { hasPrivilege, isExportPrivileged, isImportPrivileged } from '../../helpers/PriviligeHelper';
import UseQueryProject from '../../hooks/UseQueryProject';
import ReleaseModal from '../../pages/release/modal/ReleaseModal';
import FolderModal from '../../pages/requirement/modal/FolderModal';
import RequirementModal from '../../pages/requirement/modal/RequirementModal';
import TestCaseModal from '../../pages/testCase/modal/TestCaseModal';
import TestContainerModal from '../../pages/testCase/modal/TestContainerModal';
import TestCycleModal from '../../pages/testPlanning/testCycle/TestCycleModal';
import TestFolderModal from '../../pages/testPlanning/testFolder/TestFolderModal';
import TestSuiteModal from '../../pages/testPlanning/testSuite/TestSuiteModal';
import ExportFileModal from '../file/ExportFileModal';
import ImportFileModal from '../file/ImportFileModal';
import { Button } from '../../designSystem/DesignSystem';
import { SidePaneLeftContent } from '../Layout';
import { useTranslation } from 'react-i18next';
import { truncateString } from '../../helpers/Helper';
import UseJiraConfig from '../../hooks/UseJiraConfig';
import { useApi } from '../../hooks/UseApi';
import RequirementApi from '../../api/RequirementApi';
import TestCaseApi from '../../api/TestCaseApi';

export const LEFT_TREE_BUTTON_SIZE = 'xsmall';

const PageLeftTreeButton = (props) => {
  const { t } = useTranslation();
  const { pageKey, submitCallBack, afterCreate, importEntityType, exportEntityType, selectedItem, projectConfig } =
    props;

  const { projectId } = UseQueryProject('projectId');
  const jiraConfig = UseJiraConfig(projectId);

  const [selectedRequirement, setSelectedRequirement] = useState();
  const [selectedTestCase, setSelectedTestCase] = useState();

  // MODAL VISIBILITY STATES
  const [releaseModalVis, setReleaseModalVis] = useState(false);
  const [requirementModalVis, setRequirementModalVis] = useState(false);
  const [folderModalVis, setFolderModalVis] = useState(false);
  const [exportModalVis, setExportModalVis] = useState(false);
  const [importModalVis, setImportModalVis] = useState(false);
  const [testContainerModalVis, setTestContainerModalVis] = useState(false);
  const [testCaseModalVis, setTestCaseModalVis] = useState(false);
  const [testFolderModalVis, setTestFolderModalVis] = useState(false);
  const [testCycleModalVis, setTestCycleModalVis] = useState(false);
  const [testSuiteModalVis, setTestSuiteModalVis] = useState(false);

  const getSelectedRequirement = useApi({
    name: 'Get Selected Requirement',
    api: RequirementApi.get,
    onSuccess: () => {
      setSelectedRequirement(getSelectedRequirement?.data);
    }
  });

  const getSelectedTestCase = useApi({
    name: 'Get Selected Test Case',
    api: TestCaseApi.getTestCase,
    onSuccess: () => {
      setSelectedTestCase(getSelectedTestCase?.data);
    }
  });

  useEffect(() => {
    if (selectedItem?.type === ENTITY_TYPES.REQUIREMENT) {
      getSelectedRequirement.execute({ id: selectedItem?.id });
    } else if (selectedItem?.type === ENTITY_TYPES.TEST_CASE) {
      getSelectedTestCase.execute({ id: selectedItem?.id });
    }
  }, [selectedItem]);

  return (
    <>
      <SidePaneLeftContent.Buttons>
        {pageKey === ENTITY_TYPES.RELEASE && hasPrivilege(projectId, PRIVILEGES.CREATE_RELEASE) && (
          <Button
            id="release-add-button"
            primary
            text={truncateString(getReleaseName(projectConfig), 10)}
            tooltip={getReleaseName(projectConfig).length > 10 ? getReleaseName(projectConfig) : false}
            icon="Plus"
            onClick={() => setReleaseModalVis(true)}
            size={LEFT_TREE_BUTTON_SIZE}
          />
        )}
        {pageKey === ENTITY_TYPES.REQUIREMENT && (
          <>
            {hasPrivilege(projectId, PRIVILEGES.CREATE_FOLDER) && (
              <Button
                id="folder-add-button"
                primary
                text={truncateString(getArtifactName(projectConfig, ENTITY_TYPES.FOLDER), 10)}
                tooltip={
                  getArtifactName(projectConfig, ENTITY_TYPES.FOLDER).length > 10
                    ? getArtifactName(projectConfig, ENTITY_TYPES.FOLDER)
                    : false
                }
                icon="Plus"
                onClick={() => setFolderModalVis(true)}
                disabled={selectedItem?.type === ENTITY_TYPES.REQUIREMENT}
                size={LEFT_TREE_BUTTON_SIZE}
              />
            )}
            {hasPrivilege(projectId, PRIVILEGES.CREATE_REQUIREMENT) && (
              <Button
                id="requirement-add-button"
                secondary
                text={truncateString(getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT), 11)}
                tooltip={
                  getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT).length > 11
                    ? getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT)
                    : false
                }
                icon="Plus"
                onClick={() => setRequirementModalVis(true)}
                disabled={
                  selectedItem?.type === TREENODE_ROOT?.type ||
                  selectedItem === null ||
                  selectedItem === undefined ||
                  selectedItem?.type === null ||
                  selectedItem?.id === null
                }
                size={LEFT_TREE_BUTTON_SIZE}
              />
            )}
          </>
        )}
        {pageKey === ENTITY_TYPES.TEST_CASE && (
          <>
            {hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_CONTAINER) && (
              <div className="break-words ">
                <Button
                  id="testContainer-add-button"
                  primary
                  text={truncateString(getArtifactName(projectConfig, ENTITY_TYPES.TEST_CONTAINER), 10)}
                  tooltip={
                    getArtifactName(projectConfig, ENTITY_TYPES.TEST_CONTAINER).length > 10
                      ? getArtifactName(projectConfig, ENTITY_TYPES.TEST_CONTAINER)
                      : false
                  }
                  icon="Plus"
                  onClick={() => setTestContainerModalVis(true)}
                  disabled={selectedItem?.type === ENTITY_TYPES.TEST_CASE}
                  size={LEFT_TREE_BUTTON_SIZE}
                />
              </div>
            )}
            {hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_CASE) && (
              <Button
                id="testCase-add-button"
                secondary
                text={truncateString(getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE), 10)}
                tooltip={
                  getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE).length > 10
                    ? getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE)
                    : false
                }
                icon="Plus"
                onClick={() => setTestCaseModalVis(true)}
                disabled={
                  selectedItem?.type === TREENODE_ROOT?.type ||
                  selectedItem === undefined ||
                  selectedItem === null ||
                  selectedItem?.type === null ||
                  selectedItem?.id === null
                }
                size={LEFT_TREE_BUTTON_SIZE}
              />
            )}
          </>
        )}
        {pageKey === ENTITY_TYPES.TEST_FOLDER && (
          <>
            {hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_FOLDER) && (
              <Button
                id="testFolder-add-button"
                primary
                text={truncateString(getArtifactName(projectConfig, ENTITY_TYPES.TEST_FOLDER), 11)}
                tooltip={
                  getArtifactName(projectConfig, ENTITY_TYPES.TEST_FOLDER).length > 11
                    ? getArtifactName(projectConfig, ENTITY_TYPES.TEST_FOLDER)
                    : false
                }
                icon="Plus"
                onClick={() => setTestFolderModalVis(true)}
                disabled={
                  selectedItem?.type === ENTITY_TYPES.TEST_CYCLE ||
                  selectedItem?.type === ENTITY_TYPES.TEST_SUITE ||
                  selectedItem?.type === ENTITY_TYPES.RELEASE
                }
                size={LEFT_TREE_BUTTON_SIZE}
              />
            )}
            {hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_CYCLE) && (
              <Button
                id="testCycle-add-button"
                secondary
                text={truncateString(getArtifactName(projectConfig, ENTITY_TYPES.TEST_CYCLE), 10)}
                tooltip={
                  getArtifactName(projectConfig, ENTITY_TYPES.TEST_CYCLE).length > 10
                    ? getArtifactName(projectConfig, ENTITY_TYPES.TEST_CYCLE)
                    : false
                }
                icon="Plus"
                onClick={() => setTestCycleModalVis(true)}
                disabled={
                  selectedItem?.type === ENTITY_TYPES.TEST_SUITE ||
                  selectedItem?.id === null ||
                  selectedItem?.type === null
                }
                size={LEFT_TREE_BUTTON_SIZE}
              />
            )}
            {hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_SUITE) && (
              <Button
                id="testSuite-add-button"
                secondary
                text={truncateString(getArtifactName(projectConfig, ENTITY_TYPES.TEST_SUITE), 10)}
                tooltip={
                  getArtifactName(projectConfig, ENTITY_TYPES.TEST_SUITE).length > 10
                    ? getArtifactName(projectConfig, ENTITY_TYPES.TEST_SUITE)
                    : false
                }
                icon="Plus"
                onClick={() => setTestSuiteModalVis(true)}
                disabled={
                  selectedItem === null ||
                  selectedItem?.type === null ||
                  selectedItem?.id === null ||
                  selectedItem?.type === ENTITY_TYPES.TEST_FOLDER ||
                  selectedItem?.type === TREENODE_ROOT?.type
                }
                size={LEFT_TREE_BUTTON_SIZE}
              />
            )}
          </>
        )}
        {(pageKey === ENTITY_TYPES.REQUIREMENT || pageKey === ENTITY_TYPES.TEST_CASE) && (
          <>
            {isImportPrivileged(pageKey, projectId) && (
              <Button
                id="common-import-button"
                secondary
                tooltip={t('Import')}
                icon="DownloadSimple"
                onClick={() => setImportModalVis(true)}
                disabled={selectedItem?.type !== TREENODE_ROOT?.type}
                compact
                size={LEFT_TREE_BUTTON_SIZE}
              />
            )}
            {isExportPrivileged(pageKey, projectId) && (
              <Button
                id="common-export-button"
                secondary
                tooltip={t('Export')}
                icon="UploadSimple"
                onClick={() => setExportModalVis(true)}
                disabled={selectedItem?.type !== TREENODE_ROOT?.type}
                compact
                size={LEFT_TREE_BUTTON_SIZE}
              />
            )}
          </>
        )}
      </SidePaneLeftContent.Buttons>
      {releaseModalVis && (
        <ReleaseModal
          isOpen={releaseModalVis}
          closeModal={() => setReleaseModalVis(false)}
          submitCallBack={submitCallBack}
        />
      )}
      {requirementModalVis && (
        <RequirementModal
          jiraConfig={jiraConfig}
          isOpen={requirementModalVis}
          closeModal={(form) => {
            afterCreate(ENTITY_TYPES.REQUIREMENT, form);
            setRequirementModalVis(false);
          }}
          parentId={selectedItem.type === ENTITY_TYPES.FOLDER ? selectedItem?.id : selectedRequirement?.parent?.id}
          projectConfig={projectConfig}
        />
      )}
      {folderModalVis && (
        <FolderModal
          isOpen={folderModalVis}
          closeModal={(form) => {
            afterCreate(ENTITY_TYPES.FOLDER, form);
            setFolderModalVis(false);
          }}
          {...(selectedItem?.id !== TREENODE_ROOT.id && { parentId: selectedItem?.id })}
        />
      )}
      {(pageKey === ENTITY_TYPES.REQUIREMENT || pageKey === ENTITY_TYPES.TEST_CASE) && (
        <>
          {exportModalVis && (
            <ExportFileModal
              isOpen={exportModalVis}
              closeModal={() => setExportModalVis(false)}
              entityType={exportEntityType}
              projectId={projectId}
              filters={{
                includeExecutionStats: true,
                includeReleases: true,
                includeCustomFields: true
              }}
            />
          )}
          {importModalVis && (
            <ImportFileModal
              isOpen={importModalVis}
              closeModal={() => setImportModalVis(false)}
              entityType={importEntityType}
              projectId={projectId}
            />
          )}
        </>
      )}
      {testCaseModalVis && (
        <TestCaseModal
          isOpen={testCaseModalVis}
          closeModal={(form) => {
            afterCreate(ENTITY_TYPES.TEST_CASE, form);
            setTestCaseModalVis(false);
          }}
          parentId={
            selectedItem?.type === ENTITY_TYPES.TEST_CONTAINER ? selectedItem?.id : selectedTestCase?.parent?.id
          }
        />
      )}
      {testContainerModalVis && (
        <TestContainerModal
          isOpen={testContainerModalVis}
          closeModal={(form) => {
            afterCreate(ENTITY_TYPES.TEST_CONTAINER, form);
            setTestContainerModalVis(false);
          }}
          {...(selectedItem?.id !== TREENODE_ROOT.id && { parentId: selectedItem?.id })}
        />
      )}
      {testFolderModalVis && (
        <TestFolderModal
          isOpen={testFolderModalVis}
          closeModal={(form) => {
            afterCreate(ENTITY_TYPES.TEST_FOLDER, form);
            setTestFolderModalVis(false);
          }}
          parentId={selectedItem?.id}
        />
      )}
      {testCycleModalVis && (
        <TestCycleModal
          isOpen={testCycleModalVis}
          closeModal={(form) => {
            afterCreate(ENTITY_TYPES.TEST_CYCLE, form);
            setTestCycleModalVis(false);
          }}
          {...(selectedItem?.type === ENTITY_TYPES.TEST_CYCLE && { parentId: selectedItem?.id })}
          {...(selectedItem?.type === ENTITY_TYPES.TEST_FOLDER && { testFolderId: selectedItem?.id })}
        />
      )}
      {testSuiteModalVis && (
        <TestSuiteModal
          isOpen={testSuiteModalVis}
          closeModal={(form) => {
            afterCreate(ENTITY_TYPES.TEST_SUITE, form);
            setTestSuiteModalVis(false);
          }}
          {...(selectedItem?.type === ENTITY_TYPES.TEST_CYCLE && { testCycleId: selectedItem?.id })}
          {...(selectedItem?.type === ENTITY_TYPES.TEST_SUITE && { parentId: selectedItem?.id })}
        />
      )}
    </>
  );
};

export default PageLeftTreeButton;
