import React from 'react';
import { ENTITY_TYPES } from '../../constants';
import { isValidList, isValidValue, sortArr } from '../Helper';
import i18next from 'i18next';

export const JIRA_FILTER_BUTTON_NAME = i18next.t('JIRA Filter & Retrive');

export const getProjectIssueTypeList = (issueList) => {
  if (isValidList(issueList)) {
    let arr = [];
    issueList.map((x) => {
      arr.push({ name: x?.name, id: x?.id });
    });
    return arr.sort(sortArr('name', 'string'));
  }
  return [];
};

export const findProject = (jiraProjects, jiraProjectId) => {
  if (jiraProjectId && isValidList(jiraProjects))
    return jiraProjects.find((x) => x?.id === jiraProjectId || x?.key === jiraProjectId);
  return null;
};

export const findIssueTypeById = (issuetypes, jiraIssueTypeId) => {
  if (jiraIssueTypeId && isValidList(issuetypes)) return issuetypes.find((x) => x.id === jiraIssueTypeId);
  return null;
};

export const findIssueTypesInConfigByProject = (configProjects, jiraProjectId) => {
  let typeList = [];
  if (jiraProjectId && isValidList(configProjects)) {
    configProjects.map((item) => {
      if (item?.jiraProject?.id === jiraProjectId && item?.issuetypes && item?.jiraIssueType?.id) {
        let issueType = findIssueTypeById(item.issuetypes, item.jiraIssueType.id);
        if (issueType !== null) {
          typeList.push({
            key: item.jiraIssueType.id,
            value: item.jiraIssueType.id,
            text: issueType.name,
            name: issueType.name
          });
        }
      }
    });
  }
  return typeList;
};

export const getSystemFieldOptions = (entityEnums, keyIsName) => {
  if (entityEnums === undefined || entityEnums === null || entityEnums.length === 0) return [];
  let options = [];
  entityEnums.map((item) => {
    options.push({
      id: keyIsName === true ? item.name : item.id,
      name: item.name
    });
  });
  return options;
};

export const getJiraProjectDropdownList = (arrObject) => {
  if (arrObject != null && arrObject.length !== 0) {
    let list = [];
    if (isValidList(arrObject)) {
      list = arrObject.map((item) => {
        return {
          id: item?.id,
          name: item?.name + ' (' + item?.key + ')'
        };
      });
    }
    return list;
  }
  return [];
};

export const getStatusOptions = (statusList) => {
  return getOptionListByIdName(statusList);
};

export const getIssuelinkKey = (json, withSummary) => {
  if (json) {
    let key = '';

    if (json?.outwardIssue?.key) {
      if (withSummary) {
        key = key + ' ' + json?.type?.name + ': ';
      }
      key += json?.outwardIssue?.key;
      if (withSummary) {
        key = key + ' ' + json?.outwardIssue?.fields?.summary;

        key = key + ' (' + json?.outwardIssue?.fields?.issuetype?.name + ')';
        key = key + ' (' + json?.outwardIssue?.fields?.status?.name + ')';
        key = key + ' (' + json?.outwardIssue?.fields?.priority?.name + ')';
      }
    } else if (json?.inwardIssue?.key) {
      if (withSummary) {
        key = key + ' ' + json?.type?.name + ': ';
      }
      key += json?.inwardIssue?.key;
      if (withSummary) {
        key = key + ' ' + json?.inwardIssue?.fields?.summary;

        key = key + ' (' + json?.inwardIssue?.fields?.issuetype?.name + ')';
        key = key + ' (' + json?.inwardIssue?.fields?.status?.name + ')';
        key = key + ' (' + json?.inwardIssue?.fields?.priority?.name + ')';
      }
    }

    return key;
  }
  return '';
};

export const getIssueLinksFromJiraContent = (jiraServerUrl, issueLinks, withSummary) => {
  if (jiraServerUrl && isValidList(issueLinks)) {
    let arr = [];

    issueLinks.map((x) => {
      let key = getIssuelinkKey(x, withSummary);
      if (isValidValue(key)) arr.push(key);
    });

    return arr.toString();
  }
  return '-';
};

export const getJiraLink = (jiraServerUrl, issueKey) => {
  if (jiraServerUrl && issueKey) {
    let lastChar = jiraServerUrl.slice(-1);
    if (lastChar === '/') return jiraServerUrl + 'browse/' + issueKey;
    else return jiraServerUrl + '/browse/' + issueKey;
  }
  return null;
};

export const getJiraIssueLinkDiv = (text, jiraServerUrl) => {
  if (isValidValue(text) && isValidValue(jiraServerUrl)) {
    return (
      <div style={{ textDecorationLine: 'underline' }}>
        {/* eslint-disable-next-line */}
        <a
          href={getJiraLink(jiraServerUrl, text)}
          target="_blank"
        >
          {text}
        </a>
      </div>
    );
  }
  return '-';
};

export const getIssueTypeOptions = (issuetypes) => {
  return getOptionListByIdName(issuetypes);
};

export const getOptionListByIdName = (arrObject) => {
  if (arrObject != null && arrObject.length !== 0) {
    let list = [];
    arrObject.map((item) => {
      list.push({
        id: item.id,
        key: item.id,
        value: item.id,
        text: item.name,
        name: item.name
      });
    });
    return list;
  }
  return [];
};

export const getDefectProjectOptions = (defectConfigs, allJiraProjects) => {
  let optionList = [];
  let allConfigProjects = [];

  if (isValidList(defectConfigs) === true && isValidList(allJiraProjects) === true) {
    defectConfigs.map((item) => {
      const project = findProject(allJiraProjects, item?.jiraProject?.id);
      if (project && project !== null) {
        allConfigProjects.push({
          ...item,
          issuetypes: project?.issuetypes,
          name: project?.name,
          id: project?.id,
          projectKey: project?.key
        });

        let target = optionList.find((x) => x.key === item?.jiraProject?.id);
        if (target === undefined || target === null) {
          let obj = {
            id: item?.jiraProject?.id,
            projectKey: project?.key,
            name: project?.name + ' (' + project?.key + ')',
            issuetypes: project.issuetypes
          };
          if (!(optionList?.filter((option) => option?.id === obj?.id).length > 0)) optionList.push(obj);
        }
      }
    });
  }
  return [optionList, allConfigProjects];
};

export const getJiraKeyPageLinkIfExist = (isJiraActive, jiraServerUrl, entityForm, entityType) => {
  let text = '';

  if (entityType === ENTITY_TYPES.DEFECT) text = entityForm?.summary;
  else text = entityForm?.name;

  if (isJiraActive === true && isValidValue(entityForm)) {
    let key = '';
    if (isValidValue(entityForm?.jiraKey)) {
      key = entityForm?.jiraKey;
    } else if (
      entityForm?.jiraContent &&
      entityForm?.jiraContent !== null &&
      isValidValue(entityForm?.jiraContent.key)
    ) {
      key = entityForm?.jiraContent?.key;
    }

    if (isValidValue(key)) {
      const issueTitle = 'Jira: ' + key;
      if (jiraServerUrl) {
        const issueLink = getJiraLink(jiraServerUrl, key);
        // eslint-disable-next-line
        return (
          <span>
            {' '}
            {text}{' '}
            <a
              href={issueLink}
              rel="noreferrer"
              target="_blank"
            >
              {' (' + issueTitle + ')'}
            </a>
          </span>
        );
      }
    }
  }
  return text;
};

export const createJQLQuery = (entityConfigs, item) => {
  let projects = [];
  let issuetypes = [];
  let query = '';

  if (entityConfigs && entityConfigs.length > 0) {
    entityConfigs.map((x) => {
      if (x?.jiraProject?.name && projects.includes(x?.jiraProject?.name) === false) {
        projects.push(x.jiraProject.name);
      }
      if (x?.jiraIssueType?.name && issuetypes.includes(x?.jiraIssueType?.name) === false) {
        issuetypes.push(x.jiraIssueType.name);
      }
    });

    let defaultQuery =
      '( project IN ( ' + projects.toString() + ' ) AND  type IN (' + issuetypes.toString() + ' )  )  ';
    let itemQuery = createItemJQLQuery(item);
    if (itemQuery && itemQuery !== null) {
      defaultQuery = defaultQuery + '  AND  ' + itemQuery;
    }
    return defaultQuery;
  }

  return query;
};

export const createItemJQLQuery = (item) => {
  if (item && item?.jiraProject?.name && item?.jiraIssueType?.name) {
    return "  (project = '" + item.jiraProject.name + "'  AND type =  '" + item.jiraIssueType.name + "' ) ";
  }
  return null;
};

export const findTextByKey = (arrObject, key) => {
  if (isValidValue(key)) {
    if (isValidList(arrObject)) {
      let data = arrObject.find((x) => x?.key === key || x?.id === key);
      if (isValidValue(data)) return isValidValue(data?.name) ? data?.name : '';
    }
    return key;
  }
  return '';
};

export const convertJiraEntityTableData = (data, entityType, jiraIssueFields) => {
  let arr = [];
  if (isValidList(data)) {
    data.map((item) => {
      let fol = {};
      if (entityType === ENTITY_TYPES.REQUIREMENT) {
        fol = {
          folder: findTextByKey(jiraIssueFields, item?.configs?.jiraFolderField),
          subFolder: findTextByKey(jiraIssueFields, item?.configs?.jiraSubFolderField)
        };
      }
      arr.push({
        id: item?.id,
        project: item?.jiraProject?.name,
        type: i18next.t(item?.jiraIssueType?.name),
        ...fol
      });
    });
  }
  return arr;
};
