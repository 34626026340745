import React, { useEffect, useState } from 'react';
// HOOKS & API
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../hooks/UseApi';
import ProjectApi from '../../../../api/ProjectApi';
// COMPONENTS & DESIGN SYSTEM
import { Modal } from '../../../../components/modal';
import { Button, Input, Dropdown, MultiSelectDropdownPill } from '../../../../designSystem/DesignSystem';
// HELPERS & CONSTANTS
import { showErrorMes } from '../../../../helpers/NotificationHelper';
import { PARAMETER_STATUS_DROPDOWN, USE_FORM_OPTIONS } from '../../../../constants';

const CreateParameterModal = ({ open, closeModal, handleCreate }) => {
  const { control, handleSubmit } = useForm(USE_FORM_OPTIONS);
  const { t } = useTranslation();

  const [projects, setProjects] = useState([]);

  const getProjects = useApi({
    name: 'Getting projects',
    api: ProjectApi.getAll,
    onSuccess: (res) => setProjects(res),
    onError: (error) => showErrorMes(error)
  });

  const onSubmit = (data) => {
    handleCreate({
      ...data,
      status: data.status[0].value,
      parameterValues: data.parameterValues.split(','),
      projects: data?.projects?.map((project) => project.id)
    });
  };

  useEffect(() => {
    getProjects.execute();
  }, []);

  return (
    <>
      <Modal
        //TODO:MODAL CSS DEĞİŞİKLİKLERİ UYGULANACAK
        open={open}
        close={() => closeModal()}
        w="w-3/5"
      >
        <Modal.Title>{t('Add Parameter')}</Modal.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <div className="flex grow flex-col gap-2">
              <Input
                control={control}
                name="name"
                label={t('Name')}
                required
                type="text"
                placeholder={t('Please enter a name.')}
              />
              <Input
                control={control}
                name="description"
                label={t('Description')}
                required
                type="text"
                placeholder={t('Please enter a description.')}
              />
              <Dropdown
                control={control}
                name="status"
                label={t('Status')}
                required
                options={PARAMETER_STATUS_DROPDOWN}
                placeholder={t('Please select a status.')}
              />
              <Input
                control={control}
                name="parameterValues"
                label={t('Parameter Values')}
                required
                type="text"
                placeholder={t('Please enter values for the parameter.')}
              />
              <MultiSelectDropdownPill
                control={control}
                name="projects"
                label={t('Projects')}
                options={projects}
                placeholder={t('Please select releated projects.')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              primary
              text={t('Submit')}
              type="submit"
            />
            <Button
              secondary
              text={t('Cancel')}
              onClick={() => closeModal()}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default CreateParameterModal;
