import React from 'react';
import { classNames, isValidValue } from '../../helpers/Helper';

/**
 * this method can be converted to the letter İ
 * @param {text} String.
 * @returns String || Object
 * */
const replaceLowerCaseIWithUpperCaseDottedI = (text) => {
  const langValue = localStorage.getItem('lang');
  if (langValue == 'tr' && typeof text == 'string') {
    return text.replace(/i/g, 'İ');
  }
  return text;
};

//
//only for text and font classes.Don't use any padding or margin.
//
export const Heading = ({ text, type, uppercase, color, className, isNotBold, notCapitalize }) => {
  return (
    <div
      className={classNames(
        isNotBold ? '' : ' font-semibold ',
        className,
        type === 'xsmall' && 'text-xs',
        type === 'small' && 'text-sm',
        type === 'base' && 'text-base',
        type === 'medium' && 'text-md',
        type === 'large' && 'text-lg',
        type === 'xlarge' && 'text-xl',
        uppercase ? 'uppercase' : notCapitalize ? '' : 'capitalize',
        color ? color : 'text-primary-900'
      )}
    >
      {isValidValue(text) ? (uppercase ? replaceLowerCaseIWithUpperCaseDottedI(text) : text) : ''}
    </div>
  );
};

const Group = ({ text, children, subText, subTextType, type, color, isNotBold, className }) => {
  return (
    <>
      <div className={`header flex flex-row items-center justify-between space-x-6 lg:flex-row ${className}`}>
        <div className="whitespace-pre-wrap break-all">
          <div
            className={classNames(
              isNotBold ? '' : ' font-semibold ',
              type === 'xsmall' && 'text-xs',
              type === 'small' && 'text-sm',
              type === 'base' && 'text-base',
              type === 'medium' && 'text-md',
              type === 'large' && 'text-md lg:text-lg',
              type === 'xlarge' && 'text-xl',
              color ? color : 'text-primary-900'
            )}
          >
            {text}
          </div>
          {subText && (
            <>
              <div
                className={classNames(
                  subTextType === 'xsmall' && 'text-xs',
                  subTextType === 'small' && 'text-sm',
                  subTextType === 'base' && 'text-base',
                  subTextType === 'medium' && 'text-md',
                  subTextType === 'large' && 'text-md lg:text-lg',
                  subTextType === 'xlarge' && 'text-xl',
                  'font-normal',
                  color ? color : 'text-primary-900'
                )}
              >
                {subText}
              </div>
            </>
          )}
        </div>
        <div id="headerGroup_children">{children}</div>
      </div>
    </>
  );
};
const Duo = ({ text, subText, type }) => {
  let mainHeadingType;
  let secondaryHeadingType;

  if (type === 'xlarge') mainHeadingType = 'xlarge';
  secondaryHeadingType = 'large';
  if (type === 'large') {
    mainHeadingType = 'medium';
    secondaryHeadingType = 'medium';

    return (
      <>
        <Heading
          type={mainHeadingType}
          text={text}
        />
        <Body
          type={secondaryHeadingType}
          text={subText}
        />
      </>
    );
  }

  return (
    <>
      <Heading
        type={mainHeadingType}
        text={text}
      />
      <Heading
        type={secondaryHeadingType}
        text={subText}
      />
    </>
  );
};

Heading.Group = React.memo(Group);
Heading.Duo = Duo;

export const Body = ({ text, type, bold, color, className, id }) => {
  return (
    <>
      <div
        id={id}
        className={classNames(
          'font-regular',
          type === 'small' && 'text-xs',
          type === 'medium' && 'text-sm',
          type === 'large' && 'text-base',
          type === "xlarge && 'text-large",
          bold && 'font-semibold',
          color ? color : 'text-gray-800',
          isValidValue(className) && className
        )}
      >
        {text}
      </div>
    </>
  );
};

//
export function H1({ text, alignment, className }) {
  return (
    <>
      <div className={`text-3xl font-semibold text-gray-800 ${alignment} ${className} `}>{text}</div>
    </>
  );
}

export function H2({ text, alignment, className }) {
  return (
    <>
      <div className={`text-2xl font-semibold text-gray-800 ${alignment} ${className} `}>{text}</div>
    </>
  );
}

export function H3({ text, alignment, className }) {
  return (
    <>
      <div className={`text-xl font-semibold text-gray-800 ${alignment} ${className} `}>{text}</div>
    </>
  );
}

export function H4({ text, alignment, className }) {
  return (
    <>
      <div className={`text-lg font-semibold text-gray-800 ${alignment} ${className} `}>{text}</div>
    </>
  );
}

export function H5({ text, alignment, color, className }) {
  return (
    <>
      <div className={`font-regular text-sm ${alignment} ${color || 'text-gray-600'} ${className}`}>{text}</div>
    </>
  );
}

export function H6({ text, alignment, uppercase, color }) {
  return (
    <>
      <div
        className={` ${color ? color : 'text-gray-600'} text-xs font-semibold  tracking-wide ${
          uppercase && 'uppercase'
        } ${alignment} `}
      >
        {text}
      </div>
    </>
  );
}

export function P({ text, className, color, small, alignment }) {
  return (
    <>
      <div
        className={`${small ? 'text-xs' : 'text-sm'} ${color ? color : 'text-gray-600'}  ${alignment} ${className} `}
      >
        {text}
      </div>
    </>
  );
}
