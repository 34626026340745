import React, { useState } from 'react';
import ReportsMenuItem from './ReportsMenuItem';
import { DEFAULT_REPORT_LIST } from './DefaultReportList';
import { useTranslation } from 'react-i18next';

export default function ReportsMenu({ projectId, reports }) {
  const [isSubNavOpen, setIsSubNavOpen] = useState('default');
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      name: t('predefined_reports'),
      path: 'default',
      subNav: [...DEFAULT_REPORT_LIST]
    },
    {
      id: 2,
      name: t('kibana_reports'),
      path: 'kibana',
      subNav: [...reports]
    }
  ];

  return (
    <div className="treeview-container">
      <div className="mb-1">
        {data.map((item) => {
          return (
            <ReportsMenuItem
              active={isSubNavOpen}
              setActive={setIsSubNavOpen}
              key={item.id}
              item={item}
              projectId={projectId}
            />
          );
        })}
      </div>
    </div>
  );
}
