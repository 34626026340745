import React, { useEffect, useState, useId, useCallback } from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
//Api and Hook  Imports
import { useAppDispatch } from '../../context/AppContext';
import { useForm } from 'react-hook-form';
import { saveProject } from '../../context/ProjectActions';
import ProjectApi from '../../api/ProjectApi';
import { useApi } from '../../hooks/UseApi';
//Desing System Imports
import { FullPageContainer, FullPageContent } from '../../components/Layout';
import { Heading, Button } from '../../designSystem/DesignSystem';
//Helper Imports
import { isValidValue, applyFilter } from '../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { isSystemAdmin } from '../../helpers/PriviligeHelper';
import { setProjectToken } from '../../helpers/ProjectTokenHelper';
// Components Imports
import ProjectDetailModal from './Modals/ProjectDetailModal';
import ConfirmationModal from '../../components/confirm/ConfirmationModal';
import CreateEditProjectModal from './Modals/CreateEditProjectModal';
import ProjectCard from '../../components/card/ProjectCard';
import { useTranslation } from 'react-i18next';
import ProjectListPageSearch from './ProjectListPageSearch';
import { ListDashes, SquaresFour } from 'phosphor-react';
import SelectedTableFilters from '../../components/filtering/SelectedTableFilters';
import { ENTITY_TYPES } from '../../constants';
import ProjectReactTable from './ProjectReactTable';
import UseAllUsers from '../../hooks/UseAllUsers';
import { useRef } from 'react';
import { openTemplate } from './ProjectHelper';

const ProjectTemplatePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [projects, setProjects] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deletedProject, setDeletedProject] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dataForm, setDataForm] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [tableView, setTableView] = useState(() => {
    const storedValue = localStorage.getItem('tableView');
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const users = UseAllUsers();
  const ref = useRef();
  const tableHeight = ref?.current?.clientHeight - ref?.current?.childNodes[0]?.clientHeight + 300;
  const [allFilters, setAllFilters] = useState({});
  const id = useId();
  const { control, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [filters, setFilters] = useState({ name: '', prefix: '', isTemplate: true });

  const { t } = useTranslation();

  /* Api */
  const projectLoader = useApi({
    name: 'fetching projects',
    api: ProjectApi.getAll,
    autoLoad: true,
    initialValue: [],
    params: { isTemplate: true },
    onSuccess: () => setProjects(projectLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const deleteProject = useApi({
    name: 'Deleting project',
    api: ProjectApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      refreshData();
      showSuccessMes(INFO_MESSAGES.delete);
      setDeletedProject(null);
    },
    onError: (err) => showErrorMes(err)
  });

  const refreshData = () => projectLoader.execute({ isTemplate: true });

  const handleDeleteClick = (item) => setDeletedProject(item);

  const handleDeleteProject = () => deleteProject.execute(deletedProject);

  const openEditModal = (item) => {
    setDataForm(item);
    setIsOpen(true);
  };

  const openViewModal = (item) => {
    setIsUpdate(false);
    setDataForm(item);
    setViewModal(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsUpdate(false);
    setDataForm(null);
    refreshData();
  };

  const debounceSearch = useCallback(
    debounce(() => projectLoader.execute(filters), 200),
    [filters]
  );

  useEffect(() => {
    if (isValidValue(filters)) debounceSearch();
    else setProjects([]);
    return debounceSearch.cancel;
  }, [filters, debounceSearch]);

  useEffect(() => {
    localStorage.setItem('project', '');
  }, []);

  useEffect(() => {
    localStorage.setItem('tableView', JSON.stringify(tableView));
  }, [tableView]);

  return (
    <>
      <FullPageContainer>
        <Heading.Group
          type="large"
          text={t('Project Templates')}
          className="mr-3"
        >
          <div className=" grid grid-cols-none gap-4">
            <div className="col-start-1 col-end-5 mt-1">
              <Button
                text={tableView == false ? <ListDashes size={28} /> : <SquaresFour size={28} />}
                size="xsmall"
                secondary2
                weight="bold"
                tooltip={tableView == false ? t('List View') : t('Card View')}
                name={'tableView'}
                onClick={() => {
                  setTableView((prevValue) => !prevValue);
                }}
              />
            </div>
            {isSystemAdmin() && (
              <div className="col-span-2 col-end-7">
                <Button
                  id="projectTemplatePage-add-button"
                  primary
                  icon="Plus"
                  text={t('New Project Template')}
                  onClick={() => setIsOpen(true)}
                />
              </div>
            )}
          </div>
        </Heading.Group>
        {tableView ? (
          <>
            <SelectedTableFilters
              allFilters={{ ...allFilters, isTemplate: true }}
              setAllFilters={setAllFilters}
              entityType={ENTITY_TYPES.PROJECT}
              setRefreshTable={setRefreshTable}
              refreshTable={refreshTable}
              showBulkButton={false}
              showSaveFilter={false}
            />
            <ProjectReactTable
              filter={{ ...allFilters, isTemplate: true }}
              refreshStatus={refreshTable}
              setRefreshStatus={setRefreshTable}
              users={users}
              height={tableHeight}
              showCheckbox={false}
              isTemplate={true}
            />
          </>
        ) : (
          <>
            <ProjectListPageSearch
              control={control}
              setValue={(type, value) => setValue(type, value)}
              applyFilter={(filter, value) => applyFilter(filter, value, filters, setFilters)}
            />
            <FullPageContent>
              <div className="h-full overflow-y-auto">
                <div
                  className={
                    'grid w-full grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4'
                  }
                >
                  {projects?.map((item, index) => {
                    return (
                      <ProjectCard
                        key={`${id}-${index}`}
                        item={item}
                        openProject={(item) => openTemplate(item, setProjectToken, dispatch, navigate, saveProject)}
                        openViewModal={(item) => openViewModal(item)}
                        openEditModal={(item) => openEditModal(item)}
                        setIsUpdate={(item) => setIsUpdate(item)}
                        handleDeleteClick={(item) => handleDeleteClick(item)}
                      />
                    );
                  })}
                </div>
              </div>
            </FullPageContent>
          </>
        )}
        {/* <-- MODALS START --> */}
        {viewModal && (
          <ProjectDetailModal
            isOpen={viewModal}
            project={dataForm}
            closeModal={() => setViewModal(false)}
          />
        )}
        {isOpen && (
          <CreateEditProjectModal
            isOpen={isOpen}
            setDataForm={setDataForm}
            dataForm={dataForm}
            closeModal={() => closeModal()}
            isUpdate={isUpdate}
            isTemplate={true}
          />
        )}
        {isValidValue(deletedProject) && (
          <ConfirmationModal
            isOpen={isValidValue(deletedProject)}
            loading={deleteProject?.loading}
            closeModal={() => setDeletedProject(null)}
            handleConfirm={handleDeleteProject}
            header={t('Confirm Delete')}
            content={t('Do you really want to delete this templete project?')}
          />
        )}
        {/* <-- MODALS END --> */}
      </FullPageContainer>
    </>
  );
};

export default ProjectTemplatePage;
