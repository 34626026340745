import React from 'react';
import { useForm } from 'react-hook-form';
import KibanaReportsApi from '../../../api/KibanaReportsApi';
import { Input, TextArea, Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import { setSelectedReport } from '../../../context/ProjectActions';
import { useAppDispatch } from '../../../context/AppContext';
import { useNavigate } from 'react-router-dom';

const AddReportModal = ({ isOpen, closeModal, projectId }) => {
  const { control, handleSubmit } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createReport = useApi({
    name: 'Creating report',
    api: KibanaReportsApi.create,
    onSuccess: () => {
      dispatch(setSelectedReport(createReport.data));
      navigate(`/reports/kibana/${createReport.data.id}?projectId=${projectId}`);
      closeModal();
      showSuccessMes(INFO_MESSAGES.add);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (data) => {
    const body = { projectId, name: data.name, url: { url: data.reportUrl } };
    createReport.execute(body);
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={closeModal}
        w={'md'}
      >
        <Modal.Title>{t('Add Report')}</Modal.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <Input
                control={control}
                required
                label={t('Name')}
                name="name"
              />
              <TextArea
                required
                placeholder={t('Enter your text here')}
                control={control}
                name="reportUrl"
                label={t('Report Url')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="addReportModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={closeModal}
            />
            <Button
              id="addReportModal-submit-button"
              primary
              text={t('Submit')}
              loading={createReport?.loading}
              type="submit"
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default AddReportModal;
