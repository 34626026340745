import { isValidList, isValidValue } from "../../../helpers/Helper";
import moment from "moment";


export const getLineChartData = (buckets, chart) => {
  const levelStatus = (isValidList(chart?.configuration?.fields) && chart?.configuration?.fields.length>1);
  let histogram = chart?.configuration?.dateHistogram === true;
  let arr = [];
  if (histogram || levelStatus) {

    if(isValidList(buckets)){
    buckets.map((x) => {
      if (x?.key && x?.doc_count) {


        let d = [];
        let inp = {
          id: x?.key,
          data: [],
        };
        let valueBuckets = x?.["1"]?.["buckets"];
        if (isValidList(valueBuckets)) {
          valueBuckets.map((val) => {
            if(val?.key){
            let newVal = histogram
              ? moment(val?.key).format("DD MMM YYYY")
              : val?.key;

            
              d.push({
                x: newVal,
                y: val?.doc_count,
              });
            }
          });
        } //if end


        inp["data"] = d;

        arr.push(inp);
      }


      
    });
  }
  
    return arr;
  }
 
  else {
    if(isValidList(buckets)){
        buckets.map((x) => {
          if (x?.key && x?.doc_count)
            arr.push({
              x: x?.key,
              y: x?.doc_count,
            });
        });
    }

    let field = isValidList(chart?.configuration?.fields)
      ? chart?.configuration?.fields[0]?.fieldKey
      : "";
      if(isValidValue(field)){ 
    return [
      {
        id: field,
        data: arr,
      },
    ];} else {
      return [];
    }
  }
};
