import { errorMessage, isValidList, isValidValue } from '../../helpers/Helper';
import * as Icons from 'phosphor-react';
import _ from 'lodash';
/**
 * @description Returns formatted options list arr for dropdown
 * @param {Array} arr - Unformatted ptions data array
 * @returns {{id: string,name: string ,value: string, disabled: boolean}[]|array} Formatted options list arr for dropdown | empty array if arr is null"
 */
export function dropdownOptions(arr, isSort) {
  if (isValidList(arr)) {
    let newList = arr.map((item) => ({
      id: isValidValue(item.id) ? item.id : item.key,
      name: isValidValue(item.name) ? item.name : item.text,
      value: isValidValue(item.value) ? item.value : item.id,
      ...(isValidValue(item?.email) && { email: item?.email }),
      key: isValidValue(item.key) ? item.key : item.id,
      disabled: isValidValue(item?.disabled) ? item?.disabled : false,
      active: isValidValue(item?.active) ? item?.active : true,
      ...(isValidValue(item?.defaultName) && { defaultName: item?.defaultName })
    }));
    if (isSort) {
      return _.sortBy(newList, 'name').filter((item) => item?.active);
    }
    return newList.filter((item) => item?.active);
  } else return [];
}
/**
 * Render Error
 * @description Renders error message if the formsate contains errors for the specified input name
 * @param {Object} formState
 * @param {Array} selectedOption
 * @param {String} label
 * @param {String} name
 * @returns
 */
export const renderError = (formState, selectedOption, label, name) => {
  if (formState?.errors?.[name] && !isValidValue(selectedOption) && formState?.errors[name]?.type === 'required') {
    return errorMessage('required', label, name);
  }
};

export let LABEL_SPAN_CLASS = ' text-red-600 ml-2 ';

/**
 *
 * @param {Boolean} disabled
 * @param {Boolean} clearable
 * @param {*} selectedOption
 * @param {*} setSelectedOption
 * @param {VoidFunction} passValue
 * @param {VoidFunction} onClear
 * @returns
 */
export const dropdownIcon = (disabled, clearable, selectedOption, setSelectedOption, passValue, onClear) => {
  if (!disabled) {
    if (clearable) {
      if (!selectedOption) return <Icons.CaretDown className="ml-4 " />;
      if (selectedOption)
        return (
          <Icons.X
            className="cursor-pointer text-gray-500"
            weight="bold"
            size="10"
            onClick={() => {
              setSelectedOption('');
              if (passValue) passValue('');
              if (onClear) onClear('');
            }}
          />
        );
    } else {
      return <Icons.CaretDown className="ml-4 " />;
    }
  }
};
export const IconComponent = (icon) => {
  if (icon && Icons?.[icon]) return Icons[icon];
  return null;
};

export const returnButtonIconSVG = () => {
  return (
    <>
      <svg
        className="h-5 w-5 text-gray-400"
        viewBox="0 0 20 20"
        fill="none"
        stroke="currentColor"
      >
        <path
          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export const returnSelectedSVG = () => {
  return (
    <>
      <svg
        className="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </>
  );
};
