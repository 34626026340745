import React, { useEffect, useState } from 'react';
import { getChartHeading } from '../helper/DashboardHelper';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import PercentageBar from '../../../components/general/PercentageBar';
import { isValidList } from '../../../helpers/Helper';

const InsightChart = ({ chart, projectId, dateFilter, projectConfig }) => {
  const [data, setData] = useState([]);

  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      let buckets = dataLoader?.data?.aggregations?.['0']?.buckets;
      let inp = [];
      if (isValidList(buckets)) {
        buckets.map((x) => {
          inp.push({
            name: x?.key,
            value: x?.doc_count
            // color: 'white'
          });
        });
      }
      setData(inp);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);

  return (
    <>
      <div style={{ minWidth: 150 }}>{getChartHeading(chart, projectConfig)}</div>
      <PercentageBar
        datas={data}
        showLabel={true}
      />
    </>
  );
};

export default InsightChart;

// 'left-to-right'
