import { classNames } from './../../../helpers/Helper';

export const ToggleSwitch = ({ enabled: checked, disabled, passValue, setEnabled }) => {
  const handleChange = () => {
    setEnabled(!checked);
    passValue && passValue(!checked);
  };

  return (
    <>
      <label className="relative inline-flex cursor-pointer self-center">
        <input
          checked={checked}
          disabled={disabled}
          type="checkbox"
          onChange={handleChange}
          className="peer sr-only"
        />
        <div
          className={classNames(
            'flex h-5 w-9 after:self-center ',
            'peer-focus:outline-none peer-focus:ring-4',
            'peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 ',
            'peer rounded-full peer-checked:after:translate-x-full dark:border-gray-600 dark:bg-gray-700',
            'peer-checked:after:border-white',
            "after:absolute after:left-[2px] after:bg-white after:content-['']",
            'after:h-4 after:w-4 after:rounded-full after:transition-all',
            checked && !disabled && 'bg-primary-600 peer-checked:bg-primary-600 ',
            checked && disabled && 'bg-primary-300',
            !checked && !disabled && 'bg-gray-200',
            !checked && disabled && 'bg-gray-100'
          )}
        />
      </label>
    </>
  );
};
