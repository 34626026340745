import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import TestCaseApi from '../../api/TestCaseApi';
import TestCaseStepApi from '../../api/TestCaseStepApi';
// Component Imports
import ConfirmationModal from '../../components/confirm/ConfirmationModal';
// Design System Import
import { Body, Heading, TextArea, Button } from '../../designSystem/DesignSystem';
import { ENTITY_TYPES } from '../../constants';
import { getTestCaseName } from '../../helpers/ArtifactNameHelper';
//Helper Imports
import { getUrl, isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';

// API Imports
import { useApi } from '../../hooks/UseApi';
import TestCaseAddStepModal from './modal/TestCaseAddStepModal';
import TestCaseStepUpdateModal from './modal/TestCaseStepUpdateModal';

const defaultValues = {
  description: '',
  expectedResult: ''
};
const linkClass = 'text-blue-900 text-sm font-medium hover:underline underline-offset-4';

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'white' : '#white',
  padding: 0,
  width: '98%'
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '2px 18px 2px 0px',
  marginRight: 0,

  // change background colour if dragging
  background: isDragging ? '#e6f0f5' : 'white',
  borderBottom: `1px solid rgba(28,110,164,0.12)`,

  // styles we need to apply on draggables
  ...draggableStyle
});

const TestCaseSteps = (props) => {
  const { testCaseId, caseApproved, projectConfig, preconditions, projectId } = props;
  const { control, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all',
    defaultValues
  });
  const [testSteps, setTestSteps] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedStepId, setSelectedStepId] = useState('');
  const [testStepsFormEdit, setTestStepsFormEdit] = useState({});
  const [testCaseStepModal, setTestCaseStepModal] = useState(false);
  const { t } = useTranslation();

  const testCaseStepLoader = useApi({
    name: 'fetching testcase steps',
    api: TestCaseStepApi.getTestCaseSteps,
    autoLoad: false,
    onSuccess: () => setTestSteps(testCaseStepLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const addtestCaseStep = useApi({
    name: 'adding testcase step',
    api: TestCaseStepApi.createTestCaseStep,
    autoLoad: false,
    onSuccess: () => {
      getSteps();
      reset(defaultValues);
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteTestCaseStep = useApi({
    name: 'delete testcase step',
    api: TestCaseStepApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      getSteps();
      setOpenDeleteModal(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const changeOrderTestCaseStep = useApi({
    name: 'change order testCase step',
    api: TestCaseApi.changeOrder,
    autoLoad: false,
    onError: (err) => showErrorMes(err)
  });

  const getSteps = () => {
    if (testCaseId) {
      testCaseStepLoader.execute({ testCaseId });
    }
  };

  const handleDelete = () => {
    if (selectedStepId) {
      deleteTestCaseStep.execute({ id: selectedStepId });
    }
  };

  const onSubmit = (form) => {
    if (!isValidValue(form.description.trim())) return;

    addtestCaseStep.execute({
      testCaseId: testCaseId,
      description: form.description,
      expectedResult: form.expectedResult
    });
  };

  const closeStepUpdateModal = (updated) => {
    if (updated) {
      getSteps();
    }
    setOpenEditModal(false);
  };

  const onShowConfirm = (id) => {
    setSelectedStepId(id);
    setOpenDeleteModal(true);
  };

  const onShowEdit = (step) => {
    setSelectedStepId(step.id);
    setOpenEditModal(true);
    setTestStepsFormEdit(step);
  };

  const onDragEnd = (result) => {
    if (caseApproved === true) return;

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    if (!result.destination) {
      return;
    }

    const items = reorder(testSteps, result.source.index, result.destination.index);

    changeStepOrder(result.draggableId, result.destination.index);
    setTestSteps(items);
  };

  const changeStepOrder = (testCaseStepId, newIndex) => {
    changeOrderTestCaseStep.execute({ id: testCaseId, testCaseStepId, newIndex });
  };

  const checkKeyDown = (event) => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  // const handleHeight = () => {
  //   let height = tableHeight - 250;
  //   if (isValidValue(preconditions)) return height - 50;
  //   if (height) return height;
  // };

  useEffect(() => {
    getSteps();
  }, [testCaseId]);

  return (
    <>
      <div className="divide-y">
        {isValidValue(preconditions) && (
          <div className=" m-4 flex flex-row">
            <div className={`flex-row bg-white`}>
              <Heading
                className="w-36"
                type="small"
                text={t('Preconditions')}
              />
              <div className={'max-h-32 overflow-y-auto'}>
                <Body
                  type="medium"
                  text={preconditions}
                />
              </div>
            </div>
          </div>
        )}

        <table
          className="flex flex-col py-4"
          border="1"
        >
          <thead className="flex flex-grow">
            <tr className="flex flex-grow">
              <th className="flex w-24">
                <Heading
                  type="xsmall"
                  text="#"
                  uppercase
                />
              </th>
              <th className="flex flex-grow">
                <Heading
                  type="xsmall"
                  text={t('Description')}
                  uppercase
                />
              </th>
              <th className="flex flex-grow">
                <Heading
                  type="xsmall"
                  text={t('Expected Result')}
                  uppercase
                />
              </th>
              {caseApproved === false && <th className="w-24"></th>}
            </tr>
          </thead>
        </table>
        <div
        // className="overflow-y-auto "
        // style={{ height: handleHeight()}}
        >
          {testCaseStepLoader.loading ? (
            <p>Loading</p>
          ) : (
            <>
              {testSteps.length === 0 && caseApproved && !testCaseStepLoader.loading && (
                <Body
                  type="medium"
                  text={t('There are no steps.')}
                />
              )}
              <>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {testSteps.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...(!caseApproved && {
                                  ...provided.draggableProps,
                                  ...provided.dragHandleProps
                                })}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-1 self-center">
                                    <Body
                                      type="small"
                                      text={index + 1}
                                    />
                                  </div>
                                  <div className="col-span-5 self-center">
                                    {isValidValue(item.relatedTestCase?.id) ? (
                                      <Body
                                        type="medium"
                                        text={
                                          <Link
                                            className={linkClass}
                                            to={getUrl(ENTITY_TYPES.TEST_CASE, item?.relatedTestCase, projectId)}
                                          >
                                            {'Case ' +
                                              item.relatedTestCase.code +
                                              ' ' +
                                              item.relatedTestCase.name +
                                              ' (v' +
                                              item.relatedTestCase.majorVersion +
                                              ')'}
                                          </Link>
                                        }
                                      />
                                    ) : (
                                      <Body
                                        type="medium"
                                        text={item?.description}
                                      />
                                    )}
                                  </div>
                                  <div className="col-span-5 self-center">
                                    <Body
                                      type="medium"
                                      text={item?.expectedResult}
                                    />
                                  </div>
                                  {caseApproved === false && (
                                    <div className="col-span-1">
                                      <div className="flex w-24 justify-end gap-4">
                                        <Button
                                          id="testCaseSteps-edit-button"
                                          secondary
                                          icon="PencilLine"
                                          size="xsmall"
                                          onClick={() => {
                                            onShowEdit(item);
                                          }}
                                        />
                                        <Button
                                          id="testCaseSteps-delete-button"
                                          secondary
                                          icon="TrashSimple"
                                          size="xsmall"
                                          onClick={() => {
                                            onShowConfirm(item.id);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            </>
          )}
        </div>
        {caseApproved === false && (
          <form
            className="flex gap-4"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <div className="flex flex-grow flex-row gap-4">
              <TextArea
                placeholder={t('Description is mandatory')}
                control={control}
                name="description"
              />
              <TextArea
                placeholder={t('Expected result is optional')}
                control={control}
                name="expectedResult"
              />
              <div className="inline-grid	content-center">
                <Button
                  id="testCaseSteps-submit-button"
                  secondary
                  type="submit"
                  icon="FloppyDisk"
                  size="small"
                  loading={addtestCaseStep?.loading}
                />
              </div>
            </div>
          </form>
        )}
        {caseApproved === false && (
          <div className="flex flex-grow justify-end">
            <Button
              id="testCaseSteps-add-button"
              secondary
              text={t('testCase_add', { testCase: getTestCaseName(projectConfig) })}
              type="button"
              icon="Plus"
              size="base"
              onClick={() => setTestCaseStepModal(true)}
            />
          </div>
        )}
      </div>
      <ConfirmationModal
        isOpen={openDeleteModal}
        closeModal={() => {
          setOpenDeleteModal(false);
        }}
        handleConfirm={handleDelete}
        loading={deleteTestCaseStep?.loading}
        header={t('Confirm Delete')}
        content={t('Do you really want to delete this test case step?')}
      />
      {testStepsFormEdit && (
        <TestCaseStepUpdateModal
          step={testStepsFormEdit}
          isOpen={openEditModal}
          closeModal={closeStepUpdateModal}
          defaultValues={defaultValues}
        />
      )}
      {testCaseStepModal && (
        <TestCaseAddStepModal
          isOpen={testCaseStepModal}
          closeModal={() => setTestCaseStepModal(false)}
          projectConfig={projectConfig}
          testCaseId={testCaseId}
          submitCallBack={() => getSteps()}
        />
      )}
    </>
  );
};
export default TestCaseSteps;
