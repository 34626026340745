import { Listbox, Transition } from '@headlessui/react';
import { ArrowCounterClockwise, DotsSixVertical } from 'phosphor-react';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from './DesignSystem';
import { useTranslation } from 'react-i18next';

const FieldSelection = ({ fields = [], selectedFields = [], onChange = () => {}, onReset = () => {} }) => {
  const { t } = useTranslation();

  const handleSelectionClick = (e, column) => {
    let newFields = [...selectedFields];
    if (e.target.checked) {
      if (!newFields.includes(column)) {
        newFields.push(column);
      }
    } else {
      newFields = newFields.filter((f) => f !== column);
    }

    onChange(newFields);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0px',
    marginRight: 0,

    // change background colour if dragging
    background: isDragging ? '#e6f0f5' : 'white',
    borderBottom: `1px solid rgba(28,110,164,0.12)`,

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : '#white',
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  });

  const onDragEnd = (result) => {
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    if (!result?.destination) {
      return;
    }

    const items = reorder(fields, result?.source?.index, result?.destination?.index);

    const newSelectedFields = items.filter((f) => selectedFields.includes(f?.accessor)).map((f) => f.accessor);

    onChange(newSelectedFields);
  };

  return (
    <div className="flex ">
      <Listbox
        as="div"
        className="z-40 space-y-1"
      >
        {({ open }) => (
          <>
            <div className="relative">
              <span className="inline-block">
                <Listbox.Button>
                  <Button
                    id="selectedTable-manageTable-button"
                    tooltip={t('Manage Table')}
                    secondary2
                    weight="bold"
                    size="xsmall"
                    icon="Table"
                    type="submit"
                  />
                </Listbox.Button>
              </span>
              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute z-50 w-full rounded-md bg-white shadow-lg"
              >
                <Listbox.Options
                  static
                  className=" shadow-xs z-50 max-h-60 w-[250px] overflow-auto rounded-md border border-gray-200 text-xs leading-6 focus:outline-none  sm:leading-5"
                >
                  <Listbox.Option key="0">
                    {({ active }) => (
                      <div
                        onClick={onReset}
                        className={`${
                          active ? 'bg-primary-600 text-white' : 'bg-white text-primary-700'
                        } relative cursor-default select-none py-2 pl-8`}
                      >
                        <span className=" block cursor-pointer truncate pl-1.5">{t('Reset to defaults')}</span>
                        <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
                          <ArrowCounterClockwise size={16} />
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {fields.map((field, index) => {
                            const selected = selectedFields.includes(field?.accessor);
                            return (
                              <Draggable
                                key={field.accessor}
                                draggableId={`${field.accessor}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  >
                                    <Listbox.Option
                                      key={field.accessor}
                                      value={field.accessor}
                                    >
                                      {({ active }) => (
                                        <div
                                          className={`${
                                            active ? 'bg-primary-600 text-white' : 'text-primary-700'
                                          } relative flex cursor-default select-none items-center py-2 pl-8 pr-4`}
                                        >
                                          <div
                                            className={`${selected ? 'font-semibold' : 'font-normal'} block truncate `}
                                          >
                                            <span className="items-center pl-1.5 pr-1.5">
                                              <input
                                                type="checkbox"
                                                className="h-4 w-4 cursor-pointer rounded-md text-gray-600"
                                                checked={selected}
                                                onChange={(e) => handleSelectionClick(e, field?.accessor)}
                                              />
                                            </span>
                                            {t(field?.Header)}
                                          </div>
                                          <div className="absolute  inset-y-0 left-0 flex cursor-grabbing items-center pl-1.5">
                                            <DotsSixVertical size={20} />
                                          </div>
                                        </div>
                                      )}
                                    </Listbox.Option>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default FieldSelection;
