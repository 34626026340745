import React, { useState, useEffect, useMemo } from 'react';
import { PencilLine, UserGear, Trash } from 'phosphor-react';
// Api and Hook Imports
import UseQueryProject from '../../../hooks/UseQueryProject';
import { useApi } from '../../../hooks/UseApi';
import UserGroupApi from '../../../api/UserGroupApi';
// Design System & LAYOUTS Import
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { SidePaneRightContent } from '../../../components/Layout';
//Component Imports
import UserGroupModal from './UserGroupModal';
import BasicTable from '../../../components/table/BasicTable';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import UserGroupAddEditUsersAndRolesModal from './UserGroupAddEditUsersAndRolesModal';
//Helper Imports
import {
  disableResizingForColumns,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../helpers/TableHelper';
import { TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
import { isProjectManager, hasPrivilege } from '../../../helpers/PriviligeHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { isValidValue } from '../../../helpers/Helper';
import { PRIVILEGES } from '../../../constants';
import { useTranslation } from 'react-i18next';

const UserGroupsPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();

  const [openUserGroupModal, setOpenUserGroupModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUserAndRoleModal, setOpenUserAndRoleModal] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState(null);

  const dataLoader = useApi({
    name: 'fetching user groups',
    api: UserGroupApi.getUserGroups,
    autoLoad: false,
    onError: (err) => showErrorMes(err)
  });

  const deleteUserGroup = useApi({
    name: 'delete user group',
    api: UserGroupApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      getUserGroupList();
      setSelectedUserGroup(null);
      setOpenDeleteModal(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const optionsFn = (row) => {
    return [
      ...(hasPrivilege(projectId, PRIVILEGES.UPDATE_USERGROUP)
        ? [
            {
              name: t('Edit'),
              icon: (weight) => (
                <PencilLine
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: isValidValue(row?.original?.templateBy),
              onClick: () => {
                handleSelectedRow(dataLoader?.data, row, setSelectedUserGroup);
                setOpenUserGroupModal(true);
              }
            }
          ]
        : []),
      ...(hasPrivilege(projectId, PRIVILEGES.ASSIGN_USER) || hasPrivilege(projectId, PRIVILEGES.UNASSIGN_USER)
        ? [
            {
              name: t('Manage'),
              icon: (weight) => (
                <UserGear
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: false,
              onClick: () => {
                handleSelectedRow(dataLoader?.data, row, setSelectedUserGroup);
                setOpenUserAndRoleModal(true);
              }
            }
          ]
        : []),
      ...(hasPrivilege(projectId, PRIVILEGES.DELETE_USERGROUP)
        ? [
            {
              name: t('Delete'),
              icon: (weight) => (
                <Trash
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: isValidValue(row?.original?.templateBy),
              onClick: () => {
                handleSelectedRow(dataLoader?.data, row, setSelectedUserGroup);
                setOpenDeleteModal(true);
              }
            }
          ]
        : [])
    ];
  };

  const convertToTableData = (group) => ({
    name: group.name,
    id: group.id,
    templateBy: group.templateBy
  });

  const { data, tableColumns } = useMemo(() => {
    let tableData = [];
    let columns = [];
    if (dataLoader?.data) {
      tableData = dataLoader?.data?.map(convertToTableData);
      columns = generateTableColumns(tableData, optionsFn, ['id']);
    }
    return {
      data: tableData,
      tableColumns: columns
    };
  }, [dataLoader?.data]);
  let columns = disableResizingForColumns(tableColumns, [{ accessor: 'actions', width: 5 }]);
  columns = translateColumns(columns, t);
  const handleDelete = () => {
    deleteUserGroup.execute({ id: selectedUserGroup.id });
  };

  const getUserGroupList = () => {
    dataLoader.execute({ projectId });
  };

  useEffect(() => {
    if (isProjectManager(projectId) || hasPrivilege(projectId, PRIVILEGES.VIEW_USERGROUP)) getUserGroupList();
  }, []);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            text={t('User Groups')}
            type="large"
          >
            {hasPrivilege(projectId, PRIVILEGES.CREATE_USERGROUP) && (
              <>
                <Button
                  id="userGroupPage-add-button"
                  primary
                  text={t('Add')}
                  size="small"
                  icon="Plus"
                  onClick={() => setOpenUserGroupModal(true)}
                />
              </>
            )}
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Center colFull={true}>
          {hasPrivilege(projectId, PRIVILEGES.VIEW_USERGROUP) && (
            <div className="mt-6">
              <BasicTable
                columns={columns}
                data={data}
                loading={dataLoader?.loading}
              />
            </div>
          )}
        </SidePaneRightContent.Center>
      </SidePaneRightContent>
      {/* MODALS */}
      {openUserGroupModal && (
        <UserGroupModal
          projectId={projectId}
          isOpen={openUserGroupModal}
          closeModal={(form) => {
            if (form) getUserGroupList();
            setOpenUserGroupModal(false);
            setSelectedUserGroup(null);
          }}
          {...(selectedUserGroup && { data: selectedUserGroup })}
        />
      )}
      <ConfirmationModal
        isOpen={openDeleteModal}
        loading={deleteUserGroup?.loading}
        closeModal={() => setOpenDeleteModal(false)}
        handleConfirm={handleDelete}
        header={t('Remove User Group from Project')}
        content={t('Are you sure to remove this user group from project?')}
      />
      {openUserAndRoleModal && (
        <UserGroupAddEditUsersAndRolesModal
          projectId={projectId}
          isOpen={openUserAndRoleModal}
          closeModal={() => {
            getUserGroupList();
            setOpenUserAndRoleModal(false);
            setSelectedUserGroup(null);
          }}
          selectedUserGroup={selectedUserGroup}
        />
      )}
    </>
  );
};
export default UserGroupsPage;
