import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../designSystem/DesignSystem';
import { getIssuelinkKey, getJiraLink } from '../../helpers/jira/JiraHelper';

const EntityJiraLink = ({ issuelinks, jiraConfig }) => {
  const { t } = useTranslation();

  return (
    <div className={`my-4 grid grid-cols-1 gap-5 rounded border border-gray-200 bg-gray-50 p-2`}>
      <div className="my-3">
        <div className="flex-row">
          <Heading
            className="w-36"
            type="small"
            text={t('Jira Links')}
          />
          <div className="flex max-h-24 flex-col overflow-y-auto ">
            {issuelinks.map((x) => {
              return (
                <>
                  <a
                    className={'cursor-pointer p-1 text-xs text-blue-900 underline-offset-4 hover:underline'}
                    href={getJiraLink(jiraConfig?.serverUrl, getIssuelinkKey(x, false).trim())}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getIssuelinkKey(x, true)}
                  </a>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityJiraLink;
