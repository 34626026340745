import React, { useState } from 'react';
import { isSystemAdmin } from '../../helpers/PriviligeHelper';
import SystemSettingsMenuItem from './SystemSettingsMenuItem';

const SystemSettingsMenu = ({ data }) => {
  const [isClickedMenu, setIsClickedMenu] = useState(false);

  return (
    <div className=" treeview-container">
      <div className="mb-2">
        {data?.map((item) => {
          if (isSystemAdmin() || item.everyoneAccess || item.isCanAddUser)
            return (
              <SystemSettingsMenuItem
                key={item?.id}
                item={item}
                isClickedMenu={isClickedMenu}
                setIsClickedMenu={setIsClickedMenu}
              />
            );
        })}
      </div>
    </div>
  );
};

export default SystemSettingsMenu;
