import React, { useState, useEffect } from 'react';
// LAYOUT IMPORTS
import { SidePaneLeftContent, SidePaneRightContent } from '../../components/Layout';
import UseQueryProject from '../../hooks/UseQueryProject';
import ReportsMenu from './ReportsMenu';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DefaultReportsPage from './DefaultReportsPage';
import KibanaReportsPage from './KibanaReportsPage';
import { Heading } from '../../designSystem/DesignSystem';
import { t } from 'i18next';
import { useApi } from '../../hooks/UseApi';
import KibanaReportsApi from '../../api/KibanaReportsApi';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useAppDispatch } from '../../context/AppContext';
import { DEFAULT_REPORT_LIST } from './DefaultReportList';
import { setSelectedReport } from '../../context/ProjectActions';
import { isValidValue } from '../../helpers/Helper';

const ReportsPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const [reports, setReports] = useState([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const reportsLoader = useApi({
    name: 'Getting reports',
    api: KibanaReportsApi.get,
    onSuccess: () => {
      setReports(reportsLoader?.data);
      if (location.pathname.includes('default')) {
        if (location.pathname.split('/')[3]) {
          dispatch(
            setSelectedReport(DEFAULT_REPORT_LIST.find((report) => report?.id === location.pathname.split('/')[3]))
          );
          navigate(`/reports/default/${location.pathname.split('/')[3]}?projectId=${projectId}`);
        } else {
          if (!isValidValue(DEFAULT_REPORT_LIST[0])) {
            dispatch(setSelectedReport([{}]));
            navigate(`/reports/default?projectId=${projectId}`);
          } else {
            dispatch(setSelectedReport(DEFAULT_REPORT_LIST[0]));
            navigate(`/reports/default/${DEFAULT_REPORT_LIST[0]?.id}?projectId=${projectId}`);
          }
        }
      }
      if (location.pathname.includes('kibana')) {
        if (location.pathname.split('/')[3]) {
          navigate(`/reports/kibana${location.pathname.split('/')[3]}?projectId=${projectId}`);
        } else {
          if (!isValidValue(reportsLoader?.data[0])) {
            dispatch(setSelectedReport([{}]));
            navigate(`/reports/kibana?projectId=${projectId}`);
          } else {
            dispatch(setSelectedReport(reportsLoader?.data[0]));
            navigate(`/reports/kibana/${reportsLoader?.data[0]}?projectId=${projectId}`);
          }
        }
      }
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    reportsLoader.execute({ projectId });
  }, []);

  return (
    <>
      <SidePaneLeftContent>
        <SidePaneLeftContent.Heading>
          <div className="ml-6 flex flex-col justify-center">
            <Heading.Duo
              type="large"
              text={t('Reports')}
            />
          </div>
        </SidePaneLeftContent.Heading>
        <ReportsMenu
          projectId={projectId}
          reports={reports}
        />
      </SidePaneLeftContent>
      <SidePaneRightContent>
        <Routes>
          <Route path="/">
            <Route
              path=""
              element={<Navigate to={`default?projectId=${projectId}`} />}
            />
            <Route
              path="default"
              element={
                <DefaultReportsPage
                  projectId={projectId}
                  defaultReports={DEFAULT_REPORT_LIST}
                />
              }
            />
            <Route
              path="default/*"
              element={
                <DefaultReportsPage
                  projectId={projectId}
                  defaultReports={DEFAULT_REPORT_LIST}
                />
              }
            />
            <Route
              path="kibana"
              element={
                <KibanaReportsPage
                  projectId={projectId}
                  kibanaReports={reports}
                  setKibanaReports={setReports}
                />
              }
            />
            <Route
              path="kibana/*"
              element={
                <KibanaReportsPage
                  projectId={projectId}
                  kibanaReports={reports}
                  setKibanaReports={setReports}
                />
              }
            />
          </Route>
        </Routes>
      </SidePaneRightContent>
    </>
  );
};

export default ReportsPage;
