import { useEffect, useState } from 'react';
import UserPreferenceApi from '../api/UserPreferenceApi';
import { useApp, useAppDispatch } from '../context/AppContext';
import { saveUserCustomTableColumnsByType, saveUserCustomTableFiltersByType } from '../context/ProjectActions';
import { useApi } from './UseApi';
import { showErrorMes } from '../helpers/NotificationHelper';

const UseUserTablePreferences = (projectId, entityType) => {
  const app = useApp();
  const dispatch = useAppDispatch();
  const tableFilters = app?.tableFilters?.[entityType];

  // !TODO: kontrol edilip value set value düzenlenmeli
  // let [values, setValues] = useState([]);
  let [userSavedFilters, setuserSavedFilters] = useState({});
  const tableColumns = app?.tableColumns?.[entityType];
  let [userSavedColumns, setuserSavedColumns] = useState({});

  const dataLoader = useApi({
    name: 'Get project configs',
    api: UserPreferenceApi.getTablePreferences,
    onSuccess: () => {
      let d = dataLoader?.data;
      let k = d?.tableFilters?.[entityType];
      // if(!isValidList(k)) k = [];
      setuserSavedFilters(k);
      dispatch(saveUserCustomTableFiltersByType(projectId, entityType, k));
      //
      let cc = d?.tableColumns?.[entityType];
      // if(!isValidList(k)) k = [];
      setuserSavedColumns(cc);
      dispatch(saveUserCustomTableColumnsByType(projectId, entityType, cc));
    },
    onError: (err) => showErrorMes(err)
  });

  const refresh = () => {
    if (projectId) dataLoader.execute({ projectId });
  };

  useEffect(() => {
    refresh();
  }, [tableFilters, projectId, tableColumns]);

  return { userSavedFilters, userSavedColumns, refresh };
};

export default UseUserTablePreferences;
