export const usePopup = ({ panelHeight, panelWidth }) => {
  let panelStyle = {};

  if (panelHeight) {
    panelStyle = { ...panelStyle, height: panelHeight };
  }
  if (panelWidth) {
    panelStyle = { ...panelStyle, width: panelWidth };
  }
  return {
    panelStyle
  };
};
