import { useApp } from '../context/AppContext';

const UseRefreshInsights = () => {
  const app = useApp();
  let refreshData = false;

  if (app?.refreshData) {
    refreshData = true;
  }
  return { refreshData };
};

export default UseRefreshInsights;
