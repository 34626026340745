import _ from 'lodash';
import moment from 'moment';
import { WarningCircle } from 'phosphor-react';
import { EntityLinkConstants } from '../components/entityDetails/EntityLinkConstants';
import { P } from '../designSystem/Typography/Typography';
import { approveType, ENTITY_TYPES } from '../constants';
import { buildParam } from './TableHelper';
import i18next from 'i18next';

export const ITEM_NAME_LENGTH = 30;

export const TippyVisible = (param) => {
  if (isValidValue(param)) {
    if (param.length < ITEM_NAME_LENGTH) return true;
  }
  return false;
};

export const replaceSpaceAll = (value) => {
  if (isValidValue(value)) {
    return value.replaceAll(' ', '');
  }
  return '';
};

export const isValidValue = (value) => {
  return value !== undefined && value !== null && value !== '' && value !== 'null';
};

export const getActiveIndex = (panes) => {
  let activeItem = 0;
  for (var i = 0; i < panes.length; i++) {
    if (panes[i] !== false) {
      activeItem = i;
      break;
    }
  }
  return activeItem;
};

export const updateDataForm = (key, newValue, setForm, errors) => {
  if (errors) errors[key] = undefined;
  setForm((prevState) => ({ ...prevState, [key]: newValue }));
  return errors;
};

export const convertDateToMs = (targetDate) => {
  if (targetDate && targetDate != null) return targetDate.getTime();
  return 0;
};

/**
 * @decription Convert ms date string to date object
 * @param {Date} date Ms Date
 * @returns {String} Date in format DD MMM YYYY | empty string if date is null
 */
export const msToDate = (date) => {
  if (date && date != null) return moment(date).format('DD MMM YYYY HH:mm');
  else return '';
};

/**
 * @description Converts iso date to date string
 * @param {Date} date Iso date object
 * @returns {String} Date in format DD MMM YYYY | empty string if date is null
 */
export const msToSecond = (date) => {
  if (date && date != null) return moment(date).format('DD MMM YYYY HH:mm:ss');
  else return '';
};

export const isValidList = (arrObject) => {
  if (arrObject && arrObject?.length > 0) return true;
  return false;
};

export const formatTitle = (data) => data?.name;

export const formatTitleWithJiraKey = (data) => `${data?.jiraKey} - ${data?.name}`;

/**
 * @description Simple string capilalize fn
 * @param {String} s String to be capitalized
 * @returns {String} Returns the capitalized string
 */

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  let ss = s.charAt(0);
  let sl = s.slice(1);
  return (ss ? ss.toUpperCase() : ss) + (sl ? sl.toLowerCase() : sl);
};

export const capitalizeTheFirstLetterOfEachWord = (words) => {
  var separateWord = words.toLowerCase().split(' ');
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(' ');
};

export const CHART_TYPE_TITLES = {
  PIE: 'Pie Chart',
  SUNBURST: 'Sunburst',
  RADIAL_BAR: 'Radial Bar Chart',
  BUMP: 'Bump Chart',
  BAR: 'Bar Chart',
  LINE: 'Line chart',
  AREA: 'Area Chart',
  METRIC: 'Metric',
  FIELD_METRIC: 'Field Metric',
  SCATTER_PLOT: 'Scatter Plot',
  AREA_BUMP: 'Arae Bump',
  CIRCLE_PACKING: 'Circle Packing',
  INSIGHT: 'Insight'
};

export const editModalMapping = (data) => {
  let customfield = [];
  let finalMappedArray = [];
  const mappedArray = Object.keys(data).map((element) => {
    if (element === 'customFields') {
      if (isValidList(data[element])) {
        data[element]?.forEach((item) => {
          customfield.push({ name: item.customFieldId, value: item.value });
        });
      }
    }
    return { name: element, value: data[element] };
  });
  finalMappedArray = mappedArray.concat(customfield);
  return finalMappedArray;
};

/**
 * @description Filter array of objects by name property
 * @param {object[]} arr Argument array
 * @param {string} item Item name string
 * @returns {object[]} Filtered object array
 */

export function findId(arr, item) {
  return arr.filter((element) => element.name === item);
}

export const arrayToString = (arr) => {
  if (arr && arr !== null && arr.length > 0) return arr.join(',');
  return null;
};

export const getUrl = (type, item, projectId) => {
  return `/${EntityLinkConstants[type].page}?${buildParam(item, EntityLinkConstants[type].pageParams, projectId, 0)}`;
};

export const getNewUrlPath = (projectId, path, selectedItem) => {
  if (selectedItem && selectedItem !== '' && selectedItem !== null) {
    if (selectedItem.type === ENTITY_TYPES.TEST_CASE) {
      return {
        pathname: path,
        search: `?projectId=${projectId}&type=${selectedItem.type}&id=${selectedItem.rootId}&version=${selectedItem.majorVersion}`
      };
    } else {
      return {
        pathname: path,
        search: `?projectId=${projectId}&type=${selectedItem.type}&id=${selectedItem.id}`
      };
    }
  } else {
    return {
      pathname: path,
      search: `?projectId=${projectId}`
    };
  }
};

export const getNewUrlModalPath = (projectId, path, selectedItem, type) => {
  if (selectedItem && selectedItem !== '' && selectedItem !== null) {
    if (type === ENTITY_TYPES.DEFECT) {
      return {
        pathname: path,
        search: `?projectId=${projectId}&defectId=${selectedItem?.id}`
      };
    } else if (type === ENTITY_TYPES.TEST_RUN) {
      return {
        pathname: path,
        search: `?projectId=${projectId}&runId=${selectedItem?.id}`
      };
    } else {
      return {
        pathname: path,
        search: `?projectId=${projectId}`
      };
    }
  } else {
    return {
      pathname: path,
      search: `?projectId=${projectId}`
    };
  }
};

export const dateFormatter = (date) => {
  if (isValidValue(date)) {
    let formattedDate = moment(date).toDate();
    return formattedDate;
  } else return '';
};

export const dateFormatterWithUniversalDate = (date) => {
  if (isValidValue(date)) {
    let formattedDate = moment(date).format('YYYY-MM-DD');
    return formattedDate;
  } else return '';
};

/**
 * @description Returns the username for the userId
 * @param {Array} userList User list array
 * @param {string} idValue Id string of the user
 * @returns {string} User name string or emtpy string if user not found
 */
export const getUserFirstLastNameById = (userList, idValue) => {
  if (userList && userList.length > 0) {
    let del = '(X) ';
    let user = userList.find((x) => x?.key === idValue);
    if (user) {
      let name = isValidValue(user?.fullname) ? user?.fullname : isValidValue(user?.text) ? user?.text : '';
      return user?.deleted === true ? del + name : name;
    }
    user = userList.find((x) => x?.id === idValue);

    if (user) {
      let name1 = isValidValue(user?.fullname) ? user?.fullname : isValidValue(user?.text) ? user?.text : '';
      let name = user?.name ? user.name : user?.firstName ? user?.firstName + ' ' + user?.lastName : name1;
      return user?.deleted === true ? del + name : name;
    }
    user = userList.find((x) => x?.username === idValue);

    if (user) {
      let name1 = isValidValue(user?.fullname) ? user?.fullname : isValidValue(user?.text) ? user?.text : '';
      let name = user?.name ? user.name : user?.firstName ? user?.firstName + ' ' + user?.lastName : name1;
      return user?.deleted === true ? del + name : name;
    }
    user = userList.find((x) => x?.userName === idValue);
    if (user) {
      let name1 = isValidValue(user?.fullname) ? user?.fullname : isValidValue(user?.text) ? user?.text : '';
      let name = user?.name ? user.name : user?.firstName ? user?.firstName + ' ' + user?.lastName : name1;
      return user?.deleted === true ? del + name : name;
    }
  }
  return '';
};

/*
Attachment ve comment alanlarında listelenen itemlerin tam olarak görüntülenmesi için yazılmıştır.
uygulama içerisinde her sayfanın yüksekliğinin bir diğerinden farklı olmasından kaynaklı olarak bu yöntem tercih edilmiştir.
*/
export const attachmentandCommentListSize = (entityType, actionType) => {
  //attachment
  if (actionType == 'comment') {
    if (entityType) {
      switch (entityType) {
        case ENTITY_TYPES.RELEASE:
        case ENTITY_TYPES.REQUIREMENT:
        case ENTITY_TYPES.FOLDER:
        case ENTITY_TYPES.TEST_CONTAINER:
          return '34rem';
        case ENTITY_TYPES.TEST_CASE:
          return '28rem';
        case ENTITY_TYPES.TEST_FOLDER:
        case ENTITY_TYPES.TEST_CYCLE:
        case ENTITY_TYPES.TEST_SUITE:
          return '28rem';
      }
    }
  }
  if (actionType == 'attachment') {
    if (entityType) {
      switch (entityType) {
        case ENTITY_TYPES.RELEASE:
        case ENTITY_TYPES.REQUIREMENT:
        case ENTITY_TYPES.FOLDER:
        case ENTITY_TYPES.TEST_CONTAINER:
          return '30rem';
        case ENTITY_TYPES.TEST_CASE:
          return '28rem';
        case ENTITY_TYPES.TEST_FOLDER:
        case ENTITY_TYPES.TEST_CYCLE:
        case ENTITY_TYPES.TEST_SUITE:
          return '24rem';
      }
    }
  }
};
export const getUserFirstLastNameByEmail = (userList, usernameOrEmail) => {
  if (userList && userList.length > 0) {
    let del = '(X) ';

    let user = userList.find((x) => x?.username === usernameOrEmail);
    if (user) {
      let name1 = isValidValue(user?.fullname) ? user?.fullname : isValidValue(user?.text) ? user?.text : '';
      let name = user?.name ? user.name : user?.firstName ? user?.firstName + ' ' + user?.lastName : name1;
      return user?.deleted === true ? del + name : name;
    }

    user = userList.find((x) => x?.userName === usernameOrEmail);
    if (user) {
      let name1 = isValidValue(user?.fullname) ? user?.fullname : isValidValue(user?.text) ? user?.text : '';
      let name = user?.name ? user.name : user?.firstName ? user?.firstName + ' ' + user?.lastName : name1;
      return user?.deleted === true ? del + name : name;
    }

    user = userList.find((x) => x?.email === usernameOrEmail);
    if (user) {
      let name1 = isValidValue(user?.fullname) ? user?.fullname : isValidValue(user?.text) ? user?.text : '';
      let name = user?.name ? user.name : user?.firstName ? user?.firstName + ' ' + user?.lastName : name1;
      return user?.deleted === true ? del + name : name;
    }
  }
  return usernameOrEmail;
};

export const findObjectByTarget = (arr, key, target) => {
  if (isValidList(arr)) {
    var field = arr.find((x) => x[key] === target);
    if (field) return field;
  }
  return null;
};

export const formatDate_ddmmyyyy = (date) => {
  if (date && date != null) return moment(date).format('DD MMM YYYY');
  else return '-';
};
export const translatedDateFormat = (date, formatType, t) => {
  let indexOfMonth;
  if (!date && date == null) return '-';
  else {
    formatType.split(' ').map((type, i) => {
      if (type.includes('M')) {
        indexOfMonth = i;
      }
    });
  }

  let fullDateArr = moment(date).format(formatType).split(' ');
  fullDateArr[indexOfMonth] = t(fullDateArr[indexOfMonth]);
  return fullDateArr.join(' ');
};

export const startDateEndDateControl = (date, t) => {
  const dateFormat = 'DD MMM YYYY';
  if (moment(date, dateFormat, true).isValid()) return translatedDateFormat(date, dateFormat, t);
  else return t(date);
};
export const formatDate_ddmmyyyyhhmmss = (date, t) => {
  const dateFormat = 'DD MMM YYYY  HH:mm:ss';
  if (date && date != null) return translatedDateFormat(date, dateFormat, t);
  else return '-';
};

export const formatItem = (text) => {
  if (isValidValue(text)) {
    if (text.includes('By')) {
      return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('By')) + ' ' + 'By';
    } else if (text.includes('Date')) {
      return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Date')) + ' ' + 'Date';
    } else if (text.includes('Time')) {
      return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Time')) + ' ' + 'Time';
    } else if (text.includes('Id')) {
      return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Id')) + ' ' + 'Id';
    } else return text.charAt(0).toUpperCase() + text.slice(1);
  } else return '';
};

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const getVersionText = (item) => {
  if (isValidValue(item?.approveType)) return `V${item?.majorVersion} ${i18next.t(approveType(item.approveType))}`;
  return `V${item?.majorVersion}`;
};

export const uniqueItems = (arr) => {
  var cleaned = [];
  arr.forEach(function (itm) {
    var unique = true;
    cleaned.forEach(function (itm2) {
      if (_.isEqual(itm, itm2)) unique = false;
    });
    if (unique) cleaned.push(itm);
  });
  return cleaned;
};

export const getOrderedByName = (arr) => {
  return arr.sort(sortArr('name', 'string'));
};

export const sortArr = (key, type) => {
  return function (a, b) {
    if (a?.[key] && b?.[key]) {
      if (type === 'string') {
        if (a[key].toLowerCase() > b[key].toLowerCase()) {
          return 1;
        } else if (a[key].toLowerCase() < b[key].toLowerCase()) {
          return -1;
        }
      } else {
        if (a[key] > b[key]) {
          return 1;
        } else if (a[key] < b[key]) {
          return -1;
        }
      }
    }
    return 0;
  };
};

export const DEFAULT_STATUS_COLOR = '#b8b8b8';

export const getStatusLabel = (text, backgroundColor) => {
  if (isValidValue(text)) {
    return (
      <label
        style={{
          marginLeft: 0,
          backgroundColor: isValidValue(backgroundColor) ? backgroundColor : DEFAULT_STATUS_COLOR,
          color: 'white'
        }}
      >
        {text}
      </label>
    );
  }
  return '';
};

/**
 *
 * @param {string} status - status of the item
 * @param {string} color - Background color of the status label
 */
export const getStatusTag = (status, color) => {
  if (isValidValue(status)) {
    return (
      <>
        <span
          className="m-1 rounded-md px-1 py-1 align-middle text-xs"
          style={{ backgroundColor: color ? color : '#7171beeb', color: 'white' }}
        >
          {' '}
          {status}
        </span>
      </>
    );
  }
  return '-';
};

/**
 * @description Check if the parameter content is a valid url
 * @param {string} content URL string to be checked
 * @returns {boolean} true if content is valid
 */
export const isValidUrl = (content) => {
  // eslint-disable-next-line no-useless-escape
  var pattern = new RegExp(/((\w+:\/\/)[-a-zA-Z0-9:@;?&=\/%\+\.\*!'\(\),\$_\{\}\^~\[\]`#|]+)/g);
  return pattern.test(content);
};

/**
 * @decription Formats the file size to a human readable format
 * @param {number} bytes File size in bytes
 * @returns {string} Human readable file size
 * */
export const fileSizeFormatter = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

const executionStatus = [
  { key: 1, text: 'Passed', value: 'PASSED', color: 'teal' },
  { key: 2, text: 'Failed', value: 'FAILED', color: 'red' },
  { key: 3, text: 'Not Complete', value: 'NOT_COMPLETE', color: 'orange' },
  { key: 4, text: 'Blocked', value: 'BLOCKED', color: 'purple' },
  { key: 4, text: 'N/A', value: 'NA', color: 'grey' },
  { key: 4, text: 'No Run', value: 'NO_RUN', color: 'grey' }
];

export const getStatusBarOptionsDynamic = (statuses) => {
  if (!statuses) return [];

  return Object.keys(statuses).map((key) => {
    let statusObj = { name: key, value: statuses[key] };

    let status = executionStatus.find((item) => item?.text === key || item?.value === key);
    if (status) {
      statusObj['color'] = status.color;
    }
    return statusObj;
  });
};

export const getRunStatusName = (runStatuskey) => {
  let status = executionStatus.find((item) => item?.value === runStatuskey);
  if (status) {
    return status.text;
  }
  return runStatuskey;
};

export const getUserOptionsWithGroups = (users, userGroups) => {
  let userOptions = isValidList(users) ? users : [];
  return isValidList(userGroups) ? userOptions.concat(userGroups) : userOptions;
};

export const getElasticRunStatusBarOptions = (statusElasticDataList) => {
  if (!isValidList(statusElasticDataList)) {
    return [];
  }

  let total = 0;
  let allList = statusElasticDataList.map((elem) => {
    let key = elem?.key;
    let count = elem?.doc_count;
    total += count;

    let statusObj = { name: key, value: count };
    let status = executionStatus.find((item) => item?.text === key || item?.value === key);
    if (status) {
      statusObj['color'] = status.color;
      statusObj['name'] = status.text;
    }
    return statusObj;
  });

  allList.push({ name: 'Total', value: total, color: '#276dc2' });
  return allList;
};

export const getUserName = (user) => {
  let del = '';
  if (user?.deleted === true) {
    del = '(X) ';
  }
  return user ? del + user.name : '';
};

export const showMoreButtonVisibility = (description, descriptionWidth) => {
  const multiplier = 5.7;
  if (isValidValue(description)) {
    if (description.trim().length * multiplier > descriptionWidth) return true;
  }
  return false;
};

export const errorMessage = (type, label, name, minLength, maxLength) => {
  return (
    <>
      <div className="-mb-2 mt-2 flex items-center gap-2 ">
        <WarningCircle
          className=" text-red-500"
          size={16}
          weight="duotone"
        />
        {type === 'required' && (
          <P
            small
            color="text-red-400"
            text={`${label ? capitalize(label) : name} ${i18next.t(`is required.`)}`}
          />
        )}
        {type === 'minLength' && (
          <P
            small
            color="text-red-400"
            text={i18next.t('helperErrorMessage', { name: name, minLength: minLength })}
          />
        )}
        {type === 'maxLength' && (
          <P
            small
            color="text-red-400"
            text={i18next.t('helperErrorMessageMax', { name: name, maxLength: maxLength })}
          />
        )}
      </div>
    </>
  );
};

export const getPageFilterInput = (params) => {
  return {
    page: params?.page ? params?.page : 0,
    size: params?.size ? params?.size : 10,
    sortBy: params?.sortBy ? params?.sortBy : 'id',
    sortDir: params?.sortDir ? params?.sortDir : 'ASC'
  };
};

/*
 * This function generate random number.
 * Returns integers from 0 to the max 32 bit integer
 */
export const cryptoRandom = () => {
  var array = new Uint32Array(1),
    max = Math.pow(2, 32),
    randomValue = window.crypto.getRandomValues(array)[0] / max;
  return randomValue;
};

export const generateRandomAlpahabetCharacters = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(cryptoRandom() * charactersLength));
  }
  return result;
};

export const generateRandomNumberCharacters = (length) => {
  var result = '';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(cryptoRandom() * charactersLength));
  }
  return result;
};

const getRandomInt = (n) => {
  return Math.floor(Math.random() * n);
};

export const shuffleStringCharacters = (s) => {
  var arr = s.split(''); // Convert String to array
  var n = arr.length; // Length of the array
  //
  for (var i = 0; i < n - 1; ++i) {
    var j = getRandomInt(n); // Get random of [0, n-1]
    //
    var temp = arr[i]; // Swap arr[i] and arr[j]
    arr[i] = arr[j];
    arr[j] = temp;
  }
  s = arr.join(''); // Convert Array to string
  return s; // Return shuffled string
};

export const calculateTimeLeft = (endDate) => {
  let timeLeft = null;

  if (isValidValue(endDate)) {
    let difference = +new Date(endDate) - +new Date();
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
  }
  return timeLeft;
};

export const getReportExcelNameDate = () => {
  var date = new Date();
  var curMonth = date.getMonth() + 1;
  var fileDateName;
  if (curMonth < 10) {
    fileDateName = date.getFullYear() + '' + '0' + curMonth + '' + date.getDate();
  } else {
    fileDateName = date.getFullYear() + '' + curMonth + '' + date.getDate();
  }
  return fileDateName;
};

/**
 * The function sorts the values in the array parameter according to the direction expression
 * @param {array} array Array is a array
 * @param {"asc" | "ASC" | "desc" | "DESC"} direction Direction of the sort algorithm
 * @returns {array | null} The return statement returns a list or null
 */
export const sortingMethod = (array, direction) => {
  if (direction.toLowerCase() === 'asc') {
    return array.sort(function (a, b) {
      let x = a.name.toLowerCase();
      let y = b.name.toLowerCase();
      if (x < y) return -1;
      if (y > x) return 1;
      return 0;
    });
  }
  if (direction.toLowerCase() === 'desc') {
    return array.sort(function (a, b) {
      let x = a.name.toLowerCase();
      let y = b.name.toLowerCase();
      if (x < y) return 1;
      if (y > x) return -1;
      return 0;
    });
  }
  return null;
};

/**
 * This function return system field required status
 * @param {"status" | "priority" | "type" | String} name
 * @param {array} systemFieldParams
 * @returns {boolean | null}
 */
export const checkRequired = (name, systemFieldParams) => {
  const fieldParam = systemFieldParams?.find((item) => item?.name === name);
  return fieldParam?.required;
};

/**
 * The function checks the file type and provides a new tab preview for the appropriate file types.
 * @param {String} url
 * @param {Object} item
 * @returns {URL | boolean}
 */
export const openInNewTab = (url, item) => {
  if (
    item?.originalName?.endsWith('.docx') ||
    item?.originalName?.endsWith('.doc') ||
    item?.originalName?.endsWith('.pdf') ||
    item?.originalName?.endsWith('.xlsx') ||
    item?.originalName?.endsWith('.xls')
  ) {
    return false;
  }
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const urlWithParam = (url, params = {}) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    params[key] && searchParams.append(key, params[key]);
  });
  const paramsStr = searchParams.toString();
  const buildedUrl = url + (paramsStr ? '?' + paramsStr : '');
  return buildedUrl;
};
/**
 * It was created to prevent this from happening when the names of the file creation buttons located on the Tree are long, there are shifts in the buttons.
 * @param {String} str string value to be truncated
 * @param {Number} num value specifying at what length the truncate structure should be engaged
 * @returns {String} truncated structure
 */
export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

/** This function converts string to arrayBuffer.
 * @param {string} data
 * @returns {ArrayBuffer} arrayBuffer data
 */
export const stringToArrayBuffer = (data) => {
  const buf = new ArrayBuffer(data.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = data.length; i < strLen; i++) {
    bufView[i] = data.charCodeAt(i);
  }
  return buf;
};

/**
 * ArrayBuffer converts base64
 * @param {array} buffer
 * @returns {string} base64 data
 * */
export const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const handleHeight = (tableHeight, preconditions) => {
  let height = tableHeight - 250;
  if (isValidValue(preconditions)) return height - 50;
  if (height) return height;
};
/**
 * Checks that parameter is String
 * @param {*} string
 * @returns {Boolean}
 */
export const isTrue = (string) => string === 'true';

export const dateIsValid = (date) => {
  return moment(date).isValid();
};

/**
 * This function filters an array containing objects by another array whose objects have the same id.
 * @param {Array} arr1 - first array
 * @param {Array} arr2 - second array
 * @returns {Array}
 */
export const filterByDifference = (arr1, arr2) => {
  let result = [];
  result = arr1.filter((item) => {
    return !arr2.find((element) => {
      return element.id === item.id;
    });
  });
  return result;
};

/**
 * This function return number inside sentence.
 * @param {string} sentence
 * @returns {Number} numericValue
 */
export const regexNumericValueControl = (sentence) => {
  let numericValue = 0;
  var regex = /\b(\d+)\b/; //A regex looking for a numeric value
  var result = regex.exec(sentence);
  if (result) {
    numericValue = parseInt(result[1]); //Extract the matching numeric value
  }
  return numericValue;
};

/**
 * This function is used for filtering on the (template) projects pages.
 * @param {string} key name or prefix choice
 * @param {string} value filter value from the search bar
 * @param {Array} filters filter rules
 * @param {*} setFilters set state method for filter rules
 */
export const applyFilter = (key, value, filters, setFilters) => {
  let input = {};
  if (key === 'prefix') {
    input = {
      ...filters,
      prefix: isValidValue(value) ? value : null
    };
  } else {
    input = {
      ...filters,
      name: isValidValue(value) ? value : null
    };
  }
  setFilters(input);
};

/**
 * This function is used for define filter operators in tables.
 * @param {string} f filter
 * @param {string} operator filterOperator
 * @returns {string}
 */
export const setFilterOperator = (f, operator) => {
  var op = 'eq';

  if (isValidValue(operator)) {
    let filterDefinition = operator.split(':');
    if (f === filterDefinition[0] && filterDefinition[1] == 'not in') {
      op = '!=';
    }
  }

  return op;
};
