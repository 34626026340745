import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CaretDown } from 'phosphor-react';
import { Controller } from 'react-hook-form';
import { classNames } from '../../helpers/Helper';
import { useTranslation } from 'react-i18next';

const MenuDropdown = (props) => {
  const { label, options, passValue, name, control, button, floatingClass } = props;
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={() => (
        <div className="relative z-10 inline-block w-full text-left">
          <Menu as="div">
            {({ open }) => (
              <>
                <Menu.Button
                  className={classNames(
                    'float-left flex h-8 items-center justify-center border tracking-wide transition duration-500 ease-in-out',
                    'gap-2 rounded-md px-3 py-1.5 text-xs hover:cursor-pointer',
                    button &&
                      'hover-border-dashed hover-border-primary-400 hover-text-primary-700 items-center rounded border-none bg-gray-100 text-xs font-medium text-gray-700 hover:border',
                    !button &&
                      'hover-bg-primary-100 items-center rounded-lg border border-violet-200 bg-transparent text-sm font-normal text-primary-700'
                  )}
                >
                  <div style={{ whiteSpace: 'nowrap' }}>{label}</div>
                  <CaretDown
                    {...(button && { size: 16, weight: 'bold' })}
                    className="-mr-1 ml-2"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-40 mt-7 origin-top overflow-hidden rounded bg-white shadow-lg">
                    <div className={floatingClass}>
                      {options.map((option) => (
                        <Menu.Item
                          key={option.value}
                          onClick={() => {
                            passValue(option);
                          }}
                        >
                          {({ active }) => (
                            <a
                              className={`block px-4 py-2 text-sm ${
                                active ? 'bg-primary-700 text-white' : 'text-gray-700'
                              }`}
                            >
                              <div className="grow">{t(option?.text)}</div>
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      )}
    />
  );
};

export default MenuDropdown;
