import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useApi } from '../../../../hooks/UseApi';
import CustomFieldApi from '../../../../api/CustomFieldApi';
import JiraApi from '../../../../api/JiraApi';
import UseQueryProject from '../../../../hooks/UseQueryProject';

import { Dropdown, Button, MultiSelectDropdownPill } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import { isValidList, isValidValue, sortArr } from '../../../../helpers/Helper';
import { showErrorMes } from '../../../../helpers/NotificationHelper';
import { getJiraProjectDropdownList, getProjectIssueTypeList } from '../../../../helpers/jira/JiraHelper';
import { translateArrItemName } from '../../../../helpers/TranslationHelper';

const JiraCustomFieldAddModal = ({ isOpen, closeModal, entityType, jiraConfig, getItems }) => {
  const { control, handleSubmit, reset, setValue } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { t } = useTranslation();
  const { projectId } = UseQueryProject('projectId');

  const [customFieldForm, setCustomFieldForm] = useState('');
  const [fields, setFields] = useState([]);
  const [configJiraProjectOptions, setConfigJiraProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedIssueType, setSelectedIssueType] = useState(null);
  const [jiraIssueTypes, setJiraIssueTypes] = useState([]);

  const fetchJIRAAllProjects = useApi({
    name: 'fetchJIRAAllProjects',
    api: JiraApi.fetchJIRAAllProjects,
    autoLoad: false,
    onSuccess: () => {
      setConfigJiraProjectOptions(getJiraProjectDropdownList(fetchJIRAAllProjects?.data));
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const addJiraCustomFields = useApi({
    name: 'addJiraCustomFields',
    api: CustomFieldApi.addJiraCustomFields,
    autoLoad: false,
    onSuccess: () => {
      closeModal();
      if (getItems) getItems();
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const fetchJIRAProjectWithFields = useApi({
    name: 'fetchJIRAProjectWithFields',
    api: JiraApi.fetchJIRAProjectWithFields,
    autoLoad: false,
    onSuccess: () => {
      let response = fetchJIRAProjectWithFields?.data;
      if (response && response?.issuetypes && response?.issuetypes.length > 0) {
        const types = response?.issuetypes;

        if (types && types.length > 0) {
          let typeFields = {};

          types.forEach((item) => {
            if (item?.id && item?.id === customFieldForm?.jiraIssueType?.id && item?.fields) typeFields = item?.fields;
          });

          if (typeFields) {
            let list = [];

            Object.keys(typeFields).forEach((customfFieldKey) => {
              if (customfFieldKey && customfFieldKey.includes('customfield')) {
                list.push({
                  key: customfFieldKey,
                  value: customfFieldKey,
                  text: typeFields[customfFieldKey].name + ' (' + customfFieldKey + ')',
                  name: typeFields[customfFieldKey].name
                });
              }
            });
            list = list.sort(sortArr('name'));
            setFields(list);
          }
        }
      }
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const getJiraProjectIssueTypes = useApi({
    name: 'fetch jira project issue type list',
    api: JiraApi.fetchJiraProjectIssueTypes,
    onSuccess: () => {
      setJiraIssueTypes(getProjectIssueTypeList(getJiraProjectIssueTypes?.data));
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const onSubmit = (formData) => {
    let inpFiels = [];
    if (formData?.selectedFieldIds) {
      formData?.selectedFieldIds.map((x) => {
        if (isValidValue(x?.id)) inpFiels.push(x?.id);
      });
    }
    addJiraCustomFields.execute({
      selectedFieldIds: inpFiels,
      entityType,
      integrationId: jiraConfig?.id,
      projectId: projectId,
      jiraProjectId: formData?.jiraProject?.[0]?.id,
      jiraIssueTypeId: formData?.jiraIssueType?.[0]?.id
    });
  };

  const getProjectFields = () => {
    if (!isValidValue(customFieldForm?.jiraProject?.id) || !isValidValue(customFieldForm?.jiraIssueType?.id)) return;

    setFields([]);

    fetchJIRAProjectWithFields.execute({
      projectId,
      projectIdOrKey: selectedProject?.id,
      issueIdOrKey: selectedIssueType?.id
    });
  };

  const getJiraFields = () => {
    fetchJIRAAllProjects.execute({ projectId });
  };

  useEffect(() => {
    setCustomFieldForm('');
    setFields([]);
    if (projectId) getJiraFields();
  }, [projectId]);

  useEffect(() => {
    getProjectFields();
  }, [selectedProject, selectedIssueType]);

  useEffect(() => {
    if (isValidValue(selectedProject)) {
      getJiraProjectIssueTypes.execute({ projectId: projectId, projectIdOrKey: selectedProject?.id });
    }
  }, [selectedProject]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
        w={'md'}
      >
        <Modal.Title>{t('Jira Custom Fields')}</Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div
              className="flex-grow flex-col gap-4"
              style={{ minHeight: 400 }}
            >
              <Dropdown
                required
                clearable
                control={control}
                label={t('Jira Project')}
                name="jiraProject"
                placeholder={t('Select Jira Project')}
                options={configJiraProjectOptions}
                selectedItem={customFieldForm?.jiraProject}
                passValue={(value) => {
                  setSelectedProject(value[0]);
                  setCustomFieldForm({ ...customFieldForm, jiraProject: value[0] });
                  if (!isValidList(value)) setValue('jiraProject', null);
                }}
              />
              <div className="pt-2">
                <Dropdown
                  clearable
                  required
                  control={control}
                  disabled={!isValidValue(customFieldForm?.jiraProject?.id)}
                  label={t('Type')}
                  name="jiraIssueType"
                  placeholder={t('Select Jira Issue Type')}
                  options={translateArrItemName(jiraIssueTypes, t)}
                  selectedItem={customFieldForm?.jiraIssueType}
                  passValue={(value) => {
                    setSelectedIssueType(value[0]);
                    setCustomFieldForm({ ...customFieldForm, jiraIssueType: value[0] });
                    if (!isValidList(value)) setValue('jiraIssueType', null);
                  }}
                />
              </div>
              <div className="pt-2">
                <MultiSelectDropdownPill
                  disabled={
                    !isValidValue(customFieldForm?.jiraIssueType?.id) || !isValidValue(customFieldForm?.jiraProject?.id)
                  }
                  control={control}
                  name="selectedFieldIds"
                  label={t('Jira Custom Fields (by issue type)')}
                  options={fields}
                  placeholder={t('Select Fields')}
                  value={customFieldForm?.selectedFieldIds}
                  passValue={(value) =>
                    setCustomFieldForm({
                      ...customFieldForm,
                      selectedFieldIds: value
                    })
                  }
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="jiraCustomFieldAddButton-cancel-button"
              secondary
              text={t('Cancel')}
              type="button"
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="jiraCustomFieldAddButton-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={addJiraCustomFields?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default JiraCustomFieldAddModal;
