// API & HOOKS
import React, { useState, useEffect } from 'react';
// DESIGN SYSTEM & COMPONENTS
import { ENTITY_TYPES } from '../../../constants';
import { getCycleName, getRunName, getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import { Modal } from '../../modal';
import { Body, Heading, Button } from '../../../designSystem/DesignSystem';
// HELPERS & CONSTANTS
import { getRunStatusName, translatedDateFormat } from '../../../helpers/Helper';
import { getReportEntityStatusPill } from '../ReportHelper';
import { useTranslation } from 'react-i18next';

const EntityDetailModalForReport = ({ item, isOpen, closeModal, projectConfig, entityType, jiraConfig }) => {
  const { t } = useTranslation();

  const [detail, setDetail] = useState([]);

  useEffect(() => {
    fillAllOptions();
  }, [entityType, item]);

  const fillAllOptions = () => {
    let arr = [];
    if (entityType && item) {
      if (entityType === ENTITY_TYPES.TEST_RUN) {
        arr.push({
          heading: t('Code'),
          text: item?.original?.code ? t(item?.original?.code) : '-'
        });
        arr.push({
          heading: t('Tester'),
          text: item?.original?.tester ? item?.original?.tester : '-'
        });
        arr.push({
          heading: t('Status'),
          text: item?.original?.lastStatus
            ? getReportEntityStatusPill(getRunStatusName(item?.original?.lastStatus), ENTITY_TYPES.TEST_RUN)
            : '-'
        });
        arr.push({
          heading: t('Last Execuiton Start'),
          text: item?.original?.lastExecutionStart
            ? translatedDateFormat(item?.original?.lastExecutionStart, 'DD MMM YYYY', t)
            : '-'
        });
        arr.push({
          heading: t('Last Execution End'),
          text: item?.original?.lastExecutionStart
            ? translatedDateFormat(item?.original?.lastExecutionEnd, 'DD MMM YYYY', t)
            : '-'
        });
        arr.push({
          heading: getTestCaseName(projectConfig),
          text: item?.original?.testCase ? item?.original?.testCase : '-'
        });
      } else if (entityType === ENTITY_TYPES.DEFECT) {
        arr.push({
          heading: t('Name'),
          text: item?.original?.name ? t(item?.original?.name) : '-'
        });
        arr.push({
          heading: t('Assignee'),
          text: item?.original?.assignee ? item?.original?.assignee : '-'
        });
        arr.push({
          heading: t('Status'),
          text: item?.original?.status ? getReportEntityStatusPill(item?.original?.status, entityType) : '-'
        });
        arr.push({
          heading: t('Type'),
          text: item?.original?.type ? t(item?.original?.type) : '-'
        });
        arr.push({
          heading: t('Priority'),
          text: item?.original?.priority ? t(item?.original?.priority) : '-'
        });
        arr.push({
          heading: getCycleName(projectConfig),
          text: item?.original?.testCycle ? item?.original?.testCycle : '-'
        });
        arr.push({
          heading: getRunName(projectConfig),
          text: item?.original?.testRun ? item?.original?.testRun : '-'
        });
        if (jiraConfig?.activeDefect === true) {
          arr.push({
            heading: t('Jira Status'),
            text: item?.original?.jiraStatus ? getReportEntityStatusPill(item?.original?.jiraStatus, entityType) : '-'
          });
          arr.push({
            heading: t('Jira Priority'),
            text: item?.original?.jiraPriority ? t(item?.original?.jiraPriority) : '-'
          });
        }
      }

      setDetail(arr);
    }
  };

  return (
    <>
      <Modal
        w={'lg'}
        open={isOpen}
        close={() => closeModal()}
      >
        <Modal.Title>{t('Rapor Detail')} </Modal.Title>
        <Heading.Group
          notCapitalize={true}
          type="base"
          text={item?.code}
        >
          <div className="-mt-6 flex gap-3">
            <Button
              id="entityDetailModalForReport-close-button"
              size={'xsmall'}
              icon="X"
              onClick={closeModal}
            />
          </div>
        </Heading.Group>
        <Modal.Content h={'md'}>
          <div className="m-2 flex flex-grow flex-col gap-3">
            {detail.map((x) => {
              return (
                <>
                  <div className={'mb-2 flex flex-row items-center gap-4'}>
                    <Heading
                      className="w-36"
                      type="small"
                      text={x?.heading}
                    />
                    <Body
                      type="medium"
                      text={x?.text}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default EntityDetailModalForReport;
