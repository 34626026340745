import React, { useState, useEffect, useRef } from 'react';
// LIBRARY IMPORTS
import { Listbox, Transition } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import { useFloating, offset, flip, shift } from '@floating-ui/react-dom';
import { useElementSize, useOnClickOutside } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
// DESIGN SYSTEM IMPORTS
import { X, WarningCircle } from 'phosphor-react';
import { P } from '../Typography/Typography';
// HELPER & CONSTANTS IMPORTS
import { capitalize, classNames } from '../../helpers/Helper';
import { dropdownOptions, returnButtonIconSVG, returnSelectedSVG } from './Dropdown.Utils';
import i18next from 'i18next';

const MultiSelectDropdownPill = ({
  control,
  name,
  label,
  options,
  value,
  passValue,
  disabled,
  placeholder,
  required,
  isSort = true
}) => {
  const { t } = useTranslation();
  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom',
    strategy: 'fixed',
    middleware: [offset(10), flip(), shift()]
  });

  const ref = useRef();
  useOnClickOutside(ref, () => setOpenExternal(false));

  const [buttonRef, { width }] = useElementSize();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openExternal, setOpenExternal] = useState(false);

  const mapOptions = dropdownOptions(options, isSort);

  const isSelected = (option) => (selectedOptions?.find((item) => item?.value === option?.value) ? true : false);

  const getFullOption = (option) => mapOptions.find((item) => item?.value === option);

  const handleSelection = (field, selection) => {
    const selectedResult = selectedOptions.filter((item) => item?.name === selection);

    if (selectedResult.length) {
      removeItem(selection);
    } else {
      let arr = [...selectedOptions];
      arr.push(getFullOption(selection));
      setSelectedOptions(arr);
      field.onChange(arr);
      if (passValue) passValue(arr);
    }
  };

  const removeItem = (field, item) => {
    const removedSelection = selectedOptions.filter((selected) => selected !== item);
    field.onChange(removedSelection);
    setSelectedOptions(removedSelection);
    if (passValue) passValue(removedSelection);
  };

  useEffect(() => {
    if (value) setSelectedOptions(value);
  }, [value]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field, formState }) => (
        <>
          <div
            ref={ref}
            className="flex flex-grow cursor-pointer flex-col items-center justify-center p-1"
          >
            <div
              ref={buttonRef}
              className="mx-auto w-full"
            >
              <Listbox
                as="div"
                className="z-40 space-y-2"
                value={selectedOptions}
                disabled={disabled}
                onChange={(e) => handleSelection(field, e)}
              >
                <>
                  <Listbox.Label className="block text-sm font-medium leading-5 text-gray-700">
                    {label}
                    {required && <span className={!disabled && 'ml-2 text-red-500   '}>*</span>}
                  </Listbox.Label>
                  <div className="relative">
                    <span className="inline-block w-full rounded-lg shadow-sm">
                      <Listbox.Button
                        onClick={() => setOpenExternal(!openExternal)}
                        ref={reference}
                        className={classNames(
                          'relative flex min-h-[36px] w-full cursor-default flex-wrap rounded-md',
                          'items-center border border-gray-300 bg-white py-1 pl-3 pr-10 text-left',
                          'transition duration-150 ease-in-out sm:text-sm sm:leading-5',
                          'focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-0'
                        )}
                      >
                        {!selectedOptions?.length && (placeholder ? placeholder : t('One or more items'))}
                        {selectedOptions?.map((option) => (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            key={option?.key}
                            className={
                              !disabled
                                ? 'my-1 mr-1 inline-flex  flex-wrap items-center rounded-lg bg-primary-600 px-2 text-xs text-white'
                                : 'my-1 mr-1 inline-flex cursor-not-allowed flex-wrap items-center rounded-lg bg-primary-600 px-2 text-xs text-white'
                            }
                          >
                            {option?.name || option?.code}
                            <div
                              className="ml-1 cursor-pointer"
                              onClick={() => {
                                !disabled && removeItem(field, option);
                              }}
                            >
                              {!disabled && <X weight="bold" />}
                            </div>
                          </div>
                        ))}
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          {returnButtonIconSVG()}
                        </span>
                      </Listbox.Button>
                    </span>
                    <Transition
                      show={openExternal}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="absolute z-50 mt-1 w-full rounded-md bg-white shadow-lg"
                    >
                      <Listbox.Options
                        static
                        ref={floating}
                        style={{
                          width,
                          position: strategy,
                          top: y,
                          left: x
                        }}
                        className={classNames(
                          'z-9999 shadow-xs z-50 max-h-60 overflow-auto rounded-md border border-gray-200 bg-white focus:outline-none ',
                          'text-base leading-6 sm:text-sm sm:leading-5'
                        )}
                      >
                        {mapOptions.map((option) => {
                          const selected = isSelected(option);
                          return (
                            <Listbox.Option
                              key={option?.key}
                              value={option?.value}
                              disabled={selected}
                            >
                              {({ active }) => (
                                <div
                                  className={`${
                                    active ? 'bg-primary-600 text-white' : 'text-gray-900'
                                  } relative cursor-default select-none py-2 pl-8 pr-4`}
                                >
                                  <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                    {option.name}
                                  </span>
                                  {selected && (
                                    <span
                                      className={`${
                                        active ? 'text-white' : 'text-primary-600'
                                      } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                    >
                                      {returnSelectedSVG()}
                                    </span>
                                  )}
                                </div>
                              )}
                            </Listbox.Option>
                          );
                        })}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              </Listbox>
              {formState?.errors[name] && !selectedOptions.length > 0 && (
                <>
                  {formState?.errors[name]?.type === 'required' && (
                    <div className="mt-2 flex items-center gap-2 ">
                      <WarningCircle
                        className=" text-red-500"
                        size={16}
                        weight="duotone"
                      />
                      <P
                        small
                        color="text-red-400"
                        text={`${label ? capitalize(label) : name} ${i18next.t(`is required.`)}`}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    />
  );
};

export { MultiSelectDropdownPill };
