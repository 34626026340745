import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import UseSystemParamsConfig from '../../../hooks/UseSystemParamsConfig';
import { useApi } from '../../../hooks/UseApi.jsx';
import SystemParamApi from '../../../api/SystemParamApi.js';
import { Button } from '../../../designSystem/DesignSystem';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import { ACCEPTED_FILE_TYPES, ENTITY_TYPES, ONE_MB_IN_BYTES } from '../../../constants';
import { fileTypeControl } from '../../../helpers/AttachmentHelpers';
import {
  attachmentandCommentListSize,
  fileSizeFormatter,
  isValidList,
  openInNewTab,
  translatedDateFormat
} from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const QuickExecutionAttachmentTab = ({ disabled, attachments, setAttachments, projectId }) => {
  const { t } = useTranslation();
  const systemParamsConfig = UseSystemParamsConfig(projectId);

  const maxUploadSize = systemParamsConfig?.find((item) => item.paramKey === 'maxUploadSize')?.paramVal;
  const [attachmentCountLimit, setAttachmentCountLimit] = useState(0);

  const accept = ACCEPTED_FILE_TYPES;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [item, setItem] = useState(null);

  const attachmentCountExisting = attachments?.length;

  const attachmentUploadCount = useApi({
    name: 'Get Attachment Upload Count',
    api: SystemParamApi.getAttachmentUploadCount,
    onSuccess: () => {
      setAttachmentCountLimit(attachmentUploadCount.data?.paramVal);
    },
    onError: (err) => showErrorMes(err)
  });

  const checkAttachmentCountLessThanLimit = (uploadedFileCount) => {
    return uploadedFileCount + attachmentCountExisting <= attachmentCountLimit;
  };

  const handleUpload = (acceptedFiles, fileRejections) => {
    if (isValidList(fileRejections)) {
      const maxFilesExceeded = fileRejections.some((rejection) =>
        rejection.errors.some((error) => error.code === 'too-many-files')
      );

      if (maxFilesExceeded) {
        showErrorMes(t('attachment_count_error', { attachmentCountLimit: attachmentCountLimit }));
        return;
      }

      fileRejections.forEach((rejection) => {
        const { file, errors } = rejection;
        errors.forEach((error) => {
          switch (error.code) {
            case 'file-too-large':
              showErrorMes(t('file_is_too_large', { fileName: file.name, maxFileSize: maxUploadSize }));
              break;
            case 'file-too-small':
              showErrorMes(t('file_is_too_small', { fileName: file.name }));
              break;
          }
        });
      });
    }

    if (checkAttachmentCountLessThanLimit(acceptedFiles?.length)) {
      let tempList = attachments;
      tempList = attachments.concat(acceptedFiles);
      setAttachments(tempList);
    } else {
      showErrorMes(t('attachment_count_error', { attachmentCountLimit: attachmentCountLimit }));
    }
  };

  const handleDelete = (name) => {
    setAttachments(attachments?.filter((item) => item.name !== name));
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    attachmentUploadCount.execute();
  }, []);

  return (
    <>
      <Dropzone
        maxFiles={attachmentCountLimit}
        disabled={disabled}
        accept={accept}
        multiple={true}
        maxSize={maxUploadSize}
        onDrop={(acceptedFiles, fileRejections) => handleUpload(acceptedFiles, fileRejections)}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="mb-6">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mt-4 flex cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex justify-center text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className={`relative cursor-pointer rounded-md bg-white font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 ${
                        disabled ? 'text-gray-400' : 'text-primary-500 hover:text-primary-600'
                      }`}
                    >
                      <span>{t('Upload a file')}</span>
                    </label>
                    <p className="pl-1">{t('or drag and drop')}</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    {t('maxFileUploadSize', { uploadSize: Math.floor(maxUploadSize / ONE_MB_IN_BYTES) })}
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      <div
        id="attachment-list"
        className="flex h-[300px] w-full flex-col space-y-4 divide-y-2 overflow-y-auto"
        style={{ height: attachmentandCommentListSize(ENTITY_TYPES.TEST_EXECUTION, 'attachment') }}
      >
        {attachments?.map((file) => (
          <div
            key={file.name}
            className="flex items-center gap-4 py-1"
            style={{ borderTopWidth: 'inherit' }}
          >
            <div
              id="attachment-thumbnail"
              className="flex h-12 w-24 items-center justify-center overflow-hidden"
            >
              {fileTypeControl(file, file?.name, true)}
            </div>
            <div
              id="attachment-metadata"
              className="flex flex-col justify-between"
            >
              <div id="attachment-metadata-name">
                <span
                  className="cursor-pointer text-sm font-semibold"
                  onClick={() => openInNewTab(file?.path, item)}
                >
                  {file?.name}
                </span>{' '}
                {'  '}
                <span className="text-xs text-gray-500">{fileSizeFormatter(file?.size)}</span>
              </div>
              <div
                id="attachment-metadata-size"
                className="text-xs text-gray-500"
              >
                {translatedDateFormat(file?.lastModifiedDate, 'DD MMM YYYY HH:mm', t)}
              </div>
              <div
                id="attachment-buttons"
                className="flex cursor-pointer"
              >
                <Button
                  id="attachmentItem-delete-button"
                  secondary
                  compact
                  noBorder
                  size="small"
                  icon="Trash"
                  weight="duotone"
                  color={'#7171beeb'}
                  disabled={disabled}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setItem(file);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      {openDeleteModal && (
        <ConfirmationModal
          isOpen={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          handleConfirm={() => handleDelete(item?.name)}
          header={t('Confirm Delete')}
          content={t('Do you really want to delete this?')}
        />
      )}
    </>
  );
};

export default QuickExecutionAttachmentTab;
