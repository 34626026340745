import React, { useMemo, useState, useEffect } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { useApi } from '../../hooks/UseApi';
import ProjectApi from '../../api/ProjectApi';

import UseTableHelper from '../../hooks/UseTableHelper';
import UseUserTablePreferences from '../../hooks/UseUserTablePreferences';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseTableColumns from '../../hooks/UseTableColumns';

import Table from '../../components/table/Table';
import { TableColumns } from '../../components/table/TableColumns';
import { getPageFilterInput } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import {
  DEFAULT_PAGE_PARAMS,
  disableResizingForColumns,
  getNotResizedColumnList,
  translateColumns
} from '../../helpers/TableHelper';
import { ENTITY_TYPES } from '../../constants';
import { saveProject } from '../../context/ProjectActions';
import { setProjectToken } from '../../helpers/ProjectTokenHelper';
import { openProject, openTemplate } from './ProjectHelper';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../context/AppContext';

const ProjectReactTable = ({ filter, setRefreshStatus, refreshStatus, users, height, showCheckbox, isTemplate }) => {
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();
  const { createTableData } = UseTableHelper();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('name', 'ASC'));
  const [customFilters, setCustomFilters] = useState({});
  const [rerenderKey, setRerenderKey] = useState(0);

  const dataLoader = useApi({
    name: 'fetching projects',
    api: ProjectApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
    },
    onError: (err) => showErrorMes(err)
  });

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    getItems(pageInput, customFilters);
  };

  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.PROJECT);

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) {
      fil = { ...fil, ...userSavedFilters?.filterInput };
    }
    let body = { ...fil, ...getPageFilterInput(pageInput) };
    dataLoader.execute(body);
  };

  const refreshTable = () => getByPage({});
  const loading = useMemo(() => dataLoader?.loading, [dataLoader?.loading]);

  useEffect(() => {
    if (refreshStatus === true) {
      refreshTable();
      setRerenderKey((prevKey) => prevKey + 1);
      setRefreshStatus(false);
    }
  }, [refreshStatus]);

  const tData = useMemo(() => {
    let data = [];
    if (dataLoader?.data?.content) {
      const columnsConfig = TableColumns[ENTITY_TYPES.PROJECT];
      data = dataLoader?.data?.content.map((item) => ({
        ...createTableData(item, columnsConfig, projectId, false, ENTITY_TYPES.PROJECT, users),
        name: (
          <button
            className=" text-primary-500"
            onClick={() => {
              isTemplate
                ? openTemplate(item, setProjectToken, dispatch, navigate, saveProject)
                : openProject(item, setProjectToken, dispatch, navigate, saveProject);
            }}
          >
            {item.name}
          </button>
        ),
        prefix: (
          <button
            className=" text-primary-500"
            onClick={() => {
              isTemplate
                ? openTemplate(item, setProjectToken, dispatch, navigate, saveProject)
                : openProject(item, setProjectToken, dispatch, navigate, saveProject);
            }}
          >
            {item.prefix}
          </button>
        )
      }));
    }
    return data;
  }, [dataLoader?.data?.content, getI18n().language]);

  let columns;

  const { selectedColumns } = UseTableColumns(projectId, ENTITY_TYPES.PROJECT);

  columns = disableResizingForColumns(selectedColumns, getNotResizedColumnList(ENTITY_TYPES.PROJECT));
  columns = translateColumns(columns, t);

  return (
    <Table
      key={rerenderKey}
      columns={columns}
      data={tData}
      pagination
      loading={loading}
      pageParams={pageParams}
      getByPage={getByPage}
      onFilter={onFilter}
      entityType={ENTITY_TYPES.PROJECT}
      submitCallBack={() => refreshTable()}
      showCheckbox={showCheckbox}
      height={height}
    />
  );
};

export default ProjectReactTable;
