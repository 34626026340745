export const cycleUserDefectCountDefaultReportColumns = [
  {
    Header: 'Created By',
    accessor: 'key.createdBy',
    textAlign: 'left'
  },
  {
    Header: 'Project Name',
    accessor: 'key.projectName',
    textAlign: 'left'
  },
  {
    Header: 'Cycle Code',
    accessor: 'key.cycleCode',
    textAlign: 'left'
  },
  {
    Header: 'Cycle Name',
    accessor: 'key.cycleName',
    textAlign: 'left'
  },
  {
    Header: 'Defect status',
    accessor: 'key.status',
    textAlign: 'left'
  },
  {
    Header: 'Defect Count',
    accessor: 'doc_count',
    textAlign: 'left'
  }
];

export const suiteTestCaseExecutionCountDefaultReportColumns = [
  {
    Header: 'Test Suite Code',
    accessor: 'testSuiteCode',
    textAlign: 'left'
  },
  {
    Header: 'Test Suite Name',
    accessor: 'testSuiteName',
    textAlign: 'left'
  },
  {
    Header: 'Test Case Code',
    accessor: 'testCaseCode',
    textAlign: 'left'
  },
  {
    Header: 'Summary',
    accessor: 'summary',
    textAlign: 'left'
  },
  {
    Header: 'Tester',
    accessor: 'tester',
    textAlign: 'left'
  },
  {
    Header: 'Execution Count',
    accessor: 'totalValue',
    textAlign: 'left'
  }
];
