import React, { useState, useEffect } from 'react';
import { MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import { getOptionList } from '../../../helpers/CustomFieldHelper';
import { isValidList } from '../../../helpers/Helper';

const MultiSelectDropdownCustomField = (props) => {
  const { control, field, selectedItem, setCustomFieldForm, disabled } = props;
  const [options, setOptions] = useState([]);
  const [item, setItem] = useState([]);

  useEffect(() => {
    setOptions(getOptionList(field));
    const selectItems = [];
    getOptionList(field).forEach((option) => {
      // !todo: fix this
      if (isValidList(selectedItem)) {
        if (isValidList(selectedItem.filter((item) => item === option?.value))) {
          selectItems.push(option);
        }
      }
    });
    setItem(selectItems);
  }, [field]);

  const setCustomField = (value) => {
    if (value?.length > 0) {
      const arr = [];
      value.map((item) => {
        arr.push(item?.value);
        setCustomFieldForm((prev) => ({ ...prev, [field.customFieldId]: arr }));
      });
    } else {
      setCustomFieldForm((prev) => ({ ...prev, [field.customFieldId]: [] }));
    }
  };

  return (
    <>
      <MultiSelectDropdownPill
        control={control}
        name={field?.customFieldId}
        required={field?.required}
        label={field?.fieldName}
        options={options}
        value={item}
        disabled={disabled}
        passValue={(value) => setCustomField(value)}
      />
    </>
  );
};
export default MultiSelectDropdownCustomField;
