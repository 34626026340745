import React, { useState, useEffect } from 'react';
// API & HOOKS
import TestCycleApi from '../../../api/TestCycleApi';
import { useApi } from '../../../hooks/UseApi';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseProjectUsers from '../../../hooks/UseProjectUsers';
import UseCustomField from '../../../hooks/UseCustomField';
// CONSTANTS & HELPERS
import { isValidValue } from '../../../helpers/Helper';
import { ENTITY_TYPES } from '../../../constants';
import { showErrorMes } from '../../../helpers/NotificationHelper';
// COMPONENETS
import InsightGrid from '../../../components/insight/InsightGrid';
import CustomEntityDetail from '../../../components/detail/CustomEntityDetail';

const TestCycleDetails = ({ selectedItem, submitCallBack, submitCallBackAfterDeletion, submitCallBackAfterClone }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const customFields = UseCustomField(projectId, ENTITY_TYPES.TEST_CYCLE);
  const users = UseProjectUsers(projectId);

  const [dataForm, setDataForm] = useState(null);
  const [filter, setFilter] = useState({});
  const [refreshStatus, setRefreshStatus] = useState(false);

  const getTestCycles = useApi({
    name: 'fetching test cycle item',
    api: TestCycleApi.getTestCycle,
    autoLoad: false,
    onSuccess: () => setDataForm(getTestCycles.data),
    onError: (err) => showErrorMes(err)
  });

  const refreshData = (form) => {
    if (isValidValue(form)) {
      setDataForm(form);
      submitCallBack(form);
      setRefreshStatus(true);
    } else {
      getTestCycles.execute({ id: selectedItem.id });
    }
  };

  const afterDelete = (data) => isValidValue(data) && submitCallBackAfterDeletion(data);

  useEffect(() => {
    refreshData();
    setFilter({ testCycleId: selectedItem?.id });
  }, [selectedItem]);

  const getTopContent = () => {
    return (
      <div className="mb-4 mt-4 flex flex-grow">
        <InsightGrid
          projectConfig={projectConfig}
          entityId={selectedItem?.id}
          entityType={ENTITY_TYPES.TEST_CYCLE}
        />
      </div>
    );
  };

  return (
    <>
      <CustomEntityDetail
        config={{
          entityId: selectedItem?.id,
          entityType: ENTITY_TYPES.TEST_CYCLE,
          users,
          refreshStatus,
          setRefreshStatus,
          loading: getTestCycles?.loading,
          dataForm: dataForm,
          selectedItem,
          submitCallBack: refreshData,
          submitCallBackAfterDeletion: afterDelete,
          submitCallBackAfterClone,
          projectId,
          customFields,
          getTopContent,
          filter
        }}
      />
    </>
  );
};

export default TestCycleDetails;
