import React, { useEffect, useState } from 'react';
import { fillChartData, translateChart } from '../helper/DashboardHelper';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import UseProjectConfig from '../../../hooks/UseProjectConfig';

const CustomMetricChart = (props) => {
  const { chart, projectId, dateFilter } = props;

  const projectConfig = UseProjectConfig(projectId);
  const [data, setData] = useState(0);

  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      fillChartData(dataLoader?.data, chart, setData, projectId);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);

  return (
    <div className="flex-col">
      <p className="text-2xl text-center font-semibold text-gray-800">{data}</p>
      <p className="text-center text-sm text-gray-500">
        {translateChart(chart?.name, chart?.entityType, projectConfig)}
      </p>
    </div>
  );
};

export default CustomMetricChart;
