export const EVENT_TYPES = {
  UPDATED: '-NodeUpdated',
  CREATED: '-NodeCreated',
  DELETED: '-NodeDeleted',
  NEED_REFRESH: '-NeedRefresh'
};

const dispatch = (entity, eventType, item) => {
  const event = new CustomEvent(entity + eventType, {
    detail: item,
    bubbles: true,
    cancelable: true,
    composed: false
  });
  document.dispatchEvent(event);
};

const subscribe = (entity, eventType, listener) => {
  document.addEventListener(entity + eventType, listener);
};

const unsubscribe = (entity, eventType, listener) => {
  document.removeEventListener(entity + eventType, listener);
};

export { dispatch, subscribe, unsubscribe };
