import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useApp } from '../../../../context/AppContext';
import UseSelectRow from '../../../../hooks/UseSelectRow';
import UseQueryProject from '../../../../hooks/UseQueryProject';
import { useApi } from '../../../../hooks/UseApi';
import VisiumGoIntegrationApi from '../../../../api/VisiumGoIntegrationApi';

import { Button, Heading } from '../../../../designSystem/DesignSystem';
import { SidePaneRightContent } from '../../../../components/Layout';

import Table from '../../../../components/table/Table';
import ConfirmationModal from '../../../../components/confirm/ConfirmationModal';
import VisiumGoProjectIntegration from './VisiumGoProjectIntegration';
import VisiumGoIntegrationModal from './modal/VisiumGoIntegrationModal';

import { convertToTableData, handleSelectedRowWithId, translateColumns } from '../../../../helpers/TableHelper';
import { showErrorMes } from '../../../../helpers/NotificationHelper';
import { isValidList } from '../../../../helpers/Helper';

const VisiumGoPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();
  const app = useApp();
  const selectedRows = app?.selectedRows;
  const { showButton } = UseSelectRow();

  const [pageParams, setPageParams] = useState({ page: 0, size: 10, sortBy: 'name', sortDir: 'ASC', defaultSize: 10 });
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const selectedRowsIds = useMemo(() => selectedRows?.map((row) => row?.original?.id), [selectedRows]);

  const getVisiumGoIntegrations = useApi({
    name: 'get',
    api: VisiumGoIntegrationApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: getVisiumGoIntegrations?.data.size,
        pageSize: getVisiumGoIntegrations?.data.pageSize,
        totalPages: getVisiumGoIntegrations?.data?.totalPages,
        totalElements: getVisiumGoIntegrations?.data?.totalElements
      }));
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteVisiumGoIntegration = useApi({
    name: 'delete',
    api: VisiumGoIntegrationApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      setConfirmOpen(false);
      setSelectedItem(null);
      refreshTable();
    },
    onError: (err) => showErrorMes(err)
  });

  const refreshTable = () => {
    getByPage({});
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    setPageParams(pageInput);
    getItems(pageInput, {});
  };

  const getItems = (pageInput, filterInput) => {
    const body = { ...pageInput, projectId, ...filterInput };
    getVisiumGoIntegrations.execute(body);
  };

  const onDeleteData = () => {
    deleteVisiumGoIntegration.execute({ id: selectedItem?.id });
  };

  let tableColumns = [
    { Header: `Name`, accessor: 'name' },
    { Header: `URL`, accessor: 'url' },
    { Header: `Active`, accessor: 'active' }
  ];

  tableColumns = translateColumns(tableColumns, t);

  const tableData = useMemo(
    () => convertToTableData(getVisiumGoIntegrations?.data?.content, 'visiumGo'),
    [getVisiumGoIntegrations?.data?.content]
  );

  const loading = useMemo(() => getVisiumGoIntegrations?.loading, [getVisiumGoIntegrations?.loading]);

  useEffect(() => {
    if (projectId) refreshTable();
  }, [projectId]);

  useEffect(() => {
    if (isValidList(selectedRowsIds)) {
      getVisiumGoIntegrations?.data?.content.forEach((item) => {
        if (item?.id === selectedRowsIds[0]) setSelectedItem(item);
      });
    }
  }, [selectedRowsIds]);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            text={'Visium Go'}
            type="large"
          >
            <div className="flex gap-4">
              {showButton && (
                <>
                  {' '}
                  <Button
                    id="visiumgo-edit-button"
                    secondary
                    text={t('Edit')}
                    size="small"
                    icon="PencilLine"
                    onClick={() => {
                      handleSelectedRowWithId(
                        getVisiumGoIntegrations?.data?.content,
                        selectedRowsIds[0],
                        setSelectedItem
                      );
                      setModalVisibility(true);
                    }}
                  />
                  <Button
                    id="visiumgo-delete-button"
                    secondary
                    disabled={selectedItem?.active}
                    text={t('Delete')}
                    size="small"
                    icon="Trash"
                    onClick={() => {
                      handleSelectedRowWithId(
                        getVisiumGoIntegrations?.data?.content,
                        selectedRowsIds[0],
                        setSelectedItem
                      );
                      setConfirmOpen(true);
                    }}
                  />
                </>
              )}
              <Button
                id="visiumgo-add-button"
                primary
                text={t('Add')}
                size="small"
                icon="Plus"
                onClick={() => setModalVisibility(true)}
              />
            </div>
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center colFull={true}>
            <div className="mt-6">
              <Table
                showCheckbox={true}
                columns={tableColumns}
                data={tableData}
                loading={loading}
                getByPage={getByPage}
                entityType={'visiumGo'}
                compact
                onlyOneRowSelect={true}
              />
            </div>
            {selectedRowsIds?.length > 0 && (
              <VisiumGoProjectIntegration
                selectedVgo={selectedItem}
                projectId={projectId}
              />
            )}
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>

      {modalVisibility === true && (
        <VisiumGoIntegrationModal
          isOpen={modalVisibility}
          closeModal={() => {
            setModalVisibility(false);
            setSelectedItem(null);
            refreshTable();
          }}
          data={selectedItem}
          projectId={projectId}
        />
      )}

      {confirmOpen === true && (
        <ConfirmationModal
          isOpen={confirmOpen}
          loading={deleteVisiumGoIntegration?.loading}
          closeModal={() => setConfirmOpen(false)}
          handleConfirm={onDeleteData}
          header={t('Delete')}
          content={t('Do you want to delete data?')}
        />
      )}
    </>
  );
};
export default VisiumGoPage;
