import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// API & HOOKS
import { useForm } from 'react-hook-form';
import JiraApi from '../../../../api/JiraApi';
import { useApi } from '../../../../hooks/UseApi';
// DESIGN SYSTEM
import { Dropdown, Button } from '../../../../designSystem/DesignSystem';
// COMPONENT
import { Modal } from '../../../../components/modal';
// HELPER & CONSTANTS
import { ENTITY_TYPES } from '../../../../constants';
import { editModalMapping, getOrderedByName, isValidList, isValidValue } from '../../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { getProjectIssueTypeList } from '../../../../helpers/jira/JiraHelper';
import { translateArrItemName } from '../../../../helpers/TranslationHelper';

const JiraEntitySettingModal = (props) => {
  const {
    isOpen,
    closeModal,
    submitCallback,
    selectedIntegrationConfigId,
    data,
    jiraProjects,
    jiraIssueFields,
    entityType,
    projectConfig
  } = props;

  const { t } = useTranslation();
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const [defaultConfigs, setDefaultConfigs] = useState({});
  const [releaseTypes, setReleaseTypes] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [jiraIssueTypes, setJiraIssueTypes] = useState([]);
  const [folder, setFolder] = useState(null);
  const [subFolder, setSubFolder] = useState(null);

  const getJiraProjectIssueTypes = useApi({
    name: 'fetch jira project issue type list',
    api: JiraApi.fetchJiraProjectIssueTypes,
    onSuccess: () => {
      setJiraIssueTypes(getProjectIssueTypeList(getJiraProjectIssueTypes?.data));
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const createEditData = useApi({
    name: 'saving jira server config',
    api: isValidValue(data) ? JiraApi.updateJiraConfig : JiraApi.createJIRAConfig,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal(createEditData?.data);
      if (submitCallback) submitCallback();
    },
    onError: (err) => showErrorMes(err)
  });

  const fetchJiraDefaultConfigs = useApi({
    name: 'fetching jira config item',
    api: JiraApi.fetchJiraDefaultConfigs,
    autoLoad: false,
    onSuccess: () => setDefaultConfigs(fetchJiraDefaultConfigs?.data[entityType]),
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (formData) => {
    let folderInput = {};

    if (entityType === ENTITY_TYPES.REQUIREMENT) {
      folderInput = {
        jiraFolderField: formData?.jiraFolderField?.id,
        jiraSubFolderField: isValidValue(formData?.jiraFolderField?.id) ? formData.jiraSubFolderField?.id : ''
      };
    }

    const input = {
      entityType,
      integrationConfigId: selectedIntegrationConfigId,
      jiraIssueTypeId: formData?.jiraIssueType?.id,
      jiraProjectId: formData?.jiraProject?.id,
      configs: {
        ...folderInput
      }
    };

    createEditData.execute({ ...input, id: data?.id });
  };

  const releaseTypesTranslated = () => {
    if (entityType === ENTITY_TYPES.RELEASE) return translateArrItemName(releaseTypes, t);
    return translateArrItemName(jiraIssueTypes, t);
  };

  useEffect(() => {
    if (defaultConfigs) {
      if (isValidList(defaultConfigs?.types)) {
        let typeList = [];
        defaultConfigs.types.map((type) => {
          typeList.push({
            id: type.key,
            name: type.title
          });
        });
        setReleaseTypes(typeList);
      }
    }
  }, [defaultConfigs]);

  useEffect(() => {
    if (isValidValue(data)) {
      if (entityType === ENTITY_TYPES.REQUIREMENT) {
        jiraIssueFields.map((x) => {
          if (x?.id === data?.configs?.jiraFolderField) {
            setFolder({ name: x?.name, id: x?.id });
            setValue('jiraFolderField', { name: x?.name, id: x?.id });
          }
          if (x?.id === data?.configs?.jiraSubFolderField) {
            setSubFolder({ name: x?.name, id: x?.id });
            setValue('jiraSubFolderField', { name: x?.name, id: x?.id });
          }
        });
      }
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, [data]);

  useEffect(() => {
    fetchJiraDefaultConfigs.execute();
  }, []);

  useEffect(() => {
    if (isValidValue(selectedProject)) {
      getJiraProjectIssueTypes.execute({ projectId: projectConfig.projectId, projectIdOrKey: selectedProject?.id });
    }
  }, [selectedProject]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t(`JIRA Config Settings`)}</Modal.Title>
        <form
          className="flex  flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex min-h-[400px] flex-grow flex-col gap-4">
              <div className="flex gap-4">
                <Dropdown
                  required
                  control={control}
                  disabled={isValidValue(data?.jiraProject)}
                  label={t('Jira Project')}
                  name="jiraProject"
                  placeholder={t('Select Jira Project')}
                  options={jiraProjects}
                  selectedItem={data?.jiraProject}
                  passValue={(value) => {
                    setSelectedProject(value[0]);
                    setValue('jiraProject', value[0]);
                  }}
                />
              </div>
              <div className="flex gap-4">
                <Dropdown
                  clearable
                  required
                  control={control}
                  disabled={!selectedProject || isValidValue(data?.jiraIssueType)}
                  options={releaseTypesTranslated()}
                  label={t('Type')}
                  name="jiraIssueType"
                  placeholder={t('Select Jira Issue Type')}
                  selectedItem={data?.jiraIssueType}
                  passValue={(value) => {
                    setValue('jiraIssueType', value[0]);
                    if (!isValidList(value)) {
                      setValue('jiraIssueType', null);
                    }
                  }}
                />
              </div>
              {entityType === ENTITY_TYPES.REQUIREMENT && (
                <div className="flex gap-4">
                  <Dropdown
                    clearable
                    control={control}
                    label={t('Folder')}
                    name="jiraFolderField"
                    placeholder={t('Select Field')}
                    options={getOrderedByName(jiraIssueFields)}
                    selectedItem={folder}
                    passValue={(value) => setValue('jiraFolderField', value[0])}
                  />
                  <Dropdown
                    clearable
                    control={control}
                    label={t('Sub Folder')}
                    name="jiraSubFolderField"
                    placeholder={t('Select Field')}
                    options={getOrderedByName(jiraIssueFields)}
                    selectedItem={subFolder}
                    passValue={(value) => setValue('jiraSubFolderField', value[0])}
                  />
                </div>
              )}
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="jiraEntitySettingModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="jiraEntitySettingModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEditData?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default JiraEntitySettingModal;
