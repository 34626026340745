import { UserCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
// API & HOOKS IMPORTS
import { useForm } from 'react-hook-form';
import CommentApi from '../../api/CommentApi';
// COMPONENT IMPORTS
import ConfirmationModal from '../../components/confirm/ConfirmationModal';
// DESIGN SYSTEM IMPORTS
import { Body, Heading, Button, TextArea, Loader } from '../../designSystem/DesignSystem';
// HELPER & CONSTANTS IMPORTS
import {
  attachmentandCommentListSize,
  getUserFirstLastNameById,
  isValidValue,
  msToSecond,
  translatedDateFormat
} from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { getSessionUser, isPriviligedDeleteComment, isPriviligedEditComment } from '../../helpers/PriviligeHelper';
import { useApi } from '../../hooks/UseApi';
import UseQueryProject from '../../hooks/UseQueryProject';
import { CARD_BODY_SIZE, CARD_DATE_SIZE, CARD_HEADING_SIZE, CARD_USER_ICON_SIZE } from '../history/HistoryDetails';
import CommentEditModal from './CommentEditModal';
import { useTranslation } from 'react-i18next';
import UseAllUsers from '../../hooks/UseAllUsers';

const Comment = (props) => {
  const { entityId, entityType, refreshStatus, setRefreshStatus, submitCallBack, lockStatus } = props;

  const { t } = useTranslation();

  const { control, handleSubmit, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });

  const { projectId } = UseQueryProject('projectId');
  const userId = getSessionUser()?.id;
  const users = UseAllUsers();

  const [items, setItems] = useState([]);
  const [editCommentModal, setEditCommentModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDeleteComment, setOpenDeleteComment] = useState(false);
  //
  // <-- API SETUP -->
  const dataLoader = useApi({
    name: 'get comment',
    api: CommentApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setItems(dataLoader.data);
      submitCallBack(dataLoader?.data?.length);
    },
    onError: (err) => showErrorMes(err)
  });

  const add = useApi({
    name: 'create comment',
    api: CommentApi.create,
    onSuccess: () => {
      getItems();
      setValue('comment', '');
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteComment = useApi({
    name: 'delete comment',
    api: CommentApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      setOpenDeleteComment(false);
      getItems();
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (formData) => {
    let isEmpty = formData?.comment?.trim().length > 0;
    if (isValidValue(formData?.comment) && entityId && entityType && projectId && isEmpty)
      add.execute({
        comment: formData?.comment,
        projectId,
        entityId,
        entityType,
        userId
      });
  };

  const getItems = () => {
    if (entityId && entityType && projectId) dataLoader.execute({ projectId, entityId, entityType });
  };

  const onEditComment = (param) => {
    if (isPriviligedEditComment(param?.createdBy)) {
      setEditCommentModal(true);
      setSelectedItem(param);
    }
  };

  const handleDelete = () => {
    if (isPriviligedDeleteComment(selectedItem?.createdBy)) deleteComment.execute(selectedItem?.id);
  };

  const isEdited = (param) => {
    if (msToSecond(param?.modifiedTime) !== msToSecond(param?.createdTime)) {
      return (
        <Body
          type={CARD_DATE_SIZE}
          text={t(' - Edited')}
        />
      );
    }
  };

  useEffect(() => {
    getItems();
  }, [entityId, entityType]);

  useEffect(() => {
    if (refreshStatus === true) {
      getItems();
      if (setRefreshStatus) setRefreshStatus(false);
    }
  }, [refreshStatus]);

  return (
    <>
      <div className="flex w-full flex-grow flex-wrap">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mb-6 flex w-full flex-col gap-4"
        >
          <div className="flex w-full flex-col gap-2">
            <TextArea
              name="comment"
              placeholder={t('Comment')}
              control={control}
            />
            <div className="mr-1">
              <Button
                id="comment-submit-button"
                right
                secondary
                disabled={!isValidValue(entityId) || lockStatus?.addComment}
                loading={add?.loading}
                type="submit"
                icon="ChatCircleText"
                text={t('Comment')}
                size="small"
              />
            </div>
          </div>
        </form>
        {dataLoader.loading ? (
          <Loader />
        ) : (
          <div
            className="w-full space-y-4 overflow-y-auto"
            style={{ height: attachmentandCommentListSize(entityType, 'comment') }}
          >
            {items.map((elem) => {
              return (
                <>
                  <div className="flex items-center justify-between rounded-md border border-gray-200 bg-gray-50 p-2 ">
                    <div className="flex items-center gap-4">
                      <div style={{ width: 30 }}>
                        <UserCircle
                          weight="duotone"
                          size={CARD_USER_ICON_SIZE}
                          color={'#7171beeb'}
                        />{' '}
                      </div>
                      <div className="flex flex-col gap-1">
                        <Heading
                          type={CARD_HEADING_SIZE}
                          text={getUserFirstLastNameById(users, elem?.modifiedBy)}
                        />
                        <div className="flex flex-row items-center  gap-1">
                          <Body
                            type={CARD_DATE_SIZE}
                            text={translatedDateFormat(elem?.modifiedTime, 'DD MMM YYYY HH:mm', t)}
                          />
                          {isEdited(elem)}
                        </div>
                        <div className="mt-1">
                          <Body
                            type={CARD_BODY_SIZE}
                            text={elem?.comment}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ml-10 flex gap-1">
                      {isPriviligedEditComment(elem?.createdBy) && (
                        <>
                          <Button
                            id="comment-edit-button"
                            onClick={() => onEditComment(elem)}
                            noBorder
                            secondary
                            size="xsmall"
                            icon="Pencil"
                            disabled={lockStatus?.updateComment}
                          />
                        </>
                      )}
                      {isPriviligedDeleteComment(elem?.createdBy) && (
                        <>
                          <Button
                            id="comment-delete-button"
                            secondary
                            noBorder
                            disabled={lockStatus?.deleteComment}
                            size="xsmall"
                            icon="Trash"
                            onClick={() => {
                              setOpenDeleteComment(true);
                              setSelectedItem(elem);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
      {/* MODALS */}
      <CommentEditModal
        isOpen={editCommentModal}
        data={selectedItem}
        closeModal={(data) => {
          setEditCommentModal(false);
          setSelectedItem(null);
          if (isValidValue(data)) getItems();
        }}
      />
      <ConfirmationModal
        isOpen={openDeleteComment}
        closeModal={() => setOpenDeleteComment(false)}
        handleConfirm={handleDelete}
        loading={deleteComment?.loading}
        header={t('Remove Comment from Project')}
        content={t('Are you sure to remove this comment from project?')}
      />
    </>
  );
};

export default Comment;
