import React from 'react';
import { SidePaneRightContent } from '../../../../components/Layout';
import { Heading } from '../../../../designSystem/DesignSystem';
import WorkFlow from '../../../../components/workFlow/WorkFlow';
import { getDefectName } from '../../../../helpers/ArtifactNameHelper';
import UseProjectConfig from '../../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';

const DefectWorkFlowPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const { t } = useTranslation();

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading type="large" text={`${getDefectName(projectConfig)} ${t('Workflow')}`} />
      </SidePaneRightContent.Top>
      <WorkFlow relatedEntity="defect" />
    </>
  );
};

export default DefectWorkFlowPage;
