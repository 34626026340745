import React, { useState } from 'react';
// API & HOOKS IMPORTS
import UserApi from '../../../api/UserApi';
import { useApi } from '../../../hooks/UseApi';
// COMPONENT & IMPORTS
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import UserModal from './UserModal';
// DESIGN SYSTEM & LAYOUTS
import { Body, Heading, Button } from '../../../designSystem/DesignSystem';
// HELPER IMPORTS
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
import { isSystemAdmin } from '../../../helpers/PriviligeHelper';

const UserDetails = ({ setSelectedItem, selectedItem, submitCallBack, passwordRule }) => {
  const [openUserModal, setOpenUserModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { t } = useTranslation();

  const deleteUser = useApi({
    name: 'Delete user',
    api: UserApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      setDeleteModalOpen(false);
      showSuccessMes(INFO_MESSAGES.delete);
      setSelectedItem((selectedItem.deleted = true));
      submitCallBack(selectedItem);
    },
    onError: (err) => showErrorMes(err)
  });

  const recoverUser = useApi({
    name: 'Recover user',
    api: UserApi.recover,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.update);
      submitCallBack(recoverUser?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const handleDelete = () => deleteUser.execute({ id: selectedItem.id });

  const onRecoverUser = () => recoverUser.execute({ id: selectedItem.id });

  return (
    <>
      {' '}
      {isSystemAdmin() && (
        <div className="m-8">
          <div className="">
            <div className="flex items-center justify-between">
              <div className="flex">
                <Heading
                  color={`${selectedItem.deleted ? 'opacity-50' : ''}`}
                  type="medium"
                  text={selectedItem.name}
                />
                {selectedItem.deleted && (
                  <Body
                    type="medium"
                    color="text-gray-600"
                    className="flex items-end p-1 pb-[2.75px]"
                    text={t('(This user was deleted)')}
                    bold
                  />
                )}
              </div>
              <div className="flex gap-4 self-end">
                {selectedItem.deleted ? (
                  <Button
                    id="userDetail-recover-button"
                    secondary
                    text={t('Recover')}
                    size="small"
                    onClick={() => onRecoverUser()}
                  />
                ) : (
                  <>
                    <Button
                      id="userDetail-edit-button"
                      tertiary
                      text={t('Edit')}
                      size="small"
                      onClick={() => setOpenUserModal(true)}
                    />
                    <Button
                      id="userDetail-delete-button"
                      tertiary
                      text={t('Delete')}
                      size="small"
                      onClick={() => setDeleteModalOpen(true)}
                    />
                  </>
                )}
              </div>
            </div>
            {selectedItem.systemAdmin && (
              <Heading
                type="base"
                text={t('System Admin')}
              />
            )}
          </div>
          <div className="py-4">
            <div className="flex items-end gap-1">
              <Heading
                type="base"
                bold
                text={t('Mail')}
              />
              <Body
                className="pb-px"
                type="medium"
                text={` ${selectedItem?.email}`}
              />
            </div>
            <div className="flex items-end gap-1">
              <Heading
                type="base"
                bold
                text={t('Username')}
              />
              <Body
                className="pb-px"
                type="medium"
                text={` ${selectedItem?.userName}`}
              />
            </div>
            <div className="flex items-end gap-1">
              <Heading
                type="base"
                bold
                text={t('User Type')}
              />
              <Body
                className="pb-px"
                type="medium"
                text={`${selectedItem?.ldapUser ? t('LDAP User') : t('System User')}`}
              />
            </div>
          </div>
        </div>
      )}
      {openUserModal && (
        <UserModal
          passwordRule={passwordRule}
          isOpen={openUserModal}
          closeModal={() => setOpenUserModal(false)}
          data={selectedItem}
          submitCallBack={submitCallBack}
        />
      )}
      <ConfirmationModal
        header={t('Confirm Delete')}
        content={t('Are you sure you want to delete this user?')}
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        handleConfirm={handleDelete}
        loading={deleteUser?.loading}
      />
    </>
  );
};

export default UserDetails;
