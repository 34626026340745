import React, { useState, useEffect } from 'react';
import { useApi } from '../../../hooks/UseApi';
import CustomReportApi from '../../../api/CustomReportApi';
import UseJiraConfig from '../../../hooks/UseJiraConfig';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';

import { Modal } from '../../modal';
import { Heading, Button } from '../../../designSystem/DesignSystem';
import PercentageBar from '../../general/PercentageBar';
import DefectPriorityAndStatusReport from '../general/DefectPriorityAndStatusReport';
import EntityDataTable from '../general/EntityDataTable';
import ReportMailModal from '../general/ReportMailModal';
import RequirementGeneralTestReportTable from './RequirementGeneralTestReportTable';
import { ENTITY_TYPES } from '../../../constants';
import { getDefectName, getRunName, getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import { getStatusBarOptionsDynamic } from '../../../helpers/Helper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { downloadExportingFile } from '../ReportHelper';
import { useTranslation } from 'react-i18next';

const headingSize = 'base';

const RequirementReportModal = ({ isOpen, closeModal, entityId, users }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const jiraConfig = UseJiraConfig(projectId);
  const { t } = useTranslation();

  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [response, setResponse] = useState({});

  const dataLoader = useApi({
    name: 'fetching ',
    api: CustomReportApi.getEntityReport,
    onSuccess: () => setResponse(dataLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const dowloadReport = useApi({
    name: 'dowloadReport',
    api: CustomReportApi.downloadEntityReport,
    autoLoad: false,
    onSuccess: () => downloadExportingFile(dowloadReport?.data, ENTITY_TYPES.REQUIREMENT),
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    dataLoader.execute({ entityId, entityType: ENTITY_TYPES.REQUIREMENT });
  }, [entityId]);

  return (
    <>
      <Modal
        w={'lg'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title>
          <Heading.Group
            text={t('Requirement Report')}
            notCapitalize={true}
          >
            <div className="flex gap-4">
              <Button
                id="requirementReportModal-sendEmail-button"
                secondary
                text={t('Send Email')}
                onClick={() => {
                  setOpenEmailModal(true);
                }}
                size={'xsmall'}
              />
              <Button
                id="requirementReportModal-export-button"
                secondary
                text={t('Export')}
                icon="UploadSimple"
                onClick={() => {
                  showSuccessMes('Report is downloading');
                  dowloadReport.execute({ entityType: ENTITY_TYPES.REQUIREMENT, entityId });
                }}
                loading={dowloadReport?.loading}
                size={'xsmall'}
              />
              <Button
                id="requirementReportModal-close-button"
                icon="X"
                onClick={closeModal}
                size={'xsmall'}
              />
            </div>
          </Heading.Group>
        </Modal.Title>
        <Modal.Content h={'xl'}>
          {!response ? (
            'loading'
          ) : (
            <>
              <div className="flex flex-grow flex-col gap-4">
                {/* General Test Report  */}
                <div className="flex flex-grow flex-col gap-4">
                  <Heading
                    type={headingSize}
                    text={t('General Test Report')}
                  />
                  <RequirementGeneralTestReportTable
                    response={response}
                    projectId={projectId}
                    projectConfig={projectConfig}
                  />
                </div>
                {/* Test Coverage  */}
                <div className="flex flex-grow flex-col gap-4">
                  <Heading
                    type={headingSize}
                    text={t('Test Coverage')}
                  />
                  <PercentageBar
                    showLabel={true}
                    datas={getStatusBarOptionsDynamic(response?.entityJson?.coverage)}
                  />
                </div>
                {/* Defect Insight */}
                <DefectPriorityAndStatusReport
                  response={response}
                  projectId={projectId}
                  projectConfig={projectConfig}
                  users={users}
                  jiraConfig={jiraConfig}
                  {...(jiraConfig?.activeDefect === true && { isJiraStatus: true })}
                />
                {/* Defect Report */}
                <div className="flex flex-grow flex-col gap-4">
                  <Heading
                    type={headingSize}
                    text={getDefectName(projectConfig)}
                  />
                  <EntityDataTable
                    response={response?.defects}
                    projectId={projectId}
                    projectConfig={projectConfig}
                    users={users}
                    entityType={ENTITY_TYPES.DEFECT}
                    jiraConfig={jiraConfig}
                  />
                </div>
                {/* Test Run Report */}
                <div className="flex flex-grow flex-col gap-4">
                  <Heading
                    type={headingSize}
                    text={getRunName(projectConfig)}
                  />
                  <EntityDataTable
                    response={response?.testRuns}
                    projectId={projectId}
                    projectConfig={projectConfig}
                    users={users}
                    entityType={ENTITY_TYPES.TEST_RUN}
                  />
                </div>
                {/* Test Case Report */}
                <div className="flex flex-grow flex-col gap-4">
                  <Heading
                    type={headingSize}
                    text={getTestCaseName(projectConfig)}
                  />
                  <EntityDataTable
                    response={response?.testCases}
                    projectId={projectId}
                    projectConfig={projectConfig}
                    users={users}
                    entityType={ENTITY_TYPES.TEST_CASE}
                  />
                </div>
              </div>
            </>
          )}
        </Modal.Content>
      </Modal>

      {openEmailModal && (
        <ReportMailModal
          entityId={entityId}
          entityType={ENTITY_TYPES.REQUIREMENT}
          isOpen={openEmailModal}
          closeModal={() => setOpenEmailModal(false)}
          users={users}
        />
      )}
    </>
  );
};

export default RequirementReportModal;
