import React, { useState } from 'react';
// HOOK & API IMPORTS
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import UseQueryProject from '../../hooks/UseQueryProject';
import TestRunApi from '../../api/TestRunApi';
// HELPER & CONSTANT IMPORTS
import { getRunName } from '../../helpers/ArtifactNameHelper';
import { showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { Dropdown, Button, Datepicker } from '../../designSystem/DesignSystem';
// COMPONENT IMPORTS
import { Modal } from '../modal';
import { useAppDispatch } from '../../context/AppContext';
import { setSelectedRunTestCases } from '../../context/ProjectActions';
import TestSuiteSearchTree from '../treeView/TestSuiteSearchTree';
import { ENTITY_TYPES } from '../../constants';

const checkIfTestrunsHaveParameters = (testRuns) => {
  if (!testRuns) {
    return null;
  }
  return testRuns.some((testRun) => testRun?.testCase?.parameters?.length > 0);
};

const BulkCreateTestRunModal = ({
  selectedRowsIds,
  entityType,
  isOpen,
  closeModal,
  submitCallBack,
  setConfigureParametersModalOpen
}) => {
  // TRANSLATE
  const { t } = useTranslation();
  // FORM
  const { control, handleSubmit, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  // HOOKS
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const userList = UseProjectUsers(projectId);
  const dispatch = useAppDispatch();
  // SELECT STATES
  const [selectedTestSuite, setSelectedTestSuite] = useState([]);

  const createTestRuns = useApi({
    name: 'creating new test runs',
    api: TestRunApi.create,
    autoLoad: false,
    onSuccess: () => {
      if (submitCallBack) {
        submitCallBack();
      }
      closeModal();
      showSuccessMes(t('createdTestRun', { entity: getRunName(projectConfig) }));
      if (checkIfTestrunsHaveParameters(createTestRuns.data)) {
        setConfigureParametersModalOpen(true);
      }
      dispatch(setSelectedRunTestCases(createTestRuns.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    const body = {
      ...form,
      testerId: form.testerId && form.testerId[0].id,
      customFields: [],
      testSuiteId: selectedTestSuite.id,
      ...(entityType === ENTITY_TYPES.TEST_CASE && { testCaseIds: selectedRowsIds }),
      ...(entityType === ENTITY_TYPES.REQUIREMENT && { requirementIds: selectedRowsIds })
    };

    createTestRuns.execute(body);
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={closeModal}
        w={'lg'}
      >
        <Modal.Title>
          {t('createTestRun', {
            entity: getRunName(projectConfig)
          })}
        </Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <div className="flex flex-grow gap-4">
                <Datepicker
                  clearable
                  control={control}
                  endDateName="endDate"
                  startDateName="startDate"
                  startDateForm=""
                  endDateForm=""
                  startDateLabel={t('Planned Start Date')}
                  endDateLabel={t('Planned End Date')}
                />
              </div>
              <Dropdown
                clearable
                required
                control={control}
                name="testerId"
                label={t('Tester')}
                options={userList}
                placeholder={t('Select Tester')}
              />
            </div>
          </Modal.Content>
          <Modal.Content>
            <TestSuiteSearchTree setSelectedTestSuite={setSelectedTestSuite} />
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="BulkTestRunFromRequirement-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
              type="button"
            />
            <Button
              id="BulkTestRunFromRequirement-submit-button"
              primary
              text={t('Submit')}
              loading={createTestRuns?.loading}
              type="submit"
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default BulkCreateTestRunModal;
