import React, { useEffect, useRef, useState } from 'react';
//
import FolderApi from '../../../api/FolderApi';
import { useApi } from '../../../hooks/UseApi';
import UseQueryProject from '../../../hooks/UseQueryProject';
//
import { isValidValue } from '../../../helpers/Helper';
//
import { ENTITY_TYPES } from '../../../constants';
import { showErrorMes } from '../../../helpers/NotificationHelper';
//
import CustomEntityDetail from '../../../components/detail/CustomEntityDetail';
import UseCustomField from '../../../hooks/UseCustomField';
import UseProjectUsers from '../../../hooks/UseProjectUsers';

const FolderDetails = (props) => {
  const { selectedItem, submitCallBack, submitCallBackAfterDeletion, submitCallBackAfterClone } = props;
  const { projectId } = UseQueryProject('projectId');
  const customFields = UseCustomField(projectId, ENTITY_TYPES.FOLDER);
  const users = UseProjectUsers(projectId);

  const [dataForm, setDataForm] = useState(null);
  const [filter, setFilter] = useState({});
  const [refreshStatus, setRefreshStatus] = useState(false);

  const dataLoader = useApi({
    name: 'fetching folder item',
    api: FolderApi.get,
    autoLoad: false,
    onSuccess: () => setDataForm(dataLoader.data),
    onError: (err) => showErrorMes(err)
  });

  const refreshData = (form) => {
    if (isValidValue(form)) {
      setDataForm(form);
      submitCallBack(form);
      setRefreshStatus(true);
    } else {
      dataLoader.execute({ id: selectedItem.id });
    }
  };

  const afterDelete = (data) => {
    if (isValidValue(data) && submitCallBackAfterDeletion) {
      submitCallBackAfterDeletion(data);
    }
  };

  useEffect(() => {
    refreshData();
    setFilter({
      projectId,
      root: true,
      parentId: selectedItem?.id,
      includeExecutionStats: true,
      includeReleases: true,
      includeCustomFields: true,
      includeContribution: true
    });
  }, [selectedItem]);

  const ref = useRef();

  return (
    <>
      <CustomEntityDetail
        ref={ref}
        config={{
          entityId: selectedItem?.id,
          entityType: ENTITY_TYPES.FOLDER,
          users,
          refreshStatus,
          setRefreshStatus,
          loading: dataLoader?.loading,
          dataForm: dataForm,
          selectedItem,
          submitCallBack: refreshData,
          submitCallBackAfterDeletion: afterDelete,
          submitCallBackAfterClone,
          customFields,
          filter,
          projectId
        }}
      />
    </>
  );
};

export default FolderDetails;
