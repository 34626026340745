import React, { useState } from 'react';
import { useApi } from '../../../hooks/UseApi';
import UserApi from '../../../api/UserApi';

// DESING SYSTEM IMPORTS
import { Body, Heading, StatusPill, Button } from '../../../designSystem/DesignSystem';
// COMPONENT IMPORTS
import { Modal } from '../../../components/modal';
// HELPER & CONSTAT IMPORTS
import { getUserFirstLastNameById, translatedDateFormat } from '../../../helpers/Helper';
import { useTranslation } from 'react-i18next';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const bodyFontSize = 'medium';
const fontSize = 'small';
const divClass = 'flex flex-row items-center mb-2';
const headingClass = 'w-36';

const ProjectDetailModal = ({ isOpen, closeModal, project }) => {
  const { t } = useTranslation();
  const [userList, setUserList] = useState([]);

  const userLoader = useApi({
    name: 'Get all users',
    api: UserApi.getAllUsers,
    autoLoad: true,
    onSuccess: () => setUserList(userLoader?.data),
    onError: (err) => showErrorMes(err)
  });
  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal()}
      >
        <Modal.Title> {t('Project Details')} </Modal.Title>
        <Modal.Content h="md">
          <div className="m-2 flex flex-grow flex-col gap-3">
            <>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Prefix')}
                />
                <Body
                  text={project?.prefix || '-'}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Name')}
                />
                <Body
                  text={project?.name || '-'}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Start Date')}
                />
                <Body
                  text={translatedDateFormat(project?.startDate, 'DD MMM YYYY', t)}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('End Date')}
                />
                <Body
                  text={translatedDateFormat(project?.endDate, 'DD MMM YYYY', t)}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Status')}
                />
                <Body
                  text={<StatusPill value={project?.status?.name || '-'} />}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Created By')}
                />
                <Body
                  text={project?.createdBy ? getUserFirstLastNameById(userList, project?.createdBy) : '-'}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Created Time')}
                />
                <Body
                  text={translatedDateFormat(project?.createdTime, 'DD MMM YYYY', t)}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Modified By')}
                />
                <Body
                  text={project?.modifiedBy ? getUserFirstLastNameById(userList, project?.modifiedBy) : '-'}
                  type={bodyFontSize}
                />
              </div>
              <div className={divClass}>
                <Heading
                  className={headingClass}
                  type={fontSize}
                  text={t('Modified Time')}
                />
                <Body
                  text={translatedDateFormat(project?.modifiedTime, 'DD MMM YYYY', t)}
                  type={bodyFontSize}
                />
              </div>
              <Heading
                className={headingClass}
                type={fontSize}
                text={t('Description')}
              />
              <Body
                text={project?.description || '-'}
                type={bodyFontSize}
              />
            </>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="project-close-button"
            secondary
            text={t('Close')}
            onClick={() => closeModal()}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default ProjectDetailModal;
