import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { fillChartData, getChartHeading } from '../helper/DashboardHelper';
import { isValidList } from '../../../helpers/Helper';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const CustomBarChart = ({ chart, projectId, dateFilter, projectConfig }) => {
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);
  const levelStatus =
    chart?.configuration?.dateHistogram === true ||
    (isValidList(chart?.configuration?.fields) && chart?.configuration?.fields.length > 1);

  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      fillChartData(dataLoader?.data, chart, setData, projectId, null, setKeys);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);

  return (
    <>
      {getChartHeading(chart, projectConfig)}
      <ResponsiveBar
        data={data}
        keys={levelStatus ? keys : undefined}
        indexBy="label"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.6}
        valueScale={{ type: 'linear' }}
        groupMode={levelStatus ? 'stacked' : 'grouped'}
        indexScale={{ type: 'band', round: true }}
        labelTextColor={{ from: 'color', modifiers: [['darker', '3']] }}
        colors={{ scheme: 'accent' }}
        animate={true}
        enableLabel={true}
        enableGridX={true}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Count',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        legends={
          levelStatus
            ? [
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 12,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]
            : []
        }
      />
    </>
  );
};

export default CustomBarChart;
