export const EntityLinkConstants = {
    release:
    {
        page: "releases",
        pageParams: [
            {
                name: "type",
                value: "release"
            },
            {
                name: "id",
                value: { path: "$.id" }
            }
        ],
    },
    requirement:
    {
        page: "requirements",
        pageParams: [
            {
                name: "type",
                value: "requirement"
            },
            {
                name: "id",
                value: { path: "$.id" }
            }
        ],
    },
    folder:
    {
        page: "requirements",
        pageParams: [
            {
                name: "type",
                value: "folder"
            },
            {
                name: "id",
                value: { path: "$.id" }
            }
        ],
    },
    testCase:
    {
        page: "test-cases",
        pageParams: [
            {
                name: "type",
                value: "testCase"
            },
            {
                name: "id",
                value: { path: "$.rootId" }
            },
            {
                name: "version",
                value: { path: "$.majorVersion" }
            }
        ],
    },
    testContainer: {
        page: "test-cases",
        pageParams: [
            {
                name: "type",
                value: "testContainer"
            },
            {
                name: "id",
                value: { path: "$.id" }
            }
        ],
    },
    testFolder: {
        page: "planning",
        pageParams: [
            {
                name: "type",
                value: "testFolder"
            },
            {
                name: "id",
                value: { path: "$.id" }
            }
        ],
    },
    testCycle: {
        page: "planning",
        pageParams: [
            {
                name: "type",
                value: "testCycle"
            },
            {
                name: "id",
                value: { path: "$.id" }
            }
        ],
    },
    testSuite: {
        page: "planning",
        pageParams: [
            {
                name: "type",
                value: "testSuite"
            },
            {
                name: "id",
                value: { path: "$.id" }
            }
        ],
    },
    testRun: {
        page: "tasks",
        pageParams: [

            {
                name: "runId",
                value: { path: "$.id" }
            }
        ],
    },
    defect: {
        page: "defects",
        pageParams: [

            {
                name: "defectId",
                value: { path: "$.id" }
            }
        ],
    }
};