import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { uniqueId } from 'lodash-es';
import AttachmentsApi from '../../api/AttachmentsApi';
import UseSystemParamsConfig from '../../hooks/UseSystemParamsConfig.js';
import { dismissToast } from '../../helpers/AttachmentHelpers';
import { showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
import { isValidList } from '../../helpers/Helper';
import { ONE_MB_IN_BYTES } from '../../constants';
import SystemParamApi from '../../api/SystemParamApi.js';
import { useApi } from '../../hooks/UseApi.jsx';
import UploadLoadingModal from './UploadLoadingModal.js';
import UploadErrorModal from './UploadErrorModal.js';

const AttachmentUpload = ({
  accept,
  multiple,
  maxSize,
  projectId,
  entityType,
  entityId,
  getFiles,
  uploadFunction,
  disabled,
  attachmentCountExisting
}) => {
  const { t } = useTranslation();
  const systemParamsConfig = UseSystemParamsConfig(projectId);
  const maxUploadSize = systemParamsConfig?.find((item) => item.paramKey === 'maxUploadSize')?.paramVal;

  const [attachmentLimit, setAttachmentLimit] = useState();
  const [loading, setLoading] = useState(false);
  const [errorFiles, setErrorFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);

  const attachmentUploadCount = useApi({
    name: 'Get Attachment Upload Count',
    api: SystemParamApi.getAttachmentUploadCount,
    onSuccess: () => {
      setAttachmentLimit(attachmentUploadCount.data?.paramVal);
    },
    onError: (err) => showErrorMes(err)
  });

  const checkAttachmentCountLessThanLimit = (uploadedFileCount) => {
    return uploadedFileCount + attachmentCountExisting <= attachmentLimit;
  };

  const translateRejections = (fileRejections) => {
    fileRejections.map(({ errors }) => {
      errors.map((e) => {
        switch (
          e.code // we can change parametric error messages here
        ) {
          case 'file-too-large':
            return {
              ...errors,
              e: {
                ...e,
                message: t(e.message, { maxFileSize: maxUploadSize })
              }
            };
        }
      });
    });

    return fileRejections;
  };

  const checkTooManyFilesError = (fileRejections) => {
    const tooManyFilesError = fileRejections.some((rejection) =>
      rejection.errors.some((error) => error.code === 'too-many-files')
    );

    if (tooManyFilesError) {
      showErrorMes(t('attachment_count_error', { attachmentCountLimit: attachmentLimit }));
      setLoading(false);
    }

    return tooManyFilesError;
  };

  const handleUpload = async (acceptedFiles, fileRejections) => {
    if (isValidList(fileRejections) && checkTooManyFilesError(fileRejections)) return; // don't upload anything

    setLoading(true);
    setErrorFiles([]);
    setUploadedFiles(0);
    setTotalFiles(acceptedFiles.length + fileRejections.length);
    const failedFiles = [];

    if (isValidList(fileRejections)) {
      fileRejections = translateRejections(fileRejections);
      fileRejections.forEach((rejection) => {
        const { file, errors } = rejection;
        errors.forEach((error) => {
          failedFiles.push({ name: file.name, error: t(error.message) });
        });
      });
    }

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };

    // If there are accepted files, proceed with the upload
    if (acceptedFiles?.length > 0) {
      if (uploadFunction) {
        await uploadFunction(acceptedFiles);
      } else {
        if (checkAttachmentCountLessThanLimit(acceptedFiles?.length)) {
          const uploadPromises = acceptedFiles.map(async (file) => {
            let data = new FormData();
            data.append('file', file);
            data.append('projectId', projectId);
            data.append('entityType', entityType);
            data.append('entityId', entityId);

            const id = uniqueId();
            try {
              await AttachmentsApi.upload({ data, config });
              await getFiles();
              setUploadedFiles((prev) => prev + 1);
            } catch (err) {
              failedFiles.push({ name: file?.name, error: err?.response?.data?.message || err.message });
            } finally {
              dismissToast(id);
            }
          });

          await Promise.all(uploadPromises);
        } else {
          showErrorMes(t('attachment_count_error', { attachmentCountLimit: attachmentLimit }));
          setLoading(false);
          return;
        }
      }
    }

    setErrorFiles(failedFiles);
    if (failedFiles.length === 0) {
      showSuccessMes(t('All files uploaded successfully.'));
    }

    setLoading(false);
  };

  useEffect(() => {
    getFiles && getFiles();
  }, []);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  useEffect(() => {
    attachmentUploadCount.execute();
  }, []);

  return (
    <>
      <Dropzone
        maxFiles={attachmentLimit}
        disabled={disabled}
        accept={accept}
        multiple={multiple ? multiple : true}
        maxSize={maxSize ? maxSize : maxUploadSize}
        onDrop={(acceptedFiles, fileRejections) => handleUpload(acceptedFiles, fileRejections)}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="mb-6">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mt-4 flex cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex justify-center text-sm  text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className={`relative cursor-pointer rounded-md bg-white font-medium   focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 ${
                        disabled ? 'text-gray-400' : 'text-primary-500 hover:text-primary-600'
                      }`}
                    >
                      <span>{t('Upload a file')}</span>
                    </label>
                    <p className="pl-1">{t('or drag and drop')}</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    {t('maxFileUploadSize', { uploadSize: Math.floor(maxUploadSize / ONE_MB_IN_BYTES) })}
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>

      <UploadLoadingModal
        open={loading}
        totalFiles={totalFiles}
        uploadedFiles={uploadedFiles}
      />

      <UploadErrorModal
        open={!loading && errorFiles.length > 0}
        close={() => setErrorFiles([])}
        errorFiles={errorFiles}
      />
    </>
  );
};

export default AttachmentUpload;
