// DESIGN SYSTEM IMPORTS
import _ from 'lodash';
import { UserCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// HOOKS & API IMPORTS
import UserApi from '../../api/UserApi';
import Accordion from '../../components/accordion/Accordion';
import { SidePaneRightContent } from '../../components/Layout';
import { Body, Heading } from '../../designSystem/DesignSystem';
import { getArtifactName } from '../../helpers/ArtifactNameHelper';
// HELPER & CONSTANTS IMPORTS
import { showErrorMes } from '../../helpers/NotificationHelper';
import { getSessionUser } from '../../helpers/PriviligeHelper';
import { useApi } from '../../hooks/UseApi';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const projectId = query.get('projectId'); //ProjectId
  const projectConfig = projectId ? UseProjectConfig(projectId) : {};
  const sessionUser = getSessionUser();
  const { t } = useTranslation();

  const [dataForm, setDataForm] = useState(null);

  // <-- API SETUP -->
  const getProjectUserRoleDetails = useApi({
    name: 'getProjectUserRoleDetails',
    api: UserApi.getProjectUserRoleDetails,
    autoLoad: false,
    onSuccess: () => setDataForm(getProjectUserRoleDetails?.data?.user),
    onError: (err) => showErrorMes(err)
  });

  const userDataLoader = useApi({
    name: 'getting Session User Data',
    api: UserApi.getUser,
    autoLoad: false,
    onSuccess: () => setDataForm(userDataLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const urlChangeControl = () => {
    if (projectId !== null) {
      getProjectUserRoleDetails.execute({ projectId, userId: sessionUser?.id });
    } else userDataLoader.execute(sessionUser);
  };

  const userList = (param) => {
    return (
      <div className="flex flex-row items-center mb-2">
        <Heading className={'w-64 ml-5'} type={'small'} text={param?.name} />
        <Body text={param?.description || '-'} type={'medium'} />
      </div>
    );
  };

  const itemList = (item) => {
    const groupedPrivileges = _.groupBy(item?.privileges || [], 'group');
    return item?.name === 'Project Users' ? null : (
      <div className="flex flex-row items-center mt-6 gap-4 p-4 shadow bg-white rounded border border-gray-200 ">
        <div className="flex flex-col gap-4 flex-grow">
          <Accordion defaultClose border button={<Heading type="medium" text={`${item?.name}`} />}>
            <div>
              {Object.keys(groupedPrivileges).map((key) => {
                return (
                  <>
                    <Accordion
                      defaultClose
                      border
                      button={<Heading type="base" text={getArtifactName(projectConfig, key)} />}>
                      <div>
                        {groupedPrivileges[key].map((privilege) => {
                          return (
                            <div className="flex flex-row items-center mb-2" key={key}>
                              <Heading className={'w-64 ml-5'} type={'small'} text={t(privilege?.name)} />
                              <Body text={privilege?.description || '-'} className={'ml-10'} type={'medium'} />
                            </div>
                          );
                        })}
                      </div>
                    </Accordion>
                  </>
                );
              })}
            </div>
          </Accordion>
        </div>
      </div>
    );
  };

  const profileContainer = () => {
    return (
      <>
        <div className="flex flex-row items-center mt-6 gap-4 p-4 shadow bg-white rounded border border-gray-200">
          <div className="w-12 h-12">
            <UserCircle className="h-full w-full" weight="duotone" color={'#7171beeb'} />
          </div>
          <div className="">
            <Heading type="medium" text={dataForm?.name} />
            <Body type="medium" text={dataForm?.email} />
            <div className="flex flex-row gap-1">
              <Body type="medium" text={dataForm?.userName} />
            </div>
          </div>
        </div>
        {getProjectUserRoleDetails?.data ? (
          <div className="flex flex-row items-center mt-6 gap-4 p-4 shadow bg-white rounded border border-gray-200">
            <div className="flex flex-col gap-4 flex-grow">
              <Accordion defaultClose border button={<Heading type="medium" text={t('Roles and Privileges')} />}>
                <div>
                  {getProjectUserRoleDetails?.data?.roles.map((item) => {
                    return itemList(item);
                  })}
                </div>
              </Accordion>
            </div>
          </div>
        ) : (
          ''
        )}
        {getProjectUserRoleDetails?.data?.userGroups ? (
          <div className="flex flex-row items-center mt-6 gap-4 p-4 shadow bg-white rounded border border-gray-200">
            <div className="flex flex-col gap-4 flex-grow">
              <Accordion defaultClose border button={<Heading type="medium" text={t('User Groups')} />}>
                <div>
                  {getProjectUserRoleDetails?.data?.userGroups.map((param) => {
                    return userList(param);
                  })}
                </div>
              </Accordion>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  useEffect(() => {
    urlChangeControl();
  }, []);

  return (
    <SidePaneRightContent overflow={true}>
      <SidePaneRightContent.Top>
        <Heading.Group type="large" text={t('User Profile')}></Heading.Group>
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Main>
        <SidePaneRightContent.Center>{profileContainer()}</SidePaneRightContent.Center>
      </SidePaneRightContent.Main>
    </SidePaneRightContent>
  );
};

export default ProfilePage;
