import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// Api and Hook Imports
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseCustomField from '../../../hooks/UseCustomField';
import { useApi } from '../../../hooks/UseApi';
import ContainerApi from '../../../api/ContainerApi';
// Design System Imports
import { Input, Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
// Component Imports
import EntityCustomFieldForm from '../../../components/customField/EntityCustomFieldForm';

import { isValidValue, editModalMapping } from '../../../helpers/Helper';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { getCustomFields, createEntityFields } from '../../../helpers/CustomFieldHelper';
import { ENTITY_TYPES } from '../../../constants';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
import { titleTranslation } from '../../../helpers/TranslationHelper';
import Markdown from '../../../components/markdown/Markdown';

const TestContainerModal = (props) => {
  const { isOpen, data, closeModal, parentId } = props;
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const entityCustomFields = UseCustomField(projectId, ENTITY_TYPES.TEST_CONTAINER);
  const [dataForm, setDataForm] = useState({});
  const [cFields, setCFields] = useState([]);
  const [customFieldForm, setCustomFieldForm] = useState({});
  const { t } = useTranslation();

  const createEditTestContainer = useApi({
    name: 'saving test container',
    api: isValidValue(data) ? ContainerApi.edit : ContainerApi.create,
    onSuccess: () => {
      showSuccessMes(t(INFO_MESSAGES.save));
      closeModal(createEditTestContainer?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    let cFieldForm = getCustomFields(cFields, form);
    const body = {
      ...(isValidValue(data) && { id: data?.id }),
      ...(isValidValue(parentId) && { parentId: parentId }),
      name: form?.name,
      description: isValidValue(form?.description) ? form.description : '',
      projectId: projectId,
      customFields: createEntityFields(form, cFieldForm)
    };
    createEditTestContainer.execute(body);
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setDataForm(data);
    }
  }, []);

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title>
          {titleTranslation(getArtifactName(projectConfig, ENTITY_TYPES.TEST_CONTAINER), data, '', t)}
        </Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4 overflow-y-auto">
              <Input
                required
                name="name"
                label={t('Name')}
                placeholder={t('name_entity', { entity: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CONTAINER) })}
                control={control}
                minLength="3"
                maxLength="255"
              />
              <EntityCustomFieldForm
                customFieldForm={customFieldForm}
                setCustomFieldForm={setCustomFieldForm}
                control={control}
                setValue={setValue}
                entityFields={data?.customFields}
                entityCustomFields={entityCustomFields}
                dataForm={dataForm}
                customControlForJiraModal={false}
                setCFields={setCFields}
              />
              <Markdown
                control={control}
                name="description"
                label={t('Description')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="testContainerModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="testContainerModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEditTestContainer?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default TestContainerModal;
