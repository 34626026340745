import { useEffect } from 'react';
// Context Imports
import { useAppDispatch, useApp } from '../context/AppContext';
import { addRequest, saveEntityCustomFields, removeRequest } from '../context/ProjectActions';
// Api Imports
import CustomFieldApi from '../api/CustomFieldApi';
import { showErrorMes } from '../helpers/NotificationHelper';
import { uniqueId } from 'lodash-es';
import { useMemo } from 'react';
import { useApi } from './UseApi';

const UseCustomField = (projectId, entity) => {
  const app = useApp();
  const activeRequests = app?.activeRequests || [];
  const customFields = app?.customFields || [];
  const customField = customFields?.[entity];
  const dispatch = useAppDispatch();

  const id = useMemo(() => uniqueId(), []);

  const filterPredicate = (item) =>
    item.projectId !== projectId && item.type !== 'customField' && item.entity !== entity;

  const getCustomFieldLoader = useApi({
    name: 'Get Custom Field',
    api: CustomFieldApi.get,
    autoLoad: false,
    onSuccess: () => dispatch(saveEntityCustomFields(projectId, entity, getCustomFieldLoader?.data)),
    onError: (err) => showErrorMes(err),
    onFinally: () => dispatch(removeRequest(filterPredicate))
  });

  useEffect(() => {
    if (entity && projectId && customField?.projectId !== projectId)
      dispatch(addRequest({ order: id, type: 'customField', entity, projectId }));
  }, [entity, projectId]);

  useEffect(() => {
    if (activeRequests?.length) {
      const allRequestsForEntity = activeRequests
        .filter((item) => item.projectId === projectId && item.type === 'customField' && item.entity === entity)
        .map((item) => item.order);
      if ((allRequestsForEntity || []).indexOf(id) === 0 && customField?.projectId !== projectId) {
        getCustomFieldLoader.execute({ projectId, allFields: true, relatedEntity: entity });
      }
    }
  }, [activeRequests]);

  return customField?.values || [];
};

export default UseCustomField;
