import { axios } from './ApiClient';

class ContainerApi {
  async getContainer(data) {
    const resp = await axios.get(`/api/test-containers/${data.id}`);
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`/api/test-containers`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`/api/test-containers/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`/api/test-containers/${data.id}`);
    return resp.data;
  }

  async clone(data) {
    const resp = await axios.post(`/api/test-containers/clone`, data);
    return resp.data;
  }

  async getParens(id) {
    const resp = await axios.get(`/api/test-containers/${id}/parents`);
    return resp.data;
  }

  async updateContainerParent(id, body) {
    const resp = await axios.put(`/api/test-containers/${id}/parent`, body);
    return resp.data;
  }

  async getTreeData(data) {
    const resp = await axios.get(`/api/test-containers/tree`, { params: data });
    return resp.data;
  }
}

export default new ContainerApi();
