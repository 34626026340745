import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// API && HOOKS
import { useApi } from '../../hooks/UseApi';
import TestRunExecutionApi from '../../api/TestRunExecutionApi';
// COPONENTS
import TestRunExecutionDetails from './TestRunExecutionDetails';
// DESIGN SYSTEM
import { Body } from '../../designSystem/DesignSystem';
// HELPERS  && CONSTANTS
import { classNames } from '../../helpers/Helper';
import { RunExecutionStatus } from '../../constants';

const TestRunExecutions = ({ runId, projectId, selectedExe, users, submitCallBack }) => {
  const { t } = useTranslation();

  const [selectedExecution, setSelectedExecution] = useState(selectedExe);

  const testRunExecutionsLoader = useApi({
    name: 'fetching test run executions',
    api: TestRunExecutionApi.getAll,
    autoLoad: false
  });

  useEffect(() => {
    if (runId) {
      testRunExecutionsLoader.execute({ testRunId: runId });
    }
  }, [runId]);

  return (
    <div className="grid grid-cols-6 gap-2">
      <div className=" h-[calc(100vh-10rem)]	overflow-y-auto overflow-x-hidden">
        {(testRunExecutionsLoader.data || []).map((item, index) => (
          <div
            key={index}
            className="flex px-4"
          >
            <div
              className={classNames(
                'my-4 flex-grow  gap-5 rounded  p-2 shadow-lg  hover:bg-blue-200',
                selectedExecution?.id === item.id ? 'bg-blue-200' : ''
              )}
              onClick={() => setSelectedExecution(item)}
            >
              <div className="m-2">
                <div className="grid flex-row place-items-center">
                  <Body
                    text={item.code + '  '}
                    color="text-black-500"
                    type="medium"
                  />
                  {item.completed ? (
                    <Body
                      text={t(RunExecutionStatus[item?.status].text) || ''}
                      color={`text-${RunExecutionStatus[item.status].color || 'black'}-500`}
                      type="medium"
                      bold
                    />
                  ) : (
                    <Body
                      text={t('DRAFT')}
                      color={`text-gray-600`}
                      type="medium"
                      bold
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-span-5">
        {selectedExecution && (
          <TestRunExecutionDetails
            runExecution={selectedExecution}
            setSelectedExecution={setSelectedExecution}
            users={users}
            projectId={projectId}
            callback={() => {
              submitCallBack();
              testRunExecutionsLoader.execute({ testRunId: runId });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TestRunExecutions;
