import React from 'react';
// HELPER & CONSTANTS IMPORTS
import { isSystemAdmin } from '../../../helpers/PriviligeHelper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
// DESIGN SYSTEM & COMPONENTS
import { Input, Button, Password, H6 } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
// API & HOOKS
import { useForm } from 'react-hook-form';
import { useApi } from '../../../hooks/UseApi';
import LdapApi from '../../../api/LdapApi';
import { encryptData } from '../../../helpers/CryptoHelper';
import { useTranslation } from 'react-i18next';
import { editModalMapping, isValidValue } from '../../../helpers/Helper';
import { useEffect } from 'react';

const LdapTestModal = ({ isOpen, closeModal, data }) => {
  const { reset, handleSubmit, control, formState, getValues, setValue } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { isValid, isDirty } = formState;
  const { t } = useTranslation();
  const testLdapUserName = useApi({
    name: 'Testing username and password',
    api: LdapApi.test,
    onError: (err) => showErrorMes(err),
    onSuccess: () => showSuccessMes('Ldap server is tested successfully.')
  });

  const onClose = () => {
    closeModal();
    reset();
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, []);

  const onSubmit = async (form) => {
    const values = getValues();
    const { managerPassword } = values;
    const encrManagerPassword = await encryptData(managerPassword);
    const body = {
      ...values,
      managerPassword: encrManagerPassword,
      userName: await encryptData(form.username),
      password: await encryptData(form.password)
    };
    testLdapUserName.execute(body);
  };

  return (
    <>
      {isSystemAdmin() && (
        <Modal
          open={isOpen}
          close={() => closeModal}
          w={'sm'}
        >
          <Modal.Title>{t(' Test LDAP Settings ')}</Modal.Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Content h={'md'}>
              <div className="flex flex-col gap-2">
                <H6 text={t(' Use your LDAP username and password to test your LDAP settings')} />
                <Input
                  control={control}
                  required
                  name="username"
                  label={t('Username')}
                />
                <Password
                  required
                  label={t('Password')}
                  name="password"
                  control={control}
                />
              </div>
            </Modal.Content>
            <Modal.Buttons>
              <Button
                id="ldapTestModal-cancel-button"
                secondary
                text={t('Cancel')}
                onClick={onClose}
              />
              <Button
                id="ldapTestModal-submit-button"
                primary
                text={t('Test')}
                type={t('submit')}
                disabled={!isValid && !isDirty}
                onClick={onClose}
                loading={testLdapUserName?.loading}
              />
            </Modal.Buttons>
          </form>
        </Modal>
      )}
    </>
  );
};

export default LdapTestModal;
