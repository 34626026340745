import { axios } from "./ApiClient";

class LdapApi {
    
    async getAll() {
        const resp = await axios.get(`/api/ldaps`)
        return resp.data;
    }

    async create(data) {
        const resp =  await axios.post(`/api/ldaps`, data )
        return resp.data;
    }

    async edit(data) {
        const resp = await axios.put(`/api/ldaps/${data.id}`, data )
        return resp.data;
    }

    async delete(data) {
        const resp =  await axios.delete(`/api/ldaps/${data.id}`)
        return resp.data;
    }

    async check(data) {
        const resp = await axios.post(`/api/ldaps/check`, data)
        return resp.data
    }

    async test(data) {
        const resp = await axios.post(`/api/ldaps/test`, data)
        return resp.data
    }

    async getLdapAttributes() {
        const resp = await axios.get(`/api/ldaps/attributes`)
        return resp.data;
    }

    
}

export default new LdapApi();