import { axios } from "./ApiClient";
import { BASE_URL } from "./CommonApiHelper";

export const CUSTOM_REPORT = BASE_URL + "custom-reports";

class CustomReportApi {

    async getEntityReport(data) {
        const resp = await axios.get(`${CUSTOM_REPORT}`, { params: data })
        return resp.data
    }

    async downloadEntityReport(data) {
        const resp = await axios.get(`${CUSTOM_REPORT}/export`, { params: data ,responseType: 'arraybuffer'})
        return resp.data
    }

    async sendReport(data) {
        const resp = await axios.post(`${CUSTOM_REPORT}/email`, data)
        return resp.data
    }
}

export default new CustomReportApi();
