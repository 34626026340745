import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom"
import { classNames } from "../../../helpers/Helper";


const NavbarLink = ({item, onClick}) => {

    const [showText,setShowText] =  useState()
    const { pathname } = useLocation();

    return (
        <NavLink to={item.to} 
            {...(item.showTitleOnHover && {onMouseEnter:()=> setShowText(true)})}
            {...(item.showTitleOnHover && {onMouseLeave:()=> {setShowText(false)}})}
            {...(item.text === "Log Out" && { onClick: onClick})}
            className={classNames(
                "flex items-center px-4 text-white text-sm ",
                item.text && "gap-2",
                (item?.to || "").startsWith(pathname) ? "bg-primary-700 flex items-center border-b border-white" : "hover:bg-primary-700" ,
                item.to.startsWith("/settings") && "border-x w-14  border-primary-700"
            )}>
            { (item?.showIcon=== true) &&   <div>{item.icon()} </div>}
            { item.showTitleOnHover && showText && <div >{item.text}</div>}
            { !item.showTitleOnHover && <div >{item.text}</div>}
    </NavLink>
    )


}

export default NavbarLink