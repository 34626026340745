import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import { Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { errorMessage } from '../../helpers/Helper';

import rehypeSanitize from 'rehype-sanitize';

const Markdown = ({ label, required, disabled, control, name, passValue }) => {
  const { t } = useTranslation();

  let LABEL_CLASS = 'text-sm font-medium  block mb-2 capitalize';
  let LABEL_SPAN_CLASS = ' ml-2 ';

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: required }}
        render={({ field, formState }) => (
          <>
            <div className="mb-1 flex flex-grow flex-col px-1">
              <label className={LABEL_CLASS}>
                {label}
                {required && <span className={!disabled && LABEL_SPAN_CLASS}> *</span>}
              </label>
              <MDEditor
                commandsFilter={(cmd) => {
                  if (
                    /(image|codeBlock|comment|checked-list|title|strikethrough|quote|hr|code|unordered-list)/.test(
                      cmd.name
                    )
                  ) {
                    return false;
                  }
                  return cmd;
                }}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  if (passValue) passValue(e?.target?.value);
                }}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]]
                }}
              />
              {formState?.errors?.[name] && formState?.errors[name]?.type === 'required' && (
                <Trans t={t}>{errorMessage('required', label, name, t)}</Trans>
              )}
            </div>
          </>
        )}
      />
    </>
  );
};
export default Markdown;
