// API & HOOKS
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// CONSTANTS
import { PARAMETER_STATUS_DROPDOWN, USE_FORM_OPTIONS } from '../../../../constants';
// COMPONENTS & DESIGN
import { Modal } from '../../../../components/modal';
import { Button, Dropdown, Input, MultiSelectDropdownPill } from '../../../../designSystem/DesignSystem';

const EditParameterModal = ({ open, closeModal, handleUpdate, selectedItem, projects, loading }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm(USE_FORM_OPTIONS);

  const SELECTED_STATUS = {
    key: selectedItem.status,
    value: selectedItem.status,
    text: selectedItem.status,
    name: selectedItem.status
  };

  const onSubmit = (data) => {
    handleUpdate({
      ...data,
      testCases: []
    });
  };

  //TODO: MOVE TO HELPER FILE
  const setInitialValue = (arr, fn, selectedItem) => {
    arr.forEach((item) => {
      fn(item, selectedItem[item]);
    });
  };

  useEffect(() => {
    setInitialValue(['name', 'description', 'status', 'id', 'projects', 'parameterValues'], setValue, selectedItem);
  }, []);

  const value = selectedItem?.projects.map((projectId) => {
    const project = projects.find((project) => project.id === projectId);
    return project;
  });
  return (
    <>
      <Modal
        //TODO:MODAL CSS DEĞİŞİKLİKLERİ UYGULANACAK
        open={open}
        close={() => closeModal()}
      >
        <Modal.Title>{t('Edit Parameters')}</Modal.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <Input
              control={control}
              name="name"
              label={t('Name')}
              placeholder={t('Please enter a name.')}
              required
              type="text"
            />
            <Input
              control={control}
              name="description"
              label={t('Description')}
              placeholder={t('Please enter a description.')}
              required
              type="text"
            />
            <Dropdown
              control={control}
              name="status"
              label={t('Status')}
              passValue={(value) => setValue('status', value?.[0].value)}
              required
              selectedItem={SELECTED_STATUS}
              options={PARAMETER_STATUS_DROPDOWN}
            />
            <Input
              control={control}
              name="parameterValues"
              label={t('Parameter Values')}
              required
              passValue={(value) => setValue('parameterValues', value.split(','))}
              type="text"
              placeholder={t('Please enter parameter values.')}
            />
            <MultiSelectDropdownPill
              control={control}
              name="projects"
              value={value}
              passValue={(value) =>
                setValue(
                  'projects',
                  value?.map((item) => item.id)
                )
              }
              label={t('Projects')}
              options={projects}
            />
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="parameter-submit-button"
              primary
              type="submit"
              loading={loading}
              text={t('Submit')}
            />
            <Button
              id="parameter-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => closeModal()}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default EditParameterModal;
