import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Api and Hook Imports
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseCustomField from '../../../hooks/UseCustomField';
import UseSystemFieldParams from '../../../hooks/UseSystemFieldParams';
import UseSystemField from '../../../hooks/UseSystemField';
import { useApi } from '../../../hooks/UseApi';
import TestCaseApi from '../../../api/TestCaseApi';
// Design System Imports
import { Input, TextArea, Button, Dropdown } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import Markdown from '../../../components/markdown/Markdown';
// Component Imports
import EntityCustomFieldForm from '../../../components/customField/EntityCustomFieldForm';
import { isValidValue, editModalMapping, isValidList, checkRequired } from '../../../helpers/Helper';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { ENTITY_TYPES } from '../../../constants';
import { getCustomFields, createEntityFields } from '../../../helpers/CustomFieldHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { titleTranslation, translateArrItemName } from '../../../helpers/TranslationHelper';
import { getSystemFieldOptions } from '../../../helpers/EnumHelper';
import { defaultValue } from '../../../helpers/SystemFieldHelper';

const TestCaseModal = (props) => {
  const { isOpen, data, closeModal, parentId } = props;
  const { control, handleSubmit, setValue, reset, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();

  const projectConfig = UseProjectConfig(projectId);
  const entityCustomFields = UseCustomField(projectId, ENTITY_TYPES.TEST_CASE);
  let type = getSystemFieldOptions(UseSystemField(projectId, ENTITY_TYPES.TEST_CASE, 'type'));
  const systemFieldParams = UseSystemFieldParams(projectId, ENTITY_TYPES.TEST_CASE);
  type = translateArrItemName(type, t);

  const [dataForm, setDataForm] = useState({});
  const [cFields, setCFields] = useState([]);
  const [customFieldForm, setCustomFieldForm] = useState({});

  const createEditTestCase = useApi({
    name: 'saving testCase',
    api: isValidValue(data) ? TestCaseApi.edit : TestCaseApi.create,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal(createEditTestCase?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    let cFieldForm = getCustomFields(cFields, form);
    const body = {
      ...(isValidValue(data) && { id: data.id }),
      parentId: isValidValue(parentId) ? parentId : data?.parent?.id,
      name: form.name,
      preconditions: isValidValue(form.preconditions) ? form.preconditions : '',
      description: isValidValue(form.description) ? form.description : '',
      testCaseStepVms: [],
      customFields: createEntityFields(form, cFieldForm),
      type: isValidValue(form?.type?.value) ? form?.type?.value : form?.type?.id
    };
    createEditTestCase.execute(body);
  };

  useEffect(() => {
    setValue('type', defaultValue(type));
    setDataForm((prev) => ({ ...prev, typeId: defaultValue(type)?.value }));
    if (isValidValue(data)) {
      const form = { ...data, typeId: data?.type?.id };
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setDataForm(form);
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w="md"
      >
        <Modal.Title>
          {titleTranslation(getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE), data, '', t)}
        </Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h="md">
            <div className="flex flex-grow flex-col gap-4">
              <Input
                required
                name="name"
                placeholder={t('edit_modal_entity', {
                  entity: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE)
                })}
                label={t('Name')}
                control={control}
                minLength="3"
                maxLength="255"
              />
              <Dropdown
                control={control}
                label={t('Type')}
                name="type"
                required={checkRequired('type', systemFieldParams)}
                clearable
                placeholder={t('Select Type')}
                options={type}
                selectedItem={getValues('type')}
                passValue={(value) => {
                  setValue('type', isValidList(value) ? value[0] : null);
                  setDataForm((prev) => ({ ...prev, typeId: isValidList(value) ? value[0]?.id : null }));
                }}
              />
              <TextArea
                placeholder={t('Enter Preconditions')}
                control={control}
                name="preconditions"
                label={t('Preconditions')}
              />
              <EntityCustomFieldForm
                customFieldForm={customFieldForm}
                setCustomFieldForm={setCustomFieldForm}
                control={control}
                setValue={setValue}
                entityFields={data?.customFields}
                entityCustomFields={entityCustomFields}
                dataForm={dataForm}
                customControlForJiraModal={false}
                setCFields={setCFields}
              />
              <Markdown
                control={control}
                name="description"
                label={t('Description')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="testCaseModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="testCaseModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEditTestCase?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};
export default TestCaseModal;
