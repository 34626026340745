import { axios } from './ApiClient';

const BASE_URL = '/api';

class ParameterApi {
  async findAll() {
    const res = await axios.get(`${BASE_URL}/parameters`);
    return res.data;
  }
  async findOne(id) {
    const res = await axios.get(`${BASE_URL}/parameters/${id}`);
    return res.data;
  }
  async create(parameter) {
    const res = await axios.post(`${BASE_URL}/parameters`, parameter);
    return res.data;
  }
  async update(parameter) {
    const res = await axios.put(`${BASE_URL}/parameters/${parameter.id}`, parameter);
    return res.data;
  }
  async delete(id) {
    const res = await axios.delete(`${BASE_URL}/parameters/${id}`);
    return res.data;
  }
}

export default new ParameterApi();
