import { Button, H5, H6 } from '../../../designSystem/DesignSystem';

const UserItem = ({ user, type, selectedUserGroupAndResults, handleOnclick, loading }) => {
  const returnText = () => {
    return (
      <>
        <td className="w-3/4 place-content-start self-start p-1">
          <H5
            color={'text-black'}
            text={user?.name}
          />
          <H6 text={user?.email} />
        </td>
      </>
    );
  };

  const checkDisabled = () => {
    if (selectedUserGroupAndResults) {
      return selectedUserGroupAndResults.some((value) => {
        return value?.userName === user?.userName;
      });
    } else return false;
  };

  const returnButton = () => {
    return (
      <>
        <td className="flex w-1/4 place-content-center self-center">
          <Button
            id="userItem-add-remomve-button"
            primary
            size="small"
            {...(type.toLowerCase() == 'add' && { disabled: checkDisabled() })}
            {...(type.toLowerCase() == 'remove' && { disabled: user?.templateBy })}
            onClick={() => handleOnclick(user)}
            icon={type.toLowerCase() == 'add' ? 'ArrowRight' : 'ArrowLeft'}
            loading={loading}
          />
        </td>
      </>
    );
  };

  if (type.toLowerCase() == 'add') {
    return (
      <>
        <tr className="flex flex-grow">
          {returnText()}
          {returnButton()}
        </tr>
      </>
    );
  } else if (type.toLowerCase() == 'remove') {
    return (
      <>
        <tr className="flex flex-grow">
          {returnButton()}
          {returnText()}
        </tr>
      </>
    );
  } else return null;
};

export default UserItem;
