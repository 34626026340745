import React, { useState, useEffect, useRef } from 'react';

import { useApi } from '../../../hooks/UseApi';
import ContainerApi from '../../../api/ContainerApi';
import { isValidValue } from '../../../helpers/Helper';

import { TCASE_VERSION_FILTER_TYPES, ENTITY_TYPES } from '../../../constants';

import CustomEntityDetail from '../../../components/detail/CustomEntityDetail';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseCustomField from '../../../hooks/UseCustomField';
import UseProjectUsers from '../../../hooks/UseProjectUsers';

const ContainerDetails = (props) => {
  const { selectedItem, submitCallBack, submitCallBackAfterDeletion, submitCallBackAfterClone } = props;

  const { projectId } = UseQueryProject('projectId');
  const customFields = UseCustomField(projectId, ENTITY_TYPES.TEST_CONTAINER);
  const users = UseProjectUsers(projectId);

  const [dataForm, setDataForm] = useState(null);
  const [filter, setFilter] = useState({});
  const [refreshStatus, setRefreshStatus] = useState(false);

  const dataLoader = useApi({
    name: 'fetching container item',
    api: ContainerApi.getContainer,
    autoLoad: false,
    onSuccess: () => setDataForm(dataLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const refreshData = (form) => {
    if (isValidValue(form)) {
      setDataForm(form);
      setRefreshStatus(true);
      if (submitCallBack) submitCallBack(form);
    } else if (!dataLoader.loading && selectedItem?.id) {
      dataLoader.execute({ id: selectedItem.id });
    }
  };

  const afterDelete = (data) => {
    if (isValidValue(data)) {
      submitCallBackAfterDeletion(data);
    }
  };

  useEffect(() => {
    refreshData();
    setFilter({
      parentId: selectedItem?.id,
      root: true,
      versionFilter: TCASE_VERSION_FILTER_TYPES.LAST_VERSION
    });
  }, [selectedItem]);

  const ref = useRef();

  return (
    <>
      <CustomEntityDetail
        ref={ref}
        config={{
          entityId: selectedItem?.id,
          entityType: ENTITY_TYPES.TEST_CONTAINER,
          users,
          refreshStatus,
          setRefreshStatus,
          loading: dataLoader?.loading,
          dataForm: dataForm,
          selectedItem,
          submitCallBack: refreshData,
          submitCallBackAfterDeletion: afterDelete,
          submitCallBackAfterClone,
          projectId,
          customFields,
          filter
        }}
      />
    </>
  );
};

export default ContainerDetails;
