import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import JiraEntityApi from '../../../api/JiraEntityApi';
import RequirementApi from '../../../api/RequirementApi';
// Component Imports
import EntityCustomFieldForm from '../../../components/customField/EntityCustomFieldForm';
import { isValidValue, editModalMapping, isValidList, checkRequired } from '../../../helpers/Helper';
// Design System Imports
import { Dropdown, Input, Range, Toggle, Button } from '../../../designSystem/DesignSystem';
import JiraIssueModalContent from '../../../components/jira/modal/JiraIssueModalContent';
import { Modal } from '../../../components/modal';
import { ENTITY_TYPES, PRIVILEGES } from '../../../constants';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { createEntityFields, getCustomFields } from '../../../helpers/CustomFieldHelper';
import { getSystemFieldOptions } from '../../../helpers/EnumHelper';
import {
  isActiveNewJiraRequirementButton,
  isActiveUpdateJiraRequirementButton
} from '../../../helpers/jira/JiraConfigurationHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { hasPrivilege } from '../../../helpers/PriviligeHelper';
import { useApi } from '../../../hooks/UseApi';
import UseCustomField from '../../../hooks/UseCustomField';
// Api and Hook Imports
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseSystemField from '../../../hooks/UseSystemField';
import useSystemFieldParams from '../../../hooks/UseSystemFieldParams';
import { useTranslation } from 'react-i18next';
import { titleTranslation, translateArrItemName } from '../../../helpers/TranslationHelper';
import { defaultValue } from '../../../helpers/SystemFieldHelper';
import Markdown from '../../../components/markdown/Markdown';

const RequirementModal = (props) => {
  const { t } = useTranslation();

  const { isOpen, data, closeModal, parentId, jiraConfig, projectConfig } = props;
  const { control, handleSubmit, setValue, reset, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { projectId } = UseQueryProject('projectId');
  const entityCustomFields = UseCustomField(projectId, ENTITY_TYPES.REQUIREMENT);
  let type = getSystemFieldOptions(UseSystemField(projectId, ENTITY_TYPES.REQUIREMENT, 'type'));
  type = translateArrItemName(type, t);
  let status = getSystemFieldOptions(UseSystemField(projectId, ENTITY_TYPES.REQUIREMENT, 'status'));
  status = translateArrItemName(status, t);
  let priority = getSystemFieldOptions(UseSystemField(projectId, ENTITY_TYPES.REQUIREMENT, 'priority'));
  priority = translateArrItemName(priority, t);
  const [isUpdate, setisUpdate] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [cFields, setCFields] = useState([]);
  const [customFieldForm, setCustomFieldForm] = useState({});
  const [openJiraDetails, setOpenJiraDetails] = useState(false);
  const [showJiraSwitchButton, setShowJiraSwitchButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const localStateOfJiraIntegration = localStorage.getItem('jiraIntegrationIsOpen');
  const systemFieldParams = useSystemFieldParams(projectId, ENTITY_TYPES.REQUIREMENT);

  const createEditRequirement = useApi({
    name: 'saving requirement',
    api: isValidValue(data) ? RequirementApi.edit : RequirementApi.create,
    onSuccess: () => {
      closeModal(createEditRequirement?.data);
      showSuccessMes(INFO_MESSAGES.save);
      setLoading(false);
    },
    onError: (err) => {
      showErrorMes(err);
      setLoading(false);
    }
  });
  const createEditRequirementWithJira = useApi({
    name: 'saving requirement with Jira',
    api: isValidValue(data) ? JiraEntityApi.updateRequirementJira : JiraEntityApi.createRequirementJira,
    onSuccess: () => {
      closeModal(createEditRequirementWithJira?.data);
      showSuccessMes(INFO_MESSAGES.save);
      setLoading(false);
    },
    onError: (err) => {
      showErrorMes(err);
      setLoading(false);
    }
  });

  const onSubmit = (form) => {
    setLoading(true);
    let cFieldForm = getCustomFields(cFields, form);
    const body = {
      ...(isValidValue(data) && { id: data.id }),
      parentId: isValidValue(parentId) ? parentId : data?.parent?.id,
      name: form.name,
      description: isValidValue(form?.description) ? form.description : '',
      priorityId: isValidValue(form?.priority?.value) ? form?.priority?.value : form?.priority?.id,
      typeId: isValidValue(form?.type?.value) ? form?.type?.value : form?.type?.id,
      statusId: isValidValue(form?.status?.value) ? form?.status?.value : form?.status?.id,
      ...(isValidValue(form?.damage) && { damage: form?.damage }),
      ...(isValidValue(form?.frequency) && { frequency: form?.frequency }),
      customFields: createEntityFields(form, cFieldForm)
    };

    let link = isValidList(form?.jiraNewLink) ? form?.jiraNewLink[0]?.id : dataForm?.jiraNewLink;
    let linkType = form?.jiraLinkTypeId?.[0]?.id ? form?.jiraLinkTypeId[0].id : form?.jiraLinkTypeId;

    if (
      jiraConfig &&
      jiraConfig?.activeRequirement === true &&
      showJiraSwitchButton === true &&
      openJiraDetails === true
    ) {
      const jiraInput = {
        ...body,
        jiraProjectId: form?.jiraProjectId?.[0]?.id ? form?.jiraProjectId?.[0]?.id : form?.jiraProjectId,
        jiraIssueTypeId: form?.jiraIssueTypeId?.[0]?.id ? form?.jiraIssueTypeId?.[0]?.id : form?.jiraIssueTypeId,
        jiraPriorityId: form?.jiraPriorityId?.[0]?.id ? form?.jiraPriorityId?.[0]?.id : form?.jiraPriorityId,
        jiraAssigneeId: form?.jiraAssigneeId?.[0]?.id ? form?.jiraAssigneeId?.[0]?.id : form?.jiraAssigneeId,
        jiraDescription: form?.jiraDescription,
        newComment: form?.newComment,
        jiraLinkTypeId: linkType,
        jiraNewLink: link
      };
      createEditRequirementWithJira.execute(jiraInput);
    } else {
      createEditRequirement.execute(body);
    }
  };

  useEffect(() => {
    setValue('priority', defaultValue(priority));
    setValue('type', defaultValue(type));
    setValue('status', defaultValue(status));
    setDataForm((prev) => ({
      ...prev,
      priorityId: defaultValue(priority)?.value,
      typeId: defaultValue(type)?.value,
      statusId: defaultValue(status)?.value
    }));
    if (isValidValue(data)) {
      setisUpdate(true);
      const form = {
        ...data,
        statusId: data?.status?.id,
        typeId: data?.type?.id,
        priorityId: data?.priority?.id,

        jiraProjectId: data?.jiraContent?.fields?.project?.id,
        jiraIssueTypeId: data?.jiraContent?.fields?.issuetype?.id,
        jiraPriorityId: data?.jiraContent?.fields?.priority?.id,
        jiraDescription: data?.jiraContent?.fields?.description
      };

      let mappedArray = editModalMapping(form);
      mappedArray.forEach(({ name, value }) => {
        setValue(name, value);
      });

      setDataForm(form);
      if (
        hasPrivilege(projectId, PRIVILEGES.UPDATE_JIRA_REQUIREMENT) &&
        isActiveUpdateJiraRequirementButton(projectConfig)
      ) {
        setShowJiraSwitchButton(true);
        setOpenJiraDetails(
          isValidValue(localStateOfJiraIntegration) && localStateOfJiraIntegration + '' === 'false' ? false : true
        );
        setValue('openJiraDetails', true);
      }
    } else {
      if (
        hasPrivilege(projectId, PRIVILEGES.CREATE_JIRA_REQUIREMENT) &&
        isActiveNewJiraRequirementButton(projectConfig)
      ) {
        setShowJiraSwitchButton(true);
        setOpenJiraDetails(
          isValidValue(localStateOfJiraIntegration) && localStateOfJiraIntegration + '' === 'false' ? false : true
        );
        setValue('openJiraDetails', true);
      }
    }
  }, [data]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w="md"
      >
        <Modal.Title>
          {titleTranslation(getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT), data, '', t)}
        </Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <Input
                disabled={
                  isValidValue(data) &&
                  projectConfig?.configuration?.requirement?.requirementNameUpdate === 'false' &&
                  true
                }
                required
                name="name"
                label={t('Name')}
                placeholder={t('name_entity', { entity: getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT) })}
                control={control}
                maxLength="255"
              />
              <div className="flex gap-4">
                <Dropdown
                  control={control}
                  label={t('Type')}
                  name="type"
                  required={checkRequired('type', systemFieldParams)}
                  clearable
                  placeholder={t('Select Type')}
                  options={type}
                  selectedItem={isValidValue(getValues('type')) ? getValues('type') : defaultValue(type)}
                  passValue={(value) => {
                    setValue('type', isValidList(value) ? value[0] : null);
                    if (!isValidList(value)) setValue('type', null);
                    setDataForm({
                      ...dataForm,
                      typeId: isValidList(value) ? value[0]?.id : null
                    });
                  }}
                />
                <Dropdown
                  control={control}
                  label={t('Status')}
                  name="status"
                  required={checkRequired('status', systemFieldParams)}
                  clearable
                  placeholder={t('Select Status')}
                  options={status}
                  selectedItem={isValidValue(getValues('status')) ? getValues('status') : defaultValue(status)}
                  passValue={(value) => {
                    setValue('status', isValidList(value) ? value[0] : null);
                    if (!isValidList(value)) setValue('status', null);
                    setDataForm({ ...dataForm, statusId: isValidList(value) ? value[0]?.id : null });
                  }}
                />
                <Dropdown
                  control={control}
                  label={t('Priority')}
                  name="priority"
                  required={checkRequired('priority', systemFieldParams)}
                  clearable
                  placeholder={t('Select Priority')}
                  options={priority}
                  selectedItem={isValidValue(getValues('priority')) ? getValues('priority') : defaultValue(priority)}
                  passValue={(value) => {
                    setValue('priority', isValidList(value) ? value[0] : null);
                    if (!isValidList(value)) setValue('priority', null);
                    setDataForm({ ...dataForm, priorityId: isValidList(value) ? value[0]?.id : null });
                  }}
                />
              </div>
              <div className="flex">
                <div className="basis-1/3">
                  <Range
                    control={control}
                    label={t('damage')}
                    name={'damage'}
                    value={isValidValue(data?.damage) ? data?.damage : 0}
                    minValue="0"
                    maxValue="5"
                  />
                </div>
                <div className="ml-3">
                  <Range
                    control={control}
                    label={t('frequency')}
                    name={'frequency'}
                    value={isValidValue(data?.frequency) ? data?.frequency : 0}
                    minValue="0"
                    maxValue="5"
                  />
                </div>
              </div>
              {jiraConfig && jiraConfig?.activeRequirement === true && showJiraSwitchButton === true && (
                <div className="flex gap-2">
                  <Toggle
                    labelRight
                    labelText={t('Enable Jira Integration')}
                    value={openJiraDetails}
                    control={control}
                    name={'openJiraDetails'}
                    passValue={(value) => {
                      setOpenJiraDetails(value);
                      localStorage.setItem('jiraIntegrationIsOpen', value);
                    }}
                  />
                </div>
              )}
              {jiraConfig?.activeRequirement === true && openJiraDetails === true && (
                <JiraIssueModalContent
                  isUpdate={isUpdate}
                  entityType={ENTITY_TYPES.REQUIREMENT}
                  jiraConfig={jiraConfig}
                  control={control}
                  setValue={setValue}
                  dataForm={dataForm}
                  setDataForm={setDataForm}
                  projectId={projectId}
                />
              )}
              <EntityCustomFieldForm
                customFieldForm={customFieldForm}
                setCustomFieldForm={setCustomFieldForm}
                control={control}
                setValue={setValue}
                entityFields={data?.customFields}
                entityCustomFields={entityCustomFields}
                dataForm={dataForm}
                customControlForJiraModal={false}
                setCFields={setCFields}
                projectConfig={projectConfig}
                entityType={ENTITY_TYPES.REQUIREMENT}
              />
              <Markdown
                control={control}
                name="description"
                label={t('Description')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="requirement-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="requirement-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default RequirementModal;
