import React, { useState } from 'react';
// API & HOOKS IMPORTS
import { useApi } from '../../../hooks/UseApi';
import UserApi from '../../../api/UserApi';
// DESIGN SYSTEM IMPORTS
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { SidePaneRightContent } from '../../../components/Layout';
// HELPER IMPORTS
import { isValidValue } from '../../../helpers/Helper';
import { isSystemAdmin, isCanAddUser } from '../../../helpers/PriviligeHelper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
// COMPONENT IMPORTS
import AddLdapUserModal from './AddLdapUserModal';
import UserModal from './UserModal';
import UserDetails from './UserDetails';
import UserSearch from '../../settings/projectUser/UserSearch';
import { useTranslation } from 'react-i18next';

const SystemUserSettingsPage = () => {
  // SEARCH STATE
  const [userList, setUserList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [passwordRule, setPasswordRule] = useState(null);
  // OPEN MODAL STATE
  const [openLdapUserModal, setOpenLdapUserModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const { t } = useTranslation();

  const userLoader = useApi({
    name: 'Get all users',
    api: UserApi.getAllUsers,
    autoLoad: true,
    onSuccess: () => setUserList(userLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const getPasswordRule = useApi({
    name: 'getPasswordRule',
    api: UserApi.getPasswordRule,
    onSuccess: () => setPasswordRule(getPasswordRule?.data),
    onError: (err) => showErrorMes(err)
  });

  const onClickItem = (item) => setSelectedItem(item);

  const submitCallBack = (data) => {
    getPasswordRule.execute();
    userLoader.execute();
    setSelectedItem(data);
  };

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading.Group
          type="large"
          text={t('System Users')}
        >
          <div className="flex gap-2 self-end">
            {(isSystemAdmin() || isCanAddUser()) && (
              <>
                <Button
                  id="systemUserSettingsPage-addUser-button"
                  primary
                  text={t('Add User')}
                  size="small"
                  icon="Plus"
                  onClick={() => setOpenUserModal(true)}
                />
                <Button
                  id="systemUserSettingsPage-addLdapUser-button"
                  primary
                  text={t('Add Ldap User')}
                  size="small"
                  icon="Plus"
                  onClick={() => setOpenLdapUserModal(true)}
                />
              </>
            )}
          </div>
        </Heading.Group>
      </SidePaneRightContent.Top>
      <div className="grid grid-cols-4 divide-x">
        <div className="col-span-1 ">
          <UserSearch
            selectedItem={selectedItem}
            list={userList}
            loading={userLoader?.loading}
            passOnClick={(item) => onClickItem(item)}
          />
        </div>
        {isValidValue(selectedItem) && (
          <div className="col-span-3">
            <UserDetails
              passwordRule={passwordRule}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              submitCallBack={submitCallBack}
            />
          </div>
        )}
      </div>
      {openLdapUserModal && (
        <AddLdapUserModal
          savedUsers={userList}
          isOpen={openLdapUserModal}
          closeModal={() => setOpenLdapUserModal(false)}
          submitCallBack={submitCallBack}
        />
      )}
      {openUserModal && (
        <UserModal
          passwordRule={passwordRule}
          isOpen={openUserModal}
          closeModal={() => setOpenUserModal()}
          submitCallBack={submitCallBack}
        />
      )}
    </>
  );
};

export default SystemUserSettingsPage;
