import { isValidList, isValidValue } from './Helper';
export const defaultValue = (arr) => {
  let filterArray = arr.filter((item) => item?.default == true)[0];
  return filterArray;
};
export const getStatusOptionsByData = (selectedData, allStatusOptions) => {
  let nodes = [];
  if (selectedData?.status) {
    nodes.push({
      key: selectedData?.status?.id,
      value: selectedData?.status?.id,
      text: selectedData?.status?.name,
      isFirstStatus: selectedData?.status?.isFirstStatus,
      isLastStatus: selectedData?.status?.isLastStatus,
      disabled: selectedData?.status?.visible === true ? false : true
    });

    if (isValidList(allStatusOptions)) {
      allStatusOptions.forEach((item) => {
        if (selectedData?.status?.id === item?.id && isValidList(item?.nextNodes)) {
          item.nextNodes.map((elem) => {
            nodes.push({
              key: isValidValue(elem?.id) ? elem.id : elem?.id,
              value: isValidValue(elem?.id) ? elem.id : elem?.id,
              text: isValidValue(elem?.text) ? elem.text : elem?.name,
              isFirstStatus: elem?.isFirstStatus,
              isLastStatus: elem?.isLastStatus,
              disabled: allStatusOptions.filter((temp) => temp?.id === elem?.id)[0]?.visible === true ? false : true
            });
          });
        }
      });
    }
  } else {
    if (allStatusOptions && allStatusOptions.length > 0) {
      allStatusOptions.forEach((item) => {
        if (item?.isFirstStatus === true) {
          nodes.push({
            key: isValidValue(item?.id) ? item.id : item?.id,
            value: isValidValue(item?.id) ? item.id : item?.id,
            text: isValidValue(item?.text) ? item.text : item?.name,
            isFirstStatus: item?.isFirstStatus,
            isLastStatus: item?.isLastStatus,
            disabled: item?.visible === true ? false : true
          });
        }
      });
    }
  }
  return nodes;
};
