import React, { useEffect, useState } from 'react';
import { fillChartData, getChartHeading } from '../helper/DashboardHelper';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import { isValidList } from '../../../helpers/Helper';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const CustomCirclePackingChart = ({ chart, projectId, dateFilter, projectConfig }) => {
  const [data, setData] = useState([]);
  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      fillChartData(dataLoader?.data, chart, setData, projectId, null);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);

  const fieldLimit = isValidList(chart?.configuration?.fields) ? chart?.configuration?.fields.length : 1;

  return (
    <>
      {getChartHeading(chart, projectConfig)}
      <ResponsiveCirclePacking
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        id="label"
        value="count"
        colors={{ scheme: 'nivo' }}
        colorBy="id"
        childColor={{ from: 'color', modifiers: [['brighter', 0.4]] }}
        padding={32}
        label={function (e) {
          return e.id + ': ' + e.value;
        }}
        enableLabels={true}
        labelsFilter={function (e) {
          return fieldLimit === e.node.depth;
        }}
        labelsSkipRadius={0}
        labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
        defs={[
          {
            id: 'lines',
            type: 'patternLines',
            background: 'none',
            color: 'inherit',
            rotation: -45,
            lineWidth: 5,
            spacing: 8
          }
        ]}
        fill={[{ match: { depth: 1 }, id: 'lines' }]}
      />
    </>
  );
};

export default CustomCirclePackingChart;

// 'left-to-right'
