import { getParentId, TreeActions } from './TreeActions';
import FolderApi from '../../../api/FolderApi';
import * as constants from '../../../constants';
import { ENTITY_TYPES, TREENODE_ROOT } from '../../../constants';
import RequirementApi from '../../../api/RequirementApi';
import { isEmpty } from 'lodash-es';

const requirementModuleActionDefinitions = {
  [constants.ENTITY_TYPES.FOLDER]: {
    parents: FolderApi.getParens,
    page: 'requirements',
    updateParent: {
      getApi: () => FolderApi.updateFolderParent,
      getBody: (dragNode, dropNode) => {
        let parentId = dropNode.id;
        if (dropNode.type === constants.ENTITY_TYPES.REQUIREMENT) {
          parentId = getParentId(dropNode);
        } else if (dropNode.type === TREENODE_ROOT.type) {
          parentId = null;
        }
        return { parentId };
      }
    },
    children: [
      {
        type: constants.ENTITY_TYPES.FOLDER,
        api: FolderApi.getTreeData,
        getParams: ({ projectId, parentId }) => ({
          root: false,
          projectId,
          parentId
        })
      },
      {
        type: constants.ENTITY_TYPES.REQUIREMENT,
        api: RequirementApi.getTreeData,
        // eslint-disable-next-line no-unused-vars
        getParams: ({ projectId, parentId }) => ({
          root: false,
          parentId
        })
      }
    ]
  },
  [constants.ENTITY_TYPES.REQUIREMENT]: {
    parents: RequirementApi.getParens,
    page: 'requirements',
    updateParent: {
      getApi: () => RequirementApi.updateRequirementParent,
      getBody: (dragNode, dropNode) => {
        let parentId = dropNode.id;
        if (dropNode.type === constants.ENTITY_TYPES.REQUIREMENT) {
          parentId = getParentId(dropNode);
        }
        return { parentId };
      }
    }
  }
};

export class RequirementTreeActions extends TreeActions {
  constructor(projectConfig, checkable) {
    super(projectConfig, checkable, ENTITY_TYPES.REQUIREMENT, requirementModuleActionDefinitions);
  }

  // eslint-disable-next-line no-unused-vars
  async getTreeData(projectId, filters) {
    // eslint-disable-next-line no-useless-catch
    try {
      if (!isEmpty(filters)) return this.getFilteredTreeData(projectId, filters);

      const treeData = await FolderApi.getTreeData({
        root: true,
        projectId,
        parentId: null
      });
      return this.createNodes(treeData, ENTITY_TYPES.FOLDER, TREENODE_ROOT.id);
    } catch (err) {
      throw err;
    }
  }

  getFilteredTreeData = async (projectId, filters) => {
    const treeData = await RequirementApi.getAll({ projectId, [filters?.type]: filters?.searchParameter });
    return this.createNodes(treeData, ENTITY_TYPES.REQUIREMENT, null);
  };

  createNodeData(data = [], entityType, parent) {
    const { jiraKey } = data;
    return {
      ...super.createNodeData(data, entityType, parent),
      jiraKey
    };
  }
}
