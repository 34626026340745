import { toast } from 'react-toastify';
import { CheckCircle, Info, Warning } from 'phosphor-react';
import { Body } from '../designSystem/DesignSystem';
import { isValidList } from './Helper';
import i18next from 'i18next';

export const showSuccessMes = (mes) => {
  toast.dismiss(); // Close all
  mes && toast(successMessage(mes));
};

export const showErrorMes = (error, toastConfig) => {
  let message = 'An error occurred while loading the page';
  let privilegeItem = error?.response?.data?.privileges;
  const response = error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data;
  let privilegeValue = [];

  if (response && response !== null && response !== '') message = response;
  else if (error && error !== null && error !== '') message = error?.mes ? error.mes : error;

  if (isValidList(privilegeItem)) {
    privilegeItem.forEach((item) => (privilegeValue = item.description));
    message = message + ' ' + privilegeValue;
  }
  message && toast(errorMessage(message), toastConfig);
  setTimeout(() => {
    toast.dismiss();
  }, 5000);
};

export const showError = (mes) => {
  toast.dismiss(); // Close all
  mes && toast(errorMessage(mes));
};

export const showInfoMes = (mes) => {
  toast.dismiss(); // Close all
  mes && toast(infoMessage(mes));
};

export const dismissAllMes = () => {
  toast.dismiss(); // Close all
};

const successMessage = (mes) => {
  if (mes)
    return (
      <div className="flex">
        <div className="flex items-center">
          <CheckCircle
            size={32}
            color="#499167"
            weight="duotone"
          />
        </div>
        <div className="ml-2 p-1">
          {' '}
          <Body
            text={i18next.t(mes)}
            type="medium"
            color="#000000"
          />
        </div>
      </div>
    );
  else return <></>;
};

const errorMessage = (mes) => {
  if (mes)
    return (
      <div className="flex">
        <div className="flex items-center">
          <Warning
            size={32}
            color="#AC2E3A"
            weight="duotone"
          />
        </div>
        <div className="ml-2 p-1">
          {' '}
          <Body
            text={i18next.t(mes.trim())}
            type="medium"
            color="#000000"
          />
        </div>
      </div>
    );
  else return <></>;
};

export const infoMessage = (mes) => {
  if (mes)
    return (
      <div className="flex">
        <div className="flex items-center">
          <Info
            size={32}
            color="#FFBF00"
            weight="duotone"
          />
        </div>
        <div className="ml-2 p-1">
          {' '}
          <Body
            text={i18next.t(mes)}
            type="medium"
            color="#000000"
          />
        </div>
      </div>
    );
  else return <></>;
};

export const INFO_MESSAGES = {
  add: 'Added successfully',
  save: 'Saved successfully.',
  update: 'Updated successfully.',
  delete: 'Deleted successfully.',
  import: 'Imported successfully.',
  export: 'Exported successfully.',
  assign: 'Assigned successfully.',
  unassign: 'Unassigned successfully.',
  clone: 'Cloned successfully.',
  execute: 'Executed successfully.',
  sendRequest: 'Request was sent successfully',
  refreshJiraHook: 'Jira hook was refreshed successfully',
  unsubscribe: 'Unsubscribed successfully.',
  removedChanges: 'Changes were removed that were not saved.',
  noHavePrivilege: 'You dont have any privilege this action',
  mailSend: 'Mail is sended successfully'
};
