import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DashboardApi from '../../../api/DashboardApi';
import { Input, Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import { editModalMapping, isValidValue } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import Markdown from '../../../components/markdown/Markdown';

const DashboardAddModal = (props) => {
  const { isOpen, data, closeModal } = props;
  const { t } = useTranslation();

  const createEditDashboard = useApi({
    name: 'createEditDashboard',
    api: isValidValue(data) ? DashboardApi.updateDashboard : DashboardApi.addDashBoard,
    onSuccess: () => {
      closeModal(createEditDashboard?.data);
      showSuccessMes(INFO_MESSAGES.add);
    },
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    let f = {
      id: data?.id,
      name: form.name,
      description: isValidValue(form.description) ? form.description : ''
    };
    createEditDashboard.execute(f);
  };
  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, []);

  const { control, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={closeModal}
      >
        <Modal.Title>{t('Dashboard')}</Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <Input
                required
                name="name"
                label={t('Name')}
                placeholder={t(`Enter name`)}
                control={control}
              />

              <Markdown
                control={control}
                name="description"
                label={t('Description')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="dashboardAddModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => closeModal()}
            />
            <Button
              id="dashboardAddModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEditDashboard?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default DashboardAddModal;
