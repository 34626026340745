export const findBuckets = (obj) => {
  let buckets = [];

  function recursiveSearch(object) {
    if (!object || typeof object !== 'object') return;

    if (Object.prototype.hasOwnProperty.call(object, 'buckets')) {
      buckets.push(object.buckets);
    }

    for (let key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        recursiveSearch(object[key]);
      }
    }
  }

  recursiveSearch(obj);
  return buckets[0];
};

export const getTableDataFromSuiteTestCaseExecutionCountAggregation = (data) => {
  const table = [];

  const testSuiteBuckets = data.aggregations.by_testSuite_code_keyword.buckets;

  testSuiteBuckets.forEach((testSuite) => {
    const testSuiteCode = testSuite.key;

    const testSuiteNameHits = testSuite.testSuite_name.hits.hits[0]._source;
    const testSuiteName = testSuiteNameHits.testSuite.name;

    const testCaseBuckets = testSuite.by_testCase_code_keyword.buckets;

    testCaseBuckets.forEach((testCase) => {
      const testCaseCode = testCase.key;

      const runBuckets = testCase.by_run_code_keyword.buckets;

      runBuckets.forEach((run) => {
        const testRunTotalValue = run.testRunExecutionStats_total.value;

        const summaryAndTesterHits = run.summary_and_tester.hits.hits[0]._source;
        const summary = summaryAndTesterHits.summary;
        const tester = summaryAndTesterHits.tester;

        const testerFullName = `${tester.firstName} ${tester.lastName}`;

        table.push({
          testSuiteCode: testSuiteCode,
          testSuiteName: testSuiteName,
          testCaseCode: testCaseCode,
          summary: summary,
          tester: testerFullName,
          totalValue: testRunTotalValue
        });
      });
    });
  });

  return mergeAndSumSuiteTestCaseExecutionCountTableData(table);
};

export const mergeAndSumSuiteTestCaseExecutionCountTableData = (table) => {
  const mergedTable = [];

  table.forEach((row) => {
    const existingRow = mergedTable.find(
      (mergedRow) =>
        mergedRow.testSuiteCode === row.testSuiteCode &&
        mergedRow.testSuiteName === row.testSuiteName &&
        mergedRow.testCaseCode === row.testCaseCode &&
        mergedRow.summary === row.summary &&
        mergedRow.tester === row.tester
    );

    if (existingRow) {
      existingRow.totalValue += row.totalValue;
    } else {
      mergedTable.push({ ...row });
    }
  });

  return mergedTable;
};
