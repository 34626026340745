import React, { useState, useEffect, useId } from 'react';
// API and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import RoleApi from '../../../api/RoleApi';
//Design System Imports
import { Heading, Button } from '../../../designSystem/DesignSystem';
//Component Imports
import ToggleCard from '../../../components/card/ToggleCard';
import { PrivilegeGroups } from './PrivilegeGroups';
import RoleModal from './RoleModal';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
//Helper Imports
import { PRIVILEGES } from '../../../constants';
import { isValidValue } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { hasPrivilege } from '../../../helpers/PriviligeHelper';
import { useTranslation } from 'react-i18next';
import { rolePrivilegeTranslate } from '../../../helpers/EntityHelper';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';

const RoleDetails = (props) => {
  const { selectedItem, allPrivileges, projectConfig, submitCallback, projectId } = props;
  const [checkedPrivileges, setCheckedPrivileges] = useState([]);
  const [isProjectManager, setIsProjectManager] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const { t } = useTranslation();

  const id = useId();

  const addRolePrivilege = useApi({
    name: 'add role privilege',
    api: RoleApi.addRolePrivilege,
    autoLoad: false,
    onSuccess: () => submitCallback(addRolePrivilege?.data),
    onError: (err) => showErrorMes(err)
  });

  const deleteRolePrivilege = useApi({
    name: 'delete role privilege',
    api: RoleApi.deleteRolePrivilege,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => submitCallback(deleteRolePrivilege?.data),
    onError: (err) => showErrorMes(err)
  });

  const deleteRole = useApi({
    name: 'delete role ',
    api: RoleApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      submitCallback(deleteRole?.data);
      setOpenDeleteModal(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const handleDelete = () => deleteRole.execute({ id: selectedItem.id });

  const onHandleCheckbox = (e, data) => {
    if (e) addRolePrivilege.execute({ id: selectedItem.id, value: data });
    else deleteRolePrivilege.execute({ id: selectedItem.id, value: data });
  };

  useEffect(() => {
    setIsProjectManager(false); //initial
    if (selectedItem) {
      setCheckedPrivileges(selectedItem.privileges !== null ? selectedItem.privileges : []);
      if (selectedItem.privileges !== null) {
        selectedItem.privileges.map((item) => {
          if (item.type === PRIVILEGES.PROJECT_MANAGEMENT) setIsProjectManager(true);
        });
      }
    }
  }, [selectedItem, selectedItem.privileges]);

  return (
    <>
      {selectedItem.id && (
        <div className="m-8 flex flex-grow flex-col">
          {/* Users Header */}
          <div className="flex items-center justify-between">
            <Heading
              type="medium"
              text={t(selectedItem.name)}
            />
            <div className="flex gap-4 self-end">
              {hasPrivilege(projectId, PRIVILEGES.UPDATE_ROLE) && (
                <Button
                  id="roleDetails-edit-button"
                  tertiary
                  text={t('Edit')}
                  size="small"
                  icon="PencilLine"
                  disabled={selectedItem?.templateBy}
                  onClick={() => setOpenEditModal(true)}
                />
              )}
              {hasPrivilege(projectId, PRIVILEGES.DELETE_ROLE) && (
                <Button
                  id="roleDetails-remove-button"
                  onClick={() => setOpenDeleteModal(true)}
                  tertiary
                  disabled={selectedItem?.templateBy}
                  size="small"
                  icon="TrashSimple"
                  text={t('Remove')}
                />
              )}
            </div>
          </div>
          {/* Settings Grid */}
          <Heading
            type="base"
            text={t('Privileges')}
          />
          <div className="grid-cols-14 grid h-screen space-y-4 divide-y overflow-y-auto py-4 pr-4">
            {PrivilegeGroups(projectConfig).map((groupItem, index) => {
              return (
                <div
                  key={index}
                  className=""
                >
                  <div className="mt-2">
                    <Heading
                      type="small"
                      text={getArtifactName(projectConfig, groupItem.group)}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6 xl:grid-cols-2">
                    {allPrivileges.map((item, index) => {
                      if (item.privilegeGroup === groupItem.group && item.name !== 'Bulk Update') {
                        return (
                          <ToggleCard
                            key={`${id}-${index}`}
                            name={item.name}
                            labelText={rolePrivilegeTranslate(item.description, groupItem.group, projectConfig)}
                            title={rolePrivilegeTranslate(item.name, groupItem.group, projectConfig)}
                            checked={
                              selectedItem.projectAdminRole
                                ? true
                                : checkedPrivileges
                                ? checkedPrivileges.some((ch) => ch.type === item.type)
                                : false
                            }
                            submitCallBack={(e) => onHandleCheckbox(e, item)}
                            disabled={
                              selectedItem.projectAdminRole ||
                              (isProjectManager && item.type !== PRIVILEGES.PROJECT_MANAGEMENT) ||
                              isValidValue(selectedItem.templateBy) ||
                              !hasPrivilege(projectId, PRIVILEGES.ASSIGN_PRIVILEGE)
                            }
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {
        <ConfirmationModal
          isOpen={openDeleteModal}
          loading={deleteRole?.loading}
          closeModal={() => setOpenDeleteModal(false)}
          handleConfirm={handleDelete}
          header={t('Remove Role from Project')}
          content={t('Are you sure to remove this role from project?')}
        />
      }
      {openEditModal && (
        <RoleModal
          isOpen={openEditModal}
          closeModal={(form) => {
            setOpenEditModal(false);
            submitCallback(form);
          }}
          data={selectedItem}
        />
      )}
    </>
  );
};
export default RoleDetails;
