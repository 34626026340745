import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CommentApi from '../../api/CommentApi';
import { TextArea, Button } from '../../designSystem/DesignSystem';
import { Modal } from '../../components/modal';
import { editModalMapping, isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useApi } from '../../hooks/UseApi';
import { useTranslation } from 'react-i18next';

const CommentEditModal = (props) => {
  const { isOpen, data, closeModal } = props;
  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { t } = useTranslation();

  const edit = useApi({
    name: 'edit comment',
    api: CommentApi.edit,
    onSuccess: () => closeModal(edit?.data),
    onError: (err) => showErrorMes(err)
  });

  const onSubmit = (form) => {
    let isEmpty = form?.comment?.trim().length > 0;
    if (isValidValue(form?.comment) && isEmpty) {
      const body = {
        id: data?.id,
        comment: form?.comment
      };
      edit.execute(body);
    }
  };

  useEffect(() => {
    if (isValidValue(data)) {
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  }, [data]);

  return (
    <>
      <Modal
        w={'sm'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title>{t('Edit Comment')}</Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'sm'}>
            <TextArea
              required
              control={control}
              name="comment"
              label={t('Comment')}
            />
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="commentEditModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="commentEditModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={edit?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default CommentEditModal;
