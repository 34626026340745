import { Controller } from 'react-hook-form';

const Range = ({ required, disabled, control, name, value, step, minValue, maxValue, label }) => {
  let LABEL_CLASS = ' text-sm font-medium  block mb-2 capitalize';
  let LABEL_SPAN_CLASS = ' ml-2 ';

  let labelClass = LABEL_CLASS;
  let labelSpanClass = LABEL_SPAN_CLASS;

  return (
    <>
      <div className="flex flex-col flex-grow mb-1">
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <label
                htmlFor={label}
                className={labelClass}
              >
                {label}: {field?.value || 0}
                {required && <span className={!disabled && labelSpanClass}>*</span>}
              </label>
              <div className="flex flex-row gap-2">
                <div className="">
                  <input
                    name={name}
                    type="range"
                    step={step}
                    min={minValue}
                    max={maxValue}
                    defaultValue={value}
                    onChange={(e) => field.onChange(e)}
                  />
                </div>
              </div>
            </>
          )}
        />
      </div>
    </>
  );
};

export { Range };
