import React, { useEffect, useRef, useState } from 'react';
import ReleaseApi from '../../api/ReleaseApi';
import { useApi } from '../../hooks/UseApi';
import { ENTITY_TYPES, PRIVILEGES } from '../../constants';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseCustomField from '../../hooks/UseCustomField';
import { isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import CustomEntityDetail from '../../components/detail/CustomEntityDetail';
import JiraEntityApi from '../../api/JiraEntityApi';
import { hasPrivilege } from '../../helpers/PriviligeHelper';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import { useApp } from '../../context/AppContext';

const ReleaseDetails = ({ selectedItem, submitCallBack, submitCallBackAfterDeletion }) => {
  const app = useApp();
  const { jiraConfig } = app;

  const { projectId } = UseQueryProject('projectId');
  const customFields = UseCustomField(projectId, ENTITY_TYPES.RELEASE);
  const users = UseProjectUsers(projectId);

  const [dataForm, setDataForm] = useState(null);
  const [refreshStatus, setRefreshStatus] = useState(false);

  const releaseLoader = useApi({
    name: 'fetching release item',
    api: ReleaseApi.getRelease,
    autoLoad: false,
    onSuccess: () => {
      setDataForm(releaseLoader?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const releaseLoaderWithJira = useApi({
    name: 'fetching release item with Jira',
    api: JiraEntityApi.fetchReleaseJira,
    autoLoad: false,
    onSuccess: () => {
      setDataForm(releaseLoaderWithJira?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const refreshData = async (form) => {
    if (isValidValue(form) && form?.name) {
      setDataForm(form);
      submitCallBack(form);
      setRefreshStatus(true);
    } else {
      if (hasPrivilege(projectId, PRIVILEGES.VIEW_JIRA_RELEASE) && jiraConfig && jiraConfig.activeRelease === true)
        await releaseLoaderWithJira.execute(selectedItem.id);
      else await releaseLoader.execute({ id: selectedItem?.id });
    }
  };

  const afterDelete = (data) => {
    if (isValidValue(data) && submitCallBackAfterDeletion) {
      submitCallBackAfterDeletion(data);
    }
  };

  useEffect(() => {
    setDataForm(null);
    if (jiraConfig) {
      refreshData();
    }
  }, [selectedItem.id, jiraConfig]);

  const ref = useRef();

  return (
    <>
      <CustomEntityDetail
        ref={ref}
        config={{
          entityId: selectedItem?.id,
          entityType: ENTITY_TYPES.RELEASE,
          users,
          refreshStatus,
          setRefreshStatus,
          loading: releaseLoader?.loading,
          dataForm: dataForm,
          selectedItem,
          submitCallBack: refreshData,
          submitCallBackAfterDeletion: afterDelete,
          customFields,
          projectId
        }}
      />
    </>
  );
};

export default ReleaseDetails;
