import React from 'react';
import { ENTITY_TYPES } from '../../constants';
import CycleInsight from './CycleInsight';
import DefectInsight from './DefectInsight';
import TestRunInsight from './TestRunInsight';


const InsightGrid = ( {projectConfig, entityId, entityType, executeTestRun }) => {

    return (
        <div className="flex flex-grow justify-between gap-8">
            {entityType === ENTITY_TYPES.TEST_CYCLE &&
                <CycleInsight
                    projectConfig={projectConfig}
                    entityId={entityId}
                    entityType={entityType}
                />
            }
            <TestRunInsight
                entityId={entityId}
                entityType={entityType}
                executeTestRun={executeTestRun}
            />
            <DefectInsight
                entityId={entityId}
                entityType={entityType}
                executeTestRun={executeTestRun}
            />
        </div>
    )
}

export default InsightGrid;