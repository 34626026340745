import { useState, useEffect } from 'react';
import TableFilterApi from '../api/TableFilterApi';
import { useAppDispatch, useApp } from '../context/AppContext';
import { saveEntityAllFields } from '../context/ProjectActions';
import { isValidList } from '../helpers/Helper';
import { showErrorMes } from '../helpers/NotificationHelper';
import { useApi } from './UseApi';
import { getCycleName, getFolderName, getRunName, getSuiteName, getTestCaseName } from '../helpers/ArtifactNameHelper';
import useProjectConfig from './UseProjectConfig';

const UseEntityAllFields = (projectId, entityType) => {
  const dispatch = useAppDispatch();
  const app = useApp();
  const entityFields = app?.entityFields?.[entityType];
  const projectConfig = useProjectConfig(projectId);

  let [values, setValues] = useState([]);

  /** Update fieldNames
   * @param {{}} projectConfig for custom artifactNames
   * @param {*} data fields
   * @return {{}} updated data
   */
  const updateFieldNames = (projectConfig, data) => {
    // This struct store default artifact names and updated artifact names
    const names = {
      'Test Suite': getSuiteName(projectConfig),
      'Test Folder': getFolderName(projectConfig),
      'Test Run': getRunName(projectConfig),
      'Test Case': getTestCaseName(projectConfig),
      'Test Cycle': getCycleName(projectConfig)
    };
    data.map((field) => {
      let fieldName = field?.name;
      Object.keys(names).forEach((defaultName) => {
        // each of names
        const updatedArtifactName = names[defaultName];
        // if includes defaultName replaced upgradedArtifactNames
        if (fieldName.includes(defaultName)) field.name = fieldName.replace(defaultName, updatedArtifactName);
      });
    });

    return data;
  };

  // <-- API SETUP -->
  const dataLoader = useApi({
    name: 'fetching ',
    api: TableFilterApi.getAllTableFields,
    onSuccess: () => {
      let data = dataLoader?.data;
      let fields = data?.allFields?.[entityType];
      if (!isValidList(fields)) fields = [];
      fields = updateFieldNames(projectConfig, fields);
      setValues(fields);
      dispatch(saveEntityAllFields(projectId, entityType, fields));
    },
    onError: (err) => showErrorMes(err)
  });
  const projectAndTemplate = useApi({
    name: 'fetching ',
    api: TableFilterApi.getProjectAndTemplateTableFields,
    onSuccess: () => {
      let data = projectAndTemplate?.data;
      let fields = data?.allFields?.[entityType];
      if (!isValidList(fields)) fields = [];
      fields = updateFieldNames(projectConfig, fields);
      setValues(fields);
      dispatch(saveEntityAllFields(projectId, entityType, fields));
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (projectId && entityType) {
      if (entityFields && entityFields?.projectId === projectId) setValues(entityFields?.values);
      else dataLoader.execute({ projectId, entityType });
    } else {
      let curentPage = window.location.href.endsWith('/project-templates');
      projectAndTemplate.execute({ isTemplate: curentPage });
    }
  }, [projectId, entityType]);

  return values;
};

export default UseEntityAllFields;
