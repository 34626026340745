import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TestRunExecutionApi from '../../../api/TestRunExecutionApi';
import { Dropdown, TextArea, StatusPill, Button, Body, Heading, Loader } from '../../../designSystem/DesignSystem';
import { RunExecutionStatus } from '../../../constants';
import { getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import EnumHelper from '../../../helpers/EnumHelper';
import { isValidList, isValidValue } from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import ExecutionStepAttachmentModal from '../modal/ExecutionStepAttachmentModal';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { translateArrItemName } from '../../../helpers/TranslationHelper';

const TestRunExecutionTab = (props) => {
  const { execution, setExecution, runId, projectConfig, preconditions } = props;
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { t } = useTranslation();

  const [executionSteps, setExecutionSteps] = useState([]);

  const getExecutionSteps = useApi({
    name: 'get execution',
    api: TestRunExecutionApi.getSteps,
    autoLoad: false,
    onSuccess: () => {
      setExecutionSteps(getExecutionSteps?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const executionUpdate = useApi({
    name: 'saving test run execution',
    api: TestRunExecutionApi.update,
    autoLoad: false,
    onSuccess: () => {
      setExecution(executionUpdate?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err)
  });

  const executionComplete = useApi({
    name: 'complete test run execution',
    api: TestRunExecutionApi.complete,
    onSuccess: () => {
      setExecution(executionComplete?.data);
      showSuccessMes('Completed successfully.');
    },
    onError: (err) => showErrorMes(err)
  });

  const getSteps = () => getExecutionSteps.execute(execution?.id);

  const onActualResultChange = (value, index) => {
    let newState = [...executionSteps];
    newState[index].actualResult = value;
    setExecutionSteps(newState);
  };

  const onStatusChange = (value, index) => {
    let newState = [...executionSteps];
    newState[index].status = value;
    setExecutionSteps(newState);
  };

  const onAttachmentChange = (value, index) => {
    let newState = [...executionSteps];
    newState[index].attachmentCount = value;
    setExecutionSteps(newState);
  };

  const onSubmit = (data) => {
    const testRunExecutionSteps = executionSteps.map((step) => ({
      testRunExecutionStepId: step.id,
      actualResult: step.actualResult,
      status: step.status
    }));

    const body = {
      testRunExecutionSteps,
      testRunId: runId,
      status: data?.status
    };

    executionUpdate.execute({ id: execution.id, data: body });
  };

  const onComplete = () => {
    executionComplete.execute(execution.id);
  };
  let quickTestRunOptions = translateArrItemName(Object.values(RunExecutionStatus), t);

  useEffect(() => {
    if (execution?.id) {
      setValue('status', execution?.status);
      getSteps();
    }
  }, [execution]);

  return (
    <div className="grid h-176 flex-grow grid-cols-6 divide-y overflow-y-auto">
      <div className="col-span-6">
        <form
          className="flex w-full flex-col gap-4 overflow-x-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          {!execution?.completed && (
            <div className="grid grid-cols-6 gap-4">
              <div className="col-start-1 col-end-3">
                <Dropdown
                  clearable
                  control={control}
                  label={t('Execution Status')}
                  name="status"
                  options={quickTestRunOptions || []}
                  selectedItem={
                    isValidValue(getValues('status')) ? { name: getValues('status') } : { name: execution?.status }
                  }
                  passValue={(value) => {
                    if (!isValidList(value)) setValue('status', null);
                    else setValue('status', value[0]?.value);
                  }}
                />
              </div>
              <div className="col-span-2 col-end-7">
                <div className="mt-8 flex flex-row-reverse gap-4">
                  <Button
                    id="testRunExecution-Complete-button"
                    secondary
                    text={t('Complete')}
                    type="button"
                    size="small"
                    onClick={onComplete}
                    loading={executionComplete?.loading}
                    disabled={!EnumHelper('RunExecutionStatus', execution.status)}
                  />
                  <Button
                    id="testRunExecution-save-button"
                    secondary
                    text={t('Save as Draft')}
                    type="submit"
                    size="small"
                    onClick={onSubmit}
                    loading={executionUpdate?.loading}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="divide-y">
            {isValidValue(preconditions) && (
              <div className=" m-4 flex flex-row">
                <div className={`flex-row bg-white`}>
                  <Heading
                    className="w-36"
                    type="small"
                    text={t('Preconditions')}
                  />
                  <div className={'max-h-32 overflow-y-auto'}>
                    <Body
                      type="medium"
                      text={preconditions}
                    />
                  </div>
                </div>
              </div>
            )}
            <table className="w-full">
              <thead className="overflow-x-auto overflow-y-auto bg-gray-50">
                <tr className=" text-left text-xs font-medium tracking-wide  text-gray-500">
                  <th className=" px-2 py-3"> #</th>
                  <th className=" px-2 py-3">
                    {i18next.t('TestRunStepsRelated', { entity: getTestCaseName(projectConfig) })}{' '}
                  </th>
                  {['Step', 'Expected Result', 'Actual Result', 'Step Result', 'Attachments'].map((header, index) => (
                    <th
                      key={index}
                      className=" px-2 py-3"
                    >
                      {' '}
                      {t(header)}{' '}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 overflow-x-hidden  bg-white">
                {getExecutionSteps?.loading ? (
                  <Loader />
                ) : (
                  (executionSteps || []).map((step, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-1 py-4">
                        <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                          {index + 1}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4">
                        <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                          {step.testCaseStep?.testCase?.code} {' v'}
                          {step.testCaseStep?.testCase?.majorVersion}
                        </div>
                      </td>
                      <td className="col-span-2 self-center overflow-auto px-2 py-4 ">
                        <div className="font-regular  text-sm text-gray-800">{step.testCaseStep?.description}</div>
                      </td>
                      <td className="col-span-2 self-center overflow-auto px-2 py-4">
                        <div className="font-regular text-sm text-gray-800">{step.testCaseStep?.expectedResult}</div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 ">
                        <div className="font-regular text-sm text-gray-800">
                          {!execution?.completed ? (
                            <TextArea
                              placeholder={t('Enter your text here')}
                              control={control}
                              name={'actualResult' + index}
                              value={step?.actualResult}
                              passValue={(value) => onActualResultChange(value, index)}
                            />
                          ) : (
                            step.actualResult
                          )}
                        </div>
                      </td>
                      <td className="gap-5 whitespace-nowrap px-2 py-4 ">
                        {!execution?.completed ? (
                          <Dropdown
                            clearable
                            control={control}
                            name="type"
                            options={quickTestRunOptions || []}
                            selectedItem={{ name: step?.status }}
                            passValue={(value) => onStatusChange(value[0]?.value, index)}
                          ></Dropdown>
                        ) : (
                          <StatusPill value={step.status} />
                        )}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <div className=" flex gap-2 overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                          <ExecutionStepAttachmentModal
                            step={step}
                            submitCallBack={(value) => onAttachmentChange(value, index)}
                          />
                          <Heading
                            type="small"
                            text={step.attachmentCount}
                            color="text-gray-500"
                            className="place-self-center"
                          ></Heading>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TestRunExecutionTab;
