import React, { useEffect, useState } from 'react';
import { fillChartData, getChartHeading } from '../helper/DashboardHelper';
import { ResponsivePieCanvas } from '@nivo/pie';
import DashboardApi from '../../../api/DashboardApi';
import { useApi } from '../../../hooks/UseApi';
import { showErrorMes } from '../../../helpers/NotificationHelper';

const CustomPieChart = ({ chart, projectId, dateFilter, projectConfig }) => {
  const [data, setData] = useState([]);

  const dataLoader = useApi({
    name: 'getChartElasticResult',
    api: DashboardApi.getChartElasticResult,
    autoLoad: false,
    onSuccess: () => {
      fillChartData(dataLoader?.data, chart, setData, projectId, null);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (chart) {
      dataLoader.execute({ id: chart?.id, projectId, ...dateFilter });
    }
  }, [chart]);

  return (
    <>
      {getChartHeading(chart, projectConfig)}
      <ResponsivePieCanvas
        data={data}
        height={350}
        margin={{ top: 40, right: 200, bottom: 40, left: 80 }}
        innerRadius={0}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ scheme: 'accent' }}
        borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={3}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', '3']] }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 110,
            translateY: 80,
            itemsSpacing: 7,
            itemWidth: 60,
            itemHeight: 14,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 15,
            symbolShape: 'circle'
          }
        ]}
      />
    </>
  );
};

export default CustomPieChart;

// 'left-to-right'
