import { bound, indexOf, isClosed, isItem, isOpenWithEmptyChildren } from 'react-arborist/dist/main/utils';

function measureHover(el, offset) {
  const rect = el.getBoundingClientRect();
  const x = offset.x - Math.round(rect.x);
  const y = offset.y - Math.round(rect.y);
  const height = rect.height;
  const inMiddle = y > height / 4 && y < height - height / 4;
  return { x, inMiddle, atTop: !inMiddle && y < height / 2, atBottom: !inMiddle && y >= height / 2 };
}

function getNodesAroundCursor(node, prev, next, hover) {
  if (!node) return [prev, null];
  if (hover.atTop || (node.isInternal && hover.inMiddle)) return [prev, node];
  return [node, hover.atBottom && node.isInternal ? next : node];
}

function dropAt(parentId, index) {
  return { parentId: parentId || null, index };
}

function lineCursor(index, level) {
  return { type: 'line', index, level };
}

function highlightCursor(id) {
  return { type: 'highlight', id };
}

function walkUpFrom(node, level) {
  let drop = node;
  while (drop.parent && drop.level > level) drop = drop.parent;
  return { parentId: drop.parent?.id || null, index: indexOf(drop) + 1 };
}

export function computeDrop(args) {
  const hover = measureHover(args.element, args.offset);
  const hoverLevel = Math.round(Math.max(0, hover.x - args.indent) / args.indent);
  const { node, nextNode, prevNode } = args;
  const [above, below] = getNodesAroundCursor(node, prevNode, nextNode, hover);

  if (node) return { drop: dropAt(node.id, null), cursor: highlightCursor(node.id) };
  if (!above) return { drop: dropAt(below?.parent?.id, 0), cursor: lineCursor(0, 0) };

  const level = bound(hoverLevel, below?.level || 0, above.level);
  if (isItem(above) || isClosed(above) || (isOpenWithEmptyChildren(above) && level <= above.level)) {
    return { drop: walkUpFrom(above, level), cursor: lineCursor(above.rowIndex + 1, level) };
  }
  if (isOpenWithEmptyChildren(above) && level > above.level) {
    return { drop: dropAt(above.id, 0), cursor: lineCursor(above.rowIndex + 1, level) };
  }
  return { drop: dropAt(above?.id, 0), cursor: lineCursor(above.rowIndex + 1, above.level + 1) };
}
