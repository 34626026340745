import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextArea, Button } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import { editModalMapping, isValidValue } from '../../../../helpers/Helper';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const JiraRetriveStartModal = (props) => {
  const { isOpen, closeModal, startRetrieveOperation, jiraConfig, defaultQuery, customQuery } = props;

  const [data, setData] = useState({ customFilter: '' });
  const { t } = useTranslation();

  useEffect(() => {
    if (isValidValue(customQuery)) {
      data.customFilter = customQuery;
    } else data.customFilter = '';

    let mappedArray = editModalMapping(data);
    mappedArray.forEach(({ name, value }) => setValue(name, value));
    setData({ ...data });
  }, [jiraConfig, customQuery]);

  function onSubmit(formData) {
    startRetrieveOperation({
      customFilter: isValidValue(formData?.customFilter) ? formData?.customFilter : '',
      integrationId: jiraConfig?.id
    });
  }

  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t('Retrieve from JIRA')}</Modal.Title>

        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-5">
              {getInfoMessage(defaultQuery, jiraConfig)}

              <div className="flex gap-4">
                <TextArea
                  placeholder={t('Enter a custom filter (optional)')}
                  name={'customFilter'}
                  label={t('JIRA JQL Filter (optional)')}
                  control={control}
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="jiraRetriveStartModal-cancel-button"
              secondary
              text={i18next.t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="jiraRetriveStartModal-start-button"
              primary
              text={i18next.t('Start')}
              type="submit"
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default JiraRetriveStartModal;

export const getInfoMessage = (defaultQuery, jiraConfig) => {
  return (
    <>
      <div
        className=" bg-gray-100 py-1 "
        style={{
          borderRadius: 8,
          background: '#f6faff'
        }}
      >
        <div className="p-3">
          <div className="text-sm font-normal">
            <span>
              {defaultQuery}
              <br />
              <br />
              {i18next.t('Default filter will be used to filter as mandatory.')}
            </span>

            <span>
              {i18next.t('Additionally you can enter a specific project, type or other fields to filter.')}
              <br />
              {i18next.t('For example filter is')} ( type=&apos;Story&apos; AND status=&apos;To Do&apos; )
              <br />
              <br />
              {i18next.t('Please validate filter on JIRA search screen before start operation.')}
              <br />
              {jiraConfig?.serverUrl && (
                <a
                  href={jiraConfig?.serverUrl}
                  className="underline hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {i18next.t('Jira Page Link')}
                </a>
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
