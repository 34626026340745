import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
//Api & Hooks
import { useApi } from '../../hooks/UseApi';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseRefreshInsights from '../../hooks/UseRefreshInsights';
import { useAppDispatch } from '../../context/AppContext';
import { setRefreshData } from '../../context/ProjectActions';
import InsightApi from '../../api/InsightApi';
//Components
import { Heading, Loader } from '../../designSystem/DesignSystem';
import PercentageBar from '../general/PercentageBar';
//Helpers & Constants
import { getRunName } from '../../helpers/ArtifactNameHelper';
import { RunExecutionStatus } from '../../constants';

const TestRunInsight = ({ projectConfig, entityId, entityType, dateRange }) => {
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();
  const { refreshData } = UseRefreshInsights();
  const dispatch = useAppDispatch();

  const [testRunStats, setTestRunStats] = useState({});
  const [total, setTotal] = useState(0);

  const getTestRunStats = useApi({
    name: 'get test run stats',
    api: InsightApi.getTestRunInsight,
    autoLoad: false,
    onSuccess: () => {
      const data = getTestRunStats?.data;
      const list = [];
      if (data && data.length > 0) {
        data.map((item) => {
          if (item?.status === 'TOTAL') {
            setTotal(item?.count);
          } else {
            const statusObj = { name: item.status, value: item?.count };
            const data = Object.values(RunExecutionStatus).find((stts) => stts.text === item.status);
            if (data) {
              statusObj['color'] = data.color;
            }
            list.push(statusObj);
          }
        });
      }
      setTestRunStats(list);
      dispatch(setRefreshData(false));
    }
  });

  const getTestRunInsight = (dateRange) => {
    // TODO: dateRange parametresi kontrol edilecek
    // eslint-disable-next-line no-unused-vars
    dateRange = {
      startDate: dateRange?.startDate.format('yyyy-MM-DD'),
      endDate: dateRange?.endDate.format('yyyy-MM-DD')
    };
    getTestRunStats.execute({ projectId, entityType, entityId });
  };

  useEffect(() => {
    if (entityType && entityId) {
      getTestRunInsight({ startDate: new moment().add(-6, 'days'), endDate: new moment() });
    } else if (dateRange) {
      getTestRunInsight(dateRange);
    }
  }, [entityId, entityType]);

  useEffect(() => {
    if (refreshData) {
      getTestRunInsight({ startDate: new moment().add(-6, 'days'), endDate: new moment() });
    }
  }, [refreshData]);

  return (
    <>
      <div className="flex-grow items-center justify-around rounded-lg border border-gray-100 bg-white p-4 shadow-md">
        {getTestRunStats?.loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex justify-between">
              <Heading
                type="small"
                text={t('testRunInsight', { entity: getRunName(projectConfig) })}
              />
              <Heading
                type="small"
                text={t('insightTotal', { entity: total })}
              />
            </div>
            <PercentageBar datas={testRunStats} />
          </>
        )}
      </div>
    </>
  );
};

export default TestRunInsight;
