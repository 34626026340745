// API & HOOKS
import React, { useState, useEffect } from 'react';
import { useApi } from '../../hooks/UseApi';
import InsightApi from '../../api/InsightApi';
// DESIGN SYSTEM & COMPONENTS
import { ENTITY_TYPES } from '../../constants';
import UseQueryProject from '../../hooks/UseQueryProject';
// HELPERS & CONSTANTS
import { Heading, Loader } from '../../designSystem/DesignSystem';
import PercentageBar from '../general/PercentageBar';
import { getDefectName } from '../../helpers/ArtifactNameHelper';
import { useTranslation } from 'react-i18next';

const DefectInsight = ({ projectConfig, entityId, entityType }) => {
  const { projectId } = UseQueryProject('projectId');
  const [defectStats, setDefectStats] = useState({});
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const getDefectStats = useApi({
    name: 'get defect stats',
    api: InsightApi.getDefectInsight,
    autoLoad: false,
    onSuccess: () => {
      const data = getDefectStats?.data;
      const list = [];
      if (data && data.length > 0) {
        data.map((item) => {
          if (item?.name === 'Total') {
            setTotal(item?.count);
          } else {
            list.push({ name: item?.name, value: item?.count, color: item?.colorCode });
          }
        });
      }

      setDefectStats(list);
    }
  });

  const getEntityIdType = (entityType) => {
    if (entityType === ENTITY_TYPES.RELEASE) {
      return 'releaseIds';
    } else if (entityType === 'project') {
      return 'projectId';
    } else {
      return entityType + 'Id';
    }
  };

  const getDefectInsight = () => {
    const params = {};
    params[getEntityIdType(entityType)] = entityId;
    getDefectStats.execute({ projectId, ...params });
  };

  useEffect(() => {
    getDefectInsight();
  }, [entityId, entityType]);

  return (
    <>
      <div className="flex-grow items-center justify-around rounded-lg border border-gray-100 bg-white p-4 shadow-md">
        {getDefectStats?.loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex justify-between">
              <Heading
                type="small"
                text={t('testRunInsight', { entity: getDefectName(projectConfig) })}
              />
              <Heading
                type="small"
                text={t('insightTotal', { entity: total })}
              />
            </div>
            <PercentageBar datas={defectStats} />
          </>
        )}
      </div>
    </>
  );
};

export default DefectInsight;
