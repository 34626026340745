import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// HOOKS & API IMPORTS
import UsePrevious from '../../hooks/UsePrevious';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
// COMPONENT IMPORTS
import DefectReactTable from './DefectReactTable';
import DefectModal from './Modal/DefectModal';
import SelectedTableFilters from '../../components/filtering/SelectedTableFilters';
import { FullPageContainer, SidePaneRightContent, TOP_HEADING_SIZE } from '../../components/Layout';
import { Heading, Button } from '../../designSystem/DesignSystem';
// HELPER IMPORTS
import { isViewDefectPrivileged } from '../../helpers/PriviligeHelper';
import { ENTITY_TYPES } from '../../constants';
import { getDefectName } from '../../helpers/ArtifactNameHelper';
import { isValidValue } from '../../helpers/Helper';
import AlertModal from '../../components/alert/AlertModal';
import DetailsModals from '../../components/general/DetailsModals';

const DefectPage = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const users = UseProjectUsers(projectId);
  let [searchParams] = useSearchParams();

  const [allFilters, setAllFilters] = useState({});
  const [defectModal, setDefectModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  let tableHeight =
    ref?.current?.clientHeight - ref?.current?.childNodes[0]?.clientHeight - ref?.current?.childNodes[1]?.clientHeight;

  const defectId = useMemo(
    () => (searchParams?.get('defectId') ? searchParams?.get('defectId') : null),
    [searchParams]
  );
  const prevDefectId = UsePrevious(defectId);

  useEffect(() => {
    if (isValidValue(prevDefectId) && defectId === null) setRefreshTable(true);
  }, [defectId]);

  return (
    <>
      {!isViewDefectPrivileged(projectId) && (
        <AlertModal
          header={t('Access Denied')}
          content={t(`You have no permission to access this page.`)}
        />
      )}
      {defectId ? (
        <DetailsModals />
      ) : (
        <FullPageContainer p="p-1">
          <SidePaneRightContent.Top px="px-6">
            <Heading
              type={TOP_HEADING_SIZE}
              text={`${getDefectName(projectConfig)}`}
            />
          </SidePaneRightContent.Top>
          <div
            ref={ref}
            className="h-full p-6"
          >
            <div className="grid-cols-span-12-11 grid cursor-pointer grid-cols-12">
              <div className="col-span-11">
                <SelectedTableFilters
                  allFilters={allFilters}
                  setAllFilters={setAllFilters}
                  entityType={ENTITY_TYPES.DEFECT}
                  setRefreshTable={setRefreshTable}
                />
              </div>
              <div className="grid-col-none mt-5">
                <Button
                  id="defect-add-button"
                  primary
                  size="small"
                  icon="Plus"
                  text={getDefectName(projectConfig)}
                  onClick={() => setDefectModal(true)}
                />
              </div>
            </div>
            <DefectReactTable
              refreshStatus={refreshTable}
              setRefreshStatus={setRefreshTable}
              filter={{ ...allFilters, projectId }}
              users={users}
              height={tableHeight}
            />
          </div>
        </FullPageContainer>
      )}

      {defectModal && (
        <DefectModal
          isOpen={defectModal}
          closeModal={() => {
            setDefectModal(false);
            setRefreshTable(true);
          }}
          projectConfig={projectConfig}
        />
      )}
    </>
  );
};

export default DefectPage;
