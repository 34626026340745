import React, { useEffect, useState } from 'react';
// DESIGN SYSTEM IMPORTS
import { Button } from '../../../../designSystem/DesignSystem';
import { Modal } from '../../../../components/modal';
import { editModalMapping, isValidValue } from '../../../../helpers/Helper';
// HOOK && CONTEXT IMPORTS+
import { useApi } from '../../../../hooks/UseApi';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import JiraApi from '../../../../api/JiraApi';
// HELPER & CONSTANT IMPORTS
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';

const JiraHookViewModal = ({ isOpen, closeModal, jiraConfig }) => {
  const [data, setData] = useState([]);

  const { setValue } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { t } = useTranslation();

  const updateVManageJiraHook = useApi({
    name: 'saving jira server config',
    api: JiraApi.updateVManageJiraHook,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.refreshJiraHook);
      let mes = '';
      if (isValidValue(updateVManageJiraHook?.data)) mes = JSON.stringify(updateVManageJiraHook.data, null, '\t');
      setData(mes);
    },
    onError: (err) => {
      data.response = showErrorMes(err);
      let mappedArray = editModalMapping(data);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
    }
  });

  const refreshHook = () => {
    updateVManageJiraHook.execute({ id: jiraConfig.id });
  };

  useEffect(() => {
    if (jiraConfig) {
      refreshHook();
    }
  }, [jiraConfig?.id]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'sm'}
      >
        <Modal.Title>{t('JIRA Hook Response')}</Modal.Title>

        <form className="flex flex-grow flex-col gap-4">
          <Modal.Content h={'md'}>
            <div
              className="flex w-full flex-grow flex-col gap-5"
              style={{ minHeight: 400 }}
            >
              <div className="flex w-full gap-4">
                <pre className="w-full whitespace-pre-wrap break-words bg-gray-200 p-1 text-sm">{data}</pre>
              </div>
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="jiraHookViewModal-close-button"
              secondary
              text={t('Close')}
              onClick={() => {
                closeModal();
              }}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default JiraHookViewModal;
