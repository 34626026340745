import React, { useRef, useState } from 'react';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseJiraConfig from '../../hooks/UseJiraConfig';
import UseProjectUsers from '../../hooks/UseProjectUsers';
import RequirementReactTable from './RequirementReactTable';
import SelectedTableFilters from '../../components/filtering/SelectedTableFilters';
import { SidePaneRightContent, TOP_HEADING_SIZE } from '../../components/Layout';
import { Heading } from '../../designSystem/DesignSystem';
import { getArtifactName } from '../../helpers/ArtifactNameHelper';
import { ENTITY_TYPES } from '../../constants';

const AllRequirementPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const jiraConfig = UseJiraConfig(projectId);
  const users = UseProjectUsers(projectId);

  const [allFilters, setAllFilters] = useState({});
  const [refreshTable, setRefreshTable] = useState(true);

  const centerRef = useRef(); //General center height
  const centerHeight = centerRef?.current?.clientHeight;
  const tableHeight = centerHeight - centerRef?.current?.childNodes[0]?.clientHeight - 50; // Table Height

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading
            type={TOP_HEADING_SIZE}
            text={`${getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT)}`}
          />
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center ref={centerRef}>
            <SelectedTableFilters
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              entityType={ENTITY_TYPES.REQUIREMENT}
              setRefreshTable={setRefreshTable}
            />
            <RequirementReactTable
              filter={{
                ...allFilters,
                projectId,
                includeExecutionStats: true,
                includeReleases: true,
                includeCustomFields: true
              }}
              jiraConfig={jiraConfig}
              entityType={ENTITY_TYPES.REQUIREMENT}
              refreshStatus={refreshTable}
              setRefreshStatus={setRefreshTable}
              users={users}
              tableHeight={tableHeight - 5}
            />
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>
    </>
  );
};

export default AllRequirementPage;
