import React, { useEffect, useMemo, useState } from 'react';
// API & HOOKS
import { useApi } from '../../../hooks/UseApi';
import TestCycleApi from '../../../api/TestCycleApi';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseTableColumns from '../../../hooks/UseTableColumns';
import UseUserTablePreferences from '../../../hooks/UseUserTablePreferences';
import UseTableHelper from '../../../hooks/UseTableHelper';
import UseSystemField from '../../../hooks/UseSystemField';
import { getI18n, useTranslation } from 'react-i18next';
// COMPONENT
import TestPlanningReportModal from '../../../components/report/planning/TestPlanningReportModal';
import Table from '../../../components/table/Table';
import { TableColumns } from '../../../components/table/TableColumns';
import { getReportActionForTable } from '../../../components/report/ReportHelper';
// HELPER & CONSTANT
import { ENTITY_TYPES } from '../../../constants';
import { getPageFilterInput } from '../../../helpers/Helper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import {
  DEFAULT_PAGE_PARAMS,
  disableResizingForColumns,
  getNotResizedColumnList,
  getTableActions,
  handleSelectedRow,
  translateColumns
} from '../../../helpers/TableHelper';
import { getTestFolderName } from '../../../helpers/ArtifactNameHelper';

const TestCycleReactTable = ({ filter, setRefreshStatus, refreshStatus, users, height, projectConfig }) => {
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();

  UseSystemField(projectId, ENTITY_TYPES.TEST_CYCLE, 'status');
  const { selectedColumns } = UseTableColumns(projectId, ENTITY_TYPES.TEST_CYCLE);
  const { createTableData } = UseTableHelper();
  const { userSavedFilters } = UseUserTablePreferences(projectId, ENTITY_TYPES.TEST_CYCLE);

  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('name', 'ASC'));
  const [customFilters, setCustomFilters] = useState({});
  const [selectedItem, setSelectedItem] = useState({});

  const [reportModal, setReportModal] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(0);

  const getTestCycles = useApi({
    name: 'fetching test cycle',
    api: TestCycleApi.getAll,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: getTestCycles?.data.size,
        pageSize: getTestCycles?.data.pageSize,
        totalPages: getTestCycles?.data?.totalPages,
        totalElements: getTestCycles?.data?.totalElements
      }));
      setRefreshStatus(false);
    },
    onError: (err) => showErrorMes(err)
  });

  const optionsFn = (row) => {
    let ops = [];
    let opt = getReportActionForTable(
      projectId,
      handleSelectedRow,
      getTestCycles,
      setReportModal,
      row,
      setSelectedItem
    );

    if (opt !== null) {
      ops.push(opt);
      return ops;
    }
    return null;
  };

  const tData = useMemo(() => {
    let data = [];
    if (getTestCycles?.data?.content) {
      const columnsConfig = TableColumns[ENTITY_TYPES.TEST_CYCLE];

      // Test Folder is  a configurable field, so it should be updated
      const testFolderNameColumn = columnsConfig.find((column) => column?.key === 'testFolderName');
      if (testFolderNameColumn?.name)
        testFolderNameColumn.name = `${t('entityName', { entity: getTestFolderName(projectConfig) })} `;

      const testFolderCodeColumn = columnsConfig.find((column) => column?.key === 'testFolderCode');
      if (testFolderCodeColumn?.name)
        testFolderCodeColumn.name = `${t('entityCode', { entity: getTestFolderName(projectConfig) })} `;

      data = getTestCycles?.data?.content.map((item) =>
        createTableData(item, columnsConfig, projectId, false, ENTITY_TYPES.TEST_CYCLE, users)
      );
    }
    return data;
  }, [getTestCycles?.data?.content, getI18n().language]);

  const loading = useMemo(() => getTestCycles?.loading, [getTestCycles?.loading]);

  const onFilter = (newFilters) => {
    setCustomFilters(newFilters);
    getItems({ ...pageParams }, newFilters);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    getItems(pageInput, customFilters);
  };

  const getItems = (pageInput, filterInput) => {
    let fil = { ...filterInput, ...filter };
    if (userSavedFilters?.filterInput) {
      fil = { ...fil, ...userSavedFilters?.filterInput };
    }
    const body = { ...fil, ...getPageFilterInput(pageInput), projectId };
    getTestCycles.execute(body);
  };

  const refreshTable = () => getByPage({});

  let columns = [getTableActions(optionsFn), ...selectedColumns];
  columns = disableResizingForColumns(columns, getNotResizedColumnList(ENTITY_TYPES.TEST_CYCLE));
  columns = translateColumns(columns, t);

  useEffect(() => {
    if (refreshStatus) {
      refreshTable();
      setRerenderKey((prevKey) => prevKey + 1);
    }
  }, [refreshStatus]);

  return (
    <>
      <Table
        key={rerenderKey}
        columns={columns}
        data={tData}
        pagination
        loading={loading}
        pageParams={pageParams}
        getByPage={getByPage}
        onFilter={onFilter}
        entityType={ENTITY_TYPES.TEST_CYCLE}
        submitCallBack={() => refreshTable()}
        height={height}
      />
      {reportModal && (
        <TestPlanningReportModal
          entityData={selectedItem}
          isOpen={reportModal}
          closeModal={() => setReportModal(false)}
          entityId={selectedItem?.id}
          users={users}
          entityType={ENTITY_TYPES.TEST_CYCLE}
        />
      )}
    </>
  );
};

export default TestCycleReactTable;
