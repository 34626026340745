import React, { useState, useEffect } from 'react';
// API and Hook Imports
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
import { useApi } from '../../../hooks/UseApi';
import RoleApi from '../../../api/RoleApi';
import PrivilegeApi from '../../../api/PrivilegeApi';
//Design System Imports
import { Heading, Button } from '../../../designSystem/DesignSystem';
//Component Imports
import RoleDetails from './RoleDetails';
import RoleModal from './RoleModal';
import UserSearch from '../projectUser/UserSearch';
import { useTranslation } from 'react-i18next';
//Helper Imports
import { isValidList, isValidValue } from '../../../helpers/Helper';
import { isProjectManager, hasPrivilege } from '../../../helpers/PriviligeHelper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { SidePaneRightContent } from '../../../components/Layout';
import { PRIVILEGES } from '../../../constants';

const RoleSettingsPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const [selectedItem, setSelectedItem] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [allPrivileges, setAllPrivileges] = useState([]);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const { t } = useTranslation();

  const projectRoles = useApi({
    name: 'fetching project roles',
    api: RoleApi.getProjectRoleList,
    autoLoad: false,
    onSuccess: () => {
      if (isValidList(projectRoles?.data)) {
        setRoleList(projectRoles.data.filter((x) => x?.name !== 'Project Users' && x?.defaultRole !== true));
      }
    },
    onError: (err) => showErrorMes(err)
  });

  const privilegeLoader = useApi({
    name: 'Get Privilege List',
    api: PrivilegeApi.getPrivilegeList,
    onSuccess: () => setAllPrivileges(privilegeLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  const getRoles = () => projectRoles.execute({ projectId });

  const getAllPrivileges = () => privilegeLoader.execute({});

  const onClickItem = (item) => setSelectedItem(item);

  const submitCallback = (data) => {
    getRoles();
    if (data?.id) setSelectedItem(data);
    else setSelectedItem(null);
  };

  const afterCreateRole = (form) => {
    setOpenRoleModal(false);
    if (form) submitCallback(form);
  };

  useEffect(() => {
    if (projectId && (isProjectManager(projectId) || hasPrivilege(projectId, PRIVILEGES.VIEW_ROLE))) {
      getRoles();
      getAllPrivileges();
    }
  }, [projectId]);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('Roles')}
          >
            {hasPrivilege(projectId, PRIVILEGES.CREATE_ROLE) && (
              <div className="flex items-center justify-between gap-4">
                <div className="flex self-end">
                  <Button
                    id="roleSettingsPage-addRole-button"
                    primary
                    text={t('Add Role')}
                    icon="Plus"
                    onClick={() => setOpenRoleModal(true)}
                  />
                </div>
              </div>
            )}
          </Heading.Group>
        </SidePaneRightContent.Top>
        <div className="grid h-full grid-cols-4 divide-x">
          <div className="col-span-1">
            <UserSearch
              selectedItem={selectedItem}
              list={roleList}
              loading={projectRoles.loading}
              passOnClick={(item) => onClickItem(item)}
            />
          </div>
          <div className="treeview-container col-span-3 flex flex-grow">
            {isValidValue(selectedItem) && (
              <div className="flex flex-grow">
                <RoleDetails
                  allPrivileges={allPrivileges}
                  selectedItem={selectedItem}
                  projectConfig={projectConfig}
                  submitCallback={submitCallback}
                  projectId={projectId}
                />
              </div>
            )}
          </div>
        </div>
        {openRoleModal && (
          <RoleModal
            projectId={projectId}
            isOpen={openRoleModal}
            closeModal={(form) => afterCreateRole(form)}
          />
        )}
      </SidePaneRightContent>
    </>
  );
};

export default RoleSettingsPage;
