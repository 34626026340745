import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../../components/modal';
import TabBar from '../../../../components/TabBar';
import { useApi } from '../../../../hooks/UseApi';

import MailConfigApi from '../../../../api/MailConfigApi';
import { Button } from '../../../../designSystem/DesignSystem';
import UsersTabInModal from '../../common/UsersTabInModal';
import ReceiverPermissionsTabInModal from '../../common/ReceiverPermissionsTabInModal';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';

const MailSettingModal = (props) => {
  const { isOpen, selectedItem, closeModal, projectId } = props;
  // TODO: all results gereklimi kontrol edilecek.
  // eslint-disable-next-line no-unused-vars
  const [allResults, setAllResults] = useState([]);
  const { t } = useTranslation();

  const { reset } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const [selectedResults, setSelectedResults] = useState(selectedItem?.users ? selectedItem?.users : []);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPermissionResults, setSelectedPermissionResults] = useState(
    selectedItem?.permissions ? selectedItem?.permissions : []
  );
  const [selectedPermission, setSelectedPermission] = useState(null);

  const addUserApi = useApi({
    name: 'addUser',
    api: MailConfigApi.addUser,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.add);
      setSelectedResults((prev) => (prev ? [...selectedResults, selectedUser] : [selectedUser]));
    },
    onError: (err) => showErrorMes(err)
  });

  const removeUserApi = useApi({
    name: 'removeUser',
    api: MailConfigApi.removeUser,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      setSelectedResults(selectedResults ? selectedResults.filter((item) => item?.id !== selectedUser?.id) : []);
    },
    onError: (err) => {
      showErrorMes(err);
      setAllResults([]);
    }
  });

  const addPermissionApi = useApi({
    name: 'assignRecipientPermission',
    api: MailConfigApi.assignRecipientPermission,
    onSuccess: () => {
      setSelectedPermissionResults((prev) =>
        prev ? [...selectedPermissionResults, selectedPermission] : [selectedPermission]
      );
    },
    onError: (err) => showErrorMes(err)
  });

  const removePermissionApi = useApi({
    name: 'assignRecipientPermission',
    api: MailConfigApi.assignRecipientPermission,
    onSuccess: () => {
      setSelectedPermissionResults(
        selectedPermissionResults
          ? selectedPermissionResults.filter((item) => item.itemId !== selectedPermission?.itemId)
          : []
      );
    },
    onError: (err) => {
      setAllResults([]);
      showErrorMes(err);
    }
  });

  const mailSelectedItemDetails = useMemo(() => {
    const mail = JSON.parse(JSON.stringify(selectedItem));
    mail?.templateUsers?.forEach((element) => (element.templateBy = mail?.templateBy));
    return mail;
  }, [selectedItem]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>{t('Mail Server Setting')}</Modal.Title>
        <Modal.Content h={'md'}>
          <div className="min-h-[400px] flex-grow">
            <TabBar
              defaultIndex={0}
              options={[
                {
                  name: t('Users'),
                  disabled: false,
                  render: () => (
                    <UsersTabInModal
                      selectedUserGroup={mailSelectedItemDetails}
                      setSelectedRow={setSelectedUser}
                      selectedResults={selectedResults}
                      addApi={addUserApi}
                      removeApi={removeUserApi}
                      projectId={projectId}
                    />
                  )
                },
                {
                  name: t('Receiver Permissions'),
                  disabled: false,
                  render: () => (
                    <ReceiverPermissionsTabInModal
                      projectId={projectId}
                      selectedItem={selectedItem}
                      setSelectedRow={setSelectedPermission}
                      selectedResults={selectedPermissionResults}
                      setSelectedResults={setSelectedPermissionResults}
                      addApi={addPermissionApi}
                      removeApi={removePermissionApi}
                    />
                  )
                }
              ]}
            />
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="mailSettingModal-close-button"
            primary
            text={t('Close')}
            onClick={() => {
              closeModal();
              reset();
            }}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};
export default MailSettingModal;
