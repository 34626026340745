import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { setupInterceptors } from './api/ApiClient';
import Navbar from './components/navbar/topMenu/Navbar';
import AppContextProvider from './context/AppContext';
import LoginPage from './pages/authentication/LoginPage';
import DefectPage from './pages/defect/DefectPage';
import ProfilePage from './pages/profile/ProfilePage';
import ProjectTemplatePage from './pages/project/ProjectTemplatePage';
import ProjectDashboardPage from './pages/project/ProjectDashboardPage';
import ProjectListPage from './pages/project/ProjectListPage';
import ReleasePage from './pages/release/ReleasePage';
import ReportsPage from './pages/reports/ReportsPage';
import RequirementPage from './pages/requirement/RequirementPage';
import SettingsPage from './pages/settings/SettingsPage';
import SystemSettingsPage from './pages/systemSettings/SystemSettingsPage';
import TasksPage from './pages/tasks/TasksPage';
import TestCasePage from './pages/testCase/TestCasePage';
import TestPlanningPage from './pages/testPlanning/TestPlanningPage';
import FullPageTheme from './theme/FullPageTheme';
import SearchPortal from './components/search/SearchPortal';
import SubscriptionPage from './pages/subscription/SubscriptionPage';
import IntegrationProjectSelect from './pages/integration/IntegrationProjectSelect';
import DocumentationPage from './pages/documentation/DocumentationPage';
import FarmRedirection from './pages/integration/FarmRedirection';
import moment from 'moment';

//TODO: Refactor : use hooks
let token = localStorage.getItem('token');

token = JSON.parse(token) && JSON.parse(token).token;
setupInterceptors(token);
moment().locale('tr');

function App() {
  return (
    <>
      {!token ? (
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <Navigate
                  to="/login"
                  state={{ from: window.location.href }}
                />
              }
            />
            <Route
              exact
              path="/login"
              element={<LoginPage />}
            />
            <Route
              path="/*"
              element={
                <Navigate
                  to="/login"
                  state={{ from: window.location.href }}
                />
              }
            />
            <Route
              exact
              path="/login"
              element={<LoginPage />}
            />
          </Routes>
        </Router>
      ) : (
        <AppContextProvider>
          <Router>
            <div className="flex h-screen flex-col overflow-hidden">
              <Navbar />
              <SearchPortal />
              <div className="flex flex-grow overflow-y-hidden">
                <Routes>
                  <Route path="/">
                    {/* PROJECT MODULE */}
                    <Route
                      path="projects"
                      element={<ProjectListPage />}
                    />
                    <Route
                      path="project-templates"
                      element={<ProjectTemplatePage />}
                    />
                    <Route
                      path="dashboard"
                      element={<ProjectDashboardPage />}
                    />
                    {/* RELEASE MODULE */}
                    <Route
                      path="releases"
                      element={<ReleasePage />}
                    />
                    <Route
                      path="releases"
                      element={<ReleasePage />}
                    />
                    {/* REQUIREMENT MODULE */}
                    <Route
                      path="requirements"
                      element={<RequirementPage />}
                    />
                    <Route
                      path="requirements"
                      element={<RequirementPage />}
                    />
                    {/* TEST CASE MODULE */}
                    <Route
                      path="test-cases"
                      element={<TestCasePage />}
                    />
                    <Route
                      path="test-cases"
                      element={<TestCasePage />}
                    />
                    {/* TEST PLAN MODULE */}
                    <Route
                      path="planning"
                      element={<TestPlanningPage />}
                    />
                    <Route
                      path="layout-full-page"
                      element={<FullPageTheme />}
                    />
                    <Route
                      path="settings"
                      element={<SettingsPage />}
                    />
                    <Route
                      path="settings/*"
                      element={<SettingsPage />}
                    />
                    <Route
                      path="settings/*/*"
                      element={<SettingsPage />}
                    />
                    <Route
                      path="system-settings"
                      element={<SystemSettingsPage />}
                    />
                    <Route
                      path="system-settings/*"
                      element={<SystemSettingsPage />}
                    />
                    <Route
                      path="profile-page"
                      element={<ProfilePage />}
                    />
                    <Route
                      path="subscriptions"
                      element={<SubscriptionPage />}
                    />
                    <Route
                      path="documentation"
                      element={<DocumentationPage />}
                    />
                    <Route
                      path="defects"
                      element={<DefectPage />}
                    />
                    <Route
                      path="reports"
                      element={<ReportsPage />}
                    />
                    <Route
                      path="reports/*"
                      element={<ReportsPage />}
                    />
                    <Route
                      path="reports/*/*"
                      element={<ReportsPage />}
                    />
                    <Route
                      path="tasks"
                      element={<TasksPage />}
                    />
                    <Route
                      path="/3rd/defect"
                      element={<IntegrationProjectSelect />}
                    />
                    <Route
                      path="/callback/:callBackUrl"
                      element={<FarmRedirection />}
                    />
                    <Route
                      path=""
                      element={<Navigate to="projects" />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to="projects" />}
                    />
                  </Route>
                </Routes>
              </div>
            </div>
          </Router>
        </AppContextProvider>
      )}
      <ToastContainer
        position="top-right"
        hideProgressBar
        autoClose={2500}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
      />
    </>
  );
}

export default App;
