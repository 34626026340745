import React from 'react';
import { useForm } from "react-hook-form";
import { H5, P, Toggle } from "../../designSystem/DesignSystem"

const ToggleCard = ({ name, labelText, title, checked, submitCallBack, disabled }) => {

    const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: "all" });

    return (
        <>
            <div className=" flex flex-row items-center justify-between bg-white border border-gray-100 p-6 rounded-lg shadow-lg">
                <div className="flex flex-col">
                    <H5 text={title} className={"font-semibold mb-2"} />
                    <P text={labelText} />
                </div>
                <Toggle
                    name={name}
                    control={control}
                    value={checked}
                    passValue={(value) => submitCallBack(value)}
                    disabled={disabled}
                />
            </div>

        </>
    )


}
export default ToggleCard;