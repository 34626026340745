import {
  getCycleName,
  getDefectName,
  getTestCaseName,
  getRunName,
  getTestFolderName,
  getSuiteName,
  getContainerName,
  getFolderName,
  getReleaseName,
  getRequirementName,
  getExecutionName
} from '../../../helpers/ArtifactNameHelper';

export const PrivilegeGroups = ({ projectConfig }) => {
  const privileges = [
    { group: 'project_management', title: 'Project' },
    { group: 'release', title: getReleaseName(projectConfig) },
    { group: 'folder', title: getFolderName(projectConfig) },
    { group: 'requirement', title: getRequirementName(projectConfig) },
    { group: 'defect', title: getDefectName(projectConfig) },
    { group: 'test_container', title: getContainerName(projectConfig) },
    { group: 'test_case', title: getTestCaseName(projectConfig) },
    { group: 'test_folder', title: getTestFolderName(projectConfig) },
    { group: 'test_cycle', title: getCycleName(projectConfig) },
    { group: 'test_suite', title: getSuiteName(projectConfig) },
    { group: 'test_run', title: getRunName(projectConfig) },
    { group: 'test_execution', title: getExecutionName(projectConfig) },
    { group: 'user', title: 'User' },
    { group: 'role', title: 'Role' },
    { group: 'user_group', title: 'User Group' },
    { group: 'dashboard', title: 'Dashbord' },
    { group: 'report', title: 'Reports' }
  ];

  return privileges;
};
