import Tippy from '@tippyjs/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/themes/light.css';

const PercentageBar = ({ datas, sort, showLabel }) => {
  const { t } = useTranslation();
  let dataList = datas && datas.length > 0 ? [...datas] : [];

  if (sort) {
    dataList = datas && datas.length > 0 ? [...datas.sort((a, b) => b.value - a.value)] : [];
  }

  const hashCode = (str) => {
    if (!str) {
      return 0;
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };

  const intToRGB = (i) => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  };

  const filteredDataList = useMemo(() => dataList.filter((item) => item.value > 0), [dataList]);

  const getVal = (item) => {
    if (showLabel === true) {
      return t(item?.name) + ' : ' + item?.value;
    }
    return item?.value;
  };

  return (
    <>
      {filteredDataList && filteredDataList.length ? (
        <div className="inline-flex h-5  w-full  flex-grow	items-center overflow-hidden rounded-md bg-gray-50">
          {filteredDataList.map((item, index) => (
            <div
              className="flex flex-grow"
              key={index}
            >
              <Tippy
                theme="light"
                tooltip="top"
                disabled={false}
                animation="scale-extreme"
                placement="top"
                arrow
                content={t(item?.name) + ' : ' + item?.value}
              >
                <div
                  className="-mx-0 inline-block h-5 place-items-center overflow-hidden pl-3  pr-3 text-center text-sm leading-5 text-white"
                  style={{ background: item.color || '#' + intToRGB(hashCode(item.name)), flexGrow: item.value }}
                >
                  {getVal(item)}
                </div>
              </Tippy>
            </div>
          ))}
        </div>
      ) : (
        <div className="h-5 w-full overflow-hidden  rounded-md bg-gray-50  text-center"></div>
      )}
    </>
  );
};

export default PercentageBar;
