import { ENTITY_TYPES } from '../../constants';
import { getArtifactName } from '../../helpers/ArtifactNameHelper';
import i18next from 'i18next';

export const formatHistoryTableTitle = (text) => {
  if (text.includes('By')) {
    return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('By')) + ' ' + 'By';
  } else if (text.includes('Date')) {
    return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Date')) + ' ' + 'Date';
  } else if (text.includes('Time')) {
    return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Time')) + ' ' + 'Time';
  } else if (text.includes('Id')) {
    return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Id')) + ' ' + 'Id';
  } else if (text.includes('Num')) {
    return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Num')) + ' ' + 'Num';
  } else if (text.includes('Result')) {
    return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Result')) + ' ' + 'Result';
  } else if (text.includes('Type')) {
    return text.charAt(0).toUpperCase() + text.slice(1, text.indexOf('Type')) + ' ' + 'Type';
  } else return text.charAt(0).toUpperCase() + text.slice(1);
};
//text={t('assign_entity', { entity: getRequirementName(projectConfig) })}

export const getHistoryEventAction = (historyEvent, projectConfigs) => {
  switch (historyEvent) {
    case 'CREATE_REQUIREMENT':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.REQUIREMENT) });
    case 'UPDATE_REQUIREMENT':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.REQUIREMENT) });
    case 'CREATE_FOLDER':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.FOLDER) });
    case 'UPDATE_FOLDER':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.FOLDER) });
    case 'UPDATE_RELEASE':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.RELEASE) });
    case 'CREATE_RELEASE':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.RELEASE) });
    case 'UPDATE_TEST_CASE':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CASE) });
    case 'CREATE_TEST_CASE':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CASE) });
    case 'UPDATE_TEST_CASE_STEP':
      return i18next.t('entity_updated', {
        entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CASE) + i18next.t(' step')
      });
    case 'CREATE_TEST_CASE_STEP':
      return i18next.t('entity_created', {
        entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CASE) + i18next.t(' step')
      });
    case 'UPDATE_CONTAINER':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CONTAINER) });
    case 'CREATE_CONTAINER':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CONTAINER) });
    case 'UPDATE_TEST_CYCLE':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CYCLE) });
    case 'CREATE_TEST_CYCLE':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CYCLE) });
    case 'CREATE_TEST_SUITE':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_SUITE) });
    case 'UPDATE_TEST_SUITE':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_SUITE) });
    case 'CREATE_TEST_RUN':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_RUN) });
    case 'UPDATE_TEST_RUN':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_RUN) });
    case 'CREATE_TEST_EXECUTION':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_EXECUTION) });
    case 'UPDATE_TEST_EXECUTION':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_EXECUTION) });
    case 'CREATE_DEFECT':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.DEFECT) });
    case 'UPDATE_DEFECT':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.DEFECT) });
    case 'CREATE_TEST_FOLDER':
      return i18next.t('entity_created', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_FOLDER) });
    case 'UPDATE_TEST_FOLDER':
      return i18next.t('entity_updated', { entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_FOLDER) });
    case 'CREATE_FILE':
      return i18next.t('File attached');
    case 'DELETE_FILE':
      return i18next.t('File deleted');
    case 'UPDATE_TEST_CASE_AUTOMATION_STEP':
      return i18next.t('entity_updated', {
        entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CASE) + i18next.t(' automation step')
      });
    case 'CREATE_TEST_CASE_AUTOMATION_STEP':
      return i18next.t('entity_created', {
        entity: getArtifactName(projectConfigs, ENTITY_TYPES.TEST_CASE) + i18next.t(' automation step')
      });
    default:
      return '';
  }
};
