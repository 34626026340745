import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
// Design System Imports
import { H5, Heading, Input, Toggle, Button } from '../../../designSystem/DesignSystem';
//Helper Imports
import { CUSTOM_FIELD_TYPES } from '../../../constants';
import { customJiraActive } from '../../../helpers/CustomFieldHelper';
import { useTranslation } from 'react-i18next';

const CustomFieldOptionList = (props) => {
  const { customFieldEnumId, relatedEntity, jiraConfig, jiraStatus, optionList, setOptionList, isViewMode } = props;
  const { control, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { t } = useTranslation();

  const addOption = () => {
    const newList = optionList.concat({
      value: '',
      jiraId: '',
      selected: false,
      visible: true,
      id: uuidv4()
    });
    setOptionList(newList);
  };

  const updateDefault = (data, index) => {
    let newList = [...optionList];
    if (customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN || customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX) {
      newList[index] = {
        ...optionList[index],
        selected: data
      };
    } else if (customFieldEnumId === CUSTOM_FIELD_TYPES.LIST) {
      newList.forEach((item) => {
        item.selected = false;
      });
      setOptionList(newList);
      newList[index] = {
        ...optionList[index],
        selected: data
      };
    }
    setOptionList(newList);
  };

  const updateValue = (data, index, name) => {
    let newList = [...optionList];
    newList[index] = {
      ...optionList[index],
      [name]: data
    };
    setOptionList(newList);
  };

  const updateVisible = (data, index) => {
    let newList = [...optionList];
    newList[index] = {
      ...optionList[index],
      visible: data
    };
    setOptionList(newList);
  };

  const onDeleteItem = (item) => {
    let newList = [...optionList];
    newList = newList.filter((field) => field.id !== item.id);
    setOptionList(newList);
  };

  useEffect(() => {
    optionList.map((item, index) => {
      setValue(optionList[index].value, optionList[index].value);
      setValue(optionList[index].jiraId, optionList[index].jiraId);
    });
  }, [optionList]);

  return (
    <>
      {!isViewMode && (
        <div className="flex items-center justify-between pt-2">
          <Heading.Group
            text={t('If you want to add options, you must click the add option button.')}
            type="small"
            color="text-gray-800"
          ></Heading.Group>
          <Button
            id="customFieldOptionList-add-button"
            secondary
            text={t('Option')}
            type="button"
            size="small"
            icon="Plus"
            onClick={() => addOption()}
          />
        </div>
      )}
      <table>
        <thead className="border-b-2 border-gray-300 ">
          {customFieldEnumId === CUSTOM_FIELD_TYPES.DROP_DOWN && (
            <tr className="flex flex-grow">
              <div className="w-1/6">
                <th>
                  <H5 text={t('Selected')} />
                </th>{' '}
              </div>
              <th className="flex-grow text-left">
                <H5 text={t('Option')} />
              </th>
              <th className="w-1/6 text-left">
                <H5 text={t('Show/Hide')} />
              </th>
              {customJiraActive(relatedEntity, jiraConfig) === true && (
                <th className="w-1/4">
                  {jiraStatus ? <H5 text={t('Status Transition Id')} /> : <H5 text={t('Jira Option Id')} />}
                </th>
              )}
              <th className="w-1/12"></th>
            </tr>
          )}
          {customFieldEnumId === CUSTOM_FIELD_TYPES.CHECKBOX && (
            <tr className="flex flex-grow">
              <div className="w-1/6">
                <th>
                  <H5 text={t('Default Value')} />
                </th>{' '}
              </div>
              <th className="flex-grow text-left">
                <H5 text={t('Display Name')} />
              </th>
              <th className="w-1/6 text-left">
                <H5 text={t('Show/Hide')} />
              </th>
              {customJiraActive(relatedEntity, jiraConfig) === true && (
                <th className="w-1/4">
                  {jiraStatus ? <H5 text={t('Status Transition Id')} /> : <H5 text={t('Jira Option Id')} />}
                </th>
              )}
              <th className="w-1/12"></th>
            </tr>
          )}
          {customFieldEnumId === CUSTOM_FIELD_TYPES.LIST && (
            <tr className="flex flex-grow">
              <div className="w-1/6">
                <th>
                  <H5 text={t('Default Value')} />
                </th>{' '}
              </div>
              <th className="flex-grow text-left">
                <H5 text={t('Option')} />
              </th>
              <th className="w-1/4">
                <H5 text={t('Show/Hide')} />
              </th>
              {customJiraActive(relatedEntity, jiraConfig) === true && (
                <th className="w-1/4">
                  {jiraStatus ? <H5 text={t('Status Transition Id')} /> : <H5 text={t('Jira Option Id')} />}
                </th>
              )}
              <th className="w-1/12"></th>
            </tr>
          )}
        </thead>
        <tbody>
          {optionList.map((item, index) => {
            return (
              <tr
                key={index}
                className="flex flex-grow"
              >
                <td className="w-1/6 place-self-center  p-1 ">
                  <Toggle
                    disabled={isViewMode}
                    control={control}
                    value={optionList[index].selected}
                    name={'selected'}
                    passValue={(value) => updateDefault(value, index)}
                  />
                </td>
                <td className="flex-grow">
                  <Input
                    disabled={isViewMode}
                    name={optionList[index].value}
                    control={control}
                    type={'text'}
                    passValue={(value) => updateValue(value, index, 'value')}
                  />
                </td>
                <td className="w-1/6 place-self-center p-3 ">
                  <Toggle
                    disabled={isViewMode}
                    control={control}
                    value={optionList[index].visible}
                    name={'selected'}
                    passValue={(value) => updateVisible(value, index)}
                  />
                </td>
                {customJiraActive(relatedEntity, jiraConfig) === true && (
                  <td className="w-1/4 p-1 ">
                    <Input
                      disabled={isViewMode}
                      name={optionList[index].jiraId}
                      control={control}
                      type={'text'}
                      passValue={(value) => updateValue(value, index, 'jiraId')}
                    />
                  </td>
                )}
                <td className="w-1/12 self-center p-2">
                  <Button
                    id="customFieldOptionList-delete-button"
                    tertiary
                    type="button"
                    size="small"
                    icon="Trash"
                    weight={'bold'}
                    disabled={isViewMode}
                    onClick={() => onDeleteItem(item)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default CustomFieldOptionList;
