import { useTranslation } from 'react-i18next';
import UseQueryProject from '../../hooks/UseQueryProject';
import UseSystemField from '../../hooks/UseSystemField';
import { colorStyle } from '../../designSystem/Pill/Pill.Utils';
import { capitalizeTheFirstLetterOfEachWord, classNames, isValidValue } from '../../helpers/Helper';

const StatusPill = ({ value, entityType }) => {
  const { t } = useTranslation();

  const { projectId } = UseQueryProject('projectId');
  const statusEnum = UseSystemField(projectId, entityType, 'status');

  let status = isValidValue(value) ? value.toLowerCase() : '-';

  return (
    <span
      className={classNames(
        'leading-wide rounded-full bg-gray-100 px-3 py-1 text-xs font-bold uppercase shadow-sm',
        status.startsWith('ready for') ? 'bg-green-100 text-green-800' : null,
        status.startsWith('in progress') ? 'bg-yellow-100 text-yellow-800' : null,
        status.startsWith('baseline') ? 'bg-red-100 text-red-800' : null,
        status.startsWith('unknown') ? 'bg-gray-200 text-gray-800' : null,
        status.startsWith('new') ? 'bg-blue-100  text-blue-800' : null,
        // Test Case
        status.startsWith('passed') ? 'bg-green-100 text-green-800' : null,
        status.startsWith('failed') ? 'bg-red-100 text-red-800' : null,
        status.startsWith('not_complete') ? 'bg-yellow-100 text-yellow-800' : null,
        status.startsWith('na') ? 'bg-purple-100 text-purple-800' : null,
        // Test Case Approve Type
        status.startsWith('draft') ? 'bg-gray-200' : null,
        status.startsWith('approved') ? 'bg-blue-100 text-blue-800' : null,
        status.startsWith('pending for approval') ? 'bg-yellow-100 text-yellow-800' : null,
        status.startsWith('rejected') ? 'bg-red-100 text-red-800' : null,
        // Test Cycle
        status.startsWith('waiting') ? 'bg-blue-100 text-blue-800' : null,
        status.startsWith('completed') ? 'bg-green-100 text-green-800' : null,
        // Run Execution
        status.startsWith('blocked') ? 'bg-red-100 text-red-800' : null,
        // Project Detail
        status.startsWith('active') ? 'bg-green-100 text-green-800' : null,
        status.startsWith('retired') ? 'bg-blue-100 text-blue-800' : null,
        status.startsWith('future') ? 'bg-yellow-100 text-yellow-800' : null
      )}
      style={colorStyle(statusEnum, status)}
    >
      {t(capitalizeTheFirstLetterOfEachWord(status))}
    </span>
  );
};

export { StatusPill };
