import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
// Api & Hook Imports
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseSystemField from '../../../hooks/UseSystemField';
import UseCustomField from '../../../hooks/UseCustomField';
import { useApi } from '../../../hooks/UseApi';
import TestCycleApi from '../../../api/TestCycleApi';
import { useTranslation } from 'react-i18next';

// Design System Imports
import { Input, Dropdown, Button, Datepicker } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
// Component Imports
import EntityCustomFieldForm from '../../../components/customField/EntityCustomFieldForm';
import { isValidValue, editModalMapping, isValidList, checkRequired } from '../../../helpers/Helper';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { getCustomFields, createEntityFields } from '../../../helpers/CustomFieldHelper';
import { ENTITY_TYPES, PRIVILEGES } from '../../../constants';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { defaultValue, getStatusOptionsByData } from '../../../helpers/SystemFieldHelper';
import useSystemFieldParams from '../../../hooks/UseSystemFieldParams';
import { titleTranslation, translateArrItemText } from '../../../helpers/TranslationHelper';
import { checkDisabledStatus, hasPrivilege } from '../../../helpers/PriviligeHelper';
import Markdown from '../../../components/markdown/Markdown';

const TestCycleModal = ({ isOpen, data, closeModal, testFolderId, parentId }) => {
  const { control, handleSubmit, setValue, reset, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const status = UseSystemField(projectId, ENTITY_TYPES.TEST_CYCLE, 'status');
  const entityCustomFields = UseCustomField(projectId, ENTITY_TYPES.TEST_CYCLE);

  const { t } = useTranslation();
  const [dataForm, setDataForm] = useState({});
  const [cFields, setCFields] = useState([]);
  const [customFieldForm, setCustomFieldForm] = useState({});
  const [statusEnums, setStatusEnums] = useStateWithCallbackLazy([]);

  const sysFieldParams = useSystemFieldParams(projectId, ENTITY_TYPES.TEST_CYCLE);

  // <-- API SETUP -->
  const createEditTestCycle = useApi({
    name: 'saving test cycle',
    api: isValidValue(data) ? TestCycleApi.edit : TestCycleApi.create,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal(createEditTestCycle?.data);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const editStatusTestCycle = useApi({
    name: 'edit test cycle status',
    api: TestCycleApi.editStatus,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.save);
      closeModal(editStatusTestCycle?.data);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });
  // <-- ## -->
  // <-- FN -->
  const onSubmit = (form) => {
    if (
      hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE_STATUS) &&
      isValidValue(data) &&
      !hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE)
    ) {
      const statusBody = {
        statusId: isValidValue(dataForm?.statusId) ? dataForm?.statusId : null,
        id: data?.id
      };
      editStatusTestCycle.execute(statusBody);
    } else {
      const cFieldForm = getCustomFields(cFields, form);
      const body = {
        ...(isValidValue(data) && { id: data.id }),
        ...(isValidValue(testFolderId) && { testFolderId: testFolderId }),
        ...(isValidValue(parentId) && { parentId: parentId }),
        name: form.name,
        description: isValidValue(form.description) ? form.description : '',
        projectId: projectId,
        startDate: form.startDate,
        endDate: form.endDate,
        status: isValidValue(form?.status?.value) ? form?.status?.value : form?.status?.id,
        customFields: createEntityFields(form, cFieldForm)
      };
      createEditTestCycle.execute(body);
    }
  };
  // <-- ## -->
  useEffect(() => {
    setValue('status', defaultValue(statusEnums));
    setDataForm((prev) => ({ ...prev, statusId: defaultValue(statusEnums)?.value }));
    if (isValidValue(data)) {
      const form = { ...data, statusId: data?.status?.id };
      let mappedArray = editModalMapping(form);
      mappedArray.forEach(({ name, value }) => setValue(name, value));
      setDataForm(form);
    }
  }, []);

  useEffect(() => {
    let nodes = getStatusOptionsByData(data, status);
    setStatusEnums(translateArrItemText(nodes, t));
  }, [status]);

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal}
        w={'md'}
      >
        <Modal.Title>
          {titleTranslation(getArtifactName(projectConfig, ENTITY_TYPES.TEST_CYCLE), data, '', t)}
        </Modal.Title>
        <form
          className="flex flex-grow flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <Input
                required
                name="name"
                label={t('Name')}
                placeholder={t('name_entity', { entity: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CYCLE) })}
                control={control}
                minLength="3"
                maxLength="255"
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(
                        data?.lockStatus?.update,
                        hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE)
                      )
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_CYCLE)
                }
              />

              <div className="flex flex-grow gap-4 ">
                <Datepicker
                  clearable={hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE)}
                  control={control}
                  endDateName="endDate"
                  startDateName="startDate"
                  startDateLabel={t('Start Date')}
                  endDateLabel={t('End Date')}
                  startDateForm={data?.startDate}
                  endDateForm={data?.endDate}
                  disabled={
                    isValidValue(data)
                      ? checkDisabledStatus(
                          data?.lockStatus?.update,
                          hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE)
                        )
                      : !hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_CYCLE)
                  }
                />
              </div>
              <Dropdown
                control={control}
                clearable
                required={checkRequired('status', sysFieldParams)}
                label={t('Status')}
                name="status"
                placeholder={t('Select Status')}
                options={statusEnums}
                selectedItem={isValidValue(getValues('status')) ? getValues('status') : defaultValue(statusEnums)}
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(
                        data?.lockStatus?.update,
                        hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE_STATUS)
                      )
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_CYCLE)
                }
                passValue={(value) => {
                  setValue('status', isValidList(value) ? value[0] : null);
                  if (!isValidList(value)) setValue('status', null);
                  setDataForm((prev) => ({ ...prev, statusId: isValidList(value) ? value[0]?.id : null }));
                }}
              />
              <EntityCustomFieldForm
                customFieldForm={customFieldForm}
                setCustomFieldForm={setCustomFieldForm}
                control={control}
                setValue={setValue}
                entityFields={data?.customFields}
                entityCustomFields={entityCustomFields}
                dataForm={dataForm}
                customControlForJiraModal={false}
                setCFields={setCFields}
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(
                        data?.lockStatus?.update,
                        hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE)
                      )
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_CYCLE)
                }
              />
              <Markdown
                control={control}
                name="description"
                label={t('Description')}
                disabled={
                  isValidValue(data)
                    ? checkDisabledStatus(
                        data?.lockStatus?.update,
                        hasPrivilege(projectId, PRIVILEGES.UPDATE_TEST_CYCLE)
                      )
                    : !hasPrivilege(projectId, PRIVILEGES.CREATE_TEST_CYCLE)
                }
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="testCycleModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
                reset();
              }}
            />
            <Button
              id="testCycleModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={createEditTestCycle.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default TestCycleModal;
