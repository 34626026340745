import { useEffect } from 'react';
// Context Imports
import { useApp, useAppDispatch } from '../context/AppContext';
import { addRequest, removeRequest, saveEntitySystemField } from '../context/ProjectActions';
// Api Imports
import SystemFieldApi from '../api/SystemFieldApi';
import { showErrorMes } from '../helpers/NotificationHelper';
import { useMemo } from 'react';
import { uniqueId } from 'lodash-es';
import { useApi } from './UseApi';

const UseSystemField = (projectId, entity, relatedField) => {
  const app = useApp();
  const systemFields = app?.systemFields || [];
  const activeRequests = app?.activeRequests || [];

  const dispatch = useAppDispatch();
  const systemField = systemFields[entity];

  const id = useMemo(() => uniqueId(), []);

  const filterPredicate = (item) =>
    item.projectId !== projectId &&
    item.type !== 'systemField' &&
    item.entity !== entity &&
    item.relatedField !== relatedField;

  const getSystemFieldList = useApi({
    name: 'Get System Field',
    api: SystemFieldApi.getSystemFields,
    autoLoad: false,
    onSuccess: () => dispatch(saveEntitySystemField(projectId, entity, relatedField, getSystemFieldList?.data)),
    onError: (err) => showErrorMes(err),
    onFinally: () => dispatch(removeRequest(filterPredicate))
  });

  useEffect(() => {
    if (entity && projectId && relatedField && !(systemField && systemField[relatedField]?.projectId === projectId)) {
      dispatch(addRequest({ order: id, type: 'systemField', entity, projectId, relatedField }));
    }
  }, [entity, projectId, relatedField]);

  useEffect(() => {
    if (activeRequests?.length) {
      const allRequestsForEntity = activeRequests
        .filter(
          (item) =>
            item.projectId === projectId &&
            item.type === 'systemField' &&
            item.entity === entity &&
            item.relatedField === relatedField
        )
        .map((item) => item.order);
      if (
        (allRequestsForEntity || []).indexOf(id) === 0 &&
        !(systemField && systemField[relatedField]?.projectId === projectId)
      ) {
        getSystemFieldList.execute({ projectId, relatedEntity: entity, relatedField: relatedField });
      }
    }
  }, [activeRequests]);

  return systemField ? systemField[relatedField]?.values : [];
};

export default UseSystemField;
