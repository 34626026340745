import { ENTITY_TYPES, TREENODE_ROOT } from '../../../constants';
import { PARENTS } from '../utils/TreeConsts';

export class TreeActions {
  projectConfig = {};
  module = null;
  actionDefinitions = {};
  checkable = false;

  constructor(projectConfig, checkable, module, actionDefinitions = {}) {
    this.module = module;
    this.projectConfig = projectConfig;
    this.actionDefinitions = actionDefinitions;
    this.checkable = checkable;
  }

  getTreeData() {
    return [];
  }

  createNodeData(data = [], entityType, parent) {
    const { id, name, code, codeNum, type, loading } = data;
    return {
      id,
      name,
      code,
      codeNum,
      loading,
      type: type ?? entityType,
      parent: typeof parent === 'string' ? parent : getParentId(data)
    };
  }

  createNodes(data = [], type, parent) {
    return data.map((item) => this.createNodeData(item, type, parent));
  }

  async getParents(type, id) {
    let parents = [];
    if (id && type && this.actionDefinitions[type]) {
      try {
        const response = await this.actionDefinitions[type].parents(id);
        parents = [...parents, ...response];
      } catch (err) {
        // ignore
      }
    }
    return parents;
  }

  async getChildren(id, projectId, type, filters) {
    if (id === TREENODE_ROOT.id) {
      return this.getTreeData(projectId, filters);
    }

    const actions = this.actionDefinitions[type];
    if (!id || !type || !actions || !actions.children) {
      return [];
    }

    const children = await Promise.all(
      actions.children.map(async (action) => {
        try {
          const params = action.getParams({ projectId, parentId: id, version: filters?.versionFilter });
          const response = await action.api(params);
          return this.createNodes(response, action.type, id);
        } catch (err) {
          return [];
        }
      })
    );

    return children.flat();
  }

  move(dragNode, dropNode) {
    const actions = this.actionDefinitions[dragNode?.data?.type];
    if (!dragNode?.id || !actions?.updateParent) {
      return null;
    }

    const body = actions.updateParent.getBody(dragNode?.data, dropNode?.data);
    const api = actions.updateParent.getApi(dropNode.data);
    if (api && body) {
      return api(dragNode.id, body);
    }
  }

  isFolder(type) {
    return PARENTS.includes(type);
  }

  selectAllChildren = (node) => {
    node.selectMulti();
    if (node.children?.length) {
      node.children.forEach((child) => {
        this.selectAllChildren(child);
      });
    }
  };

  deselectAllChildren = (node) => {
    node.deselect();
    if (node.children?.length) {
      node.children.forEach((child) => {
        this.deselectAllChildren(child);
      });
    }
  };

  deselectAllParents = (node) => {
    node.deselect();
    if (node.parent) {
      this.deselectAllParents(node.parent);
    }
  };

  getPage = () => {
    return this.actionDefinitions[this.module]?.page;
  };
}

export const getParentId = (node, type) => {
  if (!node) return null;
  let nodeType = type || node.type;

  const parent = typeof node.parent === 'string' ? node.parent : node.parent?.id;
  const parentId = node.parentId || parent || TREENODE_ROOT.id;

  if (nodeType === ENTITY_TYPES.TEST_SUITE) {
    return node.testCycle?.id || parentId;
  }

  if (nodeType === ENTITY_TYPES.TEST_CYCLE && parentId === TREENODE_ROOT.id) {
    return node.testFolder?.id || TREENODE_ROOT.id;
  } else if (nodeType === ENTITY_TYPES.TEST_CYCLE && parentId !== TREENODE_ROOT.id) {
    return parentId;
  }

  return parentId;
};
