import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import { Body, Dropdown, Input, Toggle } from '../../designSystem/DesignSystem';
import { ENTITY_TYPES, TCASE_VERSION_FILTER_TYPES } from '../../constants';
import { getTestCaseName } from '../../helpers/ArtifactNameHelper';
import { isValidValue } from '../../helpers/Helper';
import TreeArborist from '../tree/TreeArborist';

const TestCaseSearchTree = ({ setSelectedTestCases, setSelectedContainers, type }) => {
  const { t } = useTranslation();
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const [allApproved, setAllApproved] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(0);

  const searchTypesDropdown = [
    {
      key: 'code',
      value: 'code',
      text: `${getTestCaseName(projectConfig)} Code`,
      name: `${t('entityCode', { entity: getTestCaseName(projectConfig) })}`
    },
    {
      key: 'name',
      value: 'name',
      text: `${getTestCaseName(projectConfig)} Name`,
      name: `${t('entityName', { entity: getTestCaseName(projectConfig) })}`
    }
  ];

  const version = () => {
    if (type === ENTITY_TYPES.REQUIREMENT) {
      return TCASE_VERSION_FILTER_TYPES.LAST_VERSION;
    } else if (type === ENTITY_TYPES.TEST_SUITE || type === ENTITY_TYPES.TEST_CASE) {
      if (allApproved) {
        return TCASE_VERSION_FILTER_TYPES.ALL_APPROVED;
      } else {
        return TCASE_VERSION_FILTER_TYPES.LAST_APPROVED;
      }
    }
  };

  const [filters, setFilters] = useState({ versionFilter: version() });

  const [searchOption, setSearchOption] = useState(searchTypesDropdown[1]);

  const onCheckNode = (nodes) => {
    const checkedTestCases = nodes
      .filter((item) => item?.data?.type === ENTITY_TYPES.TEST_CASE)
      .map((item) => item?.data?.testCaseId);

    const checkedContainers = nodes
      .filter((item) => item?.data?.type === ENTITY_TYPES.TEST_CONTAINER)
      .map((item) => item?.id);
    setSelectedTestCases(checkedTestCases);
    setSelectedContainers(checkedContainers);
  };

  const onChangeSearchKeyword = (value) => {
    if (isValidValue(value) && isValidValue(searchOption)) {
      let code = null;
      let name = null;
      let versionFilter = version();
      if (searchOption?.key === 'code') {
        code = value;
        setFilters({ projectId, code, versionFilter });
      } else if (searchOption?.key === 'name') {
        name = value;
        setFilters({ projectId, name, versionFilter });
      }
    } else {
      setFilters({ versionFilter: version() });
    }
  };

  const handleAllApproved = (value) => {
    setAllApproved(value);
  };

  useEffect(() => {
    setFilters({ ...filters, versionFilter: version() });
  }, [allApproved]);

  useEffect(() => {
    setRerenderKey((prevKey) => prevKey + 1);
  }, [filters]);

  return (
    <form className="flex flex-grow flex-col gap-4">
      <div className="flex items-center">
        <div className="">
          <Dropdown
            control={control}
            name={'search'}
            options={searchTypesDropdown}
            selectedItem={searchOption}
            passValue={(value) => {
              setSearchOption(value[0]);
            }}
          />
        </div>
        <Input
          type={searchOption.key === 'code' ? 'number' : 'text'}
          name="name"
          placeholder={t(`Search`)}
          control={control}
          passValue={(value) => onChangeSearchKeyword(value)}
        />
      </div>
      <div className="flex items-center px-1">
        <Body
          text={
            type === ENTITY_TYPES.REQUIREMENT
              ? t('lastversion_message', { entity: getTestCaseName(projectConfig) })
              : allApproved
              ? t('approved_message_all_versions', { entity: getTestCaseName(projectConfig) })
              : t('approved_message_last_versions', { entity: getTestCaseName(projectConfig) })
          }
          type="medium"
          bold
        />
        {(type === ENTITY_TYPES.TEST_SUITE || type === ENTITY_TYPES.TEST_CASE) && (
          <div className="ml-auto flex items-center">
            <Toggle
              name={'name'}
              labelText={t('all_approved')}
              labelRight
              control={control}
              value={allApproved}
              passValue={handleAllApproved}
            />
          </div>
        )}
      </div>
      <div className="h-[22vh] max-h-min">
        <TreeArborist
          key={rerenderKey}
          module={ENTITY_TYPES.TEST_CASE}
          draggable={false}
          projectId={projectId}
          projectConfig={projectConfig}
          filters={filters}
          checkable
          onCheck={onCheckNode}
          eventsEnabled={false}
          approvedType={allApproved}
        />
      </div>
    </form>
  );
};

export default TestCaseSearchTree;
