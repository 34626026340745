import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useApi } from '../../../hooks/UseApi';
import CustomReportApi from '../../../api/CustomReportApi';
import { Modal } from '../../modal';
import { Input, TextArea, Button, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
import { REPORT_MAIL_SUBJECT } from '../../../constants';

const ReportMailModal = ({ isOpen, closeModal, entityId, entityType, users }) => {
  const { control, handleSubmit } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const sendReport = useApi({
    name: 'sendReport',
    api: CustomReportApi.sendReport,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes('Report was sent successfully.');
      closeModal();
    },
    onError: () => {
      showErrorMes('Mail could not be send.');
      setLoading(false);
    }
  });

  const onSubmit = (form) => {
    setLoading(true);

    const emails = [];
    form?.emails?.forEach((item) => {
      emails.push(item?.email);
    });

    const body = {
      entityId,
      entityType,
      emails: emails,
      subject: form?.subject,
      message: form?.message
    };

    showSuccessMes(t('Report is preparing to send.'));
    sendReport.execute(body);
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={() => closeModal()}
        w={'md'}
      >
        <Modal.Title>{t('Send Report Email')}</Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'md'}>
            <div className="flex flex-grow flex-col gap-4">
              <div className="flex gap-4">
                <MultiSelectDropdownPill
                  label={t('Users')}
                  control={control}
                  name={'emails'}
                  options={users}
                />
              </div>
              <Input
                name="subject"
                label={t('Subject')}
                control={control}
                maxLength="255"
                placeholder={t(REPORT_MAIL_SUBJECT)}
                disabled
              />
              <TextArea
                placeholder={t('Enter your text here')}
                control={control}
                name="message"
                label={t('Message')}
              />
            </div>
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="reportMailModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              id="reportMailModal-submit-button"
              primary
              text={t('Send Report')}
              type="submit"
              loading={loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>
    </>
  );
};

export default ReportMailModal;
