export const filterName = (arr, name, type) => {
  const filteredArr = arr.filter((item) => item.artifact === name);
  if (type === 'name') return filteredArr[0]?.name;
  if (type === 'prefix') return filteredArr[0]?.prefix;
};

export const mapValue = (data, filterName) => {
  return {
    releaseArtifactName: filterName(data, 'Release', 'name'),
    releasePrefix: filterName(data, 'Release', 'prefix'),
    folderArtifactName: filterName(data, 'Folder', 'name'),
    folderPrefix: filterName(data, 'Folder', 'prefix'),
    requirementArtifactName: filterName(data, 'Requirement', 'name'),
    requirementPrefix: filterName(data, 'Requirement', 'prefix'),
    testCaseArtifactName: filterName(data, 'Test Case', 'name'),
    testCasePrefix: filterName(data, 'Test Case', 'prefix'),
    testFolderArtifactName: filterName(data, 'Test Folder', 'name'),
    testFolderPrefix: filterName(data, 'Test Folder', 'prefix'),
    testContainerArtifactName: filterName(data, 'Test Container', 'name'),
    testContainerPrefix: filterName(data, 'Test Container', 'prefix'),
    testCycleArtifactName: filterName(data, 'Test Cycle', 'name'),
    testCyclePrefix: filterName(data, 'Test Cycle', 'prefix'),
    testSuiteArtifactName: filterName(data, 'Test Suite', 'name'),
    testSuitePrefix: filterName(data, 'Test Suite', 'prefix'),
    testRunArtifactName: filterName(data, 'Test Run', 'name'),
    testRunPrefix: filterName(data, 'Test Run', 'prefix'),
    testRunExecutionArtifactName: filterName(data, 'Test Run Execution', 'name'),
    testRunExecutionPrefix: filterName(data, 'Test Run Execution', 'prefix'),
    defectArtifactName: filterName(data, 'Defect', 'name'),
    defectPrefix: filterName(data, 'Defect', 'prefix')
  };
};

export const setMappedData = (confParam) => {
  if (confParam) {
    const {
      releaseArtifactName,
      releasePrefix,
      folderArtifactName,
      folderPrefix,
      requirementArtifactName,
      requirementPrefix,
      testCaseArtifactName,
      testCasePrefix,
      testFolderArtifactName,
      testFolderPrefix,
      testContainerArtifactName,
      testContainerPrefix,
      testCycleArtifactName,
      testCyclePrefix,
      testSuiteArtifactName,
      testSuitePrefix,
      testRunArtifactName,
      testRunPrefix,
      testRunExecutionArtifactName,
      testRunExecutionPrefix,
      defectArtifactName,
      defectPrefix
    } = confParam;

    return [
      { artifact: 'Release', name: releaseArtifactName, prefix: releasePrefix },
      { artifact: 'Folder', name: folderArtifactName, prefix: folderPrefix },
      { artifact: 'Requirement', name: requirementArtifactName, prefix: requirementPrefix },
      { artifact: 'Test Case', name: testCaseArtifactName, prefix: testCasePrefix },
      { artifact: 'Test Folder', name: testFolderArtifactName, prefix: testFolderPrefix },
      { artifact: 'Test Container', name: testContainerArtifactName, prefix: testContainerPrefix },
      { artifact: 'Test Cycle', name: testCycleArtifactName, prefix: testCyclePrefix },
      { artifact: 'Test Suite', name: testSuiteArtifactName, prefix: testSuitePrefix },
      { artifact: 'Test Run', name: testRunArtifactName, prefix: testRunPrefix },
      { artifact: 'Test Run Execution', name: testRunExecutionArtifactName, prefix: testRunExecutionPrefix },
      { artifact: 'Defect', name: defectArtifactName, prefix: defectPrefix }
    ];
  }
};
