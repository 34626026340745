import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useAppDispatch } from '../../../context/AppContext';
import { saveProjectConfig } from '../../../context/ProjectActions';
// API & HOOK IMPORTS
import ProjectConfigApi from '../../../api/ProjectConfigApi';
import { useApi } from '../../../hooks/UseApi';
import { PROJECT_ACTION_KEYS } from '../../../context/Actions';
import UseQueryProject from '../../../hooks/UseQueryProject';
import { useTranslation } from 'react-i18next';
// COMPONENT IMPORTS
import TableInlineEdit from '../../../components/table/TableInlineEdit';
import { SidePaneRightContent } from '../../../components/Layout';
// DESIGN IMPORTS
import { Heading, Button } from '../../../designSystem/DesignSystem';
// HELPER IMPORTS
import { isValidValue } from '../../../helpers/Helper';
import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import { setMappedData } from './ArtifactNames.Helper';

const ArtifactNames = () => {
  const { projectId, project } = UseQueryProject('projectId');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [projectConfig, setConfig] = useState({});
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState();

  let errArr = [];

  const canSubmit = (obj, t) => {
    if (errArr.length > 0) {
      errArr.forEach((err) => showErrorMes(t('Artifact names or prefixes can not be empty : ') + t(err)));
      return false;
    }
    return true;
  };

  const ref = useRef();
  let tableHeight = ref?.current?.clientHeight;

  const projectConfigLoader = useApi({
    name: 'Get project configs',
    api: ProjectConfigApi.get,
    onSuccess: () => {
      setConfig(projectConfigLoader?.data);
      dispatch(saveProjectConfig(projectId, projectConfigLoader?.data));
      saveDataState(projectConfigLoader?.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const updateProjectConfig = useApi({
    name: 'Updating project config',
    api: ProjectConfigApi.update,
    onSuccess: () => {
      setConfig(updateProjectConfig?.data);
      dispatch(saveProjectConfig(projectId, updateProjectConfig?.data));
      saveDataState(updateProjectConfig?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => {
      console.error(err);
      let mess = err?.response?.data?.validationErrors;
      if (mess) {
        let messages = '';
        Object.keys(mess).forEach(function (item) {
          if (isValidValue(mess[item])) {
            messages += ' ' + mess[item];
          }
        });
        showErrorMes(messages);
      }
    }
  });

  const saveDataState = (confParam) => {
    const mappedData = setMappedData(confParam);
    setData(mappedData);
    setOriginalData(mappedData);
  };

  const resetData = () => {
    setData(originalData);
    showSuccessMes(INFO_MESSAGES.removedChanges);
  };

  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const filterName = (arr, name, type) => {
    const filteredArr = arr.filter((item) => item.artifact === name);
    if (type === 'name') {
      if (!isValidValue(filteredArr[0]?.name)) {
        errArr.push(filteredArr[0]?.artifact + ' Name');
      } else {
        return filteredArr[0]?.name;
      }
    }
    if (type === 'prefix') {
      if (!isValidValue(filteredArr[0]?.prefix)) {
        errArr.push(filteredArr[0]?.artifact + ' Prefix');
      } else {
        return filteredArr[0]?.prefix;
      }
    }
  };

  const submitData = () => {
    const mapValue = {
      releaseArtifactName: filterName(data, 'Release', 'name'),
      releasePrefix: filterName(data, 'Release', 'prefix'),
      folderArtifactName: filterName(data, 'Folder', 'name'),
      folderPrefix: filterName(data, 'Folder', 'prefix'),
      requirementArtifactName: filterName(data, 'Requirement', 'name'),
      requirementPrefix: filterName(data, 'Requirement', 'prefix'),
      testCaseArtifactName: filterName(data, 'Test Case', 'name'),
      testCasePrefix: filterName(data, 'Test Case', 'prefix'),
      testFolderArtifactName: filterName(data, 'Test Folder', 'name'),
      testFolderPrefix: filterName(data, 'Test Folder', 'prefix'),
      testContainerArtifactName: filterName(data, 'Test Container', 'name'),
      testContainerPrefix: filterName(data, 'Test Container', 'prefix'),
      testCycleArtifactName: filterName(data, 'Test Cycle', 'name'),
      testCyclePrefix: filterName(data, 'Test Cycle', 'prefix'),
      testSuiteArtifactName: filterName(data, 'Test Suite', 'name'),
      testSuitePrefix: filterName(data, 'Test Suite', 'prefix'),
      testRunArtifactName: filterName(data, 'Test Run', 'name'),
      testRunPrefix: filterName(data, 'Test Run', 'prefix'),
      testRunExecutionArtifactName: filterName(data, 'Test Run Execution', 'name'),
      testRunExecutionPrefix: filterName(data, 'Test Run Execution', 'prefix'),
      defectArtifactName: filterName(data, 'Defect', 'name'),
      defectPrefix: filterName(data, 'Defect', 'prefix')
    };

    if (canSubmit(mapValue, t)) {
      const body = { id: projectId, key: 'artifact', ...projectConfig, ...mapValue };
      updateProjectConfig.execute(body);

      let tempConfig = { ...projectConfig, ...mapValue };

      dispatch({
        type: PROJECT_ACTION_KEYS.UPDATED_PROJECT_CONFIG,
        config: tempConfig
      });
    } else {
      setData(originalData);
    }
  };

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(
    () => [
      { Header: t('Artifact'), accessor: 'artifact' },
      { Header: t('Name'), accessor: 'name' },
      { Header: t('Prefix'), accessor: 'prefix' }
    ],
    [data, t]
  );

  useEffect(() => {
    if (projectId && isProjectManager(projectId)) {
      projectConfigLoader.execute({ id: projectId });
    }
  }, [projectId]);

  return (
    <SidePaneRightContent>
      <SidePaneRightContent.Top>
        {isProjectManager(projectId) && (
          <Heading.Group
            type="large"
            text={t('Artifact Names')}
          >
            <div className="flex gap-4">
              <Button
                id="artifactName-submit-button"
                primary
                text={t('Submit')}
                size="small"
                onClick={submitData}
                disabled={isValidValue(project?.templateBy)}
                loading={updateProjectConfig?.loading}
              />
              <Button
                id="artifactName-refresh-button"
                secondary
                tooltip={t('Refresh')}
                size="small"
                icon="ArrowCounterClockwise"
                weight="fill"
                onClick={resetData}
                disabled={isValidValue(project?.templateBy)}
              />
            </div>
          </Heading.Group>
        )}
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Center
        ref={ref}
        colFull={true}
      >
        <div className="mt-4">
          <TableInlineEdit
            disabled={isValidValue(project?.templateBy)}
            columns={tableColumns}
            data={tableData}
            updateMyData={updateMyData}
            tableHeight={tableHeight}
          />
        </div>
      </SidePaneRightContent.Center>
    </SidePaneRightContent>
  );
};

export default ArtifactNames;
