import { axios } from './ApiClient';

const TR_PATH = '/api/test-runs';
class TestRunApi {
  async getOne(id) {
    const resp = await axios.get(`${TR_PATH}/${id}`);
    return resp.data;
  }

  async getAll(params) {
    const resp = await axios.get(`${TR_PATH}`, { params });
    return resp.data;
  }

  async getAllByTester(params) {
    const resp = await axios.get(`${TR_PATH}/byTester`, { params });
    return resp.data;
  }

  async getTestRunStatusCount(params) {
    const resp = await axios.get(`${TR_PATH}/status-count`, { params });
    return resp.data;
  }

  async getTestRunStatuses() {
    const resp = await axios.get(`${TR_PATH}/statuses`);
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${TR_PATH}`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${TR_PATH}/${data?.id}`, data);
    return resp.data;
  }

  async delete(id) {
    const resp = await axios.delete(`${TR_PATH}/${id}`);
    return resp.data;
  }

  async clone(data) {
    const resp = await axios.post(`${TR_PATH}/clone`, data);
    return resp.data;
  }

  async getSteps(id) {
    const resp = await axios.get(`${TR_PATH}/${id}/steps`);
    return resp.data;
  }

  async bulkDelete(data) {
    const resp = await axios.put(`${TR_PATH}/bulk-run-delete`, data?.testRunIds);
    return resp.data;
  }
}

export default new TestRunApi();
