import React, { useEffect, useState } from 'react';
import TestRunExecutionApi from '../../../api/TestRunExecutionApi';
import EntityDetails from '../../../components/entityDetails/EntityDetails';
import SelectedTableFilters from '../../../components/filtering/SelectedTableFilters';
import { Modal } from '../../../components/modal';
import AdvancedTabBar from '../../../components/tab/AdvancedTabBar';
import { Body, Button } from '../../../designSystem/DesignSystem';
import Upload from '../../../components/upload/Upload';
import { ENTITY_TYPES } from '../../../constants';
import { getDefectName, getExecutionName } from '../../../helpers/ArtifactNameHelper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import DefectReactTable from '../../defect/DefectReactTable';
import DefectModal from '../../defect/Modal/DefectModal';
import TestRunExecutionTab from '../tabs/TestRunExecutionTab';
import { useTranslation } from 'react-i18next';
const TestRunExecutionModal = ({ projectId, isOpen, close, runId, executionId, users, preconditions }) => {
  const projectConfig = UseProjectConfig();
  const [execution, setExecution] = useState({});
  const [allFilters, setAllFilters] = useState({});
  const [defectModal, setDefectModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const { t } = useTranslation();

  const executionCreate = useApi({
    name: 'create test run execution',
    api: TestRunExecutionApi.create,
    onSuccess: () => {
      setExecution(executionCreate.data);
    },
    onError: (err) => showErrorMes(err)
  });

  const executionLoader = useApi({
    name: 'fetch test run execution',
    api: TestRunExecutionApi.get,
    onSuccess: () => {
      setExecution(executionLoader.data);
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (isOpen) {
      if (executionId) {
        executionLoader.execute(executionId);
      } else {
        executionCreate.execute({ testRunId: runId });
      }
    }
  }, [isOpen, executionId]);

  return (
    <Modal
      open={isOpen}
      close={() => close(execution)}
      w={'xl'}
    >
      <Modal.Content h={'xl'}>
        <EntityDetails
          type={ENTITY_TYPES.TEST_EXECUTION}
          loading={false}
          data={execution}
          closeModal={() => close(execution)}
          entityType={ENTITY_TYPES.TEST_EXECUTION}
        />
        <AdvancedTabBar
          defaultIndex={0}
          tabs={[
            {
              label: t('Steps'),
              disabled: false,
              render: () => (
                <TestRunExecutionTab
                  execution={execution}
                  setExecution={setExecution}
                  runId={runId}
                  preconditions={preconditions}
                  projectConfig={projectConfig}
                  projectId={projectId}
                />
              )
            },
            {
              label: getDefectName(projectConfig),
              disabled: false,
              topRight: () => (
                <div className="mb-1">
                  <Button
                    id="testRunExecutionModal-add-button"
                    secondary
                    text={getDefectName(projectConfig)}
                    type="button"
                    size={'xsmall'}
                    icon="Plus"
                    onClick={() => setDefectModal(true)}
                  />
                </div>
              ),
              bottomLeft: () => (
                <div className="flex flex-grow justify-start gap-4">
                  <div className="flex flex-grow gap-2">
                    <SelectedTableFilters
                      setAllFilters={setAllFilters}
                      entityType={ENTITY_TYPES.DEFECT}
                      setRefreshTable={setRefreshTable}
                    />
                  </div>
                </div>
              ),
              render: () => (
                <DefectReactTable
                  users={users}
                  refreshStatus={refreshTable}
                  setRefreshStatus={setRefreshTable}
                  filter={{ ...allFilters, projectId, testExecutionId: execution?.id }}
                />
              )
            },
            {
              label: t('Attachments'),
              disabled: false,
              render: () => (
                <div className="flex flex-grow gap-8">
                  <div className="mt-2 flex-grow">
                    <Body
                      type="medium"
                      bold
                      text={t('TestRunExecutionModalAttachment', { entity: getExecutionName(projectConfig) })}
                    />
                    <Upload
                      entityType={ENTITY_TYPES.TEST_EXECUTION}
                      entityId={execution?.id}
                      users={users}
                    />
                  </div>
                </div>
              )
            }
          ]}
        />
        {defectModal && (
          <DefectModal
            testCycleId={execution?.testCycle?.id}
            runId={execution?.testRun?.id}
            selectedExecution={execution}
            isOpen={defectModal}
            closeModal={() => setDefectModal(false)}
            submitCallBack={() => {
              setRefreshTable(true);
            }}
            projectConfig={projectConfig}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default TestRunExecutionModal;
