import { getParentId, TreeActions } from './TreeActions';
import TestFolderApi from '../../../api/TestFolderApi';
import * as constants from '../../../constants';
import { ENTITY_TYPES, TREENODE_ROOT } from '../../../constants';
import TestCycleApi from '../../../api/TestCycleApi';
import TestSuiteApi from '../../../api/TestSuiteApi';
import { isEmpty } from 'lodash-es';

const testSuiteModuleActionDefinitions = {
  [constants.ENTITY_TYPES.TEST_FOLDER]: {
    parents: TestFolderApi.getParens,
    page: 'planning',
    updateParent: {
      getApi: (dropNode) => {
        if (
          dropNode.type === ENTITY_TYPES.RELEASE ||
          dropNode.type === ENTITY_TYPES.TEST_SUITE ||
          dropNode.type === ENTITY_TYPES.TEST_CYCLE
        ) {
          return;
        }

        return TestFolderApi.updateTestFolderParent;
      },
      getBody: (dragNode, dropNode) => {
        let parentId = dropNode.id;
        if (dropNode.type === constants.ENTITY_TYPES.TEST_CYCLE) {
          parentId = getParentId(dropNode);
        } else if (dropNode.type === TREENODE_ROOT.type) {
          parentId = null;
        }
        return { parentId };
      }
    },
    children: [
      {
        type: constants.ENTITY_TYPES.TEST_FOLDER,
        api: TestFolderApi.getTreeData,
        getParams: ({ projectId, parentId }) => ({
          root: false,
          projectId,
          parentId
        })
      },
      {
        type: constants.ENTITY_TYPES.TEST_CYCLE,
        api: TestCycleApi.getTreeData,
        getParams: ({ projectId, parentId }) => ({
          testFolderId: parentId,
          projectId
        })
      }
    ]
  },
  [constants.ENTITY_TYPES.TEST_CYCLE]: {
    parents: TestCycleApi.getParens,
    page: 'planning',
    updateParent: {
      getApi: (dropNode) => {
        switch (dropNode.type) {
          case TREENODE_ROOT.type:
            return TestCycleApi.updateTestCycleWithEmptyParent;
          case ENTITY_TYPES.TEST_SUITE:
            return;
          case ENTITY_TYPES.TEST_FOLDER:
            return TestCycleApi.updateTestCycleFolder;
          case ENTITY_TYPES.RELEASE:
            return TestCycleApi.updateTestCycleRelease;
          default:
            return TestCycleApi.updateTestCycleParent;
        }
      },
      getBody: (dragNode, dropNode) => {
        let parentId = dropNode.id;
        if (dropNode.type === constants.ENTITY_TYPES.TEST_FOLDER) {
          return { testFolderId: parentId };
        } else if (dropNode.type === TREENODE_ROOT.type) {
          parentId = null;
        }
        return { parentId };
      }
    },
    children: [
      {
        type: constants.ENTITY_TYPES.TEST_CYCLE,
        api: TestCycleApi.getTreeData,
        getParams: ({ projectId, parentId }) => ({
          projectId,
          parentId
        })
      },
      {
        type: constants.ENTITY_TYPES.TEST_SUITE,
        api: TestSuiteApi.getTreeData,
        getParams: ({ projectId, parentId }) => ({
          projectId,
          parentIsNull: true,
          testCycleId: parentId
        })
      }
    ]
  },
  [constants.ENTITY_TYPES.TEST_SUITE]: {
    parents: TestSuiteApi.getParens,
    page: 'planning',
    updateParent: {
      getApi: (dropNode) => {
        switch (dropNode.type) {
          case ENTITY_TYPES.RELEASE:
          case TREENODE_ROOT.type:
          case ENTITY_TYPES.TEST_FOLDER:
            return;
          case ENTITY_TYPES.TEST_CYCLE:
            return TestSuiteApi.updateTestSuiteCycle;
          default:
            return TestSuiteApi.updateTestSuiteParent;
        }
      },
      getBody: (dragNode, dropNode) => {
        let parentId = dropNode.id;
        if (dropNode.type === constants.ENTITY_TYPES.TEST_CYCLE) {
          return { testCycleId: parentId };
        }
        return { parentId };
      }
    },
    children: [
      {
        type: constants.ENTITY_TYPES.TEST_SUITE,
        api: TestSuiteApi.getTreeData,
        getParams: ({ projectId, parentId }) => ({
          projectId,
          parentIsNull: true,
          parentId
        })
      }
    ]
  }
};

export class TestSuiteTreeActions extends TreeActions {
  constructor(projectConfig, checkable) {
    super(projectConfig, checkable, ENTITY_TYPES.TEST_SUITE, testSuiteModuleActionDefinitions);
  }

  // eslint-disable-next-line no-unused-vars
  async getTreeData(projectId, filters) {
    // eslint-disable-next-line no-useless-catch
    try {
      if (!isEmpty(filters)) return this.getFilteredTreeData(projectId, filters);

      const folderData = await TestFolderApi.getTreeData({
        parentIsNull: true,
        projectId
      });
      const cycleData = await TestCycleApi.getTreeData({
        parentIsNull: true,
        releaseIsNull: true,
        testFolderIsNull: true,
        projectId
      });

      const treeData = [
        ...this.createNodes(folderData, ENTITY_TYPES.TEST_FOLDER, TREENODE_ROOT.id),
        ...this.createNodes(cycleData, ENTITY_TYPES.TEST_CYCLE, TREENODE_ROOT.id)
      ];

      return treeData;
    } catch (err) {
      throw err;
    }
  }

  async getFilteredTreeData(projectId, filters) {
    const treeData = await TestSuiteApi.getAll({ projectId, [filters?.type]: filters?.searchParameter });
    return this.createNodes(treeData, ENTITY_TYPES.TEST_SUITE, null);
  }

  createNodeData(data = [], entityType, parent) {
    const node = super.createNodeData(data, entityType, parent);

    return {
      ...node,
      ...(entityType === ENTITY_TYPES.TEST_CYCLE && {
        testFolderId: data.testFolder?.id
      }),
      ...(entityType === ENTITY_TYPES.TEST_SUITE && {
        testCycleId: data.testCycle?.id
      })
    };
  }
}
