import { STATUS_HISTORY_MENU_NAME, STATUS_HISTORY_MENU_NAME_TR } from '../constants';
import i18next from 'i18next';

export const titleTranslation = (entity, data, isTemplate, t) => {
  let title;
  if (data?.id) {
    title = t('edit_entity', { isTemplate: isTemplate ? t('template') : null, ek: isTemplate ? 'unu' : null, entity });
  } else {
    title = t('create_entity', { isTemplate: isTemplate ? t('template') : null, ek: isTemplate ? 'u' : null, entity });
  }
  return title;
};

export const translateArrItemName = (arr, t) =>
  arr?.map((item) => ({ ...item, name: t(item.name), defaultName: item.name }));
export const translateArrItemText = (arr, t) => arr?.map((item) => ({ ...item, text: t(item.text) }));

export const translateStatusHistory = () => {
  const lang = i18next.language;
  if (lang == 'tr') {
    return STATUS_HISTORY_MENU_NAME_TR;
  }
  if (lang == 'en') {
    return STATUS_HISTORY_MENU_NAME;
  }
};
