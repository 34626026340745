// states.js
import { useState } from 'react';

export const useBulkDropDownStates = () => {
  // Assign and Unassign States
  const [openDefectBulkAssignModal, setDefectBulkAssignModal] = useState(false);
  const [openUnassignRequirementFromTestCaseModal, setOpenUnassignRequirementFromTestCaseModal] = useState(false);
  const [bulkUnassignTestCasesFromRequirementModalOpen, setBulkUnassignTestCasesFromRequirementModalOpen] =
    useState(false);
  const [bulkAssignTestCasesFromRequirementsModalOpen, setBulkAssignTestCasesFromRequirementsModalOpen] =
    useState(false);

  // Approve and Send Approve
  const [openTestCaseBulkApproveModal, setOpenTestCaseBulkApproveModal] = useState(false);
  const [openTestCaseSendApproveModal, setOpenTestCaseSendApproveModal] = useState(false);

  // Delete States
  const [openTestCaseDeleteConfirmModal, setOpenTestCaseDeleteConfirmModal] = useState(false);
  const [openDefectDeleteConfirmModal, setOpenDefectDeleteConfirmModal] = useState(false);
  const [openBulkRunDeleteModal, setOpenBulkRunDeleteModal] = useState(false);

  // Others States
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [openBulkExecutionModal, setOpenBulkExecutionModal] = useState(false);
  const [openBulkCreateTestRunModal, setOpenBulkCreateTestRunModal] = useState(false);
  const [syncConfModal, setSyncConfModal] = useState(false);

  return {
    openDefectBulkAssignModal,
    setDefectBulkAssignModal,
    openUnassignRequirementFromTestCaseModal,
    setOpenUnassignRequirementFromTestCaseModal,
    bulkUnassignTestCasesFromRequirementModalOpen,
    setBulkUnassignTestCasesFromRequirementModalOpen,
    bulkAssignTestCasesFromRequirementsModalOpen,
    setBulkAssignTestCasesFromRequirementsModalOpen,
    openTestCaseBulkApproveModal,
    setOpenTestCaseBulkApproveModal,
    openTestCaseSendApproveModal,
    setOpenTestCaseSendApproveModal,
    openTestCaseDeleteConfirmModal,
    setOpenTestCaseDeleteConfirmModal,
    openDefectDeleteConfirmModal,
    setOpenDefectDeleteConfirmModal,
    openBulkRunDeleteModal,
    setOpenBulkRunDeleteModal,
    openCloneModal,
    setOpenCloneModal,
    openBulkExecutionModal,
    setOpenBulkExecutionModal,
    openBulkCreateTestRunModal,
    setOpenBulkCreateTestRunModal,
    syncConfModal,
    setSyncConfModal
  };
};
