import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
//Desing System Imports
import { Dropdown, TextArea, Input, Datepicker, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
//Helper Imports
import { CUSTOM_FIELD_TYPES } from '../../../constants';
import { isValidCustomFieldValue } from '../../../helpers/CustomFieldHelper';
import { isValidList } from '../../../helpers/Helper';
import { useTranslation } from 'react-i18next';

const CustomFieldDefaultValue = (props) => {
  const { customFieldForm, configValue, setConfigValue, userList, isViewMode } = props;
  const { t } = useTranslation();

  const { control, setValue } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [values, setValues] = useState([]);

  let items = [];

  useEffect(() => {
    switch (customFieldForm.customFieldEnumId) {
      case CUSTOM_FIELD_TYPES.MULTI_LIST_USERS:
        userList.forEach((option) => {
          if (
            isValidList(customFieldForm.config?.objects?.value) &&
            isValidList(customFieldForm.config?.objects?.value.filter((item) => item === option.id))
          ) {
            items.push(option);
          }
        });
        setValues(items);
        break;
      case CUSTOM_FIELD_TYPES.MEMO:
        setValue('value', customFieldForm?.config?.value);
        break;
      case CUSTOM_FIELD_TYPES.NUMBER:
        setValue('value', customFieldForm?.config?.value);
        break;
      case CUSTOM_FIELD_TYPES.TEXT:
        setValue('value', customFieldForm?.config?.value);
        break;
      default:
    }
  }, [customFieldForm]);

  const getSelectItem = () => {
    return isValidList(userList) ? userList.filter((user) => user?.id === configValue?.value)[0] : null;
  };

  return (
    <>
      {customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.USER && (
        <Dropdown
          clearable
          disabled={isViewMode}
          control={control}
          name={'value'}
          label={t('Default Value')}
          options={userList}
          selectedItem={getSelectItem()}
          passValue={(value) =>
            setConfigValue((prev) => ({
              ...prev,
              value: value[0].id,
              visible: true,
              autoUserAssignEnabled: isValidCustomFieldValue(value) ? false : prev.autoUserAssignEnabled
            }))
          }
        />
      )}
      {customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.MULTI_LIST_USERS && (
        <MultiSelectDropdownPill
          disabled={isViewMode}
          control={control}
          name={'value'}
          label={t('Default Value')}
          options={userList}
          value={values}
          passValue={(value) => {
            let values = [];
            value.forEach((item) => values.push(item.id));
            setConfigValue({ objects: { value: values } });
          }}
        />
      )}

      {customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.MEMO && (
        <TextArea
          disabled={isViewMode}
          name={'value'}
          label={t('Default Value')}
          control={control}
          passValue={(value) =>
            setConfigValue((prev) => ({
              ...prev,
              value: value,
              visible: true,
              autoUserAssignEnabled: isValidCustomFieldValue(value) ? false : prev.autoUserAssignEnabled
            }))
          }
        />
      )}

      {(customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.NUMBER ||
        customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.TEXT) && (
        <Input
          disabled={isViewMode}
          name={'value'}
          startDateLabel={t('Default Value')}
          control={control}
          type={customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.NUMBER ? 'number' : 'text'}
          passValue={(value) =>
            setConfigValue((prev) => ({
              value: value,
              visible: true,
              autoUserAssignEnabled: isValidCustomFieldValue(value) ? false : prev.autoUserAssignEnabled
            }))
          }
        />
      )}

      {customFieldForm.customFieldEnumId === CUSTOM_FIELD_TYPES.DATE && (
        <Datepicker
          disabled={isViewMode}
          control={control}
          startDateName={'value'}
          startDateLabel={t('Default Value')}
          startDateForm={isValidCustomFieldValue(customFieldForm?.config?.value) ? customFieldForm?.config?.value : ''}
          passValue={(value) =>
            setConfigValue((prev) => ({
              ...prev,
              value: value?.startDate,
              visible: true
            }))
          }
        />
      )}
    </>
  );
};
export default CustomFieldDefaultValue;
