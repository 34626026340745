export const getItemName = (id, dashboardOptions, removeItem = () => {}, fixProblem = () => {}) => {
  if (!id) return;
  const target = dashboardOptions.find((c) => c?.dashboardId === id || c?.id === id || c?.key === id);
  let result = dashboardOptions.map((a) => a.value);
  if (!result.includes(id)) {
    removeItem(id);
    fixProblem(true);
  }
  return target?.name;
};

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'white' : '#white',
  padding: 0,
  width: '98%'
});

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '8px 18px 8px 0px',
  marginRight: 0,
  fontSize: 12,
  // change background colour if dragging
  background: isDragging ? '#e6f0f5' : 'white',
  borderBottom: `1px solid rgba(28,110,164,0.12)`,
  // styles we need to apply on draggables
  ...draggableStyle
});
