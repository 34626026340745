import React, { useRef, useState } from 'react';
// API & HOOKS
import UseQueryProject from '../../hooks/UseQueryProject';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseProjectUsers from '../../hooks/UseProjectUsers';
// DESIGN SYSTEM & COMPONENTS
import SelectedTableFilters from '../../components/filtering/SelectedTableFilters';
import ReleaseReactTable from './ReleaseReactTable';
import { SidePaneRightContent, TOP_HEADING_SIZE } from '../../components/Layout';
import { Heading } from '../../designSystem/DesignSystem';
// HELPER & CONSTANTS
import { ENTITY_TYPES } from '../../constants';
import { getArtifactName } from '../../helpers/ArtifactNameHelper';

const ReleaseDashboard = () => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const users = UseProjectUsers(projectId);

  const [allFilters, setAllFilters] = useState({});
  const [refreshTable, setRefreshTable] = useState(true);

  const centerRef = useRef(); // General center height
  const centerHeight = centerRef?.current?.clientHeight;
  const tableHeight = centerHeight - centerRef?.current?.childNodes[0]?.clientHeight - 50; // Table Height

  return (
    <>
      <SidePaneRightContent.Top>
        <Heading
          type={TOP_HEADING_SIZE}
          text={`${getArtifactName(projectConfig, ENTITY_TYPES.RELEASE)}`}
        />
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Main>
        {projectId && (
          <>
            <SidePaneRightContent.Center ref={centerRef}>
              <SelectedTableFilters
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                entityType={ENTITY_TYPES.RELEASE}
                setRefreshTable={setRefreshTable}
              />
              <ReleaseReactTable
                filter={{ projectId, ...allFilters }}
                refreshStatus={refreshTable}
                setRefreshStatus={setRefreshTable}
                users={users}
                height={tableHeight - 5}
              />
            </SidePaneRightContent.Center>
          </>
        )}
      </SidePaneRightContent.Main>
    </>
  );
};

export default ReleaseDashboard;
