import React, { useState, useEffect } from 'react';
// API and Hook Imports
import { useApi } from '../../../hooks/UseApi';
// Design System Imports
import { H6, H5, Button } from '../../../designSystem/DesignSystem';
//Helper Imports
import { isValidValue } from '../../../helpers/Helper';
import MailConfigApi from '../../../api/MailConfigApi';
import { useTranslation } from 'react-i18next';

const ReceiverPermissionsTabInModal = (props) => {
  const { selectedItem, addApi, removeApi, selectedResults, setSelectedRow, setSelectedResults, projectId } = props;
  const [allResults, setAllResults] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const { t } = useTranslation();

  const dataLoader = useApi({
    name: 'getRecipientConfigs',
    api: MailConfigApi.getRecipientConfigs,
    onSuccess: () => setAllResults(dataLoader?.data?.filter((item) => item.name !== 'Project Users')),
    onError: () => setAllResults([])
  });

  const getRecipients = useApi({
    name: 'getRecipients',
    api: MailConfigApi.getRecipients,
    onSuccess: () => {
      let temp = [];
      getRecipients.data.forEach((element) => {
        if (element?.permissions) {
          element.permissions.forEach((item) => {
            temp.push(item);
          });
        }
      });
      setRecipients(getRecipients.data);
      setSelectedResults(temp);
    },
    onError: () => setAllResults([])
  });

  const getResults = () => {
    dataLoader.execute({ projectId, relatedEntity: 'defect' });
  };
  const onClickAdd = (item) => {
    setSelectedRow(item);

    recipients.forEach((x) => {
      addApi.execute({ id: x?.id, permissions: [...selectedResults, item] });
    });
    //   getRecipients.execute({ id: selectedItem.id})
  };

  const onClickDelete = (item) => {
    setSelectedRow(item);

    recipients.forEach((x) => {
      removeApi.execute({ id: x?.id, permissions: selectedResults.filter((x) => x.itemId !== item?.itemId) });
    });
    //   getRecipients.execute({ id: selectedItem.id})
  };

  useEffect(() => {
    getRecipients.execute({ id: selectedItem.id });
    getResults();
  }, []);

  return (
    <>
      <div className="flex flex-grow flex-col gap-8">
        <div className="grid grid-cols-2 divide-x">
          <div className="px-3">
            <div className="flex py-6">
              <table className="flex-grow">
                <thead className="flex-grow border-b-2 border-gray-300 ">
                  <tr className="flex flex-grow">
                    <div className="flex-grow">
                      <th>{t('Receiver')}</th>
                    </div>
                    <th className="w-1/4">{t('Add')}</th>
                  </tr>
                </thead>
                <tbody className="flex-grow ">
                  {allResults.map((dataItem, index) => {
                    return (
                      <tr
                        key={index}
                        className="flex flex-grow"
                      >
                        <td className="w-3/4 place-content-start self-start p-1">
                          <H5
                            color={'text-black'}
                            text={t(dataItem.name)}
                          />
                          <H6 text={dataItem.description} />
                        </td>
                        <td className="flex w-1/4 place-content-center self-center">
                          <Button
                            id="receiverPermissionsTabInModal-add-button"
                            primary
                            icon="ArrowRight"
                            size="small"
                            disabled={
                              selectedResults
                                ? selectedResults.some((value) => {
                                    return value.itemId === dataItem.itemId;
                                  })
                                : false
                            }
                            onClick={() => onClickAdd(dataItem)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="px-3">
            <div className="flex">
              <H5
                text={t('Selected Receiver Permissions')}
                className={'mb-1 font-semibold'}
              />
            </div>
            <div className="flex">
              <table className="flex-grow">
                <thead className="flex-grow border-b-2 border-gray-300 ">
                  <tr className="flex flex-grow">
                    <th className="w-1/4">{t('Delete')}</th>
                    <th>{t('Receiver')}</th>
                  </tr>
                </thead>
                <tbody className="flex-grow ">
                  {selectedResults.map((dataItem, index) => {
                    return (
                      <tr
                        key={index}
                        className="flex flex-grow"
                      >
                        <td className="flex w-1/4 place-content-center self-center ">
                          <Button
                            id="receiverPermissionsTabInModal-delete-button"
                            primary
                            size="small"
                            icon="ArrowLeft"
                            disabled={isValidValue(dataItem?.templateBy)}
                            onClick={() => onClickDelete(dataItem)}
                          />
                        </td>
                        <td className="p-1">
                          <H5
                            color={'text-black'}
                            text={t(dataItem.name)}
                          />
                          <H6 text={dataItem.description} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiverPermissionsTabInModal;
