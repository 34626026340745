import React from 'react';
import { Input } from '../../../designSystem/DesignSystem';

const InputCustomField = ({ control, field, type, disabled }) => {
  return (
    <>
      <Input
        disabled={disabled}
        required={field?.required}
        name={field?.customFieldId}
        label={field?.fieldName}
        control={control}
        type={type}
      />
    </>
  );
};
export default InputCustomField;
