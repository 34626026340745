import { isValidList } from '../../../helpers/Helper';
import moment from 'moment';

export const getBarChartData = (buckets, chart, setKeys) => {
  const levelStatus = isValidList(chart?.configuration?.fields) && chart?.configuration?.fields.length > 1;

  let arr = [];
  let histogram = chart?.configuration?.dateHistogram === true;
  let keys = [];
  if (histogram || levelStatus) {
    let uniqueVals = [];
    let changedDataArr = [];
    buckets.forEach((x) => {
      if (x?.key && x?.doc_count) {
        let valueBuckets = x?.['1']?.['buckets'];
        if (isValidList(buckets)) {
          valueBuckets.forEach((val) => {
            if (val?.key) {
              let newVal = histogram ? moment(val?.key).format('DD MMM YYYY') : val?.key;
              if (!uniqueVals.includes(newVal)) {
                uniqueVals.push(newVal);
              }
              changedDataArr.push({
                doc_count: val?.doc_count,
                newVal: newVal,
                key: x?.key
              });
            }
          });
        } //if end
        keys.push(x?.key);
      }
    });

    if (isValidList(uniqueVals)) {
      uniqueVals.forEach((x) => {
        let inp = {
          label: x
        };
        changedDataArr.forEach((y) => {
          if (y?.newVal === x && y?.key) {
            inp[y?.key] = y?.doc_count;
          } //if end
        });
        arr.push(inp);
      });
    }
  } else {
    buckets.forEach((x) => {
      if (x?.key && x?.doc_count) {
        arr.push({
          id: x?.key,
          label: x?.key,
          value: x?.doc_count
        });
        keys.push(x?.key);
      }
    });
  }

  if (setKeys) setKeys(keys);
  return arr;
};
