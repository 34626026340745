import { CaretDown, CaretUp, DotsSixVertical } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { getLoading, NoDataMessage, TableWrapper } from '../../helpers/TableHelper';

import { Body, Heading, IndeterminateCheckbox } from '../../designSystem/DesignSystem';
import TablePagination from './TablePagination';
import { useSticky } from 'react-table-sticky';
import { classNames } from '../../helpers/Helper';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../context/AppContext';
import { setSelectedRows } from '../../context/ProjectActions';

const Table = ({
  columns,
  data,
  pagination,
  loading,
  pageParams,
  getByPage,
  showCheckbox,
  compact,
  height,
  rowHeight,
  onlyOneRowSelect = false
}) => {
  const [dotsVisible, setDotsVisible] = useState(false);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      maxWidth: 250
    }),
    []
  );
  const {
    // General
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: pageParams?.page,
        pageSize: pageParams?.size
      },
      manualPagination: true,
      manualSortBy: true,
      pageCount: pageParams?.totalPages,
      autoResetSelectedRows: false,
      getRowId: (row) => row.id,
      stateReducer: (state, action) => {
        if (onlyOneRowSelect) {
          if (action.type === 'toggleRowSelected' && Object.keys(state.selectedRowIds).length) {
            const newState = { ...state };
            newState.selectedRowIds = {
              [action.id]: true
            };
            return newState;
          }
          return state;
        }
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    usePagination,
    useRowSelect,
    useSticky,
    (hooks) => {
      if (showCheckbox !== true) {
        hooks.visibleColumns.push((columns1) => [...columns1]);
      } else {
        hooks.visibleColumns.push((columns2) => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div className="checkboxColumn-header">
                {!onlyOneRowSelect && <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />}
              </div>
            ),
            width: 40,
            minWidth: 40,
            Cell: ({ row }) => (
              <div className="checkboxColumn-td">
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            disableResizing: true
          },
          ...columns2
        ]);
      }
    }
  );

  const sortByIdObj = sortBy?.[0];
  const notSortedList = ['contribution', 'coverage', 'description', 'defectCount', 'executionCount', 'summary'];

  const isSortedField = () => notSortedList.includes(sortByIdObj?.id) === false;

  const changePage = (num) => {
    if (getByPage) getByPage({ ...pageParams, page: num - 1 });
  };

  const rowSelection = (isSelectedActive, selectedRow) => {
    if (!selectedRow) return;
    const selectedRows = isSelectedActive ? Object.keys(selectedRow).map((key) => ({ original: { id: key } })) : [];
    dispatch(setSelectedRows(selectedRows));
  };

  useEffect(() => {
    rowSelection(selectedFlatRows, selectedRowIds);
  }, [selectedFlatRows.length, selectedRowIds]);

  useEffect(() => {
    if (pagination) {
      if (
        sortByIdObj?.id &&
        isSortedField() &&
        (pageParams?.sortBy !== sortByIdObj?.id || pageParams?.sortDir !== sortByIdObj?.desc)
      ) {
        if (getByPage) {
          getByPage({
            page: pageIndex,
            size: pageSize,
            sortBy: sortByIdObj?.id,
            sortDir: sortByIdObj?.desc ? 'DESC' : 'ASC'
          });
        }
      }
    }
  }, [sortByIdObj?.id, sortByIdObj?.desc]);

  const handleHeight = () => {
    if (!height) return null;
    if (height) return height;
  };

  useEffect(() => {
    if (pagination) {
      if ((pageIndex && pageIndex !== pageParams?.page) || (pageSize && pageSize !== pageParams?.size)) {
        if (getByPage) {
          getByPage({ ...pageParams, page: pageIndex, size: pageSize });
        }
      }
      handleHeight();
    }
  }, [pageIndex, pageSize]);

  const renderHeaderSorting = (column) => {
    if (columns.disableSorting) return '';
    if (column.isSorted && isSortedField()) {
      if (column.isSortedDesc && isSortedField()) {
        return (
          <CaretDown
            weight="fill"
            color="#6B7280"
            size={16}
          />
        );
      } else {
        return (
          <CaretUp
            weight="fill"
            color="#6B7280"
            size={16}
          />
        );
      }
    }
    return '';
  };

  return (
    <div
      id="table-tablePagination"
      className="overflow-hidden "
    >
      <TableWrapper>
        <div className="overflow-x-auto">
          {loading === true ? (
            <>
              <div
                className={classNames(
                  'mt-1'
                  // !compact ? "h-80" : "h-10"
                )}
              >
                {getLoading()}
              </div>
            </>
          ) : (
            <>
              {page.length === 0 ? (
                <div
                  className={classNames(
                    'mt-1'
                    //!compact ? "h-80" : "h-10"
                  )}
                >
                  <NoDataMessage message={t('No data found')} />
                </div>
              ) : (
                <>
                  <table
                    {...getTableProps()}
                    className="w-full"
                  >
                    <thead
                      className="sticky z-10 w-fit overflow-y-hidden bg-slate-100"
                      onMouseOver={() => setDotsVisible(true)}
                      onMouseOut={() => setDotsVisible(false)}
                    >
                      {headerGroups.map((headerGroup, index) => (
                        <tr
                          key={index}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column, index) => (
                            <th
                              key={index}
                              className="group border-r px-3 text-left tracking-wide"
                              {...column.getHeaderProps(
                                !column.disableSorting && column.getSortByToggleProps({ title: undefined })
                              )}
                            >
                              <div className="flex justify-between">
                                <div className={classNames('flex gap-2', !compact ? 'my-3' : 'my-1.5')}>
                                  <Heading
                                    color="text-gray-600"
                                    uppercase
                                    type="xsmall"
                                    text={column.render('Header')}
                                  />
                                  {renderHeaderSorting(column)}
                                </div>
                                {column.canResize && (
                                  <>
                                    <div
                                      {...column.getResizerProps()}
                                      className={`resizer ${column.isResizing ? 'isResizing ' : ''}`}
                                    >
                                      {dotsVisible && (
                                        <DotsSixVertical
                                          size={32}
                                          weight="duotone"
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="bg-white"
                      {...getTableBodyProps()}
                    >
                      <div
                        className={classNames(
                          'divide-y divide-gray-200 overflow-y-auto overflow-x-hidden',
                          !compact ? 'h-96' : 'h-30'
                        )}
                        style={{ height: handleHeight() - 84 }}
                      >
                        {page.map((row, index) => {
                          prepareRow(row);
                          return (
                            <tr
                              key={index}
                              className={classNames(
                                rowHeight ? rowHeight : 'h-10',
                                'py-1 odd:bg-white even:bg-slate-50'
                              )}
                              {...row.getRowProps()}
                            >
                              {row.cells.map((cell, index) => {
                                return (
                                  <td
                                    id={cell?.column?.id}
                                    key={index}
                                    className="elipsis self-center whitespace-normal px-3 py-1"
                                    {...cell.getCellProps()}
                                  >
                                    <div className="elipsis">
                                      <Body
                                        className="elipsis"
                                        type="medium"
                                        color="text-gray-500"
                                        text={
                                          typeof cell.render('Cell')?.props?.value == 'string'
                                            ? t(cell.render('Cell')?.props?.value)
                                            : cell.render('Cell')
                                        }
                                      />
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </div>
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}
        </div>
      </TableWrapper>
      {pagination && (
        <TablePagination
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageParams={pageParams}
          pageSize={pageSize}
          gotoPage={changePage}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
};

export default Table;
