import { axios } from './ApiClient';

const SUITE_PATH = '/api/test-suites';

class TestSuiteApi {
  async getTestSuite(data) {
    const resp = await axios.get(`${SUITE_PATH}/${data.id}`);
    return resp.data;
  }

  async getAll(data) {
    const resp = await axios.get(`${SUITE_PATH}`, { params: data });
    return resp.data;
  }

  async create(data) {
    const resp = await axios.post(`${SUITE_PATH}/`, data);
    return resp.data;
  }

  async edit(data) {
    const resp = await axios.put(`${SUITE_PATH}/${data.id}`, data);
    return resp.data;
  }

  async delete(data) {
    const resp = await axios.delete(`${SUITE_PATH}/${data.id}`);
    return resp.data;
  }

  async clone(data) {
    const resp = await axios.post(`${SUITE_PATH}/clone`, data);
    return resp.data;
  }

  async getParens(id) {
    const resp = await axios.get(`${SUITE_PATH}/${id}/parents`);
    return resp.data;
  }

  async updateTestSuiteParent(id, body) {
    const resp = await axios.put(`${SUITE_PATH}/${id}/parent`, body);
    return resp.data;
  }

  async updateTestSuiteCycle(id, body) {
    const resp = await axios.put(`${SUITE_PATH}/${id}/testCycle`, body);
    return resp.data;
  }

  async getSuiteOptionList(params) {
    const resp = await axios.get(`${SUITE_PATH}/filter-options`, { params });
    return resp.data;
  }

  async getTreeData(params) {
    const resp = await axios.get(`${SUITE_PATH}/tree`, { params });
    return resp.data;
  }
}

export default new TestSuiteApi();
