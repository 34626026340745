import { t } from 'i18next';
import { ENTITY_TYPES, MENU_DEFAULT_TITLES } from '../constants';
import { isValidValue } from './Helper';

export const getReleaseName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.releaseArtifactName) ? configs?.releaseArtifactName : 'Release';
};

export const getCycleName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.testCycleArtifactName)
    ? configs?.testCycleArtifactName
    : 'Test Cycle';
};

export const getTestFolderName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.testFolderArtifactName)
    ? configs?.testFolderArtifactName
    : 'Test Folder';
};

export const getSuiteName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.testSuiteArtifactName)
    ? configs?.testSuiteArtifactName
    : 'Test Suite';
};

export const getFolderName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.folderArtifactName) ? configs?.folderArtifactName : 'Folder';
};

export const getRequirementName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.requirementArtifactName)
    ? configs?.requirementArtifactName
    : 'Requirement';
};

export const getContainerName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.testContainerArtifactName)
    ? configs?.testContainerArtifactName
    : 'Container';
};

export const getTestCaseName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.testCaseArtifactName)
    ? configs?.testCaseArtifactName
    : 'Test Case';
};

export const getExecutionName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.testRunExecutionArtifactName)
    ? configs?.testRunExecutionArtifactName
    : 'Test Execution';
};

export const getRunName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.testRunArtifactName)
    ? configs?.testRunArtifactName
    : 'Test Run';
};

export const getDefectName = (configs) => {
  return isValidValue(configs) && isValidValue(configs?.defectArtifactName) ? configs?.defectArtifactName : 'Defect';
};

export const getArtifactNameLower = (configs, name) => {
  return getArtifactName(configs, name).toLowerCase();
};

export const getArtifactName = (configs, name) => {
  let lowname = isValidValue(name) ? name.toLowerCase() : '';
  switch (lowname) {
    case 'release':
      return getReleaseName(configs);
    case 'folder':
      return getFolderName(configs);
    case 'requirement':
      return getRequirementName(configs);
    case 'testcontainer':
    case 'test_container':
      return getContainerName(configs);
    case 'testcase':
    case 'test_case':
      return getTestCaseName(configs);
    case 'testcycle':
    case 'test_cycle':
      return getCycleName(configs);
    case 'testsuite':
    case 'test_suite':
      return getSuiteName(configs);
    case 'testrun':
    case 'test_run':
      return getRunName(configs);
    case 'testfolder':
    case 'test_folder':
      return getTestFolderName(configs);
    case 'testexecution':
    case 'test_execution':
    case 'testrunexecution':
      return getExecutionName(configs);
    case 'defect':
      return getDefectName(configs);
    case 'project':
      return 'Project';
    case 'project_management':
      return 'Project Management';
    case 'role':
      return 'Role';
    case 'user_group':
      return 'User Group';
    case 'dashboard':
      return 'Dashboard';
    case 'report':
      return 'Report';
    default:
      return name;
  }
};

export const getFieldsArtifactName = (configs, entityType, fieldName, exportFieldValue) => {
  switch (fieldName) {
    case 'parent':
      if (entityType === ENTITY_TYPES.REQUIREMENT) {
        return t(getArtifactName(configs, ENTITY_TYPES.FOLDER));
      } else if (entityType === ENTITY_TYPES.TEST_CASE) {
        return t(getArtifactName(configs, ENTITY_TYPES.TEST_CONTAINER));
      }
      break;
    case 'release':
      return t(getArtifactName(configs, ENTITY_TYPES.RELEASE));
    case 'testRun':
      return t(getArtifactName(configs, ENTITY_TYPES.TEST_RUN));
    case 'requirement':
      return t(getArtifactName(configs, ENTITY_TYPES.REQUIREMENT));
    case 'defect':
      return t(getArtifactName(configs, ENTITY_TYPES.DEFECT));
    case 'testRunExecution':
    case 'execution':
      return t(getArtifactName(configs, ENTITY_TYPES.TEST_EXECUTION));
    case 'testCase':
      return t(getArtifactName(configs, ENTITY_TYPES.TEST_CASE));
    case 'testSuite':
      return t(getArtifactName(configs, ENTITY_TYPES.TEST_SUITE));
    case 'testCycle':
      return t(getArtifactName(configs, ENTITY_TYPES.TEST_CYCLE));
    default:
      return t(exportFieldValue);
  }
};

export const getProjectMainMenusByKey = (config, key) => {
  let defaultConfigs = {};

  Object.keys(MENU_DEFAULT_TITLES).forEach((key) => {
    defaultConfigs[key] = MENU_DEFAULT_TITLES.key;
  });

  if (config && config?.configuration && config?.configuration?.menu) {
    let data = {
      ...defaultConfigs,
      ...config?.configuration?.menu
    };
    return data[key];
  } else {
    return defaultConfigs[key];
  }
};
