import React, { useEffect, useState } from 'react';
// API & HOOKS IMPORTS
import { useApi } from '../../hooks/UseApi';
import DashboardApi from '../../api/DashboardApi';
import ProjectApi from '../../api/ProjectApi';

// HELPER & CONSTANTS IMPORTS
import { isValidList } from '../../helpers/Helper';
import { isProjectManager } from '../../helpers/PriviligeHelper';
import { showErrorMes } from '../../helpers/NotificationHelper';
// COMPONENT IMPORTS
import ChartsTable from './table/ChartsTable';
import Dashboards from './table/Dashboards';
import UseAllUsers from '../../hooks/UseAllUsers';

const DashboardSettingMenu = (props) => {
  const { pageKey, projectId } = props;

  const users = UseAllUsers();
  const [commonConfigs, setCommonConfigs] = useState({});
  const [projects, setProjects] = useState([]);

  // ##
  // ## <-- API SETUP -->
  // ##

  const fetchDashboardCommonConfigs = useApi({
    name: 'fetchDashboardCommonConfigs',
    api: DashboardApi.fetchDashboardCommonConfigs,
    autoLoad: false,
    onSuccess: () => setCommonConfigs(fetchDashboardCommonConfigs?.data),
    onError: (err) => showErrorMes(err)
  });

  // TODO: array.map void olmayacak.
  const fetchManagerProjectOptionList = useApi({
    name: 'fetchManagerProjectOptionList',
    api: ProjectApi.fetchManagerProjectOptionList,
    autoLoad: false,
    onSuccess: () => {
      let arr = [];
      if (isValidList(fetchManagerProjectOptionList?.data)) {
        fetchManagerProjectOptionList.data.map((x) => {
          arr.push({
            id: x.id,
            key: x.id,
            value: x.id,
            text: x.name,
            name: x.name
          });
        });
      }
      setProjects(arr);
    },
    onError: (err) => showErrorMes(err)
  });

  // ## <-- -->

  useEffect(() => {
    if (isProjectManager(projectId)) fetchManagerProjectOptionList.execute();
    fetchDashboardCommonConfigs.execute();
  }, []);

  const renderSwitcher = () => {
    switch (pageKey) {
      case 'dashboard':
        return (
          <Dashboards users={users} projectId={projectId} commonConfigs={commonConfigs} managerProjects={projects} />
        );
      case 'chart':
        return (
          <ChartsTable users={users} projectId={projectId} managerProjects={projects} commonConfigs={commonConfigs} />
        );
      default:
        return (
          <Dashboards users={users} projectId={projectId} commonConfigs={commonConfigs} managerProjects={projects} />
        );
    }
  };

  return <>{renderSwitcher()}</>;
};

export default DashboardSettingMenu;
