import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Layout Imports
import { SidePaneLeftContent, SidePaneRightContent } from '../../components/Layout';
import { Heading } from '../../designSystem/DesignSystem';
import { isCanAddUser, isSystemAdmin } from '../../helpers/PriviligeHelper';
import TokenPage from '../settings/integrationSettings/token/TokenPage';
import VersionPage from '../settings/version/VersionPage';
import LdapSettingsPage from './ldapSettings/LdapSettingsPage';
import SystemSettingsMenu from './SystemSettingsMenu';
import OtherSettingsPage from './systemUserSettings/OtherSettingsPage';
//Component Imports
import SystemUserSettingsPage from './systemUserSettings/SystemUserSettingsPage';
import { useTranslation } from 'react-i18next';
import ParametersPage from './parameterSettings/ParametersPage';
import SystemParameters from './SystemParameters/SystemParameters';
import DefaultExportFieldsEditPage from './SystemParameters/DefaultExportFieldsEditPage';
import { ENTITY_TYPES } from '../../constants';

const SystemSettingsPage = () => {
  const { t } = useTranslation();

  const exportFieldsNestedSubMenu = [
    { id: 1, title: 'Project', path: 'project' },
    { id: 2, title: 'Requirement', path: 'requirement' },
    { id: 3, title: 'Test Case', path: 'test-case' },
    { id: 4, title: 'Test Run', path: 'test-run' },
    { id: 5, title: 'Defect', path: 'defect' }
  ];

  const systemParametersSubMenu = [
    { id: 1, title: t('General'), path: 'general' },
    { id: 2, title: t('Export Fields'), path: 'export-fields', nestedSubMenu: exportFieldsNestedSubMenu }
  ];

  const menuItems = [
    { name: t('Ldap '), path: '/system-settings/ldapservers', id: 1 },
    { name: t('System User'), path: '/system-settings/user', isCanAddUser: isCanAddUser(), id: 2 },
    { name: t('Tokens'), path: '/system-settings/tokens', id: 3 },
    { name: t('Versions'), path: '/system-settings/versions', everyoneAccess: true, id: 4 },
    {
      name: t('System Parameters'),
      path: '/system-settings/system-parameters',
      subMenu: systemParametersSubMenu,
      id: 5
    },
    { name: t('Other'), path: '/system-settings/other', id: 6 }
  ];

  return (
    <>
      <SidePaneLeftContent>
        <SidePaneLeftContent.Heading>
          <div className="ml-6 flex flex-col">
            <Heading.Duo
              type="large"
              text={t('System Settings')}
            />
          </div>
        </SidePaneLeftContent.Heading>
        <SystemSettingsMenu data={menuItems} />
      </SidePaneLeftContent>
      <SidePaneRightContent>
        <Routes>
          <Route path="/">
            <Route
              path="user"
              element={<SystemUserSettingsPage />}
            />
            {isSystemAdmin() && (
              <>
                <Route
                  path="ldapservers"
                  element={<LdapSettingsPage />}
                />
                <Route
                  path="tokens"
                  element={<TokenPage />}
                />
              </>
            )}
            <Route
              path="versions"
              element={<VersionPage />}
            />
            {isSystemAdmin() && (
              <Route
                path="other"
                element={<OtherSettingsPage />}
              />
            )}
            {isSystemAdmin() && (
              <Route
                path="parameters"
                element={<ParametersPage />}
              />
            )}
            {isSystemAdmin() && (
              <Route path="system-parameters">
                <Route
                  path=""
                  element={<Navigate to={`general`} />}
                />
                <Route
                  path="general"
                  element={<SystemParameters />}
                />
                <Route path="export-fields">
                  <Route
                    path=""
                    element={<Navigate to={`project`} />}
                  />
                  <Route
                    path="project"
                    element={<DefaultExportFieldsEditPage entityType={ENTITY_TYPES.PROJECT} />}
                  />
                  <Route
                    path="requirement"
                    element={<DefaultExportFieldsEditPage entityType={ENTITY_TYPES.REQUIREMENT} />}
                  />
                  <Route
                    path="test-case"
                    element={<DefaultExportFieldsEditPage entityType={ENTITY_TYPES.TEST_CASE} />}
                  />
                  <Route
                    path="test-run"
                    element={<DefaultExportFieldsEditPage entityType={ENTITY_TYPES.TEST_RUN} />}
                  />
                  <Route
                    path="defect"
                    element={<DefaultExportFieldsEditPage entityType={ENTITY_TYPES.DEFECT} />}
                  />
                </Route>
              </Route>
            )}
          </Route>
        </Routes>
      </SidePaneRightContent>
    </>
  );
};

export default SystemSettingsPage;
