export const UPDATED_CUSTOM_FIELDS = 'UPDATED_CUSTOMFIELDS';
export const UPDATED_SYSTEM_FIELD = 'UPDATED_SYSTEM_FIELD';
export const UPDATED_SELECTED_PROJECT = 'UPDATED_SELECTED_PROJECT';
export const UPDATED_PROJECT_CONFIG = 'UPDATED_PROJECT_CONFIG';
export const UPDATED_PROJECT_USERS = 'UPDATED_PROJECT_USERS';
export const SAVE_RUN_STATUSES = 'SAVE_RUN_STATUSES';
export const SET_ACTIVE_SUBNAV_SETTINGS = 'SET_ACTIVE_SUBNAV_SETTINGS';
export const USER_TABLE_FILTERS = 'USER_TABLE_FILTERS';
export const SAVED_JIRA_CONFIG = 'SAVED_JIRA_CONFIG';
export const UPDATED_SYSTEM_FIELD_PARAMS = 'UPDATED_SYSTEM_FIELD_PARAMS';
export const ADD_REQUEST = 'ADD_REQUEST';
export const REMOVE_REQUEST = 'REMOVE_REQUEST';
export const UPDATED_PROJECT_USER_GROUPS = 'UPDATED_PROJECT_USER_GROUPS';
export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT';

export const SYSTEM_ACTION_KEYS = {
  UPDATED_SYSTEM_PARAMS: 'UPDATED_SYSTEM_PARAMS'
};

export const PROJECT_ACTION_KEYS = {
  UPDATED_CUSTOM_FIELDS: 'UPDATED_CUSTOMFIELDS',
  UPDATED_SYSTEM_FIELD: 'UPDATED_SYSTEM_FIELD',
  UPDATED_SELECTED_PROJECT: 'UPDATED_SELECTED_PROJECT',
  UPDATED_PROJECT_CONFIG: 'UPDATED_PROJECT_CONFIG',
  UPDATED_PROJECT_USERS: 'UPDATED_PROJECT_USERS',
  SAVE_RUN_STATUSES: 'SAVE_RUN_STATUSES',
  SET_ACTIVE_SUBNAV_SETTINGS: 'SET_ACTIVE_SUBNAV_SETTINGS',
  USER_TABLE_FILTERS: 'USER_TABLE_FILTERS',
  USER_TABLE_COLUMNS: 'USER_TABLE_COLUMNS',
  ENTITY_ALL_FIELDS: 'ENTITY_ALL_FIELDS',
  UPDATE_SELECTED_COLUMNS: 'UPDATE_SELECTED_COLUMNS',
  SAVED_JIRA_CONFIG: 'SAVED_JIRA_CONFIG',
  UPDATED_SYSTEM_FIELD_PARAMS: 'UPDATED_SYSTEM_FIELD_PARAMS',
  ADD_REQUEST: 'ADD_REQUEST',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  UPDATED_PROJECT_USER_GROUPS: 'UPDATED_PROJECT_USER_GROUPS',
  SET_SELECTED_ROWS: 'SET_SELECTED_ROWS',
  SET_SELECTED_RUN_TESTCASES: 'SET_SELECTED_RUN_TESTCASES',
  SET_REFRESH_DATA: 'SET_REFRESH_DATA',
  SET_REFRESH_TREE: 'SET_REFRESH_TREE',
  WEB_SOCKET_UPDATE: 'WEB_SOCKET_UPDATE',
  SET_SELECTED_REPORT: 'SELECTED_REPORT'
};
