import React, { useState } from 'react';
import { Button } from '../../../../designSystem/DesignSystem';
import JiraHookViewModal from '../modal/JiraHookViewModal';
import JiraRequestModal from '../modal/JiraRequestModal';
import ProjectJiraConfigSetting from './ProjectJiraConfigSetting';
import { useTranslation } from 'react-i18next';

const JiraCommonSettingsGrid = (props) => {
  const [requestModalVis, setRequestModalVis] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [commonModal, setCommonModal] = useState(false);
  const { jiraConfig } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center rounded shadow border border-gray-50 h-20 w-full">
        <div className="flex gap-4 ml-5">
          <Button
            id="jiraEntityConfigGrid-commonSetting-button"
            size="small"
            secondary
            text={t('Common Setting')}
            onClick={() => setCommonModal(true)}
          />
          <Button
            id="jiraEntityConfigGrid-hookrefresh-button"
            secondary
            text={t('Refresh JIRA Hook')}
            size="small"
            disabled={jiraConfig?.templateBy}
            onClick={() => setModalVisibility(true)}
          />
        </div>
      </div>

      {requestModalVis === true && (
        <JiraRequestModal
          jiraConfig={jiraConfig}
          isOpen={requestModalVis}
          closeModal={() => setRequestModalVis(false)}
        />
      )}
      {modalVisibility === true && (
        <JiraHookViewModal
          jiraConfig={jiraConfig}
          isOpen={modalVisibility}
          closeModal={() => setModalVisibility(false)}
        />
      )}
      {commonModal === true && (
        <ProjectJiraConfigSetting
          isOpen={commonModal}
          closeModal={() => setCommonModal(false)}
          jiraConfig={jiraConfig}
        />
      )}
    </>
  );
};

export default JiraCommonSettingsGrid;
