import React, { useEffect } from 'react';
// API & HOOKS IMPORTS
import { useApi } from '../../hooks/UseApi';
import UseQueryProject from '../../hooks/UseQueryProject';
import HistoryApi from '../../api/HistoryApi';
// HELPER & CONSTANTS
import { getUserFirstLastNameById, isValidValue, translatedDateFormat } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
// DESIGN SYSTEM & COMPONENTS
import { CARD_BODY_SIZE, CARD_DATE_SIZE, CARD_HEADING_SIZE, CARD_USER_ICON_SIZE } from './HistoryDetails';
import { Body, Heading, StatusPill } from '../../designSystem/DesignSystem';
import { ArrowRight, UserCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

const StatusHistory = ({ entityId, entityType, refreshStatus, setRefreshStatus, users }) => {
  const { projectId } = UseQueryProject('projectId');
  const { t } = useTranslation();

  const StatusHistoryLoader = useApi({
    name: 'Getting release history data',
    api: HistoryApi.getStatusHistory,
    onError: (err) => showErrorMes(err)
  });

  const refreshTable = () => {
    const body = { projectId, entityId, entityType };
    StatusHistoryLoader.execute(body);
  };

  useEffect(() => {
    if (refreshStatus === true) {
      refreshTable();
      if (setRefreshStatus) setRefreshStatus(false);
    }
  }, [refreshStatus]);

  useEffect(() => {
    refreshTable();
  }, []);

  return (
    <div>
      {' '}
      <div className="h-160 overflow-y-auto">
        {StatusHistoryLoader?.data?.map((elem) => {
          return (
            <>
              <div className="mt-3 flex items-center justify-between gap-5 rounded-md border border-gray-200 bg-gray-50 p-2">
                <div className="flex flex-grow items-center justify-between ">
                  <div className="flex items-center gap-4">
                    <UserCircle
                      weight="duotone"
                      size={CARD_USER_ICON_SIZE}
                      color={'#7171beeb'}
                    />
                    <div className="flex flex-col gap-1">
                      <Heading
                        type={CARD_HEADING_SIZE}
                        text={getUserFirstLastNameById(users, elem?.user?.id)}
                      />
                      <Body
                        type={CARD_DATE_SIZE}
                        text={translatedDateFormat(elem?.modifiedTime, 'DD MMM YYYY HH:mm', t)}
                      />
                      {isValidValue(elem?.prevStatus) && elem?.prevStatus !== '-' ? (
                        elem?.total > 2 ? (
                          <Body
                            type={CARD_BODY_SIZE}
                            text={t('statusHistory_record', {
                              entity: elem?.prevStatus,
                              entity1: elem?.newStatus,
                              entity2: elem?.total
                            })}
                          />
                        ) : (
                          <Body
                            type={CARD_BODY_SIZE}
                            text={t('statusHistory_number', {
                              entity: t(elem?.prevStatus),
                              entity1: t(elem?.newStatus),
                              entity2: elem?.total == 1 ? t('once') : t('twice')
                            })}
                          />
                        )
                      ) : (
                        <Body
                          type={CARD_BODY_SIZE}
                          text={t('statusHistory_initilaize', { entity: t(elem?.newStatus) })}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    id="status"
                    className="flex items-center gap-3"
                  >
                    <div className="flex items-center">
                      {isValidValue(elem?.prevStatus) && elem?.prevStatus !== '-' ? (
                        <>
                          <div>
                            <StatusPill
                              value={elem?.prevStatus}
                              entityType={entityType}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    {isValidValue(elem?.prevStatus) && elem?.prevStatus !== '-' && <ArrowRight size={12} />}
                    <div>
                      {isValidValue(elem?.newStatus) && elem?.newStatus !== '' ? (
                        <>
                          <div>
                            <StatusPill
                              value={elem?.newStatus}
                              entityType={entityType}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default StatusHistory;
