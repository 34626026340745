import {axios} from "./ApiClient";
import { BASE_URL } from "./CommonApiHelper";

export const FILETEMP_PATH = BASE_URL + "file-template";

class FileTemplateApi {
    
    async downloadTemplate(type) {
        const resp = await axios.get(`${FILETEMP_PATH}/${type}`,{responseType: 'arraybuffer' });
        return resp.data;
    }

    async getFieldTemplate(type){
        const resp = await axios.get(`${FILETEMP_PATH}/${type}/fields`);
        return resp.data;
    }

    async parseTemplate(data){
        const resp = await axios.post(`${FILETEMP_PATH}/parse-template`,data);
        return resp.data;
    }

    async importTemplate(data){
        const resp = await axios.post(`${FILETEMP_PATH}/upload`,data);
        return resp.data;
    }


}

export default new FileTemplateApi();