import { useEffect } from 'react';
// API & HOOKS
import TestRunApi from '../api/TestRunApi';
import { useApi } from './UseApi';
import { useApp, useAppDispatch } from '../context/AppContext';
import { saveRunStatuses } from '../context/ProjectActions';
// HELPERS
import { showErrorMes } from '../helpers/NotificationHelper';
import { isValidList } from '../helpers/Helper';

const UseRunStatuses = () => {
  const app = useApp();
  const dispatch = useAppDispatch();
  const runStatuses = app?.runStatuses;

  const runStatusesLoader = useApi({
    name: 'Get all run statuses',
    api: TestRunApi.getTestRunStatuses,
    onSuccess: () => {
      dispatch(saveRunStatuses(runStatusesLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (!isValidList(runStatuses)) runStatusesLoader.execute();
  }, []);

  return runStatuses;
};

export default UseRunStatuses;
