// DESIGN SYSTEM
import { Heading } from '../../../designSystem/DesignSystem';
import i18next from 'i18next';
// HELPER & CONSTANT
import { convertDateToMs, isValidList, isValidValue } from '../../../helpers/Helper';
import { getBarChartData } from './BarChartHelper';
import { getLineChartData } from './LineChartHelper';
import { getPieChartData } from './PieChartHelper';
import { getSunburstChartData } from './SunburstHelper';
import { getRadialBarChartData } from './RadialBarHelper';
import { getBumpChartData } from './BumpChartHelper';
import { getAreaBumpChartData } from './AreaBumpHelper';
import { getScatterPlotChartData } from './ScatterPlotHelper';
import { getCirclePackingChartData } from './CirclePackingHelper';
import { ENTITY_TYPES } from '../../../constants';
import { findRow } from '../../../helpers/TableHelper';
import { isOwner, isSystemAdmin } from '../../../helpers/PriviligeHelper';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import moment from 'moment';

const nameSelect = (name, entityType, projectConfig) => {
  let artifactName = getArtifactName(projectConfig, entityType).toLowerCase();
  name = name.toLowerCase();
  return name.replace(artifactName, '');
};

/**
 * Dashboard is a method developed for translating parametric values.
 * @param {String} name
 * @param {ENTITY_TYPES} entityType
 * @param {projectConfig} projectConfig
 * @returns String
 */
export const translateChart = (name, entityType, projectConfig) => {
  let type = nameSelect(name, entityType, projectConfig).trim();
  let artifactName = getArtifactName(projectConfig, entityType);
  switch (type) {
    case 'priority':
      return i18next.t('chartPriority', {
        entity: artifactName
      });
    case 'status':
      return i18next.t('chartStatus', {
        entity: artifactName
      });
    case 'priority (jira)':
      return i18next.t('chartPriorityJira', {
        entity: artifactName
      });
    case 'priority/status':
      return i18next.t('chartPriorityStatus', {
        entity: artifactName
      });
    case 'priority/status (jira)':
      return i18next.t('chartPriorityStatusJira', {
        entity: artifactName
      });
    case 'type':
      return i18next.t('chartType', {
        entity: artifactName
      });
    case 'type/priority/status':
      return i18next.t('chartTypePriorityStatus', {
        entity: artifactName
      });
    case 'status (jira)':
      return i18next.t('chartStatusJira', {
        entity: artifactName
      });
    case 'total:':
      return i18next.t('chartTotal', {
        entity: artifactName
      });
    case 'pie chart':
      return i18next.t('defectPieChart', {
        entity: artifactName
      });
    case 'pie chart (clone)':
      return i18next.t('pieChartClone', {
        entity: artifactName
      });
    case 'priority  (clone)':
      return i18next.t('priotryCLone', {
        entity: artifactName
      });
    case 'priority/status (clone)':
      return i18next.t('priortyStatusClone', {
        entity: artifactName
      });
    case 'status (clone)':
      return i18next.t('statusClone', {
        entity: artifactName
      });
    case 'by execution':
      return i18next.t('testCaseByExecution', {
        entity: artifactName
      });
    default:
      return type;
  }
};

export const getChartHeading = (chart, projectConfig) => {
  return (
    <div>
      <Heading
        type="small"
        text={translateChart(chart?.name, chart?.entityType, projectConfig)}
      />
      {isValidValue(chart?.description) && (
        <>
          <span style={{ fontSize: 11 }}>{chart?.description}</span>
        </>
      )}
    </div>
  );
};

export const fillChartData = (response, chart, setData, projectId, setTotal, setKeys) => {
  if (response) {
    if (response?.error && response?.error?.type) {
      //showError(response?.error?.type);
    } else {
      if (setTotal) setTotal(response?.['hits']?.['total']?.['value']);

      if (chart?.type === CHART_TYPE.metric) {
        if (chart?.entityType === ENTITY_TYPES.TEST_CASE) setData(response?.['aggregations']?.['0']?.['value']);
        else setData(response?.hits?.total?.value);
      } else if (chart?.type === CHART_TYPE.fieldMetric) setData(response?.['aggregations']?.['0']?.['value']);
      else {
        let buckets = response?.['aggregations']?.['0']?.['buckets'];

        if (isValidList(buckets)) {
          if (chart?.type === CHART_TYPE.pie) setData(getPieChartData(buckets));
          else if (chart?.type === CHART_TYPE.sunburst) setData(getSunburstChartData(buckets, chart));
          else if (chart?.type === CHART_TYPE.bar) setData(getBarChartData(buckets, chart, setKeys));
          else if (chart?.type === CHART_TYPE.line) setData(getLineChartData(buckets, chart));
          else if (chart?.type === CHART_TYPE.area) setData(getLineChartData(buckets, chart));
          else if (chart?.type === CHART_TYPE.radialBar) setData(getRadialBarChartData(buckets, chart));
          else if (chart?.type === CHART_TYPE.bump) setData(getBumpChartData(buckets, chart));
          else if (chart?.type === CHART_TYPE.areaBump) setData(getAreaBumpChartData(buckets, chart));
          else if (chart?.type === CHART_TYPE.circlePacking) setData(getCirclePackingChartData(buckets, chart));
          else if (chart?.type === CHART_TYPE.scatterPlot) setData(getScatterPlotChartData(buckets, chart));
        }
      }
    }
  }
};

export const DASHBOARD_PAGE_ENUM = {
  DASHBOARD_PAGE: 'DASHBOARD_PAGE'
};

export const getDashboardEntityFields = (allEntityFields, entityType) => {
  if (isValidList(allEntityFields) && isValidValue(entityType)) {
    return allEntityFields.filter((x) => x?.entityType === entityType);
  }

  return [];
};

export const CHART_TYPE = {
  pie: 'PIE',
  sunburst: 'SUNBURST',
  radialBar: 'RADIAL_BAR',
  bump: 'BUMP',
  bar: 'BAR',
  line: 'LINE',
  area: 'AREA',
  metric: 'METRIC',
  fieldMetric: 'FIELD_METRIC',
  scatterPlot: 'SCATTER_PLOT',
  areaBump: 'AREA_BUMP',
  circlePacking: 'CIRCLE_PACKING',
  insight: 'INSIGHT'
};

export const showDateHistogramCheckbox = (dataForm) => {
  return (
    isValidValue(dataForm?.type?.id) &&
    dataForm?.type?.id !== CHART_TYPE.metric &&
    dataForm?.type?.id !== CHART_TYPE.insight &&
    dataForm?.type?.id !== CHART_TYPE.fieldMetric
  );
};

export const getChartTypeIcon = (type) => {
  if (CHART_TYPE.pie === type) return 'chart pie';
  else if (CHART_TYPE.sunburst === type) return 'chart pie';
  else if (CHART_TYPE.bump === type) return 'chart line';
  else if (CHART_TYPE.radialBar === type) return 'chart pie';
  else if (CHART_TYPE.bar === type) return 'chart bar';
  else if (CHART_TYPE.line === type) return 'chart line';
  else if (CHART_TYPE.area === type) return 'chart area';
  // else if (CHART_TYPE.table === type) return "table";
  else if (CHART_TYPE.metric === type || CHART_TYPE.fieldMetric === type) return '';
  else return '';
};

export const formatDashboardDate = (date) => {
  if (date && date != null) return moment(date).format('DD MMM YYYY HH:mm');
  else return '-';
};

export const getDefaultDateRange = () => {
  let startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
  startDate.setHours(0, 0, 0, 0);
  let endDate = new Date();
  endDate.setHours(23, 59, 0, 0);
  return {
    startDate: startDate,
    endDate: endDate,
    key: 'selection'
  };
};

export const getDefaultDateFilter = () => {
  let startDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
  startDate.setHours(0, 0, 0, 0);

  let endDate = new Date();
  endDate.setHours(23, 59, 0, 0);
  return {
    startMs: convertDateToMs(startDate),
    endMs: convertDateToMs(endDate)
  };
};

export const DefaultPageParams = {
  totalPage: 1,
  page: 0,
  size: 10,
  sortBy: 'name',
  sortDir: 'ASC',
  defaultSize: 10
};

export const isSystemDefault = (row, content) => {
  let t = findRow(content, row);
  if (t && t?.systemDefault) {
    return t.systemDefault + '' === 'true';
  }
  return false;
};

export const showTableAction = (row, content) => {
  let t = findRow(content, row);
  if (t?.id) {
    if (t?.systemDefault && t.systemDefault + '' === 'true') {
      return false;
    } else {
      return isOwner(t?.createdBy) || isSystemAdmin();
    }
  }
  return false;
};
