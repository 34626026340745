import { CaretDown, CaretUp } from 'phosphor-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { NoDataMessage, TableWrapper, getLoading } from '../../helpers/TableHelper';
import { Body, Heading } from '../../designSystem/DesignSystem';

const BasicTable = ({ columns, data, loading }) => {
  const { t } = useTranslation();

  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      maxWidth: 250
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useFlexLayout,
    useSortBy
  );

  return (
    <>
      <TableWrapper>
        <div className="overflow-x-auto">
          {loading === true ? (
            <>
              <div className={'mt-1'}>{getLoading()}</div>
            </>
          ) : (
            <>
              {rows.length === 0 ? (
                <div className="mt-1 h-96">
                  {' '}
                  <NoDataMessage message={t('No data found')} />{' '}
                </div>
              ) : (
                <>
                  <table
                    {...getTableProps()}
                    className="w-full"
                  >
                    <thead className="sticky z-10 w-full overflow-y-hidden bg-slate-100">
                      {headerGroups.map((headerGroup, index) => (
                        <tr
                          key={index}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column, index) => (
                            <th
                              key={index}
                              className="group border-r px-3 text-left tracking-wide"
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                              <div className="flex justify-between">
                                <div className="my-3 flex gap-2">
                                  <Heading
                                    color="text-gray-600"
                                    uppercase
                                    type="xsmall"
                                    text={column.render('Header')}
                                  />
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <CaretDown
                                        weight="fill"
                                        color="#6B7280"
                                        size={16}
                                      />
                                    ) : (
                                      <CaretUp
                                        weight="fill"
                                        color="#6B7280"
                                        size={16}
                                      />
                                    )
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="bg-white"
                      {...getTableBodyProps()}
                    >
                      <div className="h-96 divide-y divide-gray-200 overflow-y-auto">
                        {rows.map((row, i) => {
                          prepareRow(row);
                          return (
                            <tr
                              key={i}
                              className="py-1 odd:bg-white even:bg-slate-50 "
                              {...row.getRowProps()}
                            >
                              {row.cells.map((cell, i) => {
                                return (
                                  <td
                                    id={row?.allCells[i]?.column?.id}
                                    key={i}
                                    className="items-center whitespace-nowrap px-3 py-1"
                                    {...cell.getCellProps()}
                                  >
                                    <div className="overflow-hidden overflow-ellipsis whitespace-pre-wrap break-all">
                                      <Body
                                        type="medium"
                                        color="text-gray-500"
                                        text={cell.render('Cell')}
                                      />
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </div>
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}
        </div>
      </TableWrapper>
    </>
  );
};

export default BasicTable;
