import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// LAYOUT IMPORTS
import { SidePaneLeftContent, SidePaneRightContent } from '../../components/Layout';
import { Heading } from '../../designSystem/DesignSystem';
import { ENTITY_TYPES } from '../../constants';
import { useApp } from '../../context/AppContext';
import UseQueryProject from '../../hooks/UseQueryProject';
import DashboardSettingMenu from '../dashboard/DashboardSettingMenu';
// PAGE IMPORTS
import ArtifactNames from './configuration/ArtifactNames';
import MenuNames from './configuration/MenuNames';
import RequirementProjectSetting from './configuration/RequirementProjectSetting';
import DefectProjectSetting from './configuration/DefectProjectSetting';
import CustomFieldsPage from './customFields/CustomFieldsPage';
import JiraConfigPage from './jiraConfigSettings/JiraConfigPage';
import MailPage from './mail/MailPage';
import ProjectUserPage from './projectUser/ProjectUserPage';
import RoleSettingsPage from './roleSettings/RoleSettingsPage';
import RelatedProjectsPage from './relatedProject/RelatedProjectsPage';
// COMPONENT IMPORTS
import SettingsMenu from './settingsMenu/SettingsMenu';
import { menuItems } from './settingsMenu/SettingsMenuItemList';
import SystemFieldPage from './systemFieldSettings/SystemFieldPage';
import UserGroupsPage from './userGroups/UserGroupsPage';
import CycleWorkFlowPage from './workflow/cycleWorkFlow/CycleWorkFlowPage';
import DefectWorkFlowPage from './workflow/defectWorkFlow/DefectWorkFlowPage';
import VersionPage from './version/VersionPage';
import { useTranslation } from 'react-i18next';
import VisiumGoPage from './integrationSettings/visiumGo/VisiumGoPage';
import TestRunProjectSetting from './configuration/TestRunProjectSetting';

const SettingsPage = () => {
  const { project } = UseQueryProject('projectId');
  const app = useApp();
  const { t } = useTranslation();
  const menuItemsData = menuItems(app?.config, project?.id, project);

  const findFirstSubNavItem = (menuItems, targetMenuName, targetSubMenuName) => {
    for (const menuItem of menuItems) {
      if (menuItem.name === targetMenuName) {
        if (targetSubMenuName) {
          const subMenuItem = menuItem.subNav?.find((subMenu) => subMenu.name === targetSubMenuName);
          if (subMenuItem) {
            return subMenuItem.subNav?.[0] ?? null;
          }
        } else {
          return menuItem.subNav?.[0] ?? null;
        }
      } else if (menuItem.subNav) {
        const foundSubItem = findFirstSubNavItem(menuItem.subNav, targetMenuName, targetSubMenuName);
        if (foundSubItem) {
          return foundSubItem;
        }
      }
    }
    return null;
  };

  return (
    <>
      <SidePaneLeftContent>
        <SidePaneLeftContent.Heading>
          <div className="ml-6 flex flex-col justify-center">
            <Heading.Duo
              type="large"
              text={t('Settings')}
            />
          </div>
        </SidePaneLeftContent.Heading>
        <SettingsMenu
          project={project}
          data={menuItemsData}
        />
        <SettingsMenu
          project={project}
          data={menuItemsData}
        />
      </SidePaneLeftContent>
      <SidePaneRightContent>
        <Routes>
          <Route path="/">
            <Route
              path=""
              element={<Navigate to={`dashboard/dashboards?projectId=${project.id}`} />}
            />
            <Route path="configuration">
              <Route
                path=""
                element={<Navigate to={`artifact-names?projectId=${project.id}`} />}
              />
              <Route
                path="artifact-names"
                element={<ArtifactNames />}
              />
              <Route
                path="menu-names"
                element={<MenuNames />}
              />
              <Route
                path={ENTITY_TYPES.REQUIREMENT}
                element={<RequirementProjectSetting />}
              />
              <Route
                path={ENTITY_TYPES.TEST_RUN}
                element={<TestRunProjectSetting />}
              />
              <Route
                path={ENTITY_TYPES.DEFECT}
                element={<DefectProjectSetting />}
              />
            </Route>
            <Route path="dashboard">
              <Route
                path=""
                element={<Navigate to={`dashboards?projectId=${project.id}`} />}
              />
              <Route
                path="dashboards"
                element={
                  <DashboardSettingMenu
                    pageKey={'dashboard'}
                    projectId={project?.id}
                  />
                }
              />
              <Route
                path="charts"
                element={
                  <DashboardSettingMenu
                    pageKey={'chart'}
                    projectId={project?.id}
                  />
                }
              />
            </Route>
            <Route path="fields">
              <Route
                path=""
                element={<Navigate to={`custom-fields?projectId=${project.id}`} />}
              />
              <Route
                path="custom-fields"
                element={<CustomFieldsPage />}
              />
              <Route
                path="system-fields"
                element={<SystemFieldPage activeSubNav={findFirstSubNavItem(menuItemsData, 'System Field').path} />}
              />
            </Route>
            <Route path="integration">
              <Route
                path=""
                element={<Navigate to={`mail?projectId=${project.id}`} />}
              />
              <Route
                path="mail"
                element={<MailPage />}
              />
              <Route
                path="jira"
                element={<JiraConfigPage />}
              />
              <Route
                path="visiumGo"
                element={<VisiumGoPage />}
              />
            </Route>
            <Route path="parameters">
              <Route
                path=""
                element={<Navigate to={`parameters?projectId=${project.id}`} />}
              />
              <Route
                path="parameters"
                element={<MailPage />}
              />
            </Route>
            <Route path="user">
              <Route
                path=""
                element={<Navigate to={`users?projectId=${project.id}`} />}
              />
              <Route
                path="users"
                element={<ProjectUserPage />}
              />
              <Route
                path="roles"
                element={<RoleSettingsPage />}
              />
              <Route
                path="user-groups"
                element={<UserGroupsPage />}
              />
            </Route>
            <Route path="workflow">
              <Route
                path=""
                element={
                  <Navigate
                    to={`${findFirstSubNavItem(menuItemsData, 'Workflows').path}` + `?projectId=${project.id}`}
                  />
                }
              />
              <Route
                path={ENTITY_TYPES.TEST_CYCLE}
                element={<CycleWorkFlowPage />}
              />
              <Route
                path={ENTITY_TYPES.DEFECT}
                element={<DefectWorkFlowPage />}
              />
            </Route>
            <Route path="related-projects">
              <Route
                path=""
                element={<RelatedProjectsPage />}
              />
            </Route>
            <Route path="Versions">
              <Route
                path=""
                element={<VersionPage projectId={project?.id} />}
              />
            </Route>
          </Route>
        </Routes>
      </SidePaneRightContent>
    </>
  );
};

export default SettingsPage;
