import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useAppDispatch } from '../../../context/AppContext';

// Api Imports
import ProjectConfigApi from '../../../api/ProjectConfigApi';
import { useApi } from '../../../hooks/UseApi';

// Component Imports
import { Heading, Button } from '../../../designSystem/DesignSystem';
import TableInlineEdit from '../../../components/table/TableInlineEdit';
import UseQueryProject from '../../../hooks/UseQueryProject';
import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { showErrorMes, INFO_MESSAGES, showSuccessMes } from '../../../helpers/NotificationHelper';
import { SidePaneRightContent } from '../../../components/Layout';

import {
  getCycleName,
  getDefectName,
  getReleaseName,
  getRequirementName,
  getTestCaseName
} from '../../../helpers/ArtifactNameHelper';
import { capitalize, isValidList, isValidValue } from '../../../helpers/Helper';
import { saveProjectConfig } from '../../../context/ProjectActions';
import { useTranslation } from 'react-i18next';

const MenuNames = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const ref = useRef();
  const { projectId, project } = UseQueryProject('projectId');

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [projectConfig, setProjectConfig] = useState({});

  let tableHeight = ref?.current?.clientHeight;

  let errArr = [];

  const canSubmit = (obj, t) => {
    if (errArr.length > 0) {
      errArr.forEach((err) => showErrorMes(t('Menu names can not be empty : ') + err));
      return false;
    }
    return true;
  };

  const menuTitles = {
    dashboard: 'Dashboard',
    defect: getDefectName(projectConfig),
    task: 'Task',
    release: getReleaseName(projectConfig),
    report: 'Report',
    requirement: getRequirementName(projectConfig),
    testCycle: getCycleName(projectConfig),
    testCase: getTestCaseName(projectConfig)
  };

  const updateProjectConfig = useApi({
    name: 'Updating project config',
    api: ProjectConfigApi.updateProjectConfiguration,
    onSuccess: () => {
      setProjectConfig(updateProjectConfig?.data);
      dispatch(saveProjectConfig(projectId, updateProjectConfig?.data));
      saveDataState(updateProjectConfig?.data);
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err)
  });

  const projectConfigLoader = useApi({
    name: 'Get project configs',
    api: ProjectConfigApi.get,
    onSuccess: () => {
      setProjectConfig(projectConfigLoader?.data);
      saveDataState(projectConfigLoader?.data);
      dispatch(saveProjectConfig(projectId, projectConfigLoader?.data));
    },
    onError: (err) => showErrorMes(err)
  });

  const saveDataState = (confParam) => {
    const menuConfig = confParam?.configuration?.menu;
    let mappedData = [];
    const menuEntries = menuConfig ? Object.entries(menuConfig) : [];

    if (isValidList(menuEntries)) {
      mappedData = menuEntries.map((item) => ({ menu: item[0], names: item[1], title: capitalize(item[0]) }));
    } else {
      const entries = Object.entries(menuTitles);
      mappedData = entries.map((item) => ({ menu: item[0], names: item[1], title: capitalize(item[0]) }));
    }
    setData(mappedData);
    setOriginalData(mappedData);
  };

  const resetData = () => {
    setData(originalData);
    showSuccessMes(INFO_MESSAGES.removedChanges);
  };

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page

    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };
  const filterData = (arr, name) => {
    const filteredArr = arr.filter((item) => item?.menu === name);
    if (!isValidValue(filteredArr[0]?.names)) {
      errArr.push(filteredArr[0]?.title);
    } else {
      return filteredArr[0]?.names;
    }
  };
  const submitData = () => {
    const inputValue = {
      dashboard: filterData(data, 'dashboard'),
      defect: filterData(data, 'defect'),
      task: filterData(data, 'task'),
      release: filterData(data, 'release'),
      report: filterData(data, 'report'),
      requirement: filterData(data, 'requirement'),
      testCycle: filterData(data, 'testCycle'),
      testCase: filterData(data, 'testCase')
    };
    if (canSubmit(inputValue, t)) {
      const body = { id: projectId, key: 'menu', mapValue: inputValue };
      updateProjectConfig.execute(body);
    } else {
      setData(originalData);
    }
  };

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(
    () => [
      { Header: t('Menu'), accessor: 'title' },
      { Header: t('Names'), accessor: 'names' }
    ],
    [data, t]
  );

  useEffect(() => {
    if (projectId && isProjectManager(projectId)) {
      projectConfigLoader.execute({ id: projectId });
    }
  }, [projectId]);

  return (
    <SidePaneRightContent>
      <SidePaneRightContent.Top>
        {isProjectManager(projectId) && (
          <div>
            <Heading.Group
              type="large"
              text={t('Menu Names')}
            >
              <div className="flex gap-4">
                <Button
                  id="menuNames-submit-button"
                  primary
                  text={t('Submit')}
                  size="small"
                  disabled={isValidValue(project?.templateBy)}
                  onClick={submitData}
                  loading={updateProjectConfig?.loading}
                />
                <Button
                  id="menuNames-refresh-button"
                  secondary
                  tooltip={t('Refresh')}
                  size="small"
                  icon="ArrowCounterClockwise"
                  weight="fill"
                  disabled={isValidValue(project?.templateBy)}
                  onClick={resetData}
                />
              </div>
            </Heading.Group>
          </div>
        )}
      </SidePaneRightContent.Top>
      <SidePaneRightContent.Center
        colFull={true}
        ref={ref}
      >
        <div className="mt-4">
          <TableInlineEdit
            disabled={isValidValue(project?.templateBy)}
            columns={tableColumns}
            data={tableData}
            updateMyData={updateMyData}
            tableHeight={tableHeight}
          />
        </div>
      </SidePaneRightContent.Center>
    </SidePaneRightContent>
  );
};

export default MenuNames;
