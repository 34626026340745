import React, { useState } from 'react';
// API & HOOKS
import { useApi } from '../../../hooks/UseApi';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
import ReleaseApi from '../../../api/ReleaseApi';
import TestCaseApi from '../../../api/TestCaseApi';
// HELPER & CONSTANTS
import { ENTITY_TYPES } from '../../../constants';
import {
  getArtifactName,
  getReleaseName,
  getRequirementName,
  getTestCaseName
} from '../../../helpers/ArtifactNameHelper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
// DESIGN SYSTEM & COMPONENTS
import { Button } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import RequirementSearchTree from '../../../components/treeView/RequirementSearchTree';
import { useTranslation } from 'react-i18next';

const AddRequirementModal = ({ isOpen, closeModal, entityId, submitCallBack, entityType }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  // <- S ->
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const { t } = useTranslation();

  const assignSuccessFn = (type) => {
    if (submitCallBack) submitCallBack();
    closeModal();
    // <- # ->
    type === 'requirement' &&
      showSuccessMes(
        t('requirementAddedIntoRelease', {
          requirement: getRequirementName(projectConfig),
          release: getReleaseName(projectConfig)
        })
      );
    type === 'tcase' &&
      showSuccessMes(
        t('requirementAddedIntoRelease', {
          requirement: getRequirementName(projectConfig),
          release: getTestCaseName(projectConfig)
        })
      );
  };

  // <-- API SETUP -->
  const assignRequirementToRelease = useApi({
    name: 'Assigning requirement',
    api: ReleaseApi.assignRequirement,
    onSuccess: () => assignSuccessFn('requirement'),
    onError: (err) => showErrorMes(err)
  });

  const assignRequirementToTCase = useApi({
    name: 'Assigning requirement',
    api: TestCaseApi.assignRequirement,
    onSuccess: () => assignSuccessFn('tcase'),
    onError: (err) => showErrorMes(err)
  });
  // <-- ## -->

  const addRequirement = () => {
    const body = {
      folderIds: selectedFolders,
      requirementIds: selectedRequirements,
      id: entityId
    };
    // <- ∫ ->
    if (entityType === ENTITY_TYPES.RELEASE) assignRequirementToRelease.execute(body);
    if (entityType === ENTITY_TYPES.TEST_CASE) assignRequirementToTCase.execute(body);
  };

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal()}
      >
        {/* !TODO: inline style düzeltilecek */}
        <Modal.Title>
          {t('add_entity', { entity: getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT) })}
        </Modal.Title>
        <Modal.Content>
          <RequirementSearchTree
            setSelectedRequirements={setSelectedRequirements}
            setSelectedFolders={setSelectedFolders}
            showJiraKey={true}
          />
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="addRequirementModal-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={() => closeModal()}
          />
          <Button
            id="addRequirementModal-submit-button"
            primary
            text={t('Submit')}
            disabled={
              !(selectedRequirements && selectedRequirements.length) && !(selectedFolders && selectedFolders.length)
            }
            onClick={() => addRequirement()}
            loading={
              entityType === ENTITY_TYPES.RELEASE
                ? assignRequirementToRelease?.loading
                : assignRequirementToTCase?.loading
            }
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default AddRequirementModal;
