import React, { useState } from 'react';
// API & HOOKS
import { useApi } from '../../hooks/UseApi';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import TestCaseApi from '../../api/TestCaseApi';
// HELPER & CONSTANTS
import { ENTITY_TYPES } from '../../constants';
import { getArtifactName, getRequirementName, getTestCaseName } from '../../helpers/ArtifactNameHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
// DESIGN SYSTEM & COMPONENTS
import { Button } from '../../designSystem/DesignSystem';
import { useTranslation } from 'react-i18next';
import RequirementSearchTree from '../treeView/RequirementSearchTree';
import { Modal } from '../modal';

const BulkassignTestCasesFromRequirementsModal = ({ runIds, isOpen, closeModal, submitCallBack, entityType }) => {
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  // <- S ->
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const { t } = useTranslation();

  const assignSuccessFn = (type) => {
    if (submitCallBack) submitCallBack();
    closeModal();
    // <- # ->
    type === 'tcase' &&
      showSuccessMes(
        t('requirementAddedIntoRelease', {
          requirement: getRequirementName(projectConfig),
          release: getTestCaseName(projectConfig)
        })
      );
  };

  // <-- API SETUP -->

  const bulkAssignTestCasesFromRequirements = useApi({
    name: 'bulk assign Requirements',
    api: TestCaseApi.bulkTestCasesAssignFromRequirement,
    autoLoad: false,
    onSuccess: () => {
      assignSuccessFn('tcase');
      showSuccessMes(INFO_MESSAGES.save);
      closeModal();
      submitCallBack();
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  // <-- ## -->

  const addRequirement = () => {
    const body = {
      folderIds: selectedFolders,
      requirementIds: selectedRequirements,
      ids: runIds
    };
    // <- ∫ ->
    if (entityType === ENTITY_TYPES.TEST_CASE) bulkAssignTestCasesFromRequirements.execute(body);
  };

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal()}
      >
        <Modal.Title>
          {t('add_entity', { entity: getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT) })}
        </Modal.Title>
        <Modal.Content>
          <RequirementSearchTree
            setSelectedRequirements={setSelectedRequirements}
            setSelectedFolders={setSelectedFolders}
            showJiraKey={true}
          />
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="addRequirementModal-cancel-button"
            secondary
            text={t('Cancel')}
            onClick={() => closeModal()}
          />
          <Button
            id="addRequirementModal-submit-button"
            primary
            text={t('Submit')}
            disabled={
              !(selectedRequirements && selectedRequirements.length) && !(selectedFolders && selectedFolders.length)
            }
            onClick={() => addRequirement()}
            loading={bulkAssignTestCasesFromRequirements?.loading}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default BulkassignTestCasesFromRequirementsModal;
