import React, { useState, useEffect } from 'react';
// HOOKS & API
import { useForm } from 'react-hook-form';
import { useApi } from '../../../hooks/UseApi';
import DashboardApi from '../../../api/DashboardApi';
import { useTranslation } from 'react-i18next';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import { translateChart } from '../helper/DashboardHelper';
// COMPONENTS
import { Modal } from '../../../components/modal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// HELPER && CONSTANTS
import {
  getUserFirstLastNameById,
  isValidList,
  isValidValue,
  sortArr,
  sortingMethod,
  translatedDateFormat
} from '../../../helpers/Helper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
// DESIGN SYSTEM
import { Body, Heading, Button, Dropdown } from '../../../designSystem/DesignSystem';

const DashboardChartAssignModal = ({ closeModal, isSystemDashboard, isOpen, users, dashboardId, projectId }) => {
  const { t } = useTranslation();

  const projectConfig = UseProjectConfig(projectId);

  const [newItem, setnewItem] = useState({});
  const [chartOptions, setchartOptions] = useState([]);
  const [items, setItems] = useState([]);

  const { control, setValue } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const fetchCharts = useApi({
    name: 'fetchCharts',
    api: DashboardApi.fetchCharts,
    onSuccess: () => fillOptions(fetchCharts?.data)
  });

  const saveDashboardChartOrders = useApi({
    name: 'saveDashboardChartOrders',
    api: DashboardApi.saveDashboardChartOrders,
    onSuccess: () => {
      closeModal();
      showSuccessMes(INFO_MESSAGES.save);
    },
    onError: (err) => showErrorMes(err)
  });

  const fetchChartsByDashboard = useApi({
    name: 'fetchChartsByDashboard',
    api: DashboardApi.fetchChartsByDashboard,
    autoLoad: false,
    onSuccess: () => {
      setItems([]);
      if (fetchChartsByDashboard?.data) {
        setItems(fetchChartsByDashboard.data.sort(sortArr('order', null)));
      }
    },
    onError: (err) => showErrorMes(err)
  });

  const fillOptions = (charts) => {
    let arr = [];
    if (isValidList(charts)) {
      charts.map((chart) => {
        let chartName = chart?.text || translateChart(chart?.name, chart?.entityType, projectConfig);
        chartName += ' (' + t(chart?.type) + ')';

        let createdby = getUserFirstLastNameById(users, chart?.createdBy);
        if (isValidValue(createdby)) {
          chartName +=
            t(' created by ') + createdby + ',  ' + translatedDateFormat(chart?.modifiedTime, 'DD MMM YYYY', t);
        }

        if (chartName) {
          arr.push({
            value: parseInt(chart?.id, 10),
            key: parseInt(chart?.id, 10),
            id: parseInt(chart?.id, 10),
            name: chartName
          });
        }
      });
    }
    setchartOptions(sortingMethod(arr, 'asc'));
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : '#white',
    padding: 0,
    width: '98%'
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '8px 18px 8px 0px',
    marginRight: 0,
    fontSize: 12,
    // change background colour if dragging
    background: isDragging ? '#e6f0f5' : 'white',
    borderBottom: `1px solid rgba(28,110,164,0.12)`,
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const saveChanges = () => {
    let input = [];
    items.forEach((item, i) => {
      let el = { ...item, order: i + 1 };
      input.push(el);
    });

    saveDashboardChartOrders.execute({
      projectId,
      dashboardId,
      charts: input
    });
  };

  const removeItem = (id) => {
    if (id && isValidList(items)) {
      let arr = [];
      items.forEach((item) => {
        if (item?.chartId !== id) {
          arr.push(item);
        }
      });
      setItems(arr);
    }
  };

  const addItem = (id) => {
    let selectedItem = items.find((item) => item?.chartId === id);
    if (id && !isValidValue(selectedItem?.chartId)) {
      let arr = isValidList(items) ? [...items] : [];
      arr.push({
        chartId: id,
        order: 99
      });
      setItems(arr);
    }
    setValue('addItem', null);
    setnewItem({});
  };

  const getItemName = (id) => {
    if (id) {
      let target = chartOptions.find((chart) => chart?.chartId === id || chart?.id === id || chart?.key === id);
      if (target) return target?.name;
    }
    return '';
  };

  //TODO: result parametresi kontrol edilecek.
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const elems = reorder(items, result.source.index, result.destination.index);
    setItems(elems);
  };

  useEffect(() => {
    fetchCharts.execute({ projectId });
    fetchChartsByDashboard.execute({ id: dashboardId });
  }, [dashboardId, isSystemDashboard]);

  return (
    <>
      <Modal
        w={'md'}
        open={isOpen}
        close={() => closeModal()}
      >
        <Modal.Title>
          <Heading.Group
            text={t('Chart Order Setting')}
            fontSize="text-xl"
          >
            <div className="flex gap-4">
              <Button
                id="dashboardChartAssignModal-close-button"
                icon="X"
                onClick={closeModal}
                size="xsmall"
              />
            </div>
          </Heading.Group>
        </Modal.Title>
        <Modal.Content h={'md'}>
          <div className="flex flex-grow flex-col gap-8">
            {isSystemDashboard === false && (
              <div className="flex gap-1">
                <Dropdown
                  label={t('Add New Chart')}
                  clearable
                  control={control}
                  placeholder={t('Select Chart')}
                  name="addItem"
                  options={chartOptions}
                  selectedItem={newItem}
                  passValue={(value) => addItem(value?.[0]?.id)}
                />
              </div>
            )}

            {isSystemDashboard ? (
              <Body
                type="medium"
                text={t('You can not change system dashboard settings')}
              />
            ) : (
              <Body
                type="medium"
                text={
                  t('Metric and insight charts will be shown on top.') +
                  t('You can change order of charts. Use drag and drop functionality on row.')
                }
              />
            )}
            <div className={'overflow-y-auto'}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item?.chartId}
                          draggableId={`${item?.chartId}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...(!isSystemDashboard && {
                                ...provided.draggableProps,
                                ...provided.dragHandleProps
                              })}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-1 self-center">
                                  <Body
                                    type="medium"
                                    text={index + 1}
                                  />
                                </div>
                                <div className="col-span-8 self-center">
                                  <Body
                                    type="medium"
                                    text={getItemName(item?.chartId)}
                                  />
                                </div>
                                {isSystemDashboard === false && (
                                  <div className="col-span-1">
                                    <Button
                                      id="dashboardChartAssignModal-remove-button"
                                      secondary
                                      icon="TrashSimple"
                                      size="xsmall"
                                      onClick={() => removeItem(item?.chartId)}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="dashboardChartAssignModal-close-button"
            secondary
            text={t('Close')}
            onClick={() => {
              closeModal();
            }}
          />
          {isSystemDashboard === false && (
            <Button
              id="dashboardChartAssignModal-submit-button"
              primary
              text={t('Submit')}
              loading={saveDashboardChartOrders?.loading}
              onClick={() => {
                saveChanges();
              }}
            />
          )}
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default DashboardChartAssignModal;
