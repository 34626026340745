import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// API AND HOOK IMPORTS
import UseProjectConfig from '../../../hooks/UseProjectConfig';
import UseQueryProject from '../../../hooks/UseQueryProject';
// DESIGN SYSTEM IMPORTS
import { Heading } from '../../../designSystem/DesignSystem';
import SystemFieldPageTable from './SystemFieldPageTable';
// HELPER IMPORTS
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { SidePaneRightContent } from '../../../components/Layout';
import { useTranslation } from 'react-i18next';
import { isValidValue } from '../../../helpers/Helper';

const SystemFieldPage = ({ activeSubNav }) => {
  const { projectId, project } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeItem = query.get('activeSubNav');

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isValidValue(activeItem)) {
      navigate(`?projectId=${projectId}&activeSubNav=${activeSubNav}`);
    }
  }, [activeItem]);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('System Fields')}
            subTextType="base"
            subText={`${getArtifactName(projectConfig, activeItem)}`}
          />
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Center colFull={true}>
          {isProjectManager(projectId) && (
            <div className="mt-6">
              <SystemFieldPageTable
                activeItem={activeItem}
                relatedEntity={activeItem}
                projectId={projectId}
                projectConfig={projectConfig}
                project={project}
              />
            </div>
          )}
        </SidePaneRightContent.Center>
      </SidePaneRightContent>
    </>
  );
};

export default SystemFieldPage;
