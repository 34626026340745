import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// API and Hook Imports
import { useApi } from '../../../hooks/UseApi';
import SystemFieldApi from '../../../api/SystemFieldApi';
// Design System Imports
import { Toggle, Button, MultiSelectDropdownPill } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
//Helper Imports
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
import { isValidValue, isValidList, capitalize } from '../../../helpers/Helper';
//Component Imports
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';

const SystemFieldWorkflowSetting = ({
  isOpen,
  closeModal,
  projectConfig,
  relatedField,
  relatedEntity,
  systemFieldOptions,
  setSystemFieldOptions
}) => {
  const { control, reset } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const [statusList, setStatusList] = useState([]);
  const [nextStatusform, setNextStatusform] = useState({});
  const { t } = useTranslation();

  const updateIsLastStatus = useApi({
    name: 'update is last status',
    api: SystemFieldApi.updateIsLastStatus,
    onSuccess: () => {
      let newList = [...systemFieldOptions];
      let index = systemFieldOptions.findIndex((value) => value.id === updateIsLastStatus?.data.id);
      newList[index] = updateIsLastStatus?.data;
      setSystemFieldOptions(newList);
    },
    onError: (err) => showErrorMes(err)
  });

  const updateIsFirstStatus = useApi({
    name: 'update is first status',
    api: SystemFieldApi.updateIsFirstStatus,
    onSuccess: () => {
      let newList = [...systemFieldOptions];
      let index = systemFieldOptions.findIndex((value) => value.id === updateIsFirstStatus?.data.id);
      newList[index] = updateIsFirstStatus?.data;
      setSystemFieldOptions(newList);
    },
    onError: (err) => showErrorMes(err)
  });

  const updateNextStatus = useApi({
    name: 'update next status',
    api: SystemFieldApi.updateNextStatus,
    onSuccess: () => {
      let newList = [...systemFieldOptions];
      let index = systemFieldOptions.findIndex((value) => value.id === updateNextStatus?.data.id);
      newList[index] = updateNextStatus?.data;
      setSystemFieldOptions(newList);
    },
    onError: (err) => showErrorMes(err)
  });

  const fillStatusOptions = () => {
    let arr = [];
    systemFieldOptions.forEach((item) => {
      arr.push({
        key: item?.id,
        value: item?.id,
        text: t(item?.name)
      });
    });
    setStatusList(arr);
  };

  const fillNextStatusForm = () => {
    let tempForm = {};
    systemFieldOptions.forEach((fieldOptionItem) => {
      let nodes = fieldOptionItem?.nextNodes;
      if (isValidList(nodes)) {
        let ids = [];
        nodes.forEach((x) => {
          ids.push({ ...x, value: x?.id });
        });
        tempForm[fieldOptionItem?.id] = ids;
      }
    });
    setNextStatusform(tempForm);
  };

  const getStatusOptions = (statusId) => {
    if (isValidList(statusList)) {
      let newList = [];
      statusList.forEach((x) => {
        if (x?.key !== statusId) newList.push(x);
      });
      return newList;
    }
    return [];
  };

  const updateLastStatus = (data, fieldOption) => {
    updateIsLastStatus.execute({ id: fieldOption.id, checked: data });
  };

  const updateFirstStatus = (data, fieldOption) => {
    updateIsFirstStatus.execute({ id: fieldOption.id, checked: data });
  };

  const onUpdateNextStatus = (value, fieldOption) => {
    let ids = [];
    if (isValidList(value)) {
      value.map((item) => {
        ids.push(item.id);
      });
    }
    updateNextStatus.execute({ id: fieldOption.id, ids });
  };

  const translateItems = (arr) => {
    if (isValidList(arr)) {
      arr.map((item) => {
        item.name = t(item.name);
      });
    }
    return arr;
  };

  useEffect(() => {
    fillStatusOptions();
    fillNextStatusForm();
  }, []);

  return (
    <>
      <Modal
        w={'lg'}
        open={isOpen}
        close={() => closeModal}
      >
        <Modal.Title className="capitalize">
          {t('SystemFieldWorkFlowSetting_entity', {
            entity: t(getArtifactName(projectConfig, relatedEntity)),
            relatedField: t(capitalize(relatedField))
          })}
        </Modal.Title>
        <Modal.Content h={'md'}>
          <div className="flex h-64 flex-grow rounded-md  border border-gray-200 shadow ">
            <table className="flex flex-grow flex-col">
              <thead className="flex-grow bg-gray-50">
                <tr className="flex flex-grow ">
                  <th
                    className={`group w-1/12 px-6 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                  >
                    {t('Name')}
                  </th>
                  <th
                    className={`group w-1/12 px-3 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                  >
                    {t('Last Status')}
                  </th>
                  <th
                    className={`group w-1/12 px-3 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                  >
                    {t('First Status')}
                  </th>
                  <th
                    className={`group w-4/12 px-6 py-3 text-left text-xs font-medium capitalize tracking-wide text-gray-500`}
                  >
                    {t('Next Status')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 overflow-x-hidden bg-white ">
                {systemFieldOptions?.map((fieldOption, index) => {
                  return (
                    <tr
                      key={index}
                      className="flex"
                    >
                      <td className=" w-1/12 place-self-center break-words  px-3 py-3 text-sm">
                        {t(fieldOption.name)}
                      </td>
                      <td className="w-1/12 place-self-center whitespace-nowrap px-3 py-3">
                        <Toggle
                          disabled={isValidValue(fieldOption?.templateBy)}
                          control={control}
                          value={fieldOption?.isLastStatus}
                          name={fieldOption?.id}
                          passValue={(value) => {
                            updateLastStatus(value, fieldOption);
                          }}
                        />
                      </td>
                      <td className="w-1/12 place-self-center whitespace-nowrap px-3 py-3 ">
                        <Toggle
                          disabled={isValidValue(fieldOption?.templateBy)}
                          control={control}
                          value={fieldOption?.isFirstStatus}
                          name={fieldOption?.id}
                          passValue={(value) => {
                            updateFirstStatus(value, fieldOption);
                          }}
                        />
                      </td>
                      <td className="w-4/12 flex-grow place-self-center  px-3 py-3">
                        <MultiSelectDropdownPill
                          control={control}
                          disabled={isValidValue(fieldOption?.templateBy)}
                          name={fieldOption?.id}
                          options={getStatusOptions(fieldOption?.id)}
                          value={translateItems(nextStatusform[fieldOption?.id])}
                          passValue={(value) => onUpdateNextStatus(value, fieldOption)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            id="systemFieldWorkflowSetting-close-button"
            primary
            text={t('Close')}
            type="button"
            onClick={() => {
              closeModal();
              reset();
            }}
          />
        </Modal.Buttons>
      </Modal>
    </>
  );
};
export default SystemFieldWorkflowSetting;
