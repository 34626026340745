import * as Icons from 'phosphor-react';
import { classNames } from '../helpers/Helper';

const returnIcon = (icon, color, size, weight) => {
  const IconComponent = Icons[icon] || Icons.Plus;
  return (
    <IconComponent
      color={color}
      size={classNames(size === 'xsmall' && 16, size === 'small' && 16, (size === 'base' || !size) && 18)}
      weight={weight}
    />
  );
};

export { returnIcon };
