import { axios } from './ApiClient';

class DefaultReportsApi {
  async cycleUserDefectCount(data) {
    const resp = await axios.get(`/api/kibana-default-reports/cycle-user-defect-count?projectId=${data.projectId}`);
    return resp.data;
  }

  async suiteTestCaseExecutionCount(data) {
    const resp = await axios.get(
      `/api/kibana-default-reports/suite-test-case-execution-count?projectId=${data.projectId}`
    );
    return resp.data;
  }
}
export default new DefaultReportsApi();
