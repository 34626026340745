import React, { useEffect, useMemo, useState } from 'react';
// API & HOOKS IMPORTS
import { useApi } from '../../../hooks/UseApi';
import DashboardApi from '../../../api/DashboardApi';
// COMPONENT IMPORTS
import DashboardAddModal from '../modal/DashboardAddModal';
import DashboardSettingModal from '../modal/DashboardSettingModal';
import DashboardProjectAssignModal from '../modal/DashboardProjectAssignModal';
import ConfirmationModal from '../../../components/confirm/ConfirmationModal';
import DashboardChartAssignModal from '../modal/DashboardChartAssignModal';
// DESIGN SYSTEM IMPORTS
import Table from '../../../components/table/Table';
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { ChartBar, Copy, Eye, EyeClosed, GearSix, PencilLine, Trash } from 'phosphor-react';
// HELPER & CONSTANT IMPORTS
import { isValidValue } from '../../../helpers/Helper';
import { TABLE_ACTION_BUTTON_CONSTANTS, TABLE_ACTION_COLUMNS_STYLE } from '../../../helpers/ButtonConstants';
import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';
import {
  convertToTableData,
  DEFAULT_PAGE_PARAMS,
  findRow,
  generateTableColumns,
  handleSelectedRow,
  translateColumns
} from '../../../helpers/TableHelper';
import { SidePaneRightContent } from '../../../components/Layout';
import { showTableAction } from '../helper/DashboardHelper';
import useProjectConfig from '../../../hooks/UseProjectConfig';
import { getI18n, useTranslation } from 'react-i18next';

const Dashboards = ({ projectId, users, commonConfigs, managerProjects }) => {
  const { t } = useTranslation();
  // PROJECT STATE
  const projectConfig = useProjectConfig(projectId);
  // DATA STATES
  const [selectedItem, setSelectedItem] = useState('');
  const [pageParams, setPageParams] = useState(DEFAULT_PAGE_PARAMS('name', 'ASC'));
  // MODAL VISIBILITY
  const [projectAssignVis, setProjectAssignVis] = useState(false);
  const [chartAssignVis, setChartAssignVis] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [updateModalVisibility, setUpdateModalVisibility] = useState(false);
  // CONFIRM STATES
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmToClone, setConfirmToClone] = useState(false);
  // ##
  // <-- API SETUP -->
  // ##
  const dataLoader = useApi({
    name: 'fetching dashboards',
    api: DashboardApi.fetchDashboards,
    autoLoad: false,
    onSuccess: () => {
      setPageParams((prevState) => ({
        ...prevState,
        size: dataLoader?.data.size,
        pageSize: dataLoader?.data.pageSize,
        totalPages: dataLoader?.data?.totalPages,
        totalElements: dataLoader?.data?.totalElements
      }));
    },
    onError: (err) => showErrorMes(err)
  });

  const deleteLoader = useApi({
    name: 'dashboard delete',
    api: DashboardApi.deleteDashboard,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      setConfirmOpen(false);
      setSelectedItem(null);
      refreshTable();
      showSuccessMes(INFO_MESSAGES.delete);
    },
    onError: (err) => showErrorMes(err)
  });

  const clone = useApi({
    name: 'dashboard clone',
    api: DashboardApi.cloneDashboard,
    autoLoad: false,
    onSuccess: () => {
      setSelectedItem(null);
      setConfirmToClone(false);
      refreshTable();
      showSuccessMes(INFO_MESSAGES.clone);
    },
    onError: (err) => showErrorMes(err)
  });

  const changeVisible = useApi({
    name: 'dashboard change visibility',
    api: DashboardApi.changeDashboardVisible,
    autoLoad: false,
    onSuccess: () => {
      refreshTable();
      showSuccessMes(t('Completed successfully.'));
    },
    onError: (err) => showErrorMes(err)
  });

  // ##
  // <-- EVENT FN  -->
  // ##
  const getItems = (pageInput, filterInput) => {
    const body = { ...pageInput, ...filterInput };
    dataLoader.execute(body);
  };

  const getByPage = (newPageParams) => {
    let pageInput = { ...pageParams, ...newPageParams };
    setPageParams(pageInput);
    getItems(pageInput, {});
  };

  const refreshTable = () => getByPage({});

  const onDeleteData = () => deleteLoader.execute({ id: selectedItem?.id });

  const onClone = () => clone.execute({ id: selectedItem?.id });

  const onChangeVisible = (item) => {
    if (isValidValue(item))
      changeVisible.execute({
        id: item?.id,
        visible: item?.visible + '' === 'true' ? false : true
      });
  };
  // ##
  // <-- TABLE SETUP -->
  // ##
  const showAction = (row) => showTableAction(row, dataLoader?.data?.content);

  const optionsFn = (row) => {
    return [
      {
        id: 'allDashboard-view-button',
        name: t('View'),
        icon: (weight) => (
          <ChartBar
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
          setUpdateModalVisibility(true);
        }
      },
      ...(showAction(row)
        ? [
            {
              id: 'allDashboard-chartOrder-button',
              name: t('Chart Order'),
              icon: (weight) => (
                <GearSix
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: false,
              onClick: () => {
                handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
                setChartAssignVis(true);
              }
            }
          ]
        : []),
      ...(showAction(row)
        ? [
            {
              name: findRow(dataLoader?.data?.content, row)?.visible + '' === 'true' ? t('Hide') : t('Show'),
              icon: (weight) =>
                findRow(dataLoader?.data?.content, row)?.visible + '' === 'true' ? (
                  <EyeClosed
                    size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                    weight={weight}
                  />
                ) : (
                  <Eye
                    size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                    weight={weight}
                  />
                ),
              disabled: false,
              onClick: () => onChangeVisible(findRow(dataLoader?.data?.content, row))
            }
          ]
        : []),
      ...(showAction(row)
        ? [
            {
              id: 'allDashboard-edit-button',
              name: t('Edit'),
              icon: (weight) => (
                <PencilLine
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: false,
              onClick: () => {
                handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
                setModalVisibility(true);
              }
            }
          ]
        : []),
      ...(showAction(row)
        ? [
            {
              id: 'allDashboard-delete-button',
              name: t('Delete'),
              icon: (weight) => (
                <Trash
                  size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
                  weight={weight}
                />
              ),
              disabled: false,
              onClick: () => {
                handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
                setConfirmOpen(true);
              }
            }
          ]
        : []),
      {
        id: 'allDashboard-clone-button',
        name: t('Clone'),
        icon: (weight) => (
          <Copy
            size={TABLE_ACTION_COLUMNS_STYLE.iconSize}
            weight={weight}
          />
        ),
        disabled: false,
        onClick: () => {
          handleSelectedRow(dataLoader?.data?.content, row, setSelectedItem);
          setConfirmToClone(true);
        }
      }
    ];
  };

  const tableData = useMemo(
    () => convertToTableData(dataLoader?.data?.content, 'dashboard', users),
    [dataLoader?.data?.content, users, getI18n().language]
  );

  let tableColumns = useMemo(
    () => generateTableColumns(tableData, optionsFn, ['id']),
    [dataLoader?.data?.content, users]
  );

  tableColumns = translateColumns(tableColumns, t);
  const loading = useMemo(() => dataLoader?.loading, [dataLoader?.loading]);

  useEffect(() => {
    refreshTable();
  }, [projectId]);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('All Dashboards')}
          >
            <div className="flex gap-4">
              {isProjectManager(projectId) && (
                <Button
                  id="allDashboard-projectSetting-button"
                  primary
                  size="small"
                  text={t('Project Setting')}
                  icon={TABLE_ACTION_BUTTON_CONSTANTS.settingIcon}
                  onClick={() => {
                    setProjectAssignVis(true);
                  }}
                />
              )}
              <Button
                id="allDashboard-add-button"
                primary
                size="small"
                text={t('Add')}
                icon={TABLE_ACTION_BUTTON_CONSTANTS.addIcon}
                onClick={() => {
                  setSelectedItem('');
                  setModalVisibility(true);
                }}
              />
            </div>
          </Heading.Group>
        </SidePaneRightContent.Top>
        <SidePaneRightContent.Main>
          <SidePaneRightContent.Center colFull={true}>
            <div className="mt-4">
              <Table
                columns={tableColumns}
                data={tableData}
                pagination
                loading={loading}
                pageParams={pageParams}
                getByPage={getByPage}
                entityType={'dashboard'}
              />
            </div>
          </SidePaneRightContent.Center>
        </SidePaneRightContent.Main>
      </SidePaneRightContent>
      {/* <-- MODALS --> */}
      {confirmOpen === true && (
        <ConfirmationModal
          isOpen={confirmOpen}
          closeModal={() => setConfirmOpen(false)}
          loading={deleteLoader?.loading}
          handleConfirm={onDeleteData}
          header={t('Confirm')}
          content={t('Do you want to delete dashboard?')}
        />
      )}
      {confirmToClone === true && (
        <ConfirmationModal
          isOpen={confirmToClone}
          closeModal={() => setConfirmToClone(false)}
          loading={clone?.loading}
          handleConfirm={onClone}
          header={t('Confirm')}
          content={t('Do you want to clone dashboard?')}
        />
      )}
      {updateModalVisibility === true && (
        <DashboardSettingModal
          isOpen={updateModalVisibility}
          closeModal={() => setUpdateModalVisibility(false)}
          dashboard={selectedItem}
          submitCallback={undefined}
          projects={managerProjects}
          users={users}
          projectConfig={projectConfig}
        />
      )}
      {projectAssignVis === true && (
        <DashboardProjectAssignModal
          projectId={projectId}
          isOpen={projectAssignVis}
          closeModal={() => setProjectAssignVis(false)}
          projects={managerProjects}
          commonConfigs={commonConfigs}
          users={users}
        />
      )}
      {modalVisibility === true && (
        <DashboardAddModal
          isOpen={modalVisibility}
          closeModal={() => {
            setModalVisibility(false);
            setSelectedItem(null);
            refreshTable();
          }}
          data={selectedItem}
        />
      )}
      {chartAssignVis === true && (
        <DashboardChartAssignModal
          isSystemDashboard={selectedItem?.systemDefault}
          dashboardId={selectedItem?.id}
          projectId={projectId}
          isOpen={chartAssignVis}
          closeModal={() => {
            setChartAssignVis(false);
            setSelectedItem(null);
          }}
          users={users}
        />
      )}
    </>
  );
};

export default Dashboards;
