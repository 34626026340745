import React, { Fragment } from 'react'
import { Tab } from '@headlessui/react'



const TabBar = ({ options, defaultIndex }) => {
    return (
        <>
            <Tab.Group defaultIndex={defaultIndex}>
                <Tab.List>
                    {options.map((item, index) =>
                     <span key={index} style={{marginRight: 10}}>  
                        <Tab 
                            {...(item.disabled && { disabled: true })} 
                            as={Fragment}>
                            {({ selected }) => (
                                <button
                                // TODO disabled style eklenecek
                                    className={
                                        selected ? 'border-b-2 pb-2 text-primary-700 border-primary-700 px-4 font-normal text-sm' : 'px-4 pb-2 border-none font-normal text-sm'
                                    }
                                >{item.name}</button>
                            )}
                        </Tab>
                        </span>
                    )}
                </Tab.List>
                <Tab.Panels>
                    {options.map((item, index) =>
                      <span key={index}>  <Tab.Panel>{item.render()}</Tab.Panel> </span>)
                    }
                </Tab.Panels>
            </Tab.Group>
        </>
    )
}

export default TabBar
