import React, { useState } from 'react';
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { TABLE_ACTION_BUTTON_CONSTANTS } from '../../../helpers/ButtonConstants';
//HELPER
import { getDefaultDateRange } from '../helper/DashboardHelper';
import { isOwner, isSystemAdmin } from '../../../helpers/PriviligeHelper';
import { convertDateToMs, translatedDateFormat } from '../../../helpers/Helper';
import { getArtifactName } from '../../../helpers/ArtifactNameHelper';
//MODAL
import { Modal } from '../../../components/modal';
import DashboardChartAssignModal from './DashboardChartAssignModal';
// HOOK && CONTEXT IMPORTS+
import { useTranslation } from 'react-i18next';
import UseQueryProject from '../../../hooks/UseQueryProject';
import PageCharts from '../dpage/PageCharts';

const DashboardSettingModal = ({ dashboard, isOpen, closeModal, users, projectConfig }) => {
  const { t } = useTranslation();

  const { projectId } = UseQueryProject('projectId');
  const [chartAssignVis, setChartAssignVis] = useState(false);
  const [updateCharts, setUpdateCharts] = useState(false);

  const dateRanges = [getDefaultDateRange()];
  const dateFilter = {
    startMs: convertDateToMs(dateRanges[0]?.startDate),
    endMs: convertDateToMs(dateRanges[0]?.endDate)
  };

  const callAfterChartChanges = () => setUpdateCharts(true);

  const isChartSettingDisabled = () => {
    if (isSystemAdmin() && dashboard?.systemDefault) {
      return true;
    } else if (isSystemAdmin() && !dashboard?.systemDefault) {
      return false;
    } else if (isOwner(dashboard?.createdBy) && dashboard?.systemDefault) {
      return true;
    } else if (isOwner(dashboard?.createdBy) && !dashboard?.systemDefault) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Modal
        w={'xl'}
        open={isOpen}
        close={() => closeModal()}
      >
        <Modal.Title>
          <Heading.Group text={getArtifactName(projectConfig, dashboard?.name)}>
            <div className="flex gap-4">
              <div style={{ fontSize: 12, marginTop: 10, marginRight: 50 }}>
                {translatedDateFormat(dateRanges[0]?.startDate, 'DD MMM YYYY HH:mm', t)}
                {' - '}
                {translatedDateFormat(dateRanges[0]?.endDate, 'DD MMM YYYY HH:mm', t)}{' '}
              </div>
              <Button
                id="dashboardSettingModal-chartSetting-button"
                secondary
                text={t('Chart Setting')}
                icon={TABLE_ACTION_BUTTON_CONSTANTS.settingIcon}
                disabled={isChartSettingDisabled()}
                onClick={() => setChartAssignVis(true)}
              />
              <Button
                id="dashboardSettingModal-close-button"
                icon="X"
                onClick={closeModal}
                size={'xsmall'}
              />
            </div>
          </Heading.Group>
        </Modal.Title>
        <Modal.Content h={'lg'}>
          <>
            <div className="m-8  flex-wrap gap-8">
              <div className={`mt-8 grid grid-cols-1 gap-5 divide-x divide-opacity-25`}>
                <PageCharts
                  dashboardId={dashboard?.id}
                  updateCharts={updateCharts}
                  setUpdateCharts={setUpdateCharts}
                  dateFilter={dateFilter}
                  projectId={projectId}
                />
              </div>
            </div>
            {chartAssignVis === true && (
              <DashboardChartAssignModal
                isSystemDashboard={dashboard?.systemDefault}
                dashboardId={dashboard?.id}
                isOpen={chartAssignVis}
                projectId={projectId}
                users={users}
                closeModal={() => {
                  setChartAssignVis(false);
                  callAfterChartChanges();
                }}
              />
            )}
          </>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default DashboardSettingModal;
