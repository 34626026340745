import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/themes/light.css';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import { useTranslation, getI18n } from 'react-i18next';
// Design System Import
import Accordion from '../accordion/Accordion';
import { Heading, Body, StatusPill } from '../../designSystem/DesignSystem';
// Helper Import
import {
  isValidValue,
  getUserFirstLastNameById,
  formatItem,
  getUserName,
  getUrl,
  getUserOptionsWithGroups,
  translatedDateFormat
} from '../../helpers/Helper';
import { getEntityCustomFieldValue } from '../../helpers/CustomFieldHelper';
import { getJiraLink } from '../../helpers/jira/JiraHelper';
import { isJiraContentValid } from '../../helpers/jira/JiraContentHelper';
import {
  getCycleName,
  getExecutionName,
  getReleaseName,
  getRunName,
  getSuiteName,
  getTestCaseName,
  getTestFolderName
} from '../../helpers/ArtifactNameHelper';
import { ENTITY_TYPES } from '../../constants';
import UseProjectUserGroups from '../../hooks/UseProjectUserGroups';

const EntityMetaData = (props) => {
  const { data, entityType, users, customFields, projectId, jiraConfig } = props;
  const { t } = useTranslation();
  const projectConfig = UseProjectConfig(projectId);
  const userGroups = UseProjectUserGroups(projectId);
  const userOptions = getUserOptionsWithGroups(users, userGroups);

  const [dataList, setDataList] = useState([]);
  const [customFieldList, setCustomFieldList] = useState([]);
  const [jiraDataList, setJiraDataList] = useState([]);
  const linkClass = 'text-blue-900 text-sm font-medium hover:underline underline-offset-4';
  const list = [];
  const csList = [];
  const jiralist = [];

  const getParent = (type) => {
    if (type === ENTITY_TYPES.REQUIREMENT || type === ENTITY_TYPES.FOLDER) {
      return ENTITY_TYPES.FOLDER;
    } else if (type === ENTITY_TYPES.TEST_CASE || type === ENTITY_TYPES.TEST_CONTAINER) {
      return ENTITY_TYPES.TEST_CONTAINER;
    } else {
      return type;
    }
  };

  const setCustomFieldDetails = (entityCustomFields, allCustomFields, userList) => {
    if (data && entityType && allCustomFields && allCustomFields.length > 0) {
      let sortedObjs = [];
      if (allCustomFields != null && allCustomFields.length > 0) {
        sortedObjs = _.sortBy(allCustomFields, 'place');
      }
      sortedObjs.map((customField) => {
        if (customField?.visible) {
          const value = getEntityCustomFieldValue(userList, entityCustomFields, customField);
          csList.push({
            value: (
              <Body
                type="medium"
                text={isValidValue(value) ? value : '-'}
              />
            ),
            label: customField?.fieldName,
            customFieldEnumId: customField?.customFieldEnumId
          });
        }
      });
    }
  };

  const fillJiraDetails = () => {
    switch (entityType) {
      case ENTITY_TYPES.REQUIREMENT:
      case ENTITY_TYPES.DEFECT:
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraContent?.id}
            />
          ),
          label: t('Jira ID')
        });
        jiralist.push({
          value: (
            <a
              rel="noreferrer"
              className={linkClass + ' cursor-pointer'}
              href={getJiraLink(jiraConfig?.serverUrl, data?.jiraContent?.key)}
              target="_blank"
            >
              {' '}
              {data?.jiraContent?.key}{' '}
            </a>
          ),
          label: t('Jira Issue')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraContent?.fields?.project?.name ? data?.jiraContent?.fields?.project?.name : '-'}
            />
          ),
          label: t('Jira Project')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraContent?.fields?.issuetype?.name ? data?.jiraContent?.fields?.issuetype?.name : '-'}
            />
          ),
          label: t('Jira Type')
        });
        jiralist.push({
          value: data?.jiraContent?.fields?.status?.name ? (
            <StatusPill
              value={data?.jiraContent?.fields?.status?.name}
              entityType={entityType}
            />
          ) : (
            <Body
              type="medium"
              text={'-'}
            />
          ),
          label: t('Jira Status')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={
                data?.jiraContent?.fields?.assignee?.displayName
                  ? data?.jiraContent?.fields?.assignee?.displayName
                  : '-'
              }
            />
          ),
          label: t('Jira Assignee')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={
                data?.jiraContent?.fields?.reporter?.displayName
                  ? data?.jiraContent?.fields?.reporter?.displayName
                  : '-'
              }
            />
          ),
          label: t('Jira Reporter')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraContent?.fields?.priority?.name ? data?.jiraContent?.fields?.priority?.name : '-'}
            />
          ),
          label: t('Jira Priority')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraReleases.length > 0 ? data?.jiraReleases.toString() : '-'}
            />
          ),
          label: t('Jira Release')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraSprints.length > 0 ? data?.jiraSprints.toString() : '-'}
            />
          ),
          label: t('Jira Sprint')
        });
        break;
      case ENTITY_TYPES.RELEASE:
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraReleaseType}
            />
          ),
          label: t('Jira Type')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraProject?.name ? data?.jiraProject?.name : '-'}
            />
          ),
          label: t('Jira Project')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraProject?.id ? data?.jiraProject?.id : '-'}
            />
          ),
          label: t('Jira Project ID')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraProject?.name ? data?.jiraProject?.name : '-'}
            />
          ),
          label: t('Jira Project Name')
        });
        if (data.jiraReleaseType === 'release' || isValidValue(data.jiraContent?.releaseDate)) {
          jiralist.push({
            value: (
              <Body
                type="medium"
                text={data?.jiraContent?.id}
              />
            ),
            label: t('Jira Release ID')
          });
          jiralist.push({
            value: (
              <Body
                type="medium"
                text={data?.jiraContent?.name}
              />
            ),
            label: t('Jira Release Name')
          });
          jiralist.push({
            value: (
              <Body
                type="medium"
                text={translatedDateFormat(data?.jiraContent?.startDate, 'DD MMM YYYY', t)}
              />
            ),
            label: t('Jira Start Date')
          });
          jiralist.push({
            value: (
              <Body
                type="medium"
                text={translatedDateFormat(data?.jiraContent?.releaseDate, 'DD MMM YYYY', t)}
              />
            ),
            label: t('Jira Release Date')
          });
          jiralist.push({
            value: (
              <Body
                type="medium"
                text={data?.jiraContent?.overdue === true ? t('Yes') : t('No')}
              />
            ),
            label: t('Overdue')
          });
          jiralist.push({
            value: (
              <Body
                type="medium"
                text={data?.jiraContent?.overdue === true ? t('Yes') : t('No')}
              />
            ),
            label: t('Archived')
          });
          jiralist.push({
            value: (
              <Body
                type="medium"
                text={data?.jiraContent?.released === true ? t('Yes') : t('No')}
              />
            ),
            label: t('released')
          });
        }
        break;
      case ENTITY_TYPES.FOLDER:
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraContent?.id}
            />
          ),
          label: t('Jira ID')
        });
        jiralist.push({
          value: (
            <Body
              type="medium"
              text={data?.jiraContent?.name}
            />
          ),
          label: t('Jira Name')
        });
        break;
      default:
        break;
    }
  };

  const notShowingColumns = () => {
    const general = [
      'code',
      'codeNum',
      'name',
      'id',
      'projectId',
      'release',
      'lockStatus',
      'description',
      'testCaseCount'
    ];
    const JiraGeneral = [
      'jiraId',
      'jiraKey',
      'jiraProjectId',
      'jiraReleases',
      'jiraSprints',
      'jiraReleaseType',
      'jiraProject'
    ];
    switch (entityType) {
      case ENTITY_TYPES.REQUIREMENT:
        general.push('lockedStatusToUpdate');
        break;
      case ENTITY_TYPES.TEST_CASE:
        general.push('lastTestRun', 'lastTestRunExecution', 'rootId', 'preconditions');
        break;
      case ENTITY_TYPES.TEST_CYCLE:
        general.push('lockStatus', 'testFolder');
        break;
      case ENTITY_TYPES.TEST_SUITE:
        general.push('countOfChild', 'status');
        break;
      case ENTITY_TYPES.TEST_RUN:
        general.push('defectCount', 'executionCount', 'summary');
        break;
      case ENTITY_TYPES.DEFECT:
        general.push('testFolder', 'testSuite');
        break;
      default:
        break;
    }
    return general.concat(JiraGeneral);
  };

  const requirementReleases = (releases) => {
    if (releases && releases.length) {
      return releases?.map((item) => (
        <>
          <Link
            className={linkClass}
            to={getUrl(ENTITY_TYPES.RELEASE, item, projectId)}
          >
            {item?.code}
          </Link>{' '}
        </>
      ));
    } else {
      return '-';
    }
  };

  useEffect(() => {
    if (isValidValue(data)) {
      Object.keys(data).forEach((item) => {
        if (item === 'modifiedTime' || item === 'createdTime' || item === 'startDate' || item === 'endDate') {
          list.push({
            value: (
              <Body
                type="medium"
                text={translatedDateFormat(data[item], 'DD MMM YYYY', t)}
              />
            ),
            label: item
          });
        } else if (item === 'createdBy' || item === 'modifiedBy') {
          list.push({
            value: (
              <Body
                type="medium"
                text={getUserFirstLastNameById(users, data[item])}
              />
            ),
            label: item
          });
        } else if (item === 'customFields') {
          setCustomFieldDetails(data[item], customFields, userOptions);
          setCustomFieldList(csList);
        } else if (item === 'jiraContent') {
          fillJiraDetails();
        } else if (notShowingColumns().includes(item)) {
          return null;
        } else if (item === 'releases') {
          if (data[item].length === 0) {
            list.push({
              value: (
                <Body
                  type="medium"
                  text={'-'}
                />
              ),
              label: getReleaseName(projectConfig)
            });
          } else {
            list.push({
              value: <div className="flex flex-col">{requirementReleases(data[item])}</div>,
              label: getReleaseName(projectConfig)
            });
          }
        }
        //REQUIREMENT
        else if (item === 'status') {
          list.push({
            value: data[item]?.name ? (
              <StatusPill
                value={data[item]?.name?.toString()}
                entityType={entityType}
              />
            ) : (
              <Body
                type="medium"
                text={'-'}
              />
            ),
            label: item
          });
        } else if (item === 'damage' || item === 'frequency') {
          list.push({
            value: (
              <Body
                type="medium"
                text={`${t(data[item])} / 5`}
              />
            ),
            label: item
          });
        }
        //Test Case
        else if (item === 'approveType') {
          list.push({
            value: (
              <StatusPill
                value={data[item] == 'SENT_FOR_APPROVAL' ? t('pending for approval') : t(data[item])}
                entityType={entityType}
              />
            ),
            label: 'Approve Type'
          });
        } else if (item === 'majorVersion') {
          list.push({
            value: (
              <Body
                type="medium"
                text={t(data[item])}
              />
            ),
            label: 'Version'
          });
        }
        //Test Cycle
        else if (item === 'realStartDate') {
          list.push({
            value: (
              <Body
                type="medium"
                text={translatedDateFormat(data[item], 'DD MMM YYYY', t)}
              />
            ),
            label: t('Actual Start Date')
          });
        } else if (item === 'realEndDate') {
          list.push({
            value: (
              <Body
                type="medium"
                text={translatedDateFormat(data[item], 'DD MMM YYYY', t)}
              />
            ),
            label: t('Actual End Date')
          });
        }
        //Test Run
        else if (item === 'lastTestRunExecution') {
          list.push({
            value: data[item]?.status ? (
              <StatusPill
                value={data[item]?.status?.toString()}
                entityType={entityType}
              />
            ) : (
              <Body
                type="medium"
                text={'-'}
              />
            ),
            label: 'Last Execution'
          });
          list.push({
            value: (
              <Body
                type="medium"
                text={translatedDateFormat(data[item]?.executionEndTime, 'DD MMM YYYY  HH:mm:ss', t)}
              />
            ),
            label: 'Last Execution Time'
          });
        }
        //Defect
        else if (item === 'assignee') {
          list.push({
            value: (
              <Body
                type="medium"
                text={data[item] ? getUserName(data[item]) : '-'}
              />
            ),
            label: item
          });
        } else if (item === 'testCycle') {
          if (data[item]?.name) {
            list.push({
              value: (
                <Link
                  className={linkClass}
                  to={getUrl(ENTITY_TYPES.TEST_CYCLE, data[item], projectId)}
                >
                  {data[item]?.code}
                </Link>
              ),
              label: getCycleName(projectConfig)
            });
          }
        } else if (item === 'testFolder') {
          if (data[item]?.name) {
            list.push({
              value: (
                <Link
                  className={linkClass}
                  to={getUrl(ENTITY_TYPES.TEST_FOLDER, data[item], projectId)}
                >
                  {data[item]?.code}
                </Link>
              ),
              label: getTestFolderName(projectConfig)
            });
          }
        } else if (item === 'testSuite') {
          if (data[item]?.name) {
            list.push({
              value: (
                <Link
                  className={linkClass}
                  to={getUrl(ENTITY_TYPES.TEST_SUITE, data[item], projectId)}
                >
                  {data[item]?.code}
                </Link>
              ),
              label: getSuiteName(projectConfig)
            });
          }
        } else if (item === 'testCase') {
          if (data[item]?.name) {
            list.push({
              value: (
                <Link
                  className={linkClass}
                  to={getUrl(ENTITY_TYPES.TEST_CASE, data[item], projectId)}
                >
                  {data[item]?.code}
                </Link>
              ),
              label: getTestCaseName(projectConfig)
            });
          }
        } else if (item === 'testRun') {
          if (data[item]?.code) {
            list.push({
              value: (
                <Link
                  className={linkClass}
                  to={getUrl(ENTITY_TYPES.TEST_RUN, data[item], projectId)}
                >
                  {data[item]?.code}
                </Link>
              ),
              label: getRunName(projectConfig)
            });
          }
        } else if (item === 'testRunExecutionIds') {
          if (data[item] && data[item].length) {
            list.push({
              value: (
                <Body
                  type="medium"
                  text={(data[item] || []).map((param) => param.code).toString()}
                />
              ),
              label: getExecutionName(projectConfig)
            });
          }
        } else if (item === 'parent') {
          if (isValidValue(data[item]?.name)) {
            list.push({
              value: (
                <Link
                  className={linkClass}
                  to={getUrl(getParent(entityType), data[item], projectId)}
                >
                  {data[item].code}
                </Link>
              ),
              label: item
            });
          } else {
            return null;
          }
        } else if (item === 'jiraStatus' || item === 'jiraPriority') {
          return null;
        } else if (typeof data[item] === 'object') {
          list.push({
            value: (
              <Body
                type="medium"
                text={isValidValue(data[item]?.name) ? t(data[item]?.name) : '-'}
              />
            ),
            label: item
          });
        } else {
          list.push({
            value: (
              <Body
                type="medium"
                text={data[item]}
              />
            ),
            label: item
          });
        }
      });
    }
    setDataList(list);
    setJiraDataList(jiralist);
  }, [data, users, getI18n().language]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <Accordion
          defaultOpen
          border
          button={
            <Heading
              type="base"
              text={t('Details')}
            />
          }
        >
          <div>
            {dataList.map((item, index) => {
              return (
                <div
                  className="mb-2 flex flex-row items-center"
                  key={index}
                >
                  <Heading
                    className="w-36"
                    type="small"
                    text={t(formatItem(item?.label))}
                  />
                  {item.value}
                </div>
              );
            })}
          </div>
        </Accordion>
        {customFieldList.length > 0 && (
          <Accordion
            defaultOpen
            border
            button={
              <Heading
                type="base"
                text={t('Custom Fields')}
              />
            }
          >
            <div>
              {customFieldList.map((item, index) => {
                return (
                  <div
                    className="mb-2 grid grid-cols-3 gap-1"
                    key={index}
                  >
                    <Heading
                      className="col-span-1"
                      type="small"
                      text={formatItem(item?.label)}
                    />
                    {item?.customFieldEnumId == 3 ? (
                      <Tippy
                        theme="custom-theme"
                        tooltip="top"
                        className="whitespace-pre-wrap break-all"
                        animation="scale-extreme"
                        placement={'top'}
                        arrow={true}
                        content={item?.value}
                      >
                        <div className="col-span-2 cursor-pointer ">
                          <Body
                            className="truncate"
                            type="medium"
                            text={item.value}
                          />
                        </div>
                      </Tippy>
                    ) : (
                      <Body
                        className="col-span-2"
                        type="medium"
                        text={item.value}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </Accordion>
        )}
        {isJiraContentValid(jiraConfig, data) && (
          <Accordion
            defaultOpen
            border
            button={
              <Heading
                type="base"
                text={t('Jira Details')}
              />
            }
          >
            <div>
              {jiraDataList.map((item, index) => {
                return (
                  <div
                    className="mb-2 flex flex-row items-center"
                    key={index}
                  >
                    <Heading
                      className="w-36"
                      type="small"
                      text={t(formatItem(item?.label))}
                    />
                    {item.value}
                  </div>
                );
              })}
            </div>
          </Accordion>
        )}
      </div>
    </>
  );
};

export default EntityMetaData;
