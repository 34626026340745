import i18next from 'i18next';
import { getDefectName, getTestCaseName } from '../../../helpers/ArtifactNameHelper';
import Table from '../../table/Table';

const RequirementGeneralTestReportTable = (props) => {
  const { response, projectConfig } = props;
  let tableColumns = [
    { Header: i18next.t('TotalDefect', { entity: getDefectName(projectConfig) }), accessor: 'totalDefect' },
    {
      Header: i18next.t('TotalApproved', { entity: getTestCaseName(projectConfig) }),
      accessor: 'totalApprovedTestCase'
    },
    { Header: i18next.t('ExecutedTest', { entity: getTestCaseName(projectConfig) }), accessor: 'executedTestCase' },
    {
      Header: i18next.t('NotExecutedTest', { entity: getTestCaseName(projectConfig) }),
      accessor: 'notExecutedTestCase'
    }
  ];
  let tableData;

  if (response !== undefined) {
    tableData = [
      {
        totalDefect: response?.totalDefect || 0,
        totalApprovedTestCase: response?.totalTestCase || 0,
        executedTestCase: response?.executedTestCase || 0,
        notExecutedTestCase: response?.notExecutedTestCase || 0
      }
    ];
  }

  return (
    <>
      <Table
        data={tableData}
        columns={tableColumns}
        compact
        loading={response !== undefined ? false : true}
      />
    </>
  );
};

export default RequirementGeneralTestReportTable;
