import React, { useEffect, useState } from 'react';
// HOOK & API IMPORTS
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
// COMPONENT IMPORTS
import { Dropdown, Input, Loader } from '../../designSystem/DesignSystem';
import { ENTITY_TYPES } from '../../constants';
import { isValidValue } from '../../helpers/Helper';
import { getSuiteName } from '../../helpers/ArtifactNameHelper';
import TreeArborist from '../tree/TreeArborist';

const TestSuiteSearchTree = ({ setSelectedTestSuite }) => {
  const { control } = useForm({ mode: 'onBlur', shouldFocusError: true, criteriaMode: 'all' });
  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const { t } = useTranslation();

  const [filters, setFilters] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);

  const searchTypesDropdown = [
    {
      key: 'code',
      value: 'code',
      text: `${getSuiteName(projectConfig)} Code`,
      name: `${t('entityCode', { entity: getSuiteName(projectConfig) })}`
    },
    {
      key: 'name',
      value: 'name',
      text: `${getSuiteName(projectConfig)} Name`,
      name: `${t('entityName', { entity: getSuiteName(projectConfig) })}`
    }
  ];

  const [searchOption, setSearchOption] = useState(searchTypesDropdown[1]);

  const onChangeSearchKeyword = (value) => {
    if (isValidValue(value) && isValidValue(searchOption)) {
      let code = null;
      let name = null;

      if (searchOption?.key === 'code') {
        code = value;
        setFilters({ type: 'code', searchParameter: code });
      } else if (searchOption?.key === 'name') {
        name = value;
        setFilters({ type: 'name', searchParameter: name });
      }
    } else {
      setFilters({});
    }
  };
  useEffect(() => {
    setSelectedTestSuite(selectedItem);
  }, [selectedItem]);

  return (
    <form className="-mt-3 flex flex-grow flex-col gap-4">
      <div className="flex items-center py-2 pr-2">
        <div className="-mt-5 w-64">
          <Dropdown
            label={t('Test Suite')}
            control={control}
            name={'search'}
            options={searchTypesDropdown}
            selectedItem={searchOption}
            passValue={(value) => {
              setSearchOption(value[0]);
            }}
          />
        </div>
        <div className="mt-1 w-full">
          <Input
            type={searchOption.key === 'code' ? 'number' : 'text'}
            name="name"
            placeholder={t(`Search`)}
            control={control}
            passValue={(value) => onChangeSearchKeyword(value)}
          />
        </div>
      </div>
      <div className="h-[22vh] max-h-min">
        {!isValidValue(projectConfig?.projectId) ? (
          <Loader />
        ) : (
          <TreeArborist
            module={ENTITY_TYPES.TEST_SUITE}
            draggable={false}
            projectId={projectId}
            eventsEnabled={false}
            projectConfig={projectConfig}
            filters={filters}
            onSelect={(item) => setSelectedItem(item)}
          />
        )}
      </div>
    </form>
  );
};
export default TestSuiteSearchTree;
