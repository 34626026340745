export const isActiveUpdateJiraRequirementButton = (projectConfigs) =>{
    return ((projectConfigs?.configuration?.jira?.updateJiraRequirement + "") === "true");
}
//
export const isActiveNewJiraRequirementButton = (projectConfigs) =>{
    return ((projectConfigs?.configuration?.jira?.createNewJiraRequirement + "") === "true");
}
//
export const isActiveNewJiraDefectButton = (projectConfigs) =>{
    return ((projectConfigs?.configuration?.jira?.createNewJiraDefect + "") === "true");
}
//
export const isActiveUpdateJiraDefectButton = (projectConfigs) =>{
    return ((projectConfigs?.configuration?.jira?.updateJiraDefect + "") === "true");
}
