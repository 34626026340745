import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { uniqueId } from 'lodash-es';
import { dismissToast } from '../../../helpers/AttachmentHelpers';

// API & HOOKS
import UseQueryProject from '../../../hooks/UseQueryProject';
import { useApi } from '../../../hooks/UseApi';
import TestRunExecutionApi from '../../../api/TestRunExecutionApi';
import AttachmentsApi from '../../../api/AttachmentsApi';

// DESIGN SYSTEM & COMPONENTS
import TestRunSteps from '../TestRunSteps';
import { Dropdown, Button, Datepicker } from '../../../designSystem/DesignSystem';
import { Modal } from '../../../components/modal';
import AdvancedTabBar from '../../../components/tab/AdvancedTabBar';
import QuickExecutionAttachmentTab from '../tabs/QuickExecutionAttachmentTab';
import UploadLoadingModal from '../../../components/upload/UploadLoadingModal.js';
import UploadErrorModal from '../../../components/upload/UploadErrorModal.js';
// HELPERS & CONSTANTS
import { ENTITY_TYPES, RunExecutionStatus } from '../../../constants';
import { isValidList, isValidValue } from '../../../helpers/Helper';
import { showErrorMes, showSuccessMes } from '../../../helpers/NotificationHelper';

const QuickTestRunModal = ({ isOpen, close, runId, preconditions }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { projectId } = UseQueryProject('projectId');

  const [dataForm, setDataForm] = useState({});
  const [attachments, setAttachments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errorFiles, setErrorFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);

  const quickExecutionWithAttachment = useApi({
    name: 'saving test run with attachment',
    api: TestRunExecutionApi.quickExecuteWithAttachment,
    onSuccess: () => {
      handleUpload(quickExecutionWithAttachment?.data);
    },
    onError: (err) => {
      showErrorMes(err);
    }
  });

  const handleUpload = async (runExecution) => {
    setLoading(true);
    setErrorFiles([]);
    setUploadedFiles(0);
    setTotalFiles(attachments.length);
    const failedFiles = [];

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };

    // If there are accepted files, proceed with the upload
    if (attachments?.length > 0) {
      const uploadPromises = attachments.map(async (file) => {
        let data = new FormData();
        data.append('file', file);
        data.append('projectId', projectId);
        data.append('entityType', ENTITY_TYPES.TEST_EXECUTION);
        data.append('entityId', runExecution?.id);

        const id = uniqueId();
        try {
          await AttachmentsApi.upload({ data, config });
          setUploadedFiles((prev) => prev + 1);
        } catch (err) {
          failedFiles.push({ name: file?.name, error: err?.response?.data?.message || err.message });
        } finally {
          dismissToast(id);
        }
      });

      await Promise.all(uploadPromises);
    }

    setErrorFiles(failedFiles);
    if (failedFiles.length === 0) {
      showSuccessMes(t('All files uploaded successfully.'));
      close();
    }

    setLoading(false);
  };

  const onSubmit = (data) => {
    let status = '';
    if (dataForm?.status) {
      status = dataForm?.status;
    }

    const body = new FormData();
    body.append('projectId', projectId);
    body.append('entityType', ENTITY_TYPES.TEST_EXECUTION);
    body.append('quickRunExecution', true);
    body.append('testRunId', runId);
    body.append('status', status);

    if (data.executionStartTime && !data.executionEndTime) {
      showErrorMes(t('You must enter an end date when you enter a start date.'));
      return;
    }
    if (!data.executionStartTime && data.executionEndTime) {
      showErrorMes(t('You must enter a start date when you enter an end date.'));
      return;
    }
    if (!data.executionStartTime && !data.executionEndTime) {
      data.executionStartTime = moment.now();
      data.executionEndTime = moment.now();
    }
    if (data?.executionStartTime) {
      body.append('executionStartTime', moment(data.executionStartTime).format('YYYY-MM-DD HH:mm:ss'));
    }
    if (data?.executionEndTime) {
      body.append('executionEndTime', moment(data.executionEndTime).format('YYYY-MM-DD HH:mm:ss'));
    }

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };

    quickExecutionWithAttachment.execute({ body, config });
  };

  let quickTestRunOptions = Object.values(RunExecutionStatus).map((item) => {
    return {
      ...item,
      text: t(item.text),
      name: t(item.name)
    };
  });

  return (
    <>
      <Modal
        open={isOpen}
        close={close}
        w={'lg'}
      >
        <Modal.Title>{t('Quick Execution')}</Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Content h={'lg'}>
            <AdvancedTabBar
              defaultIndex={0}
              tabs={[
                {
                  label: t('Execute'),
                  disabled: false,
                  render: () => (
                    <div className="mt-3 flex flex-grow flex-col">
                      <div className="flex flex-grow">
                        <div className="w-[400px]">
                          <Dropdown
                            required
                            clearable
                            control={control}
                            label={t('Status')}
                            name="status"
                            placeholder={t('Select Status')}
                            options={quickTestRunOptions || []}
                            selectedItem={isValidValue(getValues('status')) ? getValues('status') : null}
                            passValue={(value) => {
                              setDataForm((prev) => ({ ...prev, status: isValidList(value) ? value[0]?.value : null }));
                              if (!isValidList(value)) setValue('status', null);
                              else setValue('status', value[0]);
                            }}
                          />
                        </div>
                        <Datepicker
                          clearable
                          control={control}
                          startDateLabel={t('Start Date')}
                          endDateLabel={t('End Date')}
                          endDateName="executionEndTime"
                          startDateName="executionStartTime"
                          dateFormat="dd/MM/yyy, HH:mm"
                          showTimeInput
                        />
                      </div>
                      <div className="mt-5">
                        <TestRunSteps
                          preconditions={preconditions}
                          runId={runId}
                        />
                      </div>
                    </div>
                  )
                },
                {
                  label: t('Attachments'),
                  disabled: false,
                  render: () => (
                    <QuickExecutionAttachmentTab
                      disabled={false}
                      projectId={projectId}
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  )
                }
              ]}
            />
          </Modal.Content>
          <Modal.Buttons>
            <Button
              id="quickTestRunModal-cancel-button"
              secondary
              text={t('Cancel')}
              onClick={close}
            />
            <Button
              id="quickTestRunModal-submit-button"
              primary
              text={t('Submit')}
              type="submit"
              loading={quickExecutionWithAttachment?.loading}
            />
          </Modal.Buttons>
        </form>
      </Modal>

      <UploadLoadingModal
        open={loading}
        totalFiles={totalFiles}
        uploadedFiles={uploadedFiles}
      />

      <UploadErrorModal
        open={!loading && errorFiles.length > 0}
        close={() => {
          setErrorFiles([]);
          close();
        }}
        errorFiles={errorFiles}
      />
    </>
  );
};

export default QuickTestRunModal;
