import {axios} from "./ApiClient";

class PrivilegeApi {

    async getPrivilegeList() {
        const resp = await axios.get(`/api/privileges`);
        return resp.data;
    }

}

export default new PrivilegeApi();